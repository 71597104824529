import { FilterOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { TitleBar } from 'components';
import useTableRowSelection from 'hooks/useTableRowSelection';
import { TabTitle } from 'pages/Dashboard';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useListShipMutate } from 'services/api';
import {
  useCountOutboundShipmentsShipments,
  useInboundListShipMutate,
} from 'services/api/newShipments';
import { dateLocaleFormat, dateLocalFormat, downloadJsonToCSV, mapShipInvItem } from 'utils';
import { ShipmentProps } from '../hooks/typings';
import { ShipmentTitleProps } from '../typings';
import styles from './index.module.less';
import OutboundInternalShipments from './OutboundInternalShipments';
import {
  getOutboundSettingsShipTabSession,
  getOutboundSettingsTabSession,
  setOutboundSettingsShipTabSession,
  setOutboundSettingsTabSession,
} from './OutboundTabSettings';

export const ShipmentsTitle: FC<ShipmentTitleProps> = ({ t, showFilter, setShowFilter }) => (
  <TitleBar
    title={`${t('outbound_shipment')}`}
    extra={
      <Button type="link" onClick={() => setShowFilter && setShowFilter(!showFilter)}>
        <FilterOutlined />
        {showFilter === true ? t('hide_filter') : t('show_filter')}
      </Button>
    }
  />
);

/**
 * Component to manage the inventory of a product.
 */
const ShipmentOutbound: FC<ShipmentProps> = ({
  isNetworkShip,
  outboundShipParam,
  hideOtherSystemTab,
  considerAsInbound,
}) => {
  const sessionTab: string = isNetworkShip
    ? getOutboundSettingsShipTabSession()
    : getOutboundSettingsTabSession();
  const [currentTab, setCurrentTab] = useState(sessionTab);
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  const { onRowSelectionChange, selectedRows, selectedRowKeys } = useTableRowSelection();
  const { setProducts, setSelectedShipments } = useShipmentStore();
  const listShipMutate = useListShipMutate();
  const listExternalShipMutate = useInboundListShipMutate();
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const { data: dataProductCounts } = useCountOutboundShipmentsShipments({
    ...outboundShipParam,
  });
  const totalItemsInternal = dataProductCounts?.internal || 0;
  const totalItemsWholechain = dataProductCounts?.wholechain || 0;
  const totalItemsOtherSystems = dataProductCounts?.otherSystems || 0;
  useEffect(() => {
    setCurrentTab(sessionTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChange = (activeKey: string) => {
    if (isNetworkShip) {
      setOutboundSettingsShipTabSession(activeKey);
    } else {
      setOutboundSettingsTabSession(activeKey);
    }
    setCurrentTab(activeKey);
    onRowSelectionChange([], []);
  };

  const onDownloadCSVMulti = useCallback(async () => {
    if (currentTab === 'wholechain') {
      const data = await listShipMutate.mutateAsync({
        options: {
          direction: 'Outbound',
          sortBy: '-eventDate',
          status: ['Pending', 'Rejected'],
          onlyMyShipments: true,
        },
      });
      const records = data?.results?.map(mapShipInvItem);
      downloadJsonToCSV(records);
    } else {
      const data = await listExternalShipMutate.mutateAsync({
        options: {
          sortBy: '-eventDate',
          status: ['Pending', 'Rejected'],
        },
      });
      const records = data?.results?.map(
        (item) => ({
          ...item,
          loggedDate: dateLocalFormat(new Date(item?.loggedDate || new Date())),
          eventDate: dateLocaleFormat(new Date(item?.eventDate || new Date())),
        }),
        [],
      );
      downloadJsonToCSV(records);
    }
  }, [currentTab, listExternalShipMutate, listShipMutate]);

  const hideOtherSystem = useMemo(
    () => isNetworkShip && hideOtherSystemTab,
    [hideOtherSystemTab, isNetworkShip],
  );

  return (
    <>
      {!isNetworkShip && (
        <div className={styles.titlebarwarp}>
          <ShipmentsTitle
            onRowSelectionChange={onRowSelectionChange}
            onDownloadCSVMultiple={onDownloadCSVMulti}
            selectedRowKeys={selectedRowKeys}
            selectedRows={selectedRows}
            t={t}
            setProducts={setProducts}
            setSelectedShipments={setSelectedShipments}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
        </div>
      )}
      <Tabs defaultActiveKey="internal" activeKey={currentTab} onChange={onTabChange}>
        <Tabs.TabPane
          tab={
            <TabTitle
              activeTab={currentTab === 'internal'}
              showZero
              title={t('tab.internal')}
              count={totalItemsInternal}
            />
          }
          key="internal"
        >
          <OutboundInternalShipments
            onRowSelectionChange={onRowSelectionChange}
            selectedRowKeys={selectedRowKeys}
            showFilter={showFilter}
            outboundShipmentType="Internal"
            isNetworkShip={isNetworkShip}
            outboundShipParam={outboundShipParam}
            considerAsInbound={considerAsInbound}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <TabTitle
              activeTab={currentTab === 'wholechain'}
              showZero
              title={t('tab.wholechain')}
              count={totalItemsWholechain}
            />
          }
          key="wholechain"
        >
          <OutboundInternalShipments
            onRowSelectionChange={onRowSelectionChange}
            selectedRowKeys={selectedRowKeys}
            showFilter={showFilter}
            outboundShipmentType="Wholechain"
            isNetworkShip={isNetworkShip}
            outboundShipParam={outboundShipParam}
            considerAsInbound={considerAsInbound}
          />
        </Tabs.TabPane>
        {!hideOtherSystem && (
          <Tabs.TabPane
            tab={
              <TabTitle
                activeTab={currentTab === 'other_systems'}
                showZero
                title={t('tab.other_systems')}
                count={totalItemsOtherSystems}
              />
            }
            key="other_systems"
          >
            <OutboundInternalShipments
              onRowSelectionChange={onRowSelectionChange}
              selectedRowKeys={selectedRowKeys}
              showFilter={showFilter}
              outboundShipmentType="OtherSystem"
              isNetworkShip={isNetworkShip}
              outboundShipParam={outboundShipParam}
              considerAsInbound={considerAsInbound}
            />
          </Tabs.TabPane>
        )}
      </Tabs>
      {/* Outlet used to render the drawers */}
      <Outlet />
    </>
  );
};

export default React.memo(ShipmentOutbound);

// @ts-nocheck
/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { GraphData, NodeConfig } from '@antv/g6/lib/types';
import { Form, message } from 'antd';
import { useActions } from 'hooks';
import moment from 'moment';
import {
  Header,
  TransformDataItem,
  TransformFormFields,
  useEventActions,
  useEventsStore,
  useTitle,
} from 'pages/Events';
import { ProductInventoryItem } from 'pages/Products';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTransformRequest, EnrichedWorkflowTransformEvent } from 'services/api/client/src';
import {
  dateFormat,
  dateLocaleFormat,
  errorHandler,
  getLocationById,
  getProductById,
  getProductNameById,
  getTimezoneOffset,
  getUom,
  timeLocaleFormat,
} from 'utils';
import styles from '../index.module.less';
import { EventProps, WorkflowEvent } from '../typings';

type DataItem = TransformDataItem;
const Transform: FC<EventProps> = ({
  form,
  drawer,
  event,
  isVisible,
  selectedInstances,
  instances,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.transform' });
  useTitle(t('title'));
  const locationDisabled = useMemo(() => (selectedInstances?.length || 0) > 0, [selectedInstances]);
  const [mainForm] = Form.useForm<DataItem>();
  const { setFieldsValue } = mainForm;
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const graphData: GraphData = form?.getFieldValue('graphData') || {
    nodes: [],
    edges: [],
  };

  const [ipProducts, setIpProducts] = useState<Array<ProductInventoryItem>>([]);

  const {
    product: dataProduct,
    disabledDate,
    disabledTime,
    onChangeDate,
    onChangeTemplate,
    defaultTimezone,
    isSerial,
    identifier,
    lastEventDate,
    locationId,
    defaultUom,
    onChangeLocation,
  } = useEventActions({
    productId: event?.productId || undefined,
    locationId: selectedInstances?.[0]?.locationId || event?.locationId || undefined,
    eventDate: dateLocaleFormat(selectedInstances?.[0]?.eventDate || new Date()) || '',
  });
  const { hasAdvancedSettings, dataEntryMethod, template, setSubmitting, setAdvancedSettings } =
    useEventsStore();

  const {
    products,
    locations,
    template: initTemplate,
  } = useActions({
    templateId: event?.eventData?.templateId || event?.eventTemplateId,
  });
  const onMount = useCallback(() => {
    setAdvancedSettings(false);
    if (event) {
      const edata = event?.eventData as EnrichedWorkflowTransformEvent;
      const inputProducts =
        edata?.newInputProducts?.map((p) => ({
          id: p?.lotSerial || '',
          name: p?.productName || '',
          productId: p?.productId || '',
          parentProductId: p?.productId || '',
          // @ts-ignore
          primaryId: p?.instanceId || '',
          lotSerial: p?.lotSerial || '',
          lotID: p?.lotSerial || '',
          quantity: p?.quantity || 0,
          unitOfMeasure: p?.unitOfMeasure || 'LBS',
        })) || [];
      setIpProducts(inputProducts);
      setFieldsValue({
        date: edata?.eventTime ? dateFormat(edata?.eventTime) : undefined,
        time: edata?.eventTime ? timeLocaleFormat(edata?.eventTime) : undefined,
        ...(edata?.locationId && { location: edata?.locationId }),
        poNumber: edata?.purchaseOrder,
        bizStep: edata?.bizStep || 'urn:epcglobal:cbv:bizstep:commissioning',
        disposition: edata?.disposition || 'urn:epcglobal:cbv:disp:active',
        timeZone: defaultTimezone,
        customData: event?.eventTemplateId || edata?.templateId || undefined,
        ipProducts: inputProducts,
        opProducts:
          edata?.newOutputProducts?.map((p) => ({
            id: p?.lotSerial || '',
            name: p?.productName || '',
            productId: p?.productId || '',
            parentProductId: p?.productId || '',
            primaryId: p?.lotSerial || '',
            lotSerial: p?.lotSerial || '',
            lotID: p?.lotSerial || '',
            quantity: p?.quantity || 0,
            unitOfMeasure: p?.unitOfMeasure || 'LBS',
          })) || [],
      });
    }
  }, [event, setAdvancedSettings, setFieldsValue, defaultTimezone]);
  const onGetInitTemplate = useCallback(() => {
    if (event && initTemplate) {
      const edata = event?.eventData as EnrichedWorkflowTransformEvent;
      const templateId = edata?.templateId || event?.eventTemplateId || '';
      onChangeTemplate?.(
        mainForm,
        {
          itemProps: initTemplate,
        },
        templateId,
        locationId,
      );
      if (edata) {
        setFieldsValue({
          customProperties: edata?.customProperties?.map((el) => el?.value || '') || [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTemplate, event]);
  /* effect to sync eventData in event */
  useEffect(() => onMount(), [onMount]);
  useEffect(() => onGetInitTemplate(), [onGetInitTemplate]);

  const onCloseDrawer = () => {
    mainForm?.resetFields();
    drawer?.closeDrawer();
  };
  const onCreateStoreInstance = (p: ProductInventoryItem) => {
    const instanceExists = instances?.find((instance) => instance?.id === p?.id);
    if (!instanceExists) {
      const newInstances = [...(instances || []), p];
      form?.setFieldsValue({
        instances: newInstances,
      });
    } else {
      form?.setFieldsValue({
        instances: instances?.map((instance) => {
          if (instance?.id === p?.id) {
            return p;
          }
          return instance;
        }),
      });
    }
  };
  const onCSVSubmit = async (formData: DataItem) => {
    const values = formData?.csvData;
    try {
      if (values?.length) {
        await editableTableFormRef.current?.validateFields();

        const requests: Array<CreateTransformRequest> = [];
        values?.forEach((value) => {
          const reqData: CreateTransformRequest = {
            eventTime: new Date(moment(`${value.date} ${value.time}`).toISOString()),
            eventTimeZone: getTimezoneOffset(value.timeZone),
            bizStep: value.bizStep,
            disposition: value.disposition,
            locationId: formData.location,
            purchaseOrder: value.poNumber,
            inputProducts: formData?.ipProducts?.map((product) => ({
              productId: product.parentProductId,
              instanceId: product.primaryId,
              quantity: isSerial ? 1 : Number(product.quantity || 0),
            })),
            outputProducts: formData?.csvData?.map((product) => ({
              productId: product.productId,
              lotSerial: product.lotID,
              quantity: isSerial ? 1 : Number(product.quantity || 0),
            })),
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            templateName: template?.name,
            customProperties: [
              ...(template?.templateFields?.map((field) => ({
                name: field?.name || field?.propertyName,
                value: String(value?.[String(field?.propertyName || field?.name || '')] || ''),
                namespace: field?.namespace?.prefix || 'wc',
              })) || []),
            ],
          };
          requests.push(reqData);
        });
        setSubmitting(false);
      } else {
        throw new Error('No data to submit');
      }
    } catch (error) {
      throw error;
    }
  };

  const onFormSubmit = async (formData: DataItem) => {
    try {
      const location = getLocationById(formData.location, locations);

      const updatedBody: EnrichedWorkflowTransformEvent = {
        id: event?.id,
        eventIdInTemplate: event?.id,
        eventId: event?.id,
        sourceId: selectedInstances?.map((i) => i?.id || '') || [],
        bizStep: formData.bizStep,
        disposition: formData.disposition,
        eventTime: new Date(moment(`${formData.date} ${formData.time}`).toISOString()),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        purchaseOrder: formData.poNumber,
        inputProducts: formData?.ipProducts?.map((p) => ({
          productId: p.parentProductId,
          instanceId: p.primaryId,
          quantity: p?.unitOfMeasure !== 'item' ? Number(p.quantity || 0) : 1,
        })),
        newInputProducts: formData?.ipProducts?.map((p) => ({
          productId: p.parentProductId,
          productName: p.name,
          lotSerial: p.lotSerial,
          instanceId: p.primaryId,
          quantity: p?.unitOfMeasure !== 'item' ? Number(p.quantity || 0) : 1,
          unitOfMeasure: p?.unitOfMeasure || 'item',
        })),
        outputProducts: formData?.opProducts?.map((p) => ({
          productId: p.parentProductId,
          productName: p.name,
          lotSerial: p.lotSerial,
          quantity: p?.unitOfMeasure !== 'item' ? Number(p.quantity || 0) : 1,
          unitOfMeasure: p?.unitOfMeasure || 'item',
        })),
        newOutputProducts: formData?.opProducts?.map((p) => ({
          productId: p.parentProductId,
          productName: p.name,
          lotSerial: p.lotSerial,
          quantity: p?.unitOfMeasure !== 'item' ? Number(p.quantity || 0) : 1,
          unitOfMeasure: p?.unitOfMeasure || 'item',
        })),
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        templateName: template?.name,
        customProperties:
          template?.templateFields?.map((field, idx) => ({
            name: field?.name || field?.propertyName,
            value: formData?.customProperties?.[idx]
              ? String(formData.customProperties?.[idx])
              : '',
            namespace: field?.namespace?.prefix || 'wc',
          })) || [],
      };

      /* create instance in quantities */
      formData?.opProducts?.map((p) =>
        onCreateStoreInstance({
          id: event?.id,
          name: getProductNameById(p.parentProductId, products),
          productId: p.parentProductId,
          instanceId: event?.id,
          primaryId: p.lotSerial,
          lotSerial: p.lotSerial,
          quantity: p?.unitOfMeasure !== 'item' ? Number(p.quantity || 0) : 1,
          unitOfMeasure: p?.unitOfMeasure || 'LBS',
          eventName: 'Transform',
          location: location?.name || '',
          locationAddress: location?.address?.line2 || '',
          locationId: formData.location || '',
          destinationId: undefined,
          eventId: event?.id,
          isContainer: false,
          containerId: undefined,
          containerIdentifier: '',
          containerItems: [],
          tradePartnerName: location?.tradePartnerName || '',
          address: location?.address,
          eventDate: updatedBody.eventTime,
          loggedDate: new Date(),
          purchaseOrder: formData.poNumber,
          shipmentType: undefined,
          shipped: false,
          productIdentifierType: getProductById(p.parentProductId)?.productIdentifierType,
          templateId: template?.id,
        }),
      );
      // @ts-ignore
      const updatedNodes: Array<NodeConfig> = graphData.nodes?.map((item: WorkflowEvent) => {
        if (item.id === event?.id) {
          const updatedNode: NodeConfig = {
            id: item?.id || '',
            eventType: 'Transform',
            eventTime: updatedBody?.eventTime ? dateFormat(updatedBody?.eventTime) : undefined,
            name: getProductNameById(formData.opProducts?.[0]?.parentProductId, products),
            primaryId: formData.opProducts?.[0]?.lotSerial,
            quantity: formData.opProducts?.[0]?.quantity,
            unitOfMeasure: formData.opProducts?.[0]?.unitOfMeasure,
            eventTemplateId: item?.eventTemplateId,
            eventTemplateName: location?.address?.line2 || location?.name || '',
            locationId: formData?.location,
            locationName: location?.name || '',
            productId: formData.opProducts?.[0]?.parentProductId,
            productName: getProductNameById(formData.opProducts?.[0]?.parentProductId, products),
            colourIndex: 'colour_4',
            children: [],
          };
          return {
            ...item,
            ...updatedNode,
            eventData: updatedBody,
          };
        }
        return item;
      });
      const updatedGraphData: GraphData = {
        ...graphData,
        nodes: updatedNodes,
      };

      form?.setFieldsValue({
        graphData: updatedGraphData,
      });
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);

      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
        setSubmitting(false);
      } else {
        await onCSVSubmit(formData);
      }
      onCloseDrawer();
    } catch (error: any) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      key={event?.id}
      form={mainForm}
      layoutType="DrawerForm"
      columns={TransformFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        lastEventDate,
        defaultUom,
        defaultTimezone,
        editableTableFormRef,
        isSerial,
        identifier,
        disabledDate,
        onChangeDate,
        disabledTime,
        onChangeTemplate,
        selectedInstances: (selectedInstances?.length || 0) > 0 ? selectedInstances : ipProducts,
      })}
      grid
      rowProps={{ gutter: 32 }}
      onFinish={onFinish}
      // @ts-ignore
      title={
        <Header
          isManual={dataEntryMethod === 'manual'}
          title={t('title')}
          form={mainForm}
          locationId={selectedInstances?.[0]?.locationId}
          locationDisabled={locationDisabled}
          onChangeLocation={onChangeLocation}
        />
      }
      submitter={{
        searchConfig: {
          submitText: t('add_event_title'),
          resetText: t('cancel_btn_title'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
      }}
      initialValues={{
        unitOfMeasure: getUom({ product: dataProduct }),
      }}
      visible={isVisible ? drawer?.visible || false : false}
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        className: styles.eventdrawer,
        ...drawer,
      }}
    />
  );
};

export default Transform;

import { SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import timezones from 'utils/timezones';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

const TimezoneSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const { t } = useTranslation('pages', { keyPrefix: 'select.gselect' });
  // we create here the list with the shape the select is expecting for
  const options: Array<DefaultOptionType> =
    timezones?.map((option) => ({
      label: String(option?.text || ''),
      value: String(option.text),
    })) || [];

  const fetchData: FetchDataFn<ValueType> = useCallback(() => {}, []);

  return (
    <GSelect<ValueType, OptionType>
      placeholder={t('select_timezone')}
      {...props}
      options={options as OptionType[]}
      fetchData={fetchData}
      allowFilterOption
    />
  );
};

export default TimezoneSelect;

import { SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useState } from 'react';
import { useTemplates } from 'services/api';
import { GetEventTemplatesOptionalParams } from 'services/api/client/src';
import { useDebouncedCallback } from 'use-debounce';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

const TemplateSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const [params, setParams] = useState<GetEventTemplatesOptionalParams>({
    pageNumber: 1,
    pageSize: 100,
  });
  const { data, isLoading } = useTemplates(params);

  // we create here the list with the shape the select is expecting for
  const templateOptions: Array<DefaultOptionType> =
    data?.results?.map((template) => ({
      label: String(template?.templateName || ''),
      value: String(template.id),
      itemProps: template,
    })) || [];

  const debounced = useDebouncedCallback(
    // function
    (filterText) => {
      setParams((oldParams) => ({ ...oldParams, templateName: filterText }));
    },
    // delay in ms
    1000,
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );

  return (
    <GSelect<ValueType, OptionType>
      placeholder="Select Template"
      {...props}
      loading={isLoading}
      options={templateOptions as OptionType[]}
      fetchData={fetchData}
      allowFilterOption={false}
    />
  );
};

export default TemplateSelect;

import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Form } from 'antd';
import { ActionButtons, GTable } from 'components';
import React, { FC, useCallback, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { CertDocumentsProps } from '../Forms';
import { CertificationInfo } from './typings';

type ColumnsType = {
  onEditRow: (actionPayload?: CertificationInfo) => void;
  onDelRow: (actionPayload?: CertificationInfo) => void;
  hasAdvanceSettings?: boolean;
  t?: TFunction<'pages', 'products'>;
};

const columns = ({ onEditRow, onDelRow, t }: ColumnsType): Array<ProColumns<CertificationInfo>> => [
  {
    title: t?.('cert_type'),
    dataIndex: 'certificationType',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_type_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_type'),
      size: 'small',
    },
    ellipsis: true,
  },
  {
    title: t?.('cert_standard'),
    dataIndex: 'certificationStandard',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_standard_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_standard'),
      size: 'small',
    },
    ellipsis: true,
  },
  {
    title: t?.('cert_agency'),
    dataIndex: 'certificationAgency',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_agency_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_agency'),
      size: 'small',
    },
    ellipsis: true,
  },
  {
    title: t?.('cert_value'),
    dataIndex: 'certificationValue',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_value_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_value'),
      size: 'small',
    },
    ellipsis: true,
  },
  {
    title: t?.('cert_id'),
    dataIndex: 'certificationIdentification',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_id_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_id'),
      size: 'small',
    },
    ellipsis: true,
  },

  {
    dataIndex: 'actions',
    valueType: 'option',
    width: '7%',
    render: (text, record) => (
      <ActionButtons record={record} onEdit={onEditRow} onDelete={onDelRow} />
    ),
  },
];

const CertDocuments: FC<CertDocumentsProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });

  const attributes: Array<CertificationInfo> = Form.useWatch?.('certificationList', form) || [];
  const { setFieldsValue } = form;
  const setAttributes = useCallback(
    (value: Array<CertificationInfo>) =>
      setFieldsValue({
        certificationList: value,
      }),
    [setFieldsValue],
  );
  const actionRef = useRef<ActionType>();

  const onEditRow = (actionPayload?: CertificationInfo) => {
    actionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onDelRow = async (actionPayload?: CertificationInfo) => {
    if (actionPayload) setAttributes(attributes.filter((item) => item.id !== actionPayload.id));
  };

  return (
    <GTable<CertificationInfo>
      actionRef={actionRef}
      columns={columns({ onEditRow, onDelRow, t })}
      editable={{
        onCancel: async (_rowKey, data) => {
          actionRef.current?.cancelEditable(data?.id || 0);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={() => {
        actionRef.current?.addEditRecord?.({
          id: Date.now().toString(),
        });
      }}
      value={attributes}
      onChange={setAttributes}
      enableRecordCreator
    />
  );
};

export default React.memo(CertDocuments);

//
import { PlusOutlined } from '@ant-design/icons';
import { message, Typography, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventTemplatesResponse } from 'services/api/client/src';
import { globalStyles } from 'utils';
import styles from './index.module.less';

type DocumentType = {
  template: EventTemplatesResponse;
  event?: string;
};

const Documents = ({ template, event }: DocumentType) => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates.preview' });
  const onUpload: UploadProps['customRequest'] = async ({
    file,
    onProgress,
    onSuccess,
    onError,
  }) => {
    try {
      onProgress?.({ percent: 0 });
      setTimeout(() => onProgress?.({ percent: 50 }), 1000);
      onProgress?.({ percent: 100 });

      onSuccess?.(file);
    } catch (error: any) {
      onError?.({
        name: 'upload-error',
        message: error.message,
      });
    }
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    accept: '.pdf, application/pdf',
    customRequest: onUpload,
    onChange: (info: UploadChangeParam<UploadFile<any>>) => {
      const { file } = info;
      const { status } = file;
      switch (status) {
        case 'done':
          message.success(`${info.file.name} ${t('file_upload_success_msg')}`);
          if (file?.originFileObj) {
            const blobUrl = URL.createObjectURL(file.originFileObj);
            file.url = blobUrl;
          }
          break;
        case 'error':
          message.error(`${info.file.name} ${t('file_upload_error_msg')}`);
          break;
        default:
          break;
      }
    },
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={globalStyles.mt8}>{t('upload')}</div>
    </div>
  );
  const isReqDocuments = useMemo(
    () =>
      template &&
      (template?.templateDocuments?.filter((el) => el && (el?.required || false))?.length || 0) > 0,
    [template],
  );
  const isOptionalDocuments = useMemo(
    () =>
      template &&
      (template?.templateDocuments?.filter((el) => el && !el?.required)?.length || 0) > 0,
    [template],
  );

  const requestDocuments = useMemo(
    () =>
      template?.templateDocuments
        ?.filter((el) => el?.required || false)
        .map((el) => el?.documentName || '')
        .join(', '),
    [template],
  );
  const optionalDocuments = useMemo(
    () =>
      template?.templateDocuments
        ?.filter((el) => !el?.required)
        .map((el) => el?.documentName || '')
        .join(', '),
    [template],
  );

  return (
    <div>
      <Typography.Text className={classNames('ant-pro-form-group-title', styles.documentsection)}>
        {t('documents_title')}
      </Typography.Text>
      {isReqDocuments && event !== 'Aggregation' && (
        <div className={classNames('ant-col ant-form-item-label', styles.reqdocumentsection)}>
          <label
            htmlFor="documents"
            className="ant-form-item-required"
            title={t('requested_documents')}
          >
            {t('requested_documents')} : {requestDocuments}
          </label>
        </div>
      )}
      <br />
      {isOptionalDocuments && event !== 'Aggregation' && (
        <div>
          {t('optional_documents')} : {optionalDocuments}
        </div>
      )}

      <div className={styles.content}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          disabled
          {...props}
        >
          {uploadButton}
        </Upload>
      </div>
    </div>
  );
};

Documents.defaultProps = {
  event: 'Commission',
};
export default Documents;

import { Space, Switch } from 'antd';
import { useEventsStore } from 'pages/Events/hooks';
import { getProductsT } from 'utils';

const MultipleDates = () => {
  const { multipleDates, setMultipleDates } = useEventsStore();

  return (
    <Space>
      {getProductsT('multiple_dates')}
      <Switch checked={multipleDates} onChange={setMultipleDates} size="small" />
    </Space>
  );
};

export default MultipleDates;

import { Button, Empty, Typography } from 'antd';
import { productsEmptySvg } from 'assets';
import { EmptyStateProps } from 'pages/Dashboard/components/typings';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Represents the empty state component for products.
 * @param t Translation function.
 * @returns JSX element representing the empty state component.
 */
const EmptyStateProducts: FC<EmptyStateProps> = ({ t }) => {
  const navigate = useNavigate();
  return (
    <Empty
      image={productsEmptySvg}
      description={<Typography.Text>{t?.('empty_title')}</Typography.Text>}
      className="empty-container"
    >
      <Button type="primary" shape="round" ghost onClick={() => navigate('add')}>
        {t?.('empty_add')}
      </Button>
    </Empty>
  );
};
export default React.memo(EmptyStateProducts);

import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps, RowProps } from 'antd';
import { MultipleDatesFormItem } from 'components';
import { TimezoneSelect } from 'components/GSelect';
import PhoneCodeSelect from 'components/GSelect/PhoneCodeSelect';
import PlacesSelect from 'components/GSelect/PlacesSelect';
import { AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { ChangeEvent } from 'react';
import { checkIfCodeEnteredInPhoneNumber, PhoneCode } from 'utils';
import { CreateAccountFormFieldProps, DataItem } from './typings';

const rowProps: RowProps = { gutter: [24, 24] };

const colProps8: ColProps = { xs: 24, sm: 24, md: 24, lg: 8 };
// const colProps16: ColProps = { xs: 24, sm: 16, md: 16, lg: 16 };
// const colProps12: ColProps = { xs: 24, sm: 12, md: 12, lg: 12 };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };

const CreateAccountFormFields = ({
  t,
  onChangePlace,
}: CreateAccountFormFieldProps): ProFormColumnsType<DataItem>[] => [
  {
    dataIndex: 'isGS1Member',
    initialValue: false,

    fieldProps: {
      size: 'small',
    },
    renderFormItem: (_schema, _config, form) => (
      <MultipleDatesFormItem
        title={t?.('form_fields.gs1_member_title')}
        // tooltip={
        //   <div className={styles.tooltiplink}>
        //     {t?.('form_fields.gs1_member_tooltip')}
        //     <Typography.Link target="_blank" href={gs1MemberURL}>
        //       &nbsp;{t?.('form_fields.gs1_member_link')}
        //     </Typography.Link>
        //   </div>
        // }
        form={form}
        dataIndex="isGS1Member"
      />
    ),
    colProps: colProps24,
  },

  {
    valueType: 'dependency',
    fieldProps: {
      name: ['isGS1Member', 'phoneISO'],
    },
    columns: ({ isGS1Member, phoneISO }: DataItem) => [
      {
        valueType: 'group',
        columns: [
          {
            dataIndex: 'name',
            title: t?.('form_fields.company_name_title'),
            formItemProps: {
              rules: [
                {
                  required: true,
                  message: t?.('form_fields.company_name_req'),
                },
              ],
            },
            fieldProps: {
              placeholder: t?.('form_fields.company_name_placeholder'),
            },
            colProps: colProps8,
          },
          {
            dataIndex: 'address',
            title: t?.('form_fields.company_address_title'),
            fieldProps: {
              placeholder: t?.('form_fields.company_address_placeholder'),
            },
            renderFormItem: () => <PlacesSelect onChange={onChangePlace} allowClear />,

            colProps: colProps8,
          },
          {
            dataIndex: 'timezone',
            title: t?.('form_fields.timezone_title'),
            tooltip: {
              icon: <InfoCircleOutlined />,
              title: t?.('form_fields.timezone_tooltip'),
              key: null,
              type: '',
              props: null,
            },
            formItemProps: {
              rules: [
                {
                  required: true,
                  message: t?.('form_fields.timezone_req'),
                },
              ],
            },
            fieldProps: {
              placeholder: t?.('form_fields.timezone_placeholder'),
            },
            renderFormItem: () => <TimezoneSelect />,

            colProps: colProps8,
          },
        ],
        rowProps,
      },

      {
        valueType: 'group',
        columns: [
          {
            dataIndex: 'phone',
            title: t?.('form_fields.phone_number_title'),
            fieldProps: (form) => ({
              placeholder: t?.('form_fields.phone_number_placeholder'),
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                const countryCode = form?.getFieldValue?.('phoneISO') || 'US';
                let value = new AsYouType(countryCode).input(e.target.value);
                value = checkIfCodeEnteredInPhoneNumber(value);
                form?.setFieldsValue({
                  phone: value,
                });
              },
              addonBefore: (
                <PhoneCodeSelect
                  onChange={(val, option) => {
                    // @ts-ignore
                    const item: PhoneCode = option?.itemProps;
                    form?.setFieldsValue({
                      phoneCode: `+${String(item?.code || '').replace('+', '')}`,
                      phoneISO: item.iso,
                    });
                  }}
                />
              ),
            }),
            formItemProps: {
              rules: [
                {
                  message: t?.('form_fields.phone_number_valid'),
                  validator: (_, value) => {
                    if (!value?.trim?.()) {
                      return Promise.resolve();
                    }
                    if (isValidPhoneNumber(value, phoneISO as CountryCode)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t?.('form_fields.phone_number_valid')));
                  },
                },
              ],
            },
            colProps: colProps8,
          },
          {
            dataIndex: 'coordinates',
            title: t?.('form_fields.cordinates_title'),
            fieldProps: {
              placeholder: t?.('form_fields.cordinates_placeholder'),
            },
            formItemProps: {
              rules: [
                {
                  required: true,
                  message: t?.('form_fields.cordinates_req'),
                },
                {
                  whitespace: true,
                  message: t?.('form_fields.cordinates_req'),
                },
                {
                  pattern:
                    /^([-+]?(?:[0-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:1[0-7]\d(?:\.\d+)?|180(?:\.0+)?|[0-9]{1,2}(?:\.\d+)?))$/,
                  message: t?.('form_fields.cordinates_req'),
                },
              ],
            },
            colProps: colProps8,
          },
          {
            dataIndex: 'gln',
            title: t?.('form_fields.gln_title'),
            hideInForm: !isGS1Member,
            fieldProps: {
              placeholder: t?.('form_fields.gln_placeholder'),
            },
            formItemProps: {
              rules: [
                {
                  required: true,
                  message: t?.('form_fields.gln_req'),
                },
              ],
            },
            colProps: colProps8,
          },
        ],
        rowProps,
      },

      /* hidden fields */
      {
        dataIndex: 'addressCity',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
      },
      {
        dataIndex: 'addressCountry',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
      },
      {
        dataIndex: 'addressState',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
      },
      {
        dataIndex: 'addressLine1',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
      },
      {
        dataIndex: 'addressLine2',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
      },
      {
        dataIndex: 'addressPostalCode',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
      },
      {
        dataIndex: 'phoneCode',
        initialValue: '+1',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
      },
      {
        dataIndex: 'phoneISO',
        initialValue: 'US',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
      },
    ],
  },
];

export default CreateAccountFormFields;

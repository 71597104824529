import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message } from 'antd';
import useTableRowSelection from 'hooks/useTableRowSelection';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAcceptNetworkInvite, useRejectNetworkInvite } from 'services/api/useTradePartners';
import errorHandler from 'utils/errorHandler';
import { DataItem, MyMultiLocationTableColumns } from './MyMultiLocationTable.fields';
import styles from './index.module.less';
import { InviteShareProps } from './typings';

const InviteShareLocForm: FC<InviteShareProps> = ({ modal, inviteRequest }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.invite.invite_share_loc' });
  const modalFormRef = useRef<ProFormInstance>();
  const { selectedRowKeys, onRowSelectionChange } = useTableRowSelection();
  const queryClient = useQueryClient();
  const rejectNetworkInvite = useRejectNetworkInvite(queryClient);
  const acceptNetworkInvitation = useAcceptNetworkInvite(queryClient);
  const note: string = t(`note`, { company: inviteRequest?.companyName });
  const isMyLocations = true;
  const [isEmpty, setEmpty] = useState(false);
  const { setInvitePopup } = useShipmentStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
    setInvitePopup(false);
  }, [modal, setInvitePopup]);

  const onAcceptInvite = async () => {
    if (selectedRowKeys.length > 0) {
      try {
        setLoading(true);
        const res = await acceptNetworkInvitation.mutateAsync({
          id: inviteRequest?.id || '',
          options: {
            body: {
              locationIds: selectedRowKeys as string[],
            },
          },
        });
        if (res?.id) {
          message.success(t('accept_success'));
          message.success(t('share_success', { company: res?.companyName }));
          setLoading(false);
          closeModal();
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
        setLoading(false);
      }
    } else {
      message.error(t('locations_req'));
    }
  };

  const onRejectInvite = async () => {
    try {
      await rejectNetworkInvite.mutateAsync({ id: inviteRequest?.id || '' });
      message.success(t('reject_success'));
      closeModal();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  const onEmptyLocation = useCallback((isEmptyLocations: boolean) => {
    setEmpty(isEmptyLocations);
  }, []);

  const onFinish = async () => {
    if (isEmpty) {
      navigate('/network/add_location');
    } else {
      await onAcceptInvite();
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      title={t('modal_title', { company: inviteRequest?.companyName })}
      formRef={modalFormRef}
      columns={MyMultiLocationTableColumns({
        selectedRowKeys,
        onRowSelectionChange,
        note,
        isMyLocations,
        onEmptyLocation,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        className: styles.invitemodal,
        destroyOnClose: false,
        width: '40%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: isEmpty ? t('add_location') : t('accept'),
          resetText: t('reject'),
        },
        submitButtonProps: {
          shape: 'round',
          disabled: rejectNetworkInvite.isLoading,
          loading,
        },
        resetButtonProps: {
          shape: 'round',
          className: styles.canceltext,
          loading: rejectNetworkInvite.isLoading,
          disabled: loading,
          onClick: () => onRejectInvite(),
        },
        render: (_, defaultDoms) => defaultDoms,
      }}
      initialValues={{
        connectionType: 'Buyer',
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default React.memo(InviteShareLocForm);

import { message, Space } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useResolveOrphanEvent } from 'services/api';
import { ResolveOrphanOptionalParams } from 'services/api/client/src';
import { errorHandler } from 'utils';

const useReportsActions = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'reports' });
  const queryClient = useQueryClient();
  const resolveOrphan = useResolveOrphanEvent(queryClient);

  const onResolveOrphan = useCallback(
    async (orphanInstanceId: string, productInstanceId: string, needsTransform?: boolean) => {
      const success = message.loading(t('resolving_orphan'), 0);
      try {
        const options: ResolveOrphanOptionalParams = {
          needsTransform,
        };
        await resolveOrphan.mutateAsync({
          orphanInstanceId,
          instanceId: productInstanceId,
          options,
        });
        message.success(<Space>{t('orphan_resolved_success')}</Space>);
        success();
      } catch (error) {
        success();
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [t, resolveOrphan],
  );

  return {
    onResolveOrphan,
  };
};
export default useReportsActions;

import ProList, { ProListMetas } from '@ant-design/pro-list';
import { Avatar, Button, Typography } from 'antd';
import classNames from 'classnames';
import { useNotificationFilters } from 'pages/Dashboard';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTParamOrReturnBackup } from 'utils';
import getObjectFromParam from 'utils/notifications';
import styles from './index.module.less';
import { NotificationBoxProps, NotificationItem } from './typings';

const RecentActivity: FC<NotificationBoxProps> = ({ setHasRecentActivity }) => {
  const {
    records,
    isLoading,
    isFetchingNextPage,
    disableSeeMore,
    disableClear,
    onClear,
    onViewMore,
    onItem,
  } = useNotificationFilters({
    pageNumber: 1,
    pageSize: 5,
    type: 'RecentActivity',
  });
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard.notification' });
  const [clearNotificationsControl, setClearNotificationsControl] = useState(disableClear);
  const [seeMoreControl, setSeeMoreControl] = useState(false);

  useEffect(() => {
    if (disableClear !== clearNotificationsControl && seeMoreControl === false) {
      setClearNotificationsControl(disableClear);
      if (setHasRecentActivity) setHasRecentActivity(true);
    }
    setSeeMoreControl(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableClear, records]);

  const listMetas: ProListMetas<NotificationItem> = {
    avatar: {
      dataIndex: 'avatar',
      render: (dom, record) => (
        <Avatar
          className={!record?.sender?.profileImageUrl ? styles.ravatar : undefined}
          src={record?.sender?.profileImageUrl ? record?.sender?.profileImageUrl : undefined}
        >
          {record?.sender?.givenName?.[0] || 'U'}
        </Avatar>
      ),
    },
    title: {
      dataIndex: 'title',
      render: (text, record) => (
        <Typography.Paragraph
          className={styles.ntitle}
          ellipsis={{
            rows: 3,
          }}
        >
          {`${getTParamOrReturnBackup(`${record?.message}`, `${record?.messageBackup}`, {
            ...getObjectFromParam(record?.messageParams?.split(';')),
          })} `}
        </Typography.Paragraph>
      ),
    },
    description: {
      dataIndex: 'description',
      render: (text, record) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 3,
          }}
          className={styles.listdesc}
          type="secondary"
        >
          {`${getTParamOrReturnBackup(`${record?.description}`, `${record?.descriptionBackup}`, {
            ...getObjectFromParam(record?.descriptionParams?.split(';')),
          })} `}
        </Typography.Paragraph>
      ),
    },
  };

  const updateClearNotifications = () => {
    setClearNotificationsControl(true);
    if (setHasRecentActivity) setHasRecentActivity(false);
    onClear();
  };

  const updateSeeMore = () => {
    setSeeMoreControl(true);
    onViewMore();
  };

  return (
    <div>
      <ProList
        loading={!records?.length ? isLoading : undefined}
        itemLayout="horizontal"
        rowKey="id"
        split
        dataSource={records}
        metas={listMetas}
        className={styles.list}
        rowClassName={(row: NotificationItem) =>
          classNames(styles.item, {
            [styles.read]: row.read,
          })
        }
        onItem={onItem}
        ghost
      />
      <div className={styles.bottombar}>
        <Button
          onClick={updateClearNotifications}
          type="text"
          className={styles.footerbtn}
          disabled={clearNotificationsControl}
        >
          {t('clear_notification')}
        </Button>
        <Button
          onClick={updateSeeMore}
          type="text"
          className={styles.footerbtn}
          disabled={disableSeeMore}
          loading={isFetchingNextPage}
        >
          {t('see_more')}
        </Button>
      </div>
    </div>
  );
};
export default React.memo(RecentActivity);

import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLocationStore from '../../hook/useLocationStore';
import { useTab } from './hooks';

type TabPosition = 'document' | 'attribute';

const SwitchButton: React.FC = () => {
  const [mode, setMode] = useState<TabPosition>('document');
  const { t } = useTranslation('pages', { keyPrefix: 'network.table_tabs' });
  const handleModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value);
  };
  const locationData = useLocationStore();
  useTab(mode);

  return (
    <div>
      <Radio.Group onChange={handleModeChange} value={mode}>
        <Radio.Button value="document">{t('document')}</Radio.Button>
        <Radio.Button value="attribute">{t('attribute')}</Radio.Button>
        {!locationData?.isLocationView && (
          <Radio.Button value="location">{t('locations')}</Radio.Button>
        )}
      </Radio.Group>
    </div>
  );
};

export default SwitchButton;

import { ProColumns } from '@ant-design/pro-table';
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTemplates, useTradePartners } from 'services/api';
import { GetEventTemplatesOptionalParams } from 'services/api/client/src';
import { mapTemplateItem } from 'utils';
import { TemplateItem } from './typings';

const useTemplateFilters = (initParams?: GetEventTemplatesOptionalParams) => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates' });
  const [params, setParams] = useState<GetEventTemplatesOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    ...initParams,
  });
  const onSearch = (text: string) => {
    setParams({
      ...params,
      pageNumber: 1,
      templateName: text,
    });
  };
  const { data, isLoading, refetch } = useTemplates(params);
  const { data: dataTemplates } = useTemplates();
  const { data: allCompany } = useTradePartners();

  const totalItems = useMemo(() => data?.totalItems || 0, [data]);
  const netItemCount = useMemo(() => dataTemplates?.totalItems || 0, [dataTemplates]);

  const coreCompanyFilter: ProColumns['filters'] = useMemo(
    () =>
      allCompany?.data
        ?.filter((item) => item?.wholechainId !== null && !item?.isOwnerAccount)
        ?.map((item) => ({
          text: item.name || '',
          value: item.wholechainId || '',
        })) || [],
    [allCompany],
  );

  const { filters: templateSearchfilter } = useTableSearchFilter({
    title: t?.('search_template_name'),
    onSearch,
  });

  const records = useMemo<Array<TemplateItem>>(
    () => data?.results?.map(mapTemplateItem) || [],
    [data?.results],
  );

  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, Array<string> | null>,
      sorter: any,
    ) => {
      const apiFilters: GetEventTemplatesOptionalParams = {
        // @ts-ignore - need to fix the types
        roles: filters?.role?.length ? filters?.role : undefined,
        sharedWith: filters?.sharedWith?.length ? filters?.sharedWith : undefined,
      };
      if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
        setParams({
          ...params,
          ...apiFilters,
          sortBy: sorter?.field
            ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
            : undefined,
          pageNumber: 1,
        });
      } else {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: pagination?.current,
          pageSize: pagination?.pageSize,
        });
      }
    },
    [params, setParams],
  );

  return {
    params,
    setParams,
    totalItems,
    netItemCount,
    records,
    isLoading,
    coreCompanyFilter,
    onTableChange,
    refetch,
    filters: [templateSearchfilter],
  };
};
export default useTemplateFilters;

import { InteractionType } from '@azure/msal-browser';
import type { IMsalContext } from '@azure/msal-react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Loading } from 'components';
import { signinRequest } from 'config/authConfig';
import { ElementType, FC } from 'react';
import { ProtectedProps } from './typings';

const Protected: FC<ProtectedProps> = ({ children }) => (
  <MsalAuthenticationTemplate
    interactionType={InteractionType.Redirect}
    authenticationRequest={signinRequest}
    loadingComponent={Loading as ElementType<IMsalContext>}
  >
    {children}
  </MsalAuthenticationTemplate>
);

export default Protected;

import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Button } from 'antd';
import { useDrawerVisibility } from 'hooks';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DataItem, SearchDrawerColumns } from './SearchDrawer.fields';
import styles from './index.module.less';

const SearchDrawer: React.FC = () => {
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const modalFormRef = useRef<ProFormInstance>();
  const { t } = useTranslation('pages', { keyPrefix: 'global_search.search_drawer' });

  const onFinish = async () => {
    closeDrawer();
  };

  const onClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return (
    <BetaSchemaForm<DataItem>
      formRef={modalFormRef}
      visible={visible}
      layoutType="DrawerForm"
      drawerProps={{
        ...restDrawerVisibility,
        title: t('drawer_title'),
        placement: 'top',
        height: '100%',
        extra: (
          <Button type="primary" shape="round" onClick={onClose}>
            {t('done')}
          </Button>
        ),
        className: styles.drawer,
      }}
      grid
      onFinish={onFinish}
      submitter={{
        render: () => null,
      }}
      columns={SearchDrawerColumns()}
    />
  );
};

export default SearchDrawer;

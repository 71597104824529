import { SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useState } from 'react';
import { GetPaginatedDocumentsOptionalParams } from 'services/api/client/src';
import { useDocuments } from 'services/api/documents';
import { useDebouncedCallback } from 'use-debounce';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

// Note: document type select is currently disabled/not in use as it's part of Templates>Documents (which is currently disabled/not in use)
const DocumentTypeSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const [params, setParams] = useState<GetPaginatedDocumentsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
  });
  const { data, isLoading } = useDocuments(params);

  // we create here the list with the shape the select is expecting for
  const options: Array<DefaultOptionType> =
    data?.results?.map((item) => ({
      label: item.title,
      value: item.id,
      itemProps: item,
    })) || [];

  const debounced = useDebouncedCallback(
    // function
    (filterText) => {
      setParams((oldParams) => ({ ...oldParams, search: filterText }));
    },
    // delay in ms
    1000,
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );

  return (
    <GSelect<ValueType, OptionType>
      {...props}
      loading={isLoading}
      options={options as OptionType[]}
      fetchData={fetchData}
      allowFilterOption={false}
    />
  );
};

export default DocumentTypeSelect;

import { ArrowLeftOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Button, Modal, Space, Typography, message } from 'antd';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ShareEventTemplateRequest } from 'services/api/client/src';
import { useBulkUpdateDeleteShare, useShareTemplate } from 'services/api/useTemplates';
import { errorHandler } from 'utils';
import styles from './ShareTemplate.module.less';
import ShareTemplateFormFields from './ShareTemplateFormFields';
import { DataItem, ShareTemplateProps } from './typings';
import useShareTemplateStore from './useShareTemplateStore';

const ShareTemplate: FC<ShareTemplateProps> = ({ modal }) => {
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const { t } = useTranslation('pages', { keyPrefix: 'share_template' });
  const {
    template,
    templates,
    tradePartners: partners,
    etradePartners,
    setTradePartners: setPartners,
    setTemplates,
    setEtradePartners,
  } = useShareTemplateStore();

  const pendingChanges = useMemo(
    () => etradePartners.some((partner) => partner.isUpdated || partner.isDeleted),
    [etradePartners],
  );
  const queryClient = useQueryClient();
  const shareTemplate = useShareTemplate(queryClient);
  const bulkShareTemplate = useBulkUpdateDeleteShare(queryClient);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (loaded === false && templates?.length) {
      editableFormRef.current?.setFieldsValue({
        templates,
      });
      setLoaded(true);
    }
  }, [loaded, templates]);

  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
    setPartners([]);
    setTemplates([]);
    setEtradePartners([]);
    setLoaded(false);
  }, [setEtradePartners, setPartners, setTemplates]);

  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
  }, [modal, resetFields]);

  const onCancel = useCallback(() => {
    if (pendingChanges) {
      Modal.confirm({
        title: t('cancel_changes_title'),
        okText: t('cancel_changes_btn'),
        cancelText: t('cancel_changes_btn_cancel'),
        onOk: closeModal,
        centered: true,
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
      });
    } else {
      closeModal();
    }
  }, [closeModal, pendingChanges, t]);

  const onFinish = useCallback(
    async (formData: DataItem) => {
      try {
        /* share single template */
        if (template) {
          if (formData?.tradePartners?.length) {
            const reqBody: ShareEventTemplateRequest = {
              wholechainIds: partners,
              role: formData.permission,
            };
            await shareTemplate.mutateAsync({
              templateId: template?.id || '1',
              body: reqBody,
            });
            message.success(t('submit_sucess', { name: template?.name }));
          }
        }

        /* share multiple templates */
        if (templates?.length) {
          if (formData?.tradePartners?.length) {
            const reqBody: ShareEventTemplateRequest = {
              wholechainIds: partners,
              role: formData.permission,
            };
            await Promise.all(
              templates.map((templateId) =>
                shareTemplate.mutateAsync({
                  templateId,
                  body: reqBody,
                }),
              ),
            );
            message.success(t('submit_success_many', { count: templates?.length }));
          }
        }

        /* pending changes */
        if (pendingChanges) {
          await bulkShareTemplate.mutateAsync({
            templateId: template?.id || '1',
            users: etradePartners,
          });
          message.success(t('submit_pending_changes', { name: template?.name }));
        }

        closeModal();
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [
      template,
      templates,
      pendingChanges,
      closeModal,
      partners,
      shareTemplate,
      t,
      bulkShareTemplate,
      etradePartners,
    ],
  );

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={ShareTemplateFormFields({ t, partners, setPartners, templates, etradePartners })}
      grid
      // @ts-ignore
      title={
        <Space>
          {(partners?.length || 0) > 0 && (
            <Button type="text" size="small" onClick={resetFields}>
              <ArrowLeftOutlined />
            </Button>
          )}
          {t('share_template_title', { name: template?.name })}
        </Space>
      }
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '40%',
        ...modal,
        onCancel,
        className: styles.modal,
      }}
      submitter={{
        searchConfig: {
          // eslint-disable-next-line no-nested-ternary
          submitText: pendingChanges ? 'Save' : (partners?.length || 0) > 0 ? 'Send' : 'Done',
          resetText: 'Cancel',
        },
        resetButtonProps: {
          shape: 'round',
          onClick: closeModal,
          hidden: (partners?.length || 0) === 0,
          type: 'primary',
          ghost: true,
        },
        submitButtonProps: {
          shape: 'round',
        },
        render: (_, defaultDoms) => (
          <Space>
            <Typography.Text type="secondary">
              {pendingChanges ? 'Pending Changes' : ''}
            </Typography.Text>
            {defaultDoms[0]}
            {defaultDoms[1]}
          </Space>
        ),
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default ShareTemplate;

import { ProColumns } from '@ant-design/pro-table';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions, SingleColumnTags } from 'components';
import GTable from 'components/GTable';
import { ViewDocumentForm } from 'pages/Documents';
import SharedTag from 'pages/Documents/components';
import { useDocumentActions, useDocumentFilters } from 'pages/Documents/hooks';
import useLocationStore from 'pages/Network/hook/useLocationStore';
import pluralize from 'pluralize';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useParams } from 'react-router-dom';
import { getDocTypeFilters } from 'utils';
import { DocumentItem } from '../../../../Documents/typings';

interface Props {
  onShareDocument: (record: DocumentItem) => void;
}

const DocumentTable: FC<Props> = ({ onShareDocument }) => {
  const { locationId, companyId } = useParams();
  const [documentId, setDocumentId] = useState<string | undefined>('');
  const {
    params,
    records,
    totalItems,
    isLoading,
    filters: [documentSearchfilter],
    onTableChange,
    coreCompanyFilter,
    locationsFilter,
    productsFilter,
    refetch,
  } = useDocumentFilters(
    locationId
      ? {
          locationIds: [String(locationId)],
        }
      : {
          tradePartnerIds: [String(companyId)],
        },
  );
  const { onDownloadDocument, copyURL, onArchiveDocument } = useDocumentActions();
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });
  const { accountType } = useLocationStore();
  const handleActionsMenuClick = async (actionItemKey: string, actionPayload?: DocumentItem) => {
    switch (actionItemKey) {
      case 'view':
        setDocumentId(actionPayload?.id);
        break;
      case 'download':
        onDownloadDocument(actionPayload);
        break;
      case 'share':
        onShareDocument(actionPayload || {});
        break;
      case 'copyURL':
        copyURL(actionPayload);
        break;
      case 'archive':
        onArchiveDocument(actionPayload);
        break;

      default:
        break;
    }
  };

  const actionItems: ItemType[] = [
    {
      key: 'view',
      label: t('actions.view'),
    },
    {
      key: 'download',
      label: t('actions.download'),
      disabled: false,
    },
    ...(accountType === 'WC'
      ? [
          {
            key: 'share',
            label: t('actions.share'),
          },
        ]
      : []),
    {
      key: 'copyURL',
      label: t('actions.copy_url'),
      disabled: false,
    },
    {
      key: 'archive',
      label: t('actions.archive'),
      disabled: false,
    },
  ];

  const columns: ProColumns<DocumentItem>[] = [
    {
      title: t('columns.document_name'),
      dataIndex: 'title',
      fixed: 'left',
      render: (text, record) => (
        <Link
          className="text-link"
          onClick={(e) => {
            e.preventDefault();
            setDocumentId(record.id);
          }}
          to={{
            pathname: String(record?.id || '1'),
          }}
        >
          {text}
        </Link>
      ),
      width: 150,
      ...documentSearchfilter,
    },
    {
      title: t('columns.document_type.title'),
      dataIndex: 'documentType',
      filters: getDocTypeFilters(),
      width: 150,
    },
    {
      title: t('columns.company'),
      dataIndex: 'tradepartners',
      ellipsis: true,
      render: (text, record) => (
        <SingleColumnTags
          values={
            record?.associations
              ?.filter((item) => item?.type === 'Company')
              ?.map((item) => item?.label || '') || []
          }
          columnTitle={pluralize(t('columns.company'), 2)}
          modalTitle={record?.title || ''}
          colTableTitle={t('columns.company') || ''}
        />
      ),
      width: 200,
    },
    {
      title: t('columns.location'),
      dataIndex: 'locations',
      filters: locationId ? [] : locationsFilter,
      filterSearch: !locationId,
      ellipsis: true,
      render: (text, record) => (
        <SingleColumnTags
          values={
            record?.associations
              ?.filter((item) => item?.type === 'Location')
              ?.map((item) => item?.label || '') || []
          }
          columnTitle={pluralize(t('columns.location'), 2)}
          modalTitle={record?.title || ''}
          colTableTitle={t('columns.location') || ''}
        />
      ),
      width: 200,
    },
    {
      title: t('columns.product'),
      dataIndex: 'products',
      filters: productsFilter,
      filterSearch: true,
      ellipsis: true,
      render: (text, record) => (
        <SingleColumnTags
          values={
            record?.associations
              ?.filter((item) => item?.type === 'Product')
              ?.map((item) => item?.label || '') || []
          }
          columnTitle={pluralize(t('columns.product'), 2)}
          modalTitle={record?.title || ''}
          colTableTitle={t('columns.product') || ''}
        />
      ),
      width: 200,
    },
    {
      title: t('columns.shared.title'),
      dataIndex: 'shared',
      render: (text, record) => <SharedTag sharedWith={record.sharedWith} />,
      width: 150,
      ellipsis: true,
      filters: coreCompanyFilter,
      filterSearch: true,
    },
    {
      title: t('columns.recent'),
      dataIndex: 'recent',
      width: 150,
    },
    {
      title: t('columns.expiration'),
      dataIndex: 'expire',
      width: 150,
    },
    {
      title: t('columns.blockchain.title'),
      dataIndex: 'blockchain',
      width: 150,
    },
    {
      title: t('columns.visibility.title'),
      dataIndex: 'visibility',
      filters: [
        {
          text: t('columns.visibility.filters.private'),
          value: 'private',
        },
        {
          text: t('columns.visibility.filters.public'),
          value: 'public',
        },
      ],
      width: 150,
    },
    {
      title: t('columns.url'),
      dataIndex: 'url',
      ellipsis: true,
      copyable: true,
      width: 150,
    },
    {
      title: '',
      hideInSetting: true,
      render: (text, record) => (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          items={actionItems}
          onClick={handleActionsMenuClick}
          actionPayload={record}
        />
      ),
      fixed: 'right',
      width: 100,
    },
  ];

  const useViewDocClose = useCallback(() => setDocumentId(undefined), []);

  return (
    <>
      {!!documentId && <ViewDocumentForm docId={documentId} onModalClose={useViewDocClose} />}
      <GTable<DocumentItem>
        columns={columns}
        value={records}
        loading={isLoading}
        options={{
          setting: true,
          reload: () => refetch(),
        }}
        scroll={{ x: 'max-content', y: '200px' }}
        pagination={{
          defaultPageSize: params?.pageSize,
          total: totalItems,
          showTotal: (total, range) =>
            t('doc_table_pagi', { range0: range[0], range1: range[1], total }),
        }}
        // @ts-ignore
        onTableChange={onTableChange}
      />
      {/* Outlet used to render the drawers */}
      <Outlet />
    </>
  );
};

export default DocumentTable;

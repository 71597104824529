import { Select, SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useTranslation } from 'react-i18next';

const FieldTypeSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.select' });

  const fieldOptions: Array<DefaultOptionType> = [
    {
      value: 'Text',
      label: t('text'),
    },
    {
      value: 'Number',
      label: t('number'),
    },
    {
      value: 'Dropdown',
      label: t('dropdown'),
    },
    {
      value: 'RadioButton',
      label: t('radio_button'),
    },
    {
      value: 'Date',
      label: t('date'),
    },
  ];
  return (
    <Select {...props} options={fieldOptions as OptionType[]} placeholder={t('field_select')} />
  );
};

export default FieldTypeSelect;

import { Badge, Modal, Segmented, Space, Tabs, Typography } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SegmentedValue } from 'antd/lib/segmented';
import { ComingSoon, TitleBar } from 'components';
import { useModalVisibility } from 'hooks';
import useTableRowSelection from 'hooks/useTableRowSelection';
import { useEventActions } from 'pages/Events/components';
import { useEventsStore } from 'pages/Events/hooks';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, createSearchParams, useNavigate, useParams } from 'react-router-dom';
import { useProductById, useProductInstanceQuantities } from 'services/api/useProducts';
import {
  checkAllOrNoneContainers,
  dateFormat,
  getContainerIds,
  getMaxEventDate,
  isContainer,
  isSameKey,
  isShip,
} from 'utils';
import CurrentInventory from './CurrentInventory';
import HistoryInventory from './HistoryInventory';
import styles from './index.module.less';
import {
  GeneralActionItemsProps,
  ProductInventoryTitleProps,
  ProductItem,
  TabTitleProps,
} from './typings';

/**
 * Component to generate general action items based on the selected tab.
 */
const GeneralActionItems = ({
  t,
  selectedRowKeys,
  selectedRows,
  tab,
}: GeneralActionItemsProps): Array<ItemType> =>
  tab === 'current_inventory'
    ? [
        {
          key: 'events',
          label: t('current_inventory.events_grp'),
          type: 'group',
          children: [
            {
              key: 'commission',
              label: t('current_inventory.actions.commission'),
              disabled: selectedRowKeys.length > 0,
            },
            // {
            //   key: 'receive',
            //   label: t('current_inventory.actions.receive'),
            //   // disabled: !(selectedRowKeys.length === 1 && selectedRows?.[0]?.eventName === 'Ship'),
            // },
            {
              key: 'ship',
              label: t('current_inventory.actions.ship'),
              disabled: !(
                selectedRowKeys.length > 0 &&
                isSameKey('locationAddress', selectedRows) &&
                !isShip(selectedRows) &&
                checkAllOrNoneContainers(selectedRows || [])
              ),
            },
            {
              key: 'observe',
              label: t('current_inventory.actions.observe'),
              disabled: selectedRowKeys.length !== 1 || isShip(selectedRows),
            },
            {
              key: 'aggregate',
              label: t('current_inventory.actions.aggregate'),
              disabled:
                !(
                  selectedRowKeys.length > 0 &&
                  isSameKey('locationAddress', selectedRows) &&
                  !isContainer(selectedRows)
                ) || isShip(selectedRows),
            },
            {
              key: 'disaggregation',
              label: t('current_inventory.actions.disaggregate'),
              disabled:
                !(
                  selectedRowKeys.length === 1 &&
                  selectedRows?.[0]?.isContainer &&
                  selectedRows?.[0]?.eventName !== 'Ship'
                ) || isShip(selectedRows),
            },
            {
              key: 'transform',
              label: t('current_inventory.actions.transform'),
              disabled:
                !(
                  selectedRowKeys.length > 0 &&
                  isSameKey('locationAddress', selectedRows) &&
                  !isContainer(selectedRows)
                ) || isShip(selectedRows),
            },
            {
              key: 'decommission',
              label: t('current_inventory.actions.decommission'),
              disabled:
                !(selectedRowKeys.length > 0 && isSameKey('locationAddress', selectedRows)) ||
                isShip(selectedRows),
            },
          ],
        },
        {
          key: 'manage',
          label: t('current_inventory.manage_grp'),
          type: 'group',
          children: [
            {
              key: 'view_settings',
              label: t('current_inventory.actions.view_settings'),
            },
            {
              key: 'download_xls',
              label:
                selectedRowKeys.length > 0
                  ? t('current_inventory.actions.download_csv_selected')
                  : t('current_inventory.actions.download_csv'),
            },
          ],
        },
      ]
    : [
        // {
        //   key: 'events',
        //   label: t('current_inventory.events_grp'),
        //   type: 'group',
        //   children: [
        //     {
        //       key: 'receive',
        //       label: t('current_inventory.actions.receive'),
        //       // disabled: !(
        //       //   selectedRowKeys.length === 1 &&
        //       //   selectedRows?.[0]?.eventName === 'Ship' &&
        //       //   (selectedRows?.[0]?.shipmentType === 'NonWholechain' ||
        //       //     selectedRows?.[0]?.shipmentType === 'Internal')
        //       // ),
        //     },
        //   ],
        // },
        {
          key: 'manage',
          label: t('current_inventory.manage_grp'),
          type: 'group',
          children: [
            // {
            //   key: 'archive',
            //   label: t('current_inventory.actions.archive'),
            //   disabled: !(selectedRowKeys.length >= 1),
            // },
            {
              key: 'download_xls',
              label:
                selectedRowKeys.length > 0
                  ? t('current_inventory.actions.download_csv_selected')
                  : t('current_inventory.actions.download_csv'),
            },
          ],
        },
      ];

/**
 * ProductsTitle
 * Component for the title bar of the product inventory page.
 */
const ProductsTitle: FC<ProductInventoryTitleProps> = ({
  product,
  tab = 'current_inventory',
  selectedRowKeys,
  selectedRows,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  const shareModalUnfinished = useModalVisibility();
  const sections = useMemo(() => [t('tab.inventory'), t('tab.documents')], [t]);
  const [section, setSection] = useState<string | number>(sections[0]);
  const { product: currentProduct, onDownloadCSVInventoryMultiple } = useEventActions();
  const recordId = selectedRowKeys?.[0] || '1';
  const containerArr = getContainerIds(selectedRows);
  const { productId = '1' } = useParams();
  const productsSearchParams = createSearchParams({
    products: selectedRowKeys?.join(',') || '1',
    locationId: selectedRows?.[0]?.locationId,
    eventDate: getMaxEventDate(selectedRows),
    ...(containerArr?.length && { containers: containerArr.join(',') }),
  });
  const navigate = useNavigate();
  const { setTemplate, setDataEntryMethod, setAdvancedSettings, setMultipleDates } =
    useEventsStore();
  const { setInventoryItem } = useShipmentStore();
  const receiveSearchParams = createSearchParams({
    shipmentIds: '1',
    locationId: '',
    tradePartnerId: '',
    eventDate: '',
    shipmentType: 'Internal',
    defaultProduct: productId,
  });

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'commission':
        navigate('../events/commission');
        break;
      case 'decommission':
        navigate({ pathname: '../events/decommission', search: `?${productsSearchParams}` });
        break;
      case 'receive':
        navigate({
          pathname: `/products/new_receive/events/receive`,
          search: `?${receiveSearchParams}`,
        });
        break;
      case 'aggregate':
        navigate({ pathname: `../events/aggregation`, search: `?${productsSearchParams}` });
        break;
      case 'disaggregation':
        navigate({ pathname: `../events/disaggregation`, search: `?${productsSearchParams}` });
        break;
      case 'observe':
        navigate({ pathname: `../events/observe`, search: `?${productsSearchParams}` });
        break;
      case 'ship':
        sessionStorage.setItem('ship-actionpayload-storage', JSON.stringify(selectedRows));
        navigate({ pathname: `../events/ship/${recordId}`, search: `?${productsSearchParams}` });
        break;
      case 'transform':
        navigate({
          pathname: `../events/transform/${recordId}`,
          search: `?${productsSearchParams}`,
        });
        break;
      case 'view_settings':
        navigate({ pathname: `../settings` });
        break;
      case 'share_product':
        shareModalUnfinished.show();
        break;
      case 'download_xls':
        onDownloadCSVInventoryMultiple(
          selectedRows,
          t('inv_csv_file_title', {
            name: currentProduct?.name || '',
            date: dateFormat(new Date()),
          }),
        );
        break;

      default:
        break;
    }
    setTemplate(undefined);
    setDataEntryMethod('manual');
    setMultipleDates(false);
    setInventoryItem(undefined);

    if (actionItemKey === 'observe') {
      setAdvancedSettings(true);
    } else {
      setAdvancedSettings(false);
    }
  };

  const onSegmentedButtonClick = useCallback(
    (value: SegmentedValue) => {
      setSection(value);
      switch (value) {
        case t('tab.inventory'):
          navigate('../');
          break;
        case t('tab.documents'):
          navigate({ pathname: '../documents' });
          break;

        default:
          break;
      }
    },
    [navigate, t],
  );

  return (
    <>
      <Modal
        {...shareModalUnfinished}
        title={t('share_product')}
        cancelText={t('done')}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ type: 'primary', shape: 'round' }}
      >
        <ComingSoon className={styles.sharemodalbody} />
      </Modal>
      <TitleBar
        className="page-header"
        title={
          <Space key="ptitle">
            {product?.name || ''}
            <Segmented
              value={section || sections[0]}
              options={sections}
              onChange={onSegmentedButtonClick}
            />
          </Space>
        }
        extra={
          selectedRowKeys.length > 0 && (
            <Typography.Title level={5} className="selectiontext">
              {`${selectedRowKeys.length} ${
                selectedRowKeys.length === 1 ? t?.('item') : t?.('items')
              } ${t?.('selected')}`}
            </Typography.Title>
          )
        }
        onBack={() => navigate('/products')}
        actionItems={GeneralActionItems({ selectedRowKeys, selectedRows, tab, t })}
        onActionItemClick={onActionItemClick}
      />
    </>
  );
};

const TabTitle: FC<TabTitleProps> = ({ title, count }) => (
  <Space>
    {title}
    <Badge count={count} showZero />
  </Space>
);

/**
 * Component to manage the inventory of a product.
 */
const ProductInventory = () => {
  const [currentTab, setCurrentTab] = useState('current_inventory');
  const { productId = '1' } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'products.tab' });
  const { data: currentProduct } = useProductById(productId);
  const product: ProductItem = {
    id: productId,
    epcUrn: currentProduct?.urn || '',
    name: currentProduct?.name || '',
    currentInventory: currentProduct?.currentInventory || 0,
  };
  const { data: dataProductLots } = useProductInstanceQuantities(productId, {
    pageNumber: 1,
    pageSize: 20,
    sortBy: '-loggedDate',
    isHistory: false,
  });
  const totalItems = dataProductLots?.totalItems || 0;
  const totalItemsHistory = dataProductLots?.history || 0;

  const { selectedRowKeys, selectedRows, onRowSelectionChange } = useTableRowSelection();

  const onTabChange = (activeKey: string) => {
    setCurrentTab(activeKey);
    onRowSelectionChange([], []);
  };

  return (
    <>
      <ProductsTitle
        product={product}
        tab={currentTab}
        selectedRowKeys={selectedRowKeys}
        selectedRows={selectedRows}
      />
      <Tabs defaultActiveKey="current_inventory" onChange={onTabChange}>
        <Tabs.TabPane
          tab={<TabTitle title={t('current')} count={totalItems} />}
          key="current_inventory"
        >
          <CurrentInventory
            selectedRowKeys={selectedRowKeys}
            onRowSelectionChange={onRowSelectionChange}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<TabTitle title={t('history')} count={totalItemsHistory} />}
          key="history_inventory"
        >
          {currentTab === 'history_inventory' && (
            <HistoryInventory
              selectedRowKeys={selectedRowKeys}
              onRowSelectionChange={onRowSelectionChange}
            />
          )}
        </Tabs.TabPane>
      </Tabs>

      {/* Outlet used to render the drawers */}
      <Outlet />
    </>
  );
};

export default React.memo(ProductInventory);

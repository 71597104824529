import { Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React from 'react';

interface NumericInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string | '';
  size: SizeType;
}

const NumericInput = (props: NumericInputProps) => {
  const { value, onChange, placeholder, size } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(inputValue);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
  };

  return (
    <Input
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      maxLength={25}
      size={size}
    />
  );
};

export default NumericInput;

// @ts-nocheck
import { BetaSchemaForm } from '@ant-design/pro-form';
import { Graph } from '@antv/g6';
import { EdgeConfig, GraphData } from '@antv/g6/lib/types';
import { Button, Form, Space, message } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { SupportModal } from 'components';
import { useActions, useDrawerVisibility, useModalVisibility } from 'hooks';
import { GetWorkflowTemplateByIdResponse } from 'pages/Templates';
import { WorkflowEvent } from 'pages/Templates/Workflows/typings';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  getWorkflowById,
  getWorkflowTemplateById,
  useMultipleProductInstances,
  useProductById,
  useUpdateWorkflow,
} from 'services/api';
import { WorkflowTemplate } from 'services/api/client/src';
import useEventById from 'services/api/eventDetails';
import { capitalizeFirstLetter, errorHandler, errorHandlerv2, supportEmailLink } from 'utils';
import DrawerFormHeader from './DrawerFormHeader';
import FormFields from './Form.fields';
import { DataItem, EventData, WorkflowEventType } from './typings';
import useWorkflowActions from './useWorkflowActions';

const rowProps = { gutter: 32 };
const structureData = (rootNode: WorkflowEvent) => ({
  id: rootNode?.id,
  eventType: rootNode?.eventType || '',
  name: rootNode?.eventType || 'No event selected',
  eventTemplateId: rootNode?.eventTemplateId,
  eventTemplateName: rootNode?.eventTemplateName || 'No event template selected',
  locationId: rootNode?.locationId,
  locationName: rootNode?.locationName,
  productId: rootNode?.productId,
  productName: rootNode?.productName,
  // colourIndex: 'colour_4',
  // eventData: rootNode,
  children: [],
});
const formatDataInit = (data?: Array<WorkflowEvent>) => {
  /* constructing  tree structure from the data received from the API with first node as root */
  const nodes = data;
  // edges are sequential depending on the order of the nodes
  const edges = nodes?.map((node, index) => ({
    source: node?.id,
    target: nodes[index + 1]?.id,
  }));

  const tree: GraphData = {
    nodes: (nodes?.map(structureData) || []) as GraphData['nodes'],
    edges,
  };
  return tree;
};

const ViewForm = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'workflows.create_workflow' });
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const supportModal = useModalVisibility(false);

  const { workflowId = '' } = useParams();
  const { queryClient, generateWorkflowGraph, generateInstances, logEvents, percent } =
    useActions();
  const { onUpdateDraft, workflow: dataWorkflow } = useWorkflowActions();
  const [mainGraph, setMainGraph] = useState<Graph | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [mainForm] = Form.useForm<DataItem>();

  const mainNodes = useMemo<GraphData['nodes']>(() => {
    if (!dataWorkflow) return [];
    const edges = dataWorkflow?.edges || [];
    const graphData = generateWorkflowGraph(dataWorkflow, edges as EdgeConfig[]);
    return graphData?.nodes || [];
  }, [dataWorkflow, generateWorkflowGraph]);
  const isCompleted = useMemo(() => dataWorkflow?.status === 'Completed', [dataWorkflow?.status]);
  const lastInstance = useMemo(() => {
    if (isCompleted) {
      const lastNode = mainNodes?.[(mainNodes?.length || 0) - 1];
      return lastNode?.eventData as EventData;
    }
    return undefined;
  }, [isCompleted, mainNodes]);
  const productId = useMemo(
    () =>
      lastInstance?.products?.[0]?.productId ||
      lastInstance?.outputProducts?.[0]?.productId ||
      lastInstance?.newInputProducts?.[0]?.productId ||
      lastInstance?.newProducts?.[0]?.productId ||
      '',
    [lastInstance],
  );
  const instanceId = useMemo(
    () =>
      lastInstance?.products?.[0]?.productId ||
      lastInstance?.outputProducts?.[0]?.productId ||
      lastInstance?.newInputProducts?.[0]?.productId ||
      lastInstance?.newProducts?.[0]?.productId ||
      '',
    [lastInstance],
  );
  const { data: product } = useProductById(productId);
  const { data: event } = useEventById(lastInstance?.eventId || '');
  const { data: productInstanceArray } = useMultipleProductInstances(
    !((lastInstance?.containerIdentifier?.length || 0) > 0) ? [instanceId] : [],
    (lastInstance?.containerIdentifier?.length || 0) > 0 ? [instanceId] : [],
  );
  const productInstance = productInstanceArray?.[0];

  const updateRecord = useUpdateWorkflow(queryClient);

  const getValues = useCallback(async () => {
    const workflow = await queryClient.fetchQuery(['workflow', workflowId], () =>
      getWorkflowById(workflowId),
    );

    const edges = workflow?.edges || [];
    const graphData = generateWorkflowGraph(workflow, edges as EdgeConfig[]);
    const instances = generateInstances(workflow);

    const values: DataItem = {
      name: workflow.name,
      templateId: workflow.workflowTemplateId,
      deadline: workflow.deadline,
      graphData,
      instances,
    };
    return values;
  }, [workflowId, queryClient, generateWorkflowGraph, generateInstances]);

  const onChangeTemplate = useCallback(
    async (option: DefaultOptionType | DefaultOptionType[]) => {
      try {
        const templateItem = (option as DefaultOptionType)?.itemProps as WorkflowTemplate;
        const templateId = templateItem?.id;
        if (templateId) {
          const template = await queryClient.fetchQuery<GetWorkflowTemplateByIdResponse>(
            ['workflowTemplate', templateId],
            () => getWorkflowTemplateById(templateId),
          );
          const workflowEvents: Array<WorkflowEvent> =
            template?.workflowEvents?.map((e) => ({
              ...e,
              eventType: capitalizeFirstLetter(e?.$type || '') as WorkflowEventType,
              eventTemplateId: e?.eventTemplateId,
              outputProducts: e?.outputProducts?.map((p) => p?.id || ''),
              inputProducts: e?.inputProducts?.map((p) => p?.id || ''),
              locationId: e?.locationId || e?.originId,
              locationName: e?.locationName || e?.originName || '',
              destinationId: e?.destinationId || '',
            })) || [];
          const graphData = formatDataInit(workflowEvents);
          mainForm.setFieldsValue({
            graphData,
          });
        }
      } catch (error) {
        message.error(await errorHandlerv2(error));
      }
    },
    [mainForm, queryClient],
  );

  const onFinish = async (formData: DataItem) => {
    try {
      setIsSubmitting(true);
      await onUpdateDraft(formData);
      await logEvents(mainGraph as Graph, mainForm, formData);
      const newFormData = mainForm.getFieldsValue();
      await onUpdateDraft(newFormData, 'Completed');
      setIsSubmitting(false);
      closeDrawer();

      setTimeout(() => {
        message.success(t('log_success_message', { id: formData?.name }));
      }, 1000);
    } catch (error) {
      const errMsg = errorHandler(error);
      message.error(errMsg);
      const newFormData = mainForm.getFieldsValue();
      await onUpdateDraft(newFormData, 'InProgress');
      setIsSubmitting(false);
      if (errMsg === 'Error in workflow') {
        supportModal.show();
      }
    }
  };
  const onSaveDraft = async () => {
    try {
      setIsUpdating(true);
      const formData: DataItem = mainForm.getFieldsValue();
      const res = await onUpdateDraft(formData);
      setIsUpdating(false);

      closeDrawer();
      setTimeout(() => {
        message.success(t('success_message_update', { id: res?.name }));
      }, 1500);
    } catch (error) {
      if (errorHandler(error)) {
        message.error(await errorHandler(error));
      }
      setIsUpdating(false);
    }
  };

  return (
    <>
      <BetaSchemaForm<DataItem>
        form={mainForm}
        layoutType="DrawerForm"
        columns={FormFields({
          t,
          setMainGraph,
          mainGraph,
          productInstance,
          product,
          event,
          isCompleted,
          percent,
          isSubmitting,
        })}
        grid
        request={getValues}
        // @ts-ignore
        title={
          <DrawerFormHeader
            title={t('title')}
            isEdit
            readonly={isCompleted}
            form={mainForm}
            onChangeTemplate={onChangeTemplate}
            name={dataWorkflow?.name}
            templateName={dataWorkflow?.workflowTemplateName || ''}
            deadline={dataWorkflow?.deadline}
          />
        }
        visible={visible}
        rowProps={rowProps}
        autoFocusFirstInput
        drawerProps={{
          destroyOnClose: true,
          height: '100%',
          placement: 'top',
          size: 'large',
          ...restDrawerVisibility,
        }}
        submitter={{
          searchConfig: {
            submitText: t('log_event_title'),
            resetText: t('cancel_btn'),
          },
          submitButtonProps: {
            shape: 'round',
            disabled: isCompleted || isUpdating,
            hidden: isCompleted,
          },
          resetButtonProps: {
            shape: 'round',
            type: 'primary',
            hidden: !isCompleted,
          },
          render: (_, defaultDoms) => (
            <Space>
              {!isCompleted && (
                <Button
                  type="primary"
                  shape="round"
                  ghost
                  onClick={onSaveDraft}
                  loading={updateRecord.isLoading}
                  disabled={isSubmitting}
                >
                  {t('save_draft_title')}
                </Button>
              )}
              {defaultDoms[1]}
              {defaultDoms[0]}
            </Space>
          ),
        }}
        submitTimeout={2000}
        onFinish={onFinish}
      />
      <SupportModal
        modal={supportModal}
        title={t('logging_error_title')}
        description={
          <div>
            {t('logging_error_desc')}
            &nbsp;
            <a href={supportEmailLink} type="mail">
              {t('support_anchor_email_title')}
            </a>
            .
          </div>
        }
      />
    </>
  );
};
export default ViewForm;

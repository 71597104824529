import { Avatar, Menu, Spin } from 'antd';
import { useAuthenticationContext } from 'contexts';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useListAccounts, useUserById } from 'services/api';
import { globalStyles } from 'utils';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.module.less';
import { AvatarDropdownProps } from './typings';

const AvatarDropdown: React.FC<AvatarDropdownProps> = () => {
  const navigate = useNavigate();
  const { currentUser, signout, isAuthenticated } = useAuthenticationContext();
  const { data: user } = useUserById(currentUser?.id || '');
  const { data: accounts } = useListAccounts();
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard.avatar' });

  const onMenuClick: MenuClickEventHandler = React.useCallback(
    ({ key }) => {
      switch (key) {
        case 'signout':
          signout();
          break;
        case 'change_account':
          navigate('../change_account');
          break;
        default:
          break;
      }
    },
    [signout, navigate],
  );

  // TODO: use Loading component
  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin size="small" style={globalStyles.mlr8} />
    </span>
  );

  if (!currentUser || !currentUser.displayName) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      {isAuthenticated && (
        <Menu.Item key="profile" className={styles.profile}>
          <span>
            {user?.givenName || ''} {user?.surname || ''}
          </span>
          <span>{user?.email}</span>
        </Menu.Item>
      )}

      {(accounts?.length || 0) > 1 && (
        <Menu.Item key="change_account">{t('switch_accounts')}</Menu.Item>
      )}

      <Menu.Item key="signout" className={styles.signout}>
        {t('sign_out')}
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown} trigger={['click', 'hover']}>
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar
          className={!user?.profilePictureUrl ? styles.avatar : undefined}
          src={
            user?.profilePictureUrl
              ? `${user?.profilePictureUrl}?t=${new Date().getTime()}`
              : undefined
          }
          alt="avatar"
        >
          {user?.givenName?.[0] || 'U'}
        </Avatar>
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;

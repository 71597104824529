import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Descriptions, Space, Tooltip, Typography } from 'antd';
import { FC } from 'react';
import styles from '../index.module.less';
import { DetailsDataProps, DetailsEpcisProps } from '../typings';
import DataTitle from './DataTitle';

const CardTitle: FC<DetailsDataProps> = ({ activeEvent }) => (
  <Descriptions size="small" className={styles.cardtitle}>
    <Descriptions.Item
      label={
        <Space size="small">
          <Typography.Text strong>Hash</Typography.Text>
          <Tooltip
            placement="bottom"
            title="Copy and paste this Event Data into a SHA256 hash generator to confirm that the hashes match, therefore authenticating that the data is the same as what has been sent."
          >
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <Typography.Paragraph copyable ellipsis>
        {activeEvent?.blockchain?.hashedEventData || ''}
      </Typography.Paragraph>
    </Descriptions.Item>
  </Descriptions>
);
const EpcisContent: FC<DetailsEpcisProps> = ({
  event,
  activeEvent,
  isPretty,
  productInstance,
  isSerial,
  identifier,
}) => (
  <>
    <DataTitle
      activeEvent={activeEvent}
      productInstance={productInstance}
      isSerial={isSerial}
      identifier={identifier}
    />
    <Card title={<CardTitle event={event} activeEvent={activeEvent} />} className={styles.mrt10}>
      {isPretty && <pre className={styles.jsonbox}>{JSON.stringify(activeEvent, null, 2)}</pre>}
      {!isPretty && <p className={styles.jsonbox}>{JSON.stringify(activeEvent, null, 2)}</p>}
    </Card>
  </>
);
export default EpcisContent;

import { ActionType, ProColumns } from '@ant-design/pro-table';
import GTable from 'components/GTable';
import { useTableSearchFilter } from 'hooks';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { AssociationTableColProps, SingleColumnTableProps } from './typings';

type ColumnsType = {
  columnTitle: string;
  nameSearchfilter: ProColumns;
};

const columns = ({
  columnTitle,
  nameSearchfilter,
}: ColumnsType): Array<ProColumns<AssociationTableColProps>> => [
  {
    title: columnTitle,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    valueType: 'text',
    fieldProps: {
      placeholder: columnTitle,
    },
    ...nameSearchfilter,
  },
];
const DocTable: FC<SingleColumnTableProps> = ({ form, fieldValue, values, columnTitle }) => {
  const { setFieldsValue, getFieldValue } = form;
  const tactionRef = useRef<ActionType>();
  const attributes: Array<AssociationTableColProps> = getFieldValue(fieldValue);
  const [allValues, setAllValues] = useState<Array<AssociationTableColProps>>([]);

  const setAttributes = useCallback(
    (value: Array<AssociationTableColProps>) =>
      setFieldsValue({
        [fieldValue]: value,
      }),
    [fieldValue, setFieldsValue],
  );

  const onSearch = useCallback(
    (text: string) => {
      setAttributes(
        allValues?.filter((item) =>
          item?.name?.toLocaleLowerCase()?.includes(text?.toLocaleLowerCase()),
        ),
      );
    },
    [allValues, setAttributes],
  );

  const onReset = useCallback(() => {
    setAttributes(allValues);
  }, [allValues, setAttributes]);

  const { filters: nameSearchfilter } = useTableSearchFilter({
    title: columnTitle,
    onSearch,
    onReset,
  });

  useEffect(() => {
    if (values) {
      const mapData = values.map((value, index) => ({ id: `${index}${value}`, name: value }));
      setAttributes(mapData);
      setAllValues(mapData);
    }
  }, [setAttributes, values]);

  return (
    <GTable<AssociationTableColProps>
      key="docattribt"
      value={attributes}
      actionRef={tactionRef}
      columns={columns({
        columnTitle,
        nameSearchfilter,
      })}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: false,
        cancel: false,
        delete: false,
      }}
      recordCreatorProps={false}
    />
  );
};
export default DocTable;

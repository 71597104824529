import { DownOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-card';
import { Button, DatePicker, DatePickerProps, Segmented, Space, Typography } from 'antd';
import moment from 'moment';
import InboundShipments from 'pages/AllShipments/InboundShipments/InboundShipments';
import OutboundShipments from 'pages/AllShipments/OutboundShipments/OutboundShipments';
import useLocationStore from 'pages/Network/hook/useLocationStore';
import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShipmentsSummary } from 'services/api';
import { GetShipmentsSummaryOptionalParams } from 'services/api/client/src';
import LineChart from './LineChart';
import styles from './LineChart/Linechart.module.less';

const bodyStyle = { padding: '15px', paddingTop: '0px' };
const headStyle = { padding: '10px' };
const dropdownIcon = { fontSize: '10px', color: '#3f51b5' };
const datepicker = { width: '80px' };

const setModeShipSession = (tabId: string) => {
  sessionStorage.setItem('ModeShipTab', tabId);
};

const getModeShipSession = () => {
  if (!sessionStorage.getItem('ModeShipTab')) {
    setModeShipSession('graph');
  }
  return sessionStorage.getItem('ModeShipTab') || 'internal';
};

const ViewGraph: FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.shipment_card' });
  const shipmentTypes = useMemo(() => [t('inbound'), t('outbound')], [t]);
  const [shipmentType, setShipmentType] = useState<string | number>(shipmentTypes[0]);
  const modeSession = getModeShipSession() || 'graph';
  const [mode, setMode] = useState(modeSession);
  const { urn, tradePartnerUrn, isLocationView, digitalLinkUrl, accountType } = useLocationStore();
  const [params, setParams] = useState<GetShipmentsSummaryOptionalParams>({
    startDate: new Date(`${new Date().getFullYear()}`),
    endDate: new Date(`${new Date().getFullYear() + 1}`),
  });

  useEffect(() => {
    if (urn || tradePartnerUrn) {
      setParams({
        ...params,
        ...(isLocationView
          ? {
              locationUrn: urn || '',
            }
          : {
              tradePartnerUrn,
            }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocationView, urn]);

  const { data, isLoading } = useShipmentsSummary({
    ...params,
  });
  const direction = useMemo(() => {
    switch (true) {
      case shipmentType === t('inbound'):
        return { direction: 'Inbound' };
      case shipmentType === t('outbound'):
        return { direction: 'Outbound' };
      default:
        return {};
    }
  }, [shipmentType, t]);

  const shipParam = useMemo(
    () =>
      direction?.direction === 'Outbound'
        ? {
            ...(isLocationView
              ? { originLocationUrns: [urn || ''] }
              : { originTradePartnerUrns: [tradePartnerUrn || ''] }),
          }
        : {
            ...(isLocationView
              ? { destinationLocationUrns: [urn || ''] }
              : { destinationTradePartnerUrns: [tradePartnerUrn || ''] }),
          },
    [direction?.direction, isLocationView, tradePartnerUrn, urn],
  );

  const shipOtherParam = useMemo(
    () =>
      direction?.direction === 'Outbound'
        ? {
            ...(isLocationView
              ? { shippedToUrn: urn || '' }
              : { shippedToTradePartnerUrn: [tradePartnerUrn || ''] }),
          }
        : {
            ...(isLocationView
              ? { shippedFromUrn: urn || '' }
              : { shippedFromTradePartnerUrn: [tradePartnerUrn || ''] }),
          },
    [direction?.direction, isLocationView, tradePartnerUrn, urn],
  );

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setParams({
      ...params,
      startDate: new Date(dateString),
      endDate: new Date(`${Number(dateString) + 1}`),
    });
  };

  const Title: ReactElement = (
    <Space>
      <Typography.Title level={4} className="mr0">
        {t('shipment_title')}
      </Typography.Title>
      {mode === 'table' && (
        <Segmented
          options={shipmentTypes}
          value={shipmentType || shipmentTypes[0]}
          onChange={setShipmentType}
        />
      )}
      <DatePicker
        value={moment.utc(params?.startDate)}
        suffixIcon={<DownOutlined style={dropdownIcon} />}
        style={datepicker}
        picker="year"
        mode="year"
        bordered={false}
        onChange={onChange}
        allowClear={false}
        className={styles.datepicker}
      />
    </Space>
  );

  const setShipMode = useCallback((shipMode: string) => {
    setMode(shipMode);
    setModeShipSession(shipMode);
  }, []);

  return (
    <ProCard
      className={styles.linechartbg}
      bodyStyle={bodyStyle}
      headStyle={headStyle}
      loading={isLoading}
      title={Title}
      extra={
        mode === 'graph' ? (
          <Button onClick={() => setShipMode('table')} type="link">
            <Space>{t('view_table')}</Space>
          </Button>
        ) : (
          <Button onClick={() => setShipMode('graph')} type="link">
            <Space>{t('view_graph')}</Space>
          </Button>
        )
      }
    >
      {mode === 'graph' && <LineChart data={data} />}
      {mode === 'table' &&
        ((direction?.direction === 'Outbound' && accountType !== 'ME') ||
        (direction?.direction === 'Inbound' && accountType === 'ME') ? (
          <InboundShipments
            inboundShipParam={{
              startDate: params.startDate,
              endDate: params.endDate,
              ...(urn || tradePartnerUrn ? { ...shipParam } : undefined),
            }}
            inboundOtherShipParam={{
              startDate: params.startDate,
              endDate: params.endDate,
              ...(urn || tradePartnerUrn ? { ...shipOtherParam } : undefined),
            }}
            hideOtherSystemTab={accountType !== 'ME'}
            considerAsOutbound={direction?.direction === 'Outbound'}
            isNetworkShip
          />
        ) : (
          <OutboundShipments
            outboundShipParam={{
              startDate: params.startDate,
              endDate: params.endDate,
              ...(urn || tradePartnerUrn ? { ...shipParam } : undefined),
            }}
            hideOtherSystemTab={digitalLinkUrl === undefined || digitalLinkUrl === null}
            considerAsInbound={direction?.direction === 'Inbound'}
            isNetworkShip
          />
        ))}
    </ProCard>
  );
};

export default React.memo(ViewGraph);

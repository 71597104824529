import { message, Modal, Typography } from 'antd';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useRemoveEvent } from 'services/api';
import { InboundShipmentResponse } from 'services/api/client/src';
import { errorHandler, getEventsT, getShipmentsT, getTParam } from 'utils';
import { OnErrorRemoveEvent } from './typings';

const useNewShipmentActions = () => {
  const queryClient = useQueryClient();
  const removeEvent = useRemoveEvent(queryClient);
  const onShowErrorExternalShipmentDialog = useCallback(() => {
    Modal.error({
      title: getShipmentsT?.('error_warning.unable_to_receive_shipments'),
      content: (
        <Typography.Paragraph>
          {`${getShipmentsT?.('error_warning.unable_to_receive_shipments_content')}`}{' '}
          <Typography.Link type="secondary" href="">
            {getShipmentsT('error_warning.unable_to_receive_shipments_contact')}
          </Typography.Link>
        </Typography.Paragraph>
      ),
      okText: 'Done',
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);

  const onShowMixLotDialog = useCallback(() => {
    Modal.warning({
      title: getShipmentsT?.('mix_lot_serial_title'),
      content: getShipmentsT?.('mix_lot_serial_desc'),
      okText: getShipmentsT?.('mix_lot_serial_confirm'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);

  const onShowMixSSCCDialog = useCallback(() => {
    Modal.warning({
      title: getShipmentsT?.('sscc_selection_title'),
      content: getShipmentsT?.('sscc_selection_desc'),
      okText: getShipmentsT?.('sscc_selection_confirm'),
      cancelText: getShipmentsT?.('sscc_selection_cancel'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);

  const onErrorRemoveEvent = useCallback<OnErrorRemoveEvent>(
    ({ identifier: identifieR, lotSerial, event }) => {
      const t = (key: string, param?: object) =>
        getTParam(`products.current_inventory.${key}`, param);
      Modal.warning({
        title: t?.('subsequent_event_title'),
        content: t?.('subsequent_event_desc', {
          event,
          identifier: identifieR,
          lotSerial,
        }),
        okText: t?.('subsequent_event_confirm'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
        zIndex: 9999,
      });
    },
    [],
  );

  const onRemoveEvent = useCallback(
    (actionPayload?: InboundShipmentResponse) => {
      const t = (key: string, param?: object) =>
        getTParam(`global_search.shipments_search_table.${key}`, param);

      Modal.warning({
        title: t('remove_event_title'),
        content: t('remove_event_desc'),
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        okText: t('remove_event_confirm'),
        cancelText: t('remove_event_cancel'),
        okCancel: true,
        onOk: async () => {
          try {
            await removeEvent.mutateAsync(actionPayload?.shipmentId || '');
            message.success(t('remove_event_success_msg'));
          } catch (error) {
            onErrorRemoveEvent({
              event: getEventsT('ship' || '')?.toLowerCase(),
              message: errorHandler(error),
            });
          }
        },
        centered: true,
      });
    },
    [removeEvent, onErrorRemoveEvent],
  );

  return {
    onShowMixLotDialog,
    onShowMixSSCCDialog,
    onShowErrorExternalShipmentDialog,
    onRemoveEvent,
  };
};
export default useNewShipmentActions;

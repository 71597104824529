import { MenuOutlined } from '@ant-design/icons';
import { FC, useCallback, useMemo } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { globalStyles } from 'utils';
import styles from './index.module.less';
import { DragSortTableProps, DraggableContainerProps } from './typings';

export const DragHandle = SortableHandle(() => <MenuOutlined style={globalStyles.cursorGrab} />);
// eslint-disable-next-line react/jsx-props-no-spreading
export const SortableItem = SortableElement((props: any) => <tr {...props} />);
// eslint-disable-next-line react/jsx-props-no-spreading
export const SortContainer = SortableContainer((props: any) => <tbody {...props} />);

const useDragSort = ({ dataSource, onSortEnd }: DragSortTableProps) => {
  const DraggableContainer: FC<DraggableContainerProps> = useCallback(
    (props) => (
      <SortContainer
        useDragHandle
        disableAutoscroll
        helperClass={styles['row-dragging']}
        onSortEnd={onSortEnd}
        {...props}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSortEnd],
  );

  const DraggableBodyRow: FC<any> = useCallback(
    (props) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      // @ts-ignore
      // eslint-disable-next-line react/destructuring-assignment
      const index = dataSource?.findIndex((x) => x.id === props['data-row-key']) || 0;
      return <SortableItem {...props} index={index} />;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource],
  );

  const result = useMemo(
    () => ({ body: { wrapper: DraggableContainer, row: DraggableBodyRow } }),
    [DraggableContainer, DraggableBodyRow],
  );

  return result;
};

export default useDragSort;

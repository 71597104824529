import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Space, Tooltip, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FC, useCallback } from 'react';
import { getProductsT } from 'utils';
import styles from './index.module.less';
import { SwitchFormItemProps } from './typings';

const SwitchFormItem: FC<SwitchFormItemProps> = ({
  title,
  dataIndex = 'isSwitch',
  tooltip,
  form,
  ...props
}) => {
  const { getFieldValue, setFieldsValue } = form;
  const value = getFieldValue(dataIndex);
  const setValue = useCallback(
    (e: CheckboxChangeEvent) => {
      setFieldsValue({
        [dataIndex]: e.target.checked,
      });
    },
    [setFieldsValue, dataIndex],
  );
  return (
    <Space>
      {title || getProductsT?.('multiple_dates')}
      {tooltip && (
        <Tooltip title={tooltip}>
          <Typography.Text type="secondary">
            <InfoCircleOutlined className={styles.tooltip} />
          </Typography.Text>
        </Tooltip>
      )}
      <Checkbox checked={value} onChange={setValue} {...props} />
    </Space>
  );
};
export default SwitchFormItem;

export const setInboundSettingsTabSession = (tabId: string) => {
  sessionStorage.setItem('InboundSettingsTab', tabId);
};

export const getInboundSettingsTabSession = () => {
  if (!sessionStorage.getItem('InboundSettingsTab')) {
    setInboundSettingsTabSession('internal');
  }
  return sessionStorage.getItem('InboundSettingsTab') || 'internal';
};

export const removeInboundSettingsTabSession = () => {
  sessionStorage.removeItem('InboundSettingsTab');
};

export const setInboundSettingsShipTabSession = (tabId: string) => {
  sessionStorage.setItem('InboundSettingsShipTab', tabId);
};

export const getInboundSettingsShipTabSession = () => {
  if (!sessionStorage.getItem('InboundSettingsShipTab')) {
    setInboundSettingsTabSession('internal');
  }
  return sessionStorage.getItem('InboundSettingsShipTab') || 'internal';
};

export const removeInboundSettingsShipTabSession = () => {
  sessionStorage.removeItem('InboundSettingsShipTab');
};

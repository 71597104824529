import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { DataEntryMethodType, ShipmentStore } from './typings';

const useShipmentStore = create<ShipmentStore>()(
  persist(
    (set) => ({
      title: '',
      hasAdvancedSettings: false,
      dataEntryMethod: 'manual',
      multipleDates: false,
      submitting: false,
      products: undefined,
      showSSCCPopup: false,
      disableSSCCPopup: false,
      invitePopup: false,
      inviteRequest: undefined,
      processingReceiveIds: [],
      inventoryItem: undefined,
      selectedShipments: [],
      editableShipment: {},
      setDataEntryMethod: (dataEntryMethod: DataEntryMethodType) =>
        set(() => ({
          dataEntryMethod,
          hasAdvancedSettings: dataEntryMethod !== 'csvUpload',
        })),
      toggleAdvancedSettings: () =>
        set((state) => ({
          hasAdvancedSettings: !state.hasAdvancedSettings,
        })),
      setAdvancedSettings: (value) =>
        set(() => ({
          hasAdvancedSettings: value,
        })),
      setTitle: (title: string) =>
        set(() => ({
          title,
        })),
      setMultipleDates: (value) =>
        set(() => ({
          multipleDates: value,
        })),
      setSubmitting: (value) =>
        set(() => ({
          submitting: value,
        })),
      setProducts: (products) =>
        set(() => ({
          products,
        })),
      setShowSSCCPopup: (value) =>
        set(() => ({
          showSSCCPopup: value,
        })),
      setDisableSSCCPopup: (value) =>
        set(() => ({
          disableSSCCPopup: value,
        })),
      setInvitePopup: (value) =>
        set(() => ({
          invitePopup: value,
        })),
      setInviteRequest: (value) =>
        set(() => ({
          inviteRequest: value,
        })),
      setProcessingReceiveIds: (processingReceiveIds) =>
        set(() => ({
          processingReceiveIds,
        })),
      setInventoryItem: (inventoryItem) =>
        set(() => ({
          inventoryItem,
        })),
      setSelectedShipments: (selectedShipments) =>
        set(() => ({
          selectedShipments,
        })),
      setEditableShipment: (editableShipment) =>
        set(() => ({
          editableShipment,
        })),
    }),
    {
      name: 'shipment-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ),
);

interface MyState {
  bears: number;
  addABear: () => void;
}

export const useBearStore = create<MyState>()(
  persist(
    (set, get) => ({
      bears: 0,
      addABear: () => set({ bears: get().bears + 1 }),
    }),
    {
      name: 'food-storage', // name of item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default the 'localStorage' is used
      partialize: (state) => ({ bears: state.bears }),
    },
  ),
);
export default useShipmentStore;

import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Alert, Form, FormInstance, message } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { DefaultOptionType } from 'antd/lib/select';
import ActionButtons from 'components/ActionButtons';
import ProductLotSelect from 'components/GSelect/ProductLotSelect';
import GTable from 'components/GTable';
import { Moment } from 'moment';
import { useEventsStore } from 'pages/Events/hooks';
import useProductStore from 'pages/Products/useProductStore';
import React, { FC, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ProductResponse } from 'services/api/client/src';
import { useGetProductInstanceById, useMultipleProductInstances } from 'services/api/useProducts';
import {
  dateLocalFormat,
  disableExistingLotLocations,
  getContainerSum,
  getCurrentInventory,
  getDisabledDate,
  getDisabledTime,
  getInventoryPropsAddon,
  getLotUomAddon,
  getProductsT,
  getQuantityAddon,
  getRecordValue,
  getRecordValuev2,
  hiddencol,
  mapDecommissionInvDataItem,
  onChangeFieldsPropInstance,
} from 'utils';
import { useEventActions } from '../hooks';
import styles from '../index.module.less';
import { DecommissionProductItem } from '../typings';
import { EditableFormProps, ShipProductsProps } from './typings';

type ColumnsType = {
  locationId?: string;
  defaultUom?: string;
  disabledDate?: (date: Moment) => boolean;
  disabledTime?: RangePickerProps['disabledTime'];
  onChangeDate?: (
    form: FormInstance<any>,
    value: string,
    isMultipleDates?: boolean,
    rowKey?: string,
    eventTime?: string,
    timeKey?: string,
  ) => void;
  onEditRow: (actionPayload?: DecommissionProductItem) => void;
  onDelRow: (actionPayload?: DecommissionProductItem) => void;
  onChangeProduct: (
    value: string,
    option: DefaultOptionType | DefaultOptionType[],
    totalInventoryAtSameLocation: Number,
  ) => void;
  multipleDates: boolean;
  t?: TFunction<'pages', 'events.decommission.form_fields'>;
  products?: DecommissionProductItem[];
  isSerial?: boolean;
  identifier?: string;
  editableFormRef: MutableRefObject<EditableFormInstance<EditableFormProps>>;
  instanceByProductId?: any;
  locationValue?: string;
};
const columns = ({
  onEditRow,
  onDelRow,
  onChangeDate,
  t,
  locationId,
  defaultUom,
  multipleDates,
  products,
  isSerial,
  identifier,
  editableFormRef,
}: ColumnsType): Array<ProColumns<DecommissionProductItem>> => [
  /* {
    title: t?.('tbl_col_product_title'),
    dataIndex: 'parentProductId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_product_title_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, config, form) => (
      <ProductSelect
        isSerial={isSerial}
        size="small"
        onChange={async (value, option: any) => {
          // fetch instance when a product is changed
          const productInventoryData = await instanceByProductId.mutateAsync({
            id: option?.itemProps?.id || '',
            params: {
              locationIds: [locationValue],
            },
          });

          // get total Item at a location
          const totalInventoryAtSameLocation = Number(
            productInventoryData?.results?.[0]?.productInstance?.totalInventoryAtSameLocation ||
              productInventoryData?.results?.[0]?.container?.containerItems?.[0]
                ?.totalInventoryAtSameLocation ||
              0,
          );
          onChangeFieldsPropProduct(form, config, option, {}, totalInventoryAtSameLocation);
          onChangeProduct(value, option, totalInventoryAtSameLocation);
        }}
      />
    ),
    render: (text, record) => record?.name || '',
    ellipsis: true,
  }, */
  {
    title: t?.('tbl_col_primary_id_title', {
      identifier: getProductsT(identifier),
    }),
    dataIndex: 'primaryId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_primary_id_req', {
            identifier: getProductsT(identifier),
          }),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey, record }, form) =>
      !record?.isContainer ? (
        <ProductLotSelect
          productId={record?.parentProductId}
          size="small"
          disabledItem={(item) => disableExistingLotLocations(item, products, locationId)}
          onChange={(value, option) => onChangeFieldsPropInstance(form, { recordKey }, option)}
        />
      ) : (
        `${getProductsT('SSCC')}: ${record?.lotSerial || ''}`
      ),
    render: (text, record) => (
      <span>
        {record?.isContainer
          ? `${getProductsT('SSCC')}:`
          : `${getProductsT(record?.productIdentifierType || identifier)}:`}{' '}
        {record?.lotSerial || ''}
      </span>
    ),
    ellipsis: true,
  },
  {
    title: t?.('tbl_col_quantity_title'),
    dataIndex: 'quantity',
    fieldProps: (form, config) =>
      getLotUomAddon(
        form,
        config,
        defaultUom,
        {
          disabled: isSerial,
        },
        undefined,
        isSerial,
      ),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_quantity_req'),
        },
      ],
      hasFeedback: false,
    },
    render: (text, record) => {
      const updatedQuantity = !record?.isChildren
        ? `${record?.quantity || 0} ${getQuantityAddon(record, defaultUom)}`
        : `${record?.quantity || 0} ${record?.unitOfMeasure || ''}`;
      const quantityUom = !record?.isContainer ? updatedQuantity : getContainerSum(record);
      return record?.unitOfMeasure !== 'item' ? quantityUom : `1 ${getProductsT('item')}`;
    },
    ellipsis: true,
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    tooltip: {
      title: t?.('tbl_col_current_inventory_tooltip'),
      icon: <InfoCircleOutlined />,
    },
    ellipsis: true,
    fieldProps: (form, config) =>
      getInventoryPropsAddon(
        form,
        config,
        defaultUom,
        {
          placeholder: t?.('tbl_col_current_inventory_title'),
          disabled: true,
        },
        isSerial,
      ),
    render: (text, record) =>
      record?.isChildren ? record?.totalInventoryAtLocation : getCurrentInventory(record),
  },
  {
    title: t?.('tbl_col_scss_title'),
    dataIndex: 'logisticId',
    fieldProps: {
      size: 'small',
      placeholder: t?.('tbl_col_scss_placeholder'),
    },
    ellipsis: true,
    hideInTable: true,
  },
  /*  {
    title: t?.('tbl_col_purchase_order_title'),
    dataIndex: 'purchaseOrder',
    fieldProps: {
      size: 'small',
      placeholder: t?.('tbl_col_purchase_order_placeholder'),
    },
    ellipsis: true,
  }, */
  {
    title: t?.('tbl_col_date_title'),
    dataIndex: 'date',
    valueType: 'date',
    hideInTable: !multipleDates,
    fieldProps: (form, { rowKey }) => ({
      size: 'small',
      placeholder: t?.('tbl_col_date_placeholder'),
      onChange: (value: string) =>
        onChangeDate?.(
          editableFormRef.current,
          value,
          multipleDates,
          rowKey,
          editableFormRef.current?.getFieldValue?.(String(rowKey))?.eventDate ||
            getRecordValue('eventDate', products, rowKey),
        ),
      disabledDate: getDisabledDate(
        editableFormRef.current?.getFieldValue?.(String(rowKey))?.eventDate ||
          getRecordValue('eventDate', products, rowKey),
      ),
      className: 'full-width',
    }),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_date_req'),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
  },
  {
    dataIndex: 'eventDate',
    ...hiddencol,
  },
  {
    title: t?.('tbl_col_time_title'),
    dataIndex: 'time',
    valueType: 'time',
    hideInTable: !multipleDates,
    fieldProps: (form, { rowKey }) => ({
      size: 'small',
      placeholder: t?.('tbl_col_time_title'),
      disabledTime: getDisabledTime(
        editableFormRef.current?.getFieldValue?.(String(rowKey))?.eventDate ||
          getRecordValue('eventDate', products, rowKey),
      ),
      className: 'full-width',
    }),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_time_req'),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
  },
  {
    dataIndex: 'instanceInventory',
    ...hiddencol,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    fixed: 'right',
    width: 100,
    render: (_text, record: any) => (
      <ActionButtons
        showEdit={!record?.isContainer && !record?.isChildren}
        showDelete={!record?.isContainer && !record?.isChildren}
        record={record}
        onEdit={onEditRow}
        onDelete={onDelRow}
      />
    ),
  },
];
const DecommissionProducts: FC<ShipProductsProps> = ({
  form,
  isSerial,
  identifier,
  selectedInstances,
  validationMessage,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.decommission.form_fields' });
  const multipleDates = useEventsStore((state) => state.multipleDates);
  const {
    locationId,
    productId,
    product: currProduct,
    productIdArray,
    containerIdArray,
    defaultUom,
    disabledDate,
    disabledTime,
    onChangeDate,
  } = useEventActions();
  const { setFieldsValue, getFieldValue } = form;

  const sactionRef = useRef<ActionType>();
  const [fetched, setFetched] = useState<boolean>(false);
  const { paramsStore } = useProductStore();

  const {
    data: dataProducts = [],
    isLoading,
    isSuccess,
  } = useMultipleProductInstances(productIdArray, containerIdArray, paramsStore);

  const [tmpProduct, setTmpProduct] = useState<DecommissionProductItem>();

  const queryClient = useQueryClient();
  const instanceByProductId = useGetProductInstanceById(queryClient);
  const locationValue: string = getFieldValue('location');

  const products: Array<DecommissionProductItem> = Form?.useWatch('eventProducts', form) || [];
  const editableFormRef = useRef<EditableFormInstance<any>>();

  const setProducts = useCallback(
    (value: Array<DecommissionProductItem>) => {
      setFieldsValue({
        eventProducts: value,
      });
    },
    [setFieldsValue],
  );

  useEffect(() => {
    if (isSuccess && !fetched) {
      const modifiedProducts =
        dataProducts?.map<DecommissionProductItem>((p) => mapDecommissionInvDataItem(p)) || [];
      setProducts(modifiedProducts);
      setFetched(true);
    }
    if ((selectedInstances?.length || 0) > 0) {
      const initProducts =
        selectedInstances?.map((p) => ({
          id: p?.id || '0',
          parentProductId: p?.productId,
          name: p?.name || '',
          primaryId: p?.id || '0',
          lotSerial: p?.lotSerial || '',
          quantity: p?.quantity || 0,
          currentInventory: p?.currentInventory || 0,
          unitOfMeasure: p?.unitOfMeasure,
          instanceInventory: p?.quantity || 0,
          eventDate: p?.eventDate ? dateLocalFormat(p?.eventDate) : undefined,
        })) || [];
      setProducts(initProducts);
    }
  }, [dataProducts, isSuccess, fetched, setProducts, productId, currProduct, selectedInstances]);
  const onChangeProduct: ColumnsType['onChangeProduct'] = (
    value,
    option,
    totalInventoryAtSameLocation,
  ) => {
    if (value && option) {
      // @ts-ignore
      const selProd: ProductResponse = option?.itemProps;

      setTmpProduct({
        id: value || '0',
        name: selProd?.name || '',
        parentProductId: productId || '0',
        lotSerial: '',
        primaryId: '',
        currentInventory: Number(totalInventoryAtSameLocation) || 0,
        quantity: Number(selProd?.shippedInventory || 0),
        unitOfMeasure: selProd?.simpleUnitOfMeasurement || '',
      });
    }
  };
  const onEditRow = (actionPayload?: DecommissionProductItem) => {
    sactionRef.current?.startEditable(actionPayload?.id || 0);
    setTmpProduct(actionPayload);
  };
  const onDelRow = async (actionPayload?: DecommissionProductItem) => {
    if (actionPayload && products.length > 1) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    } else {
      message.error(getProductsT('delete_last_product'));
    }
  };
  const onSaveProduct = async (rowKey: RecordKey, data: DecommissionProductItem) => {
    const isSer =
      getRecordValuev2('unitOfMeasure', products, String(rowKey), editableFormRef.current) ===
      'item';
    sactionRef.current?.cancelEditable(data?.id || 0);

    const existingProduct = products.find((product) => product.id === data?.id);
    if (existingProduct) {
      existingProduct.name = tmpProduct?.name || data?.name || '';
      existingProduct.quantity = isSer ? 1 : data?.quantity;
      existingProduct.instanceInventory = data?.instanceInventory || 0;
      existingProduct.currentInventory = tmpProduct?.currentInventory || data?.currentInventory;
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.lotSerial = data?.lotSerial || '';
      existingProduct.logisticId = data?.logisticId || '';
      existingProduct.purchaseOrder = data?.purchaseOrder || '';
      existingProduct.date = data?.date;
      existingProduct.time = data?.time;
      existingProduct.unitOfMeasure = data?.unitOfMeasure;
      existingProduct.eventDate = data?.eventDate;
      // update product
      setProducts(products.map((p) => (p.id === existingProduct.id ? existingProduct : p)));
    } else {
      const productData = {
        id: data?.id || '0',
        parentProductId: tmpProduct?.parentProductId || '0',
        name: tmpProduct?.name || '',
        lotSerial: data?.lotSerial || '',
        primaryId: data?.primaryId || '0',
        currentInventory: Number(tmpProduct?.currentInventory || 0),
        instanceInventory: Number(data?.instanceInventory || 0),
        quantity: isSer ? 1 : Number(data?.quantity || 0),
        unitOfMeasure: data?.unitOfMeasure || '',
        logisticId: data?.logisticId || '',
        purchaseOrder: data?.purchaseOrder || '',
        date: data?.date,
        time: data?.time,
        eventDate: data?.eventDate,
      };
      // add product
      setProducts([productData, ...products]);
    }
  };
  const rowExpandable = useCallback(
    (rowData: DecommissionProductItem) => rowData?.isContainer || false,
    [],
  );
  return (
    <>
      {validationMessage && (
        <Alert
          message={t('validate_message')}
          className={styles.marginbottom}
          type="error"
          showIcon
        />
      )}
      <GTable<DecommissionProductItem>
        key="sactionRef"
        actionRef={sactionRef}
        editableFormRef={editableFormRef}
        columns={columns({
          t,
          onEditRow,
          onDelRow,
          onChangeProduct,
          disabledDate,
          disabledTime,
          onChangeDate,
          multipleDates,
          locationId,
          defaultUom,
          products,
          isSerial,
          identifier,
          // @ts-ignore
          editableFormRef,
          instanceByProductId,
          locationValue,
        })}
        editable={{
          onSave: (rowKey, data) => onSaveProduct(rowKey, data),
          onCancel: async (_rowKey, data) => {
            sactionRef.current?.cancelEditable(data?.id || 0);
          },
        }}
        options={{
          reload: false,
          setting: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        recordCreatorProps={false}
        onAddRecordClick={() => {
          sactionRef.current?.addEditRecord?.(
            {
              id: Date.now().toString(),
            },
            {
              position: 'top',
            },
          );
        }}
        loading={isLoading}
        value={products}
        enableRecordCreator={false}
        scroll={{ x: 1500, y: '50vh' }}
        expandable={{ rowExpandable, columnWidth: 30 }}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
        }}
      />
    </>
  );
};
export default React.memo(DecommissionProducts);

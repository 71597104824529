import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button, Tag } from 'antd';
import GTable from 'components/GTable';
import { useProductActions, useProductFilters } from 'pages/Products';
import { ProductItem } from 'pages/Products/typings';
import { FC, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ArchivedProps } from '../typings';

type ColumnsType = {
  onRowActionClick?: (actionPayload?: ProductItem) => void;
  productSearchfilter: ProColumns;
  t: TFunction<'pages', 'products'>;
};
const columns = ({ onRowActionClick, t }: ColumnsType): Array<ProColumns<ProductItem>> => [
  {
    title: t?.('col_product_name'),
    dataIndex: 'name',
    ellipsis: true,
    hideInSetting: true,
    width: 230,
  },
  {
    title: t?.('col_current_inventory'),
    dataIndex: 'currentInventory',
    sorter: true,
    ellipsis: true,
    width: 190,
  },
  {
    title: t?.('col_shipped_inventory'),
    dataIndex: 'shippedInventory',
    sorter: true,
    ellipsis: true,
    width: 190,
  },
  {
    title: t?.('col_recent_activity'),
    dataIndex: 'recentActivity',
    sorter: true,
    ellipsis: true,
    width: 190,
  },
  {
    title: t?.('col_shared_title'),
    dataIndex: 'tags',
    render: (text, record) =>
      record.shared?.map((sharedItem) => <Tag color="geekblue">{sharedItem}</Tag>) || '',
    hideInTable: true,
  },
  {
    dataIndex: 'actions',
    hideInSetting: true,
    valueType: 'option',
    render: (text, record) => (
      <Button
        className="actions"
        disabled={!onRowActionClick}
        onClick={() => onRowActionClick?.(record)}
        type="primary"
        ghost
        size="small"
      >
        {t('move_to_products')}
      </Button>
    ),
    width: 150,
  },
];
const Products: FC<ArchivedProps> = ({ onRowSelectionChange, selectedRowKeys }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  const actionRef = useRef<ActionType>();
  const { onUnarchiveProduct, loading } = useProductActions();
  const {
    params,
    records,
    totalItems,
    isLoading,
    filters: [productSearchfilter],
    onTableChange,
    isFetching,
  } = useProductFilters({
    showArchived: true,
  });

  const onRowActionClick = async (actionPayload?: ProductItem) => {
    onUnarchiveProduct(actionPayload);
  };

  return (
    <GTable<ProductItem>
      actionRef={actionRef}
      columns={columns({
        onRowActionClick: loading || isFetching ? undefined : onRowActionClick,
        productSearchfilter,
        t,
      })}
      loading={isLoading}
      enableRowSelection
      value={records}
      pagination={{
        defaultPageSize: params.pageSize,
        current: params?.pageNumber,
        total: totalItems,
        showTotal: (total, range) =>
          total <= 1
            ? t('product_pagi', { range0: range[0], range1: range[1], total })
            : t('product_pagi_archived', { range0: range[0], range1: range[1], total }),
      }}
      scroll={{ y: '75vh', x: 980 }}
      onTableChange={onTableChange}
      rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange }}
      options={{
        setting: false,
        reload: false,
      }}
      columnsState={{
        persistenceKey: 'archived-products',
      }}
    />
  );
};
export default Products;

import { ProFormColumnsType } from '@ant-design/pro-form';
import { DataItem, EventErrorFieldProps } from './typings';

const colProps2Cols = { xs: 24, sm: 24, md: 16, lg: 20 };

const Fields = ({ t }: EventErrorFieldProps): ProFormColumnsType<DataItem>[] => [
  {
    title: t('event_error_desc'),
    dataIndex: 'errorType',
    valueType: 'radio',
    colProps: colProps2Cols,
    fieldProps: {
      options: [
        {
          label: t('event_error_option1'),
          value: 'NeverOccured',
        },
        {
          label: t('event_error_option2'),
          value: 'IncorrectData',
        },
      ],
    },
  },
];
export default Fields;

import { create } from 'zustand';
import { AttributeStore } from '../../typings';

const useDocumentAttributeStore = create<AttributeStore>((set) => ({
  attribute: {
    id: '',
    name: '',
    required: false,
    dataAttribute: '',
    fieldType: '',
    valueOptions: [],
  },
  setAttribute: (attribute) => set(() => ({ attribute })),
}));

export default useDocumentAttributeStore;

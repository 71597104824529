import { InfoCircleOutlined } from '@ant-design/icons';
import { RowProps, message } from 'antd';
import {
  BizStepSelect,
  DispositionSelect,
  LocationSelect,
  MultipleDatesFormItem,
  TemplateSelect,
  TimezoneSelect,
  TradePartnerSelect,
} from 'components';

import { TradePartnerResponse } from 'services/api/client/src';
import {
  checkSameDate,
  concatDateTime,
  getDisabledDate,
  getDisabledTimeDependency,
  getProductsT,
  getTemplateFields,
  onChangeShipFieldDate,
} from 'utils';
import { CertDocuments, DataTemplate, Documents, EventDetails } from '../Sections';
import DataEntryMethod from './DataEntryMethod';
import { ShipProducts } from './TransformTables';
import { ShipDataItem as DataItem, ShipFormFieldsFn } from './typings';

const colProps = { xs: 24, sm: 12, md: 12, lg: 6 };
const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const colPropsFullCsv = { xs: 24, sm: 24, md: 24, lg: 24 };

const rowProps: RowProps = { gutter: [32, 24] };

export const ShipFormFields: ShipFormFieldsFn = ({
  t,
  onChangeDate,
  disabledTime,
  disabledDate,
  onChangeTemplate,
  timeValidation,
  editableTableFormRef,
  defaultTimezone,
  hasAdvancedSettings,
  dataEntryMethod,
  lastEventDate,
  locationId,
  product,
  template,
  isSerial,
  identifier,
  selectedInstances,
  containerIdArray,
}) => {
  const isManualEntry = dataEntryMethod === 'manual';

  return [
    {
      renderFormItem: () => <EventDetails />,
    },

    {
      valueType: 'dependency',
      fieldProps: {
        name: ['location', 'customData'],
      },
      columns: ({ location }: DataItem) => [
        {
          dataIndex: 'isMultipleDates',
          valueType: 'switch',
          renderFormItem: (row, config, form) => (
            <MultipleDatesFormItem
              form={form}
              title={t('form_fields.multiple_dates_title')}
              dataIndex="isMultipleDates"
            />
          ),
          colProps: {
            xs: 24,
            md: 24,
          },
          hideInForm: !isManualEntry,
        },
        {
          title: t('form_fields.custom_data_title'),
          dataIndex: 'customData',
          fieldProps: {
            placeholder: t('form_fields.custom_data_placeholder'),
          },
          formItemProps: {
            rules: [
              {
                required: false,
                message: t('form_fields.custom_data_req'),
              },
            ],
          },
          renderFormItem: (row, config, form) => (
            <TemplateSelect
              onChange={(value, option) =>
                onChangeTemplate?.(form, option, value, location, 'ship')
              }
            />
          ),
          colProps,
        },
        {
          title: t('form_fields.data_entry_title'),
          dataIndex: 'dataEntryMethod',
          renderFormItem: () => (
            <DataEntryMethod disabled={containerIdArray && containerIdArray?.length > 1} />
          ),
          colProps: {
            xs: 24,
            md: 18,
          },
        },
      ],
    },

    {
      valueType: 'dependency',
      fieldProps: {
        name: ['date', 'isMultipleDates', 'shippingCompany'],
      },
      columns: ({ date = undefined, isMultipleDates, shippingCompany }: DataItem) => [
        {
          title: t('form_fields.date_title'),
          valueType: 'date',
          dataIndex: 'date',
          initialValue: null,
          hideInForm: !isManualEntry || isMultipleDates,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.date_req'),
              },
            ],
          },
          fieldProps: (form) => ({
            placeholder: t('form_fields.date_placeholder'),
            className: 'full-width',
            disabledDate,
            onChange: (value: string) => {
              onChangeDate?.(form, value);
              form?.setFieldsValue({
                receiveTime: '12:00:00',
                receiveDate: undefined,
              });
            },
          }),
          colProps,
        },
        {
          title: t('form_fields.time_title'),
          valueType: 'time',
          dataIndex: 'time',
          initialValue: '12:00:00',
          className: 'timecol',
          hideInForm: !isManualEntry || isMultipleDates,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_req'),
              },
              ...(timeValidation?.(date) || []),
            ],
          },
          fieldProps: (form) => ({
            placeholder: t('form_fields.time_placeholder'),

            className: 'full-width',
            onChange: () => {
              form?.setFieldsValue({
                receiveTime: '12:00:00',
                receiveDate: undefined,
              });
            },
            // check is same day as date
            disabledTime: checkSameDate(date, lastEventDate) ? disabledTime : undefined,
          }),

          colProps,
        },
        {
          title: t('form_fields.shippingcompany_title'),
          dataIndex: 'shippingCompany',
          hideInForm: !isManualEntry,
          fieldProps: {
            placeholder: t('form_fields.shippingcompany_placeholder'),
          },
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.shippingcompany_req'),
              },
            ],
          },
          renderFormItem: (row, config, form) => (
            <TradePartnerSelect
              onChange={(value, option) => {
                // @ts-ignore
                const item: TradePartnerResponse = option?.itemProps;
                form?.setFieldsValue({
                  shippingCompany: value,
                  shippingAddress: undefined,
                  isOwnerAccount: item?.isOwnerAccount || false,
                  // eslint-disable-next-line no-nested-ternary
                  hasWholechainAccount: !item?.isOwnerAccount
                    ? item && Object.prototype.hasOwnProperty.call(item, 'hasWholechainAccount')
                      ? item.hasWholechainAccount
                      : true
                    : true,
                  anotherTracebility:
                    (!!item?.digitalLinkUrl && item.type === 'NonWholechain') || false,
                });
              }}
            />
          ),
          colProps,
        },
        {
          title: t('form_fields.shipping_address_title'),
          dataIndex: 'shippingAddress',
          hideInForm: !isManualEntry,
          fieldProps: {
            placeholder: t('form_fields.shipping_address_placeholder'),
            onChange: (value?: string) => {
              if (value === locationId) {
                message.error(t('form_fields.same_shipping_address'));
              }
            },
          },
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.shipping_address_req'),
              },
              {
                validator: (_, value) => {
                  if (value) {
                    if (value === locationId) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
                message: t('form_fields.shipping_address_invalid'),
              },
            ],
          },
          renderFormItem: () => <LocationSelect partnerId={shippingCompany} />,
          colProps,
        },
      ],
    },

    {
      title: t('form_fields.time_zone_title'),
      dataIndex: 'timeZone',
      valueType: 'select',
      initialValue: defaultTimezone,
      fieldProps: {
        placeholder: t('form_fields.time_zone_placeholder'),
      },
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.time_zone_req'),
          },
        ],
      },
      renderFormItem: () => <TimezoneSelect />,

      colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
    },
    {
      title: t('form_fields.bizstep_title'),
      dataIndex: 'bizStep',
      initialValue: 'urn:epcglobal:cbv:bizstep:shipping',
      valueType: 'select',
      renderFormItem: () => <BizStepSelect />,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.bizstep_req'),
          },
        ],
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.bizstep_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
    },
    {
      title: t('form_fields.disposition_title'),
      dataIndex: 'disposition',
      initialValue: 'urn:epcglobal:cbv:disp:in_transit',
      valueType: 'select',
      renderFormItem: () => <DispositionSelect />,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.disposition_req'),
          },
        ],
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.disposition_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
    },

    {
      valueType: 'dependency',
      fieldProps: {
        name: [
          'autologReceiveEvent',
          'isOwnerAccount',
          'hasWholechainAccount',
          'isMultipleDates',
          'date',
          'time',
          'receiveDate',
          'anotherTracebility',
        ],
      },
      columns: ({
        autologReceiveEvent,
        isOwnerAccount,
        hasWholechainAccount,
        isMultipleDates,
        date,
        time,
        receiveDate,
        anotherTracebility,
      }: DataItem) => [
        {
          dataIndex: 'eventProducts',
          title: `${getProductsT('event_details.product_title')}: ${product?.name || ''} (${
            product?.id || ''
          })`,
          initialValue: [],
          renderFormItem: (_schema, _config, form) => (
            <ShipProducts
              form={form}
              multipleDates={isMultipleDates}
              isSerial={isSerial}
              identifier={identifier}
              selectedInstances={selectedInstances}
              product={product}
              locationId={locationId}
              autologReceiveEvent={autologReceiveEvent}
            />
          ),
          colProps: {
            xs: 24,
            md: 24,
          },
          hideInForm: !isManualEntry,
        },
        {
          valueType: 'divider',
          // eslint-disable-next-line no-nested-ternary
          hideInForm: isManualEntry
            ? isOwnerAccount
              ? false
              : anotherTracebility || hasWholechainAccount
            : false,
        },
        {
          valueType: 'group',
          title: t('form_fields.receive_grp_title'),
          // eslint-disable-next-line no-nested-ternary
          hideInForm: isManualEntry
            ? isOwnerAccount
              ? false
              : anotherTracebility || hasWholechainAccount
            : false,
          rowProps,
          columns: [
            {
              dataIndex: 'autologReceiveEvent',
              initialValue: false,
              renderFormItem: (_schema, _config, form) => (
                <MultipleDatesFormItem
                  form={form}
                  title={t('form_fields.autolog_receive_title')}
                  dataIndex="autologReceiveEvent"
                />
              ),
              colProps: colPropsFull,
            },
            {
              title: t('form_fields.date_title'),
              valueType: 'date',
              dataIndex: 'receiveDate',
              hideInForm: !autologReceiveEvent || isMultipleDates || !isManualEntry,
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.date_req'),
                  },
                ],
              },
              fieldProps: (form) => ({
                className: 'full-width',
                placeholder: t('form_fields.date_placeholder'),
                onChange: (val: string) => onChangeShipFieldDate(form, val, date),
                disabledDate: getDisabledDate(date),
                disabled: !date,
              }),
              colProps,
            },
            {
              title: t('form_fields.time_title'),
              valueType: 'time',
              dataIndex: 'receiveTime',
              initialValue: '12:00:00',
              className: 'timecol',
              hideInForm: !autologReceiveEvent || isMultipleDates || !isManualEntry,
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.time_req'),
                  },
                  ...(timeValidation?.(receiveDate, concatDateTime(date, time)) || []),
                ],
              },
              fieldProps: {
                className: 'full-width',
                placeholder: t('form_fields.time_placeholder'),
                disabledTime: checkSameDate(date, receiveDate)
                  ? getDisabledTimeDependency(time)
                  : undefined,
                disabled: !date || !time,
              },
              colProps,
            },
          ],
        },
      ],
    },

    {
      valueType: 'divider',
    },
    {
      dataIndex: 'csvData',
      renderFormItem: (row, record, form) => (
        <DataTemplate form={form} editableTableFormRef={editableTableFormRef} />
      ),
      colProps: colPropsFullCsv,
    },
    {
      valueType: 'formSet',
      dataIndex: 'customProperties',
      initialValue: [],
      columns: getTemplateFields(template),
      rowProps,
      hideInForm: !template,
    },
    {
      dataIndex: 'certificationList',
      rowProps,
      renderFormItem: (row, record, form) => <CertDocuments form={form} />,
      hideInForm:
        !template || !template?.templateAttributes || !template?.allowCertificationDocuments,
    },
    {
      valueType: 'divider',
    },
    {
      dataIndex: 'documents',
      initialValue: [],
      renderFormItem: (_row, _config, form) => <Documents form={form} />,
      colProps: colPropsFull,
    },
    /* hidden fields */
    {
      dataIndex: 'isOwnerAccount',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: colPropsNull,
    },
    {
      dataIndex: 'anotherTracebility',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: colPropsNull,
    },
    {
      initialValue: true,
      dataIndex: 'hasWholechainAccount',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: colPropsNull,
    },
  ];
};

export default ShipFormFields;

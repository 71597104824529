import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ProList, { ProListMetas } from '@ant-design/pro-list';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import {
  Button,
  FormInstance,
  Modal,
  PageHeader,
  Space,
  Switch,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import ActionButtons from 'components/ActionButtons';
import CopyText from 'components/CopyText';
import GTable from 'components/GTable';
import UpgradPlanModal from 'components/Settings/SubscriptionBilling/UpgradePlanModal';
import { useTranslationContext } from 'contexts/TranslationProvider';
import useModalVisibility from 'hooks/useModalVisibility';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import InboundAPIKey from 'pages/Network/forms/AddEdit/GenerateAPIKey/InboundAPIKey';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Address, useHasActiveInventoryCheck, useTradePartners } from 'services/api';
import { TradePartnerResponse, UpdateAccountRequest } from 'services/api/client/src';
import useAccount, { useUpdateAccount } from 'services/api/useAccount';
import { getAddress, getGeocode } from 'services/api/usePlaces';
import {
  errorHandler,
  flattenAddress,
  getPhoneCodeObject,
  getPhoneNumberWithoutCode,
  hiddencol,
  supportEmailLink,
} from 'utils';
import { getVariableFromEnv } from 'utils/config';
import { PreferenceItem } from '../Preferences/typings';
import styles from '../index.module.less';
import FormItem from './FormItem';
import { AccountAttribute, ActionProps, IntegrationSettings } from './typings';

const staticDigitalLinkUrl = getVariableFromEnv('DIGITAL_URL_LINK');

type ColumnsType = {
  t: TFunction<'pages', 'setting.account_settings'>;
  onEditRow: (actionPayload?: AccountAttribute) => void;
  onChangePlace: (
    value: string,
    option: DefaultOptionType | DefaultOptionType[],
    recordKey: string,
    form:
      | FormInstance<{
          [key: string]: AccountAttribute;
        }>
      | undefined,
  ) => void;
};
type ColumnsIntegrationType = {
  t: TFunction<'pages', 'setting.account_settings'>;
  selectedCompany?: TradePartnerResponse;
};

const Actions: FC<ActionProps> = ({ onEditRow, record }) => {
  switch (record?.id) {
    case 'address':
      return <ActionButtons showEdit showDelete={false} record={record} onEdit={onEditRow} />;

    case 'phone':
      return <ActionButtons showEdit showDelete={false} record={record} onEdit={onEditRow} />;

    case 'timezone':
      return <ActionButtons showEdit showDelete={false} record={record} onEdit={onEditRow} />;

    case 'wholechainId':
      return (
        <Button type="text" size="small" className="actions">
          <CopyText text={String(record?.value) || ''} />
        </Button>
      );

    case 'digitalLinkURL':
      return (
        <Button type="text" size="small" className="actions">
          <CopyText text={String(record?.value) || ''} />
        </Button>
      );

    default:
      return null;
  }
};
const Columns = ({
  t,
  onEditRow,
  onChangePlace,
}: ColumnsType): Array<ProColumns<AccountAttribute>> => [
  {
    title: t('tbl_col_id_title'),
    dataIndex: 'id',
    hideInTable: true,
  },
  {
    title: t('tbl_col_attribute_title'),
    dataIndex: 'attribute',
    className: 'attribute-cell',
    editable: false,
    width: '29%',
  },
  {
    dataIndex: 'address',
    ...hiddencol,
  },
  {
    dataIndex: 'phoneCode',
    ...hiddencol,
  },
  {
    dataIndex: 'coordinates',
    ...hiddencol,
  },
  {
    title: t('tbl_col_value_title'),
    dataIndex: 'value',
    fieldProps: {
      size: 'small',
    },
    width: '52%',
    ellipsis: true,
    renderFormItem: (item, { record, recordKey }, form) => (
      <FormItem
        record={record}
        recordKey={recordKey}
        form={form}
        onChangePlace={onChangePlace}
        t={t}
      />
    ),
    formItemProps: (form, { rowKey }) => {
      const recordKey = Array.isArray(rowKey) ? rowKey?.[0] : rowKey;
      let reqKey = '';
      const phoneISO = (form?.getFieldValue('phone')?.phoneISO || 'US') as CountryCode;
      switch (recordKey) {
        case 'phone':
          reqKey = t('company_phone_title');
          break;

        case 'timezone':
          reqKey = t('timezone_title');
          break;

        case 'address':
          reqKey = t('address_title');
          break;

        default:
          reqKey = t('address_title');

          break;
      }
      return {
        rules: [
          {
            required: true,
            message: `${t('please_input')} ${reqKey}.`,
          },
          {
            message: t('valid_phone_number'),
            validator: (_, value) => {
              if (recordKey !== 'phone') {
                return Promise.resolve();
              }

              if (!value?.trim?.()) {
                return Promise.resolve();
              }
              if (isValidPhoneNumber(value, phoneISO)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('valid_phone_number')));
            },
          },
        ],
      };
    },
  },
  {
    dataIndex: 'phoneISO',
    ...hiddencol,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    className: 'right-aligned-cell',
    width: '18%',
    render: (_, record) => <Actions record={record} onEditRow={onEditRow} />,
  },
];

const ColumnsIntegration = ({
  t,
  selectedCompany,
}: ColumnsIntegrationType): Array<ProColumns<IntegrationSettings>> => [
  {
    title: t('tbl_col_id_title'),
    dataIndex: 'id',
    hideInTable: true,
  },
  {
    title: t('tbl_col_attribute_title'),
    dataIndex: 'attribute',
    className: 'attribute-cell',
    editable: false,
    width: '29%',
  },
  {
    title: t('tbl_col_value_title'),
    dataIndex: 'value',
    fieldProps: {
      size: 'small',
    },
    width: '52%',
    ellipsis: true,
    render: (_, record) =>
      record?.id === 'apiKey' ? (
        <InboundAPIKey
          tradePartnerId={selectedCompany?.id || ''}
          disabled={!selectedCompany?.digitalLinkUrl != null}
          isWhiteButton
          updateTradePartner
          buttonSize="small"
        />
      ) : (
        record?.value || ''
      ),
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    className: 'right-aligned-cell',
    width: '18%',
    render: (_, record) => <Actions record={record} />,
  },
];

const AccountSettings = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.account_settings' });
  const actionRef = useRef<ActionType>();
  const queryClient = useQueryClient();
  const updateUser = useUpdateAccount(queryClient);
  const checkActiveInventory = useHasActiveInventoryCheck();
  const [disable, setDisable] = useState<boolean>();
  const { data: accountData, isLoading } = useAccount();
  const { data: dtradePartners } = useTradePartners({
    types: ['Self'],
  });
  const tradePartners = useMemo(() => dtradePartners?.data || [], [dtradePartners?.data]);
  const { hideGoogleMap } = useTranslationContext();
  const upgradeModal = useModalVisibility(false);
  const selectedCompany = useMemo(() => tradePartners?.[0], [tradePartners]);
  const showOrphan = true;
  const onChangePlace = useCallback(
    async (
      value: string,
      option: DefaultOptionType | DefaultOptionType[],
      recordKey: string,
      form:
        | FormInstance<{
            [key: string]: AccountAttribute;
          }>
        | undefined,
    ) => {
      // @ts-ignore
      const item: window.google.maps.places.AutocompletePrediction = option?.itemProps;
      const geoCode = await getGeocode(item?.place_id);
      const address = getAddress(geoCode?.[0]?.address_components || []);
      const location = geoCode?.[0]?.geometry?.location;

      form?.setFieldsValue({
        [recordKey]: {
          value,
          address: {
            line1: address?.addressLine1 || '',
            line2: address?.addressLine2 || '',
            city: address?.city || '',
            state: address?.state || '',
            country: address?.country || '',
            postalCode: address?.postalCode || '',
          } as Address,
          coordinates: {
            latitude: Number(location?.lat?.() || 0),
            longitude: Number(location?.lng?.() || 0),
          },
        },
      });
    },
    [],
  );

  const data: Array<AccountAttribute> = [
    {
      id: 'accountName',
      attribute: (
        <span>
          {t('account_name_title')} &nbsp;
          <Tooltip title={t('account_name_tooltip')}>
            <InfoCircleOutlined className={styles.infoIcon} />
          </Tooltip>
        </span>
      ),
      value: accountData?.name || '',
    },
    {
      id: 'wholechainId',
      attribute: t('wholechain_id_title'),
      value: accountData?.id || '',
    },
    {
      id: 'address',
      attribute: t('address_title'),
      value: flattenAddress(accountData?.address),
      address: accountData?.address,
    },
    {
      id: 'geoCordinates',
      attribute: t('geocoordinates_title'),
      value: `${accountData?.address?.geoCoordinates?.latitude || 37.0592}, ${
        accountData?.address?.geoCoordinates?.longitude || -77.0364
      }`,
    },
    {
      id: 'timezone',
      attribute: t('timezone_title'),
      value: accountData?.timezone || '(UTC-05:00) Eastern Time (US & Canada)',
    },
    {
      id: 'phone',
      attribute: t('company_phone_title'),
      value: accountData?.phone?.includes('undefined') ? '' : accountData?.phone || '',
      phoneISO: getPhoneCodeObject(accountData?.phone || '')?.iso || 'US',
      phoneCode: getPhoneCodeObject(accountData?.phone || '')?.code || '+1',
      phone: getPhoneNumberWithoutCode(accountData?.phone || ''),
    },
  ];

  const dataIntegration = useMemo(
    () => [
      {
        id: 'digitalLinkURL',
        attribute: (
          <span>
            {t('digital_link_url_title')} &nbsp;
            <Tooltip title={t('digital_link_url_tooltip')}>
              <InfoCircleOutlined className={styles.infoIcon} />
            </Tooltip>
          </span>
        ),
        value: staticDigitalLinkUrl,
      },
      {
        id: 'apiKey',
        attribute: (
          <span>
            {t('api_key_title')} &nbsp;
            <Tooltip title={t('api_key_tooltip')}>
              <InfoCircleOutlined className={styles.infoIcon} />
            </Tooltip>
          </span>
        ),
        value: '',
      },
    ],
    [t],
  );

  const onEditRow = (actionPayload?: AccountAttribute) => {
    if (
      actionPayload?.id === 'address' ||
      actionPayload?.id === 'phone' ||
      actionPayload?.id === 'timezone'
    ) {
      if (actionPayload?.id === 'address') {
        if (hideGoogleMap) {
          upgradeModal?.show();
        } else {
          actionRef.current?.startEditable(actionPayload?.id || '');
        }
      } else {
        actionRef.current?.startEditable(actionPayload?.id || '');
      }
    }
  };
  const onSaveRow = async (rowKey: RecordKey, actionPayload: AccountAttribute) => {
    try {
      const reqData: UpdateAccountRequest = {
        name: rowKey === 'name' ? String(actionPayload.value) : accountData?.name || '',
        address:
          rowKey === 'address'
            ? { ...actionPayload?.address, geoCoordinates: actionPayload?.coordinates }
            : accountData?.address,

        phone:
          rowKey === 'phone'
            ? `+${actionPayload?.phoneCode?.replace('+', '')} ${actionPayload?.phone || ''}`
            : accountData?.phone || '',
        timezone: rowKey === 'timezone' ? String(actionPayload.value) : accountData?.timezone || '',
        vesselEnabled:
          rowKey === 'vesselEnabled' ? Boolean(actionPayload.value) : accountData?.vesselEnabled,
        resolveOrphansEnabled:
          rowKey === 'resolveOrphansEnabled'
            ? Boolean(actionPayload.value)
            : accountData?.resolveOrphansEnabled,
      };
      await updateUser.mutateAsync(reqData);
      setDisable(false);
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  const networkArray: Array<PreferenceItem> = useMemo(
    () => [
      {
        id: 'isVessel',
        title: t('vessels'),
        description: t('vessel_description'),
        value: accountData?.vesselEnabled,
      },
    ],
    [accountData?.vesselEnabled, t],
  );

  const reportsArray: Array<PreferenceItem> = useMemo(
    () => [
      {
        id: 'isOrphan',
        title: (
          <span>
            {t('orphans')} &nbsp;
            <Tooltip title={t('orphan_tooltip')} placement="bottom">
              <InfoCircleOutlined className={styles.infoIcon} />
            </Tooltip>
          </span>
        ),
        description: t('orphans_description'),
        value: accountData?.resolveOrphansEnabled,
      },
    ],
    [accountData?.resolveOrphansEnabled, t],
  );

  const onVesselSwitch = async (flag: boolean) => {
    setDisable(true);
    if (flag) {
      onSaveRow('vesselEnabled', { value: flag });
    } else {
      try {
        const res = await checkActiveInventory.mutateAsync({
          params: { isVessel: true },
        });
        if (res?.length) {
          Modal.info({
            title: t('vessel_warning.active_inventory_title'),
            content: t('vessel_warning.active_inventory_content'),
            okText: t('vessel_warning.done'),
            cancelButtonProps: {
              hidden: true,
            },
            okButtonProps: {
              type: 'primary',
              shape: 'round',
            },
            onOk: () => setDisable(false),
            centered: true,
            okCancel: false,
          });
        } else {
          Modal.info({
            title: t('vessel_warning.consent_title'),
            content: t('vessel_warning.consent_content'),
            okText: t('vessel_warning.continue'),
            cancelText: t('vessel_warning.cancel'),
            cancelButtonProps: {
              type: 'primary',
              shape: 'round',
              ghost: true,
            },
            okButtonProps: {
              type: 'primary',
              shape: 'round',
            },
            centered: true,
            okCancel: true,
            onCancel: () => setDisable(false),
            onOk: () => {
              onSaveRow('vesselEnabled', { value: flag });
            },
          });
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    }
  };

  const listMetasNetwork: ProListMetas<PreferenceItem> = {
    title: {
      dataIndex: 'title',
      render: (dom, record) => <Typography.Text>{record?.title || ''}</Typography.Text>,
    },
    description: {
      dataIndex: 'description',
      render: (text, record) => <div className="list-desc">{record.description}</div>,
    },
    actions: {
      render: (text, record) => (
        <Switch
          checked={record?.value || false}
          onClick={(value) => onVesselSwitch(value)}
          loading={disable}
        />
      ),
    },
  };

  const listMetasReports: ProListMetas<PreferenceItem> = {
    title: {
      dataIndex: 'title',
      render: (dom, record) => <Typography.Text>{record?.title || ''}</Typography.Text>,
    },
    description: {
      dataIndex: 'description',
      render: (text, record) => <div className="list-desc">{record.description}</div>,
    },
    actions: {
      render: (text, record) => (
        <Switch
          checked={record?.value || false}
          onClick={(value) => onSaveRow('resolveOrphansEnabled', { value })}
          loading={disable}
        />
      ),
    },
  };

  return (
    <>
      {upgradeModal.visible && (
        <UpgradPlanModal
          modal={upgradeModal}
          title={
            <Space>
              <ExclamationCircleOutlined className={styles.orange} />
              {t('china_support_title')}
            </Space>
          }
          description={
            <div>
              {`${t('china_support_desc_text')} `}
              <a href={supportEmailLink} type="mail">
                {t('china_support_anchor_email')}
              </a>
              .
            </div>
          }
          descPlaceholder={t('china_support_desc_placeholder')}
        />
      )}
      <PageHeader title={t('title')} className={styles['page-header']} />
      <GTable<AccountAttribute>
        actionRef={actionRef}
        columns={Columns({ t, onEditRow, onChangePlace })}
        editable={{
          onSave: (rowKey, actionPayload) => onSaveRow(rowKey, actionPayload),
          onCancel: async (_rowKey, actionPayload) => {
            actionRef.current?.cancelEditable(actionPayload?.id || 0);
          },
        }}
        options={{
          reload: false,
          setting: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        recordCreatorProps={false}
        loading={isLoading}
        value={data}
        showHeader={false}
        columnsState={{
          persistenceKey: 'account-settings',
        }}
      />
      <PageHeader title={t('network')} className={styles['page-header-margin']} />
      <ProList
        itemLayout="horizontal"
        rowKey="id"
        split
        dataSource={networkArray}
        className={styles.alist}
        metas={listMetasNetwork}
      />
      {showOrphan && (
        <>
          <PageHeader title={t('reports')} className={styles['page-header-margin']} />
          <ProList
            itemLayout="horizontal"
            rowKey="id"
            split
            dataSource={reportsArray}
            className={styles.alist}
            metas={listMetasReports}
          />
        </>
      )}
      <PageHeader title={t('integration_settings')} className={styles['page-header-margin']} />
      <GTable<IntegrationSettings>
        columns={ColumnsIntegration({ t, selectedCompany })}
        options={{
          reload: false,
          setting: false,
        }}
        value={dataIntegration}
        recordCreatorProps={false}
        showHeader={false}
      />
    </>
  );
};

export default React.memo(AccountSettings);

import { ExclamationCircleOutlined } from '@ant-design/icons';
import type { ParamsType } from '@ant-design/pro-provider';
import { ProColumns } from '@ant-design/pro-table';
import type { EditableProTableProps } from '@ant-design/pro-table/lib/components/EditableTable';
import { TablePaginationConfig, Tag, Tooltip } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions } from 'components';
import GTable from 'components/GTable';
import useListLocation from 'hooks/useListLocation';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import useDeleteActions from 'pages/Network/hook/useDeleteActions';
import useLocationStore from 'pages/Network/hook/useLocationStore';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { GetLocationsOptionalParams } from 'hooks/useListLocation/typings';
import useTradePartners from 'services/api/useTradePartners';
import { FilterArrayProp, NetworkItem } from '../../../typings';
import EmptyState from '../../EmptyState';
import styles from '../AttributeTable/index.module.less';
import { GTableProps } from './typings';

interface LocationTableProps<DataType, Params, ValueType>
  // @ts-ignore
  extends EditableProTableProps<DataType, Params, ValueType> {
  isActionColumn?: boolean;
  // eslint-disable-next-line react/require-default-props
  onShareLocation?: (record: NetworkItem) => void;
  // eslint-disable-next-line react/require-default-props
  onEmptyLocation?: (isEmpty: boolean) => void;
  isMyLocations?: boolean;
}

const LocationTable = <
  DataType extends NetworkItem,
  Params extends ParamsType = ParamsType,
  ValueType = 'text',
>({
  isMyLocations,
  isActionColumn,
  onShareLocation,
  onEmptyLocation,
  ...props
}: LocationTableProps<DataType, Params, ValueType> & GTableProps) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.location_table' });
  const locationData = useLocationStore();
  const { companyId = '1' } = useParams();
  const { archiveSingleLocation } = useDeleteActions();
  const navigate = useNavigate();
  const { data: tradePartnerList } = useTradePartners({
    types: ['Wholechain'],
  });
  const initfilter = isMyLocations
    ? {
        tradePartnerStatus: ['Self'],
      }
    : {
        partnerIds: [String(companyId || locationData?.companyId)],
      };
  const initParam = isActionColumn
    ? {
        pageNumber: 1,
        pageSize: 5,
      }
    : {};

  const [param, setParam] = useState<GetLocationsOptionalParams>(initParam);
  const {
    data,
    isLoading: isLocationsLoading,
    refetch,
  } = useListLocation({ ...initfilter, ...param });

  const companyLocations = useMemo(() => data || [], [data]);

  const shareWithFilter: Array<FilterArrayProp> = useMemo(
    () =>
      tradePartnerList?.data?.map((item) => ({
        text: item.name || '',
        value: item.wholechainId || '',
      })) || [],
    [tradePartnerList],
  );

  const { filters: nameSearchfilter } = useTableSearchFilter({
    title: t('location_name'),
  });
  const { filters: addressSearchfilter } = useTableSearchFilter({
    title: t('location_address'),
  });

  const records: Array<NetworkItem> = useMemo(
    () =>
      companyLocations?.map((item) => ({
        id: item.id || '',
        name: item.name,
        address:
          item.address?.displayAddress ||
          `${item.address?.geoCoordinates?.latitude},${item.address?.geoCoordinates?.longitude}`,
        isOwnAccount: item.networkStatus === 'Self',
        ownerHasWholechainAccount: !!item.wholechainId,
        sharedWith: item.sharedWith,
        isShared: item?.sharedWith?.length !== 0,
      })) || [],
    [companyLocations],
  );

  const actionItems = (record: NetworkItem): ItemType[] => [
    {
      key: 'edit',
      label: t('actions.edit'),
    },
    ...(record.isOwnAccount
      ? [
          {
            key: 'share',
            label: t('actions.share'),
          },
        ]
      : []),
  ];

  const handleActionsMenuClick = async (actionItemKey: string, actionPayload?: NetworkItem) => {
    switch (actionItemKey) {
      case 'edit':
        navigate(`editLocation`);
        break;
      case 'delete':
        archiveSingleLocation(String(actionPayload?.id || ''));
        break;
      case 'share':
        if (onShareLocation) {
          onShareLocation(actionPayload || { id: '' });
        }
        break;

      default:
        break;
    }
  };

  const columns: ProColumns<NetworkItem>[] = isActionColumn
    ? [
        {
          title: t('location_name'),
          dataIndex: 'name',
          fixed: 'left',
          width: '30%',
          render: (value, record) => (
            <span>
              {value || ''}
              {record?.ownerHasWholechainAccount && !record?.isShared && !isMyLocations && (
                <Tooltip title={t('location_name_tooltip')}>
                  <ExclamationCircleOutlined className={styles.locationtooltip} />
                </Tooltip>
              )}
            </span>
          ),
          ...nameSearchfilter,
        },
        {
          title: t('location_address'),
          dataIndex: 'address',
          ellipsis: true,
          width: '30%',
          ...addressSearchfilter,
        },
        {
          title: t('shared'),
          dataIndex: 'sharedWith',
          hideInTable: !isMyLocations,
          filters: shareWithFilter,
          width: '30%',
          render: (text, options) =>
            options.sharedWith?.length !== 0 ? (
              <Tooltip
                placement="bottom"
                title={
                  <div>
                    {options?.sharedWith?.map((option) => (
                      <div key={option?.wholechainId}>{option?.accountName}</div>
                    )) || ''}
                  </div>
                }
              >
                <Tag className="blue-tag">
                  {options?.sharedWith?.length === 1
                    ? t('shared_with_single', {
                        text: options?.sharedWith[0]?.accountName,
                      })
                    : t('shared_with', { text: options?.sharedWith?.length })}
                </Tag>
              </Tooltip>
            ) : null,
        },
        {
          title: '',
          hideInSetting: true,
          render: (text, record) => (
            <Actions
              className="actions"
              buttonProps={{
                ghost: true,
                size: 'small',
              }}
              items={actionItems(record)}
              onClick={handleActionsMenuClick}
              actionPayload={record}
            />
          ),
          fixed: 'right',
          width: '7%',
        },
      ]
    : [
        {
          title: t('location_name'),
          dataIndex: 'name',
          fixed: 'left',
          width: '47%',
          ellipsis: true,
          ...nameSearchfilter,
        },
        {
          title: t('location_address'),
          dataIndex: 'address',
          width: '47%',
          ...addressSearchfilter,
          ellipsis: true,
        },
      ];

  const isEmpty = useMemo(() => {
    const emptyStatus = !isLocationsLoading && records?.length === 0;
    if (onEmptyLocation) {
      onEmptyLocation(emptyStatus);
    }
    return emptyStatus;
  }, [isLocationsLoading, onEmptyLocation, records?.length]);

  if (isEmpty && !param?.locationName && !param?.sharedWith?.length) {
    return <EmptyState hideButton />;
  }

  const onTableChange = (
    params: TablePaginationConfig,
    filter: Record<string, Array<string> | null>,
  ) => {
    const paginate = isActionColumn
      ? { pageNumber: params?.current || 1, pageSize: params?.pageSize || 5 }
      : {};
    const nameFilter = filter?.name
      ? {
          locationName: filter?.name?.[0] as string,
        }
      : {};
    const addressFilter = filter?.address
      ? {
          locationName: filter?.address?.[0] as string,
        }
      : {};
    const sharedWithFilter = filter?.sharedWith
      ? {
          sharedWith: filter?.sharedWith,
        }
      : {};
    setParam({
      ...paginate,
      ...nameFilter,
      ...addressFilter,
      ...sharedWithFilter,
    });
  };

  return (
    <>
      <GTable<DataType, Params, ValueType>
        {...props}
        {...(isActionColumn
          ? {
              options: {
                setting: true,
                reload: () => refetch(),
              },
            }
          : {})}
        columns={columns as ProColumns<DataType, ValueType>[]}
        value={records as DataType[]}
        loading={isLocationsLoading}
        scroll={{ y: '334px' }}
        {...(isActionColumn
          ? {
              pagination: {
                defaultPageSize: param?.pageSize,
                total: records?.length,
                showTotal: (total, range) =>
                  t('network_table_pagi', { range0: range[0], range1: range[1], total }),
              },
            }
          : {})}
        // @ts-ignore
        onTableChange={onTableChange}
      />
      {/* Outlet used to render the drawers */}
      <Outlet />
    </>
  );
};

LocationTable.defaultProps = {
  isActionColumn: false,
  isMyLocations: false,
};

export default LocationTable;

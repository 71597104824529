import { Select, SelectProps, Space, Typography } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { FC } from 'react';
import { dropdownStyle, getVariableFromEnv, phoneCodes } from 'utils';
import styles from './index.module.less';
import { FlagEmojiProps } from './typings';

const emogiUrl = getVariableFromEnv('FLAG_EMOGI_URL');

const FlagEmoji: FC<FlagEmojiProps> = ({ iso }) => (
  <img src={`${emogiUrl}/${iso}.svg`} alt="" className={styles.extimage} />
);

const PhoneCodeSelect = <
  ValueType = string,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => (
  // we create here the list with the shape the select is expecting for

  <Select
    placeholder="Select Extension"
    allowFilterOption
    // @ts-ignore
    defaultValue="US"
    {...props}
    className={styles.phonecodeselect}
    dropdownClassName={styles.phonecodedropdown}
    optionLabelProp="label"
    dropdownStyle={dropdownStyle}
  >
    {phoneCodes.map((ext) => (
      <Select.Option
        label={<FlagEmoji iso={ext.iso} />}
        value={ext.iso}
        key={ext.iso}
        itemProps={ext}
      >
        <Space>
          <FlagEmoji iso={ext.iso} />
          <Typography.Text>{ext.country}</Typography.Text>
          <Typography.Text type="secondary">+{String(ext.code).replace('+', '')}</Typography.Text>
        </Space>
      </Select.Option>
    ))}
  </Select>
);
export default PhoneCodeSelect;

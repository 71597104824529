import { ProColumns } from '@ant-design/pro-table';
import { Segmented, Space, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SegmentedValue } from 'antd/lib/segmented';
import { Actions, ShareDocumentModal, SingleColumnTags, TitleBar } from 'components';
import GTable from 'components/GTable';
import { TFunction } from 'i18next';
import { useDocumentActions, useDocumentFilters } from 'pages/Documents';
import SharedTag from 'pages/Documents/components';
import { DocumentItem } from 'pages/Documents/typings';
import { FilterArrayProp } from 'pages/Network/typings';
import pluralize from 'pluralize';
import React, { FC, Key, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useProductById } from 'services/api/useProducts';
import { getDocTypeFilters } from 'utils';
import { DocumentTitleProp, ProductItem } from './typings';

type ActionType = {
  t: TFunction<'pages', 'template_attributes.documents'>;
  selectedRowKeys?: Key[];
};

/**
 * Generates an array of general action items based on the selected row keys and translation function.
 * @param selectedRowKeys An array containing the keys of the selected rows.
 * @param t Translation function.
 * @returns An array of general action items.
 */
export const generalActionItems = ({ selectedRowKeys, t }: ActionType): Array<ItemType> => [
  {
    key: 'add_document',
    label: t('actions.add_document'),
    disabled: false,
  },
  {
    key: 'download',
    label: t('actions.download'),
    disabled: !((selectedRowKeys?.length || 0) >= 1),
  },
  {
    key: 'share',
    label: t('actions.share'),
    disabled: !((selectedRowKeys?.length || 0) >= 1),
  },
];

/**
 * Generates an array of action items with common functionalities.
 * @param t Translation function.
 * @returns An array of action items.
 */
export const actionItems = ({ t }: ActionType): ItemType[] => [
  {
    key: 'view',
    label: t('actions.view'),
    disabled: false,
  },
  {
    key: 'download',
    label: t('actions.download'),
    disabled: false,
  },
  {
    key: 'share',
    label: t('actions.share'),
    disabled: false,
  },
  {
    key: 'copyURL',
    label: t('actions.copy_url'),
    disabled: false,
  },
];

type ColumnsType = {
  productSearchfilter: ProColumns;
  handleActionsMenuClick: (actionItemKey: string, actionPayload?: DocumentItem) => void;
  coreCompanyFilter: Array<FilterArrayProp>;
  tradePartnersFilter: Array<FilterArrayProp>;
  locationsFilter: Array<FilterArrayProp>;
  t: TFunction<'pages', 'template_attributes.documents'>;
};

/**
 * Generates an array of table columns for displaying document items.
 * @param productSearchfilter The filter object for searching documents by product.
 * @param handleActionsMenuClick Function to handle click events on the actions menu.
 * @param coreCompanyFilter The filter object containing core company options.
 * @param tradePartnersFilter The filter object containing trade partner options.
 * @param locationsFilter The filter object containing location options.
 * @param t Translation function.
 * @returns An array of table columns for displaying document items.
 */
export const columns = ({
  productSearchfilter,
  handleActionsMenuClick,
  coreCompanyFilter,
  tradePartnersFilter,
  locationsFilter,
  t,
}: ColumnsType): ProColumns<DocumentItem>[] => [
  {
    title: t('columns.document_name'),
    dataIndex: 'title',
    fixed: 'left',
    ellipsis: true,
    render: (text, record) => (
      <Link
        className="text-link"
        to={{
          pathname: String(record?.id || '1'),
        }}
      >
        {text}
      </Link>
    ),
    width: '8%',
    ...productSearchfilter,
  },
  {
    title: t('columns.document_type.title'),
    dataIndex: 'documentType',
    filters: getDocTypeFilters(),
    width: '8%',
    ellipsis: true,
  },
  {
    title: t('columns.company'),
    dataIndex: 'tradepartners',
    filters: tradePartnersFilter,
    filterSearch: true,
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Company')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.company'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.company') || ''}
      />
    ),
    width: '10%',
  },
  {
    title: t('columns.location'),
    dataIndex: 'locations',
    filters: locationsFilter,
    filterSearch: true,
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Location')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.location'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.location') || ''}
      />
    ),
    width: '10%',
  },
  {
    title: t('columns.product'),
    dataIndex: 'products',
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Product')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.product'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.product') || ''}
      />
    ),
    width: '10%',
  },
  {
    title: t('columns.shared.title'),
    dataIndex: 'shared',
    render: (text, record) => <SharedTag sharedWith={record.sharedWith} />,
    filters: coreCompanyFilter,
    filterSearch: true,
    width: '8%',
    ellipsis: true,
  },
  {
    title: t('columns.recent'),
    dataIndex: 'recentActivity',
    ellipsis: true,
    width: '8%',
  },
  {
    title: t('columns.expiration'),
    dataIndex: 'expirationDate',
    ellipsis: true,
    width: '8%',
  },
  {
    title: t('columns.blockchain.title'),
    dataIndex: 'blockchain',
    ellipsis: true,
    width: '8%',
  },
  {
    title: t('columns.visibility.title'),
    dataIndex: 'privacy',
    render: (text, record) =>
      t(`columns.visibility.filters.${String(record?.privacy)?.toLowerCase()}`),
    width: '8%',
    filters: [
      {
        text: t('columns.visibility.filters.private'),
        value: 'Private',
      },
      {
        text: t('columns.visibility.filters.public'),
        value: 'Public',
      },
    ],
    ellipsis: true,
  },
  {
    title: t('columns.url'),
    dataIndex: 'url',
    width: '8%',
    ellipsis: true,
    copyable: true,
  },
  {
    dataIndex: 'actions',
    hideInSetting: true,
    render: (text, record) => (
      <Actions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        items={actionItems({ t })}
        onClick={handleActionsMenuClick}
        actionPayload={record}
      />
    ),
    fixed: 'right',
    width: '7%',
  },
];

/**
 * Title component for the products page.
 * @param selectedRowKeys The keys of the selected rows.
 * @param product The product object.
 * @param onActionItemClick Function to handle click events on action items.
 */
const ProductsTitle: FC<DocumentTitleProp> = ({ selectedRowKeys, product, onActionItemClick }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });
  const sections = useMemo(() => [t('tab.inventory'), t('tab.documents')], [t]);
  const [section, setSection] = useState<string | number>(sections[1]);
  const navigate = useNavigate();

  const onSegmentedButtonClick = useCallback(
    (value: SegmentedValue) => {
      setSection(value);
      switch (value) {
        case t('tab.inventory'):
          navigate('../');
          break;
        case t('tab.documents'):
          navigate({ pathname: '../documents' });
          break;

        default:
          break;
      }
    },
    [navigate, t],
  );

  const title = useMemo(
    () => (
      <Space>
        {product?.name || ''}
        <Segmented
          value={section}
          options={sections || sections[0]}
          onChange={onSegmentedButtonClick}
        />
      </Space>
    ),
    [onSegmentedButtonClick, product, section, sections],
  );
  const extra = useMemo(
    () =>
      selectedRowKeys.length > 0 && (
        <Typography.Title level={5} className="selectiontext">
          {t('selection_text', {
            count: selectedRowKeys.length,
            type: t(`${pluralize('Document', selectedRowKeys.length)?.toLowerCase()}`),
          })}
        </Typography.Title>
      ),
    [selectedRowKeys?.length, t],
  );

  return (
    <TitleBar
      title={title}
      extra={extra}
      onBack={() => navigate('/products')}
      actionItems={generalActionItems({ selectedRowKeys, t })}
      onActionItemClick={onActionItemClick}
    />
  );
};

/**
 * Component to display documents associated with a product.
 */
const ProductDocuments = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });
  const { productId = '1' } = useParams();
  const { data: currentProduct } = useProductById(productId);
  const navigate = useNavigate();
  const {
    params,
    records,
    totalItems,
    isLoading,
    filters: [productSearchfilter],
    onTableChange,
    coreCompanyFilter,
    tradePartnersFilter,
    locationsFilter,
    refetch,
  } = useDocumentFilters({
    pageNumber: 1,
    pageSize: 20,
    productId,
    // status: ['Active', 'Archived'],
  });
  const {
    selectedRowKeys,
    onRowSelectionChange,
    onDownloadDocument,
    copyURL,
    onArchiveDocument,
    onShareDocument,
    shareModal,
    onDownloadDocumentMultiple,
    onArchiveDocumentMultiple,
    onShareDocumentMultiple,
    resetRowSelection,
  } = useDocumentActions();

  const product: ProductItem = useMemo(
    () => ({
      id: productId,
      epcUrn: currentProduct?.urn || '',
      name: currentProduct?.name || '',
      currentInventory: currentProduct?.currentInventory || 0,
    }),
    [currentProduct, productId],
  );
  const handleActionsMenuClick = async (actionItemKey: string, actionPayload?: DocumentItem) => {
    switch (actionItemKey) {
      case 'view':
        navigate({ pathname: `${actionPayload?.id}` });
        break;
      case 'download':
        onDownloadDocument(actionPayload);

        break;
      case 'share':
        onShareDocument(actionPayload);

        break;

      case 'copyURL':
        copyURL(actionPayload);

        break;

      case 'archive':
        onArchiveDocument(actionPayload);

        break;

      default:
        break;
    }
  };

  const onActionItemClick = useCallback(
    async (actionItemKey: string) => {
      switch (actionItemKey) {
        case 'add_document':
          navigate({ pathname: 'add' });
          break;
        case 'download':
          onDownloadDocumentMultiple(selectedRowKeys);
          break;
        case 'share':
          onShareDocumentMultiple(selectedRowKeys as Array<string>);
          break;

        case 'archive':
          await onArchiveDocumentMultiple(selectedRowKeys);
          onRowSelectionChange?.([], []);
          break;

        default:
          break;
      }
    },
    [
      navigate,
      onArchiveDocumentMultiple,
      onDownloadDocumentMultiple,
      onRowSelectionChange,
      onShareDocumentMultiple,
      selectedRowKeys,
    ],
  );
  const headerTitle = useMemo(
    () => (
      <ProductsTitle
        selectedRowKeys={selectedRowKeys}
        product={product}
        onActionItemClick={onActionItemClick}
      />
    ),
    [onActionItemClick, product, selectedRowKeys],
  );
  return (
    <>
      <ShareDocumentModal modal={shareModal} cleanUpAfterShare={resetRowSelection} />
      <GTable<DocumentItem>
        headerTitle={headerTitle}
        columns={columns({
          productSearchfilter,
          handleActionsMenuClick,
          coreCompanyFilter,
          tradePartnersFilter,
          locationsFilter,
          t,
        })}
        value={records}
        enableRowSelection
        loading={isLoading}
        scroll={{ x: 2500, y: '75vh' }}
        pagination={{
          defaultPageSize: params.pageSize,
          total: totalItems,
          showTotal: (total, range) =>
            t('doc_table_pagi', { range0: range[0], range1: range[1], total }),
        }}
        rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange }}
        columnsState={{
          persistenceKey: 'pages-product-documents',
        }}
        // @ts-ignore
        onTableChange={onTableChange}
        options={{
          reload: () => refetch(),
        }}
      />
      <Outlet />
    </>
  );
};

export default React.memo(ProductDocuments);

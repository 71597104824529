import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Space, Typography } from 'antd';
import Settings from 'components/Settings';
import { useDrawerVisibility } from 'hooks';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { setSettingsTabSession } from 'utils/settings';
import { drawerBodyStyle, drawerContentWrapper } from './common';
import { SettingsDrawerProps } from './typings';

declare const window: any;

const SettingsDrawer: React.FC<SettingsDrawerProps> = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings' });

  const { closeDrawer, visible, ...restDrawerVisibility } = useDrawerVisibility(true);

  const onClose = useCallback(() => {
    setSettingsTabSession('profile');
    closeDrawer();
  }, [closeDrawer]);

  return (
    <Drawer
      {...restDrawerVisibility}
      title={
        <Typography.Title level={4} className="mr0">
          {t('drawer_title')}
        </Typography.Title>
      }
      placement="top"
      visible={visible}
      closable={false}
      size="large"
      height="100%"
      bodyStyle={drawerBodyStyle}
      extra={
        <Space>
          <Button type="text" onClick={() => window.Beacon('toggle')}>
            <Space>
              <QuestionCircleOutlined />
              {t('help')}
            </Space>
          </Button>
          <Button type="text" onClick={onClose}>
            <CloseOutlined />
          </Button>
        </Space>
      }
      contentWrapperStyle={drawerContentWrapper}
    >
      <Settings />
    </Drawer>
  );
};

export default SettingsDrawer;

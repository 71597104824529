const numbers = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];

const getObjectFromParam = (messageParameters?: string[]) => {
  if (messageParameters?.length) {
    const flattenKeys = messageParameters?.map(
      (item, i) => (item ? { [`${numbers[i]}`]: `${item}` } : {}),
      [],
    );
    const flattenPairs = Object.assign({}, ...flattenKeys);
    return flattenPairs;
  }
  return {};
};

export default getObjectFromParam;

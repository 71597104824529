import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { FormInstance, Space, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { AdvanceSettingToggle } from 'components';
import { getVesselFields, mapTFieldtoCustomAttributeTemplateField } from 'utils';
import { CustomDataTemplate, Documents, VesselCatchTemplate } from '../Tables';
import CertDocuments from '../Tables/CertDocuments';
import { AddTemplateFormFieldsProps, DataItem } from '../typings';
import AdvanceSettings from './AdvanceSettings';
import styles from './index.module.less';

const colProps24Cols = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0 };

const AddTemplateFormFields = ({
  t,
  isView = false,
  templateQuery,
  isEdit,
}: AddTemplateFormFieldsProps): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['additionalOptions'],
    },
    columns: ({ additionalOptions }: DataItem) => [
      {
        dataIndex: 'showAdvancedSettings',
        formItemProps: {
          className: 'no-margin-divider',
        },
        fieldProps: {
          className: 'no-margin-divider',
        },
        renderFormItem: () => (
          <>
            <Space className={styles.title}>
              <Typography.Text className={classNames('ant-pro-form-group-title')}>
                {t?.('form_fields.custom_template_grp_title')}
              </Typography.Text>
              {isEdit ? <AdvanceSettingToggle /> : null}
            </Space>
            <br />

            {isEdit ? (
              <Space>
                <Typography.Text>{t?.('form_fields.additional_options_grp_title')}</Typography.Text>
                <Tooltip title={t?.('form_fields.additional_options_grp_tooltip')}>
                  <Typography.Text type="secondary">
                    <InfoCircleOutlined />
                  </Typography.Text>
                </Tooltip>
              </Space>
            ) : null}
          </>
        ),
      },
      {
        dataIndex: 'additionalOptions',
        initialValue: [],
        valueType: 'checkbox',
        valueEnum: {
          vesselCatch: t?.('form_fields.vessel_catch_title'),
          certificationDocuments: t?.('form_fields.cert_docs_title'),
        },
        proFieldProps: {},
        fieldProps: (form: FormInstance<DataItem>) => ({
          disabled: !isEdit,
          onChange: (val: Array<string>) => {
            /* If additionalOptions contains vesselCatch or certificationDocuments add or remove vesselCatch and certification fields accordingly */
            const allowVesselCatch = val?.includes('vesselCatch') || false;
            const allowCertificationDocuments = val?.includes('certificationDocuments') || false;

            if (allowCertificationDocuments || allowVesselCatch) {
              const vesselFieldFormData = form?.getFieldValue('vesselCatchFields');
              const vesselFieldData = vesselFieldFormData?.length ? vesselFieldFormData : undefined;

              const vesselCatchFields = allowVesselCatch
                ? vesselFieldData ||
                  getVesselFields()?.map(mapTFieldtoCustomAttributeTemplateField) ||
                  []
                : [];

              form?.setFieldsValue({
                ...(!allowCertificationDocuments && { certificationFields: [] }),
                vesselCatchFields,
                additionalOptions: val,
              });
            } else {
              form?.setFieldsValue({
                certificationFields: [],
                vesselCatchFields: [],
                additionalOptions: val,
              });
            }
          },
        }),
        colProps: isEdit ? colProps24Cols : colPropsNull,
      },
      {
        dataIndex: 'customAttributes',
        initialValue: [],
        renderFormItem: (_schema, _config, form) => (
          <CustomDataTemplate form={form} viewTemplate={isView} templateQuery={templateQuery} />
        ),
      },
      {
        dataIndex: 'vesselCatchFields',
        initialValue: [],
        renderFormItem: (_schema, _config, form) => (
          <VesselCatchTemplate form={form} viewTemplate={isView} templateQuery={templateQuery} />
        ),
        hideInForm: !additionalOptions?.includes('vesselCatch'),
      },
      {
        dataIndex: 'certificationFields',
        initialValue: [],
        renderFormItem: (_schema, _config, form) => <CertDocuments form={form} />,
        hideInForm: !additionalOptions?.includes('certificationDocuments'),
      },
    ],
  },

  {
    valueType: 'divider',
    formItemProps: {
      className: 'no-margint-divider',
    },
    fieldProps: {
      className: 'no-margint-divider',
    },
  },
  {
    dataIndex: 'documents',
    initialValue: [],
    renderFormItem: (_schema, _config, form) => (
      <Documents form={form} viewTemplate={isView} templateQuery={templateQuery} />
    ),
  },
  {
    valueType: 'divider',
    formItemProps: {
      className: 'no-margint-divider',
    },
    fieldProps: {
      className: 'no-margint-divider',
    },
  },
  {
    dataIndex: 'advanceSettings',
    initialValue: [
      {
        id: 'bizStep',
        label: t?.('form_fields.advance_settings_feild_bizstep'),
        value: undefined,
      },
      // {
      //   id: 'disposition',
      //   label: t?.('form_fields.advance_settings_feild_disposition'),
      //   value: undefined,
      // },
    ],
    renderFormItem: (_schema, _config, form) => (
      <AdvanceSettings form={form} viewTemplate={isView} templateQuery={templateQuery} />
    ),
  },
];
export default AddTemplateFormFields;

import { ProFormColumnsType } from '@ant-design/pro-form';
import SingleColumnTable from './SingleColumnTable';
import { DataItem, ModalFormProps } from './typings';

const SingleColumnFormFields = ({
  values,
  columnTitle,
}: ModalFormProps): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    columns: [
      {
        dataIndex: 'singleColumnTable',
        initialValue: [],
        renderFormItem: (_schema, _config, form) => (
          <SingleColumnTable
            form={form}
            values={values}
            columnTitle={columnTitle}
            fieldValue="singleColumnTable"
          />
        ),
      },
    ],
  },
];
export default SingleColumnFormFields;

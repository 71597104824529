import { Button, Modal, Space, Tabs, Typography } from 'antd';
import { EventDiagram, EventList, EventMap, ProductItem } from 'pages/Products';
import EventContext from 'pages/Products/Inventory/EventDetails/EventContext';
import { EventContextProps, EventTabs } from 'pages/Products/Inventory/EventDetails/typings';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventById, useEventMapById, useProductById } from 'services/api';
import { getNodeLifeCycleTime, getProductsT } from 'utils';
import styles from './index.module.less';
import { EventDetailsProps } from './typings';

const { Title } = Typography;

const EventDetails: FC<EventDetailsProps> = ({
  modalProps,
  productId,
  lotId,
  eventId,
  isContainer,
}) => {
  const [currentTab, setCurrentTab] = useState<EventTabs>('map');
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const { data: currentProduct } = useProductById(productId || '');
  const { data: event } = useEventById(eventId);
  const { inventoryItem } = useShipmentStore();
  const [showAllProducts, setShowAllProducts] = useState<boolean>(true);
  const { data: instances } = useEventMapById(eventId, {
    instanceId: lotId || undefined,
    ...(isContainer
      ? {
          containerId: lotId,
          instanceId: inventoryItem?.containerItems?.[0]?.instanceId || lotId || undefined,
        }
      : {}),
    showAllProducts,
  });
  const lifeCyle = useMemo(() => getNodeLifeCycleTime(instances?.nodes), [instances]);

  const product: ProductItem = {
    id: productId,
    epcUrn: currentProduct?.urn || '',
    name: event?.containers?.[0]?.containerIdentifier
      ? `${getProductsT('sscc')}: ${event?.containers?.[0]?.containerIdentifier || ''}`
      : currentProduct?.name || '',
    currentInventory: currentProduct?.currentInventory || 0,
  };
  const contextValue = useMemo<EventContextProps>(
    () => ({
      lifeCyle,
      showAllProducts,
      setShowAllProducts,
      inventoryItem,
    }),
    [lifeCyle, showAllProducts, inventoryItem],
  );

  const onTabChange = (activeKey: EventTabs) => {
    setCurrentTab(activeKey);
  };

  const onCloseModal = useCallback(() => {
    modalProps?.hide();
  }, [modalProps]);
  return (
    <Modal
      {...modalProps}
      key={eventId}
      width="90%"
      className={styles.detailsmodal}
      title={<Title level={5}>{product?.name}</Title>}
      footer={
        <Space>
          <Button onClick={onCloseModal} type="primary" shape="round" ghost>
            {t('cancel_btn')}
          </Button>
          <Button onClick={onCloseModal} type="primary" shape="round">
            {t('ok_btn')}
          </Button>
        </Space>
      }
    >
      <EventContext.Provider value={contextValue}>
        <Tabs defaultActiveKey="map" onChange={(key) => onTabChange(key as EventTabs)}>
          <Tabs.TabPane key="map" tab={t('map')}>
            {currentTab === 'map' && (
              <EventMap
                t={t}
                product={currentProduct}
                event={event}
                primaryId={lotId}
                isContainer={isContainer}
                eventId={eventId}
                modalWidth="90%"
                closeAllModal={onCloseModal}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key="diagram" tab={t('diagram')}>
            {currentTab === 'diagram' && (
              <EventDiagram
                t={t}
                product={currentProduct}
                event={event}
                primaryId={lotId}
                eventId={eventId}
                isContainer={isContainer}
                modalWidth="90%"
                closeAllModal={onCloseModal}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key="list" tab={t('list')}>
            {currentTab === 'list' && (
              <EventList
                eventId={eventId}
                product={currentProduct}
                event={event}
                modalWidth="90%"
                closeAllModal={onCloseModal}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </EventContext.Provider>
    </Modal>
  );
};
export default React.memo(EventDetails);

import { InfoCircleOutlined } from '@ant-design/icons';
import { Select, Tag, Tooltip, Typography } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import { TagInputProp, TagProp, WCIdsProp } from './typings';

const TagRender = ({ prop, inviteWCIDs }: TagProp) => {
  const { label, value, closable, onClose } = prop;
  const tagObj = inviteWCIDs?.find((item) => item?.label === label);

  return (
    <Tag
      {...prop}
      key={String(`key${value}`)}
      icon={
        tagObj?.message !== '' && (
          <Tooltip title={tagObj?.message}>
            <InfoCircleOutlined className={styles.alerticon} />
          </Tooltip>
        )
      }
      closable={closable}
      onClose={onClose}
      className={styles.emailtag}
    >
      <Typography.Text>{label}</Typography.Text>
    </Tag>
  );
};

const TagInput = ({ placeholder, inviteWCIDs, setInviteWCIDs, form }: TagInputProp) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.invite' });
  const actionRef = useRef<BaseSelectRef>(null);

  const getObj = (value: string) => {
    const obj = inviteWCIDs?.find((item) => item?.value === String(value));
    return obj;
  };

  const onChange = (value: WCIdsProp[]) => {
    form?.setFieldsValue({
      ids: value.length === 0 ? undefined : value,
    });
    const array = value?.map((item) => {
      const obj = getObj(String(item));
      return {
        value: obj?.value ? obj?.value : String(item),
        label: obj?.label ? obj?.label : String(item),
        message: obj?.message ? obj?.message : '',
      };
    });
    setInviteWCIDs(array);
  };

  return (
    <Select
      ref={actionRef}
      placeholder={placeholder}
      mode="tags"
      onChange={onChange}
      value={inviteWCIDs}
      tokenSeparators={[',']}
      open={false}
      tagRender={(prop) => TagRender({ prop, t, inviteWCIDs })}
    />
  );
};

export default TagInput;

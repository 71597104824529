import { LogoutOutlined, MoreOutlined, PushpinOutlined } from '@ant-design/icons';
import ProList, { ProListMetas } from '@ant-design/pro-list';
import { useMsal } from '@azure/msal-react';
import { Button, Input, Typography, message } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions } from 'components';
import GenLayout from 'components/GenLayout';
import LoadingAccount from 'components/Loading/LoadingAccount';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  useListAccounts,
  usePinAccount,
  useRemoveActiveAccount,
  useSetActiveAccount,
  useSwitchAccount,
} from 'services/api';
import { ListAccountsFromUserOptionalParams, UserAccountsResponse } from 'services/api/client/src';
import { useDebouncedCallback } from 'use-debounce';
import { errorHandler } from 'utils';
import styles from './index.module.less';
import { SUserAccount } from './typings';

type ActionItemsProps = {
  t: TFunction<'pages', 'pages.current_inventory'>;
  record?: SUserAccount;
  isDefault?: boolean;
};
const ActionItems = ({ t, record }: ActionItemsProps): Array<ItemType> => [
  {
    key: 'pin',
    label: !record?.isPinnedAccount ? t('pin') : t('unpin'),
  },
  {
    key: 'toggle_default',
    label: !record?.isDefault ? t('set_default') : t('remove_default'),
  },
];

const SwitchAccount = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('pages', { keyPrefix: 'switch_account' });
  const { instance } = useMsal();
  const [loading, setLoading] = useState<boolean>(false);
  const [tick, setTick] = useState<number>(0);
  const removeActiveAccount = useRemoveActiveAccount(queryClient);
  const setActiveAccount = useSetActiveAccount(queryClient);
  const switchAccount = useSwitchAccount(queryClient);
  const pinAccount = usePinAccount(queryClient);
  const [params, setParams] = useState<ListAccountsFromUserOptionalParams>();
  const { data, isLoading } = useListAccounts(params);
  const navigate = useNavigate();
  const records = useMemo(
    () =>
      data?.map((el) => {
        const isDefault = el?.isCurrentAccount;
        return {
          ...el,
          isDefault,
        };
      }) || [],
    [data],
  );

  useEffect(() => {
    if (tick >= 100) {
      setLoading(false);
      navigate('/dashboard');
    }
  }, [navigate, tick]);

  const onSignIn = useCallback(
    async (record: UserAccountsResponse) => {
      try {
        setLoading(true);
        setTick(1);
        await switchAccount.mutateAsync({
          body: {
            accountId: record.accountId,
          },
        });
        await setActiveAccount.mutateAsync(record);
        queryClient?.clear();
      } catch (error) {
        setLoading(false);

        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [queryClient, setActiveAccount, switchAccount],
  );
  const onPinAccount = useCallback(
    async (record?: UserAccountsResponse) => {
      await pinAccount.mutateAsync({
        body: {
          accountId: record?.accountId,
          pinned: !record?.isPinnedAccount,
        },
      });
    },
    [pinAccount],
  );
  const onToggleDefault = useCallback(
    async (record?: SUserAccount) => {
      if (record?.isDefault) {
        await removeActiveAccount.mutateAsync(record);
      } else {
        await setActiveAccount.mutateAsync(record as UserAccountsResponse);
        await onSignIn(record as UserAccountsResponse);
      }
    },
    [removeActiveAccount, setActiveAccount, onSignIn],
  );
  const onRowActionClick = useCallback(
    (key: string, record?: UserAccountsResponse) => {
      switch (key) {
        case 'pin':
          onPinAccount(record);
          break;
        case 'toggle_default':
          onToggleDefault(record);
          break;
        default:
          break;
      }
    },
    [onPinAccount, onToggleDefault],
  );
  const onAccountDebounce = useDebouncedCallback(
    // function
    (val: string) => {
      setParams({
        name: val,
      });
    },
    // delay in ms
    1000,
  );

  const onSearch = useCallback(
    (e: { target: { value: string } }) => {
      onAccountDebounce(e.target.value);
    },
    [onAccountDebounce],
  );
  const listMetas: ProListMetas<UserAccountsResponse> = {
    avatar: {
      render: (_, record) => record?.isPinnedAccount && <PushpinOutlined className={styles.icon} />,
    },
    title: {
      dataIndex: 'accountName',
    },
    actions: {
      render: (_, record) => (
        <Actions
          items={ActionItems({ t, record })}
          actionPayload={record}
          showIcon={false}
          buttonProps={{
            type: 'text',
            size: 'small',
          }}
          onClick={onRowActionClick}
          actionsText={<MoreOutlined />}
        />
      ),
    },
  };
  const rightContentRender = useCallback(
    () => (
      <Button
        type="link"
        icon={<LogoutOutlined className={styles.icon} />}
        onClick={() => instance.logout()}
      >
        {t('signout')}
      </Button>
    ),
    [t, instance],
  );

  return tick > 0 && tick < 100 ? (
    <LoadingAccount
      percent={Number(tick) || 0}
      isLoading={loading}
      setTick={setTick}
      label={t('loading_account')}
    />
  ) : (
    (tick === 0 && (
      <GenLayout layoutProps={{ rightContentRender }}>
        <div className={styles.container}>
          <ProList<UserAccountsResponse>
            headerTitle={
              <>
                <Typography.Text className={styles.title}>{t('title')}</Typography.Text>
                <div>
                  <Input.Search
                    placeholder={t('search_placeholder')}
                    className={styles.search}
                    allowClear
                    onChange={onSearch}
                  />
                </div>
              </>
            }
            itemLayout="horizontal"
            rowKey="id"
            dataSource={records}
            metas={listMetas}
            onItem={(record) => ({
              onClick: () => onSignIn(record),
            })}
            className={styles.list}
            rowClassName={styles.row}
            loading={{
              spinning: loading || isLoading,
              tip: !isLoading ? t('signing_in') : undefined,
            }}
          />
        </div>
      </GenLayout>
    )) || <div />
  );
};
export default React.memo(SwitchAccount);

import G6 from '@antv/g6';
import { Tooltip } from 'antd';
import { fittingString, getNodeConfig } from './methods';

const SIMPLE_NODE_TYPE = 'simple-modelRect';

/* Reduce nodes */
const createReduceNodes = (nodeBasicMethod: any) => {
  /* Reduce nodes */
  G6.registerNode(
    SIMPLE_NODE_TYPE,
    {
      drawShape: (cfg: any, group: any) => {
        if (cfg) {
          const config = getNodeConfig(cfg);
          const isRoot = cfg.dataType === 'root';
          const { nodeError } = cfg;

          const container = nodeBasicMethod.createNodeBox(group, config, 171, 38, isRoot);

          /* name */
          group.addShape('text', {
            attrs: {
              text: (
                <Tooltip title={fittingString(cfg.name, 133, 12)}>
                  {fittingString(cfg.name, 133, 12)}
                </Tooltip>
              ),
              x: 19,
              y: 19,
              fontSize: 14,
              fontWeight: 700,
              textAlign: 'left',
              textBaseline: 'middle',
              fill: config.fontColor,
              cursor: 'pointer',
            },
            name: 'name-text-shape',
          });

          if (nodeError) {
            group.addShape('image', {
              attrs: {
                x: 119,
                y: 5,
                height: 35,
                width: 35,
              },
              name: 'image-shape',
            });
          }

          const hasChildren = cfg.children && cfg.children.length > 0;
          if (hasChildren) {
            nodeBasicMethod.createNodeMarker(group, cfg.collapsed, 164, 19);
          }
          return container;
        }
        return nodeBasicMethod.createNodeBox(
          group,
          {
            basicColor: '#722ED1',
            fontColor: '#722ED1',
            borderColor: '#722ED1',
            bgColor: '#F6EDFC',
          },
          171,
          38,
          false,
        );
      },
      afterDraw: nodeBasicMethod.afterDraw,
      setState: nodeBasicMethod.setState,
    },
    'single-node',
  );
};
export default createReduceNodes;

import { ArrowLeftOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Button, Space, Typography, message } from 'antd';
import useModalVisibility from 'hooks/useModalVisibility';
import useLocationStore from 'pages/Network/hook/useLocationStore';
import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { DocumentResponse, ShareDocumentRequest } from 'services/api/client/src';
import { useCreateShareDocument } from 'services/api/documents';
import errorHandler from 'utils/errorHandler';
import { DataItem, ShareDocumentColumns } from './ShareDocument.fields';
import ShareNewDocument from './ShareNewDocument';
import styles from './index.module.less';
import { DocumentProps, SelectedProps, ShareDocProps } from './typings';

const ShareNewDocumentLink: FC<DocumentProps> = ({ form, newDoc }) => {
  const modal = useModalVisibility(false);
  const { t } = useTranslation('pages', { keyPrefix: 'network.share_document' });

  return (
    <>
      <ShareNewDocument
        form={form}
        modal={modal}
        onAddSuccess={(data) => {
          const records: DocumentResponse = {
            id: data?.id,
            title: data?.title,
          };
          newDoc(records);
        }}
      />
      <Button onClick={() => modal.show()} className={styles.newdoclink} type="link">
        {t('new_doc_button')}
      </Button>
    </>
  );
};

const ShareDocForm: FC<ShareDocProps> = ({ modal, documentToShare, setDocumentToShare }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.share_document' });
  const [documents, setDocuments] = useState<Array<DocumentResponse>>([]);
  const [newDoc, setNewDoc] = useState<SelectedProps>({});
  const [partners, setPartners] = useState<Array<string>>([]);
  const modalFormRef = useRef<ProFormInstance>();
  const queryClient = useQueryClient();
  const shareDocument = useCreateShareDocument(queryClient);
  const { companyId, wcId } = useLocationStore();

  useEffect(() => {
    setPartners([wcId || '']);
  }, [wcId]);

  useEffect(() => {
    if (documentToShare) {
      setDocuments([
        {
          id: documentToShare?.value,
          title: documentToShare?.label,
        },
      ]);
      setNewDoc({ ...documentToShare });
    }
  }, [documentToShare]);

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    setDocuments([]);
    setNewDoc({});
    if (setDocumentToShare) {
      setDocumentToShare();
    }
    modal.hide();
  }, [modal, setDocumentToShare]);

  const onFinish = async (formData: DataItem) => {
    const selectedDocument: string[] = documents.map((document) => document.id || '') || [];
    try {
      const reqBody: ShareDocumentRequest = {
        documentIds: selectedDocument,
        message: formData.message,
        accountIds: partners || [],
      };
      await shareDocument.mutateAsync(reqBody);
      message.success(
        `${t('share_success', {
          id: selectedDocument.length === 1 ? documents?.[0]?.title : selectedDocument.length,
        })}`,
      );
      closeModal();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }

    closeModal();
  };

  const Title: ReactElement = (
    <Space>
      {documents.length === 0 ? null : (
        <ArrowLeftOutlined
          className={styles.arrow}
          onClick={() => {
            setDocuments([]);
            setPartners([]);
            setNewDoc({});
            modalFormRef?.current?.resetFields();
          }}
        />
      )}
      <Typography.Title level={4} className="mr0">
        {t('modal_title')}
      </Typography.Title>
    </Space>
  );

  return modal.visible ? (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      // @ts-ignore
      title={Title}
      formRef={modalFormRef}
      columns={ShareDocumentColumns({
        t,
        documentCount: documents.length,
        documents,
        setDocuments,
        newDoc,
        partners,
        setPartners,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        width: '40%',
        ...modal,
        onCancel: closeModal,
      }}
      initialValues={{
        tradePartners: [companyId],
      }}
      submitter={{
        searchConfig: {
          submitText: t('send'),
          resetText: !partners?.length || !documents?.length ? t('done') : t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          className: !partners?.length || !documents?.length ? styles.donetext : styles.canceltext,
        },
        render: ({ form }, defaultDoms) =>
          (!partners?.length || !documents?.length) && form
            ? [
                <ShareNewDocumentLink
                  form={form}
                  newDoc={(doc) => {
                    setNewDoc({ label: doc?.title, value: doc?.id });
                    setDocuments([...documents, doc]);
                  }}
                />,
                defaultDoms[0],
              ]
            : defaultDoms,
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  ) : null;
};

export default React.memo(ShareDocForm);

import { Button, Empty, Typography } from 'antd';
import { documentsEmptySvg } from 'assets';
import { EmptyStateProps } from 'pages/Dashboard/components/typings';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useAttributeStore from './Tables/useAttributeStore';

const EmptyStateTemplates: FC<EmptyStateProps> = ({ t }) => {
  const navigate = useNavigate();
  const setTemplateMode = useAttributeStore((state) => state.setTemplateMode);

  return (
    <Empty
      image={documentsEmptySvg}
      description={<Typography.Text>{t?.('empty_title')}</Typography.Text>}
      className="empty-container"
    >
      <Button
        type="primary"
        shape="round"
        ghost
        onClick={() => {
          setTemplateMode?.('edit');
          navigate('add');
        }}
      >
        {t?.('empty_add')}
      </Button>
    </Empty>
  );
};
export default EmptyStateTemplates;

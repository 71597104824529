import ProCard from '@ant-design/pro-card';
import { Button, Col, PageHeader, Row, Space, Typography } from 'antd';
import { useAuthenticationContext } from 'contexts';
import { useModalVisibility } from 'hooks';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountSubscription } from 'services/api';
import { contactURL, getT, supportEmailLink } from 'utils';
import UpgradPlanModal from './UpgradePlanModal';
import styles from './index.module.less';

const SubscriptionBilling = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.subscription_billing' });
  const { data: subscription, isLoading } = useAccountSubscription();
  const upgradeModal = useModalVisibility(false);
  const changecardModal = useModalVisibility(false);

  const { langCode } = useAuthenticationContext();

  const onUpgradePlan = useCallback(() => {
    upgradeModal.show();
  }, [upgradeModal]);
  return (
    <>
      <UpgradPlanModal
        modal={upgradeModal}
        title={t('upgrade_modal_title')}
        description={
          <div>
            {`${t('upgrade_modal_description')} `}
            <a href={supportEmailLink} type="mail">
              {t('support_anchor_email_title')}
            </a>
            .
          </div>
        }
      />
      <UpgradPlanModal
        modal={changecardModal}
        title={t('change_card_modal_title')}
        kind="ChangeCreditCardPayment"
        description={
          <div>
            {`${t('change_card_modal_description')} `}&nbsp;
            <a href={supportEmailLink} type="mail">
              {t('support_anchor_email_title')}
            </a>
            .
          </div>
        }
      />
      <PageHeader title={t('title')} className="page-header" />
      <ProCard loading={isLoading} className={styles.cardstyle}>
        <Row justify="space-between">
          <Col>
            <Typography.Text type="secondary">{t('plan_title')}</Typography.Text>
          </Col>
          <Col>
            <Button type="primary" shape="round" onClick={onUpgradePlan}>
              {t('upgrade_plan_btn')}
            </Button>
          </Col>
        </Row>
        <Typography.Title level={4}>
          {subscription?.subscription?.name || t('essential')}
        </Typography.Title>
        <br />
        <Typography.Text>
          {subscription?.subscription?.description || t('description')}
        </Typography.Text>
        <br />
        <Typography.Text type="secondary">
          {`${t('next_repayment')}${
            subscription?.subscription?.nextPaymentAmount || '$299.00'
          } on ${
            subscription?.subscription?.nextPaymentDate
              ? moment
                  .utc(subscription?.subscription?.nextPaymentDate)
                  .locale(langCode === '' ? 'en-US' : langCode)
                  .format('YYYY-MM-DD')
              : 'January 1, 2023'
          }`}
        </Typography.Text>
      </ProCard>
      <br />
      <ProCard.Group direction="row" loading={isLoading} className={styles.cardstyle}>
        <ProCard className={styles.cardstyle}>
          <Typography.Title level={5}>{t('payment_method_title')}</Typography.Title>
          <Space>
            <Typography.Text>{`....${
              subscription?.paymentInfo?.cardLast4 || '6122'
            }`}</Typography.Text>
            <Button type="link" onClick={changecardModal.show}>
              {t('change_card_btn')}
            </Button>
          </Space>
        </ProCard>
        <ProCard>
          <Typography.Title level={5}>{t('billing_period_title')}</Typography.Title>
          <Typography.Text>
            {subscription?.paymentInfo?.billingPeriodStart &&
            subscription?.paymentInfo?.billingPeriodEnd
              ? `${moment
                  .utc(subscription?.paymentInfo?.billingPeriodStart)
                  .locale(langCode === '' ? 'en-US' : langCode)
                  .format('YYYY-MM-DD')} - ${moment
                  .utc(subscription?.paymentInfo?.billingPeriodEnd)
                  .locale(langCode === '' ? 'en-US' : langCode)
                  .format('YYYY-MM-DD')}`
              : 'December 1, 2021 - January 1, 2022'}
          </Typography.Text>
        </ProCard>
        <ProCard className={styles.cardstyle}>
          <Typography.Title level={5}>{t('billing_type_title')}</Typography.Title>
          <Typography.Text>
            {subscription?.paymentInfo?.billingType
              ? getT(subscription?.paymentInfo?.billingType)
              : t('billing_type_text')}
          </Typography.Text>
        </ProCard>
      </ProCard.Group>
      <br />
      <Typography.Text strong>{t('support_title')}</Typography.Text>
      <br />
      <Typography.Text>
        {`${t('support_description')} `}
        <span>
          <a href={supportEmailLink} type="mail">
            {t('support_anchor_email_title')}
          </a>
        </span>
        &nbsp;{t('support_description2')}
        <span>
          <a href={contactURL} target="_blank" type="mail" rel="noreferrer">
            {` ${t('support_anchor_help_title')}`}
          </a>
        </span>
      </Typography.Text>
    </>
  );
};
export default SubscriptionBilling;

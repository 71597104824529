import { Modal, Typography } from 'antd';
import i18next from 'i18next';
import styles from './index.module.less';
import { supportEmailLink } from './timezones';
import { ErrorType } from './typings';

const errorHandler = (err: any) => {
  if (!navigator.onLine) {
    return i18next?.t?.('generic_error.you_are_offline');
  }

  /* api error */
  if (err?.response?.bodyAsText) {
    const errorJson = JSON.parse(err.response.bodyAsText);
    if (errorJson?.errorCode) {
      return i18next?.t?.(String(errorJson?.errorCode)?.toLowerCase());
    }
    if (errorJson?.title?.includes('One or more validation errors occurred')) {
      const fields = Object.values(errorJson?.errors)?.map((item: any) => item?.[0], []);
      const uniqueFields = new Set([...fields]);
      Modal.error({
        title: i18next?.t?.('generic_error.validation_error_title'),
        content: (
          <Typography.Paragraph>
            <ul className={styles.listtype}>
              {Array.from(uniqueFields)?.map(
                (item) => (
                  <li key={item}>{item}</li>
                ),
                [],
              )}
            </ul>
          </Typography.Paragraph>
        ),
        okText: i18next?.t?.('generic_error.done'),
        cancelButtonProps: {
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      });
      return undefined;
    }
    return errorJson?.title || 'Something went wrong';
  }

  /* antd validation error */
  if (err?.errorFields?.[0]?.errors?.[0]) {
    return err?.errorFields?.[0]?.errors?.[0];
  }

  /* normal error message */
  if (err?.message) {
    if (err?.message?.includes('Failed to fetch')) {
      Modal.error({
        title: i18next?.t?.('generic_error.failed_to_fetch_title'),
        content: (
          <div>
            {i18next?.t?.('generic_error.failed_to_fetch_desc1')}&nbsp;
            <a href={supportEmailLink} type="mail">
              {i18next?.t?.('generic_error.failed_to_fetch_email_title')}
            </a>
            &nbsp;
            {i18next?.t?.('generic_error.failed_to_fetch_desc2')}
          </div>
        ),
        okText: i18next?.t?.('generic_error.done'),
        cancelButtonProps: {
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      });
      return undefined;
    }
    return err.message;
  }
  // i18n?.t?.(str);

  return err?.response || 'Something went wrong';
};
export const errorHandlerv2 = async (error: any): Promise<string> => {
  // check if error is type of blob
  const err = (await error?.json?.()) || error;

  /* api error */
  if ((err as ErrorType)?.title) {
    return err?.title || 'Something went wrong';
  }

  /* antd validation error */
  if (err?.errorFields?.[0]?.errors?.[0]) {
    return err?.errorFields?.[0]?.errors?.[0];
  }

  /* normal error message */
  if (err?.message) {
    return err.message;
  }

  return err?.response || 'Something went wrong';
};

export const getErrorJSON = (err: any): ErrorType | undefined => {
  if (err?.response?.bodyAsText) {
    const errorJson = JSON.parse(err.response.bodyAsText);
    return errorJson;
  }
  return undefined;
};

export const getErrorCode = (err: any): string | undefined => {
  const errorJson = getErrorJSON(err);
  if (errorJson?.errorCode) {
    return String(errorJson?.errorCode)?.toLowerCase();
  }
  return undefined;
};

export default errorHandler;

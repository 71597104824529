import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Typography, message } from 'antd';
import { TradePartnerLocationsSelect } from 'components/GSelect';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useCreateNetwokInvite, useTradePartnerById } from 'services/api';
import { CreateNetworkInviteRequest } from 'services/api/client/src';
import errorHandler from 'utils/errorHandler';
import { DataItem } from '../../ShareLocation.fields';
import { ShareLocationProps } from '../../typings';
import styles from './index.module.less';

const RestoreConnectionForm: FC<ShareLocationProps> = ({ modal }) => {
  const { t } = useTranslation('pages', {
    keyPrefix: 'network.add_network.form_fields.restore_connection_warn',
  });
  const { companyId = '1' } = useParams();
  const { data: selectedCompany } = useTradePartnerById(String(companyId));
  // const inviteData = useListInvitesMutate();
  const modalFormRef = useRef<ProFormInstance>();
  const queryClient = useQueryClient();
  const inviteNetworkUsers = useCreateNetwokInvite(queryClient);

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onRestoreConnection = async (formData: DataItem) => {
    try {
      // const invite = await inviteData.mutateAsync({
      //   params: {
      //     accountId: selectedCompany?.wholechainId || '',
      //     incoming: true,
      //   },
      // });
      // const res = await resendInviteAPI.mutateAsync({
      //   id: invite?.results?.[0]?.id || '',
      //   options: {
      //     locationIds: formData?.locations,
      //   },
      // });
      const compReq: Array<CreateNetworkInviteRequest> = [
        {
          wholechainId: selectedCompany?.wholechainId || '',
          tradeRelationship: selectedCompany?.tradeRelationship || '',
          locationIds: formData.locations,
        },
      ];
      await inviteNetworkUsers.mutateAsync({
        options: compReq,
      });
      message.success(t('invite.resent_success_invite', { company: selectedCompany?.name }));

      closeModal();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      // @ts-ignore
      title={t('title')}
      formRef={modalFormRef}
      columns={[
        {
          valueType: 'group',
          title: (
            <Typography.Paragraph className={styles.inivitetitle}>
              {t('content', { company: selectedCompany?.name })}
            </Typography.Paragraph>
          ),
          columns: [],
        },
        {
          title: t('share_location'),
          dataIndex: 'locations',
          valueType: 'select',
          formItemProps: {
            rules: [
              {
                required: true,
                message: `${t('location_required')}`,
              },
            ],
          },
          renderFormItem: () => (
            <TradePartnerLocationsSelect
              mode="multiple"
              placeholder={t('location_placeholder')}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          ),
        },
      ]}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        width: '40%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('restore'),
          resetText: t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          className: styles.canceltext,
        },
        render: (_, defaultDoms) => defaultDoms,
      }}
      submitTimeout={2000}
      onFinish={onRestoreConnection}
    />
  );
};

export default RestoreConnectionForm;

import { createContext, useContext } from 'react';
import { EventContextProps } from './typings';

const EventContext = createContext<EventContextProps>({
  lifeCyle: '',
  showAllProducts: true,
  setShowAllProducts: () => {},
});

export const useEventContext = (): EventContextProps => {
  const context = useContext(EventContext);

  if (context === undefined) {
    throw new Error('EventContext must be used within a EventContextProvider');
  }

  return context;
};

export default EventContext;

import type { ProFormColumnsType } from '@ant-design/pro-form';
import { RowProps } from 'antd';
import { Key } from 'react';
import LocationTable from '../components/TableContainer/LocationTable';
import { NetworkItem } from '../typings';
import styles from './index.module.less';

const colProps24Cols = { xs: 24, sm: 24, md: 24, lg: 24 };
const rowProps: RowProps = { gutter: [32, 8] };
export type DataItem = {
  locations: Array<string>;
  companies: Array<string>;
  message: string;
};

type ColumnsType = {
  selectedRowKeys: Array<Key>;
  onRowSelectionChange: (selectedRowKeys: Array<Key>, selectedRows: Array<NetworkItem>) => void;
  note?: string;
  isMyLocations?: boolean;
  onEmptyLocation?: (isEmpty: boolean) => void;
};

export const MyMultiLocationTableColumns = ({
  selectedRowKeys,
  onRowSelectionChange,
  note,
  isMyLocations,
  onEmptyLocation,
}: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    title: <div className={styles.inivitetitle}>{note}</div>,
    valueType: 'group',
    rowProps,
    columns: [
      {
        colProps: colProps24Cols,
        renderFormItem: () => (
          <LocationTable
            isMyLocations={isMyLocations}
            isActionColumn={false}
            rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange }}
            enableRowSelection
            options={{
              setting: false,
              reload: false,
            }}
            onEmptyLocation={onEmptyLocation}
          />
        ),
        formItemProps: {
          className: 'readformlabel',
        },
      },
    ],
  },
];

import { BetaSchemaForm } from '@ant-design/pro-form';
import { Form, message, Modal, RowProps, Space } from 'antd';
import { useDrawerVisibility } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { CreateDocument$multipartOptionalParams } from 'services/api/client/src';
import { useCreateDocument } from 'services/api/documents';
import { capitalizeFirstLetter, errorHandler } from 'utils';
import useDocumentsStore from '../hooks/useDocumentsStore';
import AddDocumentFormFields from './AddDocumentForm.fields';
import { DataItem } from './typings';

const rowProps: RowProps = { gutter: [24, 24] };

const AddDocumentForm = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'documents' });
  const queryClient = useQueryClient();
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const { hasAdvancedSettings } = useDocumentsStore();
  const [mainForm] = Form.useForm();
  const createDocument = useCreateDocument(queryClient);

  const title = t('add_document.title');
  const submitBtnText = t('add_document.submit_btn');
  useEffect(() => {
    const collection = document.getElementsByClassName('ant-drawer-body');
    if (collection?.[0]) collection[0].scrollTop = 0;
  }, []);
  // const shareBtnText = t('add_document.share_btn');

  const onCancel = useCallback(() => {
    const hasFilled = mainForm?.isFieldsTouched();
    if (hasFilled) {
      Modal.confirm({
        title: t('add_document.cancel_changes_title'),
        content: t('add_document.cancel_changes_content'),
        okText: t('add_document.cancel_changes_btn'),
        cancelText: t('add_document.cancel_changes_btn_cancel'),
        onOk: closeDrawer,
        centered: true,
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
      });
    } else {
      closeDrawer();
    }
  }, [closeDrawer, mainForm, t]);

  const onSubmit = async () => {
    try {
      await mainForm.validateFields();
      mainForm.submit();
    } catch (error) {
      message.error(t('add_document.submit_error'));
    }
  };
  const onFinish = async (formData: DataItem) => {
    try {
      if (!formData.file) {
        message.error(t('missing_doc'));
        return;
      }
      const reqBody: CreateDocument$multipartOptionalParams = {
        title: formData.title,
        documentType: capitalizeFirstLetter(formData?.documentType || 'other'),
        file: formData.file,
        privacy: formData.privacy,
        blockchain: formData.blockchain,
        certificationAgency: formData.agency,
        certificationStandard: formData.certificationStandard,
        certificationId: formData.identification,
        purchaseOrderNumber: formData.purchaseOrder,
        documentTypeName: formData.documentTypeName,
        expirationDate: formData?.expirationDate
          ? new Date(formData?.expirationDate || '')
          : undefined,
        expirationAlertDate: formData?.expirationDateAlert
          ? new Date(formData?.expirationDateAlert)
          : undefined,
        productsIds: formData?.products as Array<string>,
        locationsIds: formData?.locations as Array<string>,
        tradePartnersIds: formData?.tradePartners as Array<string>,
        shipFrom: formData?.shipFrom,
        shipTo: formData?.shipTo,
        sid: formData?.sID,
        cid: formData?.cID,
        fob: formData?.fOB,
        shipDate: formData?.shipDate ? new Date(formData?.shipDate || '') : undefined,
        carrier: formData?.carrier,
        items: formData?.items,
        quantitiesOfShippedItems: formData?.quantitiesOfShippedItems,
      };
      const res = await createDocument.mutateAsync(reqBody);

      message.success(t('add_success', { id: res?.title || '' }));
      closeDrawer();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={AddDocumentFormFields({ hasAdvancedSettings })}
      grid
      title={title}
      visible={visible}
      rowProps={rowProps}
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        className: 'drawer-content',
        ...restDrawerVisibility,
        onClose: onCancel,
      }}
      submitter={{
        searchConfig: {
          submitText: submitBtnText,
          resetText: t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          onClick: onCancel,
        },
        render: (form, defaultDoms) => <Space>{defaultDoms[1]}</Space>,
        onSubmit,
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default AddDocumentForm;

import { ProColumns } from '@ant-design/pro-table';
import { TFunction, useTranslation } from 'react-i18next';
import { GTable } from 'components';
import { DecommissionProductItem } from 'pages/Events/components/Forms/typings';
import { FC } from 'react';
import { ProductsTableProps } from './typings';

type ColumnsType = {
  event?: string;
  t: TFunction<'pages', 'templates.preview'>;
};
const Columns = ({ event, t }: ColumnsType): Array<ProColumns<DecommissionProductItem>> => [
  {
    title: t('product'),
    dataIndex: 'parentProductId',
    hideInTable: event === 'Ship',
  },
  {
    title: event === 'Decommission' ? t('lot_id') : t('primary_id'),
    dataIndex: 'primaryId',
  },
  {
    title: t('quantity'),
    dataIndex: 'quantity',
    fieldProps: {
      addonAfter: 'LBS',
      type: 'number',
      size: 'small',
    },
  },
  {
    title: t('current_inventory'),
    dataIndex: 'currentInventory',
  },
  {
    title: t('log_id_sscc'),
    dataIndex: 'logisticId',
    hideInTable:
      event === 'Aggregation' ||
      event === 'Disaggregate' ||
      event === 'Ship' ||
      event === 'Transform',
  },
  {
    title: t('purchase_order'),
    dataIndex: 'purchaseOrder',
    hideInTable:
      event === 'Aggregation' ||
      event === 'Disaggregate' ||
      event === 'Ship' ||
      event === 'Transform',
  },
  {
    title: t('date'),
    dataIndex: 'decommissionedAt',
    hideInTable:
      event === 'Aggregation' ||
      event === 'Disaggregate' ||
      event === 'Ship' ||
      event === 'Transform',
  },
];
const ProductsTable: FC<ProductsTableProps> = ({ event }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates.preview' });
  return (
    <GTable<DecommissionProductItem>
      key="sactionRef"
      columns={Columns({ event, t })}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      value={[]}
    />
  );
};
export default ProductsTable;

import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import {
  DatePicker,
  DatePickerProps,
  Input,
  Modal,
  Select,
  TablePaginationConfig,
  message,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { NumericInput, SingleColumnTags } from 'components';
import ActionButtons from 'components/ActionButtons';
import GTable from 'components/GTable';
import useAttributeStore from 'components/Settings/DefinedAttributes/hooks';
import useListLocation from 'hooks/useListLocation';
import { GetLocationsOptionalParams } from 'hooks/useListLocation/typings';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import moment from 'moment';
import { addNewDefaultValue, getSelectOptions, updateSingleDefaultValue } from 'pages/Network/hook';
import { FilterArrayProp } from 'pages/Network/typings';
import React, {
  ChangeEvent,
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  AttributeResponse,
  ListAttributesDefaultValuesOptionalParams,
  ListAttributesOptionalParams,
  LocationResponse,
  TradePartnerResponse,
  UpdateTemplateAttributeRequest,
} from 'services/api/client/src';
import useAttributes, {
  useDefaultAttributes,
  useDeleteAttributeDefaultValue,
  useUpdateAttribute,
} from 'services/api/useAttributes';
import { useGetLocationById } from 'services/api/useLocations';
import { useDebouncedCallback } from 'use-debounce';
import { errorHandler, globalStyles } from 'utils';
import styles from '../../index.module.less';
import {
  CompanyComponentType,
  EditableFormProps,
  EventTableItem,
  EventTableProps,
} from './typings';

const getAttributeEnum = (array: Array<AttributeResponse>, row?: EventTableItem) => {
  const options = array.reduce((acc: any, val) => {
    const isDisabled = val?.fieldProperties?.values?.defaultValues?.some(
      (item) => item?.locationId === row?.locId,
    );
    const obj = val.id
      ? {
          [val.id]: {
            text: val?.name,
            value: val?.id,
            disabled: isDisabled,
          },
        }
      : {};
    return { ...acc, ...obj };
  }, {});
  return row?.itemProps?.id
    ? {
        ...options,
        [row?.itemProps?.id]: {
          text: [row?.itemProps?.name || ''],
          value: [row?.itemProps?.id],
          isDisabled: row?.itemProps?.fieldProperties?.values?.defaultValues?.some(
            (item) => item?.locationId === row?.locId,
          ),
        },
      }
    : options;
};

const getLocationEnum = (
  locationData: Array<LocationResponse>,
  selectedRow?: EventTableItem,
  row?: EventTableItem,
) => {
  const options = locationData.reduce((acc: any, val) => {
    const isDisabled =
      row?.locId === val?.id
        ? false
        : selectedRow?.itemProps?.fieldProperties?.values?.defaultValues?.some(
            (item) => item?.locationId === val?.id,
          );
    const obj = {
      [val.id as string]: {
        text: [val.name],
        value: [val.id || ''],
        disabled: isDisabled,
      },
    };
    return { ...acc, ...obj };
  }, {});
  return row?.locId
    ? {
        ...options,
        [row.locId as string]: {
          text: [row.locationName],
          value: [row.locId || ''],
          disabled: false,
        },
      }
    : options;
};
type ColumnsType = {
  allAttributes: Array<AttributeResponse>;
  locationData: Array<LocationResponse>;
  onEditRow: (actionPayload?: EventTableItem) => void;
  onDelRow: (actionPayload?: EventTableItem) => void;
  onChangeValue?: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void;
  editableFormRef?: MutableRefObject<EditableFormInstance<EditableFormProps> | undefined>;
  hasAdvancedSettings: boolean;
  selectedRow?: EventTableItem;
  setSelectedRow: (actionPayload?: EventTableItem) => void;
  fieldSearchfilter: ProColumns;
  propertyNameSearchfilter: ProColumns;
  fieldTypeFilters: Array<FilterArrayProp>;
  valuesSearchfilter: ProColumns;
  defaultValueSearchfilter: ProColumns;
  locationNameSearchfilter: ProColumns;
  stdFilters?: Array<FilterArrayProp>;
  namespaceFilters?: Array<FilterArrayProp>;
  selectedCompany?: TradePartnerResponse;
  onAttributeSearch: (val: string) => void;
  onLocationSearch: (val: string) => void;
  onLocationScroll?: (event: any) => void;
  onAttributeScroll?: (event: any) => void;
};

const filterValueArray = (array: Array<DefaultOptionType> | []) =>
  array.filter((item) => item.value !== '') || [];

const GetFieldComponent = ({ record, form, recordKey }: CompanyComponentType) => {
  const values = filterValueArray(record?.values || []);
  const { setFieldsValue } = form;
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_attributes' });

  const setDefaultValue = (val: string) => {
    setFieldsValue({
      [String(recordKey)]: {
        defaultValue: val,
      },
    });
  };

  const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (record) {
      setDefaultValue(dateString);
    }
  };

  const onSelectChange = (val: string) => {
    if (record) {
      setDefaultValue(val);
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (record) {
      setDefaultValue(e.target.value);
    }
  };

  switch (record?.fieldType) {
    case 'Date':
      return (
        <DatePicker
          value={record?.defaultValue ? moment(record?.defaultValue) : null}
          className={styles.datepicker}
          onChange={onDateChange}
          size="small"
        />
      );
    case 'Dropdown':
    case 'RadioButton':
      return (
        <Select
          allowClear
          value={record?.defaultValue !== '' ? record?.defaultValue : undefined}
          options={values}
          placeholder={t('default_value')}
          onChange={onSelectChange}
          size="small"
        />
      );
    case 'Text':
      return (
        <Input
          placeholder={t('default_value')}
          value={record?.defaultValue || ''}
          onChange={onInputChange}
          size="small"
        />
      );
    case 'Number':
      return (
        <NumericInput
          placeholder={t('default_value')}
          value={record?.defaultValue || ''}
          onChange={onSelectChange}
          size="small"
        />
      );
    default:
      <Input placeholder={t('default_value')} size="small" />;
  }
  return <Input placeholder={t('default_value')} size="small" />;
};

const getFieldsValueRenderer = (t: any, record: EventTableItem) => {
  if (record?.fieldType === 'Dropdown' || record?.fieldType === 'RadioButton') {
    return (
      <SingleColumnTags
        values={record?.values?.map((item) => String(item?.value) || '') || []}
        columnTitle={t('value')}
        modalTitle={record?.propertyName || ''}
      />
    );
  }
  if (record?.defaultValue && record?.fieldType !== 'Text') {
    return <div>{record?.defaultValue}</div>;
  }
  return <div>-</div>;
};

const columns = (
  {
    allAttributes,
    locationData,
    onEditRow,
    onDelRow,
    editableFormRef,
    hasAdvancedSettings,
    selectedRow,
    setSelectedRow,
    fieldSearchfilter,
    propertyNameSearchfilter,
    fieldTypeFilters,
    valuesSearchfilter,
    defaultValueSearchfilter,
    locationNameSearchfilter,
    stdFilters,
    namespaceFilters,
    onAttributeSearch,
    onLocationSearch,
    onAttributeScroll,
  }: ColumnsType,
  t: any,
): Array<ProColumns<EventTableItem>> => [
  {
    title: t('field_attribute.title'),
    dataIndex: 'id',
    fixed: 'left',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('field_attribute.message'),
        },
      ],
      hasFeedback: false,
    },
    valueType: 'select',
    valueEnum: getAttributeEnum(allAttributes, selectedRow),
    render: (text, record) => record?.field,
    fieldProps: (form, { rowKey }) => ({
      placeholder: t('field'),
      size: 'small',
      showSearch: true,
      onSearch: (val: any) => onAttributeSearch(val),
      onPopupScroll: onAttributeScroll,
      onChange: (val: any) => {
        const item: AttributeResponse =
          [...allAttributes, selectedRow?.itemProps]?.find((i) => i?.id === val) || {};
        setSelectedRow({
          key: selectedRow?.key || '',
          ...selectedRow,
          itemProps: item,
        });

        editableFormRef?.current?.setFieldsValue({
          [String(rowKey)]: {
            id: item?.id || '',
            itemProps: item,
            propertyName: item?.fieldProperties?.fields?.propertyName,
            fieldType: item.fieldProperties?.fields?.fieldType,
            stdOption: item?.fieldProperties?.location || '',
            namespace: item?.fieldProperties?.namespace?.name || '',
            defaultValue: undefined,
            values:
              item.fieldProperties?.fields?.fieldType === 'Dropdown' ||
              item.fieldProperties?.fields?.fieldType === 'RadioButton'
                ? getSelectOptions(item?.fieldProperties?.values?.valueOptions || [])
                : [],
          },
        });
      },
    }),
    ...fieldSearchfilter,
  },
  {
    title: t('data_attribute.title'),
    dataIndex: 'propertyName',
    renderFormItem: (_, { value }) => (
      <Input
        name="propertyName"
        value={value}
        size="small"
        placeholder={t('data_attribute.placeholder')}
        disabled
      />
    ),
    ...propertyNameSearchfilter,
  },
  {
    title: t('field_type.title'),
    dataIndex: 'fieldType',
    fieldProps: {},
    renderFormItem: (_, { value }) => (
      <Input
        name="fieldType"
        value={value}
        size="small"
        placeholder={t('data_attribute.placeholder')}
        disabled
      />
    ),
    render: (text) => (
      <>
        {text === 'Date' && <DatePicker style={globalStyles.w100} showTime size="small" disabled />}
        {text === 'Dropdown' && (
          <Select size="small" placeholder={t('field_type.dropdown')} disabled />
        )}
        {text === 'Text' && <Input size="small" placeholder={t('field_type.text')} disabled />}
        {text === 'Number' && <Input size="small" placeholder={t('field_type.number')} disabled />}
        {text === 'RadioButton' && (
          <Input size="small" placeholder={t('field_type.radio_button')} disabled />
        )}
      </>
    ),
    filters: fieldTypeFilters,
  },
  {
    title: t('value'),
    dataIndex: 'values',
    fieldProps: {
      size: 'small',
    },
    renderFormItem: (_, { record }) =>
      record?.fieldType === 'Dropdown' || record?.fieldType === 'RadioButton' ? (
        <SingleColumnTags
          values={record?.values?.map((item) => String(item?.value) || '') || []}
          columnTitle={t('value')}
          modalTitle={record?.propertyName || ''}
        />
      ) : (
        <Input placeholder={t('value')} size="small" disabled />
      ),
    render: (text, record) => getFieldsValueRenderer(t, record),
    ...valuesSearchfilter,
  },
  {
    title: t('default_value'),
    dataIndex: 'defaultValue',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('default_value_required'),
        },
      ],
    },
    renderFormItem: (_schema, record, form) => (
      <GetFieldComponent record={record?.record} form={form} recordKey={record?.recordKey} />
    ),
    render: (text) => (text !== 'undefined' ? <div>{text}</div> : '-'),
    ...defaultValueSearchfilter,
  },
  {
    title: t('location_name'),
    dataIndex: 'locId',
    valueType: 'select',
    fieldProps: {
      size: 'small',
      showSearch: true,
      // onPopupScroll: onLocationScroll,
      onSearch: (val: any) => onLocationSearch(val),
      onChange: (val: any) => {
        if (selectedRow) {
          setSelectedRow({
            ...selectedRow,
            key: selectedRow?.key,
            locId: val,
          });
        } else {
          setSelectedRow({
            key: '',
            locId: val,
          });
        }
      },
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('location_required'),
        },
      ],
    },
    render: (text, record) => record?.locationName,
    valueEnum: (row) => getLocationEnum(locationData, selectedRow, row),
    ...locationNameSearchfilter,
  },
  {
    title: t('std'),
    dataIndex: 'stdOption',
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    hideInTable: !hasAdvancedSettings,
    render: (text) => `${text}`,
    filters: stdFilters,
  },
  {
    title: t('namespace'),
    dataIndex: 'namespace',
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    hideInTable: !hasAdvancedSettings,
    render: (text) => `${text}`,
    filters: namespaceFilters,
  },
  {
    title: '',
    dataIndex: 'actions',
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons record={record} onDelete={onDelRow} onEdit={onEditRow} />
    ),
    fixed: 'right',
    width: 100,
  },
];

const AttributeTable: FC<EventTableProps> = ({ form, selectedCompany }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.event_table' });
  const { setFieldsValue } = form;
  const eventTableItemRef = useRef<ActionType>();
  const hasAdvancedSettings = useAttributeStore((state) => state.hasAdvancedSettings);
  const [selectedRow, setSelectedRow] = useState<EventTableItem>();
  const templateAttr: Array<EventTableItem> = form.getFieldValue('attributeTable');
  const editableFormRef = useRef<EditableFormInstance<any>>();
  const getLocationObj = useGetLocationById();
  const [addMode, setAddMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [statePageSize, setStatePageSize] = useState<number>(10);
  const [totalItem, setTotalItem] = useState<number>(0);

  const setTemplateAttr = useCallback(
    (value?: Array<EventTableItem>) => {
      setFieldsValue({
        attributeTable: value,
      });
    },
    [setFieldsValue],
  );
  const queryClient = useQueryClient();

  const [param, setParam] = useState<ListAttributesDefaultValuesOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [attributeParam, setAttributeParam] = useState<ListAttributesOptionalParams>({
    pageNumber: 1,
    pageSize: 9999,
  });
  const { data: attributes, isLoading: attributeLoading } = useAttributes(attributeParam);
  const allAttributes: Array<AttributeResponse> = useMemo(
    () =>
      attributes?.results?.filter(
        (i) => !(i?.name === 'Disposition' || i?.name === 'Business Step'),
        [],
      ) || [],
    [attributes],
  );
  const updateAttribute = useUpdateAttribute(queryClient);
  const deleteTemplateAttribute = useDeleteAttributeDefaultValue(queryClient);
  const { data: dataTemplateAttributes } = useDefaultAttributes({
    partnerId: selectedCompany?.id || '',
    ...param,
  });

  const [locationParam, setLocationParam] = useState<GetLocationsOptionalParams>({
    partnerIds: [selectedCompany?.id || ''],
    pageNumber: 1,
    pageSize: 10,
  });

  const { data: allLocationData } = useListLocation(locationParam);

  const locationData: Array<LocationResponse> = allLocationData ?? [];

  const stdFilters: Array<FilterArrayProp> = [
    { value: 'ILMD', text: 'ILMD' },
    { value: 'Extension', text: t('extension') },
  ];

  const namespaceFilters: Array<FilterArrayProp> = [
    { value: 'GDST', text: 'GDST' },
    { value: 'CBV', text: 'CBV' },
  ];

  const fieldTypeFilters: Array<FilterArrayProp> = [
    { value: 'Text', text: t('text') },
    { value: 'Number', text: t('number') },
    { value: 'Dropdown', text: t('dropdown') },
    { value: 'RadioButton', text: t('radiobutton') },
    { value: 'Date', text: t('date') },
  ];

  const { filters: fieldSearchfilter } = useTableSearchFilter({
    title: t('field_attribute.title'),
  });
  const { filters: propertyNameSearchfilter } = useTableSearchFilter({
    title: t('data_attribute.title'),
  });

  const { filters: valuesSearchfilter } = useTableSearchFilter({
    title: t('value'),
  });
  const { filters: defaultValueSearchfilter } = useTableSearchFilter({
    title: t('default_value'),
  });

  const { filters: locationNameSearchfilter } = useTableSearchFilter({
    title: t('location_name'),
  });

  useEffect(() => {
    if (dataTemplateAttributes?.totalItems) {
      setTotalItem(dataTemplateAttributes?.totalItems);
    }
  }, [dataTemplateAttributes?.totalItems]);

  useEffect(
    () => {
      setLoading(true);
      setTemplateAttr(undefined);
      setLocationParam({ ...locationParam, partnerIds: [selectedCompany?.id || ''] });
      setParam({
        ...param,
        partnerId: selectedCompany?.id || '',
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCompany?.id],
  );

  useEffect(() => {
    if (dataTemplateAttributes?.results) {
      if (dataTemplateAttributes?.results?.length) {
        const modifiedAttributes: Array<EventTableItem> =
          dataTemplateAttributes?.results?.map((item) => ({
            key: `${item.attribute?.id}/${item?.locationId}`,
            id: item?.attributeId || '',
            locId: item?.locationId,
            locationName: item?.location?.name,
            field: item?.attribute?.name,
            propertyName: item?.attribute?.fields?.propertyName,
            fieldType: item?.attribute?.fields?.fieldType,
            stdOption: item?.attribute?.location,
            namespace: item?.attribute?.namespace?.name || '',
            values:
              item?.attribute?.fields?.fieldType === 'Dropdown' ||
              item?.attribute?.fields?.fieldType === 'RadioButton'
                ? getSelectOptions(item?.attribute?.values?.valueOptions || [])
                : [],
            defaultValue: item?.value,
            itemProps: allAttributes?.find(
              (i: AttributeResponse) => item?.attribute?.name === i?.name,
              [],
            ),
          })) || [];
        setTemplateAttr(modifiedAttributes);
        setLoading(false);
      } else {
        setTemplateAttr([]);
        setLoading(false);
      }
    }
  }, [allAttributes, dataTemplateAttributes?.results, setTemplateAttr]);

  const onEditRow = async (actionPayload?: EventTableItem) => {
    setSelectedRow({
      key: actionPayload?.key || '',
      locId: actionPayload?.locId,
      itemProps: actionPayload?.itemProps,
    });
    eventTableItemRef.current?.startEditable(actionPayload?.key || 0);
  };

  const deleteWarning = async (locationName?: string, attribute?: string) =>
    new Promise((resolve) => {
      Modal.warning({
        title: t('delete_warning', { field: attribute, location: locationName }),
        centered: true,
        okCancel: true,
        width: 500,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
          className: styles.canceltext,
        },
        okText: t('delete'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onCancel: () => resolve(false),
        onOk: () => resolve(true),
      });
    });

  // Delete Attribute
  const onDelRow = async (actionPayload?: EventTableItem) => {
    const consent = await deleteWarning(actionPayload?.locationName, actionPayload?.field);
    if (consent && actionPayload) {
      try {
        await deleteTemplateAttribute.mutateAsync({
          id: actionPayload?.id || '',
          locationId: actionPayload?.locId || '',
        });
        message.success(
          `${t('delete_success', {
            attribute: actionPayload?.field,
            from: actionPayload?.locationName,
          })}`,
        );
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    }
  };

  const onSaveTemplate = async (_rowKey: RecordKey, data: EventTableItem) => {
    const existingTemplateAttribute = templateAttr?.find(
      (attribute) => attribute?.key === data?.key,
    );

    const locObj = await getLocationObj.mutateAsync({ id: String(data?.locId) });

    // update attribute
    if (existingTemplateAttribute) {
      try {
        if (existingTemplateAttribute?.id !== data?.id) {
          await deleteTemplateAttribute.mutateAsync({
            id: data?.itemProps?.id || '',
            locationId: locObj?.id || '',
          });
        }
        const Payload: UpdateTemplateAttributeRequest = {
          id: data?.itemProps?.id || '',
          name: data?.itemProps?.name,
          allowDelete: data?.itemProps?.allowDelete,
          fieldProperties: {
            fields: { ...data?.itemProps?.fieldProperties?.fields },
            values: {
              ...data?.itemProps?.fieldProperties?.values,
              defaultValues: updateSingleDefaultValue(
                existingTemplateAttribute?.locId || '',
                data?.itemProps?.fieldProperties?.values?.defaultValues || [],
                {
                  locationId: locObj?.id || '',
                  locationName: locObj?.name || '',
                  value: data?.defaultValue || '',
                },
              ),
            },
            namespace: { ...data?.itemProps?.fieldProperties?.namespace },
            location: data?.itemProps?.fieldProperties?.location,
          },
        };
        updateAttribute.mutate({
          id: data?.itemProps?.id || '',
          attribute: Payload,
        });
        eventTableItemRef.current?.cancelEditable(data?.key || 0);
        message.success(`${t('success_message')}`);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    }
    // add attribute
    else {
      try {
        const Payload: UpdateTemplateAttributeRequest = {
          id: data?.itemProps?.id || '',
          name: data?.itemProps?.name,
          allowDelete: data?.itemProps?.allowDelete,
          fieldProperties: {
            fields: { ...data?.itemProps?.fieldProperties?.fields },
            values: {
              ...data?.itemProps?.fieldProperties?.values,
              defaultValues: addNewDefaultValue(
                [
                  {
                    label: locObj?.name,
                    itemProps: {
                      id: data?.locId,
                      name: locObj?.name,
                    },
                  },
                ],
                data?.itemProps?.fieldProperties?.values?.defaultValues,
                data?.defaultValue || '',
                t,
              ),
            },
            namespace: { ...data?.itemProps?.fieldProperties?.namespace },
            location: data?.itemProps?.fieldProperties?.location,
          },
        };

        updateAttribute.mutate({
          id: data?.itemProps?.id || '',
          attribute: Payload,
        });
        setAddMode(false);
        setStatePageSize(statePageSize - 1);
        eventTableItemRef.current?.cancelEditable(data?.key || 0);
        message.success(`${t('success_message')}`);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    }
  };

  const onTableChange = (
    params: TablePaginationConfig,
    filter: Record<string, Array<string> | null>,
  ) => {
    const fieldFilter = filter?.id
      ? {
          field: filter?.id?.[0] as string,
        }
      : {};
    const propertyNameFilter = filter?.propertyName
      ? {
          propertyName: filter?.propertyName?.[0] as string,
        }
      : {};
    const valueTypeFilter = filter?.values
      ? {
          values: filter?.values?.[0] as string,
        }
      : {};
    const defaultValueTypeFilter = filter?.defaultValue
      ? {
          defaultValue: filter?.defaultValue?.[0] as string,
        }
      : {};
    const locationNameFilter = filter?.locId
      ? {
          location: filter?.locId?.[0] as string,
        }
      : {};
    const fieldTypeFilter = filter?.fieldType
      ? {
          types: filter?.fieldType,
        }
      : {};
    const stdOptionFilter = filter?.stdOption
      ? {
          propertyLocations: filter?.stdOption,
        }
      : {};
    const namespaceFilter = filter?.namespace
      ? {
          namespaces: filter?.namespace,
        }
      : {};
    setStatePageSize(params?.pageSize || statePageSize);
    // @ts-ignore
    setParam({
      pageNumber: params?.current || 1,
      pageSize: params?.pageSize || 10,
      ...fieldFilter,
      ...propertyNameFilter,
      ...fieldTypeFilter,
      ...valueTypeFilter,
      ...defaultValueTypeFilter,
      ...locationNameFilter,
      ...stdOptionFilter,
      ...namespaceFilter,
    });
  };

  const onAttributeDebounce = useDebouncedCallback(
    // function
    (val: string) => {
      setAttributeParam({
        ...attributeParam,
        fieldsFieldName: val,
      });
    },
    // delay in ms
    1000,
  );

  const onAttributeSearch = useCallback(
    (val: string) => {
      onAttributeDebounce(val);
    },
    [onAttributeDebounce],
  );

  const onLocationDebounce = useDebouncedCallback(
    // function
    (val: string) => {
      setLocationParam({
        ...locationParam,
        locationName: val,
      });
    },
    // delay in ms
    1000,
  );

  const onLocationSearch = useCallback(
    (val: string) => {
      onLocationDebounce(val);
    },
    [onLocationDebounce],
  );

  return (
    <GTable<EventTableItem>
      key="attributeTable1"
      actionRef={eventTableItemRef}
      editableFormRef={editableFormRef}
      columns={columns(
        {
          allAttributes,
          locationData,
          onEditRow,
          onDelRow,
          editableFormRef,
          hasAdvancedSettings,
          selectedRow,
          setSelectedRow,
          fieldSearchfilter,
          propertyNameSearchfilter,
          fieldTypeFilters,
          valuesSearchfilter,
          defaultValueSearchfilter,
          locationNameSearchfilter,
          stdFilters,
          namespaceFilters,
          selectedCompany,
          onAttributeSearch,
          onLocationSearch,
          // onLocationScroll,
          //  onAttributeScroll,
        },
        t,
      )}
      editable={{
        onSave: (rowKey, data) => onSaveTemplate(rowKey, data),
        onCancel: async (_rowKey, data) => {
          if (addMode) {
            setAddMode(false);
            setStatePageSize(statePageSize - 1);
          }
          eventTableItemRef.current?.cancelEditable(data?.id || 0);
          setSelectedRow(undefined);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={() => {
        setAddMode(true);
        setStatePageSize((param?.pageSize || 0) + 1);
        eventTableItemRef.current?.addEditRecord?.({
          key: Date.now().toString(),
        });
        setSelectedRow(undefined);
      }}
      loading={loading || attributeLoading}
      value={templateAttr}
      // @ts-ignore
      onTableChange={onTableChange}
      rowKey="key"
      enableRecordCreator
      addBtnText={t('add_attribute_button')}
      scroll={{ x: hasAdvancedSettings ? 1200 : 800, y: '75vh' }}
      pagination={{
        pageSize: statePageSize,
        total: totalItem,
        showTotal: (total, range) =>
          t('attribute_table_pagi', { range0: range[0], range1: range[1], total }),
      }}
    />
  );
};
export default React.memo(AttributeTable);

import { createContext, useContext } from 'react';
import { NotificationContextProps } from './typings';

const NotificationContext = createContext<NotificationContextProps>({
  hasBellDot: false,
  hasNotification: false,
  hasRecentActivity: false,
  isNotification: false,
  setHasBellDot: () => {},
  setVisible: () => {},
});

export const useNotifyContext = (): NotificationContextProps => {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    throw new Error('NotificationContext must be used within a EventContextProvider');
  }

  return context;
};

export default NotificationContext;

/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { message, RowProps, Space } from 'antd';
import moment from 'moment';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { TransformRequest } from 'services/api/client/src';
import { useCreateTransformEvent } from 'services/api/events';
import {
  apiDateFormat,
  checkRequestedDocuments,
  errorHandler,
  getBizStepIdByURN,
  getCustomProperties,
  getDispositionIdByURN,
  getProductsT,
  getTimeFormat,
  getTimezoneOffset,
  hasUnsavedChanges,
  mapCertProperties,
} from 'utils';
import { useEventsStore, useTitle } from '../hooks';
import { useEventActions } from './Forms';
import TransformFormFields, { DataItem } from './Forms/TransformForm.fields';
import { EventPageProps } from './typings';

const rowProps: RowProps = { gutter: [24, 24] };

const Transform: FC<EventPageProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.transform' });
  /* do not use t below , later is translated in header */
  useTitle('Transform');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();

  const { hasAdvancedSettings, dataEntryMethod, setSubmitting, template } = useEventsStore();
  const {
    disabledDate,
    disabledTime,
    onChangeDate,
    clearSameDayMessage,
    onChangeTemplate,
    timeValidation,
    lastEventDate,
    defaultUom,
    defaultTimezone,
    identifier,
    isSerial,
  } = useEventActions();

  const createTransformEvent = useCreateTransformEvent(queryClient);
  const generateSuccessMessage = (formData: DataItem) => {
    const msg =
      (formData?.ipProducts?.length || 0) > 1
        ? t('submit_success_many', { count: formData?.ipProducts?.length })
        : t('submit_success', {
            type: getProductsT(identifier),
            id: formData?.ipProducts?.[0]?.lotSerial || '',
          });
    setTimeout(() => {
      message.success(<Space>{msg}</Space>);
    }, 1000);
  };

  const onCSVSubmit = async (formData: DataItem) => {
    try {
      const values = formData?.csvData;

      if (values?.length) {
        await editableTableFormRef.current?.validateFields();
        const reqData: TransformRequest = {
          purchaseOrder: formData.poNumber,
          bizStepId: getBizStepIdByURN(formData.bizStep),
          dispositionId: getDispositionIdByURN(formData.disposition),
          eventTime: apiDateFormat(
            moment(formData?.csvData?.[0]?.date).format('YYYY-MM-DD'),
            getTimeFormat(formData?.csvData?.[0].time),
          ),
          eventTimeZone: getTimezoneOffset(formData.timeZone),
          locationId: formData.location,
          inputProducts: formData?.ipProducts?.map((product) => ({
            // productId: product.parentProductId,
            id: product.primaryId,
            quantity: isSerial ? 1 : Number(product.quantity || 0),
          })),
          outputProducts: formData?.csvData?.map((product) => ({
            productId: product.productId,
            lotSerial: product.lotID,
            quantity: isSerial ? 1 : Number(product.quantity || 0),
          })),
          documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
          templateId: template?.id,
          masterData: getCustomProperties(formData?.customProperties, template),
          certifications: mapCertProperties(formData?.certificationList || []),
        };
        await createTransformEvent.mutateAsync(reqData);
      }
    } catch (error) {
      throw error;
    }
  };
  const onFormSubmit = async (formData: DataItem) => {
    try {
      const reqData: TransformRequest = {
        purchaseOrder: formData.poNumber,
        bizStepId: getBizStepIdByURN(formData.bizStep),
        dispositionId: getDispositionIdByURN(formData.disposition),
        eventTime: apiDateFormat(formData.date, formData.time),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        inputProducts: formData?.ipProducts?.map((product) => ({
          // productId: product.parentProductId,
          id: product.primaryId,
          quantity: product?.unitOfMeasure !== 'item' ? Number(product.quantity || 0) : 1,
        })),
        outputProducts: formData?.opProducts?.map((product) => ({
          productId: product.parentProductId,
          lotSerial: product.lotSerial,
          quantity: product?.unitOfMeasure !== 'item' ? Number(product.quantity || 0) : 1,
        })),
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        masterData: getCustomProperties(formData?.customProperties, template),
        certifications: mapCertProperties(formData?.certificationList || []),
      };
      await createTransformEvent.mutateAsync(reqData);
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      hasUnsavedChanges();
      if (!((formData?.opProducts?.length || 0) > 0 || (formData?.csvData?.length || 0) > 0)) {
        throw new Error(t('out_products_req'));
      }
      checkRequestedDocuments({ template, documents: formData?.documents });

      setSubmitting(true);

      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
      } else {
        await onCSVSubmit(formData);
      }

      generateSuccessMessage(formData);
      setSubmitting(false);
      clearSameDayMessage();
      navigate(-1);
    } catch (error) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      columns={TransformFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        lastEventDate,
        defaultUom,
        defaultTimezone,
        editableTableFormRef,
        isSerial,
        identifier,
        disabledDate,
        onChangeDate,
        disabledTime,
        onChangeTemplate,
        timeValidation,
      })}
      form={form}
      grid
      layoutType="Form"
      onFinish={onFinish}
      submitter={{ render: () => null }}
      rowProps={rowProps}
    />
  );
};

export default React.memo(Transform);

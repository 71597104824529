// @ts-nocheck
/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { GraphData, NodeConfig } from '@antv/g6/lib/types';
import { Form, message } from 'antd';
import { useActions } from 'hooks';
import moment from 'moment';
import {
  Header,
  ReceiveDataItem,
  ReceiveFormFields,
  useEventActions,
  useEventsStore,
  useTitle,
} from 'pages/Events';
import { ProductInventoryItem } from 'pages/Products';

import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateReceiveRequest, EnrichedWorkflowReceiveEvent } from 'services/api/client/src';
import {
  dateFormat,
  dateLocaleFormat,
  errorHandler,
  getLocationById,
  getLocationNameById,
  getProductById,
  getProductNameById,
  getShipmentItemFromProductInventory,
  getTimezoneOffset,
  getUom,
  timeLocaleFormat,
} from 'utils';
import styles from '../index.module.less';
import { EventProps, WorkflowEvent } from '../typings';

type DataItem = ReceiveDataItem;
const Receive: FC<EventProps> = ({
  form,
  drawer,
  event,
  isVisible,
  selectedInstances,
  instances,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.recieve' });
  useTitle(t('title'));
  const locationDisabled = false;
  const [mainForm] = Form.useForm<DataItem>();
  const { setFieldsValue } = mainForm;

  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const graphData: GraphData = form?.getFieldValue('graphData') || {
    nodes: [],
    edges: [],
  };

  const isSSCC = useMemo(() => selectedInstances?.[0]?.isContainer || false, [selectedInstances]);
  const uom = useMemo(() => selectedInstances?.[0]?.unitOfMeasure, [selectedInstances]);
  const isInternal = useMemo(
    () =>
      selectedInstances?.[0]?.shipmentType === 'Internal' ||
      selectedInstances?.[0]?.shipmentType === 'NonWholechain',
    [selectedInstances],
  );

  const {
    containerIdArray,
    product: dataProduct,
    onChangeTemplate,
    defaultTimezone,
    isSerial,
    identifier,
    locationId,
    locationName,
    lastEventDate,
    disabledDate,
    disabledTime,
    onChangeDate,
    onChangeLocation,
  } = useEventActions({
    productId: event?.productId || selectedInstances?.[0]?.productId || undefined,
    locationId: selectedInstances?.[0]?.destinationId || event?.locationId || undefined,
    eventDate: dateLocaleFormat(selectedInstances?.[0]?.eventDate || new Date()) || '',
  });

  const { hasAdvancedSettings, dataEntryMethod, template, setSubmitting } = useEventsStore();

  const {
    products,
    locations,
    product,
    template: initTemplate,
  } = useActions({
    productId: selectedInstances?.[0]?.productId,
    templateId: event?.eventData?.templateId || event?.eventTemplateId,
  });

  const shipment = useMemo(
    () =>
      getShipmentItemFromProductInventory(selectedInstances?.[0], product, {
        shipmentType: selectedInstances?.[0]?.shipmentType || 'Internal',
        status: 'Pending',
        destinationId: selectedInstances?.[0]?.destinationId,
      }),
    [selectedInstances, product],
  );
  const getAssignedProduct = useCallback(() => {
    if (shipment) {
      if (isInternal) {
        return shipment?.productId || shipment?.containerItems?.[0]?.productId;
      }
      // check if product urn already exist in dataProducts?.results
      const dp = products?.find((item) => item?.urn === shipment?.productURN);
      if (dp) {
        return dp?.id;
      }

      return undefined;
    }
    return undefined;
  }, [products, isInternal, shipment]);
  const onMount = useCallback(() => {
    if (shipment) {
      const edata = event?.eventData as EnrichedWorkflowReceiveEvent;

      setFieldsValue({
        location: shipment?.destinationId || edata?.destinationLocationId || '',
        poNumber: edata?.purchaseOrder,
        date: edata?.eventTime ? dateFormat(edata?.eventTime) : undefined,
        time: edata?.eventTime ? timeLocaleFormat(edata?.eventTime) : undefined,
        quantity: shipment?.quantity || 0,
        unitOfMeasure: shipment?.unitOfMeasure || 'LBS',
        primaryId: shipment?.primaryId || '',
        scss: shipment?.isContainer ? shipment?.primaryId || '' : '',
        assignedProduct: getAssignedProduct(),
        bizStep: edata?.bizStep || 'urn:epcglobal:cbv:bizstep:receiving',
        disposition: edata?.disposition || 'urn:epcglobal:cbv:disp:in_progress',
        timeZone: defaultTimezone,
        customData: event?.eventTemplateId || edata?.templateId || undefined,
      });
    }
  }, [shipment, setFieldsValue, getAssignedProduct, event, defaultTimezone]);
  const onGetInitTemplate = useCallback(() => {
    if (event && initTemplate) {
      const edata = event?.eventData as EnrichedWorkflowReceiveEvent;
      const templateId = edata?.templateId || event?.eventTemplateId || '';
      onChangeTemplate?.(
        mainForm,
        {
          itemProps: initTemplate,
        },
        templateId,
        locationId,
      );
      if (edata) {
        setFieldsValue({
          customProperties: edata?.customProperties?.map((el) => el?.value || '') || [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTemplate, event]);
  /* effect to sync eventData in event */
  useEffect(() => onMount(), [onMount]);
  useEffect(() => onGetInitTemplate(), [onGetInitTemplate]);

  const onCloseDrawer = () => {
    mainForm?.resetFields();
    drawer?.closeDrawer();
  };
  const onCreateStoreInstance = (p: ProductInventoryItem) => {
    const instanceExists = instances?.find((instance) => instance?.id === p?.id);
    if (!instanceExists) {
      const newInstances = [...(instances || []), p];
      form?.setFieldsValue({
        instances: newInstances,
      });
    } else {
      form?.setFieldsValue({
        instances: instances?.map((instance) => {
          if (instance?.id === p?.id) {
            return p;
          }
          return instance;
        }),
      });
    }
  };
  const onCSVSubmit = async (formData: DataItem) => {
    const values = formData?.csvData;
    try {
      if (values?.length) {
        await editableTableFormRef.current?.validateFields();

        const requests: Array<CreateReceiveRequest> = [];
        values?.forEach((value) => {
          const reqData: CreateReceiveRequest = {
            eventTime: new Date(moment(`${value.date} ${value.time}`).toISOString()),
            eventTimeZone: getTimezoneOffset(value.timeZone),
            bizStep: value.bizStep,
            disposition: value.disposition,
            originLocationId: formData.location,
            purchaseOrder: value.poNumber,
            products: [
              {
                quantity: !isSerial ? Number(value?.quantity || 0) : 1,
                instanceId: value.primaryId,
              },
            ],
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            templateName: template?.name,
            customProperties:
              template?.templateFields?.map((field) => ({
                name: field?.name || field?.propertyName,
                value: String(value?.[String(field?.propertyName || field?.name || '')] || ''),
                namespace: field?.namespace?.prefix || 'wc',
              })) || [],
          };
          requests.push(reqData);
        });
        setSubmitting(false);
      } else {
        throw new Error('No data to submit');
      }
    } catch (error) {
      throw error;
    }
  };

  const onFormSubmit = async (formData: DataItem) => {
    try {
      const updatedBody: EnrichedWorkflowReceiveEvent = {
        id: event?.id,
        eventIdInTemplate: event?.id,
        eventId: event?.id,
        sourceId: selectedInstances?.map((i) => i?.id || '') || [],
        bizStep: formData.bizStep,
        disposition: formData.disposition,
        eventTime: new Date(moment(`${formData.date} ${formData.time}`).toISOString()),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        originLocationId: selectedInstances?.[0]?.locationId || '',
        destinationLocationId: formData.location,
        purchaseOrder: formData.poNumber,
        products: formData?.inboundShipments
          ?.filter((p) => !p?.isContainer || false)
          ?.map((p) => ({
            productId: p?.productId || selectedInstances?.[0]?.productId || '',
            instanceId: p.primaryId,
            quantity: formData?.quantity || p.quantity || 0,
            shipEventId: p.lastEventId,
            remainingQuantity: 0,
          })),
        newProducts: formData?.inboundShipments
          ?.filter((p) => !p?.isContainer || false)
          ?.map((p) => ({
            productId: p?.productId || selectedInstances?.[0]?.productId || '',
            instanceId: p.primaryId,
            quantity: formData?.quantity || p.quantity || 0,
            shipEventId: p.lastEventId,
            lotSerial: p.lotSerial,
            remainingQuantity: 0,
          })),
        containerIdentifiers: containerIdArray,

        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        templateName: template?.name,
        customProperties:
          template?.templateFields?.map((field, idx) => ({
            name: field?.name || field?.propertyName,
            value: formData?.customProperties?.[idx]
              ? String(formData.customProperties?.[idx])
              : '',
            namespace: field?.namespace?.prefix || 'wc',
          })) || [],
      };
      const location = getLocationById(formData.location, locations);

      onCreateStoreInstance({
        id: event?.id,
        name: selectedInstances?.[0]?.name || '',
        productId: selectedInstances?.[0]?.productId || '',
        instanceId: selectedInstances?.[0]?.instanceId || '',
        primaryId: selectedInstances?.[0].lotSerial,
        lotSerial: selectedInstances?.[0].lotSerial,
        quantity:
          selectedInstances?.[0]?.unitOfMeasure !== 'item'
            ? Number(selectedInstances?.[0].quantity || 0)
            : 1,
        unitOfMeasure: selectedInstances?.[0]?.unitOfMeasure || 'LBS',
        eventName: 'Receive',
        location: location?.name || '',
        locationAddress: location?.address?.line2 || '',
        locationId: formData.location || '',
        destinationId: formData.location || '',
        eventId: event?.id,
        isContainer: false,
        containerId: undefined,
        containerIdentifier: '',
        containerItems: [],
        tradePartnerName: location?.tradePartnerName || '',
        address: location?.address,
        eventDate: updatedBody.eventTime,
        loggedDate: new Date(),
        purchaseOrder: formData.poNumber,
        shipmentType: 'Internal',
        shipped: false,
        productIdentifierType: getProductById(selectedInstances?.[0]?.productId, products)
          ?.productIdentifierType,
        bizStep: formData?.bizStep || 'urn:epcglobal:cbv:disp:receiving',
        disposition: formData.disposition,
        timezone: getTimezoneOffset(formData.timeZone),
        templateId: template?.id,
      });
      const updatedNodes = graphData.nodes?.map((item: WorkflowEvent) => {
        if (item.id === event?.id) {
          const updatedNode: NodeConfig = {
            id: item?.id || '',
            eventType: 'Receive',
            eventTime: updatedBody?.eventTime ? dateFormat(updatedBody?.eventTime) : undefined,
            name: selectedInstances?.[0]?.name || '',
            primaryId: selectedInstances?.[0]?.primaryId || '',
            quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
            unitOfMeasure: getUom({ product }),
            eventTemplateId: item?.eventTemplateId,
            eventTemplateName: location?.address?.line2 || location?.name || '',
            locationId: formData?.location,
            locationName: getLocationNameById(formData.location, locations),
            productId: formData?.inboundShipments?.[0]?.productId,
            productName: getProductNameById(formData?.inboundShipments?.[0]?.productId, products),
            colourIndex: 'colour_4',
            children: [],
          };
          return {
            ...item,
            ...updatedNode,
            eventData: updatedBody,
          };
        }
        return item;
      });
      const updatedGraphData: GraphData = {
        ...graphData,
        nodes: updatedNodes as NodeConfig[],
      };

      form?.setFieldsValue({
        graphData: updatedGraphData,
      });
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);

      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
        setSubmitting(false);
      } else {
        await onCSVSubmit(formData);
      }
      onCloseDrawer();
    } catch (error: any) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      key={event?.id}
      form={mainForm}
      layoutType="DrawerForm"
      columns={ReceiveFormFields({
        t,
        disabledDate,
        disabledTime,
        onChangeDate,
        onChangeTemplate,
        uom,
        isSSCC,
        hasAdvancedSettings,
        dataEntryMethod,
        lastEventDate,
        defaultTimezone,
        isInternal,
        products: [shipment],
        isSerial,
        identifier,
      })}
      grid
      rowProps={{ gutter: 32 }}
      onFinish={onFinish}
      // @ts-ignore
      title={
        <Header
          isManual={dataEntryMethod === 'manual'}
          title={t('title')}
          form={mainForm}
          locationId={locationId?.trim() ? locationId?.trim() : undefined}
          locationName={locationName}
          locationDisabled={locationDisabled}
          onChangeLocation={onChangeLocation}
        />
      }
      submitter={{
        searchConfig: {
          submitText: t('add_event_title'),
          resetText: t('cancel_btn_title'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
      }}
      initialValues={{
        unitOfMeasure: getUom({ product: dataProduct }),
      }}
      visible={isVisible ? drawer?.visible || false : false}
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        className: styles.eventdrawer,
        ...drawer,
      }}
    />
  );
};

export default Receive;

import { Button, Space, Typography } from 'antd';
import React, { FC } from 'react';
import ShipmentTag from '../components/ShipmentTag';
import useShipmentStore from '../hooks/useShipmentStore';
import { ShipmentDetailsHeaderProps } from './typings';

const ShipmentDetailsHeader: FC<ShipmentDetailsHeaderProps> = ({
  isViewTicket,
  date,
  status,
  customData,
  t,
}) => {
  const { toggleAdvancedSettings, hasAdvancedSettings, dataEntryMethod } = useShipmentStore();

  return (
    <Space direction="vertical">
      <Space size="middle">
        <Typography.Text className="ant-pro-form-group-title">
          {t?.('shipment_details_title')}
        </Typography.Text>
        {dataEntryMethod === 'manual' && !customData && (
          <Button type="link" size="middle" onClick={toggleAdvancedSettings} className="link">
            {hasAdvancedSettings ? t?.('hide_advance_title') : t?.('show_advance_title')}
          </Button>
        )}
      </Space>
      {isViewTicket && (
        <Space>
          <ShipmentTag status={status} />
          {date ? `${t?.('logged')} ${date}` : ''}
        </Space>
      )}
    </Space>
  );
};
export default React.memo(ShipmentDetailsHeader);

import { message } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import PlacesSelect from 'components/GSelect/PlacesSelect';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAddress, getGeocode } from 'services/api/usePlaces';
import errorHandler from 'utils/errorHandler';
import { AddressSelectProps } from './typings';

const LocationSearchInput: FC<AddressSelectProps> = ({
  editableFormRef,
  recordKey,
  form,
  disable,
  fieldValue,
  geoFieldValue,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.form_fields' });
  const [address, setAddress] = useState<string>();

  useEffect(() => {
    let addressText = form?.getFieldValue(fieldValue || 'address')?.displayAddress;
    if (
      editableFormRef?.current?.getFieldValue(String(recordKey))?.[fieldValue || 'address']?.props
        ?.children
    ) {
      addressText = editableFormRef?.current?.getFieldValue(String(recordKey))?.[
        fieldValue || 'address'
      ]?.props?.children;
    } else if (
      editableFormRef?.current?.getFieldValue(String(recordKey))?.[fieldValue || 'address']
        ?.displayAddress
    ) {
      addressText = editableFormRef?.current?.getFieldValue(String(recordKey))?.[
        fieldValue || 'address'
      ]?.displayAddress;
    }
    if (addressText) {
      setAddress(addressText);
    }
  }, [editableFormRef, recordKey, form, fieldValue]);
  const onChangePlace = useCallback(
    async (value: string, option: DefaultOptionType | DefaultOptionType[]) => {
      try {
        if (!value) {
          setAddress(value);
          if (recordKey) {
            editableFormRef?.current?.setFieldsValue({
              [String(recordKey)]: {
                [fieldValue || 'address']: {
                  line1: '',
                  line2: '',
                  city: '',
                  state: '',
                  country: '',
                  postalCode: '',
                },
                [geoFieldValue || 'geoCoordinates']: '',
              },
            });
          } else {
            form?.setFieldsValue({
              [fieldValue || 'address']: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: '',
                postalCode: '',
              },
              [geoFieldValue || 'geoCoordinates']: '',
            });
          }
          return;
        }
        // @ts-ignore
        const item: window.google.maps.places.AutocompletePrediction = option?.itemProps;
        const geoCode = await getGeocode(item?.place_id);
        const location = geoCode?.[0]?.geometry?.location;
        const addressObj = getAddress(geoCode?.[0]?.address_components || []);
        setAddress(value);
        if (recordKey) {
          editableFormRef?.current?.setFieldsValue({
            [String(recordKey)]: {
              [fieldValue || 'address']: {
                line1: addressObj?.addressLine1 || '',
                line2: addressObj?.addressLine2 || '',
                city: addressObj?.city || '',
                state: addressObj?.state || '',
                country: addressObj?.country || '',
                postalCode: addressObj?.postalCode || '',
                displayAddress: value || '',
              },
              [geoFieldValue || 'geoCoordinates']: `${location?.lat?.() || 0},${
                location?.lng?.() || 0
              }`,
            },
          });
        } else {
          form?.setFieldsValue({
            [fieldValue || 'address']: {
              line1: addressObj?.addressLine1 || '',
              line2: addressObj?.addressLine2 || '',
              city: addressObj?.city || '',
              state: addressObj?.state || '',
              country: addressObj?.country || '',
              postalCode: addressObj?.postalCode || '',
              displayAddress: value || '',
            },
            [geoFieldValue || 'geoCoordinates']: `${location?.lat?.() || 0},${
              location?.lng?.() || 0
            }`,
          });
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [editableFormRef, fieldValue, form, geoFieldValue, recordKey],
  );

  return (
    <PlacesSelect
      onChange={onChangePlace}
      value={address}
      placeholder={t('address')}
      disabled={disable}
    />
  );
};

export default LocationSearchInput;

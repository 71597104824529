import ProCard from '@ant-design/pro-card';
import ProForm, {
  ProFormDateRangePicker,
  ProFormSelect,
  ProFormText,
  QueryFilter,
} from '@ant-design/pro-form';
import { ListToolBarProps, ProColumns } from '@ant-design/pro-table';
import { Divider, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { Actions, GTable, TitleBar } from 'components';
import useModalVisibility from 'hooks/useModalVisibility';
import ResolveOrphanModal from 'pages/Reports/components/ResolveOrphanModal';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useOrphanEvents, useProductsTableFilters, useTradePartnerFilters } from 'services/api';
import { ListOrphansOptionalParams, OrphanResponse } from 'services/api/client/src';
import { useLocationFilters } from 'services/api/useLocations';
import { dateLocalFormat, getEventsT, getProductsT } from 'utils';
import styles from './index.module.less';
import { OrphanEventsQueryFilter, ReportsTitleProps } from './typings';

type ColumnsType = {
  onRowActionClick: (actionItemKey: string, actionPayload?: string) => void;
  t: TFunction<'pages', 'reports'>;
};

const productActionItems = (t?: TFunction<'pages', 'reports'>): Array<ItemType> => [
  {
    key: 'resolve_orphan',
    label: t?.('resolve_orphan'),
  },
];

const Columns = ({ onRowActionClick, t }: ColumnsType): ProColumns<OrphanResponse>[] => [
  {
    title: t('col.primary_id'),
    dataIndex: 'name',
    sorter: false,
    width: 130,
    hideInSetting: true,
    ellipsis: true,
    render: (text, record) =>
      `${record?.isContainer ? getProductsT('sscc') : getProductsT('lot')}: ${record?.lotSerial}`,
  },
  {
    title: t('col.product_name'),
    dataIndex: 'productName',
    sorter: false,
    width: 143,
    ellipsis: true,
    render: (text, record) => record?.productName,
  },
  {
    title: t('col.quantity'),
    dataIndex: 'quantity',
    sorter: false,
    width: 143,
    ellipsis: true,
    render: (text, record) => record?.quantity,
  },
  {
    title: t('col.company'),
    dataIndex: 'company',
    width: 143,
    ellipsis: true,
    render: (text, record) => record?.company,
  },
  {
    title: t('col.location'),
    dataIndex: 'location',
    width: 143,
    ellipsis: true,
    render: (text, record) => record?.locationName,
  },
  {
    title: t('col.po'),
    dataIndex: 'purchaseOrder',
    ellipsis: true,
    width: 143,
    render: (text, record) => record?.purchaseOrder,
  },
  {
    title: t('col.logged_date'),
    dataIndex: 'loggedDate',
    ellipsis: true,
    width: 160,
    render: (text, record) => `${record?.createdTime ? dateLocalFormat(record?.createdTime) : ''}`,
  },
  {
    title: t('col.event_date'),
    dataIndex: 'eventDate',
    ellipsis: true,
    width: 160,
    render: (text, record) => `${record?.date ? dateLocalFormat(record?.date) : ''}`,
  },
  {
    title: t('col.event'),
    dataIndex: 'event',
    ellipsis: true,
    width: 152,
    render: (text, record) => record?.eventType,
  },
  {
    title: t('col.shipment_status'),
    dataIndex: 'shipmentStatus',
    defaultSortOrder: 'descend',
    ellipsis: true,
    width: 152,
    render: (text, record) => record?.shipmentStatus,
  },
  {
    title: t('col.gtin'),
    dataIndex: 'gtin',
    ellipsis: true,
    width: 152,
    render: (text, record) => record?.gtin,
  },
  {
    title: t('col.shipped_from'),
    dataIndex: 'shippedFrom',
    ellipsis: true,
    width: 152,
    render: (text, record) => record?.shippedFrom,
  },
  {
    title: t('col.shipped_to'),
    dataIndex: 'shippedTo',
    ellipsis: true,
    width: 152,
    render: (text, record) => record?.shippedTo,
  },
  {
    title: t('col.ticket_id'),
    dataIndex: 'ticketId',
    ellipsis: true,
    width: 152,
    render: (text, record) => record?.ticketId,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    width: 120,
    fixed: 'right',
    render: (text, record) => (
      <Actions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        items={productActionItems(t)}
        onClick={onRowActionClick}
        actionPayload={record?.instanceId}
      />
    ),
  },
];

const Reports: FC<ReportsTitleProps> = () => {
  const [params, setParams] = useState<ListOrphansOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    sortBy: '-createdTime',
  });
  const [orphanInstanceId, setOrphanInstanceId] = useState<string>();
  const { t } = useTranslation('pages', { keyPrefix: 'reports' });
  const { data: events, isLoading } = useOrphanEvents(params);
  const records = useMemo(() => events?.results || [], [events]);
  const totalItems = useMemo(() => events?.totalItems || 0, [events]);
  const resolveOrphanModal = useModalVisibility(false);

  const onRowActionClick = (actionItemKey: string, actionPayload?: string) => {
    switch (actionItemKey) {
      case 'resolve_orphan':
        if (actionPayload) setOrphanInstanceId(actionPayload);
        resolveOrphanModal.show();
        break;
      default:
        break;
    }
  };

  const quickFilters = useMemo(() => [t('quick_filter.orphan')], [t]);

  const [selectedTags, setSelectedTags] = useState<string[]>([t('quick_filter.orphan')]);

  const handleChange = useCallback(
    (tag: string, checked: boolean) => {
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((i) => i !== tag);
      setSelectedTags(nextSelectedTags);
    },
    [selectedTags],
  );

  /* locations */
  const coreLocations = useLocationFilters();
  /* tradepartners */
  const coreTradePartners = useTradePartnerFilters();

  /* products */
  const coreProducts = useProductsTableFilters();

  /* event types */
  const coreEventTypes = useMemo(
    () => [
      { value: 'COMMISSION', label: getEventsT('commission'), text: getEventsT('commission') },
      { value: 'OBSERVE', label: getEventsT('observe'), text: getEventsT('observe') },
      { value: 'SHIP', label: getEventsT('ship'), text: getEventsT('ship') },
      { value: 'TRANSFORM', label: getEventsT('transform'), text: getEventsT('transform') },
      { value: 'AGGREGATION', label: getEventsT('aggregation'), text: getEventsT('aggregation') },
      {
        value: 'DECOMMISSION',
        label: getEventsT('decommission'),
        text: getEventsT('decommission'),
      },
      {
        value: 'DISAGGREGATION',
        label: getEventsT('disaggregation'),
        text: getEventsT('disaggregation'),
      },
      { value: 'RECEIVE', label: getEventsT('receive'), text: getEventsT('receive') },
    ],
    [],
  );

  const coreShipmentStatus = useMemo(
    () => [
      t('shipment_status.received'),
      t('shipment_status.rejected'),
      t('shipment_status.pending'),
    ],
    [t],
  );

  const onQueryFilter = useCallback(
    async (formData: OrphanEventsQueryFilter) => {
      const apiFilters: ListOrphansOptionalParams = {
        lotSerial: formData?.lotSerial ? formData?.lotSerial : undefined,
        productName: formData?.productName
          ? (Array.isArray(coreProducts) &&
              (coreProducts?.find((item) => item?.value === formData?.productName)
                ?.text as string)) ||
            ''
          : undefined,
        shippedToIds: formData?.shippedToIds ? formData?.shippedToIds : undefined,
        shippedFromIds: formData?.shippedFromIds ? formData?.shippedFromIds : undefined,
        company: formData?.company ? formData?.company : undefined,
        ticketId: formData?.ticketId ? formData?.ticketId : undefined,
        gtin: formData?.gtin ? formData?.gtin : undefined,
        shipmentStatus: formData?.shipmentStatus ? formData?.shipmentStatus : undefined,
        quantity: formData?.quantity ? Number(formData?.quantity) : undefined,
        locationIds: formData?.locationName ? formData?.locationName : undefined,
        eventTypes: formData?.eventType ? formData?.eventType : undefined,
        purchaseOrder: formData?.purchaseOrder ? formData?.purchaseOrder : undefined,
        startEventDate: formData?.eventDate?.[0] ? new Date(formData?.eventDate?.[0]) : undefined,
        endEventDate: formData?.eventDate?.[1] ? new Date(formData?.eventDate?.[1]) : undefined,
        startLoggedDate: formData?.loggedDate?.[0]
          ? new Date(formData?.loggedDate?.[0])
          : undefined,
        endLoggedDate: formData?.loggedDate?.[1] ? new Date(formData?.loggedDate?.[1]) : undefined,
      };

      setParams({
        ...params,
        ...apiFilters,
        pageNumber: 1,
      });
    },
    [coreProducts, params],
  );

  const onQueryFilterReset = useCallback(
    () =>
      setParams({
        pageNumber: 1,
        pageSize: 10,
        sortBy: '-createdTime',
      }),
    [],
  );

  const toolBarRender: ListToolBarProps = useMemo(
    () => ({
      title: <TitleBar title={t('header')} />,
      multipleLine: true,
      filter: (
        <ProCard title={t('filters.query_events')} bordered className={styles.card}>
          <ProForm.Item label={t('quick_filter.quick_filters')}>
            <Space size={[0, 8]} wrap>
              {quickFilters.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={selectedTags.includes(tag)}
                  onChange={(checked) => handleChange(tag, checked)}
                  className={styles.quickfilterheader}
                >
                  {tag}
                </CheckableTag>
              ))}
            </Space>
          </ProForm.Item>
          <Divider />
          <QueryFilter<OrphanEventsQueryFilter>
            defaultCollapsed
            defaultColsNumber={4}
            onFinish={onQueryFilter}
            searchText={t('quick_filter.search')}
            className={styles.quickfilter}
            onReset={onQueryFilterReset}
            labelWidth={110}
          >
            <ProFormSelect
              name="productName"
              label={t('col.product')}
              mode="single"
              showSearch
              options={
                (Array.isArray(coreProducts) &&
                  coreProducts?.map((item: any) => ({ ...item, label: item?.text }))) ||
                []
              }
            />
            <ProFormText name="lotSerial" label={t('col.primary_id')} />
            <ProFormText
              name="quantity"
              label={t('col.quantity')}
              rules={[
                {
                  pattern: /^([-]?[1-9][0-9]*|0)$/,
                  message: t('col.valid_quantity'),
                },
              ]}
            />
            <ProFormSelect
              name="eventType"
              label={t('col.event')}
              placeholder={t('col.event')}
              mode="multiple"
              options={coreEventTypes}
              showSearch
            />
            <ProFormSelect
              name="company"
              label={t('col.company')}
              placeholder={t('col.company')}
              mode="single"
              options={
                (Array.isArray(coreTradePartners) &&
                  coreTradePartners?.map((item: any) => ({ ...item, label: item?.text }))) ||
                []
              }
            />
            <ProFormSelect
              name="locationName"
              label={t('col.location')}
              mode="multiple"
              showSearch
              options={
                (Array.isArray(coreLocations) &&
                  coreLocations?.map((item: any) => ({ ...item, label: item?.text }))) ||
                []
              }
            />
            <ProFormDateRangePicker name="eventDate" label={t('col.event_date')} />
            <ProFormDateRangePicker name="loggedDate" label={t('col.logged_date')} />
            <ProFormText name="purchaseOrder" label={t('col.po')} />
            <ProFormSelect
              name="shippedToIds"
              label={t('col.shipped_to')}
              mode="multiple"
              showSearch
              options={
                (Array.isArray(coreLocations) &&
                  coreLocations?.map((item: any) => ({ ...item, label: item?.text }))) ||
                []
              }
            />
            <ProFormSelect
              name="shippedFromIds"
              label={t('col.shipped_from')}
              mode="multiple"
              showSearch
              options={
                (Array.isArray(coreLocations) &&
                  coreLocations?.map((item: any) => ({ ...item, label: item?.text }))) ||
                []
              }
            />
            <ProFormSelect
              name="shipmentStatus"
              label={t('col.shipment_status')}
              placeholder={t('col.shipment_status')}
              mode="multiple"
              options={coreShipmentStatus}
              showSearch
            />
            <ProFormText name="ticketId" label={t('col.ticket_id')} />
            <ProFormText name="gtin" label={t('col.gtin')} />
          </QueryFilter>
        </ProCard>
      ),
    }),
    [
      coreEventTypes,
      coreShipmentStatus,
      coreLocations,
      coreProducts,
      coreTradePartners,
      handleChange,
      onQueryFilter,
      onQueryFilterReset,
      quickFilters,
      selectedTags,
      t,
    ],
  );

  const onCloseResolveOrphan = useCallback(() => {
    resolveOrphanModal.hide();
    setOrphanInstanceId(undefined);
  }, [resolveOrphanModal]);

  return (
    <>
      {orphanInstanceId && (
        <ResolveOrphanModal
          closeModal={onCloseResolveOrphan}
          orphanInstanceId={orphanInstanceId}
          modalProps={resolveOrphanModal}
        />
      )}
      <GTable<OrphanResponse>
        columns={Columns({
          onRowActionClick,
          t,
        })}
        value={records}
        scroll={{ y: '75vh', x: 915 }}
        options={{
          reload: false,
          setting: false,
        }}
        toolbar={toolBarRender}
        loading={isLoading}
        pagination={{
          defaultPageSize: params.pageSize,
          total: totalItems,
        }}
        columnsState={{
          persistenceKey: 'dashboard-events',
        }}
      />
    </>
  );
};

export default React.memo(Reports);

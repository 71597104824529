import { InboxOutlined } from '@ant-design/icons';
import { Button, message, Space, Typography, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useEventsStore } from 'pages/Events/hooks';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';

const { Title, Text } = Typography;
const { Dragger } = Upload;

const CustomDataTemplate = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  return (
    <div>
      <Title level={5}>{t('custom_data_template')}</Title>
      <div className={styles.content}>
        <Text>{t('no_template')}</Text>
      </div>
    </div>
  );
};

export const CSVUpload = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.csv_upload' });
  const uploadProps = {
    name: 'csvfile',
    multiple: false,
    maxCount: 1,
    accept: 'text/csv',
    onChange: (info: UploadChangeParam<UploadFile<any>>) => {
      const { status } = info.file;
      switch (status) {
        case 'done':
          message.success(`${info.file.name} ${t('success_msg')}`);
          break;
        case 'error':
          message.error(`${info.file.name} ${t('error_msg')}`);
          break;
        default:
          // Handle unexpected status here
          break;
      }
    },
  };

  return (
    <div>
      <Space size="large" direction="vertical" className={styles.csvbtn}>
        <Title level={4}>{t('csv_upload')}</Title>
        <Button shape="round" type="primary">
          {t('download_csv_template')}
        </Button>
      </Space>

      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('upload_desc')}</p>
      </Dragger>
    </div>
  );
};

const DataTemplate = () => {
  const dataEntryMethod = useEventsStore((state) => state.dataEntryMethod);

  if (dataEntryMethod === 'manual') {
    return <CustomDataTemplate />;
  }

  return <CSVUpload />;
};

export default DataTemplate;

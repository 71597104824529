import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { RowProps } from 'antd';
import { TimezoneSelect } from 'components/GSelect';
import BizStepSelect from 'components/GSelect/BizStepSelect';
import DispositionSelect from 'components/GSelect/DispositionSelect';
import TemplateSelect from 'components/GSelect/TemplateSelect';
import moment from 'moment';
import { DataItem as DocumentItem } from 'pages/Documents/Forms/typings';
import { useEventsStore } from 'pages/Events/hooks';
import { useTranslation } from 'react-i18next';
import { getProductsT, getTemplateFields } from 'utils';
import {
  CertDocuments,
  CertificationInfo,
  DataTemplate,
  Documents,
  EventDetails,
} from '../Sections';
import DataEntryMethod from './DataEntryMethod';
import MultipleDates from './MutipleDates';
import DecommissionProducts from './TransformTables/DecommissionProducts';
import { DecomissionFormFieldsProps, DecommissionProductItem } from './typings';

const colProps = { xs: 24, sm: 12, md: 12, lg: 6 };
const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const colPropsFullCsv = { xs: 24, sm: 24, md: 24, lg: 24 };

const rowProps: RowProps = { gutter: [32, 24] };

export type DataItem = {
  advancedField?: string;
  advancedField2?: string;
  advancedField3?: string;
  customData?: string;
  decommissionType?: string;
  date?: string;
  location?: string;
  lotID?: string;
  poNumber?: string;
  product?: string;
  quantity?: string;
  time?: string;
  timeZone?: string;
  bizStep?: string;
  disposition?: string;
  eventProducts?: Array<DecommissionProductItem>;
  customProperties?: Array<string>;
  documents?: Array<DocumentItem>;
  isMultipleDates?: boolean;
  csvData?: Array<DataItem>;
  certificationList?: Array<CertificationInfo>;
  [key: string]: any;
};

export const DecommissionFormFields = ({
  hasAdvancedSettings,
  dataEntryMethod,
  lastEventDate,
  defaultTimezone,
  disabledDate,
  disabledTime,
  onChangeDate,
  onChangeTemplate,
  timeValidation,
  isSerial,
  identifier,
  selectedInstances,
  editableTableFormRef,
  validationMessage,
}: DecomissionFormFieldsProps): ProFormColumnsType<DataItem>[] => {
  const isManualEntry = dataEntryMethod === 'manual';
  const { multipleDates, template, setDataEntryMethod } = useEventsStore();
  const { t } = useTranslation('pages', { keyPrefix: 'events.decommission' });

  const decommissionTypes = {
    sold: { text: getProductsT('decommission.sold'), value: 'sold' },
    damaged: { text: getProductsT('decommission.damaged'), value: 'damaged' },
    destroyed: { text: getProductsT('decommission.destroyed'), value: 'destroyed' },
    disposed: { text: getProductsT('decommission.disposed'), value: 'disposed' },
    expired: { text: getProductsT('decommission.expired'), value: 'expired' },
    recalled: { text: getProductsT('decommission.recalled'), value: 'recalled' },
    loggingError: { text: getProductsT('decommission.logging_error'), value: 'logging_error' },
  };

  return [
    {
      renderFormItem: () => <EventDetails />,
    },
    {
      dataIndex: 'isMultipleDates',
      valueType: 'switch',
      renderFormItem: () => <MultipleDates />,
      hideInForm: !isManualEntry,
      colProps: {
        xs: 24,
        md: 24,
      },
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['location'],
      },
      columns: ({ location }: DataItem) => [
        {
          title: t('form_fields.custom_data_title'),
          dataIndex: 'customData',
          fieldProps: {
            placeholder: t('form_fields.custom_data_placeholder'),
          },
          formItemProps: {
            rules: [
              {
                required: false,
                message: t('form_fields.custom_data_req'),
              },
            ],
          },
          renderFormItem: (row, config, form) => (
            <TemplateSelect
              onChange={(value, option) =>
                onChangeTemplate?.(form, option, value, location, 'decommission')
              }
              onClear={() => {
                setDataEntryMethod('manual');
              }}
            />
          ),
          colProps,
        },
      ],
    },

    {
      valueType: 'dependency',
      fieldProps: {
        name: ['date', 'customData'],
      },
      columns: ({ date = undefined }: DataItem) => [
        {
          title: t('form_fields.data_entry_title'),
          dataIndex: 'dataEntryMethod',
          renderFormItem: () => <DataEntryMethod />,
          colProps: {
            xs: 24,
            md: 18,
          },
        },
        {
          title: t('form_fields.date_title'),
          valueType: 'date',
          dataIndex: 'date',
          initialValue: null,
          hideInForm: multipleDates || !isManualEntry,
          fieldProps: (form) => ({
            placeholder: t('form_fields.date_placeholder'),
            className: 'full-width',
            disabledDate,
            onChange: (value: string) => onChangeDate?.(form, value),
          }),
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.date_req'),
              },
            ],
          },
          colProps,
        },
        {
          title: t('form_fields.time_title'),
          valueType: 'time',
          dataIndex: 'time',
          className: 'timecol',
          initialValue: '12:00:00',
          hideInForm: multipleDates || !isManualEntry,
          fieldProps: {
            placeholder: t('form_fields.time_placeholder'),
            className: 'full-width',

            // check is same day as date
            disabledTime:
              moment(date).isValid() &&
              moment(lastEventDate).isValid() &&
              moment(new Date(String(lastEventDate))).isSame(date, 'day')
                ? disabledTime
                : undefined,
          },

          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_req'),
              },
              ...(timeValidation?.(date) || []),
            ],
          },
          colProps,
        },
      ],
    },
    {
      title: t('form_fields.type_title'),
      valueType: 'select',
      dataIndex: 'decommissionType',
      valueEnum: decommissionTypes,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.type_placeholder'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.type_placeholder'),
      },
      hideInForm: !isManualEntry,
      colProps,
    },
    {
      title: t('form_fields.description_title'),
      valueType: 'textarea',
      dataIndex: 'description',
      valueEnum: decommissionTypes,
      fieldProps: {
        rows: 1,
        placeholder: t('form_fields.description_placeholder'),
        autoSize: { minRows: 1, maxRows: 6 },
      },
      hideInForm: !isManualEntry,
      colProps,
    },
    {
      title: t('form_fields.time_zone_title'),
      dataIndex: 'timeZone',
      valueType: 'select',
      initialValue: defaultTimezone,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.time_zone_req'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.time_zone_placeholder'),
      },
      renderFormItem: () => <TimezoneSelect />,
      colProps: !hasAdvancedSettings ? colPropsNull : colProps,
    },
    {
      title: t('form_fields.bizstep_title'),
      dataIndex: 'bizStep',
      initialValue: 'urn:epcglobal:cbv:bizstep:decomissioning',
      valueType: 'select',
      renderFormItem: () => <BizStepSelect />,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.bizstep_req'),
          },
        ],
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.bizstep_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps: !hasAdvancedSettings ? colPropsNull : colProps,
    },
    {
      title: t('form_fields.disposition_title'),
      dataIndex: 'disposition',
      initialValue: 'urn:epcglobal:cbv:disp:inactive',
      valueType: 'select',
      renderFormItem: () => <DispositionSelect />,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.disposition_req'),
          },
        ],
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.disposition_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps: !hasAdvancedSettings ? colPropsNull : colProps,
    },
    {
      dataIndex: 'eventProducts',
      hideInForm: !isManualEntry,
      initialValue: [],
      renderFormItem: (_schema, _config, form) => (
        <DecommissionProducts
          form={form}
          isSerial={isSerial}
          identifier={identifier}
          selectedInstances={selectedInstances}
          validationMessage={validationMessage}
        />
      ),
      colProps: {
        xs: 24,
        md: 24,
      },
    },
    {
      valueType: 'divider',
    },
    {
      dataIndex: 'csvData',
      renderFormItem: (row, record, form) => (
        <DataTemplate form={form} editableTableFormRef={editableTableFormRef} />
      ),
      colProps: colPropsFullCsv,
    },
    {
      valueType: 'formSet',
      dataIndex: 'customProperties',
      initialValue: [],
      columns: getTemplateFields(template),
      rowProps,
      hideInForm: !template,
    },
    {
      dataIndex: 'certificationList',
      rowProps,
      renderFormItem: (row, record, form) => <CertDocuments form={form} />,
      hideInForm:
        !template || !template?.templateAttributes || !template?.allowCertificationDocuments,
    },
    {
      valueType: 'divider',
    },
    {
      dataIndex: 'documents',
      initialValue: [],
      renderFormItem: (_row, _config, form) => <Documents form={form} />,
      colProps: colPropsFull,
    },
  ];
};

export default DecommissionFormFields;

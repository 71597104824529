import { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps } from 'antd';
import { DocumentSelect } from 'components/GSelect';
import TradePartnerSelect from 'components/GSelect/TradePartnerSelect';
import { TradePartnerResponse } from 'services/api/client/src';
import { disableExistingPartners } from 'utils';
import AccessList from './AccessList';
import { DataItem, ShareDocumentFormProps } from './typings';

const colProps18: ColProps = { xs: 24, sm: 14, md: 14, lg: 18 };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps6: ColProps = { xs: 24, sm: 6, md: 6, lg: 6 };
const ShareDocumentFormFields = ({
  t,
  document,
  documents,
  partners,
  setPartners,
  etradePartners,
}: ShareDocumentFormProps): ProFormColumnsType<DataItem>[] => [
  {
    dataIndex: 'documents',
    title:
      documents && documents.length > 0 && partners && partners.length === 0
        ? t('form_fields.documents_title')
        : undefined,
    initialValue: documents,
    renderFormItem: () => <DocumentSelect />,
    fieldProps: {
      mode: 'multiple',
      placeholder: t('form_fields.documents_placeholder'),
    },
    hideInForm: !(documents && documents.length > 0),
  },
  {
    title:
      documents && documents.length > 0 && partners && partners.length === 0
        ? t('form_fields.company_title')
        : undefined,
    dataIndex: 'tradePartners',
    initialValue: [],
    renderFormItem: (row, config, form) => (
      <TradePartnerSelect
        valueKey="wholechainId"
        initParams={{ types: ['Wholechain'] }}
        onChange={(value: DataItem['tradePartners'], option) => {
          // @ts-ignore
          const options: TradePartnerResponse[] = option?.map((i) => i?.itemProps, []);
          const ids = options?.map((item) => item?.wholechainId || '', []) || [];
          setPartners?.(ids);
          form?.setFieldsValue({ tradePartners: value });
        }}
        disabledItem={(item) => disableExistingPartners(item, etradePartners)}
      />
    ),
    fieldProps: {
      mode: 'multiple',
      placeholder: t('form_fields.company_placeholder'),
    },
    formItemProps: {
      className: (partners?.length || 0) === 0 ? 'no-marginb-divider' : undefined,
    },
    colProps: (partners?.length || 0) > 0 ? colProps18 : colProps24,
  },
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['tradePartners'],
    },
    columns: ({ tradePartners }: DataItem) => [
      {
        dataIndex: 'permission',
        valueType: 'select',
        initialValue: 'Viewer',
        fieldProps: {
          allowClear: false,
        },
        valueEnum: {
          Viewer: {
            text: t('viewer'),
            status: 'Default',
          },
        },
        hideInForm: tradePartners?.length === 0,
        colProps: colProps6,
      },
      {
        dataIndex: 'message',
        valueType: 'textarea',
        hideInForm: tradePartners?.length === 0,
        fieldProps: {
          placeholder: t('form_fields.message_placeholder'),
          rows: 6,
        },
      },
      {
        dataIndex: 'access',
        hideInForm: tradePartners?.length !== 0 || (documents?.length || 0) > 0,
        renderFormItem: () => <AccessList document={document} t={t} />,
      },
    ],
  },
];
export default ShareDocumentFormFields;

import G6 from '@antv/g6';
import { createReduceNodes, getNodeMethods } from 'pages/Products';
import { FC, useCallback, useEffect, useRef } from 'react';
import { globalStyles } from 'utils';
import createComplexNodes from './complexNodes';
import createEdges from './edges';
import { DiagramProps } from './typings';

const NODE_TYPE = 'modelRect';
const EDGE = 'cubic-horizontal';

const Diagram: FC<DiagramProps> = ({ mainGraph, setMainGraph, onNodeClick, graphData }) => {
  const containerRef = useRef<any>();
  const clearContainer = () => {
    const containerElement = document.getElementById('container');
    if (containerElement) {
      containerElement.innerHTML = '';
    }
  };
  const initializeGraph = useCallback(() => {
    const SCROLL_WIDTH = containerRef.current?.scrollWidth;
    // set viewport height to the height of the container
    const SCROLL_HEIGHT = window.innerHeight - 250;
    // const minimap = new G6.Minimap({
    //   size: [SCROLL_WIDTH, 150],
    // });

    const graph = new G6.Graph({
      container: document.getElementById('container') || '',
      width: SCROLL_WIDTH - 50,
      height: SCROLL_HEIGHT || 550,
      minZoom: 0.5,
      maxZoom: 3,
      // zoom: 0.2,
      fitCenter: true,
      fitView: true,
      // plugins: [minimap],
      modes: {
        default: [
          {
            type: 'collapse-expand',
            onChange: (item, collapsed) => {
              const data = item?.get?.('model');
              data.collapsed = collapsed;

              return true;
            },
            shouldBegin: (e) => {
              // only expand if there exist a expand property
              if (e?.item && e?.item?.getModel?.()?.expand) {
                return true;
              }
              return false;
            },
          },
          'drag-canvas',
          'zoom-canvas',
          {
            type: 'tooltip', // Tooltip
            formatText(model) {
              let text = '';
              if (model.expand) {
                text = 'Expand all related events';
              } else {
                // The content of tooltip
                text = 'Double-click to see the event details';
              }
              return text;
            },
          },
        ],
      },
      defaultNode: {
        type: NODE_TYPE,
        anchorPoints: [
          [0, 0.5],
          [0.9, 0.5],
        ],
      },
      defaultEdge: {
        type: EDGE,
        style: {
          stroke: '#A3B1BF',
        },
      },
      layout: {
        type: 'dagre',
        rankdir: 'LR',
        nodesepFunc: () => 20,
        ranksepFunc: () => 180,
      },
    });
    graph.on('itemcollapsed', (e: any) => {
      // eslint-disable-next-line no-underscore-dangle
      const item = e.item._cfg.model;
      item.name = e.collapsed ? 'Expand' : 'Collapse';

      graph.render();
    });
    graph.on('node:dblclick', (evt: any) => {
      onNodeClick?.(evt?.item);
    });
    setMainGraph?.(graph);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMainGraph]);

  useEffect(() => () => clearContainer(), []);

  useEffect(() => {
    clearContainer();
    initializeGraph();
  }, [initializeGraph]);

  // Graph Data
  useEffect(() => {
    if (mainGraph) {
      mainGraph.data(graphData);

      mainGraph.render();
    }
  }, [mainGraph, graphData]);

  const nodeBasicMethod = getNodeMethods(mainGraph);

  createReduceNodes(nodeBasicMethod);

  /* Complex node */
  createComplexNodes(nodeBasicMethod);

  createEdges(mainGraph);

  return <div style={globalStyles.gridcenter} ref={containerRef} id="container" />;
};
export default Diagram;

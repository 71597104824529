import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  CreateAttributeRequest,
  CreateTemplateAttributeResponse,
  EventTemplatesResponse,
  ListAttributesOptionalParams,
  ListAttributesResponse,
  UpdateTemplateAttributeRequest,
  UpdateTemplateAttributeResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

// get event templates
const getTemplateAttributes = (
  filters?: ListAttributesOptionalParams,
): Promise<ListAttributesResponse> => HttpClient.listAttributes(filters);

export const useTemplateAttributes = (filters?: ListAttributesOptionalParams) =>
  useQuery(['templateAttributes', filters], () => getTemplateAttributes(filters));

// Add Template Attribute
const createTemplateAttribute = (
  templateAttribute: CreateAttributeRequest,
): Promise<CreateTemplateAttributeResponse> =>
  HttpClient.createTemplateAttribute({
    body: templateAttribute,
  });

export const useCreateTemplateAttribute = (queryClient: QueryClient) =>
  useMutation(
    (templateAttribute: CreateAttributeRequest) => createTemplateAttribute(templateAttribute),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('templateAttributes');
        queryClient.invalidateQueries(['attributes']);
      },
    },
  );

// Delete Template attribute
const deleteTemplateAttribute = async (
  templateAttributeId: string,
  templates?: Array<EventTemplatesResponse>,
): Promise<{
  delAttributeRes: void;
  templatesRes: Array<EventTemplatesResponse>;
}> => {
  const delAttributeRes = await HttpClient.deleteTemplateAttribute(templateAttributeId);

  /* now update template fields since eventtemplates serialised on backend */
  const updateTemplatePromises =
    templates?.map((template) => {
      const templateFields = template?.templateAttributes?.filter(
        (field) => field?.attributeId !== templateAttributeId,
      );
      return HttpClient.updateEventTemplate(template?.id || '', {
        body: {
          ...template,
          bizStepId: template?.bizStepId || '',
          dispositionId: template?.dispositionId || '',
          templateFields: templateFields?.map((item) => ({
            id: item?.attributeId,
            allowDelete: item.attribute?.allowDelete,
            name: item.attribute?.name,
            fieldProperties: item.attribute?.fieldProperties,
          })),
        },
      });
    }) || [];
  const templatesRes = await Promise.all(updateTemplatePromises);
  return { delAttributeRes, templatesRes };
};

export const useDeleteTemplateAttribute = (queryClient: QueryClient) =>
  useMutation(
    ({
      templateAttributeId,
      templates,
    }: {
      templateAttributeId: string;
      templates?: Array<EventTemplatesResponse>;
    }) => deleteTemplateAttribute(templateAttributeId, templates),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('templateAttributes');
        queryClient.invalidateQueries(['attributes']);
        /* now update template fields since eventtemplates serialised on backend */
        queryClient.invalidateQueries('templates');
        data?.templatesRes?.forEach((template) => {
          queryClient.invalidateQueries(['template', template?.id]);
        });
      },
    },
  );

// Update Template Attribute
const updateTemplateAttribute = async (
  id: string,
  body: UpdateTemplateAttributeRequest,
): Promise<UpdateTemplateAttributeResponse> => HttpClient.updateTemplateAttribute(id, { body });

export const useUpdateTemplateAttributeById = (queryClient: QueryClient) =>
  useMutation(
    ({ id, body }: { id: string; body: UpdateTemplateAttributeRequest }) =>
      updateTemplateAttribute(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('templateAttributes');
        queryClient.invalidateQueries(['attributes']);
      },
    },
  );

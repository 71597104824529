import { ProColumns } from '@ant-design/pro-table';
import { Button, Col, Form, Modal, Row, Space, Typography } from 'antd';
import classNames from 'classnames';
import { SingleColumnTags } from 'components';
import GTable from 'components/GTable';
import useTableRowSelection from 'hooks/useTableRowSelection';
import { useDocumentFilters, useDocumentsStore } from 'pages/Documents';
import { DataItem } from 'pages/Documents/Forms/typings';
import SharedTag from 'pages/Documents/components';
import { DocumentItem } from 'pages/Documents/typings';
import { FilterArrayProp } from 'pages/Network/typings';
import pluralize from 'pluralize';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { generateDocTags, getDocTypeFilters } from 'utils';
import styles from './index.module.less';
import { AddExistingDocumentsProps } from './typings';

type ColumnsType = {
  searchfilter: ProColumns;
  coreCompanyFilter: Array<FilterArrayProp>;
  tradePartnersFilter: Array<FilterArrayProp>;
  locationsFilter: Array<FilterArrayProp>;
  productsFilter: Array<FilterArrayProp>;
};
const Columns = ({
  searchfilter,
  coreCompanyFilter,
  tradePartnersFilter,
  locationsFilter,
  productsFilter,
}: ColumnsType): ProColumns<DocumentItem>[] => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });
  return [
    {
      title: t('columns.document_name'),
      dataIndex: 'title',
      fixed: 'left',
      ellipsis: true,
      render: (text, record) => (
        <Link
          className="text-link"
          to={{
            pathname: `/documents/${String(record?.id || '1')}`,
          }}
        >
          {text}
        </Link>
      ),
      ...searchfilter,
    },
    {
      title: t('columns.document_type.title'),
      dataIndex: 'documentType',
      ellipsis: true,
      filters: getDocTypeFilters(),
      width: '8%',
    },
    {
      title: t('columns.company'),
      dataIndex: 'tradepartners',
      filters: tradePartnersFilter,
      filterSearch: true,

      ellipsis: true,
      render: (text, record) => (
        <SingleColumnTags
          values={
            record?.associations
              ?.filter((item) => item?.type === 'Company')
              ?.map((item) => item?.label || '') || []
          }
          columnTitle={pluralize(t('columns.company'), 2)}
          modalTitle={record?.title || ''}
          colTableTitle={t('columns.company') || ''}
        />
      ),
      width: 200,
    },
    {
      title: t('columns.location'),
      dataIndex: 'locations',
      filters: locationsFilter,
      filterSearch: true,
      ellipsis: true,
      render: (text, record) => (
        <SingleColumnTags
          values={
            record?.associations
              ?.filter((item) => item?.type === 'Location')
              ?.map((item) => item?.label || '') || []
          }
          columnTitle={pluralize(t('columns.location'), 2)}
          modalTitle={record?.title || ''}
          colTableTitle={t('columns.location') || ''}
        />
      ),
      width: 300,
    },
    {
      title: t('columns.product'),
      dataIndex: 'products',
      filters: productsFilter,
      filterSearch: true,
      ellipsis: true,
      render: (text, record) => (
        <SingleColumnTags
          values={
            record?.associations
              ?.filter((item) => item?.type === 'Product')
              ?.map((item) => item?.label || '') || []
          }
          columnTitle={pluralize(t('columns.product'), 2)}
          modalTitle={record?.title || ''}
          colTableTitle={t('columns.product') || ''}
        />
      ),
      width: 200,
    },
    {
      title: t('columns.shared.title'),
      dataIndex: 'shared',
      render: (text, record) => <SharedTag sharedWith={record.sharedWith} />,
      filters: coreCompanyFilter,
      filterSearch: true,
      width: '8%',
      ellipsis: true,
    },
    {
      title: t('columns.recent'),
      dataIndex: 'recentActivity',
      ellipsis: true,
      width: '8%',
    },
    {
      title: t('columns.expiration'),
      dataIndex: 'expirationDate',
      ellipsis: true,
      width: '8%',
    },
    {
      title: t('columns.blockchain.title'),
      dataIndex: 'blockchain',
      ellipsis: true,
      filters: [
        {
          text: t('columns.blockchain.filters.algorand'),
          value: 'Algorand',
        },
        {
          text: t('columns.blockchain.filters.none'),
          value: 'None',
        },
      ],
      width: '8%',
    },
    {
      title: t('columns.visibility.title'),
      dataIndex: 'privacy',
      render: (text, record) =>
        t(`columns.visibility.filters.${String(record?.privacy)?.toLowerCase()}`),
      width: '8%',
      ellipsis: true,
      filters: [
        {
          text: t('columns.visibility.filters.private'),
          value: 'Private',
        },
        {
          text: t('columns.visibility.filters.public'),
          value: 'Public',
        },
      ],
    },
    {
      title: t('columns.url'),
      dataIndex: 'url',
      width: '8%',
      ellipsis: true,
      copyable: true,
    },
  ];
};

const AddExistingDocuments: FC<AddExistingDocumentsProps> = ({
  form,
  modal,
  onAddDocument,
  isWorkflow,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'documents' });
  const { setFieldsValue } = form;
  const { setEventTagAlert } = useDocumentsStore();
  const { selectedRows, selectedRowKeys, onRowSelectionChange } = useTableRowSelection();
  const documents: Array<DataItem> = Form?.useWatch('documents', form) || [];
  const setDocuments = useCallback(
    (data: Array<DataItem>) => {
      setFieldsValue({ documents: data });
    },
    [setFieldsValue],
  );
  const closeModal = useCallback(() => {
    onRowSelectionChange([], []);
    modal.hide();
  }, [modal, onRowSelectionChange]);
  const onSubmit = async () => {
    const newDocuments: Array<DataItem> = [];
    selectedRows.forEach((row) => {
      const existingDocument = documents?.find((item) => item.id === row.id);

      if (!existingDocument) {
        const newDocument: DataItem = {
          id: row.id,
          title: row.title,
          documentType: row.documentType,
          privacy: row.privacy,
          blockchain: row.blockchain,
          expirationDate: row.expirationDate,
          agency: row.agency,
          certificationStandard: row.certificationStandard,
          certificationValue: row.certificationValue,
          identification: row.identification,
          documentURL: row.url,
          documentAttachment: row.url,
          locations: row?.locations || [],
          products: row?.products || [],
          tradePartners: row?.tradePartners || [],
          events: row?.events || [],
          tags: generateDocTags({
            locations: row?.locations || [],
            products: row?.products || [],
            tradePartners: row?.tradePartners || [],
            events: row?.events || [],
          }),
        };
        newDocuments.push(newDocument);
      }
    });
    setDocuments([...documents, ...newDocuments]);
    closeModal();
  };
  const onAddNew = useCallback(() => {
    onAddDocument?.();
    setEventTagAlert?.(true);
    closeModal();
  }, [onAddDocument, closeModal, setEventTagAlert]);
  const {
    params,
    records,
    totalItems,
    isLoading,
    filters: [searchfilter],
    onTableChange,
    coreCompanyFilter,
    tradePartnersFilter,
    locationsFilter,
    productsFilter,
  } = useDocumentFilters();

  return (
    <Modal
      title={t('add_document.add_existing_title')}
      width="80%"
      footer={
        <Row justify="space-between">
          <Col>
            <Button type="link" onClick={onAddNew}>
              {t('add_document.add_new_document')}
            </Button>
          </Col>
          <Col>
            <Space>
              {selectedRowKeys.length > 0 && (
                <Typography.Title level={5} className="selectiontext">
                  {t('add_document.doc_selection_text', {
                    count: selectedRowKeys.length,
                    type: pluralize(t('add_document.document_select_doc'), selectedRowKeys.length),
                  })}
                </Typography.Title>
              )}
              <Button type="primary" shape="round" onClick={onSubmit}>
                {t('add_document.existing_submit_btn')}
              </Button>
            </Space>
          </Col>
        </Row>
      }
      wrapClassName={isWorkflow ? styles.workflowmodal : undefined}
      maskStyle={isWorkflow ? { zIndex: 1001 } : undefined}
      className={isWorkflow ? styles.workflowmodal : undefined}
      {...modal}
      onCancel={closeModal}
    >
      <Typography.Text className={classNames('ant-pro-form-group-title', styles.mrb5)}>
        {t('add_document.existing_document_table')}
      </Typography.Text>
      <GTable<DocumentItem>
        columns={Columns({
          searchfilter,
          coreCompanyFilter,
          tradePartnersFilter,
          locationsFilter,
          productsFilter,
        })}
        value={records}
        enableRowSelection
        loading={isLoading}
        scroll={{ x: 1800, y: '55vh' }}
        pagination={{
          defaultPageSize: params.pageSize,
          total: totalItems,
          showTotal: (total, range) =>
            t('doc_table_pagi', { range0: range[0], range1: range[1], total }),
        }}
        rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange }}
        options={{
          setting: false,
          reload: false,
        }}
        // @ts-ignore
        onTableChange={onTableChange}
        className={styles.existingdoctbl}
      />
    </Modal>
  );
};
export default AddExistingDocuments;

import { ArrowLeftOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Modal, Space, Tooltip, Typography, message } from 'antd';
import React, { FC, ReactElement, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { CreateNetworkInviteRequest } from 'services/api/client/src';
import { useCheckValidWholechainIds, useCreateNetwokInvite } from 'services/api/useTradePartners';
import { getVariableFromEnv } from 'utils/config';
import { DataItem, InviteColumns } from './Invite.fields';
import styles from './index.module.less';
import { ShareDocProps, WCIdsProp } from './typings';

const inviteHelpLink = getVariableFromEnv('INVITE_HELP_LINK');

const InviteForm: FC<ShareDocProps> = ({ modal, onContactUs }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.invite' });
  const [inviteWCIDs, setInviteWCIDs] = useState<Array<WCIdsProp>>([]);
  const modalFormRef = useRef<ProFormInstance>();
  const queryClient = useQueryClient();
  const inviteNetworkUsers = useCreateNetwokInvite(queryClient);
  const checkWCIds = useCheckValidWholechainIds();

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    setInviteWCIDs([]);
    modal.hide();
  }, [modal]);

  const onInvalidWC = () => {
    Modal.warning({
      title: t('invalid_wc_title'),
      content: (
        <div className={styles.paragraph}>
          {t('invalid_wc_content.note')}
          <a
            className={styles.bluelink}
            href="mailto:no-reply@example.com"
            onClick={(e) => {
              if (onContactUs) {
                Modal.destroyAll();
                onContactUs();
              }
              e.preventDefault();
            }}
          >
            {` ${t('invalid_wc_content.contact_us')} `}
          </a>
          {t('invalid_wc_content.last_note')}
        </div>
      ),
      centered: true,
      okCancel: false,
      width: 500,
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
        shape: 'round',
      },
      okText: t('invalid_wc_done'),
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
    });
  };

  const onAccountError = () => {
    Modal.warning({
      title: t('account_warning.title'),
      content: <div className={styles.paragraph}>{t('account_warning.content')}</div>,
      centered: true,
      okCancel: false,
      width: 500,
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
        shape: 'round',
      },
      okText: t('invalid_wc_done'),
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
    });
  };

  const Title: ReactElement = (
    <Space>
      {inviteWCIDs.length === 0 ? null : (
        <ArrowLeftOutlined
          className={styles.arrow}
          onClick={() => {
            setInviteWCIDs([]);
          }}
        />
      )}
      <Typography.Title level={4} className="mr0">
        {t('modal_title')}
      </Typography.Title>
      <Tooltip
        title={
          <div>
            {t('invite_tooltip')}
            <Link
              className={styles.tooltiplink}
              to={inviteHelpLink}
              onClick={(e) => {
                e.preventDefault();
                window.open(inviteHelpLink, '_blank');
              }}
            >
              {` ${t('help_doc')}`}
            </Link>
          </div>
        }
        placement="bottom"
      >
        <InfoCircleOutlined className={styles.tooltipicon} />
      </Tooltip>
    </Space>
  );

  const onFinish = async (formData: DataItem) => {
    let isInvalid = 'valid';
    const connection = modalFormRef?.current?.getFieldValue('connectionType');
    const invalidReq = inviteWCIDs?.map((el) => el?.value) || [];
    const res = await checkWCIds.mutateAsync({ ids: invalidReq });
    if (res) {
      const ids = res?.map((id, index) => {
        if (id?.status === 'rejected') {
          isInvalid = 'invalid';
          return {
            value: inviteWCIDs[index]?.value,
            label: inviteWCIDs[index]?.label,
            message: t('invalid_wc_tooltip'),
          };
        }
        return {
          value: invalidReq[index],
          label: String(id?.value?.name || invalidReq[index]),
          message: '',
        };
      });

      setInviteWCIDs([...ids]);

      if (isInvalid === 'invalid') {
        onInvalidWC();
      } else {
        const compReq: Array<CreateNetworkInviteRequest> =
          inviteWCIDs?.map((el) => ({
            wholechainId: el?.value,
            tradeRelationship: connection || '',
            message: formData?.message || '',
            locationIds: formData.locations,
          })) || [];
        const inviteRes = await inviteNetworkUsers.mutateAsync({
          options: compReq,
        });
        const allIds = inviteRes?.map((id, index) => {
          if (id?.status === 'rejected') {
            return {
              value: ids[index]?.value,
              label: ids[index]?.label,
              message: JSON.parse(id?.reason?.message || '')?.title || 'Invalid',
            };
          }
          return {
            value: '',
            label: '',
            message: '',
          };
        });
        const invalidIds = allIds?.filter((id) => id.value !== '');
        setInviteWCIDs([...invalidIds]);
        if (invalidIds?.length === 0) {
          if (inviteWCIDs.length === 1) {
            message.success(
              `${t('invite_success_company', {
                company: String(ids[0]?.label || inviteWCIDs[0]?.label),
              })}`,
            );
          } else {
            message.success(`${t('invite_success', { count: inviteWCIDs.length })}`);
          }
          closeModal();
        } else {
          onAccountError();
        }
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      // @ts-ignore
      title={Title}
      formRef={modalFormRef}
      columns={InviteColumns({
        t,
        inviteWCIDsCount: inviteWCIDs.length,
        setInviteWCIDs,
        inviteWCIDs,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        width: '40%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('send'),
          resetText: inviteWCIDs.length === 0 ? t('done') : t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          className: inviteWCIDs.length === 0 ? styles.donetext : styles.canceltext,
        },
        render: (_, defaultDoms) => (inviteWCIDs.length === 0 ? defaultDoms[0] : defaultDoms),
      }}
      initialValues={{
        connectionType: 'BUYER',
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default React.memo(InviteForm);

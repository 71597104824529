import { Form, Input, Space, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions } from 'components';
import { LocationSelect } from 'components/GSelect';
import useListLocation from 'hooks/useListLocation';
import { FC, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PreviewDrawerFormHeaderProps } from '../../typings';
import styles from './index.module.less';

type ColumnsType = {
  t: TFunction<'pages', 'templates.preview'>;
};

const events = ({ t }: ColumnsType): Array<ItemType> => [
  {
    key: 'Commission',
    label: t('commission'),
  },
  {
    key: 'Observe',
    label: t('observe'),
  },
  {
    key: 'Decommission',
    label: t('decommission'),
  },
  {
    key: 'Aggregation',
    label: t('aggregation'),
  },
  {
    key: 'Disaggregate',
    label: t('disaggregate'),
  },
  {
    key: 'Transform',
    label: t('transform'),
  },
  {
    key: 'Ship',
    label: t('ship'),
  },
];

const PreviewTemplateDrawerHeader: FC<PreviewDrawerFormHeaderProps> = ({
  form,
  readonly = false,
  event,
  setEvent,
}) => {
  const { templateId } = useParams();
  const { data: dataLocations } = useListLocation();
  const locations = useMemo(() => dataLocations || [], [dataLocations]);
  const { t } = useTranslation('pages', { keyPrefix: 'templates.preview' });

  return (
    <Space size={20}>
      <Actions
        actionsText={
          <Typography.Text className={styles.eventitle}>
            {t(`${event?.toLowerCase()}`)}
          </Typography.Text>
        }
        actionPayload={event}
        items={events({ t })}
        buttonProps={{
          type: 'text',
          className: styles.eventbutton,
          shape: 'default',
        }}
        menuProps={{
          selectable: true,
          selectedKeys: [event],
        }}
        onClick={setEvent}
      />
      {!readonly ? (
        <Form form={form} layout="inline">
          <Form.Item
            name="location"
            className={styles.headerinput}
            rules={[{ required: true, message: t('select_location_msg') }]}
            initialValue={locations?.[0]?.id || 'Bristol Bay, AK, USA'}
          >
            <LocationSelect disabled />
          </Form.Item>
          <Form.Item name="poNumber">
            <Input
              placeholder={t('po_number_placeholder')}
              className={styles.headerinput}
              disabled
            />
          </Form.Item>
        </Form>
      ) : (
        <Typography.Text type="secondary">{templateId}</Typography.Text>
      )}
    </Space>
  );
};
export default PreviewTemplateDrawerHeader;

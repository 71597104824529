import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Form } from 'antd';
import {
  BizStepSelect,
  DispositionSelect,
  GTable,
  LocationSelect,
  ProductLotSelect,
  ProductSelect,
  TimezoneSelect,
  TradePartnerSelect,
} from 'components';
import moment from 'moment';
import { useEventActions } from 'pages/Events/components';
import { useEventsStore } from 'pages/Events/hooks';
import React, { FC, Key, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTemplateById } from 'services/api';
import { InventoryResponse, ProductResponse, TradePartnerResponse } from 'services/api/client/src';
import {
  dateLocalFormat,
  filterAttribute,
  getCSVTemplateFields,
  getDisabledDate,
  getDisabledTime,
  getProductsT,
  getRecordValue,
  hiddencol,
} from 'utils';
import { DataItem as CommissionDataItem } from '../CommissionForm.fields';
import ProductDropdown from '../ProductDropdown';
import styles from '../index.module.less';
import { CommissionTableProps, GetCSVColumnsFn } from '../typings';

const Columns: GetCSVColumnsFn = ({
  t,
  receiveT,
  disabledDate,
  onChangeDate,
  defaultValues,
  templateFields,
  certFields,
  columnWidth,
  pageKey,
  productId,
  isSerial,
  locationId,
  autologReceiveEvent,
  products,
}) => [
  {
    title: t?.('product_title'),
    dataIndex: 'productId',
    fieldProps: {
      placeholder: t?.('product_placeholder'),
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('product_req'),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: !(pageKey === 'transform' || pageKey === 'decommission' || pageKey === 'receive'),
    renderFormItem: (_, { recordKey, record }, form) => {
      const { setFieldsValue } = form;

      return (
        <ProductSelect
          allowLotSerial={record?.productInfo?.productIdentifierType === undefined}
          isSerial={record?.productInfo?.productIdentifierType === 'Serial'}
          size="small"
          prependOption={
            record?.productId === 'external_shipment'
              ? {
                  label: `${record?.productInfo?.name || ''}${
                    record?.productInfo?.shippedFrom ? ` (${record?.productInfo?.shippedFrom})` : ''
                  }`,
                  value: 'external_shipment',
                  itemProps: {
                    id: record?.productId || '',
                    name: record?.productInfo?.name || '',
                  },
                }
              : undefined
          }
          dropdownRender={(menu) => (
            <ProductDropdown menu={menu} product={record?.productInfo} t={receiveT} />
          )}
          onChange={(value, option) => {
            // @ts-ignore
            const item: ProductResponse = option.itemProps;
            setFieldsValue({
              [String(recordKey)]: {
                productId: item?.id,
                name: item?.name || '',
                isAssignedProduct: true,
              },
            });
          }}
        />
      );
    },
  },
  {
    dataIndex: 'parentProductId',
    ...hiddencol,
  },
  {
    title: t?.('primary_id_title', {
      identifier: isSerial ? t('serial') : t('lot'),
    }),
    dataIndex: 'lotID',
    fieldProps: {
      placeholder: t?.('primary_id_placeholder', {
        identifier: isSerial ? t('serial') : t('lot'),
      }),
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('primary_id_req', {
            identifier: isSerial ? t('serial') : t('lot'),
          }),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: pageKey === 'observe' || pageKey === 'decommission' || pageKey === 'ship',
  },
  {
    title: t?.('primary_id_title', {
      identifier: isSerial ? t('serial') : t('lot'),
    }),
    dataIndex: 'lotID',
    fieldProps: {
      placeholder: t?.('primary_id_placeholder', {
        identifier: isSerial ? t('serial') : t('lot'),
      }),
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('primary_id_req', {
            identifier: isSerial ? t('serial') : t('lot'),
          }),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey }, form) => {
      const { setFieldsValue } = form;

      return (
        <ProductLotSelect
          productId={productId}
          initParams={{
            pageSize: 9999,
          }}
          size="small"
          onChange={(value, option) => {
            // @ts-ignore
            const item: InventoryResponse = option.itemProps;
            const instance = item?.productInstance;

            setFieldsValue({
              [String(recordKey)]: {
                quantity: instance?.quantity || 0,
              },
            });
          }}
        />
      );
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: !(pageKey === 'observe' || pageKey === 'ship' || pageKey === 'decommission'),
  },
  {
    title: t?.('quantity_title'),
    dataIndex: 'quantity',
    fieldProps: {
      placeholder: t?.('quantity_placeholder'),
      stringMode: true,
      size: 'small',
      type: 'number',
      className: 'full-width',
    },
    formItemProps: {
      rules: [
        {
          /* quantity should be greater than zero */
          validator: (_, value) => {
            if (!value) {
              return Promise.reject(new Error(t?.('quantity_req')));
            }

            if (value && value > 0) {
              return Promise.resolve();
            }

            return Promise.reject(new Error(t?.('quantity_valid')));
          },
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: isSerial,
  },
  {
    title: t?.('date_title'),
    valueType: 'date',
    dataIndex: 'date',
    fieldProps: (form, { rowKey }) => {
      const lastEventDate = getRecordValue('lastEventDate', products, rowKey);
      const disabledDates =
        pageKey === 'ship' && lastEventDate ? getDisabledDate(lastEventDate) : disabledDate;
      return {
        placeholder: t?.('date_placeholder'),
        size: 'small',
        className: 'full-width',
        disabledDate: disabledDates,
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('date_req'),
        },
        {
          type: 'date',
          message: t?.('date_valid'),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
  },
  {
    dataIndex: 'originLocationId',
    ...hiddencol,
  },
  {
    title: t?.('time_title'),
    valueType: 'time',
    dataIndex: 'time',
    fieldProps: {
      placeholder: t?.('time_placeholder'),
      size: 'small',
      className: 'full-width',
      // disabledTime,
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('time_req'),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
  },
  {
    title: t?.('location_title'),
    dataIndex: 'location',
    fieldProps: {
      placeholder: t?.('location_placeholder'),
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('location_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: () => (
      <LocationSelect
        spaceClassName={styles.loccontainer}
        initParams={{
          tradePartnerStatus: ['Self', 'NonWholechain'],
        }}
        placeholder="Location"
        size="small"
      />
    ),
    ellipsis: true,
    hideInTable: !(pageKey === 'observe'),
    width: columnWidth,
  },
  {
    title: t?.('ship_to_company_title'),
    dataIndex: 'shippingCompany',
    fieldProps: {
      placeholder: t?.('ship_to_company_placeholder'),
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('ship_to_company_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey }, form) => {
      const { setFieldsValue } = form;

      return (
        <TradePartnerSelect
          size="small"
          onChange={(value, option) => {
            // @ts-ignore
            const item: TradePartnerResponse = option?.itemProps;
            const isOwnerAccount = item?.isOwnerAccount || false;
            const isWholechain =
              item && Object.prototype.hasOwnProperty.call(item, 'hasWholechainAccount')
                ? item.hasWholechainAccount
                : true;
            const hasWholechainAccount = !item?.isOwnerAccount ? isWholechain : true;
            const anotherTracebility =
              (!!item?.digitalLinkUrl && item.type === 'NonWholechain') || false;
            const isWholechainAcc = !(isOwnerAccount || !hasWholechainAccount);

            setFieldsValue({
              [String(recordKey)]: {
                shippingCompany: value,
                shippingAddress: undefined,
                isOwnerAccount,
                hasWholechainAccount,
                anotherTracebility,
                ...(isWholechainAcc && { receiveDate: undefined, receiveTime: undefined }),
              },
            });
          }}
        />
      );
    },
    ellipsis: true,
    hideInTable: pageKey !== 'ship',
    width: columnWidth,
  },
  {
    dataIndex: 'isOwnerAccount',
    ...hiddencol,
  },
  {
    title: t?.('ship_to_address_title'),
    dataIndex: 'shippingAddress',
    fieldProps: {
      placeholder: 'Location',
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('ship_to_address_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey }, form) => {
      const { getFieldValue } = form;
      const shippingCompany = getFieldValue(String(recordKey))?.shippingCompany;
      return (
        <LocationSelect
          spaceClassName={styles.loccontainer}
          size="small"
          partnerId={shippingCompany}
          filterItem={(location) => location?.id !== locationId}
        />
      );
    },
    ellipsis: true,
    hideInTable: pageKey !== 'ship',
    width: columnWidth,
  },

  /* receive date and time */
  {
    title: t?.('tbl_col_receive_date_title'),
    dataIndex: 'receiveDate',
    valueType: 'date',
    hideInTable: !autologReceiveEvent,
    fieldProps: (form, { rowKey }) => {
      const isOwnerAccount =
        form?.getFieldValue?.(String(rowKey))?.isOwnerAccount ||
        getRecordValue('isOwnerAccount', products, rowKey);
      const hasWholechainAccount =
        form?.getFieldValue?.(String(rowKey))?.hasWholechainAccount ||
        getRecordValue('hasWholechainAccount', products, rowKey);
      const anotherTracebility =
        form?.getFieldValue?.(String(rowKey))?.anotherTracebility ||
        getRecordValue('anotherTracebility', products, rowKey);

      const disabled = isOwnerAccount ? false : anotherTracebility || hasWholechainAccount;
      const disabledDates = getDisabledDate(
        form?.getFieldValue?.(String(rowKey))?.date || getRecordValue('date', products, rowKey),
      );
      const onChange = (value: string) =>
        onChangeDate?.(
          form,
          value,
          true,
          rowKey,
          form?.getFieldValue?.(String(rowKey))?.date || getRecordValue('date', products, rowKey),
          'receiveTime',
        );

      return {
        size: 'small',
        placeholder: t?.('tbl_col_receive_date_title'),
        onChange,
        disabledDate: disabledDates,
        className: 'full-width',
        disabled,
      };
    },
    formItemProps: (form, { rowKey }) => {
      const isOwnerAccount =
        form?.getFieldValue?.(String(rowKey))?.isOwnerAccount ||
        getRecordValue('isOwnerAccount', products, rowKey);
      const hasWholechainAccount =
        form?.getFieldValue?.(String(rowKey))?.hasWholechainAccount ||
        getRecordValue('hasWholechainAccount', products, rowKey);
      const isInternalAcc = isOwnerAccount || !hasWholechainAccount;

      const required = autologReceiveEvent && isInternalAcc;

      return {
        rules: [
          {
            required,
            message: t?.('tbl_col_receive_date_req'),
          },
        ],
        hasFeedback: false,
      };
    },
    width: columnWidth,
  },

  {
    title: t?.('tbl_col_receive_time_title'),
    dataIndex: 'receiveTime',
    valueType: 'time',
    hideInTable: !autologReceiveEvent,
    fieldProps: (form, { rowKey }) => {
      const date =
        form?.getFieldValue?.(String(rowKey))?.date || getRecordValue('date', products, rowKey);
      const time =
        form?.getFieldValue?.(String(rowKey))?.time || getRecordValue('time', products, rowKey);
      const isDateSelected = !!date;
      const isTimeSelected = !!time;
      // date can be str or moment so we need to convert it to date YYYY-MM-DD
      const shipDate = !(typeof date === 'string' || date instanceof String)
        ? moment(date).format('YYYY-MM-DD')
        : date;
      const shipTime = !(typeof time === 'string' || time instanceof String)
        ? moment(time).format('HH:mm:ss')
        : time;
      const shipDateTime = new Date(`${shipDate} ${shipTime}`);

      const shipDateTimeStr = dateLocalFormat(shipDateTime);

      const isOwnerAccount =
        form?.getFieldValue?.(String(rowKey))?.isOwnerAccount ||
        getRecordValue('isOwnerAccount', products, rowKey);
      const hasWholechainAccount =
        form?.getFieldValue?.(String(rowKey))?.hasWholechainAccount ||
        getRecordValue('hasWholechainAccount', products, rowKey);

      const anotherTracebility =
        form?.getFieldValue?.(String(rowKey))?.anotherTracebility ||
        getRecordValue('anotherTracebility', products, rowKey);

      const disabled = isOwnerAccount ? false : anotherTracebility || hasWholechainAccount;

      return {
        size: 'small',
        placeholder: t?.('tbl_col_receive_time_title'),
        disabledTime:
          isDateSelected && isTimeSelected ? getDisabledTime(shipDateTimeStr) : undefined,
        className: 'full-width',
        disabled,
      };
    },
    formItemProps: (form, { rowKey }) => {
      const isOwnerAccount =
        form?.getFieldValue?.(String(rowKey))?.isOwnerAccount ||
        getRecordValue('isOwnerAccount', products, rowKey);
      const hasWholechainAccount =
        form?.getFieldValue?.(String(rowKey))?.hasWholechainAccount ||
        getRecordValue('hasWholechainAccount', products, rowKey);
      const isInternalAcc = isOwnerAccount || !hasWholechainAccount;

      const required = autologReceiveEvent && isInternalAcc;

      return {
        rules: [
          {
            required,
            message: t?.('tbl_col_receive_time_req'),
          },
        ],
        hasFeedback: false,
      };
    },
    width: columnWidth,
  },
  /* decommission type and description */
  {
    title: t?.('tbl_col_decommission_type_title'),
    valueType: 'select',
    dataIndex: 'decommissionType',
    valueEnum: {
      sold: { text: getProductsT('decommission.sold'), key: 'sold' },
      damaged: { text: getProductsT('decommission.damaged'), key: 'damaged' },
      destroyed: { text: getProductsT('decommission.destroyed'), key: 'destroyed' },
      disposed: { text: getProductsT('decommission.disposed'), key: 'disposed' },
      expired: { text: getProductsT('decommission.expired'), key: 'expired' },
      recalled: { text: getProductsT('decommission.recalled'), key: 'recalled' },
      loggingError: { text: getProductsT('decommission.logging_error'), key: 'logging_error' },
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_decommission_type_req'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('tbl_col_decommission_type_title'),
      size: 'small',
    },
    width: columnWidth,
    hideInTable: pageKey !== 'decommission',
  },
  {
    title: t?.('tbl_col_description_title'),
    valueType: 'textarea',
    dataIndex: 'description',
    fieldProps: {
      rows: 1,
      placeholder: t?.('tbl_col_description_placeholder'),
      autoSize: { minRows: 1, maxRows: 6 },
      size: 'small',
    },
    width: columnWidth,
    hideInTable: pageKey !== 'decommission',
  },
  /* advance fields */
  {
    title: t?.('bizstep_title'),
    dataIndex: 'bizStep',
    valueType: 'select',
    renderFormItem: () => <BizStepSelect size="small" />,
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('bizstep_req'),
        },
        {
          whitespace: true,
          message: t?.('bizstep_req'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('bizstep_placeholder'),
      size: 'small',
    },
    tooltip: {
      icon: <InfoCircleOutlined />,
      title: t?.('bizstep_tooltip'),
      key: null,
      type: '',
      props: null,
    },
    width: columnWidth,
  },
  {
    dataIndex: 'hasWholechainAccount',
    ...hiddencol,
  },
  {
    title: t?.('disposition_title'),
    dataIndex: 'disposition',
    valueType: 'select',
    fieldProps: {
      placeholder: t?.('disposition_title'),
      size: 'small',
    },
    renderFormItem: () => <DispositionSelect size="small" />,
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('disposition_req'),
        },
        {
          whitespace: true,
          message: t?.('disposition_req'),
        },
      ],
    },

    tooltip: {
      icon: <InfoCircleOutlined />,
      title: t?.('disposition_tooltip'),
      key: null,
      type: '',
      props: null,
    },
    width: columnWidth,
  },
  {
    dataIndex: 'anotherTracebility',
    ...hiddencol,
  },
  {
    title: t?.('time_zone_title'),
    dataIndex: 'timeZone',
    valueType: 'select',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('time_zone_req'),
        },
        {
          whitespace: true,
          message: t?.('time_zone_req'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('time_zone_placeholder'),
      size: 'small',
    },
    renderFormItem: () => (
      <TimezoneSelect placeholder={t?.('time_zone_placeholder')} size="small" />
    ),
    width: columnWidth,
  },

  {
    dataIndex: 'lastEventId',
    ...hiddencol,
  },
  /* custom data template fields */
  ...(pageKey === 'commission'
    ? getCSVTemplateFields({ templateFields, defaultValues, certFields })
    : []),
  {
    dataIndex: 'instanceId',
    ...hiddencol,
  },
];

const CommissionTable: FC<CommissionTableProps> = ({
  form,
  editableTableFormRef,
  defaultValues,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.csv' });
  const { t: receiveT } = useTranslation('pages', { keyPrefix: 'events.recieve.form_fields' });
  const { setFieldsValue, getFieldValue } = form;
  const templateId = Form.useWatch('customData', form);
  const title = useEventsStore((state) => state.title);
  const { productId, disabledDate, disabledTime, isSerial, locationId, onChangeDate } =
    useEventActions();
  const pageKey = String(title).toLowerCase();

  const columnWidth = 100;
  const { data: template } = useTemplateById(templateId);
  const totalColumnWidth = useMemo(
    () =>
      pageKey === 'commission'
        ? columnWidth * (template?.templateAttributes?.length || 0) +
          10 * columnWidth * (template?.templateCertifications?.length || 0) +
          20 * columnWidth
        : 20 * columnWidth,
    [pageKey, template?.templateAttributes?.length, template?.templateCertifications?.length],
  );
  const templateFields = useMemo(
    () => template?.templateAttributes?.filter(filterAttribute) || [],
    [template],
  );
  const certFields = useMemo(() => template?.templateCertifications || [], [template]);
  const products: Array<CommissionDataItem> = getFieldValue('csvData');
  // const locationIdForm: string = Form.useWatch('location', form);
  const autologReceiveEvent: boolean = Form.useWatch('autologReceiveEvent', form);

  const sactionRef = useRef<ActionType>();

  useEffect?.(() => {
    if (editableTableFormRef?.current) {
      setTimeout?.(() => {
        editableTableFormRef?.current?.validateFields();
      }, 500);
    }
  }, [products, editableTableFormRef]);

  const setProducts = useCallback(
    (value?: Array<CommissionDataItem>) => {
      setFieldsValue({
        csvData: value,
      });
    },
    [setFieldsValue],
  );

  const onDelRow = useCallback(
    async (key: RecordKey, actionPayload?: CommissionDataItem) => {
      if (actionPayload) {
        setProducts(products?.filter((p) => p?.id !== actionPayload?.id));
      }
    },
    [products, setProducts],
  );

  const editableKeys = useMemo(() => products?.map((p) => p?.id || ('' as Key)), [products]);

  const onValuesChange = useCallback(
    (record: CommissionDataItem, recordList: Array<CommissionDataItem>) => {
      setProducts(recordList);
    },
    [setProducts],
  );

  return (
    <GTable<CommissionDataItem>
      key="sactionRef"
      editableFormRef={editableTableFormRef}
      actionRef={sactionRef}
      columns={Columns({
        t,
        receiveT,
        disabledDate,
        disabledTime,
        onChangeDate,
        defaultValues,
        templateFields,
        certFields,
        columnWidth,
        pageKey,
        productId,
        isSerial,
        locationId,
        products,
        autologReceiveEvent,
      })}
      editable={{
        type: 'multiple',
        onlyAddOneLineAlertMessage: undefined,
        editableKeys,
        onDelete: onDelRow,
        onValuesChange,
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: false,
        cancel: false,
        delete: false,
      }}
      recordCreatorProps={false}
      addBtnText="Add Row"
      onAddRecordClick={() => {
        sactionRef.current?.addEditRecord?.({
          id: Date.now().toString(),
        });
      }}
      value={products}
      scroll={{ y: '50vh', x: totalColumnWidth }}
      pagination={{
        pageSize: 10,
        showSizeChanger: true,
      }}
    />
  );
};
export default React.memo(CommissionTable);

// @ts-nocheck
import { BetaSchemaForm } from '@ant-design/pro-form';
import { Graph } from '@antv/g6';
import { GraphData } from '@antv/g6/lib/types';
import { Button, Form, message, Space } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { SupportModal } from 'components';
import { useActions, useDrawerVisibility, useModalVisibility } from 'hooks';
import moment from 'moment';
import { GetWorkflowTemplateByIdResponse } from 'pages/Templates';
import { WorkflowEvent } from 'pages/Templates/Workflows/typings';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getWorkflowTemplateById,
  useCreateWorkflow,
  useUpdateWorkflow,
  useWorkflowById,
} from 'services/api';
import {
  CreateWorkflowRequest,
  UpdateWorkflowRequest,
  WorkflowAggregateEvent,
  WorkflowCommissionEvent,
  WorkflowDecommissionEvent,
  WorkflowDisaggregateEvent,
  WorkflowEdge,
  WorkflowObserveEvent,
  WorkflowReceiveEvent,
  WorkflowShipEvent,
  WorkflowTemplate,
  WorkflowTransformEvent,
} from 'services/api/client/src';
import { capitalizeFirstLetter, errorHandlerv2, supportEmailLink } from 'utils';
import DrawerFormHeader from './DrawerFormHeader';
import FormFields from './Form.fields';
import { DataItem, EventData, WorkflowEventType } from './typings';

const rowProps = { gutter: 32 };
const structureData = (rootNode: WorkflowEvent) => ({
  id: rootNode?.id,
  eventType: rootNode?.eventType || '',
  name: rootNode?.eventType || 'No event selected',
  eventTemplateId: rootNode?.eventTemplateId,
  eventTemplateName: rootNode?.eventTemplateName || 'No event template selected',
  locationId: rootNode?.locationId,
  locationName: rootNode?.locationName,
  productId: rootNode?.productId,
  productName: rootNode?.productName,
  // colourIndex: 'colour_4',
  // eventData: rootNode,
  children: [],
});
const formatDataInit = (data?: Array<WorkflowEvent>) => {
  /* constructing  tree structure from the data received from the API with first node as root */
  const nodes = data;
  // edges are sequential depending on the order of the nodes
  const edges = nodes?.map((node, index) => ({
    source: node?.id,
    target: nodes[index + 1]?.id,
  }));

  const tree: GraphData = {
    nodes: (nodes?.map(structureData) || []) as GraphData['nodes'],
    edges,
  };
  return tree;
};

const AddForm = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'workflows.create_workflow' });
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const supportModal = useModalVisibility(false);
  const { logEvents, percent } = useActions();
  const [workflowId, setWorkflowId] = useState<string | undefined>();
  const { data: dataWorkflow } = useWorkflowById(workflowId || '');
  const [mainGraph, setMainGraph] = useState<Graph | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [mainForm] = Form.useForm<DataItem>();
  const [headerForm] = Form.useForm(mainForm);
  const queryClient = useQueryClient();
  const createRecord = useCreateWorkflow(queryClient);
  const updateRecord = useUpdateWorkflow(queryClient);
  const initialValues: DataItem = {
    graphData: {
      nodes: [],
      edges: [],
    },
    instances: [],
  };
  const onChangeTemplate = useCallback(
    async (option: DefaultOptionType | DefaultOptionType[]) => {
      try {
        const templateItem = (option as DefaultOptionType)?.itemProps as WorkflowTemplate;
        const templateId = templateItem?.id;
        if (templateId) {
          const template = await queryClient.fetchQuery<GetWorkflowTemplateByIdResponse>(
            ['workflowTemplate', templateId],
            () => getWorkflowTemplateById(templateId),
          );
          const workflowEvents: Array<WorkflowEvent> =
            template?.workflowEvents?.map((event) => ({
              ...event,
              eventType: capitalizeFirstLetter(event?.$type || '') as WorkflowEventType,
              eventTemplateId: event?.eventTemplateId,
              outputProducts: event?.outputProducts?.map((p) => p?.id || ''),
              inputProducts: event?.inputProducts?.map((p) => p?.id || ''),
              locationId: event?.locationId || event?.originId,
              locationName: event?.locationName || event?.originName || '',
              destinationId: event?.destinationId || '',
            })) || [];
          const graphData = formatDataInit(workflowEvents);
          mainForm.setFieldsValue({
            graphData,
          });
        }
      } catch (error) {
        message.error(await errorHandlerv2(error));
      }
    },
    [mainForm, queryClient],
  );

  const onSaveDraft = async (close = true) => {
    try {
      await mainForm.validateFields();
      const formData: DataItem = mainForm.getFieldsValue();
      const nodes: GraphData['nodes'] =
        formData.graphData?.nodes?.map((el, idx) => ({
          ...el,
          eventData: {
            id: el?.id,
            templateId: el?.eventTemplateId || undefined,
            templateName: el?.eventTemplateName,
            ...(el?.eventData as EventData),
            order: idx,
          },
        })) || [];
      const reqBody: CreateWorkflowRequest = {
        name: formData.name,
        deadline: formData?.deadline
          ? new Date(moment(formData.deadline).toISOString())
          : undefined,
        workflowTemplateId: formData.templateId,
        commissionEvents: nodes
          ?.filter((node) => node?.eventType === 'Commission')
          ?.map((n) => n.eventData as WorkflowCommissionEvent),
        decommissionEvents: nodes
          ?.filter((node) => node?.eventType === 'Decommission')
          ?.map((n) => n.eventData as WorkflowDecommissionEvent),
        aggregateEvents: nodes
          ?.filter((node) => node?.eventType === 'Aggregate')
          ?.map((n) => n.eventData as WorkflowAggregateEvent),
        disaggregateEvents: nodes
          ?.filter((node) => node?.eventType === 'Disaggregation')
          ?.map((n) => n.eventData as WorkflowDisaggregateEvent),
        observeEvents: nodes
          ?.filter((node) => node?.eventType === 'Observe')
          ?.map((n) => n.eventData as WorkflowObserveEvent),
        receiveEvents: nodes
          ?.filter((node) => node?.eventType === 'Receive')
          ?.map((n) => n.eventData as WorkflowReceiveEvent),
        shipEvents: nodes
          ?.filter((node) => node?.eventType === 'Ship')
          ?.map((n) => n.eventData as WorkflowShipEvent),
        transformEvents: nodes
          ?.filter((node) => node?.eventType === 'Transform')
          ?.map((n) => n.eventData as WorkflowTransformEvent),
        edges: (formData.graphData?.edges as WorkflowEdge[]) || [],
      };
      const res = await createRecord.mutateAsync(reqBody);
      if (close) {
        closeDrawer();
        setTimeout(() => {
          message.success(t('success_message', { id: res?.name }));
        }, 1000);
      } else {
        setWorkflowId(res?.id);
      }
      return res;
    } catch (error) {
      message.error(await errorHandlerv2(error));
      return undefined;
    }
  };
  const onUpdateDraft = async (
    wId?: string,
    status: UpdateWorkflowRequest['status'] = 'InProgress',
  ) => {
    try {
      await mainForm.validateFields();
      const formData: DataItem = mainForm.getFieldsValue();
      const nodes: GraphData['nodes'] =
        formData.graphData?.nodes?.map((el, idx) => ({
          ...el,
          eventData: {
            id: el?.id,
            templateId: el?.eventTemplateId || undefined,
            templateName: el?.eventTemplateName,
            ...(el?.eventData as EventData),
            order: idx,
          },
        })) || [];

      const reqBody: UpdateWorkflowRequest = {
        id: wId || workflowId,
        status,
        name: formData.name,
        deadline: formData?.deadline
          ? new Date(moment(formData.deadline).toISOString())
          : undefined,
        workflowTemplateId: formData.templateId,
        commissionEvents: nodes
          ?.filter((node) => node?.eventType === 'Commission')
          ?.map((n) => n.eventData as WorkflowCommissionEvent),
        decommissionEvents: nodes
          ?.filter((node) => node?.eventType === 'Decommission')
          ?.map((n) => n.eventData as WorkflowDecommissionEvent),
        aggregateEvents: nodes
          ?.filter((node) => node?.eventType === 'Aggregate')
          ?.map((n) => n.eventData as WorkflowAggregateEvent),
        disaggregateEvents: nodes
          ?.filter((node) => node?.eventType === 'Disaggregation')
          ?.map((n) => n.eventData as WorkflowDisaggregateEvent),
        observeEvents: nodes
          ?.filter((node) => node?.eventType === 'Observe')
          ?.map((n) => n.eventData as WorkflowObserveEvent),
        receiveEvents: nodes
          ?.filter((node) => node?.eventType === 'Receive')
          ?.map((n) => n.eventData as WorkflowReceiveEvent),
        shipEvents: nodes
          ?.filter((node) => node?.eventType === 'Ship')
          ?.map((n) => n.eventData as WorkflowShipEvent),
        transformEvents: nodes
          ?.filter((node) => node?.eventType === 'Transform')
          ?.map((n) => n.eventData as WorkflowTransformEvent),
        edges: (formData.graphData?.edges as WorkflowEdge[]) || [],
      };
      await updateRecord.mutateAsync({
        id: wId || workflowId || '',
        body: reqBody,
      });
    } catch (error) {
      message.error(await errorHandlerv2(error));
    }
  };

  const onFinish = async (formData: DataItem) => {
    let wId: string | undefined;
    try {
      setIsSubmitting(true);
      if (!workflowId) {
        const res = await onSaveDraft(false);
        wId = res?.id;
      } else {
        await onUpdateDraft();
      }

      await logEvents(mainGraph as Graph, mainForm, formData);
      setIsSubmitting(false);
      await onUpdateDraft(wId, 'Completed');
      closeDrawer();

      setTimeout(() => {
        message.success(t('log_success_message', { id: formData?.name }));
      }, 1000);
    } catch (error) {
      setIsSubmitting(false);
      await onUpdateDraft(wId);
      supportModal.show();
      message.error(await errorHandlerv2(error));
    }
  };

  return (
    <>
      <BetaSchemaForm<DataItem>
        form={mainForm}
        layoutType="DrawerForm"
        columns={FormFields({ t, mainGraph, setMainGraph, isSubmitting, percent })}
        grid
        initialValues={initialValues}
        // @ts-ignore
        title={
          <DrawerFormHeader
            title={t('title')}
            form={headerForm}
            onChangeTemplate={onChangeTemplate}
          />
        }
        visible={visible}
        rowProps={rowProps}
        autoFocusFirstInput
        drawerProps={{
          destroyOnClose: true,
          height: '100%',
          placement: 'top',
          size: 'large',
          ...restDrawerVisibility,
        }}
        submitter={{
          searchConfig: {
            submitText: t('log_event_title'),
          },
          submitButtonProps: {
            shape: 'round',
            disabled: dataWorkflow?.status === 'Completed' || createRecord.isLoading,
          },
          render: (_, defaultDoms) => (
            <Space>
              <Button
                type="primary"
                shape="round"
                ghost
                onClick={() => onSaveDraft()}
                loading={createRecord.isLoading}
                disabled={isSubmitting}
              >
                {t('save_draft_title')}
              </Button>
              {defaultDoms[1]}
            </Space>
          ),
        }}
        submitTimeout={2000}
        onFinish={onFinish}
      />
      <SupportModal
        modal={supportModal}
        title={t('logging_error_title')}
        description={
          <div>
            {t('logging_error_desc')}
            &nbsp;
            <a href={supportEmailLink} type="mail">
              {t('support_anchor_email_title')}
            </a>
            .
          </div>
        }
      />
    </>
  );
};
export default AddForm;

import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import {
  GetPaginatedInboundShipmentsOptionalParams,
  GetPaginatedOtherSystemShipmentsOptionalParams,
  GetPaginatedShipmentsOptionalParams,
  GetShipmentsSummaryOptionalParams,
  GetShipmentsSummaryResponse,
  ListExternalShipmentsOperationResponse,
  ListExternalShipmentsOptionalParams,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

/* reject shipment by id */
// export const rejectShipmentById = (body: RejectShipmentRequest) =>
//   HttpClient.rejectShipment({ body });

/* get shipments */
export const getShipments = (params?: GetPaginatedShipmentsOptionalParams) =>
  HttpClient.getPaginatedShipments(params);

export const getInboundWholechainShipments = (
  params?: GetPaginatedInboundShipmentsOptionalParams,
) => HttpClient.getPaginatedInboundShipments(params);

export const getInboundOtherSystemsShipments = (
  params?: GetPaginatedOtherSystemShipmentsOptionalParams,
) => HttpClient.getPaginatedOtherSystemShipments(params);
/* get shipments summary */
const getShipmentsSummary = (
  params?: GetShipmentsSummaryOptionalParams,
): Promise<GetShipmentsSummaryResponse> => HttpClient.getShipmentsSummary(params);

/* use reject shipment by id */
// export const useRejectShipmentById = (queryClient: QueryClient) =>
//   useMutation((body: RejectShipmentRequest) => rejectShipmentById(body), {
//     onSuccess: () => {
//       queryClient.invalidateQueries('shipments');
//     },
//   });

export const useShipments = (params?: GetPaginatedShipmentsOptionalParams) =>
  useQuery(['shipments', params], () => getShipments(params));

export const useInfiniteShipments = (params?: GetPaginatedShipmentsOptionalParams) =>
  useInfiniteQuery(
    ['infiniteShipments', params?.pageSize, params?.direction, params?.status],
    ({ pageParam = 1 }) => getShipments({ ...params, pageNumber: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        // if there is no next page, return undefined
        if ((lastPage?.pageNumber || 0) < (lastPage?.totalPages || 0)) {
          const nextPage = (lastPage?.pageNumber || 1) + 1;
          return nextPage;
        }
        return undefined;
      },
    },
  );

export const useInfiniteWholeChainShipments = (params?: GetPaginatedShipmentsOptionalParams) =>
  useInfiniteQuery(
    ['infiniteWholeChainShipments', params?.pageSize, params?.direction, params?.status],
    ({ pageParam = 1 }) =>
      getInboundWholechainShipments({
        ...params,
        pageNumber: pageParam,
        inboundShipmentType: 'Wholechain',
      }),
    {
      getNextPageParam: (lastPage) => {
        // if there is no next page, return undefined
        if ((lastPage?.pageNumber || 0) < (lastPage?.totalPages || 0)) {
          const nextPage = (lastPage?.pageNumber || 1) + 1;
          return nextPage;
        }
        return undefined;
      },
    },
  );

export const useInfiniteOtherSystemsShipments = (params?: GetPaginatedShipmentsOptionalParams) =>
  useInfiniteQuery(
    ['infiniteOtherSystemsShipments', params?.pageSize, params?.direction, params?.status],
    ({ pageParam = 1 }) => getInboundOtherSystemsShipments({ ...params, pageNumber: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        // if there is no next page, return undefined
        if ((lastPage?.pageNumber || 0) < (lastPage?.totalPages || 0)) {
          const nextPage = (lastPage?.pageNumber || 1) + 1;
          return nextPage;
        }
        return undefined;
      },
    },
  );

export const useShipmentsSummary = (params?: GetShipmentsSummaryOptionalParams) =>
  useQuery(['shipments_summary', params], () => getShipmentsSummary(params));

export const listExternalShipments = (
  options?: ListExternalShipmentsOptionalParams,
): Promise<ListExternalShipmentsOperationResponse> => HttpClient.listExternalShipments(options);

export const useExternalShipments = (params?: ListExternalShipmentsOptionalParams) =>
  useQuery(['externalShipments', params], () => listExternalShipments(params));

export const useListShipMutate = () =>
  useMutation(({ options }: { options: GetPaginatedShipmentsOptionalParams }) =>
    getShipments(options),
  );

export const useExternalListShipMutate = () =>
  useMutation(({ options }: { options: ListExternalShipmentsOptionalParams }) =>
    listExternalShipments(options),
  );

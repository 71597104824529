import Profield from '@ant-design/pro-field';
import { Form, Input, Space, Typography } from 'antd';
import { WorkflowTemplateSelect } from 'components/GSelect';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from 'utils';
import styles from './index.module.less';
import { DrawerFormHeaderProps } from './typings';

const DrawerFormHeader: FC<DrawerFormHeaderProps> = ({
  title,
  form,
  readonly = false,
  onChangeTemplate,
  isEdit,
  name,
  templateName,
  deadline,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'workflows.create_workflow' });
  return (
    <Space size={20}>
      <Typography.Title level={4}>{!isEdit ? title : 'Workflow'}</Typography.Title>
      {!readonly ? (
        <Form form={form} layout="inline">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t('form_fields.name_required'),
              },
            ]}
          >
            <Input
              placeholder={t('form_fields.name_placeholder')}
              className={styles.templatename}
            />
          </Form.Item>
          <Form.Item
            name="templateId"
            rules={[
              {
                required: true,
                message: t('form_fields.template_name_required'),
              },
            ]}
          >
            <WorkflowTemplateSelect
              placeholder={t('form_fields.template_name_placeholder')}
              className={styles.templatename}
              onChange={(value, option) => onChangeTemplate?.(option)}
            />
          </Form.Item>
          <Form.Item name="deadline">
            <Profield
              mode="edit"
              valueType="date"
              fieldProps={{
                placeholder: t('form_fields.deadline_date_placeholder'),
                className: styles.templatename,
              }}
            />
          </Form.Item>
        </Form>
      ) : (
        <Typography.Text type="secondary">{` ${name || ''} ${templateName || ''} ${
          deadline ? `Deadline : ${dateFormat(deadline)}` : ''
        }`}</Typography.Text>
      )}
    </Space>
  );
};
export default DrawerFormHeader;

import { Col, Input, Row, Tabs } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SearchList from './components/SearchList';
import styles from './index.module.less';

const colProps1 = { xs: 24, sm: 24, md: 16, lg: 16 };

const Search: FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'global_search' });
  const { purchaseOrder = '' } = useParams();
  const [searchPOText, setSearchPOText] = useState<string>(purchaseOrder);
  const navigate = useNavigate();

  useEffect(() => {
    setSearchPOText(purchaseOrder);
  }, [purchaseOrder]);

  const onSearchClick = useCallback(
    (value: string) => {
      navigate(`../../../search/${value}`);
    },
    [navigate],
  );

  const onSearchChange = useCallback((e: any) => {
    setSearchPOText(e?.target?.value);
  }, []);

  return (
    <Col>
      <Row justify="center">
        <Col {...colProps1}>
          <Input.Search
            size="middle"
            value={searchPOText}
            onChange={onSearchChange}
            onSearch={onSearchClick}
          />
        </Col>
      </Row>
      <Row justify="center" className={styles.margin20}>
        <Col {...colProps1}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={t('purchase_order')} key="1">
              <SearchList purchaseOrder={purchaseOrder} />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Col>
  );
};

export default Search;

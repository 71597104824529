import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { RowProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import {
  BizStepSelect,
  DispositionSelect,
  ProductLotSelect,
  ProductSelect,
  TemplateSelect,
  TimezoneSelect,
} from 'components/GSelect';
import moment from 'moment';
import { DataItem as DocumentItem } from 'pages/Documents/Forms/typings';
import {
  InventoryResponse,
  ProductResponse,
  TemplateCertificationResponse,
} from 'services/api/client/src';
import { getProductsT, getTemplateFields } from 'utils';
import { CertDocuments, DataTemplate, Documents, EventDetails } from '../Sections';
import DataEntryMethod from './DataEntryMethod';
import { CommissionFormFieldsProps } from './typings';

const rowProps: RowProps = { gutter: 32 };
const colProps = { xs: 24, sm: 12, md: 12, lg: 6 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsFullCsv = { xs: 24, sm: 24, md: 24, lg: 24 };
// const rowProps: RowProps = { gutter: [32, 24] };
export type DataItem = {
  id?: string;
  advancedField?: string;
  advancedField2?: string;
  advancedField3?: string;
  customData?: string;
  date?: string;
  location?: string;
  lotID?: string;
  sscc?: string;
  poNumber?: string;
  product?: string;
  quantity?: number;
  unitOfMeasure?: string;
  time?: string;
  timeZone?: string;
  bizStep?: string;
  disposition?: string;
  documents?: Array<DocumentItem>;
  csvData?: Array<DataItem>;
  customProperties?: Array<string>;
  certificationList?: Array<TemplateCertificationResponse>;
  lastEventDate?: Date;
  [key: string]: any;
};

export const CommissionFormFields = ({
  hasAdvancedSettings,
  dataEntryMethod,
  isObserve = false,
  editableTableFormRef,
  container,
  lastEventDate,
  lotId,
  productId,
  template,
  defaultTimezone,
  isSerial,
  identifier,
  timeValidation,
  disabledDate,
  disabledTime,
  onChangeDate,
  onChangeTemplate,
  t,
}: CommissionFormFieldsProps): ProFormColumnsType<DataItem>[] => {
  const isManualEntry = dataEntryMethod === 'manual';

  return [
    {
      renderFormItem: () => <EventDetails />,
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['location'],
      },
      columns: ({ location }: DataItem) => [
        {
          title: t('form_fields.custom_data_title'),
          dataIndex: 'customData',
          formItemProps: {
            rules: [
              {
                required: false,
                message: t('form_fields.custom_data_req'),
              },
            ],
          },
          fieldProps: {
            placeholder: t('form_fields.custom_data_placeholder'),
          },
          renderFormItem: (row, config, form) => (
            <TemplateSelect
              onChange={(value, option) => onChangeTemplate?.(form, option, value, location)}
            />
          ),
          colProps,
        },
      ],
    },

    {
      title: t('form_fields.data_entry_title'),
      dataIndex: 'dataEntryMethod',
      renderFormItem: () => <DataEntryMethod />,
      colProps: {
        xs: 24,
        md: 18,
      },
    },
    {
      title: t('form_fields.product_title'),
      dataIndex: 'product',
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.product_req'),
          },
        ],
        initialValue: productId,
      },
      renderFormItem: (_row, record, form) => {
        const { setFieldsValue } = form;

        return (
          <ProductSelect
            isSerial={isSerial}
            onChange={(value, option) => {
              const product = (option as DefaultOptionType)?.itemProps as ProductResponse;
              setFieldsValue({
                lotID: undefined,
                product: value,
                unitOfMeasure: product?.simpleUnitOfMeasurement,
              });
            }}
          />
        );
      },
      colProps,
    },
    {
      title: t('form_fields.product_lot_title', {
        identifier: getProductsT(identifier),
      }),
      dataIndex: 'lotID',
      hideInForm: !isManualEntry || isObserve,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.product_lot_req', {
              identifier: getProductsT(identifier),
            }),
          },
          {
            whitespace: true,
            message: t('form_fields.product_lot_req', {
              identifier: getProductsT(identifier),
            }),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.product_lot_placeholder', {
          identifier: getProductsT(identifier),
        }),
        whitespace: false,
      },
      colProps,
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['product', 'unitOfMeasure', 'date'],
      },
      columns: ({ product, unitOfMeasure = 'LBS', date = undefined }: DataItem) => [
        {
          title: t('form_fields.product_lot_title', {
            identifier: getProductsT(identifier),
          }),
          dataIndex: 'lotID',
          hideInForm: !isManualEntry || !isObserve || !!container,
          initialValue: lotId,
          renderFormItem: (sc, _row, form) => {
            const { setFieldsValue } = form;
            return (
              <ProductLotSelect
                productId={product || productId}
                onChange={(value, option) => {
                  // @ts-ignore
                  const item: InventoryResponse = option.itemProps;
                  const instance = item?.productInstance;
                  const p = item?.productInstance?.product;
                  setFieldsValue({
                    lotID: value,
                    quantity: instance?.quantity || 0,
                    unitOfMeasure: p?.simpleUnitOfMeasurement || '',
                  });
                }}
              />
            );
          },
          fieldProps: {
            disabled: !product,
            placeholder: t('form_fields.product_lot_placeholder', {
              identifier: getProductsT(identifier),
            }),
          },
          colProps,
        },
        {
          title: t('form_fields.sscc'),
          dataIndex: 'sscc',
          hideInForm: !isManualEntry || !isObserve || !container,
          initialValue: container?.containerIdentifier || '',
          fieldProps: {
            disabled: true,
            placeholder: t('form_fields.sscc'),
          },
          colProps,
        },
        {
          title: t('form_fields.product_quantity_title'),
          dataIndex: 'quantity',
          valueType: 'digit',
          fieldProps: {
            addonAfter: unitOfMeasure,
            className: 'full-width',
            stringMode: true,
          },
          hideInForm: !isManualEntry || !!container || isSerial,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.product_quantity_req'),
              },
            ],
          },
          colProps,
        },
        {
          title: t('form_fields.date_title'),
          valueType: 'date',
          dataIndex: 'date',
          initialValue: null,
          hideInForm: !isManualEntry,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.date_req'),
              },
            ],
          },
          fieldProps: (form) => ({
            placeholder: t('form_fields.date_placeholder'),
            className: 'full-width',
            disabledDate,
            onChange: (value: string) => onChangeDate?.(form, value),
          }),
          colProps,
        },
        {
          title: t('form_fields.time_title'),
          valueType: 'time',
          dataIndex: 'time',
          className: 'timecol',
          initialValue: '12:00:00',
          hideInForm: !isManualEntry,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_req'),
              },
              ...(timeValidation?.(date) || []),
            ],
          },
          fieldProps: {
            placeholder: t('form_fields.time_placeholder'),
            className: 'full-width',
            // check is same day as date
            disabledTime:
              moment(date).isValid() &&
              moment(lastEventDate).isValid() &&
              moment(new Date(String(lastEventDate))).isSame(date, 'day')
                ? disabledTime
                : undefined,
          },
          colProps,
        },
      ],
    },

    {
      title: t('form_fields.time_zone_title'),
      dataIndex: 'timeZone',
      valueType: 'select',
      initialValue: defaultTimezone,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.time_zone_req'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.time_zone_placeholder'),
      },
      colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
      hideInForm: !isManualEntry,
      renderFormItem: () => <TimezoneSelect />,
    },
    {
      title: t('form_fields.bizstep_title'),
      dataIndex: 'bizStep',
      initialValue: isObserve ? undefined : 'urn:epcglobal:cbv:bizstep:commissioning',
      valueType: 'select',
      // hideInForm: !isManualEntry || !hasAdvancedSettings,
      renderFormItem: () => <BizStepSelect />,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.bizstep_req'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.bizstep_placeholder'),
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.bizstep_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
      hideInForm: !isManualEntry,
    },
    {
      title: t('form_fields.disposition_title'),
      dataIndex: 'disposition',
      initialValue: isObserve
        ? 'urn:epcglobal:cbv:disp:in_progress'
        : 'urn:epcglobal:cbv:disp:active',
      valueType: 'select',
      renderFormItem: () => <DispositionSelect />,
      // hideInForm: !isManualEntry || !hasAdvancedSettings,
      formItemProps: {
        rules: [
          {
            required: true,
            message: t('form_fields.disposition_req'),
          },
        ],
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.disposition_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
      hideInForm: !isManualEntry,
    },
    {
      valueType: 'divider',
    },
    {
      dataIndex: 'csvData',
      initialValue: [],
      renderFormItem: (row, record, form) => (
        <DataTemplate form={form} editableTableFormRef={editableTableFormRef} />
      ),
      colProps: colPropsFullCsv,
    },
    {
      valueType: 'formSet',
      dataIndex: 'customProperties',
      columns: getTemplateFields(template),
      rowProps,
      hideInForm:
        (isObserve ? false : !isManualEntry) || !template || !template?.templateAttributes,
    },
    {
      dataIndex: 'certificationList',
      rowProps,
      renderFormItem: (row, record, form) => <CertDocuments form={form} />,
      hideInForm:
        (isObserve ? false : !isManualEntry) ||
        !template ||
        !template?.templateAttributes ||
        !template?.allowCertificationDocuments,
    },
    {
      valueType: 'divider',
    },
    {
      dataIndex: 'documents',
      initialValue: [],
      renderFormItem: (_row, _config, form) => <Documents form={form} />,
      colProps: colPropsFull,
    },
  ];
};

export default CommissionFormFields;

import { Col, Form, Input, Row, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DrawerFormHeaderProps } from '../typings';
import styles from './index.module.less';

const DrawerFormHeader: FC<DrawerFormHeaderProps> = ({ title, form, readonly = false }) => {
  const { templateId } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'templates.add_template' });
  return (
    <Row gutter={[10, 10]}>
      <Col>
        <Typography.Title level={4}>{title}</Typography.Title>
      </Col>
      <Col>
        {!readonly ? (
          <Form form={form} layout="inline">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: t('form_fields.name_required'),
                },
                {
                  whitespace: true,
                  message: t?.('form_fields.name_required'),
                },
              ]}
            >
              <Input
                placeholder={t('form_fields.name_placeholder')}
                className={styles.templatename}
              />
            </Form.Item>
          </Form>
        ) : (
          <Typography.Text>{`: ${templateId}`}</Typography.Text>
        )}
      </Col>
    </Row>
  );
};
export default DrawerFormHeader;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import PDFViewer from 'components/PDFViewer';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../index.module.less';
import { DocumentAttachmentProps } from './typings';

const DocumentAttachment: FC<DocumentAttachmentProps> = ({
  form,
  isView = false,
  containerStyle,
  documentAttachment,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'workflows.create_workflow' });
  const { setFieldsValue } = form;
  const setDocumentAttachment = useCallback(
    (value: string, file?: string | RcFile | Blob) =>
      setFieldsValue({
        documentAttachment: value,
        file,
      }),
    [setFieldsValue],
  );
  const viewModestyle = {
    minHeight: isView ? '55vh' : undefined,
  };
  const viewModeDocstyle = {
    maxHeight: isView ? '55vh' : undefined,
  };
  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    accept: '.pdf,application/pdf',
    onChange: async (info) => {
      const { file } = info;
      file.status = 'done';
      let fileURL = null;
      if (!file.url && !file.preview && file.originFileObj) {
        fileURL = URL.createObjectURL(file.originFileObj);
        setDocumentAttachment(String(fileURL), file.originFileObj);
      }
    },
    onDrop: (e) => {
      // show a message if file type is not pdf
      const { files } = e.dataTransfer;
      const file = files[0];
      if (file.type !== 'application/pdf') {
        message.error(t('only_pdf_allowed'));
      }
    },
    className: styles.documentupload,
    style: viewModestyle,
  };
  return !documentAttachment ? (
    <Upload.Dragger {...uploadProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t('upload_modal_desc')}</p>
    </Upload.Dragger>
  ) : (
    <PDFViewer
      file={documentAttachment}
      containerStyle={{ ...viewModeDocstyle, ...containerStyle }}
      onLoadError={() => {
        message.error(t('failed_to_load'));
        setFieldsValue({
          documentAttachment: undefined,
          file: undefined,
        });
      }}
    />
  );
};
export default DocumentAttachment;

import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Descriptions, Space, Tooltip, Typography } from 'antd';
import React, { FC } from 'react';
import styles from '../index.module.less';
import { DetailsDataProps } from '../typings';
import DataTitle from './DataTitle';

const CardTitle: FC<DetailsDataProps> = ({ activeEvent, t }) => (
  <div className={styles.titleflex}>
    <Descriptions size="small" className={styles.cardtitle}>
      <Descriptions.Item
        label={
          <Space size="small">
            <Typography.Text strong>{t?.('hash_title')}</Typography.Text>
            <Tooltip placement="bottom" title={t?.('hash_tooltip')}>
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        }
      >
        <Typography.Paragraph copyable ellipsis>
          {activeEvent?.blockchain?.hashedEventData || ''}
        </Typography.Paragraph>
      </Descriptions.Item>
    </Descriptions>
  </div>
);
const DataContent: FC<DetailsDataProps> = ({
  event,
  activeEvent,
  isPretty,
  productInstance,
  isSerial,
  identifier,
  t,
}) => (
  <>
    <DataTitle
      activeEvent={activeEvent}
      productInstance={productInstance}
      isSerial={isSerial}
      identifier={identifier}
      t={t}
    />
    <Card title={<CardTitle event={event} activeEvent={activeEvent} t={t} />}>
      {isPretty && <pre className={styles.jsonbox}>{JSON.stringify(activeEvent, null, 2)}</pre>}
      {!isPretty && <p className={styles.jsonbox}>{JSON.stringify(activeEvent, null, 2)}</p>}
    </Card>
  </>
);
export default React.memo(DataContent);

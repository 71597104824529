import { Col, Row, Tag, Typography } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { dateLocaleFormat } from 'utils';
import { OutboundShipmentTableRowDataProps, ShipmentStatusProps } from '../typings';
import styles from './index.module.less';

export const ShipmentStatus: FC<ShipmentStatusProps> = ({ status, t }) => {
  const color = useMemo(() => {
    switch (status) {
      case 'Active':
        return 'success';
      case 'Accepted':
        return 'success';
      case 'Pending':
        return 'warning';
      case 'Invited':
        return 'processing';
      case 'Disabled':
        return 'error';
      case 'Received':
        return 'success';
      default:
        return 'default';
    }
  }, [status]);
  const text = useMemo(() => {
    switch (status) {
      case 'Active':
        return t?.('accepted');
      case 'Accepted':
        return t?.('accepted');
      case 'Pending':
        return t?.('pending');
      case 'Invited':
        return t?.('invited');
      case 'Disabled':
        return t?.('disabled');
      case 'Received':
        return t?.('received');
      default:
        return 'default';
    }
  }, [status, t]);
  return (
    <Tag color={color} key={status}>
      {text}
    </Tag>
  );
};
const OutboundShipmentTableRowData: FC<OutboundShipmentTableRowDataProps> = ({
  record,
  hideStatus,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  return (
    <div>
      <Typography.Text strong>{`${record?.destinationCompanyName} `}</Typography.Text>&nbsp;&nbsp;
      {!hideStatus && <ShipmentStatus status={record?.shipmentStatus} t={t} />}
      <Row justify="space-between" gutter={[16, 16]}>
        <Col>
          <Typography.Text className={styles.tabledetails} type="secondary">{`${t(
            'number_of_products',
          )} ${record?.numberOfProducts || '-'}`}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text className={styles.tabledetails} type="secondary">{`${t('location')} ${
            record?.destinationLocationName || '-'
          }`}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text className={styles.tabledetails} type="secondary">{`${t(
            'ship_date_time',
          )}  ${dateLocaleFormat(record?.eventDate || new Date()) || '-'}`}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text className={styles.tabledetails} type="secondary">{`${t(
            'purchase_order',
          )}  ${record?.purchaseOrderNumber || '-'}`}</Typography.Text>
        </Col>
      </Row>
    </div>
  );
};
export default OutboundShipmentTableRowData;

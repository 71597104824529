// @ts-nocheck

import { ProColumns } from '@ant-design/pro-table';
import { Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Actions, GTable, ShareTemplate, TitleBar } from 'components';
import { useTableRowSelection } from 'hooks';
import { ShipmentTag } from 'pages/Shipments';
import pluralize from 'pluralize';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ListWorkflowItem } from 'services/api/client/src';
import { coreStatus, dateFormat, dateLocalFormat } from 'utils';
import EmptyStateWorkflows from './EmptyStateWorkflow';
import { ActionMenu, ActionProps, WorkflowColumnsType, WorkflowTitleProps } from './typings';
import useWorkflowActions from './useWorkflowActions';
import useWorkflowFilters from './useWorkflowFilters';

const mainActionItems = ({ selectedRowKeys, t }: ActionMenu): Array<ItemType> => [
  {
    key: 'add',
    label: t?.('main_actions.add'),
  },
  {
    key: 'manage',
    label: t?.('main_actions.manage_grp_title'),
    type: 'group',
    children: [
      {
        key: 'download',
        label: t?.('main_actions.download'),
        disabled: !((selectedRowKeys?.length || 0) > 0),
      },
      {
        key: 'delete',
        label: t?.('main_actions.delete'),
        disabled: !((selectedRowKeys?.length || 0) > 0),
      },
    ],
  },
];
const ActionItems = ({ t, record }: ActionProps): ItemType[] => [
  {
    key: 'edit',
    label: record?.status === 'Completed' ? t?.('actions.view') : t?.('actions.complete'),
  },
  {
    key: 'duplicate',
    label: t?.('actions.duplicate'),
  },
  // {
  //   key: 'share',
  //   label: t?.('actions.share'),
  // },
  {
    key: 'download',
    label: t?.('actions.download'),
  },
  {
    key: 'delete',
    label: t?.('actions.delete'),
  },
];
const columns = ({
  t,
  handleActionsMenuClick,
  templateSearchfilter,
}: WorkflowColumnsType): ProColumns<ListWorkflowItem>[] => [
  {
    title: t?.('col_template_title'),
    dataIndex: 'name',
    render: (text, record) => (
      <Link className="text-link" to={record?.id || '0'}>
        {text}
      </Link>
    ),
    hideInSetting: true,
    ...templateSearchfilter,
  },
  {
    title: t?.('col_last_edited_title'),
    dataIndex: 'lastEditedBy',
    ellipsis: true,
    sorter: true,
    render: (text, record) =>
      `${record?.lastEditedBy || ''} ${
        record?.lastModifiedTime ? dateLocalFormat(record?.lastModifiedTime) : ''
      }`,
    width: 250,
  },
  {
    title: t?.('col_deadlin_title'),
    dataIndex: 'deadline',
    sorter: true,
    ellipsis: true,
    render: (text, record) => `${record?.deadline ? dateFormat(record?.deadline) : '-'}`,
  },
  {
    title: t?.('col_shared_title'),
    dataIndex: 'sharedWith',
    sorter: true,
    ellipsis: true,
  },
  {
    title: t?.('col_status_title'),
    dataIndex: 'status',
    filters: coreStatus,
    width: 150,
    ellipsis: true,
    render: (text, record) => <ShipmentTag status={record.status} />,
  },
  {
    dataIndex: 'actions',
    render: (text, record) => (
      <Actions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        items={ActionItems({ t, record })}
        onClick={handleActionsMenuClick}
        actionPayload={record}
      />
    ),
    hideInSetting: true,
  },
];
const Title: FC<WorkflowTitleProps> = ({ records, selectedRowKeys, onRowSelectionChange, t }) => {
  const navigate = useNavigate();
  const { shareModal, onShareTemplateMultiple, onDeleteRecordMultiple, onDownloadCSVMultiple } =
    useWorkflowActions();

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'add':
        navigate('add');

        break;
      case 'template_attributes':
        navigate({ pathname: `../../settings`, search: `?tab=${actionItemKey}` });
        break;
      case 'share':
        onShareTemplateMultiple(selectedRowKeys as Array<string>);
        break;
      case 'delete':
        onDeleteRecordMultiple(records);
        onRowSelectionChange([], []);
        break;
      case 'download':
        onDownloadCSVMultiple(records);
        onRowSelectionChange([], []);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <ShareTemplate modal={shareModal} />
      <TitleBar
        title={t('title')}
        extra={
          (selectedRowKeys?.length || 0) > 0 && (
            <Typography.Title level={5} className="selectiontext">
              {`${selectedRowKeys?.length || 0} ${pluralize(
                t('singular'),
                selectedRowKeys?.length || 0,
              )} ${t('selected')}`}
            </Typography.Title>
          )
        }
        actionItems={mainActionItems({ selectedRowKeys, t })}
        onActionItemClick={onActionItemClick}
      />
    </>
  );
};
const Workflows = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'workflows' });
  const { selectedRowKeys, selectedRows, onRowSelectionChange } = useTableRowSelection();

  const { handleActionsMenuClick, shareModal, onShareTemplate } = useWorkflowActions();

  const {
    params,
    totalItems,
    netItemCount,
    records,
    isLoading,
    onTableChange,
    filters: [templateSearchfilter],
  } = useWorkflowFilters();

  return (
    <>
      <ShareTemplate modal={shareModal} />
      <GTable<ListWorkflowItem>
        columns={columns({
          t,
          handleActionsMenuClick,
          onShareTemplate,
          templateSearchfilter,
        })}
        headerTitle={
          <Title
            records={selectedRows}
            selectedRowKeys={selectedRowKeys}
            onRowSelectionChange={onRowSelectionChange}
            t={t}
          />
        }
        value={records}
        loading={isLoading}
        enableRowSelection
        pagination={{
          defaultPageSize: params.pageSize,
          total: totalItems,
          showTotal: (total, range) =>
            t('template_table_pagi', { range0: range[0], range1: range[1], total }),
        }}
        scroll={netItemCount > 0 ? { y: '75vh', x: 1045 } : undefined}
        emptyContainer={!netItemCount ? <EmptyStateWorkflows t={t} /> : undefined}
        showHeader={netItemCount > 0}
        rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange, columnWidth: 30 }}
        columnsState={{
          persistenceKey: 'pages-workflows',
        }}
        // @ts-ignore
        onTableChange={onTableChange}
      />
    </>
  );
};
export default Workflows;

import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { Switch, Typography } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import classNames from 'classnames';
import ActionButtons from 'components/ActionButtons';
import GTable from 'components/GTable';
import { DragHandle, SortContainer, SortableItem } from 'components/useDragSort';
import { DraggableContainerProps } from 'components/useDragSort/typings';
import { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { SortEnd } from 'react-sortable-hoc';
import { getDocTypeEnum } from 'utils';
import { DocumentItem, DocumentTableProps } from '../typings';
import styles from './index.module.less';
import useAttributeStore from './useAttributeStore';

type DataItem = {
  id?: string;
  name?: string;
  required?: boolean;
  type?: string;
};
type ColumnsType = {
  onEditRow: (actionPayload?: DocumentItem) => void;
  onDelRow: (actionPayload?: DocumentItem) => void;
  t: TFunction<'pages', 'templates.add_template'>;
  isEdit?: boolean;
  editableFormRef?: MutableRefObject<EditableFormInstance<DataItem> | undefined>;
};
const columns = ({
  onEditRow,
  onDelRow,
  t,
  isEdit = true,
}: ColumnsType): Array<ProColumns<DocumentItem>> => [
  {
    dataIndex: 'id',
    width: 40,
    render: () => <DragHandle />,
    fieldProps: {
      disabled: true,
    },
    renderFormItem: () => null,
    className: 'drag-visible',
  },
  {
    title: t('form_fields.documents_col_required'),
    dataIndex: 'required',
    valueType: 'switch',
    fieldProps: {
      size: 'small',
    },
    width: 90,
    render: (text, record) => <Switch checked={record.required} size="small" disabled />,
    className: 'drag-visible',
  },
  {
    title: t('form_fields.documents_col_doc_type'),
    dataIndex: 'type',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('form_fields.documents_col_doc_type_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t('form_fields.documents_col_doc_type'),
      size: 'small',
    },
    valueType: 'select',
    valueEnum: getDocTypeEnum(),
    width: 300,
    className: 'drag-visible',
  },
  {
    title: t('form_fields.documents_col_doc_type_name'),
    dataIndex: 'name',
    formItemProps: (form, { rowKey }) => {
      const documentType = form?.getFieldValue(String(rowKey))?.type?.toLocaleLowerCase();
      return {
        rules: [
          {
            required: documentType === 'other',
            message: t('form_fields.documents_col_doc_type_name_req'),
          },
        ],
        hasFeedback: false,
      };
    },
    fieldProps: (form, { rowKey }) => {
      const documentType = form?.getFieldValue(String(rowKey))?.type?.toLocaleLowerCase();
      return {
        placeholder: t('form_fields.documents_col_doc_type_name'),
        size: 'small',
        disabled: documentType !== 'other',
        hidden: documentType !== 'other',
      };
    },
    width: 350,
    className: 'drag-visible',
  },
  {
    render: () => null,
    renderFormItem: () => null,
    width: 400,
    className: 'drag-visible',
  },

  {
    dataIndex: 'actions',
    hideInTable: !isEdit,
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons record={record} onDelete={onDelRow} onEdit={onEditRow} />
    ),
    width: 100,
    fixed: 'right',
  },
];

const Documents: FC<DocumentTableProps> = ({ form, templateQuery }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates.add_template' });
  const templateMode = useAttributeStore((state) => state.templateMode);
  const isEdit = templateMode === 'edit';
  const editableFormRef = useRef<EditableFormInstance<DataItem>>();

  const { getFieldValue, setFieldsValue } = form;
  const actionRef = useRef<ActionType>();
  const templateData = templateQuery?.data;
  const templateLoading = templateQuery?.isLoading || false;

  const documents: Array<DocumentItem> = getFieldValue('documents');
  const setDocuments = useCallback(
    (value: Array<DocumentItem>) =>
      setFieldsValue({
        documents: value,
      }),
    [setFieldsValue],
  );

  useEffect(() => {
    if (templateData) {
      setDocuments(
        templateData?.templateDocuments?.map((el) => ({
          id: el?.id || `new${Date.now().toString()}`,
          required: el?.required || false,
          type: el?.documentType || 'certification',
          name: el?.documentType?.toLocaleLowerCase() === 'other' ? el?.documentName : '',
        })) || [],
      );
    }
  }, [templateData, setDocuments]);

  const onEditRow = (actionPayload?: DocumentItem) => {
    actionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onDelRow = async (actionPayload?: DocumentItem) => {
    if (actionPayload) setDocuments(documents.filter((item) => item.id !== actionPayload.id));
  };

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable([...documents], oldIndex, newIndex);

        setDocuments([...newData]);
      }
    },
    [documents, setDocuments],
  );

  const DraggableContainer: FC<DraggableContainerProps> = useCallback(
    (props) => (
      <SortContainer
        useDragHandle
        disableAutoscroll
        helperClass={styles['row-dragging']}
        onSortEnd={onSortEnd}
        {...props}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSortEnd],
  );

  const DraggableBodyRow: FC<any> = useCallback(
    (props) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      // @ts-ignore
      // eslint-disable-next-line react/destructuring-assignment
      const index = documents?.findIndex((x) => x.id === props['data-row-key']) || 0;
      return <SortableItem {...props} index={index} />;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documents],
  );

  const { body } = useMemo(
    () => ({ body: { wrapper: DraggableContainer, row: DraggableBodyRow } }),
    [DraggableContainer, DraggableBodyRow],
  );

  return (
    <>
      <Typography.Paragraph className={classNames('ant-pro-form-group-title', styles.title)}>
        {t('form_fields.documents_grp_title')}
      </Typography.Paragraph>
      <GTable<DocumentItem>
        actionRef={actionRef}
        columns={columns({ onEditRow, onDelRow, t, isEdit, editableFormRef })}
        editable={{
          onCancel: async (_rowKey, data) => {
            actionRef.current?.cancelEditable(data?.id || 0);
          },
        }}
        options={{
          reload: false,
          setting: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        recordCreatorProps={false}
        onAddRecordClick={() => {
          actionRef.current?.addEditRecord?.({
            id: `new${Date.now().toString()}`,
          });
        }}
        value={documents}
        onChange={setDocuments}
        enableRecordCreator={isEdit}
        addBtnText={t('form_fields.add_document_title')}
        components={{
          body,
        }}
        loading={templateLoading}
        scroll={{ x: 700 }}
      />
    </>
  );
};
export default Documents;

import {
  ArrowLeftOutlined,
  CloseOutlined,
  CopyOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import ProList, { ProListMetas } from '@ant-design/pro-list';
import {
  Alert,
  Avatar,
  Button,
  Col,
  Modal,
  PageHeader,
  Row,
  Space,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import { integrationImg1, integrationImg2, integrationImg3, integrationImg4 } from 'assets';
import RowTable from 'components/RowTable';
import RowField from 'components/RowTable/RowField';
import React, { useState } from 'react';
import { copyToClipboard } from 'utils';
import styles from './index.module.less';
import { Integration } from './typings';

const Integrations = () => {
  const screenTitle = 'Integrations';
  const sftpTooltip = {
    url: {
      initial: 'Copy URL',
      success: 'URL Copied to Clipboard',
    },
    username: {
      initial: 'Copy Username',
      success: 'Username Copied to Clipboard',
    },
    password: {
      initial: 'Copy Password',
      success: 'Password Copied to Clipboard',
    },
  };
  const [urlTip, setUrlTip] = useState(sftpTooltip.url.initial);
  const [usernameTip, setUsernameTip] = useState(sftpTooltip.username.initial);
  const [passwordTip, setPasswordTip] = useState(sftpTooltip.password.initial);

  const integrations: Integration[] = [
    {
      id: 1,
      name: 'Best Aquaculture Practice (BAP)',
      shortDesc: 'Global Aquaculture Alliance',
      longDesc:
        'BAP is the only seafood-specific certification program that is capable of certifying every step in the production chain. The process starts with hatcheries and ends in a processing plant where it is prepared for delivery to you.',
      code: 'bap',
      img: integrationImg1,
      manage: true,
    },
    {
      id: 2,
      name: 'Secure File Transfer Protocol (SFTP)',
      shortDesc: 'Wholechain',
      longDesc:
        'Setup a secure SFTP directory where you can deposit files to be processed. You will be able to automate the upload of Dynamic Data for Soureceview so your Profiles and Collections always stay up-to-date without any manual work.',
      code: 'sftp',
      img: integrationImg2,
      manage: true,
    },
    {
      id: 3,
      name: 'Mastercard',
      shortDesc: 'Mastecard Provenance Solution',
      longDesc:
        'Provenance is Mastercard’s blockchain-based solution for supply chain visibility. The idea is that the same benefits blockchain brings to financial transactions — a complete and immutable record of an asset’s journey — can also be applied to consumer goods, from luxury fashion to salmon.',
      code: 'mastercard',
      img: integrationImg3,
      manage: false,
    },
    {
      id: 4,
      name: 'Sea Warden',
      shortDesc: 'Satellite Observation & Analysis for Aquaculture',
      longDesc:
        'Effortlessly generate Key Data Elements at the farm level to enhance transparency, reduce risk to your farmed shrimp supply chain, and boost consumer trust. Sea Warden offers this service for any shrimp farm in the world, and generates KDEs in line with industry standards including: GDST, BAP, and ASC.',
      code: 'sea_warden',
      img: integrationImg4,
      manage: false,
    },
    // {
    //   id: 5,
    //   name: 'Stellar',
    //   shortDesc: 'Stellar Foundation (built by the Wholechain team)',
    //   longDesc:
    //     'Stellar is an open source, decentralized protocol for digital currency to fiat money transfers which allows cross-border transactions between any pair of currencies. The Stellar protocol is supported by a 5013 nonprofit, the Stellar Development Foundation.',
    //   code: 'stellar',
    //   img: integrationImg5,
    //   manage: false,
    // },
  ];

  const listSpan = {
    md: { span: 24 },
    lg: { span: 24 },
  };
  const [sftpModal, setSftpModal] = useState(false);
  const accessPasswordHint =
    'Your password will be displayed once when you click the link. Click the link again if you need to generate a new one. ';

  const [sftpSettings, setSftpSettings] = useState({
    upload: false,
    server: {
      url: 'https://sftp.sourceview.co',
      username: 'ASeafoodCompany-M3FP8M',
      password: '-N0iBYOFi-X1bSYqTv4j',
    },
    accessPassword: false,
  });
  const onManageBAP = () => {
    Modal.info({
      title: 'This integration a beta feature',
      content: (
        <div>
          Please request special access to utilize
          <br />
          this feature.
        </div>
      ),
      okCancel: true,
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
        shape: 'round',
      },
      okText: 'Request Access',
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      onOk: () => window.open('https://wholechain.com/contact'),
    });
  };

  const onManageSftp = () => {
    setSftpModal(true);
    setSftpSettings({ ...sftpSettings, upload: false, accessPassword: false });
  };
  const onChangeUpload = () => {
    setSftpSettings({ ...sftpSettings, upload: !sftpSettings.upload });
  };
  const onAccessPassword = () => {
    setSftpSettings({ ...sftpSettings, accessPassword: true });
  };
  const onBackAccessPassword = () => {
    setSftpSettings({ ...sftpSettings, accessPassword: false });
  };
  const onCloseSftpModal = () => {
    setSftpModal(false);
  };
  const onSubmitSftpSettings = () => {
    try {
      onCloseSftpModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  const onManageIntegration = (e: React.MouseEvent<HTMLElement, MouseEvent>, item: Integration) => {
    switch (item.code) {
      case 'bap':
        onManageBAP();
        break;
      case 'sftp':
        onManageSftp();
        break;

      default:
        break;
    }
  };
  const listMetas: ProListMetas = {
    title: {
      dataIndex: 'name',
      render: (text) => (
        <a href="#href" onClick={(e) => e.preventDefault()}>
          {text}
        </a>
      ),
    },
    avatar: {
      dataIndex: 'img',
      render: (text, record) => <Avatar src={record.img} alt={record.name} size={64} />,
    },
    description: {
      dataIndex: 'shortDesc',
      render: (text, record) => (
        <>
          <div className="list-desc">{record.shortDesc}</div>
          <div className="list-desc">{record.longDesc}</div>
        </>
      ),
    },
    actions: {
      render: (text, record) =>
        record.manage ? (
          <Button type="primary" shape="round" onClick={(e) => onManageIntegration(e, record)}>
            Manage
          </Button>
        ) : null,
    },
  };
  const onCopyToClipboard = async (type: string, text: string) => {
    await copyToClipboard(text);

    switch (type) {
      case 'url':
        setUrlTip(sftpTooltip.url.success);
        setTimeout(() => setUrlTip(sftpTooltip.url.initial), 2000);
        break;
      case 'username':
        setUsernameTip(sftpTooltip.username.success);
        setTimeout(() => setUsernameTip(sftpTooltip.username.initial), 2000);
        break;
      case 'password':
        setPasswordTip(sftpTooltip.password.success);
        setTimeout(() => setPasswordTip(sftpTooltip.password.initial), 2000);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Modal
        visible={sftpModal}
        title={
          <Row justify="space-between">
            <div>
              {!sftpSettings.accessPassword ? (
                'Secure File Transfer Protocol (SFTP) Settings'
              ) : (
                <Space>
                  <Button type="text" size="small" onClick={onBackAccessPassword}>
                    <ArrowLeftOutlined />
                  </Button>
                  <div>SFTP Password</div>
                </Space>
              )}
            </div>
            <Button type="text" size="small" onClick={onCloseSftpModal}>
              <CloseOutlined />
            </Button>
          </Row>
        }
        closable={false}
        footer={
          <Space>
            <Button onClick={onCloseSftpModal} type="primary" ghost shape="round">
              Cancel
            </Button>
            <Button onClick={onSubmitSftpSettings} type="primary" shape="round">
              Done
            </Button>
          </Space>
        }
        onCancel={onCloseSftpModal}
        width="58%"
        centered
      >
        {!sftpSettings.accessPassword ? (
          <>
            <Space align="center">
              <Typography.Paragraph className="mr0">
                SFTP Upload for Dynamic Data
              </Typography.Paragraph>
              <Switch checked={sftpSettings.upload} onChange={onChangeUpload} />
            </Space>

            <Typography.Paragraph className="mr-t20">
              Use these settings to connect to our SFTP server.
            </Typography.Paragraph>
          </>
        ) : (
          <>
            <Typography.Paragraph className="mr-t5 mr-b10">
              Here is your password for your SFTP account. Click the copy icon to copy your
              password.
            </Typography.Paragraph>
            <br />
            <RowTable className="mr-b20">
              <RowField
                title={
                  <Space>
                    <div className="row-field-title">Password</div>
                  </Space>
                }
                fieldValue={sftpSettings.server.password}
                actions={
                  <Tooltip placement="bottom" title={passwordTip}>
                    <Button
                      type="text"
                      size="small"
                      onClick={() => onCopyToClipboard('password', sftpSettings.server.password)}
                    >
                      <CopyOutlined />
                    </Button>
                  </Tooltip>
                }
                borderBottom={false}
              />
            </RowTable>
            <Alert
              message="Warning"
              description="This password is only be displayed once! We do not store it or have a way to retrieve it. If you forget your password, return here to receive a new one."
              type="warning"
            />
          </>
        )}

        {sftpSettings.upload && !sftpSettings.accessPassword ? (
          <RowTable>
            <RowField
              title={
                <Space>
                  <div className="row-field-title">URL</div>
                </Space>
              }
              fieldValue={sftpSettings.server.url}
              actions={
                <Tooltip placement="bottom" title={urlTip}>
                  <Button
                    type="text"
                    size="small"
                    onClick={() => onCopyToClipboard('url', sftpSettings.server.url)}
                  >
                    <CopyOutlined type="primary" />
                  </Button>
                </Tooltip>
              }
            />
            <RowField
              title={
                <Space>
                  <div className="row-field-title">Username</div>
                </Space>
              }
              fieldValue={sftpSettings.server.username}
              actions={
                <Tooltip placement="bottom" title={usernameTip}>
                  <Button
                    type="text"
                    size="small"
                    onClick={() => onCopyToClipboard('username', sftpSettings.server.username)}
                  >
                    <CopyOutlined />
                  </Button>
                </Tooltip>
              }
            />
            <RowField
              title={
                <Space>
                  <div className="row-field-title">Password</div>
                  <Tooltip placement="bottom" title={accessPasswordHint}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
              fieldValue={
                <Button size="small" onClick={onAccessPassword} type="primary" shape="round" ghost>
                  Access Password
                </Button>
              }
              borderBottom={false}
            />
          </RowTable>
        ) : null}
      </Modal>
      <PageHeader title={screenTitle} className={styles['page-header']} />

      <Row>
        <Col {...listSpan}>
          <ProList
            itemLayout="horizontal"
            rowKey="id"
            showActions="hover"
            split
            dataSource={integrations}
            className={styles.alist}
            cardProps={{ bodyStyle: { padding: 0, margin: 0 } }}
            metas={listMetas}
          />
        </Col>
      </Row>
    </>
  );
};
export default Integrations;

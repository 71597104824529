export const setOutboundSettingsTabSession = (tabId: string) => {
  sessionStorage.setItem('OutboundSettingsTab', tabId);
};

export const getOutboundSettingsTabSession = () => {
  if (!sessionStorage.getItem('OutboundSettingsTab')) {
    setOutboundSettingsTabSession('internal');
  }
  return sessionStorage.getItem('OutboundSettingsTab') || 'internal';
};

export const removeOutboundSettingsTabSession = () => {
  sessionStorage.removeItem('OutboundSettingsTab');
};

export const setOutboundSettingsShipTabSession = (tabId: string) => {
  sessionStorage.setItem('OutboundSettingsShipTab', tabId);
};

export const getOutboundSettingsShipTabSession = () => {
  if (!sessionStorage.getItem('OutboundSettingsShipTab')) {
    setOutboundSettingsTabSession('internal');
  }
  return sessionStorage.getItem('OutboundSettingsShipTab') || 'internal';
};

export const removeOutboundSettingsShipTabSession = () => {
  sessionStorage.removeItem('OutboundSettingsShipTab');
};

import { ProFormColumnsType } from '@ant-design/pro-form';
import { colPropsNull } from 'utils';
import EventGrid from './EventGrid';
import { DataItem, FormFieldsProps } from './typings';

const FormFields = ({
  isEdit = false,
  mainGraph,
  setMainGraph,
  product,
  productInstance,
  event,
  isCompleted,
  percent,
  isSubmitting,
}: FormFieldsProps): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['graphData', 'instances', 'templateId'],
    },
    columns: ({ graphData, instances, templateId }: DataItem) => [
      {
        dataIndex: 'events',
        renderFormItem: (_schema, _config, form) => (
          <EventGrid
            form={form}
            templateId={templateId}
            isEdit={isEdit}
            graphData={graphData}
            instances={instances}
            mainGraph={mainGraph}
            setMainGraph={setMainGraph}
            product={product}
            productInstance={productInstance}
            event={event}
            isCompleted={isCompleted}
            percent={percent}
            isSubmitting={isSubmitting}
          />
        ),
      },
      {
        dataIndex: 'graphData',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: colPropsNull,
      },
      {
        dataIndex: 'instances',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: colPropsNull,
      },
      {
        dataIndex: 'csvData',
        fieldProps: {
          className: 'no-display',
        },
        formItemProps: {
          className: 'no-display',
        },
        colProps: colPropsNull,
      },
    ],
  },
];
export default FormFields;

import { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps } from 'antd';
import SearchTable from './SearchTable';

const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };

export type DataItem = {
  productTable?: string;
};

export const SearchDrawerColumns = (): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    colProps: colProps24,
    columns: [
      {
        dataIndex: 'productTable',
        initialValue: [],
        renderFormItem: () => <SearchTable />,
      },
    ],
  },
];

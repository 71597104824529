/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { message, Space, Typography } from 'antd';
import { MessageType } from 'antd/lib/message';
import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { getEventResponse } from 'services/api';
import { CommissionRequest, EventResponse } from 'services/api/client/src';
import { useCreateCommissionEvent, useCreateCommissionEvents } from 'services/api/events';
import {
  apiDateFormat,
  checkFieldValues,
  checkRequestedDocuments,
  errorHandler,
  getBizStepIdByURN,
  getCertProperties,
  getCSVCustomProperties,
  getCustomProperties,
  getDispositionIdByURN,
  getProductsT,
  getTimezoneOffset,
  getUom,
  mapCertProperties,
} from 'utils';
import { useEventsStore, useTitle } from '../hooks';
import { commissionTemplate } from './CSVTemplates';
import { useEventActions } from './Forms';
import CommissionFormFields, { DataItem } from './Forms/CommissionForm.fields';
import { EventPageProps } from './typings';

const Commission: FC<EventPageProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.commission' });
  /* do not use t below , later is translated in header */
  useTitle('commission');
  const {
    productId,
    product: dataProduct,
    lotId,
    onChangeTemplate,
    onChangeDate,
    defaultTimezone,
    isSerial,
    identifier,
  } = useEventActions();
  const { hasAdvancedSettings, dataEntryMethod, template, setSubmitting, setTemplate } =
    useEventsStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const createCommissionEvent = useCreateCommissionEvent(queryClient);
  const createCommissionEvents = useCreateCommissionEvents(queryClient);
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();

  const onViewEvent = useCallback(
    (formData: DataItem, res?: EventResponse) => {
      const instanceId = res?.productInstances?.[0]?.id || '';
      const eventId = res?.id || '';
      navigate({
        pathname: `/products/${productId}/${instanceId}`,
        search: `?eventId=${eventId}`,
      });
    },
    [navigate, productId],
  );

  const onCSVSubmit = async (formData: DataItem) => {
    const values = formData?.csvData;
    let success: MessageType | undefined;
    try {
      if (values?.length) {
        await editableTableFormRef.current?.validateFields();
        checkFieldValues(values, template?.templateAttributes || [], commissionTemplate, isSerial);
        success = message.loading(
          t('csv_submit_progress', {
            count: values?.length || 0,
            identifier: String(getProductsT(identifier)).toLowerCase(),
          }),
          0,
        );

        const commissions: Array<CommissionRequest> = [];
        values?.forEach((value) => {
          const reqData: CommissionRequest = {
            eventTime: apiDateFormat(value.date, value.time),
            eventTimeZone: getTimezoneOffset(value.timeZone),
            bizStepId: getBizStepIdByURN(value.bizStep),
            dispositionId: getDispositionIdByURN(value.disposition),
            locationId: formData.location,
            purchaseOrder: value.poNumber,
            productId: formData.product,
            productInstance: {
              quantity: !isSerial ? Number(value?.quantity || 0) : 1,
              lotSerial: value.lotID,
            },
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            masterData: getCSVCustomProperties(value, template),
            certifications: getCertProperties(value, template),
          };
          commissions.push(reqData);
        });
        setSubmitting(false);
        await createCommissionEvents.mutateAsync(commissions);
        success?.();
      } else {
        throw new Error(getProductsT('no_data'));
      }
    } catch (error: any) {
      success?.();

      throw error;
    }
  };

  const onFormSubmit = async (formData: DataItem) => {
    try {
      const reqData: CommissionRequest = {
        bizStepId: getBizStepIdByURN(formData.bizStep),
        dispositionId: getDispositionIdByURN(formData.disposition),
        eventTime: apiDateFormat(formData.date, formData.time),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        purchaseOrder: formData.poNumber,
        productId: formData.product,
        productInstance: {
          quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
          lotSerial: formData.lotID?.trim(),
        },
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        masterData: getCustomProperties(formData?.customProperties, template),
        templateId: template?.id,
        certifications: mapCertProperties(formData?.certificationList || []),
      };

      const res = await createCommissionEvent.mutateAsync({
        commissions: [reqData],
      });
      /* get event response */
      const event = await getEventResponse(res);

      message.success(
        <Space>
          {t('submit_success', {
            id: formData.lotID?.trim(),
            identifier: getProductsT(identifier),
          })}
          <Typography.Link
            onClick={(e) => {
              e.preventDefault();
              onViewEvent(formData, event);
            }}
          >
            {t('view_event')}
          </Typography.Link>
        </Space>,
      );
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);
      checkRequestedDocuments({ template, documents: formData?.documents });

      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
        setSubmitting(false);
      } else {
        await onCSVSubmit(formData);
      }
      navigate(-1);
    } catch (error: any) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      columns={CommissionFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        editableTableFormRef,
        template,
        productId,
        lotId,
        defaultTimezone,
        setTemplate,
        onChangeTemplate,
        onChangeDate,
        t,
        isSerial,
        identifier,
      })}
      form={form}
      grid
      rowProps={{ gutter: 32 }}
      layoutType="Form"
      submitter={{ render: () => null }}
      onFinish={onFinish}
      initialValues={{
        unitOfMeasure: getUom({ product: dataProduct }),
      }}
    />
  );
};

export default React.memo(Commission);

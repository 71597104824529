import { Button, message } from 'antd';
import { useModalVisibility } from 'hooks';
import useAddEditActions from 'pages/Network/hook/useAddEditActions';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryDigitalLink } from 'services/api';
import GenerateAPIKeyModal from './GenerateAPIKey';
import styles from './index.module.less';
import { OutboundProps } from './typings';

const OutboundAPIKey: FC<OutboundProps> = ({ tradePartnerId, digitalLink, form }) => {
  const apiKeyModal = useModalVisibility(false);
  const { t } = useTranslation('pages', {
    keyPrefix: 'network.add_network.form_fields.inbound_key',
  });
  const { updateTraceabilityData, digitalLinkInvalid } = useAddEditActions();
  const onOpenModal = useCallback(() => apiKeyModal?.show(), [apiKeyModal]);
  const validateDigitalLink = useQueryDigitalLink();

  const onSave = useCallback(
    async (myAPIKey: string) => {
      if (!digitalLink) {
        form?.setFieldsValue({
          apiKey: myAPIKey,
        });
        message.success(t('api_key_success'));
        apiKeyModal?.hide();
      } else {
        const valid = await validateDigitalLink.mutateAsync({
          params: { url: digitalLink, apiKey: myAPIKey },
        });
        if (valid?.result === 'OWN_API') {
          digitalLinkInvalid();
          return;
        }
        const res = await updateTraceabilityData(tradePartnerId, {
          tradePartnerId,
          outboundApiKey: myAPIKey,
        });
        //   const res = await addTraceabilityData(tradePartnerId, digitalLink, myAPIKey);

        if (res) {
          message.success(t('api_key_success'));
          apiKeyModal?.hide();
        }
      }
    },
    [
      apiKeyModal,
      digitalLink,
      digitalLinkInvalid,
      form,
      t,
      tradePartnerId,
      updateTraceabilityData,
      validateDigitalLink,
    ],
  );

  return (
    <>
      {apiKeyModal.visible && (
        <GenerateAPIKeyModal
          modal={apiKeyModal}
          t={t}
          modalTitle={!digitalLink ? t('add_api_key') : t('change_api_key')}
          onSave={onSave}
        />
      )}
      <Button shape="round" className={styles.deletebuttontext} onClick={onOpenModal}>
        {!digitalLink ? t('add_api_key') : t('change_api_key')}
      </Button>
    </>
  );
};

export default OutboundAPIKey;

import { Image, Typography } from 'antd';
import { upcomingSvg } from 'assets';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import { ComingSoonProps } from './typings';

const ComingSoon: FC<ComingSoonProps> = ({ className }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'comingsoon' });

  return (
    <div className={classNames(styles.comingsoon, className)}>
      <Image src={upcomingSvg} width="196px" height="201px" preview={false} />
      <div className={classNames(styles.body)}>
        <Typography.Title level={3}>{t('title')}</Typography.Title>
        <Typography.Text>{t('description')}</Typography.Text>
      </div>
    </div>
  );
};
export default ComingSoon;

import { ProFormColumnsType } from '@ant-design/pro-form';
import DocumentTypeDetails from '../../Tables/DocumentTemplateTable';
import { DataItem } from '../../typings';

const AddDocumentTemplateFormFields = (): ProFormColumnsType<DataItem>[] => [
  {
    dataIndex: 'customAttributes',
    initialValue: [],
    renderFormItem: (_schema, _config, form) => <DocumentTypeDetails form={form} />,
  },
];
export default AddDocumentTemplateFormFields;

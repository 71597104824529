import { useQuery } from 'react-query';
import {
  CreateLanguageAttributeRequest,
  CreateLanguageAttributeResponse,
  ListLanguageAttributesOptionalParams,
  ListLanguageAttributesResponse,
  ListLanguagesOptionalParams,
  ListLanguagesResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';
import { fetchRequest } from 'services/utils/security/HttpClient';

const chinaCheckPath = '/api/Users/disableMaps';

/* Get all languages */

export const getAllLanguages = async (
  languageCode: string,
  params?: ListLanguagesOptionalParams,
): Promise<ListLanguagesResponse> => HttpClient.listLanguages(languageCode, params);

export const useGetAllLanguages = (languageCode: string, params?: ListLanguagesOptionalParams) =>
  useQuery(['allLanguages', params], () => getAllLanguages(languageCode, params));

export const createAttributeKey = (
  params?: CreateLanguageAttributeRequest,
): Promise<CreateLanguageAttributeResponse> => HttpClient.createLanguageAttribute({ body: params });

export const getTranslationByLangCodeAPI = (
  languageCode: string,
  params?: ListLanguageAttributesOptionalParams,
): Promise<ListLanguageAttributesResponse> =>
  HttpClient.listLanguageAttributes(languageCode, params);

export const useCheckChina = () =>
  useQuery(['chinaCheck'], () =>
    fetchRequest({
      path: chinaCheckPath,
    }),
  );

import { Tag, TagProps } from 'antd';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ShipmentTagProps } from '../typings';

const ShipmentTag: FC<ShipmentTagProps> = ({ status }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  const color: TagProps['color'] = useMemo(() => {
    switch (status) {
      case 'Pending':
        return 'warning';
      case 'InProgress':
        return 'warning';
      case 'PENDING':
        return 'warning';

      case 'Accepted':
        return 'success';
      case 'Completed':
        return 'success';
      case 'SENT':
        return 'success';
      case 'Received':
        return 'success';
      case 'RECEIVED':
        return 'success';

      case 'Rejected':
        return 'error';

      case 'Failed':
        return 'error';

      case 'FAILED':
        return 'error';

      case 'Shipped':
        return 'processing';
      case 'SHIPPED':
        return 'processing';
      case 'DRAFT':
        return 'processing';

      default:
        return 'default';
    }
  }, [status]);

  const text = useMemo(() => {
    switch (status) {
      case 'Pending':
        return t('pending');
      case 'PENDING':
        return t('pending');
      case 'InProgress':
        return t('inprogress');

      case 'Accepted':
        return t('accepted');
      case 'ACCEPTED':
        return t('accepted');

      case 'Sent':
        return t('sent');
      case 'SENT':
        return t('sent');

      case 'Failed':
        return t('failed');
      case 'FAILED':
        return t('failed');

      case 'Received':
        return t('accepted');
      case 'RECEIVED':
        return t('accepted');

      case 'Rejected':
        return t('rejected');

      case 'Shipped':
        return t('shipped');
      case 'SHIPPED':
        return t('shipped');
      case 'DRAFT':
        return t('draft');

      default:
        return status;
    }
  }, [status, t]);
  return (
    <Tag color={color} className={status === 'DRAFT' ? 'document-tag' : undefined}>
      {text}
    </Tag>
  );
};
export default React.memo(ShipmentTag);

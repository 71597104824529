import { ProColumns } from '@ant-design/pro-table';
import { useMemo } from 'react';
import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  ChangeArchivedTradePartnerResponse,
  CreateNetworkInviteRequest,
  CreateNetworkInviteResponse,
  CreateTradePartnerOptionalParams,
  GetInboundApiKeyOperationResponse,
  GetInboundApiKeyOptionalParams,
  GetTradePartnerByIdOptionalParams,
  GetTradePartnerByIdResponse,
  GetTradePartnerStatisticsByIdOptionalParams,
  HasActiveInventoryOperationResponse,
  HasActiveInventoryOptionalParams,
  ListNetworkInvitesOptionalParams,
  ListNetworkInvitesResponse,
  ListTradePartnersOptionalParams,
  QueryDigitalLinkOptionalParams,
  QueryDigitalLinkResponse,
  SetExternalRegistryDetailsOptionalParams,
  SetExternalRegistryDetailsResponse,
  SetExternalRegistryRequest,
  UpdateExternalRegistryDetailsResponse,
  UpdateExternalRegistryRequest,
  UpdateNetworkInviteOptionalParams,
  UpdateNetworkInviteResponse,
  UpdateTradePartnerRequest,
  UpdateTradePartnerResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

export const getTradePartners = (params?: ListTradePartnersOptionalParams) =>
  HttpClient.listTradePartners(params);

const createTradePartnerRequest = async (options: CreateTradePartnerOptionalParams) =>
  HttpClient.createTradePartner(options);

const getTradePartnerById = (
  id: string,
  options?: GetTradePartnerByIdOptionalParams,
): Promise<GetTradePartnerByIdResponse> => HttpClient.getTradePartnerById(id, options);

export const useTradePartnerById = (id: string, options?: GetTradePartnerByIdOptionalParams) =>
  useQuery(['tradePartnerDataId', id, options], () => getTradePartnerById(id, options), {
    // disable refetch on window focus and switch tab
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useTradePartnerByIdMutate = () =>
  useMutation(({ id, options }: { id: string; options?: GetTradePartnerByIdOptionalParams }) =>
    getTradePartnerById(id, options),
  );
const useTradePartners = (params?: ListTradePartnersOptionalParams) =>
  useQuery(['tradePartners', params], () => getTradePartners(params));

/* tradepartner filter for table */
export const useTradePartnerFilters = (
  params?: ListTradePartnersOptionalParams,
): ProColumns['filters'] => {
  const { data: dataTradePartners } = useTradePartners(params);
  const tradePartnerFilters = useMemo(
    () =>
      dataTradePartners?.data?.map((option) => ({
        text: option?.name || '',
        value: option?.urn || '',
      })) || [],
    [dataTradePartners],
  );
  return tradePartnerFilters;
};
export const useTradePartnerURNFilters = (
  params?: ListTradePartnersOptionalParams,
): ProColumns['filters'] => {
  const { data: dataTradePartners } = useTradePartners(params);
  const tradePartnerFilters = useMemo(
    () =>
      dataTradePartners?.data?.map((option) => ({
        text: option?.name || '',
        value: option?.urn || '',
      })) || [],
    [dataTradePartners],
  );
  return tradePartnerFilters;
};

export const useCreateTradePartner = (queryClient: QueryClient) =>
  useMutation(
    (attribute: CreateTradePartnerOptionalParams) => createTradePartnerRequest(attribute),
    {
      onSuccess: () => {
        queryClient.refetchQueries('tradePartners');
        queryClient.invalidateQueries('attributes');
      },
    },
  );

const updateTradePartnerRequest = (
  id: string,
  options: UpdateTradePartnerRequest,
): Promise<UpdateTradePartnerResponse> =>
  HttpClient.updateTradePartner(id, {
    body: options,
  });

export const useUpdateTradePartnerById = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options: UpdateTradePartnerRequest }) =>
      updateTradePartnerRequest(id, options),
    {
      onSuccess: () => {
        queryClient.refetchQueries('locations');
        queryClient.refetchQueries('mylocations');
        queryClient.refetchQueries('networks');
        queryClient.refetchQueries('tradePartnerDataId');
      },
    },
  );

interface PromiseFulfilledResult<T> {
  status: 'fulfilled';
  value: T;
}

interface PromiseRejectedResult {
  status: 'rejected';
  reason: any;
}

export type PromiseSettledResult =
  | PromiseFulfilledResult<CreateNetworkInviteResponse>
  | PromiseRejectedResult;

const createNetworkInvite = (
  options: Array<CreateNetworkInviteRequest>,
): Promise<Array<PromiseSettledResult>> =>
  Promise.allSettled(
    options.map((value) =>
      HttpClient.createNetworkInvite({
        body: value,
      }),
    ),
  );

export const useCreateNetwokInvite = (queryClient: QueryClient) =>
  useMutation(
    ({ options }: { options: Array<CreateNetworkInviteRequest> }) => createNetworkInvite(options),
    {
      onSuccess: () => {
        queryClient.refetchQueries('listNetworkInvites');
        queryClient.refetchQueries('networks');
      },
    },
  );

export const listNetworkInvitations = (
  options?: ListNetworkInvitesOptionalParams,
): Promise<ListNetworkInvitesResponse> => HttpClient.listNetworkInvites(options);

export const useListNetworkInvites = (params?: ListNetworkInvitesOptionalParams) =>
  useQuery(['listNetworkInvites', params], () => listNetworkInvitations(params), {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 0,
  });

const cancelNetworkInvitation = (
  id: string,
  options?: UpdateNetworkInviteOptionalParams,
): Promise<UpdateNetworkInviteResponse> => HttpClient.deleteNetworkInvite(id, options);

export const useDeleteNetworkInvite = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options?: UpdateNetworkInviteOptionalParams }) =>
      cancelNetworkInvitation(id, options),
    {
      onSuccess: () => {
        queryClient.refetchQueries('listNetworkInvites');
        queryClient.refetchQueries('networks');
      },
    },
  );

const checkValidWholechainIds = (ids: Array<string>) =>
  Promise.allSettled(ids.map((value) => HttpClient.getAccountById(value)));

export const useCheckValidWholechainIds = () =>
  useMutation(({ ids }: { ids: Array<string> }) => checkValidWholechainIds(ids));

const getCompanyNetworkDetails = (
  id: string,
  options?: GetTradePartnerStatisticsByIdOptionalParams,
) => HttpClient.getTradePartnerStatisticsById(id, options);

export const useGetCompanyNetworkDetails = (
  id: string,
  options?: GetTradePartnerStatisticsByIdOptionalParams,
) => useQuery(['companyNetworkInfo', id, options], () => getCompanyNetworkDetails(id, options));

export type PromiseSettledResultDeleteLocation =
  | PromiseFulfilledResult<void>
  | PromiseRejectedResult;

const deleteCompanyFromNetwork = (id: string): Promise<ChangeArchivedTradePartnerResponse> =>
  HttpClient.changeArchivedTradePartner(id, { body: { archived: true } });

export const useDeleteNetworkTradePartners = (queryClient: QueryClient) =>
  useMutation(({ id }: { id: string; documentIds?: string[] }) => deleteCompanyFromNetwork(id), {
    onSuccess: (_data, props) => {
      queryClient.refetchQueries(['notifications']);
      queryClient.refetchQueries('locations');
      queryClient.refetchQueries('mylocations');
      queryClient.refetchQueries('tradePartners');
      queryClient.refetchQueries('networks');
      // Check whether we have documents to invalidate the cache
      if (props.documentIds?.length) {
        props.documentIds.forEach((documentId) => {
          queryClient.invalidateQueries('documents');
          queryClient.invalidateQueries(['document', documentId]);
          queryClient.invalidateQueries(['documentId', documentId]);
        });
      }
    },
  });

const rejectNetworkInvitation = (
  id: string,
  options?: UpdateNetworkInviteOptionalParams,
): Promise<UpdateNetworkInviteResponse> =>
  HttpClient.updateNetworkInvite(id, { body: { status: 'Rejected' }, ...options });

export const useRejectNetworkInvite = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options?: UpdateNetworkInviteOptionalParams }) =>
      rejectNetworkInvitation(id, options),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['notifications']);
        queryClient.refetchQueries('listNetworkInvites');
        queryClient.refetchQueries('networks');
      },
    },
  );

const acceptNetworkInvitation = (
  id: string,
  options?: UpdateNetworkInviteOptionalParams,
): Promise<UpdateNetworkInviteResponse> =>
  HttpClient.updateNetworkInvite(id, { body: { ...options?.body, status: 'Accepted' } });

export const useAcceptNetworkInvite = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options?: UpdateNetworkInviteOptionalParams }) =>
      acceptNetworkInvitation(id, options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('locations');
        queryClient.invalidateQueries('tradePartners');
        queryClient.refetchQueries(['notifications']);
        queryClient.refetchQueries('listNetworkInvites');
        queryClient.refetchQueries('networks');
        queryClient.refetchQueries('tradePartnerDataId');
      },
    },
  );

export const useAllCompany = () =>
  useMutation(({ params }: { params: ListTradePartnersOptionalParams }) =>
    getTradePartners(params),
  );

// DEPRECATED: use createnetworkinvite again
// const ResendNetworkInvitation = (
//   id: string,
//   options?: ResendNetworkInvitationOptionalParams,
// ): Promise<ResendNetworkInvitationResponse> => HttpClient.resendNetworkInvitation(id, options);

// export const useResendNetworkInvite = (queryClient: QueryClient) =>
//   useMutation(
//     ({ id, options }: { id: string; options?: ResendNetworkInvitationOptionalParams }) =>
//       ResendNetworkInvitation(id, options),
//     {
//       onSuccess: () => {
//         queryClient.refetchQueries('listNetworkInvites');
//         queryClient.refetchQueries('networks');
//       },
//     },
//   );

const addTraceabilityDetails = (
  options: SetExternalRegistryDetailsOptionalParams,
): Promise<SetExternalRegistryDetailsResponse> => HttpClient.setExternalRegistryDetails(options);

export const queryDigitalLink = (
  options?: QueryDigitalLinkOptionalParams,
): Promise<QueryDigitalLinkResponse> => HttpClient.queryDigitalLink(options);

export const useAddTraceabilityDetails = (queryClient: QueryClient) =>
  useMutation(
    ({ options }: { options: SetExternalRegistryRequest }) =>
      addTraceabilityDetails({ body: options }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tradePartnerDataId');
        queryClient.invalidateQueries('tradePartners');
      },
    },
  );

const updateTraceabilityDetails = (
  options: UpdateExternalRegistryRequest,
): Promise<UpdateExternalRegistryDetailsResponse> =>
  HttpClient.updateExternalRegistryDetails({
    body: options,
  });

const setInboundApiKey = (
  tradePartnerId: string,
  options?: GetInboundApiKeyOptionalParams,
): Promise<GetInboundApiKeyOperationResponse> =>
  HttpClient.getInboundApiKey(tradePartnerId, options);

export const useUpdateTraceabilityDetails = (queryClient: QueryClient) =>
  useMutation(
    ({ options }: { options: UpdateExternalRegistryRequest }) => updateTraceabilityDetails(options),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tradePartnerDataId');
        queryClient.invalidateQueries('tradePartners');
      },
    },
  );

export const useGenerateAPIKey = () =>
  useMutation(({ id, options }: { id: string; options?: GetInboundApiKeyOptionalParams }) =>
    setInboundApiKey(id, options),
  );

const hasActiveInventoryCheck = (
  params: HasActiveInventoryOptionalParams,
): Promise<HasActiveInventoryOperationResponse> => HttpClient.hasActiveInventory(params);

export const useHasActiveInventoryCheck = () =>
  useMutation(({ params }: { params: HasActiveInventoryOptionalParams }) =>
    hasActiveInventoryCheck(params),
  );

export const useListInvitesMutate = () =>
  useMutation(({ params }: { params: ListNetworkInvitesOptionalParams }) =>
    listNetworkInvitations(params),
  );

export const useRevokeNetworkInvitation = (queryClient: QueryClient) =>
  useMutation(
    ({ id, options }: { id: string; options?: UpdateNetworkInviteOptionalParams }) =>
      rejectNetworkInvitation(id, options),
    {
      onSuccess: () => {
        queryClient.refetchQueries('listNetworkInvites');
        queryClient.refetchQueries('networks');
        queryClient.refetchQueries('tradePartnerDataId');
      },
    },
  );

export const useQueryDigitalLink = () =>
  useMutation(({ params }: { params: QueryDigitalLinkOptionalParams }) => queryDigitalLink(params));

export default useTradePartners;

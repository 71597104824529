import { ArrowLeftOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button, Modal, Space, TablePaginationConfig, Typography } from 'antd';
import { ColumnFilterItem, FilterValue } from 'antd/lib/table/interface';
import GTable from 'components/GTable';
import { useTableRowSelection, useTableSearchFilter } from 'hooks';
import useReportsActions from 'pages/Reports/useReportsActions';
import { FC, useCallback, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useTradePartnerFilters } from 'services/api';
import {
  ListOrphansSuggestionsOptionalParams,
  OrphanSuggestionsResponse,
} from 'services/api/client/src';
import { useLocationFilters } from 'services/api/useLocations';
import OrphanMatchEventDetails from './OrphanMatchEventDetails';
import styles from './index.module.less';
import { ResolveOrphanModalProps } from './typings';
import useListOrphanMatches from './useListOrphanMatches';

const { Title } = Typography;

type ColumnsType = {
  t: TFunction<'pages', 'reports'>;
  onViewDetails: (record: OrphanSuggestionsResponse) => void;
  primarySearchfilter?: ProColumns;
  quantitySearchfilter?: ProColumns;
  coreTradePartners?: Array<ColumnFilterItem> | boolean;
  locationsFilter?: Array<ColumnFilterItem> | boolean;
};

const Columns = ({
  t,
  onViewDetails,
  primarySearchfilter,
  quantitySearchfilter,
  coreTradePartners,
  locationsFilter,
}: ColumnsType): ProColumns<OrphanSuggestionsResponse>[] => [
  {
    title: t('col.primary_id'),
    dataIndex: 'name',
    sorter: false,
    width: 256,
    hideInSetting: true,
    ellipsis: true,
    render: (text, record) => `${record?.productType}: ${record?.lotSerial}`,
    ...primarySearchfilter,
  },
  {
    title: t('col.quantity'),
    dataIndex: 'quantity',
    sorter: false,
    width: 189,
    ellipsis: true,
    render: (text, record) => record?.quantity,
    ...quantitySearchfilter,
  },
  {
    title: t('col.company'),
    dataIndex: 'company',
    width: 219,
    ellipsis: true,
    render: (text, record) => record?.companyName,
    filters: coreTradePartners,
    filterSearch: true,
  },
  {
    title: t('col.location'),
    dataIndex: 'location',
    width: 225,
    ellipsis: true,
    render: (text, record) => record?.locationName,
    filters: locationsFilter,
    filterSearch: true,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    fixed: 'right',
    width: 120,
    render: (text, record) => (
      <Button
        className="actions"
        onClick={() => onViewDetails(record)}
        type="primary"
        ghost
        size="small"
      >
        {t('col.view_details')}
      </Button>
    ),
  },
];

const ResolveOrphanModal: FC<ResolveOrphanModalProps> = ({
  orphanInstanceId,
  modalProps,
  closeModal,
}) => {
  const [matchEvent, setMatchEvent] = useState<OrphanSuggestionsResponse | undefined>();
  const actionRef = useRef<ActionType>();
  const { onResolveOrphan } = useReportsActions();
  const { selectedRowKeys, selectedRows, onRowSelectionChange } = useTableRowSelection();

  const {
    suggestions: potentialMatches,
    isLoading: potentialLoading,
    params,
    setParams,
    totalItems,
  } = useListOrphanMatches({
    orphanInstanceId,
    preferred: false,
  });

  const { suggestions: suggestedMatches, isLoading: suggestedLoading } = useListOrphanMatches({
    orphanInstanceId,
    preferred: true,
  });

  /* locations */
  const locationsFilter = useLocationFilters();
  /* tradepartners */
  const coreTradePartners = useTradePartnerFilters();

  const onCloseModal = useCallback(() => {
    setMatchEvent(undefined);
    onRowSelectionChange([], []);
    closeModal();
  }, [closeModal, onRowSelectionChange]);

  const { t } = useTranslation('pages', { keyPrefix: 'reports' });

  const activeInventoryModal = useCallback(
    (productInstanceId: string) => {
      Modal.warning({
        title: t('orphan_modal.transformation_modal.title'),
        content: t('orphan_modal.transformation_modal.content'),
        cancelText: t('orphan_modal.cancel_button'),
        okText: t('orphan_modal.transformation_modal.continue_button'),
        okButtonProps: { shape: 'round' },
        cancelButtonProps: { shape: 'round' },
        onOk: () => {
          onResolveOrphan(orphanInstanceId, productInstanceId, true);
          onCloseModal();
        },
        centered: true,
        okCancel: true,
      });
    },
    [onCloseModal, onResolveOrphan, orphanInstanceId, t],
  );

  const clickResolve = (needsTransform: boolean, productInstanceId: string) => {
    if (needsTransform) activeInventoryModal(productInstanceId);
    else {
      onResolveOrphan(orphanInstanceId, productInstanceId);
      onCloseModal();
    }
  };

  const onSearch = useCallback(
    (searchParam: ListOrphansSuggestionsOptionalParams) => {
      setParams({
        ...params,
        ...searchParam,
        pageNumber: 1,
      });
    },
    [params, setParams],
  );
  const { filters: primarySearchfilter } = useTableSearchFilter({
    title: t('col.primary_id'),
    onSearch: (text: string) => onSearch({ lotSerial: text }),
  });

  const { filters: quantitySearchfilter } = useTableSearchFilter({
    title: t('col.quantity'),
    onSearch: (text: string) => onSearch({ quantity: Number(text) || 0 }),
  });

  const onTableChange = useCallback(
    (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
      const apiFilters: ListOrphansSuggestionsOptionalParams = {
        tradePartnerIds: filters?.company?.length
          ? filters?.company?.map((val) => String(val))
          : undefined,
        locationIds: filters?.location?.length
          ? filters?.location?.map((val) => String(val))
          : undefined,
      };
      if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: 1,
        });
      } else {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: pagination?.current,
          pageSize: pagination?.pageSize,
        });
      }
    },
    [params, setParams],
  );

  const onViewDetails = useCallback(
    (record: OrphanSuggestionsResponse) => setMatchEvent(record),
    [],
  );

  return (
    <Modal
      className={styles.modal}
      {...modalProps}
      okText={t('orphan_modal.match_button')}
      cancelText={t('orphan_modal.cancel_button')}
      onCancel={onCloseModal}
      onOk={() =>
        clickResolve(selectedRows?.[0]?.needsTransform, selectedRows?.[0]?.productInstanceId)
      }
      width="72%"
      title={
        <div>
          {matchEvent?.lastEventId ? (
            <Space>
              <Button
                type="text"
                size="small"
                onClick={() => {
                  setMatchEvent(undefined);
                }}
              >
                <ArrowLeftOutlined />
              </Button>
              <div>
                {matchEvent?.productType}: {matchEvent?.lotSerial}
              </div>
            </Space>
          ) : (
            t('resolve_orphan')
          )}
        </div>
      }
      okButtonProps={{
        shape: 'round',
        disabled: !selectedRows?.[0],
        hidden: !!matchEvent?.lastEventId,
      }}
      cancelButtonProps={{
        shape: 'round',
        onClick: onCloseModal,
        hidden: !!matchEvent?.lastEventId,
      }}
    >
      {matchEvent?.lastEventId ? (
        <OrphanMatchEventDetails matchEvent={matchEvent} />
      ) : (
        <>
          <GTable<OrphanSuggestionsResponse>
            columns={Columns({
              t,
              onViewDetails,
            })}
            headerTitle={<Title level={5}>{t('orphan_modal.suggested_matches')}</Title>}
            value={suggestedMatches}
            scroll={{ y: '75vh', x: 915 }}
            loading={suggestedLoading}
            rowKey="productInstanceId"
            options={{
              setting: false,
              reload: false,
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: onRowSelectionChange,
              type: 'radio',
            }}
            enableRowSelection
          />
          <br />
          <GTable<OrphanSuggestionsResponse>
            columns={Columns({
              t,
              onViewDetails,
              primarySearchfilter,
              quantitySearchfilter,
              coreTradePartners,
              locationsFilter,
            })}
            headerTitle={<Title level={5}>{t('orphan_modal.potential_matches')}</Title>}
            actionRef={actionRef}
            value={potentialMatches}
            scroll={{ y: '75vh', x: 915 }}
            loading={potentialLoading}
            rowKey="productInstanceId"
            options={{
              setting: false,
              reload: false,
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: onRowSelectionChange,
              type: 'radio',
            }}
            pagination={{
              defaultPageSize: params.pageSize,
              total: totalItems,
            }}
            onTableChange={onTableChange}
            enableRowSelection
          />
        </>
      )}
    </Modal>
  );
};
export default ResolveOrphanModal;

import { DeleteOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Col, message, Row, Space, Typography } from 'antd';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from 'utils';
import { DocumentAttachmentHeaderProps } from './typings';

const DocumentAttachmentHeader: FC<DocumentAttachmentHeaderProps> = ({ form, isView = false }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });
  const { getFieldValue, setFieldsValue } = form;
  const documentURL: string = `${window.location.origin}/view_document/?id=${getFieldValue('id')}`;
  // const documentHash: string = getFieldValue('documentHash');
  const documentAttachment: string | undefined = getFieldValue?.('documentAttachment') || undefined;
  const toggleFullscreen: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    const pdfViewer = document.getElementById('pdf-viewer');
    const fullscreen = document.fullscreenElement;

    if (fullscreen) {
      document.exitFullscreen();
    } else {
      pdfViewer?.requestFullscreen();
    }
  };
  const onDeleteAttachment: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault();
      setFieldsValue({
        documentURL: '',
        documentHash: '',
        documentAttachment: null,
        file: null,
      });
    },
    [setFieldsValue],
  );

  return (
    <Row justify="space-between">
      <Col>
        <Space size={16}>
          <Typography.Text className="ant-pro-form-group-title">
            {t('doc_attachments')}
          </Typography.Text>
          {isView && (
            <Typography.Link
              href={documentURL}
              copyable={{ text: documentURL }}
              onClick={(e) => {
                e.preventDefault();
                copyToClipboard(documentURL);
                message.success(t('url_copied'));
              }}
            >
              {t('url')}
            </Typography.Link>
          )}
          {isView && (
            <Typography.Link href="#fullscreen" onClick={toggleFullscreen}>
              {`${t('view_full')} `} <FullscreenOutlined />
            </Typography.Link>
          )}
        </Space>
      </Col>
      <Col>
        <Space>
          {!isView && documentAttachment && (
            <Typography.Link onClick={onDeleteAttachment}>
              <Space>
                <DeleteOutlined />
                {t('delete_attachments')}
              </Space>
            </Typography.Link>
          )}
        </Space>
      </Col>
    </Row>
  );
};
export default DocumentAttachmentHeader;

import type { ActionType, ListToolBarProps, ProColumns } from '@ant-design/pro-table';
import { Button, TablePaginationConfig } from 'antd';
import classNames from 'classnames';
import GTable from 'components/GTable';
import { UseModalVisibilityReturnType } from 'hooks/useModalVisibility';
import { useEventsStore } from 'pages/Events';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  GetPaginatedOutboundShipmentsOptionalParams,
  OutboundShipmentResponse,
} from 'services/api/client/src';
import { useOutboundShipments } from 'services/api/newShipments';
import { dateLocaleFormat } from 'utils';
import useShipmentStore from '../../Shipments/hooks';
import NoShipmentsFound from '../NoShipmentsFound';
import ShipmentToolbar from '../ShipmentToolbar';
import { OutboundInternalShipmentProps } from '../typings';
import OutboundShipmentTableRowData from './OutboundShipmentTableRowData';
import ShipmentOutboundDetailsTable from './ShipmentOutbountDetailsTable';
import styles from './index.module.less';

type ColumnsType = {
  t?: TFunction<'pages', 'products'>;
  supportModal?: UseModalVisibilityReturnType;
  productNameSearchfilter?: ProColumns;
  onRowActionClick: (actionItemKey: string, record?: OutboundShipmentResponse) => void;
  outboundShipmentType?: string;
  isNetworkShip?: boolean;
  considerAsInbound?: boolean;
};

const columns = ({
  t,
  productNameSearchfilter,
  onRowActionClick,
  outboundShipmentType,
  isNetworkShip,
  considerAsInbound,
}: ColumnsType): Array<ProColumns<OutboundShipmentResponse>> => [
  {
    dataIndex: 'shipmentId',
    hideInTable: true,
  },
  {
    title: t?.('col_product'),
    dataIndex: 'originCompanyName',
    hideInSetting: true,
    render: (text, record) => (
      <OutboundShipmentTableRowData
        record={record}
        hideStatus={outboundShipmentType === 'OtherSystem' ? true : considerAsInbound}
      />
    ),
    ellipsis: true,
    ...productNameSearchfilter,
  },
  {
    dataIndex: 'networkActions',
    width: 180,
    hideInTable: !isNetworkShip,
    render: (text, record) =>
      record?.shipmentId ? (
        <Button
          className="actions"
          type="link"
          onClick={() => onRowActionClick('preview_shipment', record)}
        >
          {considerAsInbound ? t?.('preview_shipment') : t?.('view_shipment')}
        </Button>
      ) : undefined,
  },
  {
    dataIndex: 'actions',
    width: 180,
    hideInTable: isNetworkShip,
    fixed: 'right',
    render: (text, record) =>
      record?.shipmentId ? (
        <Button
          className="actions"
          type="link"
          onClick={() => onRowActionClick('preview_shipment', record)}
        >
          {t?.('view_shipment')}
        </Button>
      ) : undefined,
  },
];

const OutboundInternalShipments: FC<OutboundInternalShipmentProps> = ({
  outboundShipmentType,
  showFilter,
  isNetworkShip,
  outboundShipParam,
  considerAsInbound,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'global_search.shipments_search_table' });
  const { setSelectedShipments } = useShipmentStore();
  const { setAdvancedSettings, setDataEntryMethod, setTemplate } = useEventsStore();
  const navigate = useNavigate();
  const actionRef = useRef<ActionType>();
  const [params, setParams] = useState<GetPaginatedOutboundShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    sortBy: '-eventDate',
    outboundShipmentType,
    ...outboundShipParam,
  });
  const { data, isLoading } = useOutboundShipments(params);

  const expandedRowRender = (row: OutboundShipmentResponse) => (
    <ShipmentOutboundDetailsTable shipmentId={row?.shipmentId} />
  );

  const onTableChange = (pagination: TablePaginationConfig, sorter: any) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };

  const onViewEvent = useCallback(
    (record?: OutboundShipmentResponse) => {
      const productsSearchParams = createSearchParams({
        shipmentId: record?.shipmentId || '',
        shipmentType: outboundShipmentType || '',
        tradePartnerId: record?.destinationTradePartnerId || '',
        locationId: record?.destinationLocationId || '',
        eventDate: dateLocaleFormat(record?.eventDate || new Date()) || '',
      });
      // sets signle shippment details in store
      setSelectedShipments([record as OutboundShipmentResponse]);
      navigate({
        pathname: `/products/${record?.shipmentId}/events/receive`,
        search: record ? `?${productsSearchParams}` : undefined,
      });
    },
    [outboundShipmentType, navigate, setSelectedShipments],
  );

  const onPreviewShip = useCallback(
    (record?: OutboundShipmentResponse) => {
      const previewSearchParams = createSearchParams({
        name: record?.destinationCompanyName || '',
      });
      // sets signle shippment details in store
      setSelectedShipments([record as OutboundShipmentResponse]);
      navigate({
        pathname: isNetworkShip
          ? `../../../network/shipments/outbound/${record?.shipmentId}/eventDetails`
          : `./${record?.shipmentId}/eventDetails`,
        search: record ? `?${previewSearchParams}` : undefined,
      });
    },
    [isNetworkShip, navigate, setSelectedShipments],
  );

  const onRowActionClick = useCallback(
    (actionItemKey: string, actionPayload?: OutboundShipmentResponse) => {
      switch (actionItemKey) {
        case 'receive':
          onViewEvent(actionPayload);
          break;

        /* case 'reject':
          break; */

        case 'preview_shipment':
          onPreviewShip(actionPayload);
          break;

        default:
          break;
      }
      setAdvancedSettings(false);
      setDataEntryMethod('manual');
      setTemplate(undefined);
    },
    [onPreviewShip, onViewEvent, setAdvancedSettings, setDataEntryMethod, setTemplate],
  );

  useEffect(() => {
    if (!showFilter) {
      setParams({
        pageNumber: 1,
        pageSize: 10,
        sortBy: '-eventDate',
        outboundShipmentType,
        ...outboundShipParam,
      });
    }
  }, [outboundShipParam, outboundShipmentType, showFilter]);

  const getFilterParam = useMemo(() => {
    switch (outboundShipmentType) {
      case 'Wholechain':
        return ['Wholechain'];
      case 'Internal':
        return ['Self', 'NonWholechain'];
      default:
        return ['NonWholechain'];
    }
  }, [outboundShipmentType]);

  const toolBarRender: ListToolBarProps = useMemo(
    () =>
      showFilter
        ? {
            multipleLine: true,
            filter: (
              <ShipmentToolbar
                t={t}
                originLocationUrn={params.destinationLocationUrns}
                purchaseOrderNumber={params.purchaseOrderNumber}
                originTradePartnerUrn={params.destinationTradePartnerUrns}
                onLocationChange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    destinationLocationUrns: value,
                  })
                }
                onTradePartnerChange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    destinationTradePartnerUrns: value,
                  })
                }
                onPOchange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    purchaseOrderNumber: value,
                  })
                }
                tradePartnerParam={{
                  types: getFilterParam,
                }}
                locationParam={{
                  tradePartnerStatus: getFilterParam,
                }}
                {...(outboundShipmentType === 'OtherSystem' && {
                  tradePartnerFilterItem: (item) => item?.digitalLinkUrl !== null,
                })}
              />
            ),
          }
        : {},
    [getFilterParam, outboundShipmentType, params, showFilter, t],
  );
  return (
    <GTable<OutboundShipmentResponse>
      actionRef={actionRef}
      showHeader={false}
      rowKey="shipmentId"
      columns={columns({
        t,
        onRowActionClick,
        outboundShipmentType,
        isNetworkShip,
        considerAsInbound,
      })}
      columnsState={{
        persistenceKey: 'pages-products1',
      }}
      loading={isLoading}
      value={data?.results}
      recordCreatorProps={false}
      pagination={{
        defaultPageSize: params.pageSize,
        total: data?.totalItems,
        current: params?.pageNumber,
        showTotal: (total) =>
          total <= 1
            ? t('search_table_pagi_shipment', { total })
            : t('search_table_pagi', { total }),
      }}
      onTableChange={onTableChange}
      options={{
        setting: false,
        reload: false,
      }}
      toolbar={showFilter ? toolBarRender : undefined}
      emptyContainer={
        <NoShipmentsFound
          t={t}
          params={params}
          shipmentType={outboundShipmentType || ''}
          isInbound={false}
        />
      }
      {...(isNetworkShip && { className: classNames(styles.table) })}
      scroll={isNetworkShip ? { x: 1025, y: 160 } : { x: 1025 }}
      {...(!isNetworkShip && { expandable: { expandedRowRender, defaultExpandedRowKeys: ['0'] } })}
    />
  );
};

export default React.memo(OutboundInternalShipments);

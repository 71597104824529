import { scopes } from 'config/authConfig';
import { jwtDecode } from 'jwt-decode';
import { msalInstance } from './index';

export default class TokenCredential {
  // TODO: implement GetTokenOptions
  /* eslint-disable class-methods-use-this */
  async getToken(): Promise<{
    token: string;
    expiresOnTimestamp: number;
  } | null> {
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0] || null;
    try {
      if (account) {
        const tokenResponse = await msalInstance.acquireTokenSilent({ scopes, account });
        const decoded = jwtDecode<{ exp: string }>(tokenResponse.accessToken);
        return {
          token: tokenResponse.accessToken,
          expiresOnTimestamp: Number(decoded.exp),
        };
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    return { token: '', expiresOnTimestamp: 0 };
  }
}

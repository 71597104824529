import ProCard from '@ant-design/pro-card';
import { Segmented, Tabs } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import classNames from 'classnames';
import React, { FC, useMemo, useState } from 'react';
import { useListNetworkInvites } from 'services/api';
import { useCountInboundShipmentsShipments } from 'services/api/newShipments';
import InboundOtherSystemsShipments from './InboundOtherSystemsShipments';
import InboundWholechainShipments from './InboundWholechainShipments';
import Invites from './Invites';
import TabTitle from './TabTitle';
import styles from './index.module.less';
import { ShipmentInviteProps } from './typings';

const sections = ['inbound_shipments', 'invites'];
const ShipmentInvite: FC<ShipmentInviteProps> = ({ t }) => {
  const [, setCurrentTab] = useState(sections[0]);

  const { data: dataProductCounts, isLoading } = useCountInboundShipmentsShipments({
    status: ['Pending'],
  });
  const totalItemsWholechain = dataProductCounts?.wholechain || 0;
  const totalItemsOtherSystems = dataProductCounts?.otherSystems || 0;

  const { data: invitations, isLoading: inviteLoading } = useListNetworkInvites({
    incoming: true,
    status: ['Pending'],
  });
  const totalInvites = useMemo(() => invitations?.totalItems || 0, [invitations]);
  const onTabChange = (activeKey: string) => {
    setCurrentTab(activeKey);
  };
  const shipmentSections = ['Wholechain', 'Other Systems'];
  const [shipmentSection, setShipmentSection] = useState<SegmentedValue>(shipmentSections[0]);
  const onShipmentTabChange = (activeKey: SegmentedValue) => {
    setShipmentSection(activeKey);
  };
  return (
    <ProCard loading={isLoading || inviteLoading} className={styles.cardstyle}>
      <Tabs defaultActiveKey={sections[0]} onChange={onTabChange}>
        <Tabs.TabPane
          tab={
            <TabTitle
              title={t('shipment_invite.shipment_title')}
              count={totalItemsWholechain + totalItemsOtherSystems}
            />
          }
          key={sections[0]}
        >
          <Segmented
            defaultValue="Wholechain"
            options={shipmentSections}
            value={shipmentSection}
            onChange={onShipmentTabChange}
            size="small"
          />
          {shipmentSection && shipmentSection === shipmentSections[0] ? (
            <div
              className={classNames(styles.inboundcontent, {
                [styles.overflowhidden]: totalItemsWholechain === 0,
              })}
            >
              <InboundWholechainShipments t={t} />
            </div>
          ) : null}
          {shipmentSection && shipmentSection === shipmentSections[1] ? (
            <div
              className={classNames(styles.inboundcontent, {
                [styles.overflowhidden]: totalItemsOtherSystems === 0,
              })}
            >
              <InboundOtherSystemsShipments t={t} />
            </div>
          ) : null}
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<TabTitle title={t('shipment_invite.invites_title')} count={totalInvites} />}
          key={sections[1]}
        >
          <div className={styles.invitecontent} id="invitetab">
            <Invites t={t} />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </ProCard>
  );
};
export default React.memo(ShipmentInvite);

import { useQuery } from 'react-query';
import { ListDispositionsOptionalParams } from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

const getDispositions = (params?: ListDispositionsOptionalParams) =>
  HttpClient.listDispositions(params);

const useDispositions = (params?: ListDispositionsOptionalParams) =>
  useQuery(['dispositions', params], () => getDispositions(params), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export default useDispositions;

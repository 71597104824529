// @ts-nocheck
import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { FormInstance } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import {
  BizStepSelect,
  DispositionSelect,
  LocationSelect,
  ProductLotSelect,
  ProductSelect,
  TimezoneSelect,
  TradePartnerSelect,
} from 'components/GSelect';
import GTable from 'components/GTable';
import { Moment } from 'moment';
import { CommissionDataItem, CommissionTableProps } from 'pages/Events';
import { useEventActions } from 'pages/Events/components';
import { FC, Key, useCallback, useEffect, useMemo, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useTemplateById } from 'services/api';
import { InventoryResponse, TemplateAttributeResponse } from 'services/api/client/src';
import { DefaultValue, fieldTypes } from 'utils';

type ColumnsType = {
  templateFields?: TemplateAttributeResponse[];
  columnWidth?: string | number;
  pageKey?: string;
  productId?: string;
  t?: TFunction<'pages', 'events.csv'>;
  disabledDate?: (date: Moment) => boolean;
  disabledTime?: RangePickerProps['disabledTime'];
  onChangeDate?: (form: FormInstance<any>, value: string) => void;
  lastEventDate?: string;
  defaultValues?: DefaultValue;
  isSerial?: boolean;
};
const Columns = ({
  defaultValues,
  templateFields,
  columnWidth,
  pageKey,
  productId,
  isSerial,
  disabledDate,
  disabledTime,
  onChangeDate,
  t,
}: ColumnsType): Array<ProColumns<CommissionDataItem>> => [
  {
    title: 'Product',
    dataIndex: 'productId',
    fieldProps: {
      placeholder: 'Product',
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please input product',
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
    renderFormItem: () => <ProductSelect size="small" />,
  },
  {
    title: !isSerial ? 'Lot ID' : 'Serial ID',
    dataIndex: 'lotID',
    fieldProps: {
      placeholder: 'Lot ID',
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please input lot ID',
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: pageKey === 'observe' || pageKey === 'decomission' || pageKey === 'ship',
  },
  {
    title: !isSerial ? 'Lot ID' : 'Serial ID',
    dataIndex: 'lotID',
    fieldProps: {
      placeholder: 'Lot ID',
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please input lot ID',
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey }, form) => {
      const { setFieldsValue } = form;

      return (
        <ProductLotSelect
          productId={productId}
          size="small"
          onChange={(value, option) => {
            // @ts-ignore
            const item: InventoryResponse = option.itemProps;
            const instance = item?.productInstance;

            setFieldsValue({
              [String(recordKey)]: {
                quantity: instance?.quantity || 0,
              },
            });
          }}
        />
      );
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: !(pageKey === 'observe' || pageKey === 'ship'),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: {
      placeholder: 'Quantity',
      stringMode: true,
      size: 'small',
      type: 'number',
      className: 'full-width',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please input lot quantity',
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: pageKey === 'decomission' || isSerial,
  },
  {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
    fieldProps: {
      placeholder: 'Purchase Order',
      size: 'small',
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: pageKey === 'decomission',
  },
  {
    title: 'Logistic Unit ID/SSCC',
    dataIndex: 'scss',
    fieldProps: {
      placeholder: 'Logistic Unit ID/SSCC ',
      size: 'small',
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: pageKey === 'decomission',
  },
  {
    title: 'Date',
    valueType: 'date',
    dataIndex: 'date',
    fieldProps: (form) => ({
      placeholder: 'Date',
      size: 'small',
      className: 'full-width',
      disabledDate,
      onChange: (value: string) => onChangeDate?.(form, value),
    }),
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please input date',
        },
        {
          type: 'date',
          message: 'Please input a valid date',
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: pageKey === 'decomission',
  },
  {
    title: 'Time',
    valueType: 'time',
    dataIndex: 'time',
    initialValue: '12:00:00',
    fieldProps: {
      placeholder: 'Time',
      size: 'small',
      className: 'full-width',
      disabledTime,
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please input time',
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
    hideInTable: pageKey === 'decomission',
  },
  {
    title: 'Location',
    dataIndex: 'location',
    fieldProps: {
      placeholder: 'Location',
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please input location',
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: () => (
      <LocationSelect
        initParams={{
          tradePartnerStatus: ['Self', 'NonWholechain'],
        }}
        placeholder="Location"
        size="small"
      />
    ),
    ellipsis: true,
    width: columnWidth,
  },
  {
    title: 'Ship To Company',
    dataIndex: 'shippingCompany',
    fieldProps: {
      placeholder: 'Shipping Company',
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please input ship to company',
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: () => <TradePartnerSelect placeholder="Ship To Company" size="small" />,
    ellipsis: true,
    hideInTable: pageKey !== 'ship',
    width: columnWidth,
  },
  {
    title: 'Ship To Address',
    dataIndex: 'shippingAddress',
    fieldProps: {
      placeholder: 'Ship To Address',
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Please input ship to address',
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: () => (
      <LocationSelect
        initParams={{
          tradePartnerStatus: ['Self', 'NonWholechain'],
        }}
        placeholder="Ship To Address"
        size="small"
      />
    ),
    ellipsis: true,
    hideInTable: pageKey !== 'ship',
    width: columnWidth,
  },
  {
    title: t?.('time_zone_title'),
    dataIndex: 'timeZone',
    valueType: 'select',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('time_zone_req'),
        },
        {
          whitespace: true,
          message: t?.('time_zone_req'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('time_zone_placeholder'),
      size: 'small',
    },
    renderFormItem: () => (
      <TimezoneSelect placeholder={t?.('time_zone_placeholder')} size="small" />
    ),
    width: columnWidth,
    hideInTable: pageKey === 'decomission',
  },
  {
    title: t?.('bizstep_title'),
    dataIndex: 'bizStep',
    valueType: 'select',
    renderFormItem: () => <BizStepSelect size="small" />,
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('bizstep_req'),
        },
        {
          whitespace: true,
          message: t?.('bizstep_req'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('bizstep_placeholder'),
      size: 'small',
    },
    tooltip: {
      icon: <InfoCircleOutlined />,
      title: t?.('bizstep_tooltip'),
      key: null,
      type: '',
      props: null,
    },
    width: columnWidth,
    hideInTable: pageKey === 'decomission',
  },
  {
    title: t?.('disposition_title'),
    dataIndex: 'disposition',
    initialValue:
      pageKey === 'Observe'
        ? 'urn:epcglobal:cbv:disp:in_progress'
        : 'urn:epcglobal:cbv:disp:active',
    valueType: 'select',
    fieldProps: {
      placeholder: t?.('disposition_title'),
      size: 'small',
    },
    renderFormItem: () => <DispositionSelect size="small" />,
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('disposition_req'),
        },
        {
          whitespace: true,
          message: t?.('disposition_req'),
        },
      ],
    },

    tooltip: {
      icon: <InfoCircleOutlined />,
      title: t?.('disposition_tooltip'),
      key: null,
      type: '',
      props: null,
    },
    width: columnWidth,
    hideInTable: pageKey === 'decomission',
  },
  ...(templateFields?.map((item) => ({
    title: item?.name || '',
    dataIndex: item?.propertyName || '',
    valueType: fieldTypes[item?.fieldType || 'Text'].valueType,
    initialValue: defaultValues?.[item?.propertyName || ''],
    fieldProps: {
      ...fieldTypes[item?.fieldType || 'Text'].fieldProps,
      placeholder: item?.name || '',
      size: 'small',
      options: item?.valueOptions,
    },
    formItemProps: {
      rules: [
        {
          message: 'Please input a valid value',
          validator: (_: any, value: string) => {
            if (!item?.valueOptions?.length) {
              return Promise.resolve();
            }
            if (item?.valueOptions?.includes(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('Please input a valid value'));
          },
        },
      ],
    },
    ellipsis: true,
    width: item.fieldType !== 'RadioButton' ? columnWidth : 2 * Number(columnWidth),
  })) || []),
  {
    dataIndex: 'actions',
    valueType: 'option',
    render: () => null,
    width: 50,
    fixed: 'right',
  },
];

const CSVTable: FC<CommissionTableProps> = ({
  form,
  editableTableFormRef,
  defaultValues,
  templateId,
  tab,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.csv' });
  const { productId, disabledDate, disabledTime, isSerial, onChangeDate } = useEventActions();
  const tabKey = String(tab)?.split('.')?.[1];
  const pageKey = String(tabKey)?.trim().toLowerCase();
  const { getFieldValue, setFieldsValue } = form;

  const columnWidth = 100;
  const { data: template } = useTemplateById(templateId || '');
  const totalColumnWidth = useMemo(
    () => columnWidth * (template?.templateFields?.length || 0) + 20 * columnWidth,
    [template],
  );
  const products: Array<CommissionDataItem> = useMemo(
    () => getFieldValue?.('csvData')?.[tab || 'Commission'] || [],
    [getFieldValue, tab],
  );

  const sactionRef = useRef<ActionType>();
  useEffect?.(() => {
    if (editableTableFormRef?.current) {
      setTimeout?.(() => {
        editableTableFormRef?.current?.validateFields();
      }, 500);
    }
  }, [products, editableTableFormRef]);

  const setProducts = useCallback(
    (value: Array<CommissionDataItem>) => {
      setFieldsValue({
        csvData: {
          [tab || 'Commission']: value,
        },
      });
    },
    [setFieldsValue, tab],
  );
  const onDelRow = async (key: RecordKey, actionPayload?: CommissionDataItem) => {
    if (actionPayload) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    }
  };
  return (
    <GTable<CommissionDataItem>
      key="sactionRef"
      editableFormRef={editableTableFormRef}
      actionRef={sactionRef}
      columns={Columns({
        defaultValues,
        templateFields: template?.templateFields || [],
        columnWidth,
        pageKey,
        productId,
        disabledDate,
        disabledTime,
        onChangeDate,
        isSerial,
        t,
      })}
      editable={{
        type: 'multiple',
        onlyAddOneLineAlertMessage: undefined,
        editableKeys: products.map((p) => p.id as Key),
        onDelete: onDelRow,
        onValuesChange: (record, recordList) => {
          setProducts(recordList);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: false,
        cancel: false,
        delete: true,
      }}
      recordCreatorProps={false}
      addBtnText="Add Row"
      onAddRecordClick={() => {
        sactionRef.current?.addEditRecord?.({
          id: Date.now().toString(),
        });
      }}
      value={products}
      scroll={{ y: '50vh', x: totalColumnWidth }}
      pagination={{
        pageSize: 10,
        showSizeChanger: true,
      }}
    />
  );
};
export default CSVTable;

import { create } from 'zustand';
import { AttributeStore } from '../typings';

const useAttributeStore = create<AttributeStore>((set) => ({
  templateMode: 'view',
  attribute: {
    id: '',
    name: '',
    required: false,
    dataAttribute: '',
    fieldType: '',
    valueOptions: [],
  },
  setAttribute: (attribute) => set(() => ({ attribute })),
  setTemplateMode: (templateMode) => set(() => ({ templateMode })),
}));

export default useAttributeStore;

import { ProColumns } from '@ant-design/pro-table';
import { EditableProTableProps } from '@ant-design/pro-table/lib/components/EditableTable';
import { useTableSearchFilter } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProductInstanceQuantities } from 'services/api';
import { GetPaginatedProductInventoryOptionalParams } from 'services/api/client/src';
import { useLocationFilters } from 'services/api/useLocations';
import { mapInvItem } from 'utils';
import { CoreEventTypes, ProductInventoryItem } from './typings';
import useProductStore from './useProductStore';

const eventTypes = ({ t }: CoreEventTypes): ProColumns['filters'] => [
  { value: 'COMMISSION', text: t('actions.commission') },
  { value: 'OBSERVE', text: t('actions.observe') },
  { value: 'SHIP', text: t('actions.ship') },
  { value: 'TRANSFORM', text: t('actions.transform') },
  { value: 'AGGREGATION', text: t('actions.aggregate') },
  { value: 'DECOMMISSION', text: t('actions.decommission') },
  { value: 'DISAGGREGATION', text: t('actions.disaggregate') },
  { value: 'RECEIVE', text: t('actions.receive') },
];

type ReactText = string | number;
type Sorter = {
  order: 'ascend' | 'descend';
  field?: string;
};

/**
 * Custom hook to handle product instance filters and fetching.
 * @param productId The ID of the product.
 * @param initParams Initial parameters for fetching product instances.
 */
const useProductInstanceFilters = (
  productId?: string,
  initParams?: GetPaginatedProductInventoryOptionalParams,
) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.current_inventory' });
  const { setParamsStore } = useProductStore();

  const [params, setParams] = useState<GetPaginatedProductInventoryOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    ...initParams,
  });
  const {
    data: dataInstances,
    isLoading,
    refetch,
  } = useProductInstanceQuantities(productId || '', params);

  useEffect(() => {
    if (params) {
      setParamsStore?.(params);
    }
  }, [params, setParamsStore]);

  // useProductInstances(productId || '', {
  //   isHistory: false,
  //   pageSize: 9999,
  // });
  const totalItems = useMemo(() => dataInstances?.totalItems || 0, [dataInstances]);
  /* locations */
  const coreLocations = useLocationFilters();
  /* event types */
  const coreEventTypes = useMemo(() => eventTypes({ t }), [t]);

  const netItemCount = useMemo(() => dataInstances?.inventory || 0, [dataInstances]);
  const records: Array<ProductInventoryItem> = useMemo(
    () => dataInstances?.results?.map(mapInvItem) || [],
    [dataInstances],
  );
  const onSearch = (text: string, key: string) => {
    setParams({
      ...params,
      pageNumber: 1,
      [key]: text,
    });
  };
  const { filters: primaryIdSearchfilter } = useTableSearchFilter({
    title: t('tbl_primary_id_filter_placeholder'),
    onSearch: (text) => onSearch(text, 'lotSerial'),
  });
  const { filters: poSearchfilter } = useTableSearchFilter({
    title: t('tbl_po_filter_placeholder'),
    onSearch: (text) => onSearch(text, 'purchaseOrder'),
  });
  const getSortField = (sorter: Sorter) => {
    switch (sorter.field) {
      case 'eventName':
        return 'lastEvent';
      case 'eventDate':
        return 'lastEventDate';

      default:
        return sorter.field;
    }
  };
  const onTableChange: EditableProTableProps<ProductInventoryItem, ReactText[]>['onTableChange'] = (
    pagination,
    filters,
    sorter: any,
  ) => {
    const apiFilters: GetPaginatedProductInventoryOptionalParams = {
      locationIds: filters?.locationAddress?.length
        ? (filters.locationAddress as string[])
        : undefined,
      // @ts-ignore
      eventTypes: filters?.eventName?.length ? (filters.eventName as string[]) : undefined,
    };
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        ...apiFilters,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${getSortField(sorter)}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        ...apiFilters,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };
  return {
    params,
    setParams,
    totalItems,
    netItemCount,
    isLoading,
    records,
    coreLocations,
    coreEventTypes,
    onTableChange,
    refetch,
    filters: [primaryIdSearchfilter, poSearchfilter],
    getSortField,
  };
};
export default useProductInstanceFilters;

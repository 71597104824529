import { ColProps } from 'antd';
import { CSSProperties } from 'react';

export const dropdownStyle: CSSProperties = { minWidth: '300px' };

export const colPropsAdd: ColProps = { xs: 24, sm: 6, md: 4, lg: 4 };

// eslint-disable-next-line no-shadow
enum GlobalStyleKeys {
  ts1 = 'ts1',
  ts2 = 'ts2',
  gridcenter = 'gridcenter',
  w100 = 'w100',
  mt8 = 'mt8',
  z4 = 'z4',
  fleft = 'fleft',
  mapContainer = 'mapContainer',
  dmapContainer = 'dmapContainer',
  mlr8 = 'mlr8',
  cursorGrab = 'cursorGrab',
}

type GlobalStyle = {
  [key in GlobalStyleKeys]: CSSProperties;
};
export const globalStyles: GlobalStyle = {
  ts1: { width: '500px', margin: '20px' },
  ts2: { padding: 0, margin: 0 },
  gridcenter: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  w100: { width: '100%' },
  mt8: { marginTop: 8 },
  z4: { zIndex: 4 },
  fleft: { float: 'left' },
  mapContainer: { height: '80vh' },
  dmapContainer: { height: '50vh', minHeight: '300px' },
  mlr8: { marginLeft: 8, marginRight: 8 },
  cursorGrab: { cursor: 'grab' },
};

import { Button, Empty, Typography } from 'antd';
import { productsEmptySvg } from 'assets';
import useListLocation from 'hooks/useListLocation';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyNewStateProps } from './typings';

const EmptyNewStateShipments: FC<EmptyNewStateProps> = ({ t, isWholechain }) => {
  const navigate = useNavigate();
  const { data: locations } = useListLocation();
  const totalLocations = locations?.length || 0;
  const getNoDataFoundText = () =>
    isWholechain
      ? t?.('shipment_invite.empty_wholechain_shipment_title')
      : t?.('shipment_invite.empty_other_systems_shipment_title');
  return (
    <Empty
      className="empty-state"
      image={productsEmptySvg}
      description={
        <Typography.Text type="secondary">
          {totalLocations > 0 ? getNoDataFoundText() : t?.('shipment_invite.empty_title')}
        </Typography.Text>
      }
    >
      {!(totalLocations > 0) && (
        <Button
          type="primary"
          shape="round"
          ghost
          onClick={() => navigate('../network/add_location')}
        >
          {t?.('shipment_invite.empty_add_location')}
        </Button>
      )}
    </Empty>
  );
};
export default EmptyNewStateShipments;

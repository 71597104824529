import { create } from 'zustand';
import { UseQuantities } from './typings';

const useQuantities = create<UseQuantities>((set) => ({
  instances: [],
  selectedInstances: [],
  createInstance: (instance) => {
    set((state) => ({
      instances: [...state.instances, instance],
    }));
  },
  updateInstance: (instance) => {
    set((state) => ({
      instances: state.instances.map((i) => {
        if (i?.id === instance?.id) {
          return instance;
        }
        return i;
      }),
    }));
  },
  deleteInstance: (id) => {
    set((state) => ({
      instances: state.instances.filter((i) => i.id !== id),
    }));
  },
  truncateInstances: () => {
    set(() => ({
      instances: [],
    }));
  },
  setSelectedInstances: (instances) => {
    set(() => ({
      selectedInstances: instances,
    }));
  },
}));

export default useQuantities;

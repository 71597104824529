import { SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useDispositions from 'services/api/useDispositions';
import { getProductsT } from 'utils';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

const DispositionSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const { data, isLoading } = useDispositions();
  const { t } = useTranslation('pages', { keyPrefix: 'select' });

  // we create here the list with the shape the select is expecting for
  const options: Array<DefaultOptionType> =
    data?.results?.map((item) => {
      const urns = item?.urn?.split(':');
      const step = urns?.[(urns?.length || 0) - 1]?.replace('/', '_');
      const isCBV = urns?.[2] === 'cbv';
      const type = isCBV ? 'cbv' : 'gdst';

      const label = `${getProductsT(step)} (${getProductsT(type)})`;

      return {
        label,
        value: String(item.urn),
        itemProps: item,
      };
    }) || [];

  const fetchData: FetchDataFn<ValueType> = useCallback(() => {}, []);

  return (
    <GSelect<ValueType, OptionType>
      placeholder={t('disposition_placeholder')}
      {...props}
      loading={isLoading}
      options={options as OptionType[]}
      fetchData={fetchData}
      allowFilterOption
      optionFilterProp="label"
    />
  );
};

export default React.memo(DispositionSelect);

import { ProListProps } from '@ant-design/pro-list';
import { NotificationItem } from 'components';
import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useInfiniteNotifications, useReadNotifications } from 'services/api';
import {
  ChangeToReadNotificationRequest,
  ListNotificationsOptionalParams,
} from 'services/api/client/src';

const useNotificationFilters = (initParams?: ListNotificationsOptionalParams) => {
  const queryClient = useQueryClient();
  const readNotification = useReadNotifications(queryClient);

  const [params, setParams] = useState<ListNotificationsOptionalParams>({
    pageNumber: 1,
    pageSize: 5,
    type: 'Notification',
    ...initParams,
  });
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useInfiniteNotifications(params);

  const records: Array<NotificationItem> = useMemo(
    () =>
      data?.pages?.reduce(
        (acc, page) => [...acc, ...(page?.results || [])],
        [] as Array<NotificationItem>,
      ) || [],
    [data],
  );

  const disableSeeMore = useMemo(() => !hasNextPage || isLoading, [hasNextPage, isLoading]);
  const disableClear = useMemo(() => (data?.pages?.[0]?.totalUnreadAmount || 0) === 0, [data]);

  const onClear = useCallback(async () => {
    records?.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.read = true;
    });

    const request: ChangeToReadNotificationRequest = {
      notificationIds: [],
    };
    await readNotification.mutateAsync(request);
  }, [records, readNotification]);

  const onViewMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const onClickItem = useCallback(
    async (record: NotificationItem) => {
      // eslint-disable-next-line no-param-reassign
      record.read = true;
      const reqData: ChangeToReadNotificationRequest = {
        notificationIds: [record?.id || ''],
      };
      await readNotification.mutateAsync(reqData);
    },
    [readNotification],
  );

  const onItem: ProListProps<NotificationItem>['onItem'] = (record) => ({
    onClick: () => onClickItem(record),
  });

  return {
    params,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    records,
    disableSeeMore,
    disableClear,
    setParams,
    fetchNextPage,
    onClear,
    onViewMore,
    onItem,
  };
};
export default useNotificationFilters;

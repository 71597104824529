import { EditableProTableProps } from '@ant-design/pro-table/lib/components/EditableTable';
import { useTableSearchFilter } from 'hooks';
import { ShipNewProductItem } from 'pages/Events';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetInboundShipmentDetailsOptionalParams } from 'services/api/client/src';
import { useGetExternalShipmentDetailsById } from 'services/api/newShipments';
import { mapNewExternalShipItem } from 'utils';

type ReactText = string | number;
type Sorter = {
  order: 'ascend' | 'descend';
  field?: string;
};

/**
 * Custom hook to handle shipment instance filters and fetching.
 * @param shipmentId The ID of the shipment.
 * @param initParams Initial parameters for fetching shipment instances.
 */
const useExternalShipmentInstanceFilters = (
  shipmentId?: string,
  initParams?: GetInboundShipmentDetailsOptionalParams,
) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound.tbl' });

  const [params, setParams] = useState<GetInboundShipmentDetailsOptionalParams>({
    ...initParams,
  });
  const {
    data: dataInstances,
    isLoading,
    refetch,
  } = useGetExternalShipmentDetailsById([shipmentId || ''], {
    ...params,
  });
  const records: Array<ShipNewProductItem> = useMemo(
    () => mapNewExternalShipItem(dataInstances?.[0] || {}) || [],
    [dataInstances],
  );
  const totalItems = useMemo(() => records?.length || 0, [records]);
  /* locations */
  const coreProducts = useMemo(
    () =>
      records?.map((option) => ({
        text: option?.productName || '',
        value: option?.productId || '',
      })) || [],
    [records],
  );

  const netItemCount = useMemo(() => records?.length || 0, [records]);

  const onSearch = (text: string, key: string) => {
    setParams({
      ...params,
      [key]: text,
    });
  };
  const { filters: primaryIdSearchfilter } = useTableSearchFilter({
    title: t?.('col_primary_id_title'),
    onSearch: (text) => onSearch(text, 'primaryId'),
  });
  const { filters: gtinSearchfilter } = useTableSearchFilter({
    title: t('col_gtin_title'),
    onSearch: (text) => onSearch(text, 'gtin'),
  });
  const getSortField = (sorter: Sorter) => {
    switch (sorter.field) {
      case 'eventName':
        return 'lastEvent';
      case 'eventDate':
        return 'lastEventDate';

      default:
        return sorter.field;
    }
  };
  const onTableChange: EditableProTableProps<ShipNewProductItem, ReactText[]>['onTableChange'] = (
    filters,
    sorter: any,
  ) => {
    const apiFilters: GetInboundShipmentDetailsOptionalParams = {};
    setParams({
      ...params,
      ...apiFilters,
      sortBy: sorter?.field
        ? `${sorter.order === 'ascend' ? '+' : '-'}${getSortField(sorter)}`
        : undefined,
    });
  };
  return {
    params,
    setParams,
    totalItems,
    netItemCount,
    isLoading,
    records,
    coreProducts,
    onTableChange,
    refetch,
    filters: [primaryIdSearchfilter, gtinSearchfilter],
    getSortField,
  };
};
export default useExternalShipmentInstanceFilters;

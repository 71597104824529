import { Empty, Select, SelectProps, Skeleton, Spin } from 'antd';
import classNames from 'classnames';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import { GSelectProps } from './typings';

const GSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  allowSearch = true,
  allowFilterOption,
  className,
  fetchData,
  loading,
  onChange,
  ...props
}: GSelectProps<ValueType> & SelectProps<ValueType, OptionType>) => {
  const valueRef = useRef(props.value);
  const { t } = useTranslation('pages', { keyPrefix: 'select.gselect' });
  const [initLoading, setInitLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchData({ initialValue: valueRef.current });
    setInitLoading(true);
  }, [fetchData]);

  useEffect(() => {
    // use intial loading of the select
    if (initLoading && !loading) {
      setInitLoading(false);
    }
  }, [initLoading, loading]);

  const handleOnChange = (value: ValueType, option: OptionType | OptionType[]) => {
    if (!value) {
      fetchData({});
    }

    if (onChange) {
      onChange(value, option);
    }
  };

  return initLoading && loading ? (
    <Skeleton.Input size="small" active className={styles.skcontainer} />
  ) : (
    <Select<ValueType, OptionType>
      allowClear
      {...props}
      className={classNames(styles.container, className)}
      filterOption={allowFilterOption}
      notFoundContent={
        loading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_data')} />
        )
      }
      onChange={handleOnChange}
      onSearch={allowSearch ? (searchValue) => fetchData({ filterText: searchValue }) : undefined}
      showSearch
      loading={loading}
    />
  );
};

export default GSelect;

import { ProColumns } from '@ant-design/pro-table';
import GTable from 'components/GTable';
import React, { FC, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { getShipDetailsAttributes } from 'utils';
import styles from '../index.module.less';
import { DetailsContentProps, EventItem } from '../typings';
import DataTitle from './DataTitle';

type CType = {
  t?: TFunction<'pages', 'products.event_details'>;
};
const Columns = ({ t }: CType): Array<ProColumns<EventItem>> => [
  {
    title: t?.('attributes_col_title'),
    dataIndex: 'attribute',
    className: 'attribute-cell',
    fixed: 'left',
    ellipsis: true,
  },
  {
    title: t?.('values_col_title'),
    dataIndex: 'values',
    ellipsis: true,
    copyable: true,
  },
];

const DetailsContent: FC<DetailsContentProps> = ({ activeEvent, productInstance }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const isSerial = useMemo(
    () => activeEvent?.productInstances?.[0]?.productIdentifierType === 'Serial',
    [activeEvent],
  );
  const identifier = useMemo(
    () => (isSerial ? t?.('serial_title') : t?.('lot_title')),
    [isSerial, t],
  );

  const data: Array<EventItem> = useMemo(
    () => getShipDetailsAttributes(activeEvent),
    [activeEvent],
  );
  return (
    <>
      <DataTitle
        activeEvent={activeEvent}
        productInstance={productInstance}
        isSerial={isSerial}
        identifier={identifier}
        t={t}
      />
      <GTable<EventItem>
        className={styles.etable}
        columns={Columns({ t })}
        options={{
          setting: false,
          reload: false,
        }}
        value={data}
        scroll={{ y: '55vh' }}
      />
    </>
  );
};
export default React.memo(DetailsContent);

import GMap from 'components/GMap';
import { MarkerItemProps } from 'components/GMap/typings';
import { renderInfoWindow } from 'pages/Network/hook';
import { FC } from 'react';
import { LocationResponse } from 'services/api/client/src';

const defaultStyle = {
  width: '100%',
  height: '330px',
};

interface MapProps {
  companyLocations: Array<LocationResponse>;
}

const SingleMapLocation: FC<MapProps> = ({ companyLocations }) => {
  const markers: Array<MarkerItemProps> =
    companyLocations?.map((el) => ({
      id: el?.id || '',
      position: {
        lat: Number(el?.address?.geoCoordinates?.latitude) || 47.116386,
        lng: Number(el?.address?.geoCoordinates?.longitude) || -101.299591,
      },
      title: el.tradePartnerName,
      companyId: el.tradePartnerId,
      shortDesc: el.name,
      longDesc:
        el.address?.displayAddress ||
        `${el?.address?.geoCoordinates?.latitude},${el?.address?.geoCoordinates?.longitude}`,
      isMyLocation: el?.networkStatus === 'Self',
    })) || [];

  return (
    <GMap
      containerStyle={defaultStyle}
      markers={markers}
      renderInfoWindow={renderInfoWindow}
      isRestrict={false}
    />
  );
};

export default SingleMapLocation;

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import React, { FC } from 'react';
import { FooterProps } from './typings';

const { Text } = Typography;
const Footer: FC<FooterProps> = ({ isSubmitting, onSubmit, t }) => (
  <Row justify="space-between" align="middle" gutter={[16, 16]}>
    <Col>
      <Text type="secondary">
        <span>
          <InfoCircleOutlined /> &nbsp; {t?.('footer_title')}
        </span>
      </Text>
    </Col>

    <Col>
      <Space>
        <Button shape="round" type="primary" onClick={onSubmit} loading={isSubmitting}>
          {t?.('log_event_title')}
        </Button>
      </Space>
    </Col>
  </Row>
);

export default React.memo(Footer);

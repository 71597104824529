import { GMap } from 'components';
import { renderInfoWindow, useNetworkActions } from 'pages/Network/hook';
import React from 'react';
import { globalStyles } from 'utils';

const Map = () => {
  const { markers } = useNetworkActions({
    pageSize: 9999,
  });

  return markers && markers.length > 0 ? (
    <GMap
      markers={markers}
      renderInfoWindow={renderInfoWindow}
      isRestrict
      containerStyle={globalStyles.dmapContainer}
    />
  ) : (
    <GMap containerStyle={globalStyles.dmapContainer} />
  );
};
export default React.memo(Map);

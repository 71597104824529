import queryString from 'qs';
import { WebAPIClient } from 'services/api/client/src';
import { getVariableFromEnv } from 'utils/config';
import TokenCredential from './TokenCredential';
import { FetchRequestProps } from './typings';

const host = getVariableFromEnv('API_HOST');
const credential = new TokenCredential();
const httpClient = new WebAPIClient(credential, host, {
  retryOptions: { maxRetries: 1 },
});

export const fetchRequest = async ({
  path,
  params,
  method = 'GET',
  body,
  isUrl,
}: FetchRequestProps) => {
  const tokenRes = await credential.getToken();
  const accessToken = tokenRes?.token;
  const query = queryString.stringify({ ...params });
  const queryStr = query ? `?${query}` : '';
  const res = await fetch(isUrl ? `${path}` : `${host}${path}${queryStr}`, {
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  // check if json is available at output
  const contentType = res.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    const json = await res.json();
    if (res.ok) {
      return json;
    }
    throw json;
  }
  if (res.ok) {
    return res;
  }
  throw res;
};

export default httpClient;

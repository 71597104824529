import { ArrowLeftOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Space, Typography, message } from 'antd';
import useListLocation from 'hooks/useListLocation';
import { useNetworkActions } from 'pages/Network/hook';
import React, { FC, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationResponse, SharedWithContract } from 'services/api/client/src';
import { useLocationById } from 'services/api/useLocations';
import useTradePartners from 'services/api/useTradePartners';
import errorHandler from 'utils/errorHandler';
import { DataItem, ShareLocationColumns } from './ShareLocation.fields';
import styles from './index.module.less';
import { ShareLocationProps } from './typings';

const ShareLocationForm: FC<ShareLocationProps> = ({
  modal,
  locationToShare,
  clearLocationToShare,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.share_location' });
  const [isMessageHidden, setHideMessage] = useState<boolean>(false);
  const modalFormRef = useRef<ProFormInstance>();
  const { shareUserLocations } = useNetworkActions();
  const { data: tradePartners } = useTradePartners({ types: ['Wholechain'] });
  const { data: location = {} } = useLocationById(String(locationToShare?.id) || '');
  const { data: locations } = useListLocation({ tradePartnerStatus: ['Self'] });
  const [selectedCompany, setSelectedCompany] = useState<Array<string>>([]);

  const isShared = useCallback(
    (locationObj: LocationResponse, id: string) =>
      locationObj?.sharedWith?.some((item) => item?.wholechainId === id),
    [],
  );

  const isLocationShared = useCallback(
    (locs: SharedWithContract[]) =>
      selectedCompany?.every((id) => locs.some((loc) => loc?.wholechainId === id)),
    [selectedCompany],
  );

  const tradePartnerList = useMemo(
    () =>
      tradePartners?.data?.reduce((acc: any, val) => {
        if (val.wholechainId) {
          const obj = {
            [val.wholechainId as string]: {
              text: [val.name],
              value: [val.wholechainId || ''],
              disabled: locationToShare?.id ? isShared(location, val.wholechainId || '') : false,
            },
          };
          return { ...acc, ...obj };
        }
        return { ...acc };
      }, {}),
    [isShared, location, locationToShare?.id, tradePartners],
  );

  const locationsList = useMemo(
    () =>
      locations?.reduce((acc: any, val) => {
        const obj = {
          [val.id as string]: {
            text: `${val.name} ${
              val?.address?.displayAddress ||
              `${val?.address?.geoCoordinates?.latitude},${val?.address?.geoCoordinates?.longitude}`
            }`,
            value: [val.id || ''],
            disabled: isLocationShared(val.sharedWith || []),
            itemProps: val,
          },
        };
        return { ...acc, ...obj };
      }, {}),
    [isLocationShared, locations],
  );

  const closeModal = useCallback(() => {
    if (clearLocationToShare) {
      clearLocationToShare();
    }
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [clearLocationToShare, modal]);

  const Title: ReactElement = (
    <Space>
      {!isMessageHidden ? (
        <ArrowLeftOutlined
          className={styles.arrow}
          onClick={() => {
            modalFormRef.current?.resetFields();
          }}
        />
      ) : null}

      <Typography.Title level={4} className="mr0">
        {t('modal_title', { name: locationToShare ? `- ${locationToShare?.name}` : '' })}
      </Typography.Title>
    </Space>
  );

  const shareMultipleLocations = async (
    formData: DataItem,
    selectedLocations?: (LocationResponse | undefined)[],
  ) => {
    let showSharedMessage = false;
    try {
      selectedCompany?.forEach((compId) => {
        selectedLocations?.forEach(async (locObj) => {
          if (locObj && !isShared(locObj, compId)) {
            await shareUserLocations([locObj?.id || ''], [compId]);
          } else {
            showSharedMessage = true;
          }
        });
      });
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
    return showSharedMessage;
  };

  const onFinish = async (formData: DataItem) => {
    try {
      if (locationToShare?.id) {
        const companyList = formData.companies;
        const locationList = [String(locationToShare?.id)];
        const isSuccess = await shareUserLocations(locationList, companyList);
        if (isSuccess) {
          if (companyList?.length === 1) {
            const companyObj = tradePartners?.data?.find(
              (item) => item?.wholechainId === companyList[0],
            );
            message.success(t('share_success', { company: companyObj?.name }));
          } else {
            message.success(
              t('share_success', {
                company: `${companyList?.length} companies`,
              }),
            );
          }
          closeModal();
        }
      } else {
        const selectedLocations = formData.locations?.map((item) =>
          locations?.find((loc) => loc?.id === item),
        );
        const shareMessage = await shareMultipleLocations(formData, selectedLocations);
        message.success(
          t('share_multiple_success', {
            loc_count: formData?.locations?.length,
            comp_count: selectedCompany?.length,
          }),
        );
        if (shareMessage) {
          message.info(t('shared_info'));
        }
        closeModal();
      }
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      // @ts-ignore
      title={Title}
      formRef={modalFormRef}
      columns={ShareLocationColumns({
        t,
        isMessageHidden,
        setHideMessage,
        isAllLocations: !locationToShare,
        tradePartnerList,
        locationsList,
        setSelectedCompany,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        maskClosable: false,
        destroyOnClose: true,
        width: '40%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('send'),
          resetText: isMessageHidden ? t('done') : t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          className: isMessageHidden ? styles.donetext : styles.canceltext,
        },
        render: (_, defaultDoms) => (isMessageHidden ? defaultDoms[0] : defaultDoms),
      }}
      initialValues={{
        connectionType: 'Buyer',
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default React.memo(ShareLocationForm);

import { Form, Input, Space, Typography } from 'antd';
import React, { FC } from 'react';
import styles from './index.module.less';
import { DrawerFormHeaderProps } from './typings';

const DrawerFormHeader: FC<DrawerFormHeaderProps> = ({
  title,
  form,
  readonly = false,
  ticketId,
  t,
}) => (
  <Space size={20}>
    <Typography.Title level={4}>{title}</Typography.Title>
    {!readonly ? (
      <Form form={form} layout="inline">
        <Form.Item
          name="ticketId"
          rules={[
            {
              required: true,
              message: t?.('ticket_id_req'),
            },
            {
              whitespace: true,
              message: t?.('ticket_id_req'),
            },
          ]}
        >
          <Input
            placeholder={t?.('ticket_id_placeholder')}
            className={styles.ticketinput}
            readOnly={readonly}
          />
        </Form.Item>
      </Form>
    ) : (
      `: ${ticketId}`
    )}
  </Space>
);
export default React.memo(DrawerFormHeader);

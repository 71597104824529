import { Button, Modal } from 'antd';
import useModalVisibility from 'hooks/useModalVisibility';
import useAddEditActions from 'pages/Network/hook/useAddEditActions';
import { FC, useCallback, useState } from 'react';
import GenerateAPIKeyModal from './GenerateAPIKey';
import styles from './index.module.less';
import { APIKeyProps } from './typings';

const InboundAPIKey: FC<APIKeyProps> = ({
  tradePartnerId,
  disabled,
  isWhiteButton,
  updateTradePartner,
  buttonSize,
}) => {
  const apiKeyModal = useModalVisibility(false);
  const [inboundKey, setInboundKey] = useState<string>('');
  const { t, generateNewAPIKey, queryClient } = useAddEditActions();

  const newAPIKeyConfirmation = useCallback(
    async () =>
      new Promise((resolve) => {
        Modal.warning({
          title: t('generate_key.warn.title'),
          content: t('generate_key.warn.content'),
          centered: true,
          okCancel: true,
          okText: t('generate_key.warn.continue'),
          cancelText: t('generate_key.warn.cancel'),
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          cancelButtonProps: {
            type: 'primary',
            ghost: true,
            shape: 'round',
          },
          onCancel: () => resolve(false),
          onOk: () => resolve(true),
        });
      }),
    [t],
  );

  const onUpdateInboundApiKey = useCallback(async () => {
    const consent = disabled ? true : await newAPIKeyConfirmation();
    if (consent) {
      const res = await generateNewAPIKey(tradePartnerId);
      if (res) {
        if (updateTradePartner) {
          queryClient.invalidateQueries('tradePartnerDataId');
          queryClient.invalidateQueries('tradePartners');
        }
        setInboundKey(res?.inboundApiKey || '');
        apiKeyModal.show();
      }
    }
  }, [
    apiKeyModal,
    disabled,
    generateNewAPIKey,
    newAPIKeyConfirmation,
    queryClient,
    tradePartnerId,
    updateTradePartner,
  ]);

  return (
    <>
      {apiKeyModal.visible && (
        <GenerateAPIKeyModal modal={apiKeyModal} newAPIKey={inboundKey} t={t} />
      )}
      <Button
        shape="round"
        className={isWhiteButton ? styles.whitebutton : styles.deletebuttontext}
        onClick={onUpdateInboundApiKey}
        size={buttonSize || 'middle'}
      >
        {disabled ? t('generate_api_key') : t('generate_api_new_key')}
      </Button>
    </>
  );
};

export default InboundAPIKey;

import { message, Space, TablePaginationConfig, Typography } from 'antd';
import { useShareDocumentStore } from 'components';
import { useModalVisibility, useTableRowSelection } from 'hooks';
import useListLocation from 'hooks/useListLocation';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { FilterArrayProp } from 'pages/Network/typings';
import { Key, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useProducts, useTradePartners } from 'services/api';
import { GetPaginatedDocumentsOptionalParams } from 'services/api/client/src';
import { downloadDocumentById, useDocuments, useUpdateDocument } from 'services/api/documents';
import {
  copyToClipboard,
  dateFormat,
  dateLocalFormat,
  downloadDocument,
  errorHandler,
  generateDocTags,
} from 'utils';
import { DocumentItem } from '../typings';
import { Permission } from './typings';

const useDocumentActions = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'documents' });
  const navigate = useNavigate();
  const { selectedRowKeys, onRowSelectionChange } = useTableRowSelection();
  const queryClient = useQueryClient();
  const updateDocument = useUpdateDocument(queryClient);
  const shareModal = useModalVisibility();

  const {
    setDocument: setShareDocument,
    setDocuments,
    setEtradePartners,
  } = useShareDocumentStore();
  /* download document */
  const onDownloadDocument = useCallback(
    async (document?: DocumentItem) => {
      const success = message.loading(t('downloading_single_doc'), 0);
      try {
        if (document) {
          const url = await downloadDocumentById(document?.id || '');
          downloadDocument(url || '', document?.title || '');
        }
        success();
      } catch (error) {
        success();
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [t],
  );
  const onDownloadDocumentMultiple = useCallback(
    async (rowKeys?: Array<Key>) => {
      message.loading(t('downloading_multi_doc'), 1);
      try {
        if (rowKeys?.length) {
          const promises = rowKeys.map(async (id) => {
            const url = await downloadDocumentById(String(id) || '');
            downloadDocument(url || '', String(id) || '');
          });
          await Promise.all(promises);
          onRowSelectionChange([], []);
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [onRowSelectionChange, t],
  );

  /* copy document url */
  const copyURL = useCallback(
    async (document?: DocumentItem) => {
      const url = `${window.location.origin}/view_document/?id=${document?.id}`;
      await copyToClipboard(url || ' ');
      message.success(t('url_copied'));
    },
    [t],
  );

  const onViewArchived = useCallback(() => {
    navigate({
      pathname: `/settings`,
      search: `?tab=archived&section=documents`,
    });
  }, [navigate]);
  const onViewUnarchived = useCallback(() => {
    navigate({
      pathname: `/documents`,
    });
  }, [navigate]);
  /* archive document */
  const onArchiveDocument = useCallback(
    async (document?: DocumentItem) => {
      const success = message.loading(t('document.archive_single_doc'), 0);

      try {
        const res = await updateDocument.mutateAsync({
          id: document?.id || '',
          document: { id: document?.id || '', archived: true },
        });
        message.success(
          <Space>
            {t('archive_success', { name: res?.title || '' })}
            <Typography.Link
              onClick={(e) => {
                e.preventDefault();
                onViewArchived();
              }}
            >
              {t('view_archived')}
            </Typography.Link>
          </Space>,
        );
        success();
      } catch (error) {
        success();
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [updateDocument, t, onViewArchived],
  );
  const onUnarchiveDocument = useCallback(
    async (document?: DocumentItem) => {
      const success = message.loading(t('unarchive_single_doc'), 0);

      try {
        const res = await updateDocument.mutateAsync({
          id: document?.id || '',
          document: { id: document?.id || '', archived: false },
        });
        message.success(
          <Space>
            {t('unarchive_success', { name: res?.title || '' })}
            <Typography.Link
              onClick={(e) => {
                e.preventDefault();
                onViewUnarchived();
              }}
            >
              {t('view')}
            </Typography.Link>
          </Space>,
        );
        success();
      } catch (error) {
        success();
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [updateDocument, t, onViewUnarchived],
  );
  const onArchiveDocumentMultiple = useCallback(
    async (rowKeys?: Array<Key>) => {
      message.loading(t('document.archive_multi_doc'), 1);
      try {
        if (rowKeys?.length) {
          const promises = rowKeys.map(async (id) =>
            updateDocument.mutateAsync({
              id: String(id) || '',
              document: { id: String(id) || '', archived: true },
            }),
          );
          await Promise.all(promises);
          onRowSelectionChange([], []);
          message.success(
            <Space>
              {t('archive_success_many', { count: rowKeys?.length || 0 })}
              <Typography.Link
                onClick={(e) => {
                  e.preventDefault();
                  onViewArchived();
                }}
              >
                {t('view_archived')}
              </Typography.Link>
            </Space>,
          );
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [t, onRowSelectionChange, updateDocument, onViewArchived],
  );
  const onUnarchiveDocumentMultiple = useCallback(
    async (rowKeys?: Array<Key>) => {
      message.loading(t('document.unarchive_multi_doc'), 1);
      try {
        if (rowKeys?.length) {
          const promises = rowKeys.map(async (id) =>
            updateDocument.mutateAsync({
              id: String(id) || '',
              document: { id: String(id) || '', archived: false },
            }),
          );
          await Promise.all(promises);
          message.success(
            <Space>
              {t('unarchive_success_many', { count: rowKeys?.length || 0 })}
              <Typography.Link
                onClick={(e) => {
                  e.preventDefault();
                  onViewUnarchived();
                }}
              >
                {t('view')}
              </Typography.Link>
            </Space>,
          );
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [updateDocument, t, onViewUnarchived],
  );

  /* share document */
  const onShareDocument = useCallback(
    (document?: DocumentItem) => {
      setShareDocument(document);
      setEtradePartners([
        {
          id: document?.ownerAccount,
          name: document?.ownerAccountName || '',
          isOwner: true,
          isUpdated: false,
          isDeleted: false,
        },
        ...(document?.sharedWith?.map((el) => ({
          id: el?.wholechainId,
          name: el?.accountName || '',
          permission: (el?.role as Permission) || ('Viewer' as Permission),
          isOwner: false,
          isUpdated: false,
          isDeleted: false,
        })) || []),
      ]);
      shareModal.show();
    },
    [setShareDocument, shareModal, setEtradePartners],
  );
  /* share document */
  const onShareDocumentMultiple = useCallback(
    (documents?: Array<string>) => {
      shareModal.show();
      setShareDocument(document);
      setDocuments(documents);
    },
    [setShareDocument, setDocuments, shareModal],
  );

  const resetRowSelection = useCallback(() => {
    onRowSelectionChange([], []);
  }, [onRowSelectionChange]);

  return {
    onDownloadDocument,
    onDownloadDocumentMultiple,
    copyURL,
    onArchiveDocument,
    onUnarchiveDocument,
    onArchiveDocumentMultiple,
    onUnarchiveDocumentMultiple,
    shareModal,
    onShareDocument,
    onShareDocumentMultiple,
    selectedRowKeys,
    onRowSelectionChange,
    resetRowSelection,
    isUnarchiving: updateDocument?.isLoading,
  };
};
export const useDocumentFilters = (initParams?: GetPaginatedDocumentsOptionalParams) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });
  const [params, setParams] = useState<GetPaginatedDocumentsOptionalParams>({
    ...initParams,
    pageNumber: 1,
    pageSize: 20,
  });
  const { data, isLoading, refetch, isFetching } = useDocuments({ ...initParams, ...params });
  const { data: dataDocuments } = useDocuments({
    pageNumber: 1,
    pageSize: 20,
  });
  const totalItems = useMemo(() => data?.totalItems || 0, [data]);
  const netItemCount = useMemo(() => dataDocuments?.totalItems || 0, [dataDocuments]);
  const { data: locations } = useListLocation();
  const { data: products } = useProducts({
    pageNumber: 1,
    pageSize: 100,
  });
  const { data: allCompany } = useTradePartners();
  const account = allCompany?.data?.find((item) => item?.isOwnerAccount === true, []);
  const coreCompanyFilter = useMemo<Array<FilterArrayProp>>(
    () =>
      allCompany?.data
        /* previosly  filter was mapped to item?.wholechainId !== null */
        ?.filter((item) => item?.hasWholechainAccount && !item?.isOwnerAccount)
        ?.map((item) => ({
          text: item.name || '',
          /* previosly was mapped to item?.wholechainId */
          value: item.id || '',
        })) || [],
    [allCompany],
  );

  const tradePartnersFilter = useMemo<Array<FilterArrayProp>>(
    () =>
      allCompany?.data?.map((item) => ({
        text: item.name || '',
        value: item.id || '',
      })) || [],
    [allCompany],
  );

  const locationsFilter = useMemo<Array<FilterArrayProp>>(
    () =>
      /* tradePartnerName is missing */
      locations?.map((item) => ({
        text: `${item?.name} - ${item?.tradePartnerName}` || '',
        value: item.id || '',
      })) || [],
    [locations],
  );

  const productsFilter: Array<FilterArrayProp> = useMemo(
    () =>
      products?.results?.map((item) => ({
        text: item.name || '',
        value: item.id || '',
      })) || [],
    [products?.results],
  );

  const records = useMemo<Array<DocumentItem>>(
    () =>
      data?.results?.map((item) => ({
        id: item.id,
        title: item.title,
        documentType: t(`${item?.documentType?.toLocaleLowerCase()}`),
        recentActivity: item?.recentActivity
          ? dateLocalFormat(new Date(item?.recentActivity || ''))
          : '',
        expirationDate: item?.expirationDate
          ? dateFormat(new Date(item?.expirationDate || ''))
          : '-',
        blockchain: item.blockchain,
        privacy: item.privacy,
        url: `${window.location.origin}/view_document/?id=${item?.id}`,
        associations: generateDocTags({
          tradePartners: item.tradePartners,
          products: item.products,
          locations: item.locations,
          events: item.events,
        }),
        locations: item?.locations || [],
        products: item?.products || [],
        tradePartners: item?.tradePartners || [],
        events: item?.events || [],
        tags: generateDocTags({
          locations: item?.locations || [],
          products: item?.products || [],
          tradePartners: item?.tradePartners || [],
          events: item?.events || [],
        }),
        sharedWith: item.sharedWith,
        ownerAccount: item?.ownerAccount,
        ownerAccountName: item?.ownerAccountName,
        isOwner: item?.ownerAccount === account?.wholechainId,
      })) || [],
    [account?.wholechainId, data?.results, t],
  );

  const onSearch = useCallback(
    (text: string) => {
      setParams({
        ...params,
        pageNumber: 1,
        title: text,
      });
    },
    [params],
  );
  const { filters: nameSearchfilter } = useTableSearchFilter({
    title: t('columns.document_name'),
    onSearch,
  });
  const onTableChange = useCallback(
    (pagination: TablePaginationConfig, filters: Record<string, Array<string> | null>) => {
      const apiFilters: GetPaginatedDocumentsOptionalParams = {
        documentType: filters?.documentType?.length
          ? [...(filters?.documentType || []), ...(initParams?.documentType || [])]
          : initParams?.documentType || undefined,
        /* blockchain: filters?.blockchain?.length
          ? [...(filters?.blockchain || []), ...(initParams?.blockchain || [])]
          : initParams?.blockchain || undefined, */
        privacy: filters?.privacy?.length
          ? [...(filters?.privacy || []), ...(initParams?.privacy || [])]
          : initParams?.privacy || undefined,
        sharedWith: filters?.shared?.length
          ? [...(filters?.shared || []), ...(initParams?.sharedWith || [])]
          : initParams?.sharedWith || undefined,
        locationIds: filters?.locations?.length
          ? [...(filters?.locations || []), ...(initParams?.locationIds || [])]
          : initParams?.locationIds || undefined,
        productIds: filters?.products?.length
          ? [...(filters?.products || []), ...(initParams?.productIds || [])]
          : initParams?.productIds || undefined,
        tradePartnerIds: filters?.tradepartners?.length
          ? [...(filters?.tradepartners || []), ...(initParams?.tradePartnerIds || [])]
          : initParams?.tradePartnerIds || undefined,
      };

      if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: 1,
        });
      } else {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: pagination?.current,
          pageSize: pagination?.pageSize,
        });
      }
    },
    [params, setParams, initParams],
  );

  return {
    records,
    totalItems,
    netItemCount,
    isLoading,
    onTableChange,
    params,
    setParams,
    filters: [nameSearchfilter],
    coreCompanyFilter,
    tradePartnersFilter,
    locationsFilter,
    productsFilter,
    refetch,
    t,
    isFetching,
  };
};

export default useDocumentActions;

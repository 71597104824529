import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import type { ParamsType } from '@ant-design/pro-provider';
import { EditableProTable } from '@ant-design/pro-table';
import type { EditableProTableProps } from '@ant-design/pro-table/lib/components/EditableTable';
import { Button, ButtonProps, Empty, Skeleton, Tooltip } from 'antd';
import type { Key } from 'antd/lib/table/interface';
import classnames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GTable.module.less';
import { GTableProvider, useGTableContext } from './contexts';
import { ActionsRenderOptions, GTableProps } from './typings';

const defaultBtnProps: ButtonProps = {
  size: 'small',
  ghost: false,
  type: 'text',
  className: 'value-actions-btn',
};

const GTable = <
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = 'text',
>({
  addButtonProps,
  addBtnText,
  addBtnPosition = 'bottom',
  className,
  editable,
  enableRecordCreator,
  enableRowSelection,
  actionsRenderOptions,
  onAddRecordClick,
  options,
  pagination = false,
  showSizeChanger = true,
  recordCreatorProps,
  rowKey = 'id',
  rowSelection,
  size = 'small',
  search = false,
  emptyContainer,
  loading,
  columnsState,
  customDeleteButton,
  ...props
}: EditableProTableProps<DataType, Params, ValueType> & GTableProps) => {
  const { actionRef, selectedRowKeys, onRowSelectionChange } = useGTableContext();
  const [editableKeys, setEditableKeys] = useState<Array<Key>>();
  const { t } = useTranslation('pages', { keyPrefix: 'gtable' });

  const actionsRenderOptionsDefault: ActionsRenderOptions = {
    save: true,
    cancel: true,
  };

  const mergedActionsRenderOptions: ActionsRenderOptions = {
    ...actionsRenderOptionsDefault,
    ...actionsRenderOptions,
  };

  const saveChangesFirst = useMemo(() => t('save_changes_first'), [t]);
  const initLocale = useMemo(() => {
    const emptyComp = !emptyContainer ? (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        className={styles.sktcontainer}
        description={t('no_data')}
      />
    ) : (
      emptyContainer
    );

    return {
      emptyText: loading ? (
        <Skeleton
          active
          className={styles.sktcontainermain}
          title={false}
          paragraph={{ rows: 4, width: '100%' }}
        />
      ) : (
        emptyComp
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, emptyContainer]);
  return (
    <>
      {enableRecordCreator && addBtnPosition === 'top' && (
        <div className={styles.addtopbtn}>
          <Button
            type="primary"
            size="middle"
            shape="round"
            {...addButtonProps}
            onClick={onAddRecordClick}
          >
            {addBtnText || t('add')}
          </Button>
        </div>
      )}
      <EditableProTable<DataType, Params, ValueType>
        {...props}
        actionRef={actionRef}
        className={classnames(styles.table, className)}
        editable={{
          type: 'single',
          deletePopconfirmMessage: t('are_u_sure'),
          onlyAddOneLineAlertMessage: saveChangesFirst,
          onlyOneLineEditorAlertMessage: saveChangesFirst,
          actionRender: (row, config, defaultDoms) => [
            mergedActionsRenderOptions?.save && (
              <Tooltip placement="bottom" title={t('save')}>
                <Button {...defaultBtnProps} id="gtbl-save-btn">
                  {defaultDoms.save}
                </Button>
              </Tooltip>
            ),
            mergedActionsRenderOptions?.cancel && (
              <Tooltip placement="bottom" title={t('cancel')}>
                <Button {...defaultBtnProps} id="gtbl-cancel-btn">
                  {defaultDoms.cancel}
                </Button>
              </Tooltip>
            ),
            mergedActionsRenderOptions?.delete && (
              <Tooltip placement="bottom" title={t('delete')}>
                <Button {...defaultBtnProps} id="gtbl-delete-btn">
                  {customDeleteButton ? (
                    <DeleteOutlined
                      className={styles.deletebutton}
                      onClick={() => customDeleteButton(row)}
                    />
                  ) : (
                    defaultDoms.delete
                  )}
                </Button>
              </Tooltip>
            ),
          ],
          cancelText: <CloseOutlined />,
          saveText: <CheckOutlined />,
          deleteText: <DeleteOutlined />,
          editableKeys,
          onChange: setEditableKeys,
          ...editable,
        }}
        ghost
        options={{
          density: false,
          reload: true,
          fullScreen: false,
          setting: true,
          ...options,
        }}
        pagination={
          pagination && {
            showTotal: (total, range) =>
              t('table_pagi', { range0: range[0], range1: range[1], total }),
            showSizeChanger,
            ...pagination,
          }
        }
        rowKey={rowKey}
        rowSelection={
          enableRowSelection && {
            selectedRowKeys,
            onChange: onRowSelectionChange,
            ...rowSelection,
          }
        }
        recordCreatorProps={recordCreatorProps || false}
        search={search}
        size={size}
        loading={false}
        locale={initLocale}
        columnsState={{
          ...columnsState,
          persistenceType: 'localStorage',
        }}
      />
      {enableRecordCreator && addBtnPosition === 'bottom' && (
        <div className={styles.toolbar}>
          <Button
            type="primary"
            size="middle"
            shape="round"
            {...addButtonProps}
            onClick={onAddRecordClick}
          >
            {addBtnText || t('add')}
          </Button>
        </div>
      )}
    </>
  );
};

const GTableContainer = <
  DataType extends Record<string, any>,
  Params extends ParamsType = ParamsType,
  ValueType = 'text',
>({
  actionRef,
  ...props
}: EditableProTableProps<DataType, Params, ValueType> & GTableProps) => (
  <GTableProvider actionRef={actionRef}>
    <GTable<DataType, Params, ValueType> {...props} />
  </GTableProvider>
);

export default GTableContainer;

import { QuestionCircleOutlined } from '@ant-design/icons';
import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Form, Modal, Typography } from 'antd';
import { ActionButtons, GTable, ProductSelect } from 'components';
import { ContainerItem } from 'pages/Shipments/typings';
import { FormInstance } from 'rc-field-form';
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useProducts } from 'services/api';
import { ProductResponse } from 'services/api/client/src';
import {
  getAssignedProductId,
  getDisabledDate,
  getDisabledTime,
  getProductsT,
  getRecordValue,
  getShipmentProductName,
  getUom,
  hiddencol,
} from 'utils';
import ProductDropdown from '../ProductDropdown';
import { useEventActions } from '../hooks';
import styles from '../index.module.less';
import { ReceiveProductItem } from '../typings';
import { EditableFormProps, ReceiveProductsProps } from './typings';

type ColumnsType = {
  t: TFunction<'pages', 'events.recieve.form_fields'>;
  onEditRow: (actionPayload?: ReceiveProductItem) => void;
  onDelRow: (actionPayload?: ReceiveProductItem) => void;
  productChangeModal?: (value: string, recordKey: string) => void;
  lotChangeModal?: (value: string, recordKey: string, tblForm: FormInstance<any>) => void;
  quantityChangeModal?: (value: string, recordKey: string, tblForm: FormInstance<any>) => void;
  isInternal?: boolean;
  isSerial?: boolean;
  identifier?: string;
  isSSCC?: boolean;
  products?: ReceiveProductItem[];
  multipleDates?: boolean;
  editableFormRef: MutableRefObject<EditableFormInstance<EditableFormProps>>;
  onChangeDate: (
    form: FormInstance<any>,
    value: string,
    isMultipleDates?: boolean,
    rowKey?: string,
    eventTime?: string,
    timeKey?: string,
  ) => void;
};
const columns = ({
  t,
  onEditRow,
  onDelRow,
  lotChangeModal,
  quantityChangeModal,
  isSSCC,
  isInternal,
  products,
  isSerial,
  identifier,
  multipleDates,
  onChangeDate,
  editableFormRef,
}: ColumnsType): Array<ProColumns<ReceiveProductItem>> => [
  {
    title: t('tbl_col_product_title'),
    dataIndex: 'parentProductId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('tbl_col_product_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t('tbl_col_product_placeholder'),
      disabled: isInternal,
    },
    tooltip: isSSCC
      ? {
          title: t('tbl_col_product_tooltip'),
          icon: (
            <Typography.Text type="secondary">
              <QuestionCircleOutlined />
            </Typography.Text>
          ),
        }
      : {
          title: t('tbl_col_lot_product_tooltip'),
          icon: (
            <Typography.Text type="secondary">
              <QuestionCircleOutlined />
            </Typography.Text>
          ),
        },
    renderFormItem: (_, { recordKey, record }, form) => {
      const { setFieldsValue } = form;
      return (
        <ProductSelect
          allowLotSerial={record?.productIdentifierType === undefined}
          isSerial={record?.productIdentifierType === 'Serial'}
          size="small"
          prependOption={
            record?.parentProductId === 'external_shipment'
              ? {
                  label: `${record?.name || ''}${
                    record?.shippedFrom ? ` (${record?.shippedFrom})` : ''
                  }`,
                  value: 'external_shipment',
                  itemProps: {
                    id: record?.parentProductId || '',
                    name: record?.name || '',
                  },
                }
              : undefined
          }
          dropdownRender={(menu) => <ProductDropdown menu={menu} product={record} t={t} />}
          onChange={(value, option) => {
            // @ts-ignore
            const item: ProductResponse = option.itemProps;
            setFieldsValue({
              [String(recordKey)]: {
                parentProductId: item?.id,
                name: item?.name || '',
                isAssignedProduct: true,
              },
            });
          }}
          disabled={isInternal}
        />
      );
    },
    render: (text, record) => `${record?.name || ''}`,
    // ) : (
    //   <Typography.Text type="secondary">{record?.name || ''}</Typography.Text>
    // ),
  },
  {
    dataIndex: 'eventDate',
    ...hiddencol,
  },
  {
    title: t('tbl_col_product_lot_title'),
    dataIndex: 'lotSerial',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('tbl_col_product_lot_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: (form, { rowKey }) => ({
      disabled: isInternal,
      placeholder: t('tbl_col_product_lot_placeholder'),
      size: 'small',
      onBlur: (e: any) => lotChangeModal?.(e.target.value, String(rowKey), form),
    }),

    render: (text, record) =>
      `${getProductsT(record?.productIdentifierType || identifier)}: ${record?.lotSerial || ''}`,
  },
  {
    dataIndex: 'instanceQuantity',
    ...hiddencol,
  },
  {
    dataIndex: 'unitOfMeasure',
    ...hiddencol,
  },
  {
    title: t('tbl_col_product_quantity_title'),
    dataIndex: 'quantity',
    valueType: 'digit',
    hideInTable: isSerial,
    fieldProps: (form, { rowKey }) => {
      const instanceInventory =
        form?.getFieldValue?.(String(rowKey))?.instanceQuantity ||
        getRecordValue('instanceQuantity', products, rowKey) ||
        0;
      const defaultAddon = `/ ${instanceInventory}`;

      const unitOfMeasure = form?.getFieldValue?.(String(rowKey))?.unitOfMeasure || 'LBS';
      return {
        addonAfter: `${defaultAddon} ${unitOfMeasure}`,
        size: 'small',
        stringMode: true,
        placeholder: t('tbl_col_product_quantity_placeholder'),
        onBlur: (e: any) => quantityChangeModal?.(e.target.value, String(rowKey), form),
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('tbl_col_product_quantity_req'),
        },
      ],
      hasFeedback: false,
    },
    render: (text, record) =>
      `${record?.quantity} / ${record?.instanceQuantity || 0} ${record?.unitOfMeasure || ''}`,
  },

  {
    title: t?.('tbl_col_date_title'),
    dataIndex: 'date',
    valueType: 'date',
    hideInTable: !multipleDates,
    fieldProps: (form, { rowKey }) => ({
      size: 'small',
      placeholder: t?.('tbl_col_date_title'),
      onChange: (value: string) =>
        onChangeDate?.(
          editableFormRef?.current,
          value,
          multipleDates,
          rowKey,
          editableFormRef?.current?.getFieldValue?.(String(rowKey))?.eventDate ||
            getRecordValue('eventDate', products, rowKey),
        ),
      disabledDate: getDisabledDate(
        editableFormRef?.current?.getFieldValue?.(String(rowKey))?.eventDate ||
          getRecordValue('eventDate', products, rowKey),
      ),
      className: 'full-width',
    }),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_date_req'),
        },
      ],
      hasFeedback: false,
    },
  },
  {
    title: t?.('tbl_col_time_title'),
    dataIndex: 'time',
    valueType: 'time',
    hideInTable: !multipleDates,
    fieldProps: (form, { rowKey }) => ({
      size: 'small',
      placeholder: t?.('tbl_col_time_title'),
      disabledTime: getDisabledTime(
        editableFormRef?.current?.getFieldValue?.(String(rowKey))?.eventDate ||
          getRecordValue('eventDate', products, rowKey),
      ),
      className: 'full-width',
    }),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_time_req'),
        },
      ],
      hasFeedback: false,
    },
  },
  {
    title: t('tbl_col_shipped_from_title'),
    dataIndex: 'shippedFrom',
    fieldProps: {
      size: 'small',
      disabled: true,
      placeholder: t('tbl_col_shipped_from_placeholder'),
    },
    hideInTable: isSSCC,
  },
  {
    dataIndex: 'isAssignedProduct',
    ...hiddencol,
  },

  {
    dataIndex: 'actions',
    // hideInTable: !isSSCC,
    valueType: 'option',
    render: (_text, record) => (
      <ActionButtons record={record} onEdit={onEditRow} onDelete={onDelRow} showDelete={false} />
    ),
  },
];
const ReceiveProducts: FC<ReceiveProductsProps> = ({
  form,
  allowEdit = true,
  dataProducts,
  isSSCC,
  isInternal,
  isSerial,
  identifier,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.recieve.form_fields' });
  const editableFormRef = useRef<EditableFormInstance<any>>();
  const { setFieldsValue } = form;
  const [fetched, setFetched] = useState<boolean>(false);
  const sactionRef = useRef<ActionType>();
  // used to fectch total item info from products array
  const { data: preProducts, isLoading: isPreProductLoading } = useProducts();
  // products array with all of the items
  const { data: dProducts, isLoading } = useProducts({ pageSize: preProducts?.totalItems });

  const multipleDates = Form?.useWatch('isMultipleDates', form) || false;
  const products: Array<ReceiveProductItem> = Form?.useWatch('inboundShipments', form) || [];
  const { onChangeDate } = useEventActions();
  const setProducts = useCallback(
    (value: Array<ReceiveProductItem>) => {
      setFieldsValue({
        inboundShipments: value,
      });
    },
    [setFieldsValue],
  );
  const getAssignedProduct = useCallback(
    (product: ContainerItem) => {
      if (product) {
        if (isInternal) {
          return product?.productId;
        }
        // check if product urn already exist in dataProducts?.results
        const dataProduct = dProducts?.results?.find((item) => item?.urn === product?.productURN);
        if (dataProduct) {
          return dataProduct?.id;
        }

        return undefined;
      }
      return undefined;
    },
    [isInternal, dProducts],
  );

  const modifiedProducts: Array<ReceiveProductItem> = useMemo(
    () =>
      !isSSCC
        ? dataProducts?.map((p) => ({
            id: p?.id || '0',
            productId: getAssignedProductId(p, dProducts?.results) || '',
            parentProductId: getAssignedProductId(p, dProducts?.results) || 'external_shipment',
            name: p?.productName || getShipmentProductName(p),
            primaryId: p?.instanceId || p?.containerId || '',
            lotSerial: p?.primaryId || '',
            logisticId: p?.isContainer ? p?.primaryId || '' : '',
            quantity: p?.quantity || 0,
            instanceQuantity: p?.quantity || 0,
            unitOfMeasure: getUom({ product: p }) || 'LBS',
            lastEventId: p?.shipmentEventId || '',
            eventDate: p?.eventDate || '',
            locationId: p?.originId || '',
            shippedFrom: p?.senderName || '',
            isContainer: p?.isContainer || false,
            containerItems: p?.containerItems || [],
            isAssignedProduct: !!getAssignedProductId(p, dProducts?.results),
            isQuantityChangeModalTriggered: false,
            poNumber: p?.purchaseOrderNumber,
            productIdentifierType: p?.productIdentifierType || 'Lot',
            instanceUrn: p?.instanceUrn || '',
          })) || []
        : dataProducts?.[0]?.containerItems?.map((p) => ({
            id: p?.instanceId || '0',
            productId: getAssignedProduct(p) || '',
            parentProductId: getAssignedProduct(p) || 'external_shipment',
            name: p?.productName || getShipmentProductName(p),
            primaryId: p?.instanceId,
            lotSerial: p?.primaryId || '',
            logisticId: dataProducts?.[0]?.primaryId || '',
            quantity: p?.quantity || 0,
            instanceQuantity: p?.quantity || 0,
            unitOfMeasure: getUom({ product: p }) || 'LBS',
            lastEventId: dataProducts?.[0]?.shipmentEventId || '',
            eventDate: dataProducts?.[0]?.eventDate || '',
            locationId: dataProducts?.[0]?.originId || '',
            shippedFrom: dataProducts?.[0]?.senderName || '',
            shipmentType: dataProducts?.[0]?.shipmentType || 'Internal',
            isContainer: true,
            isAssignedProduct: isInternal || !!getAssignedProduct(p),
            containerItems: [],
            isQuantityChangeModalTriggered: false,
            productIdentifierType: p?.productIdentifierType || 'Lot',
            instanceUrn: p?.instanceUrn || '',
          })) || [],
    [dataProducts, isSSCC, isInternal, getAssignedProduct, dProducts?.results],
  );

  useEffect(() => {
    if (modifiedProducts && !fetched && dProducts) {
      setProducts(modifiedProducts);
      setFetched(true);
    }
  }, [modifiedProducts, fetched, setProducts, dProducts]);

  const productChangeModal = (value: string, rowKey?: string) => {
    const product = modifiedProducts?.find((item) => item?.id === rowKey);
    const productName = product?.name || '';
    const isSameName = value === productName;

    if (!isSameName && value) {
      Modal.info({
        title: t?.('assign_product_title'),
        content: t?.('assign_product_desc', {
          product: productName,
          assignedProduct: value,
        }),
        okText: t('assign_product_cancel_btn'),
        cancelButtonProps: {
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
        onOk: () => {
          setFieldsValue({
            autologDisaggregateEvent: true,
            disableAutologDisaggregateEvent: true,
          });
        },
        onCancel: () => {
          setFieldsValue({
            autologDisaggregateEvent: false,
            disableAutologDisaggregateEvent: false,
          });
        },
      });
    }
  };
  const lotChangeModal = (value: string, recordKey: string, tblForm: FormInstance<any>) => {
    const product = modifiedProducts?.find((item) => item?.id === recordKey);
    const lotSerial = product?.lotSerial || '';

    if (value !== lotSerial && value) {
      if (!isSSCC) {
        Modal.warning({
          title: t?.('lot_change_modal_title', {
            identifier: getProductsT(identifier),
          }),
          content: t?.('lot_change_modal_desc', {
            newPrimaryId: value,
            oldPrimaryId: lotSerial || '',
            identifier: getProductsT(identifier),
          }),
          cancelText: t('lot_change_cancel_btn'),
          okText: t('lot_change_continue_btn'),
          cancelButtonProps: {
            type: 'primary',
            shape: 'round',
            ghost: true,
          },
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          centered: true,
          onCancel: () => {
            tblForm?.setFieldsValue({
              [String(recordKey)]: { lotSerial: lotSerial || '' },
            });
            setFieldsValue({
              autologDisaggregateEvent: false,
              disableAutologDisaggregateEvent: false,
            });
          },
          onOk: () => {
            setFieldsValue({
              autologDisaggregateEvent: true,
              disableAutologDisaggregateEvent: true,
            });
          },
          okCancel: true,
        });
      } else {
        Modal.warning({
          title: t?.('sscc_lot_change_modal_title', {
            identifier: getProductsT(identifier),
            containerId: dataProducts?.[0]?.primaryId || '',
          }),
          content: t?.('sscc_lot_change_modal_desc', {
            newPrimaryId: value,
            oldPrimaryId: lotSerial || '',
            identifier: getProductsT(identifier),
            containerId: dataProducts?.[0]?.primaryId || '',
          }),
          cancelText: t('sscc_lot_change_cancel_btn'),
          okText: t('sscc_lot_change_continue_btn'),
          cancelButtonProps: {
            type: 'primary',
            shape: 'round',
            ghost: true,
          },
          okButtonProps: {
            type: 'primary',
            shape: 'round',
          },
          centered: true,
          onCancel: () => {
            tblForm?.setFieldsValue({
              [String(recordKey)]: { lotSerial: lotSerial || '' },
            });
            setFieldsValue({
              autologDisaggregateEvent: false,
              disableAutologDisaggregateEvent: false,
            });
          },
          onOk: () => {
            setFieldsValue({
              autologDisaggregateEvent: true,
              disableAutologDisaggregateEvent: true,
            });
          },
          okCancel: true,
        });
      }
    }
  };
  const quantityChangeModal = (value: string, recordKey: string, tblForm: FormInstance<any>) => {
    const product = modifiedProducts?.find((item) => item?.id === recordKey);
    const quantity = product?.quantity || 0;
    const isQuantityChangeModalTriggered = tblForm?.getFieldValue(
      String(recordKey),
    )?.isQuantityChangeModalTriggered;

    if (Number(value) !== quantity && value && !isQuantityChangeModalTriggered && isSSCC) {
      Modal.info({
        title: t?.('quantity_change_modal_title', { sscc: dataProducts?.[0]?.primaryId || '' }),
        content: t?.('quantity_change_modal_desc', {
          sscc: dataProducts?.[0]?.primaryId || '',
        }),
        cancelText: t('quantity_change_cancel_btn'),
        okText: t('quantity_change_continue_btn'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        onCancel: () => {
          tblForm?.setFieldsValue({
            [String(recordKey)]: {
              quantity: quantity || 0,
              isQuantityChangeModalTriggered: true,
            },
          });
          setFieldsValue({
            autologDisaggregateEvent: false,
            disableAutologDisaggregateEvent: false,
          });
        },
        onOk: () => {
          setFieldsValue({
            autologDisaggregateEvent: true,
            disableAutologDisaggregateEvent: true,
          });
          tblForm?.setFieldsValue({
            [String(recordKey)]: {
              isQuantityChangeModalTriggered: true,
            },
          });
        },
        okCancel: true,
      });
    }
  };

  const onEditRow = (actionPayload?: ReceiveProductItem) => {
    sactionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onDelRow = async (actionPayload?: ReceiveProductItem) => {
    if (actionPayload) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    }
  };
  const toggleDisaggregateEvent = (rowKey: RecordKey, data: ReceiveProductItem) => {
    const product = modifiedProducts?.find((item) => item?.id === rowKey);

    if (product?.lotSerial !== data?.lotSerial || product?.quantity !== data?.quantity) {
      setFieldsValue({
        autologDisaggregateEvent: true,
        disableAutologDisaggregateEvent: true,
      });
    } else {
      setFieldsValue({
        autologDisaggregateEvent: false,
        disableAutologDisaggregateEvent: false,
      });
    }
  };
  const onSaveProduct = async (_rowKey: RecordKey, data: ReceiveProductItem) => {
    sactionRef.current?.cancelEditable(data?.id || 0);

    const existingProduct = products.find((product) => product.id === data?.id);
    if (existingProduct) {
      existingProduct.name = data?.name || '';
      existingProduct.parentProductId =
        data?.parentProductId?.replace('external_shipment', '')?.trim() || undefined;
      existingProduct.isAssignedProduct = data?.isAssignedProduct || false;
      existingProduct.quantity = data?.quantity;
      existingProduct.currentInventory = data?.currentInventory;
      existingProduct.instanceInventory = data?.instanceInventory;
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.lotSerial = data?.lotSerial || '';
      existingProduct.unitOfMeasure = data?.unitOfMeasure || '';
      existingProduct.date = data?.date;
      existingProduct.time = data?.time;
      existingProduct.eventDate = data?.eventDate;

      toggleDisaggregateEvent(_rowKey, data);

      // update product
      setProducts(products.map((p) => (p.id === existingProduct.id ? existingProduct : p)));
    } else {
      const productData: ReceiveProductItem = {
        id: data?.id || '',
        parentProductId:
          data?.parentProductId?.replace('external_shipment', '')?.trim() || undefined,
        name: data?.name || '',
        lotSerial: data?.lotSerial || '',
        primaryId: data?.primaryId || '',
        quantity: Number(data?.quantity || 0),
        unitOfMeasure: data?.unitOfMeasure || '',
        shippedFrom: data?.shippedFrom || '',
        lastEventId: data?.lastEventId || '',
        instanceInventory: Number(data?.instanceInventory || 0),
        date: data?.date,
        time: data?.time,
        eventDate: data?.eventDate,
      };
      // add product
      setProducts([...products, productData]);
    }
  };
  const onCancelProduct = async (rowKey: RecordKey, data: ReceiveProductItem) => {
    sactionRef.current?.cancelEditable(data?.id || 0);
    toggleDisaggregateEvent(rowKey, data);
  };

  return (
    <>
      {isSSCC && (
        <Typography.Text className={styles.sscctitle}>
          {getProductsT('sscc')}: {dataProducts?.[0]?.primaryId || ''} {t?.('shipped_from')}{' '}
          {dataProducts?.[0]?.senderName || ''}
        </Typography.Text>
      )}
      <GTable<ReceiveProductItem>
        key="sactionRef"
        actionRef={sactionRef}
        editableFormRef={editableFormRef}
        columns={columns({
          t,
          onEditRow,
          onDelRow,
          productChangeModal,
          lotChangeModal,
          quantityChangeModal,
          // @ts-ignore
          onChangeDate,
          multipleDates,
          isSSCC,
          isInternal,
          products,
          isSerial,
          identifier,
          // @ts-ignore
          editableFormRef,
        })}
        editable={{
          onSave: (rowKey, data) => onSaveProduct(rowKey, data),
          onCancel: async (rowKey, oldData, data) => onCancelProduct(rowKey, data),
        }}
        loading={isPreProductLoading || isLoading}
        options={{
          reload: false,
          setting: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        recordCreatorProps={false}
        onAddRecordClick={() => {
          sactionRef.current?.addEditRecord?.({
            id: Date.now().toString(),
          });
        }}
        value={products}
        enableRecordCreator={allowEdit}
        scroll={{ y: '50vh' }}
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
        }}
      />
    </>
  );
};
export default React.memo(ReceiveProducts);

import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { Alert, Col, Descriptions, Row, Typography } from 'antd';
import CopyText from 'components/CopyText';
import { TFunction } from 'react-i18next';
import styles from './index.module.less';

export interface DataItem {
  myAPIKey: string;
}

export type ColumnsType = {
  t: TFunction<'pages', 'network.invite'>;
  newAPIKey?: string;
};

export const FieldColumns = ({ t, newAPIKey }: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    title: <div className={styles.inivitetitle}>{t('generate_key.description')}</div>,
    hideInForm: !!newAPIKey,
    columns: [
      {
        title: t('generate_key.title'),
        dataIndex: 'myAPIKey',
        valueType: 'password',
        fieldProps: {
          placeholder: t('generate_key.title'),
        },
        tooltip: {
          icon: <InfoCircleOutlined />,
          placement: 'top',
          title: t('generate_key.api_key_tooltip'),
          key: null,
          type: '',
          props: null,
        },
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('generate_key.traceability_validation'),
            },
          ],
        },
      },
    ],
  },
  {
    valueType: 'group',
    hideInForm: !newAPIKey,
    title: (
      <Descriptions
        title={<div className={styles.inivitetitle}>{t('generate_key.description')}</div>}
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        size="small"
      >
        <Descriptions.Item
          label={
            <Typography.Text className={styles.label}>{t('generate_key.title')}</Typography.Text>
          }
        >
          <Row justify="space-between">
            <Col>
              <Typography.Text className={styles.inivitetitle}>{newAPIKey}</Typography.Text>
            </Col>
            <Col>{newAPIKey && <CopyText text={newAPIKey} />}</Col>
          </Row>
        </Descriptions.Item>
      </Descriptions>
    ),
    columns: [],
  },
  {
    valueType: 'group',
    title: (
      <Alert
        showIcon
        message={
          <Typography.Text className={styles.warntitle}>{`${t(
            'generate_key.warning',
          )}`}</Typography.Text>
        }
        description={
          <Typography.Text className={styles.inivitetitle}>
            {t('generate_key.warning_note')}
          </Typography.Text>
        }
        type="warning"
      />
    ),
    columns: [],
  },
];

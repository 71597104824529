import { ActionType, EditableFormInstance } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Form, Switch, message } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import {
  ActionButtons,
  AttributeSelect,
  DragHandle,
  DraggableContainerProps,
  GTable,
  SingleColumnTags,
  SortContainer,
  SortableItem,
  useAttStore,
} from 'components';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { SortEnd } from 'react-sortable-hoc';
import { useAttributes, useUpdateAttribute } from 'services/api';
import { AttributeResponse } from 'services/api/client/src';
import {
  errorHandler,
  filterTDataAttributes,
  filterTemplateAtrributes,
  hiddencol,
  mapTemplateFieldsToCustomAttributeTemplateField,
} from 'utils';
import {
  CustomAttribute,
  CustomDataColumnsFn,
  CustomDataColumnsType,
  CustomDataTemplateProps,
} from '../typings';
import OptionSelect, { DefaultField } from './OptionSelect';
import TemplateFieldType from './TemplateFieldType';
import styles from './index.module.less';
import useAttributeStore from './useAttributeStore';

const columns: CustomDataColumnsFn = ({
  onEditRow,
  onDelRow,
  onChangeAttribute,
  t,
  editableFormRef,
  isEdit = true,
  hasAdvancedSettings,
  records,
}) => [
  {
    dataIndex: 'id',
    width: 40,
    render: () => <DragHandle />,
    fieldProps: {
      disabled: true,
    },
    renderFormItem: () => null,
    className: 'drag-visible',
  },
  {
    ...hiddencol,
    dataIndex: 'attributeId',
  },
  {
    title: t('form_fields.custom_template_col_required'),
    dataIndex: 'required',
    valueType: 'switch',
    fieldProps: {
      size: 'small',
    },
    width: 90,
    render: (text, record) => <Switch checked={record.required} size="small" disabled />,
    className: 'drag-visible',
  },
  {
    title: t('form_fields.custom_template_col_field_attribute'),
    dataIndex: 'name',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('form_fields.custom_template_col_field_attribute_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t('form_fields.custom_template_col_field_attribute_placholder'),
      size: 'small',
    },
    renderFormItem: (row, { recordKey }) => (
      <AttributeSelect
        key="attributeselect"
        placeholder={t('form_fields.custom_template_col_field_attribute_placholder')}
        size="small"
        filterItem={filterTemplateAtrributes(records)}
        onChange={(value, option) => {
          // @ts-ignore
          const item: AttributeResponse = option?.itemProps;
          const fieldProperties = item?.fieldProperties;
          const values = fieldProperties?.values;
          const field = fieldProperties?.fields;
          const isNewAttribute = fieldProperties?.namespace?.name === 'newAttribute';
          onChangeAttribute(value, option);
          editableFormRef?.current?.setFieldsValue({
            [String(recordKey)]: {
              name: item?.name || '',
              attributeId: item?.id,
              dataAttribute: field?.propertyName || '',
              fieldType: field?.fieldType || 'Text',
              isNewAttribute,
              defaultValue: undefined,
              valueOptions: values?.valueOptions,
              namespace:
                fieldProperties?.namespace?.name === 'newAttribute' ||
                fieldProperties?.namespace?.name === 'custom'
                  ? 'Custom'
                  : fieldProperties?.namespace?.name,
              location: fieldProperties?.location,
            },
          });
        }}
      />
    ),
    width: 300,
    className: 'drag-visible',
  },
  {
    title: t('form_fields.custom_template_col_data_attribute'),
    dataIndex: 'dataAttribute',
    formItemProps: {
      hasFeedback: false,
      rules: [
        {
          required: true,
          message: t('form_fields.custom_template_col_data_attribute_req'),
        },
      ],
    },
    fieldProps: {
      placeholder: t('form_fields.custom_template_col_data_attribute'),
      size: 'small',
      disabled: true,
    },
    tooltip: {
      title: t('form_fields.custom_template_col_data_attribute_tooltip'),
      key: null,
      type: '',
      props: null,
    },
    width: 236,
  },
  {
    title: t('form_fields.custom_template_col_field_type'),
    dataIndex: 'fieldType',
    valueType: 'select',
    valueEnum: {
      Text: {
        text: t('text'),
        status: 'default',
      },
      Number: {
        text: t('number'),
        status: 'default',
      },
      Dropdown: {
        text: t('dropdown'),
        status: 'default',
      },
      RadioButton: {
        text: t('radio_button'),
        status: 'default',
      },
      Date: {
        text: t('date'),
        status: 'default',
      },
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('form_fields.custom_template_col_field_type_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: (form, { rowKey }) => {
      const isNewAttribute = editableFormRef?.current?.getFieldValue(
        String(rowKey),
      )?.isNewAttribute;
      return {
        placeholder: t('form_fields.custom_template_col_field_type'),
        size: 'small',
        disabled: !isNewAttribute,
        onChange: () => {
          editableFormRef?.current?.setFieldsValue({
            [String(rowKey)]: {
              defaultValue: undefined,
            },
          });
        },
      };
    },
    render: (text, record) => <TemplateFieldType fieldType={record?.fieldType || 'Text'} />,
    width: 200,
  },
  {
    title: t('form_fields.custom_template_col_values'),
    dataIndex: 'valueOptions',
    fieldProps: {
      placeholder: t('form_fields.custom_template_col_values'),
      size: 'small',
    },
    renderFormItem: (schema, config, form) => <OptionSelect form={form} config={config} />,
    render: (text, record) =>
      record?.fieldType === 'Dropdown' || record?.fieldType === 'RadioButton' ? (
        <SingleColumnTags
          values={record?.valueOptions || []}
          columnTitle={t('values')}
          modalTitle={record?.name || ''}
        />
      ) : (
        '-'
      ),
    width: 200,
  },
  {
    title: t('default_value'),
    dataIndex: 'defaultValue',
    width: 200,
    fieldProps: {
      size: 'small',
    },
    renderFormItem: (_schema, config, form) => <DefaultField config={config} form={form} />,
  },
  {
    title: t('standard'),
    dataIndex: 'location',
    width: 200,
    hideInTable: !hasAdvancedSettings,
    fieldProps: {
      size: 'small',
      placeholder: t('standard'),
    },
    valueEnum: {
      ILMD: t?.('imld'),
      Extension: t?.('extension'),
    },
  },
  {
    title: t('namespace'),
    dataIndex: 'namespace',
    width: 200,
    hideInTable: !hasAdvancedSettings,
    fieldProps: {
      size: 'small',
      placeholder: t('namespace'),
    },
    initialValue: 'Custom',
    valueEnum: {
      Custom: t?.('custom'),
      GDST: t?.('gdst'),
      CBV: t?.('cbv'),
    },
  },
  {
    ...hiddencol,
    dataIndex: 'hidden',
  },
  {
    dataIndex: 'actions',
    hideInTable: !isEdit,
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons record={record} onDelete={onDelRow} onEdit={onEditRow} />
    ),
    width: 100,
    fixed: 'right',
  },
];
const CustomDataTemplate: FC<CustomDataTemplateProps> = ({ form, templateQuery }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates.add_template' });
  const { setFieldsValue } = form;
  const templateData = templateQuery?.data;
  const templateLoading = templateQuery?.isLoading || false;
  const tactionRef = useRef<ActionType>();
  const {
    attribute: tmpAttribute,
    templateMode,
    setAttribute: setTmpAttribute,
  } = useAttributeStore();
  const { data: dataAttributes } = useAttributes();
  const { hasAdvancedSettings } = useAttStore();

  const isEdit = templateMode === 'edit';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const attributes: Array<CustomAttribute> = Form?.useWatch('customAttributes', form) || [];
  const setAttributes = useCallback(
    (value: Array<CustomAttribute>) =>
      setFieldsValue({
        customAttributes: value,
      }),
    [setFieldsValue],
  );
  const queryClient = useQueryClient();
  const updateAttribute = useUpdateAttribute(queryClient);
  const editableFormRef = useRef<EditableFormInstance<any>>();

  useEffect(() => {
    if (templateData) {
      setAttributes(
        templateData?.templateAttributes
          ?.map(mapTemplateFieldsToCustomAttributeTemplateField)
          ?.filter?.(filterTDataAttributes) || [],
      );
    }
  }, [templateData, setAttributes]);

  const onEditRow = (actionPayload?: CustomAttribute) => {
    tactionRef.current?.startEditable(actionPayload?.id || 0);
    setTmpAttribute({
      id: actionPayload?.id || Date.now().toString(),
      name: actionPayload?.name || '',
      dataAttribute: actionPayload?.dataAttribute || '',
      fieldType: actionPayload?.fieldType || '',
      valueOptions: actionPayload?.valueOptions || [],
      required: actionPayload?.required || false,
      defaultValue: actionPayload?.defaultValue,
      namespace: actionPayload?.namespace,
      location: actionPayload?.location,
    });
  };
  const onDelRow = async (actionPayload?: CustomAttribute) => {
    if (actionPayload) setAttributes(attributes.filter((item) => item.id !== actionPayload.id));
  };

  const onChangeAttribute: CustomDataColumnsType['onChangeAttribute'] = (value, option) => {
    if (value && option) {
      // @ts-ignore
      // itemprops will exist when the attribute is selected from the dropdown
      const item: CustomAttribute = option?.itemProps;
      setTmpAttribute({
        id: value || '0',
        name: item?.name || '',
        dataAttribute: item?.dataAttribute || '',
        fieldType: item?.fieldType || '',
        valueOptions: item?.valueOptions || [],
        defaultValue: item?.defaultValue,
        required: item?.required || false,
        namespace: item?.namespace,
        location: item?.location,
      });
    }
  };

  const onSaveAttribute = async (rowKey: RecordKey, data: CustomAttribute) => {
    try {
      if (data?.isNewAttribute) {
        const eAttribute = dataAttributes?.results?.find((item) => item.id === data?.attributeId);

        updateAttribute.mutate({
          id: data?.attributeId || '',
          attribute: {
            name: data?.name || '',
            fieldProperties: {
              ...eAttribute?.fieldProperties,
              fields: {
                propertyName: data?.dataAttribute || '',
                fieldType: data?.fieldType || '',
                required: data?.required || false,
              },
              location: data?.location,
              values: {
                defaultValue: data?.defaultValue,
                defaultValues: [],
                valueOptions: data?.valueOptions || [],
              },
              namespace: {
                name: data?.namespace,
                prefix: 'wc',
                uri: 'wholechain.com',
              },
            },
          },
        });
      }

      tactionRef.current?.cancelEditable(data?.id || 0);
      const existingAttribute = attributes.find((item) => item.id === data?.id);
      if (existingAttribute) {
        existingAttribute.name = tmpAttribute?.name || data?.name || '';
        existingAttribute.attributeId = data?.attributeId || '';
        existingAttribute.dataAttribute = data?.dataAttribute || '';
        existingAttribute.fieldType = data?.fieldType || '';
        existingAttribute.defaultValue = data?.defaultValue;
        existingAttribute.namespace = data?.namespace;
        existingAttribute.location = data?.location;
        existingAttribute.valueOptions = data?.valueOptions || [];
        existingAttribute.required = data?.required || false;
        existingAttribute.hidden = data?.hidden || false;

        setAttributes(
          attributes.map((p) => (p.id === existingAttribute.id ? existingAttribute : p)),
        );
      } else {
        const attributeData: CustomAttribute = {
          id: data?.id || '0',
          attributeId: data?.attributeId || '0',
          name: tmpAttribute?.name || '',
          dataAttribute: data?.dataAttribute || '',
          fieldType: data?.fieldType || '',
          valueOptions: data?.valueOptions || [],
          defaultValue: data?.defaultValue,
          required: data?.required || false,
          namespace: data?.namespace,
          location: data?.location,
          hidden: data?.hidden || false,
        };
        setAttributes([...attributes, attributeData]);
      }
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable([...attributes], oldIndex, newIndex);

        setAttributes([...newData]);
      }
    },
    [attributes, setAttributes],
  );
  const DraggableContainer: FC<DraggableContainerProps> = useCallback(
    (props) => (
      <SortContainer
        useDragHandle
        disableAutoscroll
        helperClass={styles['row-dragging']}
        onSortEnd={onSortEnd}
        {...props}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSortEnd],
  );

  const DraggableBodyRow: FC<any> = useCallback(
    (props) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      // @ts-ignore
      // eslint-disable-next-line react/destructuring-assignment
      const index = attributes?.findIndex((x) => x.id === props['data-row-key']) || 0;
      return <SortableItem {...props} index={index} />;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes],
  );

  const { body } = useMemo(
    () => ({ body: { wrapper: DraggableContainer, row: DraggableBodyRow } }),
    [DraggableContainer, DraggableBodyRow],
  );

  return (
    <GTable<CustomAttribute>
      key="sttribt"
      editableFormRef={editableFormRef}
      actionRef={tactionRef}
      columns={columns({
        onEditRow,
        onDelRow,
        onChangeAttribute,
        t,
        editableFormRef,
        isEdit,
        hasAdvancedSettings,
        records: attributes,
      })}
      editable={{
        onSave: (rowKey, data) => onSaveAttribute(rowKey, data),
        onCancel: async (_rowKey, data) => {
          tactionRef.current?.cancelEditable(data?.id || 0);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={() => {
        tactionRef.current?.addEditRecord?.({
          id: Date.now().toString(),
        });
      }}
      value={attributes}
      enableRecordCreator={isEdit}
      components={{
        body,
      }}
      addBtnText={t('form_fields.custom_template_attribute_add_title')}
      loading={templateLoading}
      scroll={{ x: 700 }}
    />
  );
};
export default React.memo(CustomDataTemplate);

// @ts-nocheck
/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { GraphData, NodeConfig } from '@antv/g6/lib/types';
import { Form, message } from 'antd';
import { useActions } from 'hooks';
import moment from 'moment';
import {
  AggregateDataItem,
  AggregateFormFields,
  Header,
  useEventActions,
  useEventsStore,
  useTitle,
} from 'pages/Events';

import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EnrichedWorkflowAggregateEvent } from 'services/api/client/src';
import {
  dateFormat,
  dateLocaleFormat,
  errorHandler,
  getLocationById,
  getLocationNameById,
  getTimezoneOffset,
  getUom,
  onCreateStoreInstance,
  timeLocaleFormat,
} from 'utils';
import styles from '../index.module.less';
import { EventProps, WorkflowEvent } from '../typings';

type DataItem = AggregateDataItem;
const Aggregate: FC<EventProps> = ({
  form,
  drawer,
  event,
  isVisible,
  selectedInstances,
  instances,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.aggregate' });
  useTitle(t('title'));
  const locationDisabled = true;
  const [mainForm] = Form.useForm<DataItem>();
  const { setFieldsValue } = mainForm;

  const graphData: GraphData = form?.getFieldValue('graphData') || {
    nodes: [],
    edges: [],
  };

  const {
    disabledDate,
    disabledTime,
    onChangeDate,
    product: dataProduct,
    lastEventDate,
    defaultTimezone,
    isSerial,
    identifier,
    locationId,
    locationName,
    onChangeLocation,
  } = useEventActions({
    productId: event?.productId || undefined,
    locationId: event?.locationId || selectedInstances?.[0]?.locationId || undefined,
    eventDate: dateLocaleFormat(selectedInstances?.[0]?.eventDate || new Date()) || '',
  });
  const { hasAdvancedSettings, dataEntryMethod, template, setSubmitting, setAdvancedSettings } =
    useEventsStore();
  const { locations } = useActions();
  const onMount = useCallback(() => {
    setAdvancedSettings(false);
    if (event?.eventData) {
      const edata = event?.eventData as EnrichedWorkflowAggregateEvent;
      setFieldsValue({
        date: edata?.eventTime ? dateFormat(edata?.eventTime) : undefined,
        time: edata?.eventTime ? timeLocaleFormat(edata?.eventTime) : undefined,
        location: edata?.locationId,
        poNumber: edata?.purchaseOrder,
        bizStep: edata?.bizStep,
        disposition: edata?.disposition,
        timeZone: edata?.eventTimeZone,
        sscc: edata?.containerIdentifier,
        eventProducts:
          edata?.newProducts?.map((p) => ({
            id: p?.lotSerial || '',
            name: p?.productName || '',
            productId: p?.productId || '',
            parentProductId: p?.productId || '',
            // @ts-ignore
            primaryId: p?.instanceId || '',
            lotSerial: p?.lotSerial || '',
            lotID: p?.lotSerial || '',
            quantity: p?.quantity || 0,
            instanceInventory: p?.quantity || 0,
            unitOfMeasure: p?.unitOfMeasure || 'LBS',
            currentInventory: p?.currentInventory || 0,
          })) || [],
      });
    }
  }, [event, setAdvancedSettings, setFieldsValue]);
  /* effect to sync eventData in event */
  useEffect(() => onMount(), [onMount]);

  const onCloseDrawer = () => {
    mainForm?.resetFields();
    drawer?.closeDrawer();
  };

  const onFormSubmit = async (formData: DataItem) => {
    try {
      const location = getLocationById(formData.location, locations);
      const updatedBody: EnrichedWorkflowAggregateEvent = {
        id: event?.id,
        eventIdInTemplate: event?.id,
        eventId: event?.id,
        sourceId: selectedInstances?.map((i) => i?.id || '') || [],
        bizStep: formData.bizStep,
        disposition: formData.disposition,
        eventTime: new Date(moment(`${formData.date} ${formData.time}`).toISOString()),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        purchaseOrder: formData.poNumber,
        containerIdentifier: formData.sscc,
        products: formData.eventProducts.map((p) => ({
          instanceId: p.primaryId,
          quantity: Number(p.quantity || 0),
        })),
        newProducts: formData.eventProducts.map((p) => ({
          instanceId: p.primaryId,
          quantity: Number(p?.quantity || 0),
          productId: p.parentProductId,
          productName: p.name,
          currentInventory: p?.currentInventory || 0,
          lotSerial: p.lotSerial,
          unitOfMeasure: p.unitOfMeasure,
          unitDescriptor: p.unitDescriptor,
          definedUnits: p.definedUnits,
        })),
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        templateName: template?.name,
        customProperties:
          template?.templateFields?.map((field, idx) => ({
            name: field?.name || field?.propertyName,
            value: formData?.customProperties?.[idx]
              ? String(formData.customProperties?.[idx])
              : '',
            namespace: field?.namespace?.prefix || 'wc',
          })) || [],
      };
      onCreateStoreInstance(
        {
          id: event?.id,
          name: formData.sscc,
          productId: undefined,
          instanceId: event?.id,
          containerId: event?.id,
          primaryId: formData.sscc,
          lotSerial: formData.sscc,
          quantity: undefined,
          unitOfMeasure: undefined,
          eventName: 'Aggregate',
          location: location?.name || '',
          locationAddress: location?.address?.line2 || '',
          locationId: formData.location || '',
          destinationId: undefined,
          eventId: event?.id,
          isContainer: true,
          containerIdentifier: formData.sscc,
          containerItems: formData.eventProducts.map((p) => ({
            productId: p.parentProductId,
            instanceId: p.primaryId,
            lotSerial: p.lotSerial,
            quantity: Number(p.quantity || 0),
            unitOfMeasure: p.unitOfMeasure,
            unitDescriptor: p.unitDescriptor,
            definedUnits: p.definedUnits,
          })),
          tradePartnerName: location?.tradePartnerName || '',
          address: location?.address,
          eventDate: updatedBody.eventTime,
          loggedDate: new Date(),
          purchaseOrder: formData.poNumber,
          shipmentType: undefined,
          shipped: false,
          productIdentifierType: selectedInstances?.[0]?.productIdentifierType || '',
          bizStep: formData.bizStep,
          disposition: formData.disposition,
          timezone: getTimezoneOffset(formData.timeZone),
          templateId: template?.id,
        },
        instances,
        form,
      );
      const updatedNodes = graphData.nodes?.map((item: WorkflowEvent) => {
        if (item.id === event?.id) {
          const updatedNode: NodeConfig = {
            id: item?.id || '',
            eventType: 'Aggregate',
            eventTime: updatedBody?.eventTime ? dateFormat(updatedBody?.eventTime) : undefined,
            name: formData?.sscc || '',
            primaryId: formData?.sscc || '',
            eventTemplateId: item?.eventTemplateId,
            quantity: 0,
            unitOfMeasure: '',
            eventTemplateName: getLocationById(formData.location, locations)?.address?.line2 || '',
            locationId: formData?.location,
            locationName: getLocationNameById(formData.location, locations),
            productId: undefined,
            productName: undefined,
            colourIndex: 'colour_4',
            children: [],
          };
          return {
            ...item,
            ...updatedNode,
            eventData: updatedBody,
          };
        }
        return item;
      });
      const updatedGraphData = {
        ...graphData,
        nodes: updatedNodes,
      };

      form?.setFieldsValue({
        // @ts-ignore
        graphData: updatedGraphData,
      });
      drawer?.closeDrawer();
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);
      await onFormSubmit(formData);
      setSubmitting(false);
      onCloseDrawer();
    } catch (error) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={AggregateFormFields({
        disabledDate,
        disabledTime,
        onChangeDate,
        hasAdvancedSettings,
        dataEntryMethod,
        lastEventDate,
        defaultTimezone,
        isSerial,
        identifier,
        selectedInstances,
      })}
      grid
      rowProps={{ gutter: 32 }}
      onFinish={onFinish}
      // @ts-ignore
      title={
        <Header
          isManual={dataEntryMethod === 'manual'}
          title={t('title')}
          form={mainForm}
          locationId={locationId?.trim() ? locationId?.trim() : undefined}
          locationName={locationName}
          locationDisabled={locationDisabled}
          onChangeLocation={onChangeLocation}
        />
      }
      submitter={{
        searchConfig: {
          submitText: t('add_event_title'),
          resetText: t('cancel_btn_title'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
      }}
      initialValues={{
        unitOfMeasure: getUom({ product: dataProduct }),
      }}
      visible={isVisible ? drawer?.visible || false : false}
      drawerProps={{
        destroyOnClose: false,
        height: '100%',
        placement: 'top',
        size: 'large',
        className: styles.eventdrawer,
        ...drawer,
      }}
    />
  );
};

export default Aggregate;

import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message } from 'antd';
import useTableRowSelection from 'hooks/useTableRowSelection';
import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import errorHandler from 'utils/errorHandler';
import useDeleteActions from '../hook/useDeleteActions';
import { DataItem, MyMultiLocationTableColumns } from './MyMultiLocationTable.fields';
import styles from './index.module.less';
import { ShareDocProps } from './typings';

const DeleteMultiForm: FC<ShareDocProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.delete_location' });
  const modalFormRef = useRef<ProFormInstance>();
  const { selectedRowKeys, onRowSelectionChange } = useTableRowSelection();
  const { archiveMultileLocations, loading } = useDeleteActions();
  const note: string = t(`note`);

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onFinish = async () => {
    try {
      const success = await archiveMultileLocations(selectedRowKeys);
      if (success) {
        closeModal();
      }
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      title={t('modal_title')}
      formRef={modalFormRef}
      columns={MyMultiLocationTableColumns({
        selectedRowKeys,
        onRowSelectionChange,
        note,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        maskClosable: false,
        width: '40%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('delete'),
          resetText: t('cancel'),
        },
        submitButtonProps: {
          shape: 'round',
          disabled: !selectedRowKeys.length,
          loading,
        },
        resetButtonProps: {
          shape: 'round',
          className: styles.canceltext,
        },
        render: (_, defaultDoms) => defaultDoms,
      }}
      initialValues={{
        connectionType: 'Buyer',
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default React.memo(DeleteMultiForm);

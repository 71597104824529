import { message, Space, TablePaginationConfig, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { FilterValue, Key } from 'antd/lib/table/interface';
import { MarkerItemProps } from 'components/GMap/typings';
import { useTableSearchFilter } from 'hooks';
import useListLocation from 'hooks/useListLocation';
import { GetLocationsOptionalParams } from 'hooks/useListLocation/typings';
import { useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useTradePartnerFilters } from 'services/api';
import { AttributeDefaultValueContract, ShareLocationRequest } from 'services/api/client/src';
import { useShareLocations, useUnarchiveLocationById } from 'services/api/useLocations';
import { errorHandler, flattenAddress } from 'utils';
import styles from '../index.module.less';
import { NetworkItem } from '../typings';

const useNetworkActions = (initParams?: GetLocationsOptionalParams) => {
  const [params, setParams] = useState<GetLocationsOptionalParams | undefined>(initParams);
  const { data, isLoading, isFetching, totalItems } = useListLocation(params);
  const navigate = useNavigate();
  const locations = useMemo(() => data || [], [data]);

  const queryClient = useQueryClient();
  const shareLocationAPI = useShareLocations(queryClient);
  /* tradepartners */
  const coreTradePartners = useTradePartnerFilters({
    types: ['Wholechain', 'NonWholechain', 'Self'],
  });
  const unarchiveLocation = useUnarchiveLocationById(queryClient);

  const { t } = useTranslation('pages', { keyPrefix: 'network' });

  const { filters: nameSearchfilter } = useTableSearchFilter({
    title: t('network_table_cols.location_name'),
  });
  const { filters: addressSearchfilter } = useTableSearchFilter({
    title: t('network_table_cols.location_address'),
  });
  const records = useMemo(
    () =>
      locations?.map((item) => ({
        id: item.id || '',
        name: item.name,
        company: item.tradePartnerName,
        locationAddress: item.address?.displayAddress
          ? item.address?.displayAddress
          : flattenAddress(item.address),
        lat: Number(item?.address?.geoCoordinates?.latitude),
        lng: Number(item?.address?.geoCoordinates?.longitude),
        companyId: item.tradePartnerId,
        isOwnAccount: item?.networkStatus === 'Self',
        ownerHasWholechainAccount: !!item?.wholechainId,
        status:
          !!item?.wholechainId || item?.networkStatus === 'Self' ? 'Wholechain' : 'Non-Wholechain',
      })) || [],
    [locations],
  );

  const markers: Array<MarkerItemProps> = useMemo(
    () =>
      records?.map((el) => ({
        id: el.id,
        position: {
          lat: el?.lat || 47.116386,
          lng: el?.lng || -101.299591,
        },
        title: el.company,
        companyId: el.companyId,
        shortDesc: el.name,
        longDesc: el.locationAddress,
        isMyLocation: el.isOwnAccount,
      })) || [],
    [records],
  );

  const shareUserLocations = async (locIds: string[], wcIds: string[]) => {
    try {
      const reqList: ShareLocationRequest = {
        locationIds: locIds,
        wholechanIds: wcIds,
        // message: note,
      };
      await shareLocationAPI.mutateAsync({
        options: reqList,
      });
      return true;
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
    return false;
  };
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
  ) => {
    const apiFilters: GetLocationsOptionalParams = {
      partnerIds: (filters?.company as string[]) || [],
      locationName: (filters?.name?.[0] as string) || undefined,
    };
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        ...apiFilters,
        // @ts-ignore
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        ...apiFilters,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };
  const onViewArchived = useCallback(() => {
    navigate({
      pathname: `/settings`,
      search: `?tab=archived&section=locations`,
    });
  }, [navigate]);

  const onViewUnarchived = useCallback(() => {
    navigate({
      pathname: `/network`,
    });
  }, [navigate]);
  const onUnarchiveLocation = async (actionPayload?: NetworkItem) => {
    const name = actionPayload?.name || 'location';
    const success = message.loading('Unarchiving location...', 0);
    try {
      await unarchiveLocation.mutateAsync({
        ids: [String(actionPayload?.id || '')],
      });
      message.success(
        <Space>
          {t('unarchive_success', { name })}
          <Typography.Link
            onClick={(e) => {
              e.preventDefault();
              onViewUnarchived();
            }}
          >
            {t('view_networks')}
          </Typography.Link>
        </Space>,
      );
      success();
    } catch (error) {
      success();
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const onUnarchiveLocationMultiple = useCallback(
    async (rowKeys?: Array<Key>) => {
      message.loading('Unarchiving locations...', 1);
      try {
        if (rowKeys?.length) {
          const promises = rowKeys.map(async (id) =>
            unarchiveLocation.mutateAsync({
              ids: [String(id) || ''],
            }),
          );
          await Promise.all(promises);
          message.success(
            <Space>
              {t('unarchive_success_many', { count: rowKeys?.length || 0 })}
              <Typography.Link
                onClick={(e) => {
                  e.preventDefault();
                  onViewUnarchived();
                }}
              >
                {t('view_networks')}
              </Typography.Link>
            </Space>,
          );
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [t, unarchiveLocation, onViewUnarchived],
  );

  return {
    params,
    records,
    markers,
    isLoading,
    coreTradePartners,
    totalItems,
    onTableChange,
    shareUserLocations,
    setParams,
    filters: [nameSearchfilter, addressSearchfilter],
    onUnarchiveLocation,
    onUnarchiveLocationMultiple,
    onViewArchived,
    isFetching,
  };
};

export const renderInfoWindow = (activeMarker: MarkerItemProps) => (
  <div className={styles.popup}>
    <Space>
      <Link to={`/network/${activeMarker?.companyId}`} title={`View ${activeMarker?.title}`}>
        <Typography.Text className={styles.infotitle} strong>
          {activeMarker?.title || ''}
        </Typography.Text>
      </Link>
    </Space>
    <Space direction="vertical" className={styles.infowindow}>
      <Link
        className={styles.infosubtitle}
        to={String(`/network/${activeMarker?.companyId}/${activeMarker?.id}`)}
      >
        {activeMarker?.shortDesc || ''}
      </Link>
      <Typography.Text className={styles.infodesc}>{activeMarker?.longDesc || ''}</Typography.Text>
    </Space>
  </div>
);

export const getSelectOptions = (data: string[]) => {
  const AttributeOptions: Array<DefaultOptionType> =
    data?.map((item) => ({
      label: String(item),
      value: String(item),
      itemProps: item,
    })) || [];

  return AttributeOptions;
};

export const getDefaultValue = (locId: string, defaultValues: AttributeDefaultValueContract[]) => {
  const attribute = defaultValues?.find((item) => item?.locationId === locId);
  if (attribute) {
    return attribute.value;
  }
  return '';
};

const getAlreadyAddedValue = (locId: string, defaultValues: AttributeDefaultValueContract[]) => {
  const attribute = defaultValues?.find((item) => item?.locationId === locId);
  if (attribute) {
    return attribute;
  }
  return false;
};

export const filterDefaultAttribute = (
  locationIds: string[],
  defaultValues: AttributeDefaultValueContract[],
) => {
  const filterArray = defaultValues?.filter((item) =>
    locationIds?.includes(String(item?.locationId)),
  );
  return filterArray;
};

export const updateDefaultValue = (
  locObj: DefaultOptionType[],
  defaultValues?: AttributeDefaultValueContract[],
  value?: String,
) =>
  locObj?.reduce(
    (acc: AttributeDefaultValueContract[], loc) => {
      const attribute = acc ? acc.filter((item) => item?.locationId !== loc?.itemProps?.id) : [];
      return [
        ...attribute,
        {
          locationId: loc?.itemProps?.id,
          locationName: loc?.itemProps?.name,
          value: value as string,
        },
      ];
    },
    [...(defaultValues || [])],
  ) || [];

export const updateSingleDefaultValue = (
  locId: string,
  defaultValues: AttributeDefaultValueContract[],
  value: AttributeDefaultValueContract,
) => {
  const attribute = defaultValues ? defaultValues.filter((item) => item?.locationId !== locId) : [];
  return [...attribute, value];
};

export const addNewDefaultValue = (
  locObj: DefaultOptionType[],
  defaultValues?: AttributeDefaultValueContract[],
  value?: String,
  t?: TFunction<'pages', 'network.add_attributes'>,
) => {
  let showMessage = true;

  return (
    locObj?.reduce(
      (acc: AttributeDefaultValueContract[], loc) => {
        const otherAttributes = acc
          ? acc.filter((item) => item?.locationId !== loc?.itemProps?.id)
          : [];
        const attribute = getAlreadyAddedValue(loc?.itemProps?.id, acc);
        if (attribute && showMessage) {
          message.info(t ? t('added_warning') : '');
          showMessage = false;
        }
        return [
          ...otherAttributes,
          attribute || {
            locationId: loc?.itemProps?.id,
            locationName: loc?.itemProps?.name,
            value: value as string,
          },
        ];
      },
      [...(defaultValues || [])],
    ) || []
  );
};

export const removeDefaultValue = (
  locId: string,
  defaultValues?: AttributeDefaultValueContract[],
) => {
  const attribute = defaultValues
    ? defaultValues?.filter((item) => item?.locationId !== locId)
    : [];
  return [...attribute];
};

export default useNetworkActions;

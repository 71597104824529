import { PlusOutlined } from '@ant-design/icons';
import { Divider, Space } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.less';
import { ProductDropdownProps } from './typings';

const ProductDropdown: FC<ProductDropdownProps> = ({ menu, products, product, t }) => (
  <>
    {menu}
    <Divider className={styles.dropdowndivider} />

    <Link
      to={`/products/add${
        (product || products?.[0])?.shipmentType !== 'Internal' ? '?from=receive' : ''
      }&productId=${product?.productId || products?.[0]?.productId || ''}&productName=${
        product?.name || products?.[0]?.productName || ''
      }&senderName=${product?.shippedFrom || products?.[0]?.senderName || ''}
      `}
      className={styles.addproductlink}
    >
      <Space>
        <PlusOutlined />
        {t('new_product')}
      </Space>
    </Link>
  </>
);
export default ProductDropdown;

import { Segmented, Space, Typography } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { ShareDocumentModal, TitleBar } from 'components';
import GTable from 'components/GTable';
import { useDocumentActions, useDocumentFilters } from 'pages/Documents';
import { DocumentItem } from 'pages/Documents/typings';
import { columns, generalActionItems } from 'pages/Products/ProductDocuments';
import { ProductItem } from 'pages/Products/typings';
import pluralize from 'pluralize';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useProductById } from 'services/api/useProducts';
import { DocumentTitleProp, LocationState } from './typings';

const ProductsTitle: FC<DocumentTitleProp> = ({
  selectedRowKeys,
  product,
  eventId,
  onActionItemClick,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });
  const sections = useMemo(() => [t('details'), t('documents')], [t]);
  const [section, setSection] = useState<string | number>(sections[1]);
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const isContainerParam = queryParams?.get('isContainer') || false;

  const location = useLocation() as LocationState;

  const onSegmentedButtonClick = (value: SegmentedValue) => {
    setSection(value);
    switch (value) {
      case t('details'):
        navigate({
          pathname: '../',
          search: isContainerParam
            ? `?eventId=${eventId || '1'}&isContainer=${isContainerParam}`
            : `?eventId=${eventId || '1'}`,
        });
        break;
      case t('documents'):
        break;

      default:
        break;
    }
  };

  const onBack = () => {
    const locationFrom = location.state?.from;
    if (locationFrom) {
      navigate(`${locationFrom}`);
    } else {
      navigate('../../');
    }
  };

  return (
    <TitleBar
      title={
        <Space>
          {product?.name || ''}
          <Segmented
            value={section || sections[0]}
            options={sections}
            onChange={onSegmentedButtonClick}
          />
        </Space>
      }
      extra={
        selectedRowKeys.length > 0 && (
          <Typography.Title level={5} className="selectiontext">
            {t('selection_text', {
              count: selectedRowKeys.length,
              type: t(`${pluralize('Document', selectedRowKeys.length)?.toLowerCase()}`),
            })}
          </Typography.Title>
        )
      }
      onBack={onBack}
      actionItems={generalActionItems({ selectedRowKeys, t })}
      onActionItemClick={onActionItemClick}
    />
  );
};

const EventDocuments = () => {
  const { productId = '1' } = useParams();
  const [queryParams] = useSearchParams();
  const eventId = queryParams?.get('eventId') || '1';

  const { data: currentProduct } = useProductById(productId);
  const navigate = useNavigate();
  const {
    params,
    records,
    totalItems,
    isLoading,
    filters: [productSearchfilter],
    onTableChange,
    coreCompanyFilter,
    tradePartnersFilter,
    locationsFilter,
    t,
  } = useDocumentFilters({
    pageNumber: 1,
    pageSize: 20,
    eventId,
    // eventsHistory: true,
    // status: ['Archived', 'Active'],
  });

  const {
    onDownloadDocumentMultiple,
    onArchiveDocumentMultiple,
    onShareDocumentMultiple,
    shareModal,
    onDownloadDocument,
    copyURL,
    onArchiveDocument,
    onShareDocument,
    selectedRowKeys,
    onRowSelectionChange,
    resetRowSelection,
  } = useDocumentActions();

  const product: ProductItem = {
    id: productId,
    epcUrn: currentProduct?.urn || '',
    name: currentProduct?.name || '',
    currentInventory: currentProduct?.currentInventory || 0,
  };
  const handleActionsMenuClick = async (actionItemKey: string, actionPayload?: DocumentItem) => {
    switch (actionItemKey) {
      case 'view':
        navigate({ pathname: `${actionPayload?.id}` });
        break;
      case 'download':
        onDownloadDocument(actionPayload);
        break;
      case 'share':
        onShareDocument(actionPayload);
        break;
      case 'email':
        break;
      case 'copyURL':
        copyURL(actionPayload);
        break;

      case 'archive':
        onArchiveDocument(actionPayload);
        break;

      default:
        break;
    }
  };

  const onActionItemClick = useCallback(
    (actionItemKey: string) => {
      switch (actionItemKey) {
        case 'add_document':
          navigate({ pathname: 'add' });
          break;
        case 'download':
          onDownloadDocumentMultiple(selectedRowKeys);
          break;
        case 'share':
          onShareDocumentMultiple(selectedRowKeys as Array<string>);
          break;

        case 'archive':
          onArchiveDocumentMultiple(selectedRowKeys);
          break;

        default:
          break;
      }
    },
    [
      navigate,
      onArchiveDocumentMultiple,
      onDownloadDocumentMultiple,
      onShareDocumentMultiple,
      selectedRowKeys,
    ],
  );
  return (
    <>
      <ShareDocumentModal modal={shareModal} cleanUpAfterShare={resetRowSelection} />
      <GTable<DocumentItem>
        headerTitle={
          <ProductsTitle
            selectedRowKeys={selectedRowKeys}
            product={product}
            eventId={eventId}
            onActionItemClick={onActionItemClick}
          />
        }
        columns={columns({
          productSearchfilter,
          handleActionsMenuClick,
          coreCompanyFilter,
          tradePartnersFilter,
          locationsFilter,
          t,
        })}
        value={records}
        enableRowSelection
        loading={isLoading}
        scroll={{ x: 2500, y: 1000 }}
        pagination={{
          defaultPageSize: params.pageSize,
          total: totalItems,
          showTotal: (total, range) =>
            t('doc_table_pagi', { range0: range[0], range1: range[1], total }),
        }}
        columnsState={{
          persistenceKey: 'product-event-documents',
        }}
        rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange }}
        // @ts-ignore
        onTableChange={onTableChange}
      />
      <Outlet />
    </>
  );
};

export default React.memo(EventDocuments);

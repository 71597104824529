// @ts-nocheck
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { GraphData, NodeConfig } from '@antv/g6/lib/types';
import { Form, message } from 'antd';
import { useActions } from 'hooks';
import moment from 'moment';
import {
  CommissionDataItem,
  CommissionFormFields,
  Header,
  useEventActions,
  useEventsStore,
  useTitle,
} from 'pages/Events';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CreateCommissionRequest, EnrichedWorkflowObserveEvent } from 'services/api/client/src';
import {
  dateFormat,
  dateLocaleFormat,
  errorHandler,
  getLocationById,
  getLocationNameById,
  getTimezoneOffset,
  getUom,
  onCreateStoreInstance,
  timeLocaleFormat,
} from 'utils';
import styles from '../index.module.less';
import { EventProps, WorkflowEvent } from '../typings';

type DataItem = CommissionDataItem;
const Observe: FC<EventProps> = ({
  form,
  drawer,
  event,
  isVisible,
  selectedInstances,
  instances,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.observe' });
  useTitle(t('title'));
  const locationDisabled = useMemo(() => (selectedInstances?.length || 0) > 0, [selectedInstances]);
  const [mainForm] = Form.useForm<DataItem>();
  const formProductId = Form.useWatch(['product'], mainForm);
  const { setFieldsValue } = mainForm;

  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const graphData: GraphData = form?.getFieldValue('graphData');
  const {
    containerIdArray,
    productId,
    product: dataProduct,
    lotId,
    defaultTimezone,
    isSerial,
    identifier,
    locationId,
    onChangeTemplate,
    onChangeLocation,
    disabledDate,
    disabledTime,
    onChangeDate,
  } = useEventActions({
    productId: event?.productId || selectedInstances?.[0]?.productId || undefined,
    locationId: event?.locationId || selectedInstances?.[0]?.locationId || undefined,
    eventDate: dateLocaleFormat(selectedInstances?.[0]?.eventDate || new Date()) || '',
  });
  const { hasAdvancedSettings, dataEntryMethod, template, setSubmitting, setTemplate } =
    useEventsStore();
  const {
    locations,
    product,
    template: initTemplate,
  } = useActions({
    productId: formProductId,
    templateId: event?.eventData?.templateId || event?.eventTemplateId,
  });

  const navigate = useNavigate();
  const onMount = useCallback(() => {
    if (event) {
      const edata = event?.eventData as EnrichedWorkflowObserveEvent;
      setFieldsValue({
        product: selectedInstances?.[0]?.productId || edata?.newProducts?.[0]?.productId,
        date: edata?.eventTime ? dateFormat(edata?.eventTime) : undefined,
        time: edata?.eventTime ? timeLocaleFormat(edata?.eventTime) : undefined,
        unitOfMeasure:
          selectedInstances?.[0]?.unitOfMeasure || edata?.newProducts?.[0]?.unitOfMeasure,
        // @ts-ignore
        lotID: selectedInstances?.[0]?.lotSerial || edata?.newProducts?.[0]?.instanceId,
        quantity: selectedInstances?.[0]?.quantity || edata?.newProducts?.[0]?.quantity,
        location: selectedInstances?.[0]?.locationId || edata?.locationId,
        poNumber: edata?.purchaseOrder,
        customData: event?.eventTemplateId || edata?.templateId || undefined,
        bizStep: edata?.bizStep,
        disposition: edata?.disposition || 'urn:epcglobal:cbv:disp:in_progress',
      });
    }
  }, [selectedInstances, event, setFieldsValue]);
  const onGetInitTemplate = useCallback(() => {
    if (event && initTemplate) {
      const edata = event?.eventData as EnrichedWorkflowObserveEvent;
      const templateId = edata?.templateId || event?.eventTemplateId || '';
      onChangeTemplate?.(
        mainForm,
        {
          itemProps: initTemplate,
        },
        templateId,
        locationId,
      );
      if (edata) {
        mainForm.setFieldsValue({
          customProperties: edata?.customProperties?.map((el) => el?.value || '') || [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTemplate, event]);
  /* effect to get updated values */
  useEffect(() => onMount(), [onMount]);
  useEffect(() => onGetInitTemplate(), [onGetInitTemplate]);

  const onCloseDrawer = () => {
    mainForm?.resetFields();
    drawer?.closeDrawer();
  };
  const onCSVSubmit = async (formData: DataItem) => {
    const values = formData?.csvData;
    try {
      if (values?.length) {
        await editableTableFormRef.current?.validateFields();

        const requests: Array<CreateCommissionRequest> = [];
        values?.forEach((value) => {
          const reqData: CreateCommissionRequest = {
            eventTime: new Date(moment(`${value.date} ${value.time}`).toISOString()),
            eventTimeZone: getTimezoneOffset(value.timeZone),
            bizStep: value.bizStep,
            disposition: value.disposition,
            locationId: formData.location,
            purchaseOrder: value.poNumber,
            products: [
              {
                productId: formData.product,
                quantity: !isSerial ? Number(value?.quantity || 0) : 1,
                lotSerial: value.lotID,
              },
            ],
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            templateName: template?.name,
            customProperties: [
              ...(template?.templateFields?.map((field) => ({
                name: field?.name || field?.propertyName,
                value: String(value?.[String(field?.propertyName || field?.name || '')] || ''),
                namespace: field?.namespace?.prefix || 'wc',
              })) || []),
            ],
          };
          requests.push(reqData);
        });
        setSubmitting(false);
        navigate(-1);
      } else {
        throw new Error('No data to submit');
      }
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ CSV upload commission onFormSubmit ~ error', error);

      throw error;
    }
  };

  const onFormSubmit = async (formData: DataItem) => {
    try {
      const updatedBody: EnrichedWorkflowObserveEvent = {
        id: event?.id,
        eventIdInTemplate: event?.id,
        eventId: event?.id,
        sourceId: selectedInstances?.map((i) => i?.id || '') || [],
        bizStep: formData.bizStep,
        disposition: formData.disposition,
        eventTime: new Date(moment(`${formData.date} ${formData.time}`).toISOString()),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        purchaseOrder: formData.poNumber,
        containerIdentifiers: containerIdArray,
        products: !containerIdArray?.length
          ? [
              {
                instanceId: selectedInstances?.[0]?.instanceId,
                quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
              },
            ]
          : [],
        newProducts: !containerIdArray?.length
          ? [
              {
                productId: formData.product,
                // @ts-ignore
                instanceId: formData?.lotID,
                lotSerial: formData.lotID,
                quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
              },
            ]
          : [],

        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        templateName: template?.name,
        customProperties:
          template?.templateFields?.map((field, idx) => ({
            name: field?.name || field?.propertyName,
            value: formData?.customProperties?.[idx]
              ? String(formData.customProperties?.[idx])
              : '',
            namespace: field?.namespace?.prefix || 'wc',
          })) || [],
      };
      const location = getLocationById(formData.location, locations);

      onCreateStoreInstance(
        {
          id: event?.id,
          name: product?.name || '',
          productId: formProductId,
          instanceId: formData?.id,
          primaryId: selectedInstances?.[0]?.primaryId || '',
          lotSerial: selectedInstances?.[0]?.primaryId || '',
          quantity: selectedInstances?.[0]?.quantity || 0,
          unitOfMeasure: selectedInstances?.[0]?.unitOfMeasure || 'LBS',
          eventName: 'Observe',
          location: location?.name || '',
          locationAddress: location?.address?.line2 || '',
          locationId: formData.location || '',
          destinationId: undefined,
          eventId: event?.id,
          isContainer: false,
          containerId: undefined,
          containerIdentifier: '',
          containerItems: [],
          tradePartnerName: location?.tradePartnerName || '',
          address: location?.address,
          eventDate: updatedBody.eventTime,
          loggedDate: new Date(),
          purchaseOrder: formData.poNumber,
          shipmentType: undefined,
          shipped: false,
          productIdentifierType: product?.productIdentifierType,
          bizStep: formData.bizStep,
          disposition: formData.disposition,
          timezone: getTimezoneOffset(formData.timeZone),
          templateId: template?.id,
        },
        instances,
        form,
      );
      const updatedNodes = graphData.nodes?.map((item: WorkflowEvent) => {
        if (item.id === event?.id) {
          const updatedNode: NodeConfig = {
            id: item?.id || '',
            eventType: 'Observe',
            eventTime: updatedBody?.eventTime ? dateFormat(updatedBody?.eventTime) : undefined,
            name: product?.name || '',
            primaryId: selectedInstances?.[0]?.primaryId || '',
            quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
            unitOfMeasure: selectedInstances?.[0]?.unitOfMeasure || 'LBS',
            eventTemplateId: item?.eventTemplateId,
            eventTemplateName: location?.address?.line2 || location?.name || '',
            locationId: formData?.location,
            locationName: getLocationNameById(formData.location, locations),
            productId: formData.product,
            productName: product?.name || '',
            colourIndex: 'colour_4',
            children: [],
          };
          return {
            ...item,
            ...updatedNode,
            eventData: updatedBody,
          };
        }
        return item;
      });
      const updatedGraphData = {
        ...graphData,
        nodes: updatedNodes,
      };

      form?.setFieldsValue({
        // @ts-ignore
        graphData: updatedGraphData,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ formsubmit ~ error', error);

      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);

      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
        setSubmitting(false);
      } else {
        await onCSVSubmit(formData);
      }
      onCloseDrawer();
    } catch (error) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={CommissionFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        isObserve: true,
        editableTableFormRef,
        // container,
        template,
        productId,
        lotId,
        defaultTimezone,
        isSerial,
        identifier,
        setTemplate,
        onChangeDate,
        onChangeTemplate,
        disabledDate,
        disabledTime,
        t,
      })}
      // @ts-ignore
      title={
        <Header
          isManual={dataEntryMethod === 'manual'}
          title={t('title')}
          form={mainForm}
          locationId={locationId?.trim() ? locationId?.trim() : undefined}
          locationDisabled={locationDisabled}
          onChangeLocation={onChangeLocation}
        />
      }
      grid
      rowProps={{ gutter: 32 }}
      onFinish={onFinish}
      submitter={{
        searchConfig: {
          submitText: t('add_event_title'),
          resetText: t('cancel_btn_title'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
      }}
      initialValues={{
        unitOfMeasure: getUom({ product: dataProduct }),
      }}
      visible={isVisible ? drawer?.visible || false : false}
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        className: styles.eventdrawer,
        ...drawer,
      }}
    />
  );
};

export default Observe;

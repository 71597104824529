import { DataItem } from 'pages/Events/components/Forms/CommissionForm.fields';
import { TemplateAttributeResponse } from 'services/api/client/src';
import { getProductsT } from './language';
import { CSVTemplate } from './typings';

export const checkFieldValues = (
  formData: Array<DataItem>,
  templateFields: Array<TemplateAttributeResponse>,
  csvTemplate: CSVTemplate,
  isSerial = false,
) => {
  const errors: Array<string> = [];
  formData.forEach((item) => {
    /* strict check against csvTemplate */
    Object.keys(csvTemplate).forEach((key) => {
      const field = csvTemplate[key];
      if (field?.required && !item?.[key]) {
        /* quantity is not required for serial item */
        if (field?.dataIndex === 'quantity' && isSerial) {
          return;
        }

        errors.push(`Please input ${field?.title}.`);
      }
      /*  check number type */
      if (field?.valueType === 'number') {
        if (Number.isNaN(Number(item?.[key]))) {
          errors.push(`${field?.title} should be a number`);
        }
      }

      /* check quantity and it should greater than 0 */
      if (field?.dataIndex === 'quantity') {
        if (Number(item[key]) <= 0 && !isSerial) {
          errors.push(`${field?.title} should be greater than 0`);
        }
      }
    });

    /* strict check against templateFields */
    templateFields.forEach((field) => {
      const propertyName = field?.attribute?.fieldProperties?.fields?.propertyName;
      const fieldName = field?.attribute?.name;
      const fieldtype = field?.attribute?.fieldProperties?.fields?.fieldType;
      const required = field?.attribute?.fieldProperties?.fields?.required;
      if (field.attribute && !item?.[propertyName as keyof DataItem] && required) {
        errors.push(`Please input ${fieldName}`);
      }
      /*  check number type */
      if (fieldtype === 'Number') {
        if (Number.isNaN(Number(item[propertyName as keyof DataItem]))) {
          errors.push(`${fieldName} should be a number`);
        }
      }
    });
  });
  if (errors.length > 0) {
    throw new Error(errors?.[0] || '');
  }
};

export const checkFieldNames = (
  formData: Array<DataItem>,
  templateFields: Array<TemplateAttributeResponse>,
) => {
  const errors: Array<string> = [];
  formData.forEach((item) => {
    const templateField = templateFields.find(
      (f) => f?.attribute?.fieldProperties?.fields?.propertyName === item.dataIndex,
    );
    if (!templateField) {
      errors.push(`Field ${item.dataIndex} is not in the template`);
    }
  });
  return errors;
};

export const hasUnsavedChanges = () => {
  const saveElement = document?.getElementById('gtbl-save-btn');
  if (saveElement) {
    throw new Error(getProductsT('unsaved_changes'));
  }
};

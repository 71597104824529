import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Form, Typography, message } from 'antd';
import classNames from 'classnames';
import { ActionButtons, GTable } from 'components';
import { CertDocumentsProps, CertificationInfo } from 'pages/Events';
import React, { FC, useCallback, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import styles from './index.module.less';
import useAttributeStore from './useAttributeStore';

type ColumnsType = {
  onEditRow: (actionPayload?: CertificationInfo) => void;
  onDelRow: (actionPayload?: CertificationInfo) => void;
  hasAdvanceSettings?: boolean;
  isEdit?: boolean;
  t?: TFunction<'pages', 'products'>;
};

const columns = ({
  onEditRow,
  onDelRow,
  t,
  isEdit = true,
}: ColumnsType): Array<ProColumns<CertificationInfo>> => [
  {
    title: t?.('cert_type'),
    dataIndex: 'certificationType',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_type_req'),
        },
        {
          whitespace: true,
          message: t?.('cert_type_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_type'),
      size: 'small',
    },
  },
  {
    title: t?.('cert_standard'),
    dataIndex: 'certificationStandard',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_standard_req'),
        },
        {
          whitespace: true,
          message: t?.('cert_standard_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_standard'),
      size: 'small',
    },
  },
  {
    title: t?.('cert_agency'),
    dataIndex: 'certificationAgency',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_agency_req'),
        },
        {
          whitespace: true,
          message: t?.('cert_agency_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_agency'),
      size: 'small',
    },
  },
  {
    title: t?.('cert_value'),
    dataIndex: 'certificationValue',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_value_req'),
        },
        {
          whitespace: true,
          message: t?.('cert_value_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_value'),
      size: 'small',
    },
  },
  {
    title: t?.('cert_id'),
    dataIndex: 'certificationIdentification',
    formItemProps: {
      rules: [
        {
          required: false,
          message: t?.('cert_id_req'),
        },
        {
          whitespace: true,
          message: t?.('cert_id_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t?.('cert_id'),
      size: 'small',
    },
  },

  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInTable: !isEdit,
    width: '7%',
    render: (text, record) => (
      <ActionButtons record={record} onEdit={onEditRow} onDelete={onDelRow} />
    ),
  },
];

const CertDocuments: FC<CertDocumentsProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  const { templateMode } = useAttributeStore();
  const isEdit = templateMode === 'edit';

  const attributes: Array<CertificationInfo> = Form.useWatch?.('certificationFields', form) || [];
  const { setFieldsValue } = form;
  const setAttributes = useCallback(
    (value: Array<CertificationInfo>) =>
      setFieldsValue({
        certificationFields: value,
      }),
    [setFieldsValue],
  );
  const actionRef = useRef<ActionType>();

  const onEditRow = (actionPayload?: CertificationInfo) => {
    actionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onDelRow = async (actionPayload?: CertificationInfo) => {
    if (actionPayload) setAttributes(attributes.filter((item) => item.id !== actionPayload.id));
  };

  return (
    <>
      <Typography.Paragraph className={classNames('ant-pro-form-group-title', styles.title)}>
        {t('cert_fields_title')}
      </Typography.Paragraph>
      <Typography.Paragraph>{t('cert_fields_description')}</Typography.Paragraph>
      <GTable<CertificationInfo>
        actionRef={actionRef}
        columns={columns({ isEdit, onEditRow, onDelRow, t })}
        editable={{
          onCancel: async (_rowKey, data) => {
            actionRef.current?.cancelEditable(data?.id || 0);
          },
          onSave: async (rowKey, data) => {
            const filterEmptyFields = Object.keys(data).reduce((acc, key) => {
              const typedKey = key as keyof CertificationInfo;
              if (data[typedKey] !== '') {
                acc[typedKey] = data[typedKey];
              }
              return acc;
            }, {} as CertificationInfo);
            // validatation to add atleast one field value
            if (filterEmptyFields && Object.keys(filterEmptyFields)?.length <= 2) {
              message.error(t('validate.atleast_add_one_value'));
              return Promise.reject();
            }
            return Promise.resolve();
          },
        }}
        options={{
          reload: false,
          setting: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        recordCreatorProps={false}
        onAddRecordClick={() => {
          actionRef.current?.addEditRecord?.({
            id: `newField${Date.now().toString()}`,
          });
        }}
        value={attributes}
        onChange={setAttributes}
        enableRecordCreator={isEdit}
        addBtnText={t?.('add_cert')}
      />
    </>
  );
};

export default React.memo(CertDocuments);

import { QueryClient, useInfiniteQuery, useMutation, useQuery } from 'react-query';
import {
  CreateAttributeRequest,
  ListAttributesDefaultValuesOptionalParams,
  ListAttributesDefaultValuesResponse,
  ListAttributesOptionalParams,
  UpdateTemplateAttributeRequest,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

/* get attributes */
export const getAttributes = async (params?: ListAttributesOptionalParams) =>
  HttpClient.listAttributes(params);

/* create attributes */
const createAttributeRequest = async (body: CreateAttributeRequest) =>
  HttpClient.createTemplateAttribute({ body });

/* update attribute by id */
const updateAttributeRequest = async (id: string, body: UpdateTemplateAttributeRequest) =>
  HttpClient.updateTemplateAttribute(id, { body });

/* queries */
/* get attributes */
const useAttributes = (params?: ListAttributesOptionalParams) =>
  useQuery(['attributes', params], () => getAttributes(params));

const getAttributesByPageNumber = async (
  pageNumber: number,
  params?: ListAttributesOptionalParams,
) => {
  const res = await getAttributes({ ...params, pageNumber: pageNumber || 1 });
  return {
    ...res,
  };
};
export const useInfiniteAttributes = (params?: ListAttributesOptionalParams) =>
  useInfiniteQuery({
    queryKey: ['attributesList', params?.fieldsPropertyName],
    queryFn: ({ pageParam = 1 }) => getAttributesByPageNumber(pageParam, params),
    getNextPageParam: (lastPage) => {
      // if there is no next page, return undefined
      if ((lastPage?.pageNumber || 0) < (lastPage?.totalPages || 0)) {
        const nextPage = (lastPage?.pageNumber || 1) + 1;
        return nextPage;
      }
      return undefined;
    },
  });

/* use create attributes */
export const useCreateAttribute = (queryClient: QueryClient) =>
  useMutation((attribute: CreateAttributeRequest) => createAttributeRequest(attribute), {
    onSuccess: () => {
      queryClient.invalidateQueries('attributes');
    },
  });

/* use update attribute */
export const useUpdateAttribute = (queryClient: QueryClient) =>
  useMutation(
    ({ id, attribute }: { id: string; attribute: UpdateTemplateAttributeRequest }) =>
      updateAttributeRequest(id, attribute),
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          ['attributesList', undefined],
          // loop through old. if this item replace, otherwise, don't
          (old: any) => {
            const newData = {
              ...old,
              pages: old?.pages?.map((i: any) => ({
                ...i,
                results: i?.results?.map((d: any) => (d.id === data?.id ? data : d)),
              })),
            };
            return newData || old;
          },
        );
        queryClient.refetchQueries('attributes');
        queryClient.refetchQueries('defaultAttributes');
        queryClient.invalidateQueries(['templateAttributes']);
      },
    },
  );

/* use default attribute */
const getDefaultAttributes = async (
  params?: ListAttributesDefaultValuesOptionalParams,
): Promise<ListAttributesDefaultValuesResponse> => HttpClient.listAttributesDefaultValues(params);

/* use default attribute */
export const useDefaultAttributes = (params?: ListAttributesDefaultValuesOptionalParams) =>
  useQuery(['defaultAttributes', params], () => getDefaultAttributes(params));

export default useAttributes;

// Update Template Attribute
const deleteAttributeDefaultValue = async (id: string, locationId: string): Promise<void> =>
  HttpClient.deleteAttributeDefaultValue(id, locationId);

export const useDeleteAttributeDefaultValue = (queryClient: QueryClient) =>
  useMutation(
    ({ id, locationId }: { id: string; locationId: string }) =>
      deleteAttributeDefaultValue(id, locationId),
    {
      onSuccess: () => {
        queryClient.refetchQueries('attributes');
        queryClient.refetchQueries('defaultAttributes');
        queryClient.invalidateQueries(['templateAttributes']);
      },
    },
  );

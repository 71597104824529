import { ProFormColumnsType } from '@ant-design/pro-form';
import { ViewCustomDataTemplate } from '../../Tables';
import { DataItem } from '../../typings';

const ViewDocumentTemplateFormFields = (): ProFormColumnsType<DataItem>[] => [
  {
    dataIndex: 'customAttributes',
    initialValue: [],
    renderFormItem: (_schema, _config, form) => <ViewCustomDataTemplate form={form} viewTemplate />,
  },
];
export default ViewDocumentTemplateFormFields;

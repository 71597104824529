import { useEffect } from 'react';
import useTabStore from './useTabStore';

const useTab = (tab: string) => {
  const setTab = useTabStore((state) => state.setTab);

  useEffect(() => {
    setTab(tab);
  }, [setTab, tab]);
};

export default useTab;

import { InfoCircleOutlined } from '@ant-design/icons';
import type { FormInstance, ProFormColumnsType } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { Alert, Button, RowProps, Space, Switch, Tooltip, Typography } from 'antd';
import { AdvanceSettingToggle, CopyText } from 'components';
import { PhoneCodeSelect } from 'components/GSelect';
import { useTranslationContext } from 'contexts/TranslationProvider';
import useModalVisibility from 'hooks/useModalVisibility';
import { AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { CertificationInfo } from 'pages/Events';
import { MutableRefObject, useCallback, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Address, LocationResponse, TradePartnerResponse } from 'services/api/client/src';
import { checkIfCodeEnteredInPhoneNumber, PhoneCode } from 'utils';
import { getVariableFromEnv } from 'utils/config';
import AddressSelectInput from '../../components/AddressSelectInput';
import CompanySelectInput from '../../components/CompanySelectInput';
import ConnectionTypeSelect from '../../components/ConnectionTypeSelect';
import styles from '../index.module.less';
import AttributeTable from './AttributeTable';
import CSVUpload from './CSVUpload';
import DataEntryMethod from './DataEntryMethod/DataEntryMethod';
import { DataEntryMethodType } from './DataEntryMethod/typings';
import InboundAPIKey from './GenerateAPIKey/InboundAPIKey';
import OutboundAPIKey from './GenerateAPIKey/OutboundAPIKey';
import LocationTable from './LocationTable';
import { LocationCols } from './LocationTable/typings';
import RestoreConnection from './RestoreConnection';

const colProps = { xs: 24, sm: 12, md: 8, lg: 6 };
const colWidthProps = { xs: 24, sm: 12, md: 8, lg: 7 };
const rowProps: RowProps = { gutter: [32, 24] };
const colPropsFullCsv = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsHalfCsv = { xs: 24, sm: 24, md: 16, lg: 12 };
const hiddenColProp = { xs: 0, sm: 0, md: 0, lg: 0 };
// const col1Props: ColProps = { xs: 24, sm: 24, md: 5, lg: 3 };
// const col2Props: ColProps = { xs: 24, sm: 24, md: 18, lg: 21 };

const staticDigitalLinkUrl = getVariableFromEnv('DIGITAL_URL_LINK');

export interface PhoneNumber {
  code: string;
  number: string;
}

export interface LocTable {
  companyId?: string;
  tradeRelationship?: string;
  locationName?: string;
  address?: Address;
  geoCoordinates?: string;
  email?: string;
  phoneNumber?: string;
  number?: string;
  phonePrefix?: string;
  phoneCode?: string;
  gln?: string;
  vesselName?: string;
  vesselId?: string;
  vesselState?: string;
  imoNumber?: string;
  vesselAuth?: string;
  vesselLink?: string;
  vesselReg?: string;
}

export type DataItem = {
  id?: string;
  companyId?: string;
  companyName?: string;
  connectionType?: string;
  pgln?: string;
  locationName?: string;
  address?: Address;
  geoCoordinates?: string;
  email?: string;
  phoneNumber?: string;
  number?: string;
  phonePrefix?: string;
  phoneCode?: string;
  gln?: string;
  vesselName?: string;
  vesselId?: string;
  vesselState?: string;
  imoNumber?: string;
  vesselAuth?: string;
  vesselLink?: string;
  isMultipleLocations?: string;
  vesselReg?: string;
  digitalLink?: string;
  apiKey?: string;
  multiLoc?: Array<LocTable>;
  csvData?: Array<DataItem>;
  certificationList?: Array<CertificationInfo>;
  urn?: string;

  // vessel attributes
  isMultipleVessels?: string;
  isVessel?: string;
  multiVesselLoc?: Array<LocTable>;
  vLocationName?: string;
  vAddress?: Address;
  vGeoCoordinates?: string;
  vEmail?: string;
  vPhonePrefix?: string;
  vPhoneCode?: string;
  vPhoneNumber?: string;
  vNumber?: string;
  vGln?: string;
  vVesselName?: string;
  vVesselId?: string;
  vVesselState?: string;
  vImoNumber?: string;
  vVesselAuth?: string;
  vVesselLink?: string;
  vVesselReg?: string;
};

export const LocationFormItem = (
  dataEntryMethod: DataEntryMethodType,
  isCompanyEmpty: boolean,
  onNoMultiLoc: (form: FormInstance) => void,
  setLocationFieldValues: (form: FormInstance, selectedCompany?: TradePartnerResponse) => void,
  isDetails: boolean,
  selectedCompany?: TradePartnerResponse,
  userType?: string,
  isVesselTable?: boolean,
  allowAddNewRecord?: boolean,
  onDeleteLocation?: (record?: LocationCols, companyName?: string) => Promise<void>,
  traceSwitch?: boolean,
  hideGoogleMap?: boolean,
): ProFormColumnsType<DataItem>[] => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.form_fields' });
  const isManualEntry = dataEntryMethod === 'manual';

  return [
    // LOCATION DETAILS
    {
      valueType: 'group',
      title: t('location_details'),
      colProps: isDetails ? colPropsFullCsv : hiddenColProp,
      hideInForm: isCompanyEmpty || !isManualEntry,
      columns: [
        {
          title: () => null,
          hideInForm: isCompanyEmpty || userType === 'WC' || !isManualEntry,
          dataIndex: 'isMultipleLocations',
          rowProps,
          fieldProps: (form) => ({
            onChange: () => {
              const locationArray = form.getFieldValue('multiLoc');
              if (locationArray && locationArray.length > 1) {
                onNoMultiLoc(form);
                form.setFieldsValue({
                  isMultipleLocations: 'multi_loc',
                });
              } else {
                setLocationFieldValues(locationArray, selectedCompany);
              }
            },
          }),
          colProps: isDetails ? colProps : hiddenColProp,
          valueType: 'checkbox',
          valueEnum: {
            multi_loc: t('multi_loc'),
          },
        },
      ],
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['isMultipleLocations'],
      },
      columns: ({ isMultipleLocations }) => [
        {
          valueType: 'group',
          hideInForm:
            !isDetails ||
            isCompanyEmpty ||
            String(isMultipleLocations) !== 'multi_loc' ||
            !isManualEntry,
          columns: [
            {
              dataIndex: 'multiLoc',
              initialValue: [],
              renderFormItem: (_schema, _config, form) => (
                <LocationTable
                  form={form}
                  isVessel="No"
                  selectedCompany={selectedCompany}
                  userType={userType}
                  fieldValue="multiLoc"
                  allowAddNewRecord={allowAddNewRecord}
                  onDeleteLocation={onDeleteLocation}
                />
              ),
            },
          ],
        },
        {
          valueType: 'group',
          hideInForm:
            isCompanyEmpty || String(isMultipleLocations) === 'multi_loc' || !isManualEntry,
          rowProps,
          columns: [
            {
              title: t('location_name'),
              dataIndex: 'locationName',
              colProps: isDetails ? colProps : hiddenColProp,
              fieldProps: {
                placeholder: t('location_name'),
                disabled: userType === 'WC',
              },
              formItemProps: (form) => {
                const vLocationName = form?.getFieldValue?.('vLocationName');
                const coordinates = form?.getFieldValue?.('geoCoordinates');
                return {
                  rules: [
                    {
                      required: traceSwitch
                        ? false
                        : !!coordinates || (isVesselTable ? false : !vLocationName),
                      message: t('validation_text', {
                        field: t('location_name').toLocaleLowerCase(),
                      }),
                    },
                    {
                      whitespace: true,
                      message: t('validation_text', {
                        field: t('location_name').toLocaleLowerCase(),
                      }),
                    },
                  ],
                };
              },
            },
            {
              title: t('address'),
              dataIndex: 'address',
              hideInForm: hideGoogleMap,
              renderFormItem: (_, config, form) => (
                <AddressSelectInput form={form} disable={userType === 'WC'} />
              ),
              colProps: isDetails ? colProps : hiddenColProp,
              fieldProps: {
                placeholder: t('address'),
              },
            },
            {
              title: t('geo_coordinates'),
              dataIndex: 'geoCoordinates',
              colProps: isDetails ? colProps : hiddenColProp,
              formItemProps: (form) => {
                const locationName = form?.getFieldValue?.('locationName');

                return {
                  rules: [
                    {
                      required: !!locationName,
                      message: t('validation_text', {
                        field: t('geo_coordinates').toLocaleLowerCase(),
                      }),
                    },
                    {
                      pattern:
                        /^([-+]?(?:[0-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:1[0-7]\d(?:\.\d+)?|180(?:\.0+)?|[0-9]{1,2}(?:\.\d+)?))$/,
                      message: t('validate_coordinate'),
                    },
                  ],
                };
              },
              fieldProps: {
                placeholder: t('geo_coordinates'),
                disabled: userType === 'WC',
              },
            },
            {
              title: t('email'),
              dataIndex: 'email',
              colProps: isDetails ? colProps : hiddenColProp,
              formItemProps: {
                rules: [
                  {
                    type: 'email',
                    message: t('validate_email'),
                  },
                ],
              },
              fieldProps: {
                placeholder: t('email'),
                disabled: userType === 'WC',
              },
            },
            {
              title: t('phone_number'),
              dataIndex: ['phonePrefix', 'phoneCode', 'phoneNumber'],
              key: 'number',
              colProps: isDetails ? colProps : hiddenColProp,
              render: (text, row) => <div>{row.number}</div>,
              fieldProps: (form) => {
                const code = form?.getFieldValue?.('phoneCode') || 'US';
                return {
                  placeholder: t('phone_number'),
                  disabled: userType === 'WC',
                  onChange: (e: any) => {
                    let value = new AsYouType(code).input(e.target.value);
                    value = checkIfCodeEnteredInPhoneNumber(value);
                    form.setFieldsValue({
                      number: value,
                    });
                  },
                  addonBefore: (
                    <PhoneCodeSelect
                      disabled={userType === 'WC'}
                      value={code}
                      defaultValue={code || 'US'}
                      onChange={(val, option) => {
                        // @ts-ignore
                        const item: PhoneCode = option?.itemProps;
                        form.setFieldsValue({
                          phoneCode: val,
                          phonePrefix: String(item?.code || ''),
                        });
                      }}
                    />
                  ),
                };
              },
              formItemProps: (form) => {
                const code = form?.getFieldValue?.('phoneCode') || 'US';
                return {
                  rules: [
                    {
                      message: t?.('validate_phone_number'),
                      validator: (_, value) => {
                        if (!value?.trim?.()) {
                          return Promise.resolve();
                        }
                        if (isValidPhoneNumber(value, code as CountryCode)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t?.('validate_phone_number')));
                      },
                    },
                  ],
                };
              },
            },
            {
              title: t('gln'),
              dataIndex: 'gln',
              colProps: isDetails ? colProps : hiddenColProp,
              fieldProps: {
                placeholder: t('gln'),
                disabled: userType === 'WC',
              },
              tooltip: {
                icon: <InfoCircleOutlined />,
                placement: 'top',
                title: t('gln_tooltip'),
                key: null,
                type: '',
                props: null,
              },
            },
          ],
        },
      ],
    },
  ];
};

export const VesselLocationFormItem = (
  t: TFunction<'pages', 'network.add_network.form_fields'>,
  isCompanyEmpty: boolean,
  onNoMultiLoc: (form: FormInstance) => void,
  setVesselLocationFieldValues: (
    form: FormInstance,
    selectedCompany?: TradePartnerResponse,
  ) => void,
  isDetails: boolean,
  selectedCompany?: TradePartnerResponse,
  userType?: string,
  isVessel?: string,
  isManualEntry?: boolean,
  isLocationTable?: boolean,
  allowAddNewRecord?: boolean,
  onDeleteLocation?: (record?: LocationCols, companyName?: string) => Promise<void>,
  traceSwitch?: boolean,
  hideGoogleMap?: boolean,
): ProFormColumnsType<DataItem>[] => [
  // LOCATION DETAILS
  {
    valueType: 'group',
    title: t('vessel_details'),
    colProps: isDetails ? colPropsFullCsv : hiddenColProp,
    hideInForm: isCompanyEmpty || !isManualEntry || isVessel === 'No',
    columns: [
      {
        title: () => null,
        hideInForm: isCompanyEmpty || userType === 'WC' || !isManualEntry,
        dataIndex: 'isMultipleVessels',
        rowProps,
        fieldProps: (form) => ({
          onChange: () => {
            const locationArray = form.getFieldValue('multiVesselLoc');
            if (locationArray && locationArray.length > 1) {
              onNoMultiLoc(form);
              form.setFieldsValue({
                isMultipleVessels: 'multi_vessel',
              });
            } else {
              setVesselLocationFieldValues(locationArray, selectedCompany);
            }
          },
        }),
        colProps: isDetails ? colProps : hiddenColProp,
        valueType: 'checkbox',
        valueEnum: {
          multi_vessel: t('multi_vessel'),
        },
      },
    ],
  },
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['isMultipleVessels'],
    },
    columns: ({ isMultipleVessels }) => [
      {
        valueType: 'group',
        hideInForm:
          !isDetails ||
          isCompanyEmpty ||
          String(isMultipleVessels) !== 'multi_vessel' ||
          !isManualEntry ||
          isVessel === 'No',
        columns: [
          {
            dataIndex: 'multiVesselLoc',
            initialValue: [],
            renderFormItem: (_schema, _config, form) => (
              <LocationTable
                form={form}
                isVessel="Yes"
                selectedCompany={selectedCompany}
                userType={userType}
                fieldValue="multiVesselLoc"
                allowAddNewRecord={allowAddNewRecord}
                onDeleteLocation={onDeleteLocation}
              />
            ),
          },
        ],
      },
      {
        valueType: 'group',
        hideInForm:
          isCompanyEmpty ||
          String(isMultipleVessels) === 'multi_vessel' ||
          !isManualEntry ||
          isVessel === 'No',
        rowProps,
        columns: [
          {
            title: t('location_name'),
            dataIndex: 'vLocationName',
            colProps: isDetails ? colProps : hiddenColProp,
            fieldProps: {
              placeholder: t('location_name'),
              disabled: userType === 'WC',
            },
            formItemProps: (form) => {
              const locationName = form?.getFieldValue?.('locationName');
              const coordinates = form?.getFieldValue?.('vGeoCoordinates');

              return {
                rules: [
                  {
                    required: traceSwitch
                      ? false
                      : !!coordinates || (isLocationTable ? false : !locationName),
                    message: t('validation_text', {
                      field: t('location_name').toLocaleLowerCase(),
                    }),
                  },
                  {
                    whitespace: true,
                    message: t('validation_text', {
                      field: t('location_name').toLocaleLowerCase(),
                    }),
                  },
                ],
              };
            },
          },
          {
            title: t('address'),
            dataIndex: 'vAddress',
            hideInForm: hideGoogleMap,
            renderFormItem: (_, config, form) => (
              <AddressSelectInput
                form={form}
                disable={userType === 'WC'}
                fieldValue="vAddress"
                geoFieldValue="vGeoCoordinates"
              />
            ),
            colProps: isDetails ? colProps : hiddenColProp,
            fieldProps: {
              placeholder: t('address'),
            },
          },
          {
            title: t('geo_coordinates'),
            dataIndex: 'vGeoCoordinates',
            colProps: isDetails ? colProps : hiddenColProp,
            hideInForm: isVessel === 'No',
            formItemProps: (form) => {
              const vLocationName = form?.getFieldValue?.('vLocationName');
              return {
                rules: [
                  {
                    required: !!vLocationName,
                    message: t('validation_text', {
                      field: t('geo_coordinates').toLocaleLowerCase(),
                    }),
                  },
                  {
                    pattern:
                      /^([-+]?(?:[0-8]?\d(?:\.\d+)?|90(?:\.0+)?)),\s*([-+]?(?:1[0-7]\d(?:\.\d+)?|180(?:\.0+)?|[0-9]{1,2}(?:\.\d+)?))$/,
                    message: t('validate_coordinate'),
                  },
                ],
              };
            },
            fieldProps: {
              placeholder: t('geo_coordinates'),
              disabled: userType === 'WC',
            },
            tooltip: {
              icon: <InfoCircleOutlined />,
              placement: 'top',
              title: t('geo_coordinates_tooltip'),
              key: null,
              type: '',
              props: null,
            },
          },
          {
            title: t('email'),
            dataIndex: 'vEmail',
            colProps: isDetails ? colProps : hiddenColProp,
            formItemProps: {
              rules: [
                {
                  type: 'email',
                  message: t('validate_email'),
                },
              ],
            },
            fieldProps: {
              placeholder: t('email'),
              disabled: userType === 'WC',
            },
          },
          {
            title: t('phone_number'),
            dataIndex: ['vPhonePrefix', 'vPhoneCode', 'vPhoneNumber'],
            key: 'vNumber',
            colProps: isDetails ? colProps : hiddenColProp,
            render: (text, row) => <div>{row.number}</div>,
            fieldProps: (form) => {
              const code = form?.getFieldValue?.('vPhoneCode') || 'US';
              return {
                placeholder: t('phone_number'),
                disabled: userType === 'WC',
                onChange: (e: any) => {
                  let value = new AsYouType(code).input(e.target.value);
                  if (value?.includes('+')) {
                    value = value?.replace(`${value?.split(' ')[0]} `, '');
                  }
                  form.setFieldsValue({
                    vNumber: value,
                  });
                },
                addonBefore: (
                  <PhoneCodeSelect
                    disabled={userType === 'WC'}
                    value={code}
                    defaultValue={code || 'US'}
                    onChange={(val, option) => {
                      // @ts-ignore
                      const item: PhoneCode = option?.itemProps;
                      form.setFieldsValue({
                        vPhoneCode: val,
                        vPhonePrefix: String(item?.code || ''),
                      });
                    }}
                  />
                ),
              };
            },
            formItemProps: (form) => {
              const code = form?.getFieldValue?.('vPhoneCode') || 'US';
              return {
                rules: [
                  {
                    message: t?.('validate_phone_number'),
                    validator: (_, value) => {
                      if (!value?.trim?.()) {
                        return Promise.resolve();
                      }
                      if (isValidPhoneNumber(value, code as CountryCode)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t?.('validate_phone_number')));
                    },
                  },
                ],
              };
            },
          },
          {
            title: t('gln'),
            dataIndex: 'vGln',
            colProps: isDetails ? colProps : hiddenColProp,
            fieldProps: {
              placeholder: t('gln'),
              disabled: userType === 'WC',
            },
            tooltip: {
              icon: <InfoCircleOutlined />,
              placement: 'top',
              title: t('gln_tooltip'),
              key: null,
              type: '',
              props: null,
            },
          },
          {
            title: t('vessel_name'),
            dataIndex: 'vVesselName',
            colProps: isDetails ? colProps : hiddenColProp,
            fieldProps: {
              placeholder: t('vessel_name'),
              disabled: userType === 'WC',
            },
          },
          // {
          //   title: t('vessel_id'),
          //   dataIndex: 'vVesselId',
          //   colProps: isDetails ? colProps : hiddenColProp,
          //   fieldProps: {
          //     placeholder: t('vessel_id'),
          //     disabled: userType === 'WC',
          //   },
          // },
          {
            title: t('vessel_state'),
            dataIndex: 'vVesselState',
            colProps: isDetails ? colProps : hiddenColProp,
            fieldProps: {
              placeholder: t('vessel_state'),
              disabled: userType === 'WC',
            },
          },
          {
            title: t('imo_number'),
            dataIndex: 'vImoNumber',
            colProps: isDetails ? colProps : hiddenColProp,
            fieldProps: {
              placeholder: t('imo_number'),
              disabled: userType === 'WC',
            },
          },
          {
            title: t('vessel_auth'),
            dataIndex: 'vVesselAuth',
            colProps: isDetails ? colProps : hiddenColProp,
            fieldProps: {
              placeholder: t('vessel_auth'),
              disabled: userType === 'WC',
            },
          },
          {
            title: t('vessel_link'),
            dataIndex: 'vVesselLink',
            colProps: isDetails ? colProps : hiddenColProp,
            fieldProps: {
              placeholder: t('vessel_link'),
              disabled: userType === 'WC',
            },
          },
          {
            title: t('vessel_reg'),
            dataIndex: 'vVesselReg',
            colProps: isDetails ? colProps : hiddenColProp,
            fieldProps: {
              placeholder: t('vessel_reg'),
              disabled: userType === 'WC',
            },
          },
        ],
      },
    ],
  },
];

export const AddLocationColumns = (
  dataEntryMethod: DataEntryMethodType,
  isCompanyEmpty: boolean,
  isLocationEdit: boolean,
  locationData: LocationResponse[],
  vesselLocations: LocationResponse[],
  formRef: MutableRefObject<FormInstance | undefined>,
  onNoVessel: (
    vesselLocationList: Array<LocationResponse>,
    selectedCompany?: TradePartnerResponse,
  ) => void,
  onNoMultiLoc: (form: FormInstance) => void,
  setLocationFieldValues: (form: FormInstance, selectedCompany?: TradePartnerResponse) => void,
  setVesselLocationFieldValues: (
    form: FormInstance,
    selectedCompany?: TradePartnerResponse,
  ) => void,
  myCompanyName: string,
  selectedCompany?: TradePartnerResponse,
  onCompanyChange?: (value: any) => void,
  editableTableFormRef?: MutableRefObject<EditableFormInstance<DataItem> | undefined>,
  onContactUs?: () => void,
  archive?: () => void,
  tabType?: string | number,
  userType?: string,
  vesselEnabled?: boolean,
  traceSwitch?: boolean,
  onTraceSwitchOff?: (tradePartnerId: string, flag: boolean, digitalLinkUrl?: string) => void,
  onRemoveConnection?: (company?: TradePartnerResponse) => void,
  allowAddNewRecord?: boolean,
  connectionOptionButton?: string,
  onAcceptCancelInvite?: () => void,
  onDeleteLocation?: (record?: LocationCols, companyName?: string) => Promise<void>,
): ProFormColumnsType<DataItem>[] => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.form_fields' });
  const isManualEntry = dataEntryMethod === 'manual';
  const [isNoteClosed, CloseNote] = useState<boolean>(false);
  const isDetails = tabType === t('details');
  const gdstPending = false;
  const restoreConnectionModal = useModalVisibility(false);
  const { hideGoogleMap } = useTranslationContext();

  const onNoVesselSwitch = useCallback(
    (form: FormInstance<any>) => {
      const vesselVal = form.getFieldValue('isVessel');
      const vesselLocs = form.getFieldValue('multiVesselLoc');
      if (vesselVal === 'No') {
        onNoVessel(vesselLocs, selectedCompany);
      }
    },
    [onNoVessel, selectedCompany],
  );
  return [
    // MASTER DATA
    {
      valueType: 'group',
      title: (
        <Space>
          {t('default_template_attibutes_title', { company: selectedCompany?.name })}
          <AdvanceSettingToggle />
        </Space>
      ),
      hideInForm: isDetails,
      columns: [
        {
          dataIndex: 'attributeTable',
          initialValue: [],
          renderFormItem: (_schema, _config, form) => (
            <AttributeTable form={form} selectedCompany={selectedCompany} />
          ),
        },
      ],
    },
    {
      valueType: 'group',
      hideInForm: !isDetails || isLocationEdit,
      title: (
        <Alert
          message={<div className={styles.notetitle}>{t('info_alert.title')} </div>}
          description={
            <div className={styles.inivitetitle}>
              {t('info_alert.content')}
              <Typography.Text strong>{` ${t('info_alert.bold_content')} `}</Typography.Text>
              {t('info_alert.invite_content')}
            </div>
          }
          type="info"
          onClose={() => CloseNote(!isNoteClosed)}
          closable
          showIcon
          className={styles.alertnote}
        />
      ),
      columns: [],
    },
    {
      valueType: 'group',
      title: isDetails ? t('company_details') : null,
      rowProps,
      columns: [
        {
          title: t('data_entry'),
          hideInForm: isLocationEdit,
          dataIndex: 'dataEntryMethod',
          renderFormItem: () => <DataEntryMethod />,
          colProps: isDetails ? colProps : hiddenColProp,
        },
        {
          title: t('vessel_title'),
          dataIndex: 'isVessel',
          hideInForm: isCompanyEmpty || isManualEntry || userType === 'WC' || !vesselEnabled,
          initialValue: 'No',
          colProps: isDetails ? colProps : hiddenColProp,
          valueType: 'radio',
          valueEnum: {
            Yes: t('yes'),
            No: t('no'),
          },
        },
        {
          title: ' ',
          dataIndex: 'anotherTaceSystem',
          valueType: 'switch',
          colProps: isDetails ? colPropsFullCsv : hiddenColProp,
          hideInForm: gdstPending || userType !== 'NONWC' || !isLocationEdit,
          renderFormItem: () => (
            <Space>
              <span>
                <Typography.Text className={styles.header}>
                  {t('trace_switch_title')}{' '}
                </Typography.Text>
                <Tooltip title={t('another_trace_tooltip')} placement="bottom">
                  <InfoCircleOutlined className={styles.infoicon} />
                </Tooltip>
              </span>

              <Switch
                checked={traceSwitch}
                onChange={(checked: boolean) =>
                  onTraceSwitchOff?.(
                    selectedCompany?.id || '',
                    checked,
                    selectedCompany?.digitalLinkUrl,
                  )
                }
              />
            </Space>
          ),
        },
        {
          title: t('company_name'),
          dataIndex: 'companyName',
          hideInForm: !isLocationEdit || !isManualEntry,
          fieldProps: {
            placeholder: t('company_name'),
            disabled: userType !== 'NONWC',
          },
          tooltip: userType === 'ME' && {
            icon: <InfoCircleOutlined />,
            placement: 'top',
            title: (
              <div>
                {`${t('company_tooltip')} `}
                <a
                  className={styles.whitelink}
                  href="mailto:no-reply@example.com"
                  onClick={(e) => {
                    if (onContactUs) {
                      onContactUs();
                    }
                    e.preventDefault();
                  }}
                >
                  {t('contact_us')}
                </a>
              </div>
            ),
            key: null,
            type: '',
            props: null,
          },
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('validation_text', {
                  field: t('company_name').toLocaleLowerCase(),
                }),
              },
            ],
          },
          colProps: isDetails ? colProps : hiddenColProp,
        },
        {
          valueType: 'group',
          hideInForm: isLocationEdit || !isManualEntry,
          rowProps,
          columns: [
            {
              title: t('company_name'),
              dataIndex: 'companyId',
              fieldProps: {
                placeholder: t('company_name'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('validation_text', {
                      field: t('company_name').toLocaleLowerCase(),
                    }),
                  },
                ],
              },
              colProps: isDetails ? colProps : hiddenColProp,
              renderFormItem: () => <CompanySelectInput onChange={onCompanyChange} />,
            },
            {
              title: ' ',
              dataIndex: 'anotherTaceSystem',
              valueType: 'switch',
              colProps: isDetails ? colProps : hiddenColProp,
              hideInForm: gdstPending || userType !== 'NONWC' || isCompanyEmpty || !isManualEntry,
              renderFormItem: () => (
                <Space>
                  <span>
                    <Typography.Text className={styles.header}>
                      {t('trace_switch_title')}{' '}
                    </Typography.Text>
                    <Tooltip title={t('another_trace_tooltip')} placement="bottom">
                      <InfoCircleOutlined className={styles.infoicon} />
                    </Tooltip>
                  </span>

                  <Switch
                    checked={traceSwitch}
                    onChange={(checked: boolean) =>
                      onTraceSwitchOff?.(
                        selectedCompany?.id || '',
                        checked,
                        selectedCompany?.digitalLinkUrl,
                      )
                    }
                  />
                </Space>
              ),
            },
          ],
        },
        {
          title: t('wc_id'),
          hideInForm: userType === 'NONWC' || isCompanyEmpty || !isManualEntry,
          dataIndex: 'identifier',
          colProps: isDetails ? colProps : hiddenColProp,
          fieldProps: {
            placeholder: t('wc_id'),
            disabled: true,
            value: selectedCompany?.wholechainId,
          },
        },
        {
          title: t('pgln'),
          hideInForm: userType !== 'NONWC' || isCompanyEmpty || !isManualEntry,
          dataIndex: 'pgln',
          colProps: isDetails ? colProps : hiddenColProp,
          fieldProps: {
            placeholder: t('pgln_placeholder'),
          },
          tooltip: {
            icon: <InfoCircleOutlined />,
            placement: isLocationEdit ? 'top' : 'right',
            title: t('wc_note'),
            key: null,
            type: '',
            props: null,
          },
        },

        {
          title: t('connection_type'),
          dataIndex: 'connectionType',
          hideInForm: isCompanyEmpty || selectedCompany?.isOwnerAccount || !isManualEntry,
          valueType: 'select',
          renderFormItem: () => <ConnectionTypeSelect />,
          fieldProps: {
            placeholder: t('connection_type'),
            disabled: userType !== 'NONWC',
          },
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('validation_text', {
                  field: t('connection_type').toLocaleLowerCase(),
                }),
              },
            ],
          },
          colProps: isDetails ? colProps : hiddenColProp,
        },
        {
          title: t('connection'),
          hideInForm:
            isCompanyEmpty ||
            !isLocationEdit ||
            !isManualEntry ||
            selectedCompany?.wholechainId === null ||
            selectedCompany?.isOwnerAccount,
          dataIndex: 'restoreConnection',
          colProps: isDetails ? colProps : hiddenColProp,
          renderFormItem: () => (
            <>
              {restoreConnectionModal.visible && (
                <RestoreConnection modal={restoreConnectionModal} />
              )}
              <Button
                shape="round"
                className={styles.deletebuttontext}
                onClick={() => {
                  switch (connectionOptionButton) {
                    case 'restore_connection':
                      restoreConnectionModal.show();
                      break;
                    case 'remove_connection':
                      onRemoveConnection?.(selectedCompany);
                      break;
                    default:
                      onAcceptCancelInvite?.();
                  }
                }}
              >
                {t(`${connectionOptionButton}`)}
              </Button>
            </>
          ),
        },
        {
          title: t('vessel_title'),
          dataIndex: 'isVessel',
          initialValue: 'No',
          colProps: isDetails ? colProps : hiddenColProp,
          valueType: 'radio',
          hideInForm: isCompanyEmpty || !isManualEntry || userType === 'WC' || !vesselEnabled,
          fieldProps: (form) => ({
            disabled: userType === 'WC',
            onChange: () => onNoVesselSwitch(form),
          }),
          valueEnum: {
            Yes: t('yes'),
            No: t('no'),
          },
        },
        {
          title: t('company_urn'),
          dataIndex: 'urn',
          colProps: isDetails ? colWidthProps : hiddenColProp,
          hideInForm: isCompanyEmpty || !isManualEntry || !isLocationEdit,
          fieldProps: {
            disabled: true,
          },
        },
      ],
    },
    // CSV UPLOAD
    {
      valueType: 'divider',
      hideInForm: !isDetails || isCompanyEmpty || !isManualEntry,
    },
    {
      dataIndex: 'csvData',
      initialValue: [],
      hideInForm: !isDetails || isManualEntry,
      renderFormItem: (row, record, form) => (
        <CSVUpload form={form} editableTableFormRef={editableTableFormRef} />
      ),
      colProps: colPropsFullCsv,
    },
    ...LocationFormItem(
      dataEntryMethod,
      isCompanyEmpty,
      onNoMultiLoc,
      setLocationFieldValues,
      isDetails,
      selectedCompany,
      userType,
      // formRef?.current?.getFieldValue('isMultipleVessels') === 'multi_vessel',
      vesselLocations?.length !== 0,
      allowAddNewRecord,
      onDeleteLocation,
      traceSwitch,
      hideGoogleMap,
    ),
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['isVessel'],
      },
      columns: ({ isVessel }) => [
        {
          valueType: 'divider',
          hideInForm: !isDetails || isCompanyEmpty || !isManualEntry || isVessel === 'No',
        },
        ...VesselLocationFormItem(
          t,
          isCompanyEmpty,
          onNoMultiLoc,
          setVesselLocationFieldValues,
          isDetails,
          selectedCompany,
          userType,
          isVessel,
          isManualEntry,
          // formRef?.current?.getFieldValue('isMultipleLocations') === 'multi_loc',
          locationData?.length !== 0,
          allowAddNewRecord,
          onDeleteLocation,
          traceSwitch,
          hideGoogleMap,
        ),
      ],
    },
    {
      valueType: 'divider',
      hideInForm:
        gdstPending || !isDetails || !isManualEntry || userType !== 'NONWC' || !traceSwitch,
    },
    {
      valueType: 'group',
      title: null,
      hideInForm:
        gdstPending || isCompanyEmpty || !isManualEntry || userType !== 'NONWC' || !traceSwitch,
      rowProps,
      columns: [
        {
          valueType: 'group',
          title: isDetails ? (
            <div>{`${selectedCompany?.name} ${t('traceability_system')}`}</div>
          ) : null,
          colProps: colPropsHalfCsv,
          rowProps,
          columns: [
            {
              title: t('digital_link'),
              hideInForm: isCompanyEmpty || !isManualEntry,
              dataIndex: 'digitalLink',
              colProps: isDetails ? colPropsHalfCsv : hiddenColProp,
              fieldProps: {
                placeholder: t('digital_link'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('traceability_validation_digital_link'),
                  },
                ],
              },
              tooltip: {
                icon: <InfoCircleOutlined />,
                placement: isLocationEdit ? 'top' : 'right',
                title: t('digital_url_tooltip'),
                key: null,
                type: '',
                props: null,
              },
            },
            {
              title: t('api_key'),
              hideInForm: isCompanyEmpty || !isManualEntry,
              dataIndex: 'apiKey',
              colProps: isDetails ? colPropsHalfCsv : hiddenColProp,
              fieldProps: {
                placeholder: t('api_key'),
              },
              tooltip: {
                icon: <InfoCircleOutlined />,
                placement: 'top',
                title: t('api_key_tooltip'),
                key: null,
                type: '',
                props: null,
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('traceability_validation', {
                      field: t('api_key'),
                    }),
                  },
                ],
              },
              renderFormItem: (_, __, form) => (
                <OutboundAPIKey
                  form={form}
                  tradePartnerId={selectedCompany?.id || ''}
                  digitalLink={selectedCompany?.digitalLinkUrl}
                />
              ),
            },
          ],
        },
        {
          valueType: 'group',
          title: isDetails ? (
            <div>
              {`${myCompanyName} ${t('traceability_system')}`}
              <span>
                <Typography.Text
                  strong={false}
                  className={styles.secondarytext}
                  type="secondary"
                >{` (${t('me')})`}</Typography.Text>
              </span>
            </div>
          ) : null,
          colProps: colPropsHalfCsv,
          rowProps,
          columns: [
            {
              title: t('digital_link'),
              hideInForm: isCompanyEmpty || !isManualEntry,
              dataIndex: 'myDigitalLink',
              colProps: isDetails ? colPropsHalfCsv : hiddenColProp,
              fieldProps: {
                placeholder: t('digital_link'),
                copyable: true,
                disabled: true,
                addonAfter: <CopyText text={staticDigitalLinkUrl} />,
                value: staticDigitalLinkUrl,
              },
              tooltip: {
                icon: <InfoCircleOutlined />,
                placement: isLocationEdit ? 'top' : 'right',
                title: t('my_digital_url_tooltip'),
                key: null,
                type: '',
                props: null,
              },
            },
            {
              title: t('api_key'),
              hideInForm: isCompanyEmpty || !isManualEntry,
              dataIndex: 'myApiKey',
              colProps: isDetails ? colPropsHalfCsv : hiddenColProp,
              tooltip: {
                icon: <InfoCircleOutlined />,
                placement: 'top',
                title: t('my_disabled_api_key_tooltip'),
                key: null,
                type: '',
                props: null,
              },
              renderFormItem: () => (
                <InboundAPIKey
                  tradePartnerId={selectedCompany?.id || ''}
                  disabled={!selectedCompany?.digitalLinkUrl}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      valueType: 'divider',
      hideInForm: !isDetails || !isLocationEdit,
    },
    {
      valueType: 'group',
      hideInForm: !isDetails || !isLocationEdit,
      title: () => {
        const isMulti = (locationData?.length || 0) + (vesselLocations?.length || 0);
        const title = isMulti === 1 ? t('archive_location') : t('archive_locations');
        return (
          <Space>
            <Button shape="round" className={styles.deletebuttontext} onClick={() => archive?.()}>
              {title}
            </Button>
          </Space>
        );
      },
      columns: [],
    },
  ];
};

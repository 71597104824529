import { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps } from 'antd';
import TradePartnerSelect from 'components/GSelect/TradePartnerSelect';
import AccessList from './AccessList';
import { DataItem, ShareProductFormProps } from './typings';
import useShareProductStore from './useShareProductStore';

const colProps18: ColProps = { xs: 24, sm: 14, md: 14, lg: 18 };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps6: ColProps = { xs: 24, sm: 6, md: 6, lg: 6 };
const ShareProductFormFields = ({
  t,
  product,
}: ShareProductFormProps): ProFormColumnsType<DataItem>[] => {
  const partners = useShareProductStore((state) => state.tradePartners);
  const setPartners = useShareProductStore((state) => state.setTradePartners);
  return [
    {
      dataIndex: 'tradePartners',
      initialValue: [],
      renderFormItem: (row, config, form) => (
        <TradePartnerSelect
          onChange={(value: DataItem['tradePartners']) => {
            setPartners(value);
            form?.setFieldsValue({ tradePartners: value });
          }}
        />
      ),
      fieldProps: {
        mode: 'multiple',
        placeholder: t('form_fields.company_placeholder'),
      },
      colProps: (partners?.length || 0) > 0 ? colProps18 : colProps24,
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['tradePartners'],
      },
      columns: ({ tradePartners }: DataItem) => [
        {
          dataIndex: 'permission',
          valueType: 'select',
          initialValue: 'Viewer',
          fieldProps: {
            allowClear: false,
          },
          valueEnum: {
            Viewer: {
              text: 'Viewer',
              status: 'Default',
            },
            Editor: {
              text: 'Editor',
              status: 'Default',
            },
          },
          hideInForm: tradePartners?.length === 0,
          colProps: colProps6,
        },
        {
          dataIndex: 'message',
          valueType: 'textarea',
          hideInForm: tradePartners?.length === 0,
          fieldProps: {
            placeholder: t('form_fields.message_placeholder'),
            rows: 6,
          },
        },
        {
          dataIndex: 'access',
          hideInForm: tradePartners?.length !== 0,
          renderFormItem: () => <AccessList product={product} />,
        },
      ],
    },
  ];
};
export default ShareProductFormFields;

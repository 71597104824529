import { InfoCircleOutlined } from '@ant-design/icons';
import type { ProFormColumnsType } from '@ant-design/pro-form';
import { Select, Space, Tooltip, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import {
  AdvanceSettingsFormItem,
  CopyText,
  MultipleDatesFormItem,
  SwitchFormItem,
} from 'components';
import { SubProducts } from 'pages/Products/Inventory';
import pluralize from 'pluralize';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SubProductResponse } from 'services/api/client/src';
import {
  coreUnitofMeasureEnum,
  getCoreUOMEnum,
  getCoreUOMOptions,
  getProductsT,
  onchangeReceivedProduct,
  resetReceivedProduct,
  setReceivedProduct,
} from 'utils';
import styles from './Columns.module.less';
import CustomAttributeTable from './CustomAttributeTable';
import { AddProductColumnProps, CustomAttribute } from './typings';

const { Text } = Typography;

const colProps = { xs: 24, sm: 12, md: 8, lg: 6 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0 };
const colProps2Cols = { xs: 24, sm: 24, md: 16, lg: 12 };
const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };

const rowProps = { gutter: 32 };

export type DataItem = {
  wholechainProductId?: string;
  epcUrn?: string;
  gtin?: string;
  name?: string;
  blockchain?: string;
  customAttributes?: Array<CustomAttribute>;
  receivedProduct?: boolean;
  customDataAttributes?: boolean;
  dataSharingPolicy?: string;
  definedUnits?: boolean;
  identifier?: string;
  identifierType?: string;
  traceabilityType?: string;
  unitDescriptor?: string;
  unitOfMeasure?: string;
  uomQuantity?: string;
  currentInventoryTotal?: number;
  currentInventory?: number;
  unitQuantity?: number;
  hasAdvanceSettings?: boolean;
  associatedProducts?: SubProductResponse[];
  subProducts?: SubProductResponse[];
};
/**
 * Generates columns for the AddProductForm component.
 * @param isProductSettingsDrawer Whether the drawer is for product settings.
 * @param allowChangeDetails Whether to allow changing product details.
 * @param isReceive Whether the product is received.
 * @param inboundProduct The inbound product data.
 * @param productName The name of the product.
 * @param senderName The name of the sender.
 * @returns An array of ProFormColumnsType<DataItem>.
 */
export const AddProductColumns = ({
  isProductSettingsDrawer = false,
  allowChangeDetails = true,
  isReceive,
  inboundProduct,
  productName,
  senderName,
}: AddProductColumnProps): ProFormColumnsType<DataItem>[] => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.settings.form_fields' });

  const disableClick = useCallback((e: any) => e?.preventDefault(), []);

  return [
    // MASTER DATA

    {
      valueType: 'group',
      title: t('master_data'),
      rowProps,
      columns: [
        {
          dataIndex: 'receivedProduct',
          valueType: 'switch',
          hideInForm: !isReceive || !inboundProduct,
          renderFormItem: (_schema, _config, form) => (
            <SwitchFormItem
              title={t('received_product_title', {
                product: productName,
                senderName,
              })}
              tooltip={t('received_product_tooltip')}
              form={form}
              dataIndex="receivedProduct"
              onChange={(e: CheckboxChangeEvent) => {
                if (e.target.checked) {
                  setReceivedProduct(form, inboundProduct, e.target.checked);
                } else {
                  resetReceivedProduct(form, {
                    receivedProduct: e.target.checked,
                  });
                }
              }}
            />
          ),
          colProps: colPropsFull,
        },

        {
          valueType: 'dependency',
          fieldProps: {
            name: [
              'customDataAttributes',
              'gtin',
              'epcUrn',
              'receivedProduct',
              'hasAdvanceSettings',
            ],
          },
          columns: ({
            customDataAttributes,
            gtin,
            epcUrn,
            receivedProduct,
            hasAdvanceSettings,
          }) => [
            {
              dataIndex: 'customDataAttributes',
              colProps: colPropsFull,
              valueType: 'switch',
              fieldProps: {
                size: 'small',
              },
              renderFormItem: (_schema, _config, form) => (
                <Space>
                  <MultipleDatesFormItem
                    title={t('custom_data_attribute_title')}
                    tooltip={t('custom_data_attribute_tooltip')}
                    form={form}
                    dataIndex="customDataAttributes"
                  />
                  {customDataAttributes && (
                    <AdvanceSettingsFormItem form={form} dataIndex="hasAdvanceSettings" />
                  )}
                </Space>
              ),
            },
            {
              valueType: 'group',
              rowProps,
              columns: [
                {
                  title: t('name_title'),
                  dataIndex: 'name',
                  colProps,
                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        message: t('name_req'),
                      },
                      {
                        whitespace: true,
                        message: t?.('name_req'),
                      },
                    ],
                  },
                  fieldProps: (form) => ({
                    placeholder: t('name_placeholder'),
                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                      onchangeReceivedProduct(
                        'name',
                        form,
                        inboundProduct?.sharingPolicy,
                        e.target.value,
                        isReceive,
                      );
                    },
                  }),
                },
                {
                  title: t('product_id_title'),
                  dataIndex: 'wholechainProductId',
                  colProps,
                  hideInForm: !isProductSettingsDrawer,
                  fieldProps: (form) => ({
                    placeholder: t('product_id_placeholder'),
                    disabled: true,
                    addonAfter: <CopyText text={form.getFieldValue('wholechainProductId')} />,
                  }),
                },
                {
                  title: t('epc_urn_title'),
                  dataIndex: 'epcUrn',
                  colProps,
                  fieldProps: {
                    placeholder: t('epc_urn_placeholder'),
                    copyable: true,
                    disabled: true,
                    addonAfter: <CopyText text={epcUrn} />,
                  },
                  // show for isProductSettingsDrawer , isReceive and epcUrn is not empty
                  hideInForm: !isProductSettingsDrawer ? !(isReceive && epcUrn) : false,
                },
                {
                  title: t('gtin_title'),
                  dataIndex: 'gtin',
                  // eslint-disable-next-line no-nested-ternary
                  hideInForm: isProductSettingsDrawer ? !gtin : receivedProduct ? !gtin : false,
                  colProps,
                  valueType: 'text',
                  fieldProps: {
                    className: 'full-width',
                    placeholder: t('gtin_placeholder'),
                    disabled: !isProductSettingsDrawer ? receivedProduct : true,
                    // eslint-disable-next-line no-nested-ternary
                    addonAfter: isProductSettingsDrawer ? (
                      <CopyText text={gtin} />
                    ) : receivedProduct ? (
                      <CopyText text={gtin} />
                    ) : undefined,
                    maxLength: 14,
                    pattern: /^[0-9]*$/,
                  },
                  formItemProps: {
                    rules: [
                      {
                        /*  prevent max character length of 14 */
                        validator: (rule, value) => {
                          if (value && value.length > 14) {
                            return Promise.reject(t('gtin_max'));
                          }
                          if (value && !/^[0-9]*$/?.test(value)) {
                            return Promise.reject(t('invalid_gtin'));
                          }
                          return Promise.resolve();
                        },

                        message: t('gtin_max'),
                      },
                    ],
                  },
                },
              ],
            },
            {
              dataIndex: 'customAttributes',
              initialValue: [],
              hideInForm: !customDataAttributes,
              renderFormItem: (_schema, _config, form) => (
                <CustomAttributeTable form={form} hasAdvanceSettings={hasAdvanceSettings} />
              ),
            },
          ],
        },
      ],
    },
    {
      valueType: 'divider',
    },

    // PRODUCT DETAILS

    {
      valueType: 'group',
      rowProps,
      title: t('details_grp_title'),
      columns: [
        {
          valueType: 'group',
          rowProps,
          columns: [
            {
              title: t('tracebility_type_title'),
              dataIndex: 'traceabilityType',
              colProps,
              valueType: 'radio',
              valueEnum: {
                Lot: {
                  text: getProductsT('lot'),
                },
                Serial: {
                  text: getProductsT('serial'),
                },
              },
              /* tooltip: {
                icon: <InfoCircleOutlined />,
                title: (
                  <div className={styles.tooltiplink}>
                    {`${t('tracebility_type_tooltip')} `}
                    <Link target="_blank" href={serLotArticleURL}>
                      {t('tracebility_type_link')}
                    </Link>
                    .
                  </div>
                ),
                key: null,
                type: '',
                props: null,
              }, */
              fieldProps: (form) => ({
                disabled: !allowChangeDetails,
                onChange: (e: any) =>
                  onchangeReceivedProduct(
                    'traceabilityType',
                    form,
                    inboundProduct?.productIdentifierType,
                    e.target.value,
                    isReceive,
                  ),
              }),
            },
            {
              valueType: 'dependency',
              fieldProps: {
                name: ['traceabilityType'],
              },
              columns: ({ traceabilityType }) => [
                {
                  title: (
                    <Typography.Text onClick={disableClick}>
                      {t('defined_units_title')}
                    </Typography.Text>
                  ),
                  dataIndex: 'definedUnits',
                  hideInForm: traceabilityType !== 'Lot',
                  colProps,
                  valueType: 'switch',
                  fieldProps: {
                    disabled: !allowChangeDetails,
                    size: 'small',
                  },
                  tooltip: {
                    icon: <InfoCircleOutlined onClick={disableClick} />,
                    title: t('defined_units_tooltip'),
                    key: null,
                    type: '',
                    props: null,
                  },
                },
              ],
            },
          ],
        },
        {
          valueType: 'group',
          rowProps,
          columns: [
            {
              title: t('blochain_title'),
              dataIndex: 'blockchain',
              initialValue: 'Algorand',
              valueType: 'select',
              colProps,
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('blochain_req'),
                  },
                ],
              },
              valueEnum: {
                Algorand: {
                  text: t('algorand'),
                },
                None: {
                  text: t('none'),
                },
              },
              fieldProps: {
                placeholder: t('blochain_placeholder'),
                disabled: true,
              },
            },
            {
              valueType: 'dependency',
              fieldProps: {
                name: [
                  'definedUnits',
                  'unitDescriptor',
                  'unitOfMeasure',
                  'traceabilityType',
                  'uomQuantity',
                ],
              },
              columns: ({
                definedUnits,
                unitDescriptor,
                uomQuantity,
                traceabilityType,
              }: DataItem) => [
                {
                  title: t('unitofmeasure_title'),
                  dataIndex: 'unitOfMeasure',
                  valueType: 'select',
                  colProps: definedUnits ? colPropsNull : colProps,
                  formItemProps: {
                    rules: [
                      {
                        required: !definedUnits,
                        message: t('unitofmeasure_req'),
                      },
                    ],
                    className: definedUnits ? 'no-display' : undefined,
                  },
                  hideInForm: traceabilityType === 'Serial',
                  valueEnum: getCoreUOMEnum(),
                  fieldProps: (form) => ({
                    placeholder: t('unitofmeasure_placeholder'),
                    disabled: !allowChangeDetails,
                    className: definedUnits ? 'no-display' : undefined,
                    onChange: (value?: string) =>
                      onchangeReceivedProduct(
                        'unitOfMeasure',
                        form,
                        inboundProduct?.simpleUnitOfMeasurement,
                        value,
                        isReceive,
                      ),
                  }),
                },

                // Visible if definedUnits = true

                {
                  title: t('unitdescriptor_title'),
                  dataIndex: 'unitDescriptor',
                  valueType: 'select',
                  hideInForm: !definedUnits || traceabilityType === 'Serial',
                  colProps,
                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        message: t('unitdescriptor_req'),
                      },
                    ],
                  },
                  valueEnum: {
                    Cases: {
                      text: getProductsT('descriptor_cases'),
                    },
                    Units: {
                      text: getProductsT('descriptor_units'),
                    },
                    Totes: {
                      text: getProductsT('descriptor_tones'),
                    },
                    Pallets: {
                      text: getProductsT('descriptor_pallets'),
                    },
                  },
                  fieldProps: (form) => ({
                    placeholder: t('unitdescriptor_placeholder'),
                    disabled: !allowChangeDetails,
                    onChange: (value?: string) =>
                      onchangeReceivedProduct(
                        'unitDescriptor',
                        form,
                        inboundProduct?.unitDescriptor,
                        value,
                        isReceive,
                      ),
                  }),
                },
                {
                  title: t('unit_quantity_title'),
                  dataIndex: 'unitQuantity',
                  hideInForm: !definedUnits || traceabilityType === 'Serial',
                  valueType: 'digit',
                  colProps,
                  formItemProps: {
                    rules: [
                      {
                        required: true,
                        message: t('unit_quantity_req'),
                      },
                    ],
                  },
                  fieldProps: (form) => ({
                    placeholder: t('unit_quantity_placeholder'),
                    disabled: !allowChangeDetails,
                    stringMode: true,
                    addonAfter: (
                      <Select
                        defaultValue={coreUnitofMeasureEnum.LBS}
                        value={`${uomQuantity || ''} ${uomQuantity ? t('per') : t('select')} ${
                          unitDescriptor && uomQuantity ? pluralize.singular(unitDescriptor) : ''
                        }`}
                        options={getCoreUOMOptions()}
                        onChange={(value) =>
                          form?.setFieldsValue({
                            uomQuantity: value,
                          })
                        }
                        disabled={!allowChangeDetails}
                      />
                    ),
                    onChange: (value?: string) =>
                      onchangeReceivedProduct(
                        'unitQuantity',
                        form,
                        inboundProduct?.unitQuantity,
                        value,
                        isReceive,
                      ),
                  }),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      valueType: 'divider',
    },

    // DATA SHARING POLICY
    {
      valueType: 'group',
      title: t('data_sharing_policy_title'),
      columns: [
        {
          dataIndex: 'dataSharingPolicy',
          colProps: colProps2Cols,
          valueType: 'radio',
          fieldProps: (form) => ({
            options: [
              {
                label: (
                  <Space direction="vertical" size={0}>
                    <Text strong>{t('open_sharing_title')}</Text>
                    <Text>{t('open_sharing_tooltip')}</Text>
                    <br />
                  </Space>
                ),
                value: 'Open',
              },
              {
                label: (
                  <Space direction="vertical" size={0}>
                    <Text strong>{t('restricted_data_sharing_title')}</Text>
                    <Text>{t('restricted_data_sharing_tooltip')}</Text>
                  </Space>
                ),
                value: 'Restricted',
                disabled: true,
              },
            ],
            onChange: (e: any) =>
              onchangeReceivedProduct(
                'dataSharingPolicy',
                form,
                inboundProduct?.sharingPolicy,
                e.target.value,
                isReceive,
              ),
          }),
        },
      ],
    },
    /* associated products */
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['subProducts'],
      },
      columns: ({ subProducts }: DataItem) => [
        {
          valueType: 'divider',
          hideInForm: !isProductSettingsDrawer,
        },
        {
          dataIndex: 'subProducts',
          hideInForm: !isProductSettingsDrawer,
          title: (
            <Space>
              <Typography.Paragraph className={classNames('ant-pro-form-group-title', styles.mrb5)}>
                {t('sub_products_title')}
              </Typography.Paragraph>
              <Tooltip title={t('sub_products_tooltip')}>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          ),
          renderFormItem: () => (
            <SubProducts t={t} inboundProduct={inboundProduct} products={subProducts} />
          ),
          initialValue: [],
        },
      ],
    },
    {
      dataIndex: 'uomQuantity',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
  ];
};

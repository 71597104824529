import { Segmented } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataEntryMethodType } from './typings';
import useDataEntryMethodStore from './useDataEntryStore';

const DataEntryMethod: FC = () => {
  const { dataEntryMethod, setDataEntryMethod } = useDataEntryMethodStore();
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.data_entry' });
  return (
    <Segmented
      value={dataEntryMethod}
      options={[
        { label: t('manual'), value: 'manual' },
        { label: t('csv_upload'), value: 'csvUpload' },
      ]}
      onChange={(e) => setDataEntryMethod(e as DataEntryMethodType)}
    />
  );
};

export default React.memo(DataEntryMethod);

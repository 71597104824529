import { Form, Input, Space, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DocumentTypeSelect from 'components/GSelect/DocumentTypeSelect';
import { DrawerFormHeaderProps } from '../../typings';
import styles from './index.module.less';

const DrawerFormHeader: FC<DrawerFormHeaderProps> = ({ title, form, readonly = false }) => {
  const { templateId } = useParams();
  const { t } = useTranslation('pages', { keyPrefix: 'templates_document.add_template' });
  return (
    <Space size={20}>
      <Typography.Title level={4}>{title}</Typography.Title>
      {!readonly ? (
        <Form form={form} layout="inline">
          <Form.Item name="name">
            <Input
              placeholder={t('form_fields.name_placeholder')}
              className={styles.templatename}
            />
          </Form.Item>
          <Form.Item name="type">
            <DocumentTypeSelect
              placeholder={t('form_fields.type_placeholder')}
              className={styles.documenttype}
            />
          </Form.Item>
        </Form>
      ) : (
        `: ${templateId}`
      )}
    </Space>
  );
};
export default DrawerFormHeader;

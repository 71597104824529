/* eslint-disable no-useless-catch */

import { useMemo } from 'react';
import { useProducts } from 'services/api';

const useActions = () => {
  // const queryClient = useQueryClient();
  // const [percent, setPercent] = useState<number>(0);

  // const productId = props?.productId || '';
  // const templateId = props?.templateId || '';
  // const { workflowId: defaultId } = useParams();
  // const { data: dataworkflow } = useWorkflowById(defaultId || '');
  // const { data: workflowTemplate } = useWorkflowTemplateById(
  //   dataworkflow?.workflowTemplateId || '',
  // );

  // const { data: dataProductInstances } = useProductInstances(productId, {
  //   isShipped: false,
  //   isAggregated: false,
  //   isConsumed: false,
  // });
  // const { data: product } = useProductById(productId);
  // const { data: template } = useTemplateById(templateId);
  // const createCommission = useCreateCommissionEvent(queryClient);
  // const createObserce = useCreateObserveEvent(queryClient);
  // const createTransform = useCreateTransformEvent(queryClient);
  // const createShip = useCreateShipEvent(queryClient);
  // const createReceive = useCreateReceiveEvent(queryClient);
  // const createDecommission = useCreateDecommissionEvent(queryClient);
  // const createAggregate = useCreateAggregateEvent(queryClient);

  const { data: dataProducts } = useProducts();
  // const { data: dataLocations } = useLocations();
  // const { data: attributes } = useAttributes();
  // const { data: tradePartners } = useTradePartners();

  // const productInstances = useMemo(() => dataProductInstances?.results, [dataProductInstances]);
  const products = useMemo(() => dataProducts?.results, [dataProducts]);
  // const locations = useMemo(() => dataLocations?.results, [dataLocations]);

  // const getNode = useCallback(
  //   (item: WorkflowEventType): NodeConfig => {
  //     const nodeTemplate = workflowTemplate?.workflowEvents?.find((el) => el.id === item?.id);
  //     const defaultProductId = nodeTemplate?.productId || nodeTemplate?.outputProducts?.[0]?.id;
  //     const defaultLocationId = nodeTemplate?.locationId;
  //     // eslint-disable-next-line no-shadow
  //     const productId =
  //       item?.products?.[0]?.productId ||
  //       item?.outputProducts?.[0]?.productId ||
  //       item?.newInputProducts?.[0]?.productId ||
  //       item?.newProducts?.[0]?.productId ||
  //       defaultProductId;
  //     // eslint-disable-next-line no-shadow
  //     const product = getProductById(productId, products);
  //     const productName = product?.name || item?.newProducts?.[0]?.productName || '';
  //     const unitOfMeasure =
  //       product?.unitOfMeasure ||
  //       item?.newProducts?.[0]?.unitOfMeasure ||
  //       item?.newOutputProducts?.[0]?.unitOfMeasure ||
  //       'LBS';
  //     const primaryId =
  //       item.products?.[0]?.lotSerial ||
  //       item?.outputProducts?.[0]?.lotSerial ||
  //       item?.newInputProducts?.[0]?.lotSerial ||
  //       item?.newProducts?.[0]?.lotSerial ||
  //       '';
  //     const quantity =
  //       item?.products?.[0]?.quantity ||
  //       item?.outputProducts?.[0]?.quantity ||
  //       item?.newOutputProducts?.[0]?.quantity ||
  //       0;
  //     const locationId = item?.destinationLocationId || item?.locationId || defaultLocationId || '';
  //     const location = getLocationById(locationId, locations);
  //     const locationName = location?.address?.line2 || item.locationName || '';
  //     const isSuccess = item?.workflowEventStatus === 'Succeeded' || false;
  //     const isError = item?.workflowEventStatus === 'Failed' || false;
  //     const isFilled = !!item?.eventId;
  //     const node: NodeConfig = {
  //       id: item?.id || '',
  //       eventType: item.eventType,
  //       eventTime: !isNullString(item?.eventTime)
  //         ? dateFormat(item?.eventTime || new Date())
  //         : undefined,
  //       name: productName || item?.eventType,
  //       primaryId,
  //       quantity,
  //       unitOfMeasure,
  //       eventTemplateId: item?.templateId || '',
  //       eventTemplateName: locationName || item?.templateName || '',
  //       locationId: !isNullString(item?.locationId) ? item?.locationId : undefined,
  //       locationName,
  //       productId,
  //       productName: product?.name || '',
  //       ...(isFilled && { colourIndex: 'colour_4' }),
  //       children: [],
  //       eventData: {
  //         ...item,
  //         eventTime: !isNullString(item?.eventTime) ? item?.eventTime : undefined,
  //         locationId: !isNullString(item?.locationId) ? item?.locationId : undefined,
  //         destinationLocationId: !isNullString(item?.destinationLocationId)
  //           ? item?.destinationLocationId
  //           : undefined,
  //       },
  //       isSuccess,
  //       isError,
  //     };
  //     return node;
  //   },
  //   [products, locations, workflowTemplate],
  // );
  // const generateWorkflowGraph = useCallback(
  //   (workflow: GetWorkflowByIdResponse, edges: Array<EdgeConfig>): GraphData => {
  //     const events = [
  //       ...(workflow?.commissionEvents?.map((el) => ({ ...el, eventType: 'Commission' })) || []),
  //       ...(workflow?.decommissionEvents?.map((el) => ({ ...el, eventType: 'Decommission' })) ||
  //         []),
  //       ...(workflow?.aggregateEvents?.map((el) => ({ ...el, eventType: 'Aggregate' })) || []),
  //       ...(workflow?.disaggregateEvents?.map((el) => ({ ...el, eventType: 'Disagregate' })) || []),
  //       ...(workflow?.observeEvents?.map((el) => ({ ...el, eventType: 'Observe' })) || []),
  //       ...(workflow?.receiveEvents?.map((el) => ({ ...el, eventType: 'Receive' })) || []),
  //       ...(workflow?.shipEvents?.map((el) => ({ ...el, eventType: 'Ship' })) || []),
  //       ...(workflow?.transformEvents?.map((el) => ({ ...el, eventType: 'Transform' })) || []),
  //     ];
  //     /* sort by order key asc */
  //     const orderedEvents = events.sort((a, b) => {
  //       if ((a?.order || 0) < (b?.order || 0)) {
  //         return -1;
  //       }
  //       if ((a?.order || 0) > (b?.order || 0)) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     const nodes: Array<NodeConfig> = orderedEvents?.map(getNode) || [];
  //     const graphData: GraphData = {
  //       nodes,
  //       edges,
  //     };
  //     return graphData;
  //   },
  //   [getNode],
  // );

  // const getInstance = useCallback(
  //   (item: WorkflowEventType): ProductInventoryItem => {
  //     const nodeTemplate = workflowTemplate?.workflowEvents?.find((el) => el.id === item?.id);
  //     const defaultProductId = nodeTemplate?.productId || nodeTemplate?.outputProducts?.[0]?.id;
  //     const defaultLocationId = nodeTemplate?.locationId;

  //     // eslint-disable-next-line no-shadow
  //     const productId =
  //       item?.products?.[0]?.productId ||
  //       item?.outputProducts?.[0]?.productId ||
  //       item?.newInputProducts?.[0]?.productId ||
  //       item?.newProducts?.[0]?.productId ||
  //       defaultProductId;
  //     // eslint-disable-next-line no-shadow
  //     const product = getProductById(productId, products);
  //     const primaryId =
  //       item.products?.[0]?.lotSerial ||
  //       item?.outputProducts?.[0]?.lotSerial ||
  //       item?.newInputProducts?.[0]?.lotSerial ||
  //       item?.newProducts?.[0]?.lotSerial ||
  //       '';
  //     const instanceId = item?.eventId || '';
  //     const quantity =
  //       item?.products?.[0]?.quantity ||
  //       item?.outputProducts?.[0]?.quantity ||
  //       item?.newOutputProducts?.[0]?.quantity ||
  //       0;
  //     const locationId = item?.locationId || defaultLocationId || '';
  //     const destinationId = item?.destinationLocationId || '';
  //     const location = getLocationById(locationId, locations);
  //     const instance: ProductInventoryItem = {
  //       id: item?.id,
  //       name: product?.name || '',
  //       productId,
  //       instanceId,
  //       primaryId,
  //       lotSerial: primaryId,
  //       quantity,
  //       unitOfMeasure: product?.unitOfMeasure || 'LBS',
  //       eventName: item.eventType,
  //       location: location?.name || '',
  //       locationAddress: location?.address?.line2 || '',
  //       locationId: locationId || '',
  //       destinationId,
  //       eventId: item?.id,
  //       isContainer: (item.containerIdentifiers?.length || 0) > 0,
  //       containerId: instanceId,
  //       containerIdentifier: '',
  //       containerItems: [],
  //       tradePartnerName: location?.tradePartnerName || '',
  //       address: location?.address,
  //       eventDate: item.eventTime,
  //       loggedDate: new Date(),
  //       purchaseOrder: item.purchaseOrder,
  //       shipmentType: 'Internal',
  //       shipped: false,
  //       productIdentifierType: product?.productIdentifierType,
  //     };
  //     return instance;
  //   },
  //   [products, locations, workflowTemplate],
  // );
  // const generateInstances = useCallback(
  //   (workflow: GetWorkflowByIdResponse): Array<ProductInventoryItem> => {
  //     const events = [
  //       ...(workflow?.commissionEvents?.map((el) => ({ ...el, eventType: 'Commission' })) || []),
  //       ...(workflow?.decommissionEvents?.map((el) => ({ ...el, eventType: 'Decommission' })) ||
  //         []),
  //       ...(workflow?.aggregateEvents?.map((el) => ({ ...el, eventType: 'Aggregate' })) || []),
  //       ...(workflow?.disaggregateEvents?.map((el) => ({ ...el, eventType: 'Disagregate' })) || []),
  //       ...(workflow?.observeEvents?.map((el) => ({ ...el, eventType: 'Observe' })) || []),
  //       ...(workflow?.receiveEvents?.map((el) => ({ ...el, eventType: 'Receive' })) || []),
  //       ...(workflow?.shipEvents?.map((el) => ({ ...el, eventType: 'Ship' })) || []),
  //       ...(workflow?.transformEvents?.map((el) => ({ ...el, eventType: 'Transform' })) || []),
  //     ];
  //     /* sort by order key asc */
  //     const orderedEvents = events.sort((a, b) => {
  //       if ((a?.order || 0) < (b?.order || 0)) {
  //         return -1;
  //       }
  //       if ((a?.order || 0) > (b?.order || 0)) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     const instances: Array<ProductInventoryItem> = orderedEvents?.map(getInstance) || [];

  //     return instances;
  //   },
  //   [getInstance],
  // );

  // const logEvents = useCallback(
  //   async (graph: Graph, form: FormInstance<DataItem>, formdata: DataItem) => {
  //     try {
  //       const stack: Array<EventResponse> = [];
  //       const nodes = graph.getNodes()?.map((node) => node.getModel());
  //       const isNodesNotFilled = nodes?.some(
  //         (node) => !(node?.eventData as WorkflowEventType)?.eventId,
  //       );
  //       if (isNodesNotFilled) {
  //         throw new Error('Please fill all nodes before logging events.');
  //       }

  //       const totalNodes = nodes?.length || 1;
  //       setPercent(0);
  //       // eslint-disable-next-line no-restricted-syntax
  //       for (const node of nodes) {
  //         setPercent((p) => p + Math.floor(100 / totalNodes));
  //         if (node?.isSuccess) {
  //           // eslint-disable-next-line no-continue
  //           continue;
  //         }
  //         // eslint-disable-next-line no-await-in-loop
  //         const eventData = node?.eventData as WorkflowEventType;
  //         let res: EventResponse | null;
  //         const sourceIds = (eventData?.sourceId || []) as Array<string>;
  //         const instances: DataItem['instances'] = form?.getFieldValue('instances') || [];
  //         const sourceNodes =
  //           instances?.filter((instance) => sourceIds.includes(instance?.id || '')) || [];

  //         const currentInstance = instances?.find((instance) => instance?.id === eventData?.id);

  //         try {
  //           switch (node?.eventType) {
  //             case 'Commission':
  //               // eslint-disable-next-line no-await-in-loop
  //               res = await createCommission.mutateAsync({ ...eventData, eventId: undefined });
  //               /* update saved instance */
  //               if (eventData?.products?.[0]?.instanceId) {
  //                 eventData.products[0].instanceId = res?.products?.[0]?.instanceId || '';
  //               }
  //               eventData.eventId = res?.id || '';
  //               eventData.workflowEventStatus = 'Succeeded';
  //               if (currentInstance) {
  //                 currentInstance.instanceId = res?.products?.[0]?.instanceId || '';
  //                 currentInstance.eventId = res?.id || '';
  //               }
  //               node.isSuccess = true;
  //               node.isError = false;
  //               stack.push(res);
  //               break;

  //             case 'Decommission':
  //               // eslint-disable-next-line no-await-in-loop
  //               res = await createDecommission.mutateAsync({ ...eventData, eventId: undefined });
  //               /* update saved instance */
  //               if (eventData?.products?.[0]?.instanceId) {
  //                 eventData.products[0].instanceId = res?.products?.[0]?.instanceId || '';
  //               }
  //               eventData.eventId = res?.id || '';
  //               eventData.workflowEventStatus = 'Succeeded';
  //               if (currentInstance) {
  //                 currentInstance.instanceId = res?.products?.[0]?.instanceId || '';
  //                 currentInstance.eventId = res?.id || '';
  //               }
  //               node.isSuccess = true;
  //               node.isError = false;
  //               stack.push(res);
  //               break;

  //             case 'Observe':
  //               /* observe depends on previos node instance id */
  //               // eslint-disable-next-line no-await-in-loop
  //               res = await createObserce.mutateAsync({
  //                 ...eventData,
  //                 products:
  //                   eventData?.newProducts?.map((p) => ({
  //                     ...p,
  //                     ...(sourceNodes?.[0]?.instanceId && {
  //                       instanceId: sourceNodes?.[0]?.instanceId || '',
  //                     }),
  //                   })) || [],
  //                 eventId: undefined,
  //               });
  //               node.isSuccess = true;
  //               node.isError = false;
  //               /* update saved instance */
  //               if (eventData?.products?.[0]?.instanceId) {
  //                 eventData.products[0].instanceId = res?.products?.[0]?.instanceId || '';
  //               }
  //               eventData.eventId = res?.id || '';
  //               eventData.workflowEventStatus = 'Succeeded';
  //               if (currentInstance) {
  //                 currentInstance.instanceId = res?.products?.[0]?.instanceId || '';
  //                 currentInstance.eventId = res?.id || '';
  //               }
  //               stack.push(res);
  //               break;

  //             case 'Transform':
  //               /* observe depends on previos node instance id */
  //               // eslint-disable-next-line no-await-in-loop
  //               res = await createTransform.mutateAsync({
  //                 ...eventData,
  //                 inputProducts:
  //                   eventData?.newInputProducts?.map((p, idx) => ({
  //                     ...p,
  //                     ...(sourceNodes?.[idx]?.instanceId && {
  //                       instanceId: sourceNodes?.[idx]?.instanceId,
  //                     }),
  //                   })) || [],
  //                 outputProducts:
  //                   eventData?.newOutputProducts?.map((p) => ({
  //                     ...p,
  //                   })) || [],
  //                 eventId: undefined,
  //               });
  //               node.isSuccess = true;
  //               node.isError = false;
  //               /* update saved instance */
  //               if (eventData?.inputProducts?.[0]?.instanceId) {
  //                 eventData.inputProducts[0].instanceId = res?.inputProducts?.[0]?.instanceId || '';
  //               }
  //               eventData.eventId = res?.id || '';
  //               eventData.workflowEventStatus = 'Succeeded';
  //               if (currentInstance) {
  //                 currentInstance.eventId = res?.id || '';
  //                 currentInstance.instanceId = res?.outputProducts?.[0]?.instanceId || '';
  //               }
  //               stack.push(res);
  //               break;

  //             case 'Ship':
  //               /* observe depends on previos node instance id */
  //               // eslint-disable-next-line no-await-in-loop
  //               res = await createShip.mutateAsync({
  //                 ...eventData,
  //                 products:
  //                   eventData?.products?.map((p) => ({
  //                     ...p,
  //                     ...(sourceNodes?.[0]?.instanceId && {
  //                       instanceId: sourceNodes?.[0]?.instanceId,
  //                     }),
  //                   })) || [],
  //                 eventId: undefined,
  //               });
  //               node.isSuccess = true;
  //               node.isError = false;
  //               /* update saved instance */
  //               if (eventData?.products?.[0]?.instanceId) {
  //                 eventData.products[0].instanceId = res?.products?.[0]?.instanceId || '';
  //               }
  //               eventData.eventId = res?.id || '';
  //               eventData.workflowEventStatus = 'Succeeded';
  //               if (currentInstance) {
  //                 currentInstance.instanceId = res?.products?.[0]?.instanceId || '';
  //                 currentInstance.eventId = res?.id || '';
  //               }
  //               stack.push(res);
  //               break;

  //             case 'Receive':
  //               /* Receive depends on previos node instance id */
  //               // eslint-disable-next-line no-await-in-loop
  //               res = await createReceive.mutateAsync({
  //                 ...eventData,
  //                 products:
  //                   eventData?.products?.map((p, idx) => ({
  //                     ...p,
  //                     instanceId: sourceNodes?.[idx]?.instanceId || '',
  //                     shipEventId: sourceNodes?.[idx]?.eventId || eventData?.eventId || '',
  //                     remainingQuantity: 0,
  //                   })) || [],
  //                 eventId: undefined,
  //               });
  //               node.isSuccess = true;
  //               node.isError = false;
  //               /* update saved instance */
  //               if (eventData?.products?.[0]?.instanceId) {
  //                 eventData.products[0].instanceId = res?.products?.[0]?.instanceId || '';
  //                 eventData.products[0].shipEventId =
  //                   sourceNodes?.[0]?.eventId || eventData?.eventId || '';
  //               }
  //               eventData.eventId = res?.id || '';
  //               eventData.workflowEventStatus = 'Succeeded';
  //               if (currentInstance) {
  //                 currentInstance.instanceId = res?.products?.[0]?.instanceId || '';
  //                 currentInstance.eventId = res?.id || '';
  //               }

  //               stack.push(res);
  //               break;

  //             case 'Aggregate':
  //               /* observe depends on previos node instance id */
  //               // eslint-disable-next-line no-await-in-loop
  //               res = await createAggregate.mutateAsync({
  //                 ...eventData,
  //                 products:
  //                   eventData?.products?.map((p, idx) => ({
  //                     ...p,
  //                     ...(sourceNodes?.[idx]?.instanceId && {
  //                       instanceId: sourceNodes?.[idx]?.instanceId || '',
  //                     }),
  //                   })) || [],
  //                 eventId: undefined,
  //               });
  //               node.isSuccess = true;
  //               node.isError = false;
  //               /* update saved instance */
  //               if (eventData?.products?.[0]?.instanceId) {
  //                 eventData.products =
  //                   res?.container?.products?.map((el) => ({
  //                     ...el,
  //                     instanceId: el?.instanceId || '',
  //                   })) || [];
  //               }
  //               eventData.eventId = res?.id || '';
  //               eventData.workflowEventStatus = 'Succeeded';
  //               if (currentInstance) {
  //                 currentInstance.instanceId = undefined;
  //                 currentInstance.containerId = res?.container?.id;
  //                 currentInstance.containerItems = res?.container?.products || [];
  //               }

  //               stack.push(res);
  //               break;

  //             default:
  //               res = null;
  //               break;
  //           }
  //         } catch (error) {
  //           message.error(errorHandler(error));
  //           node.isSuccess = false;
  //           node.isError = true;
  //           eventData.workflowEventStatus = 'Failed';
  //         }

  //         /* re render graph */
  //         const updatedNodes = nodes?.map((n) => {
  //           if (n?.id === node?.id) {
  //             return {
  //               ...node,
  //               eventData,
  //             };
  //           }
  //           return n;
  //         });

  //         const updatedInstances =
  //           instances?.map((i) => {
  //             if (i?.id === currentInstance?.id) {
  //               return { ...currentInstance };
  //             }
  //             return i;
  //           }) || [];

  //         form?.setFieldsValue({
  //           graphData: {
  //             ...formdata?.graphData,
  //             nodes: updatedNodes as NodeConfig[],
  //           },
  //           instances: updatedInstances,
  //         });
  //       }

  //       const isNodeError = nodes?.find((node) => node?.isError || false);
  //       if (isNodeError) {
  //         throw new Error('Error in workflow');
  //       }

  //       return stack;
  //     } catch (error) {
  //       throw error;
  //     }
  //   },
  //   [
  //     createCommission,
  //     createDecommission,
  //     createObserce,
  //     createShip,
  //     createTransform,
  //     createReceive,
  //     createAggregate,
  //   ],
  // );

  return {
    // queryClient,
    products,
    // product,
    // locations,
    // attributes,
    // tradePartners,
    // productInstances,
    // percent,
    // template,
    // generateWorkflowGraph,
    // generateInstances,
    // logEvents,
  };
};
export default useActions;

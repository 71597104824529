import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { Alert, RowProps, Tag, Typography } from 'antd';
import { LocationSelect, ProductSelect, TradePartnerSelect } from 'components/GSelect';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { getDisabledAlertDate, getDocTypeEnum, isPastExpiryDate } from 'utils';
import styles from '../index.module.less';
import DocumentAttachment from './DocumentAttachment';
import DocumentAttachmentHeader from './DocumentAttachmentHeader';
import { AddDocumentFormFieldsProps, DataItem } from './typings';
// const colProps = { xs: 24, sm: 12, md: 8, lg: 6 };
const colProps2Cols = { xs: 24, sm: 24, md: 24, lg: 12 };
const colProps26Cols = { xs: 24, sm: 12, md: 12, lg: 12 };
const colProps24Cols = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0 };

const rowProps: RowProps = { gutter: [24, 24] };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const tagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      className={styles.tagfieldinput}
    >
      {label}
    </Tag>
  );
};
const AddDocumentFormFields = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasAdvancedSettings = false,
  docContainerStyle,
  eventTagAlert,
  setEventTagAlert,
}: AddDocumentFormFieldsProps): ProFormColumnsType<DataItem>[] => {
  const [queryParams] = useSearchParams();
  const { productId = '' } = useParams();
  const isReceive = queryParams?.get('shipmentId') || productId === 'new_receive';
  const { t } = useTranslation('pages', { keyPrefix: 'documents.add_document' });
  const [showPastExpiryTooltip, setShowPastExpiryTooltip] = useState<boolean>(false);
  return [
    {
      valueType: 'group',
      title: t('form_fields.doc_inf_title'),
      rowProps,
      colProps: colProps2Cols,
      columns: [
        {
          title: t('form_fields.doc_title'),
          dataIndex: 'title',
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.doc_title_req'),
              },
              {
                whitespace: true,
                message: t('form_fields.doc_title_req'),
              },
            ],
          },
          fieldProps: {
            placeholder: t('form_fields.doc_title_placeholder'),
          },
          colProps: colProps26Cols,
        },
        {
          title: t('form_fields.doc_type_title'),
          dataIndex: 'documentType',
          valueType: 'select',
          valueEnum: getDocTypeEnum(),
          fieldProps: {
            allowSearch: true,
            showSearch: true,
            placeholder: t('form_fields.doc_type_placeholder'),
          },
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.doc_type_req'),
              },
            ],
          },
          colProps: colProps26Cols,
        },
        {
          valueType: 'dependency',
          fieldProps: {
            name: ['documentType'],
          },
          columns: ({ documentType }) => [
            {
              title: t('form_fields.doc_type_name_title'),
              dataIndex: 'documentTypeName',
              hideInForm: documentType !== 'other',

              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.doc_type_name_placeholder'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.doc_type_name_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.doc_type_name_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.doc_exp_date_title'),
              dataIndex: 'expirationDate',
              valueType: 'date',
              fieldProps: (form) => ({
                placeholder: t('form_fields.doc_exp_date_placeholder'),
                className: 'full-width',
                onChange: () => {
                  const expiry = form?.getFieldValue('expirationDate');
                  setShowPastExpiryTooltip(isPastExpiryDate(expiry));
                  form?.setFieldsValue({ expirationDateAlert: undefined });
                },
              }),
              formItemProps: {
                rules: [
                  {
                    required: documentType === 'certification' || documentType === 'bol',
                    message: t('form_fields.doc_exp_date_req'),
                  },
                ],
              },
              colProps: colProps26Cols,

              tooltip: {
                icon: <InfoCircleOutlined />,
                title: t('form_fields.doc_exp_date_tooltip'),
                placement: 'right',
                key: null,
                type: '',
                props: null,
              },
            },
            {
              valueType: 'dependency',
              fieldProps: {
                name: ['expirationDate'],
              },
              columns: ({ expirationDate }) => [
                {
                  title: t('form_fields.doc_exp_date_alert_title'),
                  dataIndex: 'expirationDateAlert',
                  valueType: 'date',
                  hideInForm: !expirationDate || showPastExpiryTooltip,
                  fieldProps: {
                    placeholder: t('form_fields.doc_exp_alert_date_placeholder'),
                    className: 'full-width',
                    disabledDate: getDisabledAlertDate(expirationDate),
                  },
                  formItemProps: {
                    rules: [
                      {
                        required: documentType === 'certification' || documentType === 'bol',
                        message: t('form_fields.doc_exp_date_req'),
                      },
                    ],
                  },
                  colProps: colProps26Cols,
                  tooltip: {
                    icon: <InfoCircleOutlined />,
                    title: t('form_fields.doc_exp_alert_date_tooltip'),
                    placement: 'right',
                    key: null,
                    type: '',
                    props: null,
                  },
                },
              ],
            },
            {
              title: t('form_fields.ship_from'),
              dataIndex: 'shipFrom',
              hideInForm: documentType !== 'bol',
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.ship_from'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.ship_from_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.ship_from_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.doc_blockchain_title'),
              dataIndex: 'blockchain',
              initialValue: 'Algorand',
              valueType: 'select',
              valueEnum: {
                Algorand: {
                  text: 'Algorand',
                  status: 'default',
                },
                None: {
                  text: 'None',
                  status: 'default',
                },
              },
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.doc_blockchain_placeholder'),
                disabled: true,
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.sid'),
              dataIndex: 'sID',
              hideInForm: documentType !== 'bol',
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.sid'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.sid_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.sid_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.ship_to'),
              dataIndex: 'shipTo',
              hideInForm: documentType !== 'bol',
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.ship_to'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.ship_to_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.ship_to_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.fob'),
              dataIndex: 'fOB',
              hideInForm: documentType !== 'bol',
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.fob'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.fob_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.fob_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.cid'),
              dataIndex: 'cID',
              hideInForm: documentType !== 'bol',
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.cid'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.cid_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.cid_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.ship_date'),
              dataIndex: 'shipDate',
              valueType: 'date',
              hideInForm: documentType !== 'bol',
              fieldProps: {
                placeholder: t('form_fields.ship_date'),
                className: 'full-width',
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.doc_ship_date_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              hideInForm: !!documentType,
              colProps: colProps2Cols,
              renderFormItem: () => (
                <Typography.Text type="secondary">
                  {t('form_fields.doc_no_doc_type_text')}
                </Typography.Text>
              ),
              formItemProps: {
                className: 'no-margin-divider',
              },
              fieldProps: {
                className: 'no-margin-divider',
              },
            },
            {
              title: t('form_fields.doc_cert_std_title'),
              dataIndex: 'certificationStandard',
              hideInForm: documentType !== 'certification',

              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.doc_cert_std_placeholder'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.doc_cert_std_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.doc_cert_std_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.doc_agency_title'),
              dataIndex: 'agency',
              hideInForm: documentType !== 'certification',

              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.doc_agency_placeholder'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.doc_agency_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.doc_agency_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.doc_indentification_title'),
              dataIndex: 'identification',
              hideInForm: documentType !== 'certification',

              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.doc_indentification_placeholder'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.doc_indentification_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.doc_indentification_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title:
                documentType === 'bol'
                  ? t('form_fields.doc_bol_po_title')
                  : t('form_fields.doc_purchase_order_title'),
              dataIndex: 'purchaseOrder',
              hideInForm:
                !documentType || documentType === 'certification' || documentType === 'other',
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder:
                  documentType === 'bol'
                    ? t('form_fields.doc_bol_po_title')
                    : t('form_fields.doc_purchase_order_placeholder'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.doc_purchase_order_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.doc_purchase_order_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.items'),
              dataIndex: 'items',
              hideInForm: documentType !== 'bol',
              valueType: 'textarea',
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.items'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.items_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.items_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.carrier'),
              dataIndex: 'carrier',
              hideInForm: documentType !== 'bol',
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.carrier'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.carrier_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.carrier_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
            {
              title: t('form_fields.quantity_of_shipped_items'),
              dataIndex: 'quantitiesOfShippedItems',
              hideInForm: documentType !== 'bol',
              fieldProps: {
                allowSearch: true,
                showSearch: true,
                placeholder: t('form_fields.quantity_of_shipped_items'),
              },
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.quantity_of_shipped_items_req'),
                  },
                  {
                    whitespace: true,
                    message: t('form_fields.quantity_of_shipped_items_req'),
                  },
                ],
              },
              colProps: colProps26Cols,
            },
          ],
        },
        {
          valueType: 'divider',
          formItemProps: {
            className: 'no-margin-divider',
          },
          fieldProps: {
            className: 'no-margin-divider',
          },
        },
        {
          title: t('form_fields.doc_details_grp_title'),
          valueType: 'group',
          rowProps,
          columns: [
            {
              renderFormItem: () => (
                <Alert
                  message={t('form_fields.event_tag_alert_title')}
                  type="info"
                  closable
                  showIcon
                  className={styles.alertnote}
                  onClose={() => setEventTagAlert?.(false)}
                />
              ),
              formItemProps: {
                className: 'no-margin-divider',
              },
              fieldProps: {
                className: 'no-margin-divider',
              },
              hideInForm: !eventTagAlert,
            },
            {
              initialValue: [],
              title: t('form_fields.company'),
              dataIndex: 'tradePartners',
              fieldProps: {
                placeholder: t('form_fields.company'),
              },
              renderFormItem: () => <TradePartnerSelect mode="multiple" />,
              colProps: colProps24Cols,
            },
            {
              initialValue: [],
              dataIndex: 'locations',
              title: t('form_fields.location'),
              fieldProps: {
                placeholder: t('form_fields.location'),
              },
              renderFormItem: () => (
                <LocationSelect mode="multiple" placeholder={t('form_fields.location')} />
              ),
              colProps: colProps24Cols,
            },
            {
              initialValue: productId && !isReceive ? [productId] : [],
              dataIndex: 'products',
              title: t('form_fields.product'),
              fieldProps: {
                placeholder: t('form_fields.product'),
              },
              renderFormItem: () => <ProductSelect mode="multiple" allowLotSerial />,
              colProps: colProps24Cols,
            },
            {
              title: t('form_fields.doc_details_privacy_title'),
              dataIndex: 'privacy',
              colProps: colProps2Cols,
              valueType: 'radio',
              initialValue: 'Private',
              valueEnum: {
                Public: {
                  text: t('public'),
                  status: 'default',
                },
                Private: {
                  text: t('private'),
                  status: 'default',
                },
              },
              tooltip: {
                icon: <InfoCircleOutlined />,
                title: t('form_fields.doc_details_privacy_tooltip'),
                placement: 'right',
                key: null,
                type: '',
                props: null,
              },
            },
            {
              dataIndex: 'file',
              fieldProps: {
                className: 'no-display',
              },
              formItemProps: {
                className: 'no-display',
              },
              colProps: colPropsNull,
            },
          ],
        },
      ],
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['documentAttachment'],
      },
      columns: ({ documentAttachment = '' }: DataItem) => [
        {
          valueType: 'group',
          rowProps,
          colProps: colProps2Cols,
          columns: [
            {
              renderFormItem: (_schema, _config, form) => <DocumentAttachmentHeader form={form} />,
              formItemProps: {
                className: styles.documentheader,
              },
            },
            {
              dataIndex: 'documentAttachment',
              renderFormItem: (_schema, _config, form) => (
                <DocumentAttachment
                  form={form}
                  containerStyle={docContainerStyle}
                  documentAttachment={documentAttachment}
                />
              ),
            },
          ],
        },
      ],
    },
  ];
};
export default AddDocumentFormFields;

import { Line, LineConfig } from '@ant-design/plots';
import { Col, ColProps, Empty, Row } from 'antd';
import { useAuthenticationContext } from 'contexts';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetShipmentsSummaryResponse } from 'services/api/client/src';
import { inboundColor, mainStyle, outboundColor } from '../../../styles';
import styles from './Linechart.module.less';

interface LineChartProps {
  data: GetShipmentsSummaryResponse | undefined;
}

const colProps: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };

const ViewGraph: FC<LineChartProps> = ({ data }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipment_graph' });
  const { langCode } = useAuthenticationContext();

  const Inbound = useMemo(
    () =>
      data?.shipments?.map((el) => ({
        month: moment
          .utc(el?.date)
          .locale(langCode === '' ? 'en-US' : langCode)
          .format('MMM '),
        value: el.inboundShipments ? el.inboundShipments : 0,
        shipment: t('inbound'),
      })) || [],
    [data?.shipments, langCode, t],
  );
  const outbound = useMemo(
    () =>
      data?.shipments?.map((el) => ({
        month: moment
          .utc(el?.date)
          .locale(langCode === '' ? 'en-US' : langCode)
          .format('MMM '),
        value: el.outboundShipments ? el.outboundShipments : 0,
        shipment: t('outbound'),
      })) || [],
    [data?.shipments, langCode, t],
  );
  const isEmpty = useMemo(
    () => [...Inbound, ...outbound]?.filter((item) => item.value !== 0)?.length === 0,
    [Inbound, outbound],
  );

  const chartData = useMemo(
    () =>
      isEmpty
        ? [...Inbound, ...outbound]?.map((item) => ({
            ...item,
            value: null,
          }))
        : [...Inbound, ...outbound],
    [Inbound, isEmpty, outbound],
  );
  const config: LineConfig = {
    data: chartData,
    xField: 'month',
    yField: 'value',
    seriesField: 'shipment',
    color: ({ shipment }) => (shipment === t('inbound') ? inboundColor : outboundColor),
    style: mainStyle,
    point: {
      size: 4,
      shape: 'circle',
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: (element) => {
          const shipment = element?.getData();
          const color = shipment[0]?.shipment === t('inbound') ? inboundColor : outboundColor;
          return {
            shadowBlur: 4,
            stroke: color,
            fill: color,
            opacity: 0.3,
          };
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      offsetY: 10,
    },
  };
  return isEmpty ? (
    <Row className={styles.rowheight}>
      <Row className={styles.innerdiv}>
        <Col {...colProps} className={styles.emptycol}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_data')} />
        </Col>
        <Col {...colProps} className={styles.linecol}>
          <Line {...config} />
        </Col>
      </Row>
    </Row>
  ) : (
    <div className={styles.rowheight}>
      <div className={styles.innerdiv}>
        <Line {...config} />
      </div>
    </div>
  );
};

export default ViewGraph;

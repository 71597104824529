// @ts-nocheck
import { DeleteOutlined, InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, ColProps, Divider, Form, Space, Tooltip, Typography, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import { useActions } from 'hooks';
import { batchShipTemplate, getTranslatedCSVTemplate } from 'pages/Events/components/CSVTemplates';
import BatchShipTable from 'pages/Events/components/Forms/CSVTables/BatchShipTable';
import { CustomDataTemplateProps } from 'pages/Events/components/Sections/typings';
import React, { DragEvent, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAttributes, useInstanceQuantities, useTemplateById } from 'services/api';
import {
  CSVTemplate,
  checkDateFormat,
  checkTimeFormat,
  coreBizStep,
  coreDispositions,
  dateLocaleFormat,
  downloadXLSXTemplate,
  getInstanceByLot,
  getProductByName,
  getProductIdByName,
  getProductsT,
  getUom,
  msofficeHelpLink,
  readExcelTemplate,
  titletoKeys,
} from 'utils';
import { getDefaultValues } from 'utils/locations';
import { v4 as uuidv4 } from 'uuid';
import useShipmentStore from '../hooks/useShipmentStore';
import styles from './index.module.less';
import { DataItem } from './typings';

const uploadColProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 12 };

const { Text, Link } = Typography;
const { Dragger } = Upload;

export const CSVUpload: FC<CustomDataTemplateProps> = ({ form, editableTableFormRef }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.data_template' });
  const { setFieldsValue } = form;
  const { data: dataQuantities } = useInstanceQuantities();
  const instanceQuantities = useMemo(() => dataQuantities?.results || [], [dataQuantities]);
  const { products } = useActions();
  const templateId = Form.useWatch?.('customData', form) || '';
  const { data: template } = useTemplateById(templateId);
  const csvData: Array<DataItem> = Form.useWatch?.('csvData', form) || [];
  const locationId = Form?.useWatch?.('shipFromAddress', form) || '';
  const { data: attributes } = useAttributes();

  const isMultipleDates = true;

  const defaultValues = useMemo(
    () => getDefaultValues({ template, attributes, locationId }),
    [locationId, template, attributes],
  );

  const templateAttributes = useMemo(
    () =>
      template?.templateAttributes?.reduce((acc, templateObj) => {
        if (
          templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep' ||
          templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'disposition'
        ) {
          if (templateObj?.attribute?.fieldProperties?.values?.defaultValue) {
            const initialValue =
              templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep'
                ? coreBizStep()?.find?.(
                    (el) =>
                      el.urn === templateObj?.attribute?.fieldProperties?.values?.defaultValue,
                  )?.name || undefined
                : coreDispositions()?.find?.(
                    (el) =>
                      el.urn === templateObj?.attribute?.fieldProperties?.values?.defaultValue,
                  )?.name || undefined;
            acc[String(templateObj?.attribute?.fieldProperties?.fields?.propertyName || '')] = {
              dataIndex: templateObj?.attribute?.fieldProperties?.fields?.propertyName || '',
              title: templateObj?.attribute?.name || '',
              initialValue,
            };
          }
        } else {
          acc[String(templateObj?.attribute?.fieldProperties?.fields?.propertyName || '')] = {
            dataIndex: templateObj?.attribute?.fieldProperties?.fields?.propertyName || '',
            title: templateObj?.attribute?.name || '',
            ...(defaultValues?.[
              templateObj?.attribute?.fieldProperties?.fields?.propertyName || ''
            ] && {
              initialValue:
                defaultValues?.[
                  templateObj?.attribute?.fieldProperties?.fields?.propertyName || ''
                ],
            }),
          };
        }

        return acc;
      }, {} as CSVTemplate),
    [template, defaultValues],
  );
  const overallTemplate: CSVTemplate = useMemo(
    () => ({
      ...batchShipTemplate,
      ...templateAttributes,
      ...(isMultipleDates && {
        quantity: {
          title: getProductsT('quantity'),
          dataIndex: 'quantity',
        },
      }),
    }),
    [templateAttributes, isMultipleDates],
  );
  const setCsvData = useCallback(
    (value: Array<DataItem>) => {
      setFieldsValue({ csvData: value });
    },
    [setFieldsValue],
  );
  const onDownloadCSVTemplate = () => {
    downloadXLSXTemplate(getTranslatedCSVTemplate(overallTemplate), 'Batch Ship');
  };
  const onCSVUpload: UploadProps['onChange'] = async (info) => {
    const { file } = info;
    file.status = 'done';
    if (!file.url && !file.preview && file.originFileObj) {
      const data: any = await readExcelTemplate(file.originFileObj);

      const newData: Array<DataItem> =
        data?.map((item: any) => {
          const instance = getInstanceByLot(item?.[getProductsT('lot_number')], instanceQuantities);
          const eventDate = dateLocaleFormat(instance?.eventDate);
          const product = getProductByName(item?.[getProductsT('csv_product_title')], products);
          const unitOfMeasure = getUom({ product });
          const currentInventory = product?.currentInventory || 0;
          return {
            id: item?.id || uuidv4(),
            ...titletoKeys(overallTemplate, item),
            date: checkDateFormat(item?.[getProductsT('date')]),
            time: checkTimeFormat(item?.[getProductsT('time')]),
            productId: getProductIdByName(item?.[getProductsT('csv_product_title')], products),
            productName: item?.[getProductsT('csv_product_title')],
            primaryId: instance?.instanceId || instance?.containerId,
            eventDate,
            lotSerial: item?.[getProductsT('lot_number')],
            instanceInventory: instance?.quantity,
            unitOfMeasure,
            currentInventory,
          };
        }) || [];

      setCsvData(newData);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [files, setFiles] = useState<any>();
  const uploadProps: UploadProps = {
    name: 'csvfile',
    multiple: false,
    maxCount: 1,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: onCSVUpload,
    onDrop: (e: DragEvent<HTMLDivElement>) => {
      setFiles(e.dataTransfer.files);
    },
  };
  const renderTable = useCallback(
    () => (
      <BatchShipTable
        form={form}
        editableTableFormRef={editableTableFormRef}
        defaultValues={defaultValues}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValues],
  );

  return (
    <>
      <Divider className="no-margint-divider" />
      <Space size="large" direction="vertical" className={styles.csvbtn}>
        <Space>
          <Text className="ant-pro-form-group-title">{t('csv_upload')}</Text>
          <Tooltip
            title={
              <div className={styles.tooltiplink}>
                {t('upload_tooltip')}
                &nbsp;
                <Link target="_blank" href={msofficeHelpLink} rel="noreferrer">
                  {t('upload_tooltip_href')}
                </Link>
              </div>
            }
          >
            <Typography.Text type="secondary">
              <InfoCircleOutlined className={styles.tooltip} />
            </Typography.Text>
          </Tooltip>
        </Space>
        {!csvData?.length && (
          <Button shape="round" type="primary" onClick={onDownloadCSVTemplate}>
            {t('download_csv_template')}
          </Button>
        )}
      </Space>

      {csvData?.length ? (
        <div>
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            shape="round"
            ghost
            onClick={() => setCsvData([])}
            className={styles.removebtn}
          >
            {t('remove_file')}
          </Button>
          {renderTable()}
        </div>
      ) : (
        <Col {...uploadColProps}>
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('upload_desc')}</p>
          </Dragger>
        </Col>
      )}
    </>
  );
};

const DataTemplate: FC<CustomDataTemplateProps> = ({ form, editableTableFormRef }) => {
  const templateId = form?.getFieldValue?.('customData') || '';

  const dataEntryMethod = useShipmentStore((state) => state.dataEntryMethod);

  if (dataEntryMethod === 'manual' && !templateId) {
    return null;
  }

  return <CSVUpload form={form} editableTableFormRef={editableTableFormRef} />;
};

export default React.memo(DataTemplate);

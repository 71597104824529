import { useMemo, useState } from 'react';
import { useOrphanSuggestions } from 'services/api';
import { ListOrphansSuggestionsOptionalParams } from 'services/api/client/src';

const useListOrphanMatches = (initParam: ListOrphansSuggestionsOptionalParams) => {
  const [params, setParams] = useState<ListOrphansSuggestionsOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    ...initParam,
  });

  const { data: suggestions, isLoading } = useOrphanSuggestions(params);

  const totalItems = useMemo(() => suggestions?.totalItems || 0, [suggestions?.totalItems]);

  return {
    suggestions: suggestions?.results,
    isLoading,
    params,
    setParams,
    totalItems,
  };
};

export default useListOrphanMatches;

import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button, Modal, Space, TablePaginationConfig, Typography } from 'antd';
import { FilterValue } from 'antd/lib/table/interface';
import { GTable } from 'components';
import { useTableSearchFilter } from 'hooks';
import React, { FC, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useProductById, useProductContainerInstances } from 'services/api';
import {
  ContainerProductInstanceResponse,
  GetContainerInventoryOptionalParams,
} from 'services/api/client/src';
import { getUom } from 'utils';
import styles from './index.module.less';
import { ContainerModalProps } from './typings';
import useProductStore from './useProductStore';

type ColumnsType = {
  onRowActionClick: (actionPayload?: ContainerProductInstanceResponse) => void;
  productSearchfilter: ProColumns;
  productId: string;
  t?: TFunction<'pages', 'products'>;
};

/**
 * Columns
 * Defines the columns configuration for displaying container product instance quantity data.
 * @param onRowActionClick Function to handle click events on row actions.
 * @param productSearchfilter Configuration object for the product name column filter.
 * @param productId The ID of the product.
 * @param t Translation function.
 * @returns An array of ProColumns<ContainerProductInstanceResponse> objects representing the columns configuration.
 */
const Columns = ({
  onRowActionClick,
  productSearchfilter,
  productId,
  t,
}: ColumnsType): ProColumns<ContainerProductInstanceResponse>[] => [
  {
    dataIndex: 'parentProductId',
    hideInTable: true,
  },
  {
    title: t?.('product_name'),
    dataIndex: 'productName',
    fixed: 'left',
    width: 180,
    ...productSearchfilter,
  },
  {
    title: t?.('primary_id'),
    dataIndex: 'lotSerial',

    render: (text, record) => `${t?.('lot')}: ${record?.lotSerial || ''}`,
    width: 180,
    sorter: true,
  },
  {
    title: t?.('quantity'),
    dataIndex: 'quantity',
    sorter: true,
    width: 140,
    render: (text, record) => `${record?.quantity} ${getUom({ product: record })}`,
  },
  {
    title: t?.('last_event'),
    dataIndex: 'eventType',
    width: 140,
    ellipsis: true,
  },
  {
    title: t?.('location'),
    dataIndex: 'locationName',
    width: 150,
    ellipsis: true,
  },
  {
    dataIndex: 'actions',
    hideInSetting: true,
    valueType: 'option',
    fixed: 'right',
    width: 200,
    render: (text, record) => {
      const pId = record?.productId;
      const isPidDifferent = pId !== productId;

      return (
        isPidDifferent && (
          <Button
            className="actions"
            onClick={() => onRowActionClick(record)}
            type="primary"
            ghost
            size="small"
          >
            {t?.('goto_inventory')}
          </Button>
        )
      );
    },
  },
];
/**
 * ContainerModal
 * Represents a modal component for displaying container information and its associated product instances.
 * @param modal The modal properties.
 */
const ContainerModal: FC<ContainerModalProps> = ({ modal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  const actionRef = useRef<ActionType>();
  const navigate = useNavigate();
  const { productId = '1' } = useParams();
  const { data: product } = useProductById(productId);

  const productInstance = useProductStore((state) => state.product);

  const [params, setParams] = useState<GetContainerInventoryOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
  });
  const { data: containerInstances, isLoading } = useProductContainerInstances(
    productInstance?.containerId || '',
  );
  const records = containerInstances?.results;
  const totalItems = containerInstances?.totalItems;
  const onRowActionClick = (actionPayload?: ContainerProductInstanceResponse) => {
    modal?.hide();
    const pId = actionPayload?.productId;
    navigate(`../../${pId}`);
  };
  const onSearch = (text: string) => {
    setParams({
      ...params,
      pageNumber: 1,
      // @ts-ignore bug to be created for backend team
      productName: text,
    });
  };
  const { filters: productSearchfilter } = useTableSearchFilter({
    title: t('product_name'),
    onSearch,
  });
  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
  ) => {
    const apiFilters: GetContainerInventoryOptionalParams = {};
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        ...apiFilters,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        ...apiFilters,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };

  return (
    <Modal
      width="60%"
      {...modal}
      title={`${t('sscc')}: ${productInstance?.containerIdentifier || ''}`}
      cancelText={t('done')}
      cancelButtonProps={{
        type: 'primary',
        shape: 'round',
      }}
      okButtonProps={{ hidden: true }}
    >
      <Space className={styles.containertitle}>
        <Typography.Text>{`${product?.name || ''} ${t('total_quantity_sscc')}:`}</Typography.Text>
        <Typography.Text strong>
          {t('sscc_content', {
            quantity: productInstance?.quantity || 0,
            unit: getUom({ product: productInstance }),
            product: product?.name || '',
          })}
        </Typography.Text>
      </Space>
      <br />
      <GTable<ContainerProductInstanceResponse>
        actionRef={actionRef}
        columns={Columns({ onRowActionClick, productSearchfilter, productId, t })}
        loading={isLoading}
        value={records}
        pagination={{ defaultPageSize: params.pageSize, total: totalItems }}
        onTableChange={onTableChange}
        options={{
          setting: false,
          reload: false,
        }}
        scroll={{ x: 1000 }}
        rowSelection={false}
      />
    </Modal>
  );
};
export default React.memo(ContainerModal);

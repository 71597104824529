import ProCard from '@ant-design/pro-card';
import { Divider, Skeleton, Tabs } from 'antd';
import React, { CSSProperties, FC, useMemo, useState } from 'react';
import { useEventListById } from 'services/api';
import Activity from './Activity';
import Events from './Events';
import TabTitle from './TabTitle';
import styles from './index.module.less';
import { ShipmentInviteProps } from './typings';

const cardbodyStyle: CSSProperties = {
  padding: '2px 16px 12px',
  // height: '51.9vh',
  // overflow: 'hidden',
};
const sections = ['activity', 'events'];

const ActivityInvite: FC<ShipmentInviteProps> = ({ t }) => {
  const [, setCurrentTab] = useState(sections[0]);

  const { isLoading } = useEventListById({
    pageNumber: 1,
    pageSize: 20,
    sortBy: '-createdTime',
  });

  const onTabChange = (activeKey: string) => {
    setCurrentTab(activeKey);
  };
  const skeletonLoader = useMemo(() => {
    if (isLoading) {
      return (
        <div className={styles.inboundskeletion}>
          <Skeleton loading avatar active />
          <Divider />
          <Skeleton loading avatar active />
          <Divider />
          <Skeleton loading avatar active />
        </div>
      );
    }
    return false;
  }, [isLoading]);
  return (
    <ProCard loading={skeletonLoader} className={styles.ecardstyle} bodyStyle={cardbodyStyle}>
      <Tabs defaultActiveKey={sections[0]} onChange={onTabChange}>
        <Tabs.TabPane
          tab={<TabTitle title={t('activity_event.activity_title')} />}
          key={sections[0]}
        >
          <div id="inboundcontent">
            <Activity t={t} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={<TabTitle title={t('activity_event.events_title')} />} key={sections[1]}>
          <Events t={t} />
        </Tabs.TabPane>
      </Tabs>
    </ProCard>
  );
};
export default React.memo(ActivityInvite);

import { Modal, Tabs, Typography } from 'antd';
import {
  aggregationImg,
  commissionImg,
  disaggregateImg,
  manyToOneImg,
  observeImg,
  oneToManyImg,
  oneToOneImg,
  sendReceiveImg,
} from 'assets';
import { useWindowSize } from 'hooks';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import { EventTypesModalProps } from './typings';

const EventTypesModal: FC<EventTypesModalProps> = ({
  activeTabKey = 'commission',
  ...modalProps
}) => {
  const [width] = useWindowSize();
  const tabPosition = useMemo(() => {
    if (width < 992) {
      return 'top';
    }
    return 'left';
  }, [width]);
  const modalWidth = useMemo(() => {
    if (width < 992) {
      return '80%';
    }
    return '40%';
  }, [width]);

  const [tabKey, setTabKey] = useState<string>();
  useEffect(() => {
    setTabKey(activeTabKey);
  }, [activeTabKey]);

  const { t } = useTranslation('pages', { keyPrefix: 'event_types' });
  return (
    <Modal
      className={styles.modal}
      {...modalProps}
      okText={t('ok_text')}
      title={t('title')}
      width={modalWidth}
      okButtonProps={{ shape: 'round' }}
      cancelButtonProps={{ shape: 'round', hidden: true }}
    >
      <Tabs tabPosition={tabPosition} onChange={setTabKey} activeKey={tabKey}>
        <Tabs.TabPane tab={t('commission.title')} key="commission" className={styles.tabpanel}>
          <Typography.Title level={2} className={styles.title}>
            {t('commission.title')}
          </Typography.Title>
          <Typography.Paragraph>{t('commission.description')}</Typography.Paragraph>
          <div className={styles.imagecontainer}>
            <img src={commissionImg} alt="commission_img" className={styles.image} />
          </div>
          <Typography.Text italic className={styles.exampletext}>
            {t('commission.example_title')}
          </Typography.Text>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('observe.title')} key="observe" className={styles.tabpanel}>
          <Typography.Title level={2} className={styles.title}>
            {t('observe.title')}
          </Typography.Title>
          <Typography.Paragraph>{t('observe.description')}</Typography.Paragraph>
          <div className={styles.imagecontainer}>
            <img src={observeImg} alt="observe_img" className={styles.image} />
          </div>
          <Typography.Text italic className={styles.exampletext}>
            {t('observe.example_title')}
          </Typography.Text>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('decommission.title')} key="decommission" className={styles.tabpanel}>
          <Typography.Title level={2} className={styles.title}>
            {t('decommission.title')}
          </Typography.Title>
          <Typography.Paragraph>{t('decommission.description')}</Typography.Paragraph>
          <Typography.Text italic className={styles.exampletext}>
            {t('decommission.example_title')}
          </Typography.Text>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('ship.title')} key="ship" className={styles.tabpanel}>
          <Typography.Title level={2} className={styles.title}>
            {t('ship.title')}
          </Typography.Title>
          <Typography.Paragraph>{t('ship.description_line1')}</Typography.Paragraph>
          <Typography.Paragraph>{t('ship.description_line2')}</Typography.Paragraph>
          <img src={sendReceiveImg} alt="sendReceiveImg" className={styles.imagefull} />
          <Typography.Text italic className={styles.exampletext}>
            {t('receive.example_title')}
          </Typography.Text>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('receive.title')} key="receive" className={styles.tabpanel}>
          <Typography.Title level={2} className={styles.title}>
            {t('receive.title')}
          </Typography.Title>
          <Typography.Paragraph>{t('receive.description')}</Typography.Paragraph>
          <img src={sendReceiveImg} alt="receive_img" className={styles.imagefull} />
          <Typography.Text italic className={styles.exampletext}>
            {t('receive.example_title')}
          </Typography.Text>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('aggregate.title')} key="aggregation" className={styles.tabpanel}>
          <Typography.Title level={2} className={styles.title}>
            {t('aggregate.title')}
          </Typography.Title>
          <Typography.Paragraph>{t('aggregate.description')}</Typography.Paragraph>
          <img src={aggregationImg} alt="receive_img" className={styles.imagefull} />
          <Typography.Text italic className={styles.exampletext}>
            {t('aggregate.example_title')}
          </Typography.Text>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('disaggregate.title')} key="disaggregate" className={styles.tabpanel}>
          <Typography.Title level={2} className={styles.title}>
            {t('disaggregate.title')}
          </Typography.Title>
          <Typography.Paragraph>{t('disaggregate.description')}</Typography.Paragraph>
          <img src={disaggregateImg} alt="disaggregateImg" className={styles.imagefull} />
          <Typography.Text italic className={styles.exampletext}>
            {t('disaggregate.example_title')}
          </Typography.Text>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('transform.title')} key="transform" className={styles.tabpanel}>
          <Typography.Title level={2} className={styles.title}>
            {t('transform.title')}
          </Typography.Title>
          <Typography.Paragraph>{t('transform.description_line1')}</Typography.Paragraph>
          <Typography.Paragraph>{t('transform.description_line2')}</Typography.Paragraph>
          <img src={oneToOneImg} alt="oneToOneImg" className={styles.imagefull} />
          <Typography.Text italic className={styles.exampletext}>
            {t('transform.example1_title')}
          </Typography.Text>
          <img src={oneToManyImg} alt="oneToManyImg" className={styles.imagefull} />
          <Typography.Text italic className={styles.exampletext}>
            {t('transform.example2_title')}
          </Typography.Text>
          <img src={manyToOneImg} alt="manyToOneImg" className={styles.imagefull} />
          <Typography.Text italic className={styles.exampletext}>
            {t('transform.example3_title')}
          </Typography.Text>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};
export default EventTypesModal;

import { Segmented } from 'antd';
import { DataEntryMethodType } from 'pages/Events/hooks/typings';
import { FC } from 'react';
import { getProductsT } from 'utils';
import useShipmentStore from '../hooks/useShipmentStore';
import { DataEntryMethodProps } from './typings';

const DataEntryMethod: FC<DataEntryMethodProps> = ({ disabled }) => {
  const dataEntryMethod = useShipmentStore((state) => state.dataEntryMethod);
  const setDataEntryMethod = useShipmentStore((state) => state.setDataEntryMethod);

  return (
    <Segmented
      disabled={disabled}
      value={dataEntryMethod}
      options={[
        { label: getProductsT('manual'), value: 'manual' },
        { label: getProductsT('csv_upload'), value: 'csvUpload' },
      ]}
      onChange={(e) => setDataEntryMethod(e as DataEntryMethodType)}
    />
  );
};

export default DataEntryMethod;

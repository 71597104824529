import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { ListBizStepsOptionalParams } from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';
import { getProductsT } from 'utils';

const getBizSteps = (params?: ListBizStepsOptionalParams) => HttpClient.listBizSteps(params);

const useBizSteps = (params?: ListBizStepsOptionalParams) =>
  useQuery(['bizSteps', params], () => getBizSteps(params), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useBizStepOptions = (params?: ListBizStepsOptionalParams) => {
  const { data: dataBizStep, isLoading } = useBizSteps(params);
  const data: Array<DefaultOptionType> = useMemo(
    () =>
      dataBizStep?.results?.map((item) => {
        const urns = item?.urn?.split(':');
        const step = urns?.[(urns?.length || 0) - 1]?.replace('/', '_');
        const isCBV = urns?.[2] === 'cbv';
        const type = isCBV ? 'cbv' : 'gdst';
        const label = `${getProductsT(step)} (${getProductsT(type)})`;

        return {
          label,
          value: String(item.urn),
          itemProps: item,
        };
      }) || [],
    [dataBizStep],
  );

  return {
    data,
    isLoading,
  };
};

export default useBizSteps;

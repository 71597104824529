import ContactUsModal from 'components/Settings/SubscriptionBilling/UpgradePlanModal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { supportEmailLink } from 'utils';
import { ContactUsProps } from './typings';

const ContactUs: FC<ContactUsProps> = ({ contactUsModal }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.contact_us_modal' });
  return (
    <ContactUsModal
      modal={contactUsModal}
      title={t('title')}
      description={
        <div>
          {t('description')}{' '}
          <a href={supportEmailLink} type="mail">
            {t('mail')}
          </a>
        </div>
      }
    />
  );
};
export default ContactUs;

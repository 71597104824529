import { Button, Modal } from 'antd';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useAttributeStore from '../../hooks/useAttributeStore';

const AdvanceSettingToggle: FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes' });

  const { toggleAdvancedSettings, hasAdvancedSettings } = useAttributeStore();

  const onToggleSetting = useCallback(() => {
    Modal.warning({
      title: t('enable_advanced_setting_modal.title'),
      content: (
        <p>
          {t('enable_advanced_setting_modal.content.first')}
          <br />
          <br />
          {t('enable_advanced_setting_modal.content.second')}
          <li>• {t('enable_advanced_setting_modal.content.standard')}</li>
          <li>• {t('enable_advanced_setting_modal.content.namespace')}</li>
          <li>• {t('enable_advanced_setting_modal.content.data')} </li>
        </p>
      ),
      centered: true,
      okCancel: true,
      width: 500,
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
        shape: 'round',
      },
      okText: t('enable_advanced_setting_modal.ok_text'),
      cancelText: t('enable_advanced_setting_modal.cancel_text'),
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      onOk: toggleAdvancedSettings,
    });
  }, [toggleAdvancedSettings, t]);
  return (
    <Button
      type="link"
      size="middle"
      onClick={hasAdvancedSettings ? toggleAdvancedSettings : onToggleSetting}
    >
      {hasAdvancedSettings
        ? t('defined_attributes.disable_advanced')
        : t('defined_attributes.enable_advanced')}
    </Button>
  );
};
export default React.memo(AdvanceSettingToggle);

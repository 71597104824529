import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Modal } from 'antd';
import { FormInstance } from 'antd/lib/form/Form';
import { ActionButtons, GTable, ProductSelect } from 'components';
import { MultiReceiveProductItem } from 'pages/Events/components/Forms/AllReceiveForm.fields';
import { ReceiveProductsDataType } from 'pages/Events/components/Forms/TransformTables/AllReceiveProducts';
import { Shipment } from 'pages/Shipments/typings';
import pluralize from 'pluralize';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerProductInstanceResponse, ProductResponse } from 'services/api/client/src';
import { getProductsT } from 'utils';
import useWatchValues from './hooks/useWatchValues';
import { ColumnType, EditableEventTableProps } from './typings';

const Columns = ({
  onEditRow,
  isEdit,
  quantityChangeModal,
  lotChangeModal,
}: ColumnType): ProColumns<ReceiveProductsDataType>[] => [
  {
    dataIndex: 'id',
    hideInTable: true,
  },
  {
    title: 'Product',
    dataIndex: 'parentProductId',
    valueType: 'select',
    hideInForm: true,
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { record, recordKey }, form) => {
      const isInternal = record?.shipmentType === 'Internal';
      const { setFieldsValue } = form;
      return (
        <ProductSelect
          allowLotSerial={record?.productIdentifierType === undefined}
          isSerial={record?.productIdentifierType === 'Serial'}
          size="small"
          disabled={isInternal}
          filterItem={(item) => item?.id !== record?.originalInstance?.product?.id}
          isAddProduct
          prependOption={{
            label: `${record?.originalInstance?.product?.name || ''}`,
            value: record?.originalInstance?.product?.id,
            itemProps: {
              ...record?.originalInstance?.product,
            },
          }}
          onChange={(value, option) => {
            // @ts-ignore
            const item: ProductResponse = option.itemProps;
            setFieldsValue({
              [String(recordKey)]: {
                parentProductId: item?.id,
                productName: item?.name || '',
                isAssignedProduct: true,
                // resets values of row items being edited
                primaryId: undefined,
                quantity: undefined,
                currentInventory: item?.currentInventory,
                currentInventoryTotal: item?.currentInventoryTotal,
                instanceQuantity: item?.currentInventoryTotal,
                unitOfMeasure: item?.simpleUnitOfMeasurement || '',
                productIdentifierType: item?.productIdentifierType,
              },
            });
          }}
        />
      );
    },
    render: (_t, record) => record?.productName,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: (form, { rowKey, entity }) => {
      const unitOfMeasure =
        entity?.unitOfMeasure || form?.getFieldValue?.(String(rowKey))?.unitOfMeasure || 'LBS';
      const productIdentifierType =
        entity?.productIdentifierType ||
        form?.getFieldValue?.(String(rowKey))?.productIdentifierType ||
        'Lot';
      return {
        addonAfter:
          productIdentifierType === 'Serial' ? 'Items' : `${unitOfMeasure}`?.toLocaleUpperCase(),
        size: 'small',
        stringMode: true,
        type: 'number',
        style: {
          width: '100%',
        },
        onBlur: (e: any) => quantityChangeModal?.(e.target.value, String(rowKey), form),
        disabled: productIdentifierType === 'Serial',
        placeholder: '0',
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    render: (text, record) =>
      record?.productIdentifierType === 'Serial'
        ? `${record?.quantity} ${pluralize('item', record?.quantity)}`
        : `${record?.quantity} ${record?.unitOfMeasure?.toLocaleUpperCase() || ''}`,
  },
  {
    title: 'Primary ID',
    dataIndex: 'primaryId',
    valueType: 'text',
    fieldProps: (form, { rowKey, entity }) => {
      const productIdentifierType = form?.getFieldValue?.(String(rowKey))?.productIdentifierType;
      return {
        addonBefore: `${entity?.productIdentifierType || productIdentifierType || 'Lot'}:`,
        size: 'small',
        onBlur: (e: any) => lotChangeModal?.(e.target.value, String(rowKey), form),
        placeholder: entity?.productIdentifierType || productIdentifierType,
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    render: (_t, record) => `${record?.productIdentifierType}: ${record?.primaryId}`,
  },
  {
    dataIndex: 'actions',
    hideInTable: !isEdit,
    valueType: 'option',
    render: (_text, record) => (
      <ActionButtons
        record={record}
        onEdit={onEditRow}
        showDelete={false}
        showEdit={!record?.isSSCC}
      />
    ),
  },
];
const EditableEventTable: FC<EditableEventTableProps> = ({ form, shipmentId }) => {
  const sactionRef = useRef<ActionType>();
  const { getFieldValue } = form;
  const { t } = useTranslation('pages', { keyPrefix: 'events.recieve.form_fields' });
  // Receive Products table data
  const originalProducts: MultiReceiveProductItem[] = getFieldValue('originalMultiReceiveObjects');
  const modifiedProducts: ReceiveProductsDataType[] = useMemo(
    () =>
      originalProducts?.find((item) => item?.shipmentId === shipmentId, [])?.inboundShipments || [],
    [originalProducts, shipmentId],
  );
  const { multiReceiveObjects } = useWatchValues(form) || [];
  const receiveProducts: Array<ReceiveProductsDataType> = useMemo(
    () =>
      multiReceiveObjects?.find((item) => item?.shipmentId === shipmentId, [])?.inboundShipments ||
      [],
    [multiReceiveObjects, shipmentId],
  );
  const SetReceiveProducts = useCallback(
    (value: ReceiveProductsDataType[]) => {
      form?.setFieldsValue({
        multiReceiveObjects: value,
      });
    },
    [form],
  );
  const onEditRow = (actionPayload?: Shipment) => {
    sactionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onCancelProduct = async (_: RecordKey, data: ReceiveProductsDataType) => {
    sactionRef.current?.cancelEditable(data?.id || 0);
  };

  // quantity change modal
  const quantityChangeModal = (value: string, rowKey: string, tblForm: FormInstance<any>) => {
    const product = modifiedProducts?.find((item) => item?.id === rowKey);
    let ssccChildren: ReceiveProductsDataType = {};
    for (let i = 0; i <= (modifiedProducts?.length || 1) - 1; i += 1) {
      ssccChildren = modifiedProducts?.[i]?.children?.find((item: any) => item?.id === rowKey);
    }
    const quantity = product?.quantity || 0;
    const ssccChildQuantity = ssccChildren?.quantity || '';
    const isQuantityChangeModalTriggered = tblForm?.getFieldValue(
      String(rowKey),
    )?.isQuantityChangeModalTriggered;

    if (
      (product ? Number(value) !== quantity : value !== ssccChildQuantity) &&
      value &&
      !isQuantityChangeModalTriggered
    ) {
      Modal.info({
        title:
          'Changing the Quantity will create an observe event for this item in the selected product',
        content: `Quantity “${
          quantity || ssccChildQuantity
        }” will be changed to "${value}". This will log an observe event after you log the receive.`,
        cancelText: 'cancel',
        okText: 'Continue',
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        onCancel: () => {
          tblForm?.setFieldsValue({
            [String(rowKey)]: {
              quantity: quantity || 0,
              isQuantityChangeModalTriggered: true,
            },
          });
        },
        onOk: () => {
          tblForm?.setFieldsValue({
            [String(rowKey)]: {
              isQuantityChangeModalTriggered: true,
            },
          });
        },
        okCancel: true,
      });
    }
  };
  // lot change modal
  const lotChangeModal = (value: string, rowKey: string, tblForm: FormInstance<any>) => {
    const product = modifiedProducts?.find((item) => item?.instanceId === rowKey);
    let ssccChildren: ReceiveProductsDataType = {};
    for (let i = 0; i <= (modifiedProducts?.length || 1) - 1; i += 1) {
      ssccChildren = modifiedProducts?.[i]?.children?.find((item: any) => item?.id === rowKey);
    }
    const isSerial = product?.productIdentifierType === 'Serial';
    const lotSerial = product?.primaryId || '';
    const ssccChildLotSerial = ssccChildren?.primaryId || '';
    if ((product ? value !== lotSerial : value !== ssccChildLotSerial) && value) {
      Modal.warning({
        title: !isSerial
          ? t?.('lot_change_modal_title', {
              identifier: getProductsT(product?.productIdentifierType),
            })
          : `Changing the Serial ID will Transform this item in the selected product`,
        content: `${isSerial ? 'Serial ID' : 'Lot ID'} “${
          lotSerial || ssccChildLotSerial
        }” will be changed to “${value}”. This is the ${
          isSerial ? 'Serial ID' : 'Lot ID'
        } that will appear in your inventory.`,
        cancelText: t('lot_change_cancel_btn'),
        okText: t('lot_change_continue_btn'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        onCancel: () => {
          tblForm?.setFieldsValue({
            [String(rowKey)]: { primaryId: lotSerial || ssccChildLotSerial || '' },
          });
        },
        okCancel: true,
      });
    }
  };

  // find total quantity in all lots of sscc
  const findTotalSSCCQuantity = useCallback((p: ContainerProductInstanceResponse[]) => {
    let total = 0;
    for (let i = 0; i <= Number(p?.length) - 1 || 0; i += 1) {
      total = total + Number(p?.[i]?.quantity) || 0;
    }
    return total;
  }, []);

  const onSaveRow = async (_rowKey: RecordKey, data: ReceiveProductsDataType) => {
    sactionRef.current?.cancelEditable(data?.id || 0);
    const existingProduct = receiveProducts.find((product) => product.id === data?.id);
    const existingSsccParent = receiveProducts.find(
      (product) => product.id === data?.parentContainerId,
    );

    if (existingProduct) {
      existingProduct.productName = data?.productName || '';
      existingProduct.productId = data?.productId || '';
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.parentProductId = data?.parentProductId?.trim() || undefined;
      existingProduct.quantity = data?.quantity;
      existingProduct.currentInventory = data?.currentInventory;
      existingProduct.instanceQuantity = data?.quantity;
      existingProduct.unitOfMeasure = data?.unitOfMeasure || '';

      const modifiedObj = multiReceiveObjects?.map((ship) => {
        const obj = ship?.inboundShipments?.map((row) => {
          if (row?.id === existingProduct.id) {
            return existingProduct;
          }
          if (row?.productId === data?.productId) {
            return {
              ...row,
              parentProductId: data?.parentProductId,
              productName: data?.productName,
            };
          }
          return row;
        }, []);

        return {
          ...ship,
          inboundShipments: obj,
        };
      }, []);

      // update product
      SetReceiveProducts(modifiedObj);
    } else if (existingSsccParent) {
      const changedChildren = existingSsccParent?.children?.find((c: any) => c?.id === data?.id);
      const updatedChildren = {
        ...changedChildren,
        parentContainerId: existingSsccParent?.id,
        parentProductId: data?.parentProductId,
        productName: data?.productName,
        primaryId: data?.primaryId || '',
        quantity: Number(data?.quantity || 0),
        instanceQuantity: Number(data?.quantity || 0),
      };

      const modifiedObj = multiReceiveObjects?.map((ship) => {
        const obj = ship?.inboundShipments?.map((row) => {
          if (row?.id === existingSsccParent.id) {
            const children = existingSsccParent?.children?.map((child: any) => {
              if (child?.id !== changedChildren?.id) {
                if (child?.productId === changedChildren?.productId) {
                  return {
                    ...child,
                    parentProductId: data?.parentProductId,
                    productName: data?.productName,
                  };
                }
                return child;
              }
              return updatedChildren;
            }, []);
            return {
              ...existingSsccParent,
              quantity: findTotalSSCCQuantity(children || []),
              productName: children?.find(
                (i: any) => i?.parentProductId !== children?.[0]?.parentProductId,
                [],
              )
                ? 'SSCC: Multiple Products'
                : `SSCC: ${children?.[0]?.productName}`,
              children,
            };
          }
          const children = row?.children?.map((child: any) => {
            if (child?.productId === changedChildren?.productId) {
              return {
                ...child,
                parentProductId: data?.parentProductId,
                productName: data?.productName,
              };
            }
            return child;
          }, []);
          return {
            ...row,
            productName: children?.find(
              (i: any) => i?.parentProductId !== children?.[0]?.parentProductId,
              [],
            )
              ? 'SSCC: Multiple Products'
              : `SSCC: ${children?.[0]?.productName}`,
            children,
          };
        }, []);

        return {
          ...ship,
          inboundShipments: obj,
        };
      }, []);

      SetReceiveProducts(modifiedObj);
    }
  };

  return (
    <GTable<ReceiveProductsDataType>
      actionRef={sactionRef}
      key="receiveProductsTable"
      rowKey="id"
      columns={Columns({ onEditRow, isEdit: true, quantityChangeModal, lotChangeModal })}
      options={{
        setting: false,
        reload: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      editable={{
        onSave: (rowKey, actionPayload) => onSaveRow(rowKey, actionPayload),
        onCancel: async (rowKey, oldData, data) => onCancelProduct(rowKey, data),
      }}
      value={receiveProducts}
      showHeader
    />
  );
};
export default EditableEventTable;

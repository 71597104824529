import ProList, { ProListMetas } from '@ant-design/pro-list';
import { Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Actions from 'components/Actions';
import { FC } from 'react';
import useAccount from 'services/api/useAccount';
import styles from './ShareProduct.module.less';
import { AccessListProps, TradePartnerItem } from './typings';

const ActionItems = (): Array<ItemType> => [
  {
    type: 'group',
    key: 'access',
    label: 'Access',
    children: [
      {
        key: 'viewer',
        label: 'Viewer',
      },
      {
        key: 'editor',
        label: 'Editor',
      },
    ],
  },
  {
    type: 'group',
    key: 'manage',
    label: 'Manage',
    children: [
      {
        key: 'remove',
        label: 'Remove',
      },
    ],
  },
];
const AccessList: FC<AccessListProps> = () => {
  const { data } = useAccount();
  // remaining trade partners should be from api
  const tradePartners: Array<TradePartnerItem> = [
    {
      id: data?.id,
      name: data?.name || '',
      isOwner: true,
    },
    {
      id: '1',
      name: 'Beaver Street Fisheries',
      permission: 'Viewer',
    },
    {
      id: '2',
      name: 'A Seafood Company',
      permission: 'Editor',
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRowActionClick = (key: string, payload?: TradePartnerItem) => {
    switch (key) {
      case 'remove':
        break;
      case 'viewer':
        break;
      case 'editor':
        break;

      default:
        break;
    }
  };
  const listMetas: ProListMetas<TradePartnerItem> = {
    title: {
      dataIndex: 'name',
      render: (text) => (
        <Typography.Text ellipsis className={styles.user}>
          {text}
        </Typography.Text>
      ),
    },
    actions: {
      render: (text, record) =>
        !record?.isOwner ? (
          <Actions
            buttonProps={{
              ghost: true,
              size: 'small',
            }}
            items={ActionItems()}
            onClick={onRowActionClick}
            actionsText={record?.permission}
          />
        ) : (
          <span>Owner</span>
        ),
    },
  };
  return (
    <ProList
      itemLayout="horizontal"
      rowKey="id"
      split
      dataSource={tradePartners}
      // cardProps={{ bodyStyle: { padding: 0, margin: 0 } }}
      metas={listMetas}
      ghost
    />
  );
};
export default AccessList;

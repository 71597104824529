import { Badge, Space } from 'antd';
import { FC } from 'react';
import { TabTitleProps } from './typings';

const TabTitle: FC<TabTitleProps> = ({ title, count, showZero }) => (
  <Space>
    {title}
    <Badge count={count} showZero={showZero} />
  </Space>
);
export default TabTitle;

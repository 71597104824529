import { getAccountType } from 'utils/network';
import { create } from 'zustand';
import { EventsStore } from './typings';

const useCompanySelectStore = create<EventsStore>((set) => ({
  selectedCompany: undefined,
  setSelectedCompany: (selectedCompany) =>
    set({
      selectedCompany,
      userType: getAccountType(
        selectedCompany?.isOwnerAccount,
        selectedCompany?.hasWholechainAccount,
      ),
    }),
  isNewCompany: false,
  setNewCompanyFlag: (isNewCompany) => set({ isNewCompany }),
  isCompanyEmpty: true,
  userType: '',
  setCompanyEmpty: () =>
    set((state) => ({
      isCompanyEmpty: Object.keys(state.selectedCompany || {}).length === 0,
    })),
  multipleLocationsFlag: false,
  setMultipleLocations: (multipleLocationsFlag) => set({ multipleLocationsFlag }),
}));

export default useCompanySelectStore;

import { FormInstance } from '@ant-design/pro-form';
import { message, Modal } from 'antd';
import { useModalVisibility } from 'hooks';
import { useGetLocationMutate } from 'hooks/useListLocation/useListLocation';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  CreateLocationOptionalParams,
  CreateTradePartnerOptionalParams,
  GetInboundApiKeyResponse,
  LocationResponse,
  SetExternalRegistryRequest,
  TradePartnerResponse,
  UpdateExternalRegistryRequest,
  UpdateLocationRequest,
} from 'services/api/client/src';
import {
  useArchiveAllVesselLocations,
  useCreateLocation,
  useUpdateLocationById,
} from 'services/api/useLocations';
import {
  useAddTraceabilityDetails,
  useAllCompany,
  useCreateTradePartner,
  useGenerateAPIKey,
  useHasActiveInventoryCheck,
  useListInvitesMutate,
  useRevokeNetworkInvitation,
  useUpdateTraceabilityDetails,
  useUpdateTradePartnerById,
} from 'services/api/useTradePartners';
import { supportEmailLink } from 'utils';
import errorHandler from 'utils/errorHandler';
import { getPhoneNumber } from 'utils/helpers';
import { getConnectionTypeString } from 'utils/network';
import useAddressStore from '../components/AddressSelectInput/useAddressStore';
import { DataItem } from '../forms/AddEdit/CompanyLocation.fields';
import useDataEntryMethodStore from '../forms/AddEdit/DataEntryMethod/useDataEntryStore';
import { NetworkItem } from '../typings';
import useDrawerVisibility from './useLocationDrawerVisibility';
import useSubscriptionAction from './useSubscriptionActions';

const useAddEditActions = () => {
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.form_fields' });
  const queryClient = useQueryClient();
  const formRef = useRef<FormInstance<any>>();
  const addressComponents = useAddressStore();
  const [addEditLocationData, setAddEditLocationData] = useState<NetworkItem>();
  const [addEditVesselLocationData, setAddEditVesselLocationData] = useState<NetworkItem>();
  const updateLocation = useUpdateLocationById(queryClient);
  const updateCompany = useUpdateTradePartnerById(queryClient);
  const createLocation = useCreateLocation(queryClient);
  const createTradePartner = useCreateTradePartner(queryClient);
  const fetchAllCompanies = useAllCompany();
  const { getLocationAPICall } = useGetLocationMutate();
  const setDataEntryMethod = useDataEntryMethodStore((state) => state.setDataEntryMethod);
  const updateTraceability = useUpdateTraceabilityDetails(queryClient);
  const addTraceability = useAddTraceabilityDetails(queryClient);
  const [traceSwitch, setTraceSwitch] = useState<boolean>(false);
  const [csvUploading, setCSVUploading] = useState<boolean>(false);
  const revokeConnection = useRevokeNetworkInvitation(queryClient);
  const useGenerateAPIKeyAPI = useGenerateAPIKey();
  const upgradeTraceModal = useModalVisibility(false);
  const { allowInternalLocation, allowExternalLocation, allowTraceibility } =
    useSubscriptionAction();
  const checkActiveInventory = useHasActiveInventoryCheck();
  const archiveAllVesselLocs = useArchiveAllVesselLocations(queryClient);

  const inviteData = useListInvitesMutate();
  const setAttributes = useCallback(
    (value: DataItem) => formRef.current?.setFieldsValue(value),
    [formRef],
  );

  // const { cancelInvite } = useDeleteActions();

  const savePhoneNumber = (formData: DataItem) => {
    if (!formData.phoneNumber) {
      const number = formRef?.current?.getFieldValue('number');
      const phoneCode = formRef?.current?.getFieldValue('phoneCode');
      if (number && phoneCode) {
        return `${phoneCode},${number}`;
      }
    }
    return formData.phoneNumber;
  };

  const saveVesselPhoneNumber = (formData: DataItem) => {
    if (!formData.vPhoneNumber) {
      const number = formRef?.current?.getFieldValue('vNumber');
      const phoneCode = formRef?.current?.getFieldValue('vPhoneCode');
      if (number && phoneCode) {
        return `${phoneCode},${number}`;
      }
    }
    return formData.vPhoneNumber;
  };

  const isVesselLocation = useCallback((locations: Array<LocationResponse>) => {
    const vesselLocations = locations.filter((loc) => {
      if (
        loc?.vessel?.vesselName ||
        loc?.vessel?.vesselPublicRegistryLink ||
        loc?.vessel?.vesselSatelliteTrackingAuthority ||
        loc?.vessel?.imoNumber ||
        loc?.vessel?.vesselFlagState ||
        loc?.vessel?.vesselRegistration
      ) {
        return true;
      }
      return false;
    });

    if (vesselLocations.length !== 0) {
      return true;
    }

    return false;
  }, []);

  const getVesselLocations = useCallback((locations: Array<LocationResponse>) => {
    const vesselLocations = locations.filter((loc) => loc?.vessel !== null);

    return vesselLocations;
  }, []);

  const getNonVesselLocations = useCallback((locations: Array<LocationResponse>) => {
    const vesselLocations = locations.filter((loc) => loc?.vessel === null);

    return vesselLocations;
  }, []);

  const closeModal = () => {
    formRef?.current?.resetFields();
    addressComponents.setAddressData({});
    setDataEntryMethod('manual');
    queryClient.resetQueries('attributesList');
    closeDrawer();
  };

  const updateVesselLocation = async (locId: string, formData: DataItem) => {
    const latlng = formData?.vGeoCoordinates?.split(',');
    const reqData: UpdateLocationRequest = {
      // id: String(locId),
      gln: formData.vGln,
      name: formData.vLocationName,
      address: {
        addressLine1: formData?.vAddress?.line1 || '',
        addressLine2: formData?.vAddress?.line2 || '',
        city: formData?.vAddress?.city || '',
        state: formData?.vAddress?.state || '',
        country: formData?.vAddress?.country || '',
        postalCode: formData?.vAddress?.postalCode || '',
        geoCoordinates: {
          latitude: Number(latlng ? latlng[0] : 1),
          longitude: Number(latlng ? latlng[1] : 1),
        },
      },
      contact: {
        phone: saveVesselPhoneNumber(formData),
        email: formData.vEmail,
      },
      vessel: {
        vesselName: formData.vVesselName || '',
        vesselRegistration: formData.vVesselReg || '',
        vesselPublicRegistryLink: formData.vVesselLink || '',
        vesselSatelliteTrackingAuthority: formData.vVesselAuth || '',
        imoNumber: formData.vImoNumber || '',
        vesselFlagState: formData.vVesselState || '',
      },
    };
    const res = await updateLocation.mutateAsync({
      id: String(locId) || '',
      options: reqData,
    });
    return res;
  };

  const createNewVesselLocation = async (
    formData: DataItem,
    selectedCompany?: TradePartnerResponse,
  ) => {
    const latlng = formData?.vGeoCoordinates?.split(',');
    if (formData.vLocationName && latlng) {
      const reqData: CreateLocationOptionalParams = {
        body: {
          gln: formData.vGln,
          name: formData.vLocationName,
          tradePartnerId: selectedCompany?.id || '',
          address: {
            addressLine1: formData?.vAddress?.line1 || '',
            addressLine2: formData?.vAddress?.line2 || '',
            city: formData?.vAddress?.city || '',
            state: formData?.vAddress?.state || '',
            country: formData?.vAddress?.country || '',
            postalCode: formData?.vAddress?.postalCode || '',
            geoCoordinates: {
              latitude: Number(latlng ? latlng[0] : 1),
              longitude: Number(latlng ? latlng[1] : 1),
            },
          },

          contact: {
            phone: saveVesselPhoneNumber(formData),
            email: formData.vEmail,
          },
          vessel: {
            vesselName: formData.vVesselName || '',
            vesselRegistration: formData.vVesselReg || '',
            vesselPublicRegistryLink: formData.vVesselLink || '',
            vesselSatelliteTrackingAuthority: formData.vVesselAuth || '',
            imoNumber: formData.vImoNumber || '',
            vesselFlagState: formData.vVesselState || '',
          },
        },
      };

      const res = await createLocation.mutateAsync(reqData);
      return res;
    }
    return {};
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateLocationData = async (locId: string, formData: DataItem, isVessel: boolean) => {
    const latlng = formData?.geoCoordinates?.split(',');
    const reqData: UpdateLocationRequest = {
      gln: formData.gln,
      name: formData.locationName,
      address: {
        addressLine1: formData.address?.line1 || '',
        addressLine2: formData.address?.line2 || '',
        city: formData.address?.city || '',
        state: formData.address?.state || '',
        country: formData.address?.country || '',
        postalCode: formData.address?.postalCode || '',
        geoCoordinates: {
          latitude: Number(latlng ? latlng[0] : 1),
          longitude: Number(latlng ? latlng[1] : 1),
        },
      },
      contact: {
        phone: savePhoneNumber(formData),
        email: formData.email,
      },
      vessel: isVessel
        ? {
            vesselName: formData.vesselName || '',
            vesselRegistration: formData.vesselReg || '',
            vesselPublicRegistryLink: formData.vesselLink || '',
            vesselSatelliteTrackingAuthority: formData.vesselAuth || '',
            imoNumber: formData.imoNumber || '',
            vesselFlagState: formData.vesselState || '',
          }
        : undefined,
    };
    const res = await updateLocation.mutateAsync({
      id: String(locId) || '',
      options: reqData,
    });
    return res;
  };

  const hasInventoryPopup = useCallback(
    (locations: string) => {
      Modal.warning({
        title: t('vessel_inventory.title'),
        content: t('vessel_inventory.content', { locations }),
        centered: true,
        okCancel: false,
        width: 500,
        okText: t('vessel_inventory.done'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onOk: () => {
          setAttributes({ isVessel: 'Yes' });
        },
      });
    },
    [setAttributes, t],
  );

  const archiveWarningPopup = async () =>
    new Promise((resolve) => {
      Modal.warning({
        title: t('archive_warning.title'),
        content: t('archive_warning.content'),
        centered: true,
        okCancel: true,
        width: 500,
        okText: t('archive_warning.continue'),
        cancelText: t('archive_warning.cancel'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        onCancel: () => resolve(false),
        onOk: () => resolve(true),
      });
    });

  const onNoVessel = async (
    vesselLocationList: Array<LocationResponse>,
    selectedCompany?: TradePartnerResponse,
  ) => {
    const res = await checkActiveInventory.mutateAsync({
      params: { isVessel: true, tradePartnerId: selectedCompany?.id || '' },
    });
    if (res?.length) {
      const vessels = res?.map((item) => `"${item?.name}"`).join(',');
      hasInventoryPopup(vessels);
    } else if (vesselLocationList?.length) {
      const consent = await archiveWarningPopup();
      if (consent) {
        await archiveAllVesselLocs?.mutateAsync({
          body: { archived: true, tradePartnerIds: [selectedCompany?.id || ''] },
        });
        setAttributes({ multiVesselLoc: [] });
      } else {
        setAttributes({ isVessel: 'Yes' });
      }
    }
  };

  const onNoMultiLoc = (form: FormInstance) => {
    Modal.warning({
      title: t('location_warning.title'),
      content: <p>{t('location_warning.content')}</p>,
      centered: true,
      okCancel: false,
      width: 500,
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
        shape: 'round',
      },
      okText: t('location_warning.done'),
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      onOk: () => {
        form.getFieldValue('isMultipleLocations');
      },
    });
  };

  const onRemoveConnectionPopup = (companyName: string) =>
    new Promise((resolve) => {
      Modal.warning({
        title: t('remove_connection_warn.title', { company: companyName }),
        content: <p>{t('remove_connection_warn.description')}</p>,
        centered: true,
        okCancel: true,
        width: 500,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okText: t('remove_connection_warn.remove'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onCancel: () => resolve(false),
        onOk: () => resolve(true),
      });
    });

  const deleteInviteConsent = (companyName: string) =>
    new Promise((resolve) => {
      Modal.warning({
        title: t('delete_invite_warn.title', { company: companyName }),
        content: <p>{t('delete_invite_warn.description', { company: companyName })}</p>,
        centered: true,
        okCancel: true,
        width: 500,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okText: t('cancel_invite'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onCancel: () => resolve(false),
        onOk: () => resolve(true),
      });
    });

  const onRemoveConnection = async (selectedCompany?: TradePartnerResponse) => {
    try {
      const consent = await onRemoveConnectionPopup(selectedCompany?.name || '');
      if (consent) {
        const invite = await inviteData.mutateAsync({
          params: {
            accountId: selectedCompany?.wholechainId || '',
          },
        });
        const response = await revokeConnection.mutateAsync({
          id: invite?.results?.[0]?.id || '',
        });
        if (response?.id) {
          message.success(t('remove_success', { company: selectedCompany?.name }));
        }
      }
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  // const onCancelConnection = async (selectedCompany: TradePartner) => {
  //   try {
  //     const invite = await getNetworks({
  //       tradePartnerIds: [selectedCompany?.id || ''],
  //       networkStatus: ['Pending'],
  //       incoming: true,
  //     });

  //     cancelInvite(invite?.results?.[0]?.invite?.id || '');
  //   } catch (error) {
  //     message.error(errorHandler(error));
  //   }
  // };

  const onRestoreConnection = (form: FormInstance) =>
    new Promise((resolve) => {
      Modal.warning({
        title: t('restore_connection_warn.title', { company: form.getFieldValue('companyName') }),
        content: '',
        centered: true,
        okCancel: true,
        width: 500,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okText: t('restore_connection_warn.restore'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onCancel: () => resolve(false),
        onOk: () => resolve(true),
      });
    });

  const setLocationFieldValues = (locationArray: any, selectedCompany?: TradePartnerResponse) => {
    if (locationArray && locationArray.length !== 0) {
      const loc = locationArray[0];
      setAddEditLocationData({
        id: loc?.id || '',
        company: selectedCompany?.name || '',
        companyId: selectedCompany?.id,
      });
      const numberObj = getPhoneNumber(loc?.phoneNumber);
      const data =
        {
          gln: loc?.gln,
          locationName: loc?.locationName || '',
          address: loc?.address,
          geoCoordinates: `${loc?.geoCoordinates}`,
          phoneNumber: loc?.phoneNumber || '',
          number: numberObj?.tenDigits,
          phoneCode: numberObj?.iso,
          phonePrefix: numberObj?.code,
          email: loc?.email,
          vesselName: loc?.vesselName,
          vesselLink: loc?.vesselLink,
          vesselAuth: loc?.vesselAuth,
          imoNumber: loc?.imoNumber,
          vesselState: loc?.vesselState,
          vesselReg: loc?.vesselReg,
        } || {};
      setAttributes(data);
    }
  };

  const setVesselLocationFieldValues = (
    locationArray: any,
    selectedCompany?: TradePartnerResponse,
  ) => {
    if (locationArray && locationArray.length !== 0) {
      const loc = locationArray[0];
      setAddEditVesselLocationData({
        id: loc?.id || '',
        company: selectedCompany?.name || '',
        companyId: selectedCompany?.id,
      });
      const numberObj = getPhoneNumber(loc?.phoneNumber);
      const data =
        {
          vGln: loc?.gln,
          vLocationName: loc?.locationName || '',
          vAddress: loc?.address,
          vGeoCoordinates: `${loc?.geoCoordinates}`,
          vPhoneNumber: loc?.phoneNumber || '',
          vNumber: numberObj?.tenDigits,
          vPhoneCode: numberObj?.iso,
          vPhonePrefix: numberObj?.code,
          vEmail: loc?.email,
          vVesselName: loc?.vesselName,
          vVesselLink: loc?.vesselLink,
          vVesselAuth: loc?.vesselAuth,
          vImoNumber: loc?.imoNumber,
          vVesselState: loc?.vesselState,
          vVesselReg: loc?.vesselReg,
        } || {};
      setAttributes(data);
    }
  };

  const createNewCompanyByName = async (name: string) => {
    try {
      const newCompany: CreateTradePartnerOptionalParams = {
        body: {
          name,
        },
      };
      const res = await createTradePartner.mutateAsync(newCompany);
      return res;
    } catch (err) {
      message.error(errorHandler(err));
    }
    return {};
  };

  const createNewCompany = async (
    formData: DataItem,
  ): Promise<TradePartnerResponse | undefined> => {
    try {
      const newCompany: CreateTradePartnerOptionalParams = {
        body: {
          name: formData?.companyName || '',
          pgln: formData?.pgln || '',
          connectionType: formData.connectionType
            ? getConnectionTypeString(formData.connectionType)
            : '',
        },
      };
      const res = await createTradePartner.mutateAsync(newCompany);
      return res;
    } catch (err) {
      message.error(errorHandler(err));
    }
    return undefined;
  };

  const updateCompanyData = async (formData: DataItem, selectedCompany?: TradePartnerResponse) => {
    const compReq = {
      id: String(selectedCompany?.id) || '',
      name: formData.companyName ? String(formData.companyName) : selectedCompany?.name || '',
      pgln: formData?.pgln || '',
      tradeRelationship: formData.connectionType
        ? getConnectionTypeString(formData.connectionType)
        : selectedCompany?.tradeRelationship,
    };
    await updateCompany.mutateAsync({
      id: String(selectedCompany?.id) || '',
      options: compReq,
    });
  };

  const generateNewAPIKey = async (tradePartnerId: string) => {
    try {
      const res: GetInboundApiKeyResponse = await useGenerateAPIKeyAPI.mutateAsync({
        id: tradePartnerId,
      });
      return res;
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
    return false;
  };

  const updateTraceabilityData = async (
    tradePartnerId: string,
    options: UpdateExternalRegistryRequest,
  ) => {
    const compReq: UpdateExternalRegistryRequest = {
      ...options,
      tradePartnerId,
    };
    const res = await updateTraceability.mutateAsync({
      options: compReq,
    });
    return res;
  };
  const addTraceabilityData = async (
    tradePartnerId: string,
    digitalLinkUrl: string,
    outboundApiKey: string,
  ) => {
    const compReq: SetExternalRegistryRequest = {
      tradePartnerId,
      digitalLinkUrl,
      outboundApiKey,
    };
    const res = await addTraceability.mutateAsync({
      options: compReq,
    });
    return res || false;
  };

  const onTraceSwitchOff = (tradePartnerId: string, flag: boolean, digitalLinkUrl?: string) => {
    if (flag) {
      if (allowTraceibility) {
        setTraceSwitch(true);
      } else {
        upgradeTraceModal?.show();
      }
    } else {
      Modal.info({
        title: t('tracebility_warn.title'),
        content: t('tracebility_warn.content'),
        okText: t('tracebility_warn.continue'),
        cancelText: t('tracebility_warn.cancel'),
        cancelButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
        onOk: () => {
          setTraceSwitch(false);
          formRef?.current?.resetFields(['digitalLink', 'apiKey']);
          if (digitalLinkUrl) {
            addTraceabilityData(tradePartnerId, '', '');
          }
        },
      });
    }
  };

  const createNewLocation = async (
    formData: DataItem,
    selectedCompany?: TradePartnerResponse,
    isVessel?: boolean,
  ) => {
    const latlng = formData?.geoCoordinates?.split(',');
    if (formData.locationName && latlng) {
      const reqData: CreateLocationOptionalParams = {
        body: {
          gln: formData.gln,
          name: formData.locationName,
          tradePartnerId: selectedCompany?.id || '',
          address: {
            addressLine1: formData.address?.line1 || '',
            addressLine2: formData.address?.line2 || '',
            city: formData.address?.city || '',
            state: formData.address?.state || '',
            country: formData.address?.country || '',
            postalCode: formData.address?.postalCode || '',
            geoCoordinates: {
              latitude: Number(latlng ? latlng[0] : 1),
              longitude: Number(latlng ? latlng[1] : 1),
            },
          },
          contact: {
            phone: savePhoneNumber(formData),
            email: formData.email,
          },
          ...(isVessel && {
            vessel: {
              vesselName: formData.vesselName || '',
              vesselRegistration: formData.vesselReg || '',
              vesselPublicRegistryLink: formData.vesselLink || '',
              vesselSatelliteTrackingAuthority: formData.vesselAuth || '',
              imoNumber: formData.imoNumber || '',
              vesselFlagState: formData.vesselState || '',
            },
          }),
        },
      };
      const res = await createLocation.mutateAsync(reqData);
      return res;
    }
    return {};
  };

  const getTradePartnerIdByName = async (
    formData: DataItem,
    selectedCompany: TradePartnerResponse | undefined,
  ): Promise<TradePartnerResponse | undefined> => {
    try {
      if (selectedCompany) {
        await updateCompanyData(formData, selectedCompany);
        return selectedCompany;
      }
      const res = await createNewCompany(formData);
      return res;
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
    return undefined;
  };

  const handleLocation = async (formData: DataItem, selectedCompany: TradePartnerResponse) => {
    try {
      const fetchLocation = await getLocationAPICall({
        locationName: String(formData.locationName),
      });
      const location = fetchLocation?.find(
        (item) => String(item?.name) === String(formData.locationName),
      );
      if (location) {
        await updateLocationData(String(location?.id), formData, false);
        return;
      }
      await createNewLocation(formData, selectedCompany, false);
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  const getSelectedCompany = async (formData: DataItem) => {
    const selectedCompany = await fetchAllCompanies.mutateAsync({
      params: { types: ['Self', 'NonWholechain'], name: formData?.companyName },
    });
    return selectedCompany?.data?.[0];
  };

  const handleEachCSVRow = async (formData: DataItem) => {
    const selectedCompany = await getSelectedCompany(formData);
    const tradePartner = await getTradePartnerIdByName(formData, selectedCompany);
    if (tradePartner) {
      await handleLocation(formData, tradePartner);
    }
  };

  const bulkAddEdit = async (csvData: Array<DataItem>) => {
    setCSVUploading(true);
    await csvData.reduce((p, row) => p.then(() => handleEachCSVRow(row)), Promise.resolve());
    setCSVUploading(false);
  };

  const digitalLinkInvalid = useCallback(
    () =>
      Modal.error({
        title: t?.('digital_link_validation.title'),
        content: (
          <div>
            {`${t?.('digital_link_validation.content1')}`}&nbsp;
            <a href={supportEmailLink} type="mail">
              {t('digital_link_validation.support')}
            </a>
            &nbsp;
            {t('digital_link_validation.content2')}
          </div>
        ),
        okText: t('digital_link_validation.done'),
        cancelButtonProps: {
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      }),
    [t],
  );

  return {
    setAttributes,
    isVesselLocation,
    closeModal,
    onNoVessel,
    onNoMultiLoc,
    setLocationFieldValues,
    t,
    formRef,
    visible,
    closeDrawer,
    addEditLocationData,
    setAddEditLocationData,
    addEditVesselLocationData,
    setAddEditVesselLocationData,
    updateCompanyData,
    updateLocationData,
    createNewLocation,
    bulkAddEdit,
    createNewCompanyByName,
    onRemoveConnection,
    onRestoreConnection,
    loading:
      updateLocation.isLoading ||
      updateCompany.isLoading ||
      createLocation.isLoading ||
      createTradePartner.isLoading ||
      csvUploading,
    updateVesselLocation,
    createNewVesselLocation,
    getVesselLocations,
    getNonVesselLocations,
    setVesselLocationFieldValues,
    updateTraceabilityData,
    addTraceabilityData,
    traceSwitch,
    setTraceSwitch,
    onTraceSwitchOff,
    upgradeTraceModal,
    allowInternalLocation,
    allowExternalLocation,
    //  onCancelConnection,
    deleteInviteConsent,
    generateNewAPIKey,
    digitalLinkInvalid,
    queryClient,
    ...restDrawerVisibility,
  };
};

export default useAddEditActions;

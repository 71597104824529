import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message, Modal, RowProps, Typography } from 'antd';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from 'utils';
import Fields from './Fields';
import { DataItem, EventErrorProps } from './typings';

const rowProps: RowProps = { gutter: [24, 24] };

const EventErrorModal: FC<EventErrorProps> = ({ modal }) => {
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const { t } = useTranslation('pages', { keyPrefix: 'events' });
  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
  }, []);
  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
  }, [modal, resetFields]);
  const displayNeverOccuredConfirmation = useCallback(() => {
    Modal.warning({
      title: t('event_no_title'),
      content: (
        <div>
          <Typography.Paragraph>{t('event_no_desc')}</Typography.Paragraph>
          <Typography.Paragraph>{t('event_no_desc2')}</Typography.Paragraph>
        </div>
      ),
      okText: t('event_error_submit_btn'),
      cancelText: t('event_error_cancel_btn'),
      onOk: () => {
        message.success(t('event_error_submit_sucess'));
        closeModal();
      },
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, [closeModal, t]);
  const onFinish = useCallback(
    async (formData: DataItem) => {
      try {
        if (formData.errorType === 'NeverOccured') {
          displayNeverOccuredConfirmation();
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [displayNeverOccuredConfirmation],
  );
  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={Fields({ t })}
      grid
      // @ts-ignore
      title={t('event_error_title')}
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '30%',
        ...modal,
        centered: true,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('event_error_submit_btn'),
          resetText: t('event_error_cancel_btn'),
        },
        resetButtonProps: {
          shape: 'round',
          onClick: closeModal,
          type: 'primary',
          ghost: true,
        },
        submitButtonProps: {
          shape: 'round',
        },
      }}
      rowProps={rowProps}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default EventErrorModal;

import { SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetPaginatedDocumentsOptionalParams } from 'services/api/client/src';
import { useDocuments } from 'services/api/documents';
import { useDebouncedCallback } from 'use-debounce';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

const DocumentSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const [params, setParams] = useState<GetPaginatedDocumentsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
  });
  // const { data, isLoading } = useDocumentTags(params);
  const { data, isLoading } = useDocuments(params);
  const { t } = useTranslation('pages', { keyPrefix: 'select' });

  // we create here the list with the shape the select is expecting for
  const options: Array<DefaultOptionType> =
    data?.results?.map((item) => ({
      label: item.title,
      value: item.id,
      itemProps: item,
    })) || [];

  const debounced = useDebouncedCallback(
    // function
    (filterText) => {
      setParams((oldParams) => ({ ...oldParams, search: filterText }));
    },
    // delay in ms
    1000,
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, id: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );

  return (
    <GSelect<ValueType, OptionType>
      placeholder={t('document_placeholder')}
      {...props}
      allowClear={false}
      loading={isLoading}
      options={options as OptionType[]}
      fetchData={fetchData}
      allowFilterOption
    />
  );
};

export default DocumentSelect;

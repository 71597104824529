import { BetaSchemaForm } from '@ant-design/pro-form';
import React, { FC } from 'react';
import EventTableFields, { DataItem } from './EventTable/EventTable.fields';
import { EventPageProps } from './typings';

const Events: FC<EventPageProps> = ({ form }) => (
  <BetaSchemaForm<DataItem>
    columns={EventTableFields()}
    form={form}
    grid
    layoutType="Form"
    submitter={{ render: () => null }}
  />
);

export default React.memo(Events);

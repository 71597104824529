import { Segmented } from 'antd';
import { useEventsStore } from 'pages/Events/hooks';
import { DataEntryMethodType } from 'pages/Events/hooks/typings';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DataEntryProps } from './typings';

const DataEntryMethod: FC<DataEntryProps> = ({ disabled = false, onChange }) => {
  const { dataEntryMethod, setDataEntryMethod } = useEventsStore();
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  return (
    <Segmented
      disabled={disabled}
      value={dataEntryMethod}
      options={[
        { label: t('manual'), value: 'manual' },
        { label: t('csv_upload'), value: 'csvUpload' },
      ]}
      onChange={(e) => {
        setDataEntryMethod(e as DataEntryMethodType);
        onChange?.(e as DataEntryMethodType);
      }}
    />
  );
};

export default DataEntryMethod;

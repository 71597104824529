import G6, { Graph } from '@antv/g6';
import { getProductsT } from 'utils';
import { EventNodeTreeNode } from './typings';

const EDGE = 'cubic-horizontal';
const SOFAROUTER_TEXT_CLASS = 'sofarouter-text-class';
const SOFAROUTER_RECT_CLASS = 'sofarouter-rect-class';

const createEdges = (mainGraph: Graph) => {
  G6.registerEdge(
    EDGE,
    {
      // @ts-ignore
      draw: (cfg, group) => {
        const startPoint = cfg?.startPoint || { x: 0, y: 0 };
        const endPoint = cfg?.endPoint || { x: 0, y: 0 };
        // @ts-ignore
        const targetNode: EventNodeTreeNode = cfg.sourceNode.getModel();

        group?.addShape('path', {
          attrs: {
            path: [
              ['M', startPoint.x, startPoint.y],
              [
                'C',
                (startPoint.x + endPoint.x) / 2,
                startPoint.y,
                (startPoint.x + endPoint.x) / 2,
                endPoint.y,
                endPoint.x,
                endPoint.y,
              ], // 1/3
            ],
            lineWidth: 1,
            stroke: 'rgba(47,84,235,0.05)',
            opacity: 0,
            zIndex: 0,
          },
          name: 'line-bg',
        });
        const shape = group?.addShape('path', {
          attrs: {
            path: [
              ['M', startPoint.x, startPoint.y],
              [
                'C',
                (startPoint.x + endPoint.x) / 2,
                startPoint.y,
                (startPoint.x + endPoint.x) / 2,
                endPoint.y,
                endPoint.x,
                endPoint.y,
              ], // 1/3
            ],
            stroke: 'rgba(0,0,0,0.25)',
            zIndex: 1,
            lineAppendWidth: 12,
          },
          // must be assigned in G6 3.3 and later versions. it can be any value you want
          name: 'path-shape',
        });
        /* Midpoint of the connecting line */
        const centerPoint = {
          x: startPoint.x + (endPoint.x - startPoint.x) / 2,
          y: startPoint.y + (endPoint.y - startPoint.y) / 2,
        };
        const textRect = group?.addShape('rect', {
          attrs: {
            fill: '#FFF1F0',
            radius: 2,
            opacity: 1,
          },
          name: SOFAROUTER_RECT_CLASS,
        });
        const container =
          targetNode?.eventData?.container || targetNode?.eventData?.containers?.[0];
        const isContainer =
          targetNode?.eventData?.eventType !== 'Disaggregation'
            ? !!container?.identifier || (targetNode?.eventData?.containers?.length || 0) > 0
            : false;
        const lotIdentifier = getProductsT(
          targetNode?.eventData?.products?.[0]?.productIdentifierType ||
            targetNode?.eventData?.outputProducts?.[0]?.productIdentifierType ||
            'Lot',
        );
        const containerIdentifier = isContainer ? container?.identifier || '' : '';

        const identifierText = isContainer ? `${getProductsT('sscc')}: ` : `${lotIdentifier}: `;
        const primaryId = containerIdentifier || targetNode?.primaryId || '';
        const nameText = `${identifierText}\n${primaryId}`;
        const text = group?.addShape('text', {
          attrs: {
            text: nameText,
            fontSize: 12,
            textAlign: 'left',
            textBaseline: 'middle',
            fill: '#F5222D',
            opacity: 1,
            zIndex: 1,
          },
          name: SOFAROUTER_TEXT_CLASS,
        });
        const textBBox = text?.getBBox();
        /* position of the text */
        text?.attr({
          x: centerPoint.x - textBBox.width / 2,
          y: centerPoint.y,
        });
        /* box for text */
        textRect?.attr({
          x: centerPoint.x - textBBox.width / 2 - 4,
          y: centerPoint.y - textBBox.height / 2 - 5,
          width: textBBox.width + 8,
          height: textBBox.height + 10,
        });
        return shape;
      },
      afterDraw: (cfg: any, group: any) => {
        /* Background color */
        const lineBG = group.get('children')[0]; // The order is determined according to the order
        /* line */
        const line = group.get('children')[1];
        line.on('mouseenter', () => {
          lineBG.attr('opacity', '1');
          /* If there is no error, the line needs to be blue when hovered. */
          if (!line.get('edgeError')) {
            line.attr('stroke', '#0A5F7A');
          }
          mainGraph.get('canvas').draw();
        });
        line.on('mouseleave', () => {
          lineBG.attr('opacity', '0');
          if (!line.get('edgeError')) {
            line.attr('stroke', 'rgba(0,0,0,0.25)');
          }
          mainGraph.get('canvas').draw();
        });
      },
      setState: (name: any, value: any, item: any) => {
        const group = item.getContainer();
        const childrens = group.get('children');
        mainGraph.setAutoPaint(true);
        if (name === 'emptiness') {
          if (value) {
            childrens.forEach((shape: any) => {
              if (shape.get('name') === 'line-bg') {
                return;
              }
              shape.attr('opacity', 0.4);
            });
          } else {
            childrens.forEach((shape: any) => {
              if (shape.get('name') === 'line-bg') {
                return;
              }
              shape.attr('opacity', 1);
            });
          }
        }
        mainGraph.setAutoPaint(true);
      },
      update: undefined,
    },
    'cubic-horizontal',
  );
};
export default createEdges;

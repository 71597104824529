import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Button, FormInstance, RowProps, Space, message } from 'antd';
import AddDocumentFormFields from 'pages/Documents/Forms/AddDocumentForm.fields';
import { DataItem } from 'pages/Documents/Forms/typings';
import useDocumentsStore from 'pages/Documents/hooks/useDocumentsStore';
import { AddDocumentFormProps } from 'pages/Events/components/Sections/typings';
import React, { CSSProperties, FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { CreateDocument$multipartOptionalParams } from 'services/api/client/src';
import { useCreateDocument } from 'services/api/documents';
import { capitalizeFirstLetter, errorHandler, getDivImage } from 'utils';
import styles from './index.module.less';

const rowProps: RowProps = { gutter: [32, 24] };
const docContainerStyle: CSSProperties = {
  maxHeight: '65vh',
};

const AddDocumentForm: FC<AddDocumentFormProps> = ({ form, modal, onAddSuccess }) => {
  const queryClient = useQueryClient();
  const { setFieldsValue } = form;
  const { t } = useTranslation('pages', { keyPrefix: 'network.share_document' });
  const hasAdvancedSettings = useDocumentsStore((state) => state.hasAdvancedSettings);
  const modalFormRef = useRef<ProFormInstance>();
  const setDocuments = useCallback(
    (data: Array<DataItem>) => {
      setFieldsValue({ documents: data });
    },
    [setFieldsValue],
  );

  const createDocument = useCreateDocument(queryClient);

  const title = t('new_doc_title');
  const submitBtnText = t('new_doc_submit_btn');
  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onFinish = async (formData: DataItem) => {
    try {
      if (!formData.file) {
        message.error(t('missing_doc'));
        return;
      }

      const reqBody: CreateDocument$multipartOptionalParams = {
        title: formData.title,
        documentType: capitalizeFirstLetter(formData?.documentType || 'other'),
        file: formData.file,
        privacy: formData.privacy,
        blockchain: formData.blockchain,
        certificationAgency: formData.agency,
        certificationStandard: formData.certificationStandard,
        certificationId: formData.identification,
        purchaseOrderNumber: formData.purchaseOrder,
        documentTypeName: formData.documentTypeName,
        expirationDate: formData?.expirationDate
          ? new Date(formData?.expirationDate || '')
          : undefined,
        expirationAlertDate: formData?.expirationDateAlert
          ? new Date(formData?.expirationDateAlert)
          : undefined,
        productsIds: formData?.products as Array<string>,
        locationsIds: formData?.locations as Array<string>,
        tradePartnersIds: formData?.tradePartners as Array<string>,
        shipFrom: formData?.shipFrom,
        shipTo: formData?.shipTo,
        sid: formData?.sID,
        cid: formData?.cID,
        fob: formData?.fOB,
        shipDate: formData?.shipDate ? new Date(formData?.shipDate || '') : undefined,
        carrier: formData?.carrier,
        items: formData?.items,
        quantitiesOfShippedItems: formData?.quantitiesOfShippedItems,
      };
      const res = await createDocument.mutateAsync(reqBody);
      const thumbURL = await getDivImage('page_1');
      const documentData: DataItem = {
        id: res.id,
        title: formData.title,
        documentType: formData.documentType,
        file: formData.file,
        privacy: formData.privacy,
        blockchain: formData.blockchain,
        expirationDate: formData.expirationDate,
        agency: formData.agency,
        certificationStandard: formData.certificationStandard,
        certificationValue: formData.certificationValue,
        identification: formData.identification,
        documentURL: res?.url,
        documentAttachment: formData.documentAttachment,
        thumbURL,
      };
      setDocuments([documentData]);
      if (onAddSuccess) {
        onAddSuccess(documentData);
      }
      closeModal();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  const onShareDocument = async (formShareData?: FormInstance<any>) => {
    if (formShareData) {
      const formData: DataItem = formShareData.getFieldsValue();
      onFinish(formData);
      closeModal();
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      formRef={modalFormRef}
      columns={AddDocumentFormFields({ hasAdvancedSettings, docContainerStyle })}
      grid
      title={title}
      rowProps={rowProps}
      autoFocusFirstInput
      visible={modal.visible}
      modalProps={{
        destroyOnClose: false,
        width: '80%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: submitBtnText,
        },
        submitButtonProps: {
          shape: 'round',
        },
        render: ({ form: formShareData }) => (
          <>
            <Button onClick={() => closeModal()} className={styles.newdoclink} type="link">
              {t('old_doc_button')}
            </Button>

            <Space>
              <Button type="primary" shape="round" onClick={() => onShareDocument(formShareData)}>
                {t('share')}
              </Button>
            </Space>
          </>
        ),
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default React.memo(AddDocumentForm);

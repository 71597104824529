import ProList, { ProListMetas } from '@ant-design/pro-list';
import { message, Modal, PageHeader, Select, Switch, Typography } from 'antd';
import { useAuthenticationContext, useTranslationContext } from 'contexts';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getUserById, useChangeSettings, useUserById } from 'services/api';
import { ChangeSettingsRequest, ChangeUserSettingsRequest } from 'services/api/client/src';
import { useGetAllLanguages } from 'services/api/useLanguage';
import { AntLocale, antLocale, errorHandler } from 'utils';
import { changeLanguage } from 'utils/language';
import styles from './index.module.less';
import { LangPreferenceItem, PreferenceItem } from './typings';

const Preferences = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.preferences' });
  const queryClient = useQueryClient();
  const { currentUser, setLangCode, langCode } = useAuthenticationContext();
  const { setLocale, isTLoading } = useTranslationContext();
  const { data: user } = useUserById(currentUser?.id || '');
  const updateUser = useChangeSettings(queryClient);
  const userPreferences = useMemo(
    () => user?.settings?.emailNotifications,
    [user?.settings?.emailNotifications],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { data: languages } = useGetAllLanguages(user?.settings?.language ?? 'en');
  const languageOptions = useMemo(
    () =>
      languages?.map((item) => ({
        label: (item?.code && t(item?.code)) || '',
        value: item?.code || '',
      })) || [{ label: 'English', value: 'en' }],
    [languages, t],
  );
  const preferences = useMemo<Array<PreferenceItem>>(
    () => [
      {
        id: 'notifyInbound',
        title: t('inbound_shipments_title'),
        description: t('inbound_shipments_description'),
        value: userPreferences?.notifyInbound,
      },
      {
        id: 'notifyOutbound',
        title: t('outbound_shipments_title'),
        description: t('outbound_shipments_description'),
        value: userPreferences?.notifyOutbound,
      },
      {
        id: 'notifyNetworkInvites',
        title: t('network_invites_title'),
        description: t('network_invites_description'),
        value: userPreferences?.notifyNetworkInvites,
      },
      {
        id: 'notifyDocumentExpiration',
        title: t('document_expire_title'),
        description: t('document_expire_description'),
        value: userPreferences?.notifyDocumentExpiration,
      },
      {
        id: 'notifySharing',
        title: t('sharing_title'),
        description: t('sharing_description'),
        value: userPreferences?.notifySharing,
      },
    ],
    [t, userPreferences],
  );
  const langPreferences = useMemo<Array<LangPreferenceItem>>(
    () => [
      {
        id: 'selectedLanguage',
        title: t('selected_language'),
        description: t('selected_language_description'),
        value: user?.settings?.language || 'en',
      },
    ],
    [t, user?.settings?.language],
  );

  const updateUserAsync = useCallback(
    async (preferencesOptions: ChangeUserSettingsRequest) => {
      const { emailNotifications, language } = preferencesOptions;
      try {
        const latestUserData = await getUserById(currentUser?.id || '');
        const reqData: ChangeSettingsRequest = {
          settings: {
            emailNotifications: {
              ...latestUserData?.settings?.emailNotifications,
              ...emailNotifications,
            },
            language: language || latestUserData?.settings?.language || 'en',
          },
        };
        await updateUser.mutateAsync({ body: reqData });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [currentUser?.id, setLoading, updateUser],
  );

  const onChangeNotification = useCallback(
    async (value: boolean, record: PreferenceItem) => {
      try {
        setLoading(true);
        await updateUserAsync({
          emailNotifications: {
            [String(record.id)]: value,
          },
        });
      } catch (err) {
        if (errorHandler(err)) {
          message.error(errorHandler(err));
        }
      }
    },
    [updateUserAsync],
  );
  const listMetas = useMemo<ProListMetas<PreferenceItem>>(
    () => ({
      title: {
        dataIndex: 'title',
        render: (dom, record) => <Typography.Text>{record?.title || ''}</Typography.Text>,
      },
      description: {
        dataIndex: 'description',
        render: (text, record) => <div className="list-desc">{record.description}</div>,
      },
      actions: {
        render: (text, record) => (
          <Switch
            checked={record?.value || false}
            onClick={(value) => onChangeNotification(value, record)}
            loading={loading}
          />
        ),
      },
    }),
    [loading, onChangeNotification],
  );

  const onChangeLanguage = useCallback(
    async (value: string) => {
      try {
        changeLanguage(value);
        setLangCode(value);
        setLocale(antLocale[value as keyof AntLocale]);
        await updateUserAsync({
          language: value || 'en',
        });
      } catch (err) {
        if (errorHandler(err)) {
          message.error(errorHandler(err));
        }
      }
      // bulkCreateAPI();
    },
    [setLangCode, setLocale, updateUserAsync],
  );

  const langPreferenceMetas = useMemo<ProListMetas<LangPreferenceItem>>(
    () => ({
      title: {
        dataIndex: 'title',
        render: (dom, record) => <Typography.Text>{record?.title || ''}</Typography.Text>,
      },
      description: {
        dataIndex: 'description',
        render: (text, record) => <div className="list-desc">{record.description}</div>,
      },
      actions: {
        render: (text, record) => (
          <Select
            loading={isTLoading}
            disabled={isTLoading}
            defaultValue={langCode || record.value}
            options={languageOptions}
            onChange={onChangeLanguage}
            className={styles.langselect}
          />
        ),
      },
    }),
    [langCode, languageOptions, onChangeLanguage, isTLoading],
  );

  return (
    <>
      <Modal visible={loading} title={null} footer={null} closable={false} width={0} mask={false} />
      <PageHeader title={t('title')} className={styles['page-header']} />
      <ProList
        headerTitle={
          <>
            {t('email_notifications_title')}
            &nbsp;
          </>
        }
        tooltip={t('email_notifications_tooltip')}
        itemLayout="horizontal"
        rowKey="id"
        split
        dataSource={preferences}
        className={styles.alist}
        metas={listMetas}
      />
      <ProList
        headerTitle={
          <>
            {t('language_title')}
            &nbsp;
          </>
        }
        tooltip={t('language_tooltip')}
        itemLayout="horizontal"
        rowKey="id"
        split
        dataSource={langPreferences}
        className={styles.listmrt20}
        metas={langPreferenceMetas}
      />
    </>
  );
};
export default React.memo(Preferences);

import { BetaSchemaForm } from '@ant-design/pro-form';
import { Form, Space } from 'antd';
import { useDrawerVisibility } from 'hooks';
import { useEventsStore } from 'pages/Events/hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTemplateById } from 'services/api/useTemplates';
import { EventType } from '../typings';
import PreviewTemplateDrawerHeader from './Sections/PreviewTemplateDrawerHeader';
import ViewTemplatePreviewFormFields, { DataItem } from './ViewTemplatePreviewForm.fields';

const ViewTemplatePreviewForm: FC = () => {
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const { hasAdvancedSettings, dataEntryMethod } = useEventsStore();
  const { t } = useTranslation('pages', { keyPrefix: 'templates.preview' });
  const [event, setEvent] = useState<EventType>('Commission');
  const isManualEntry = dataEntryMethod === 'manual';

  const { templateId = '' } = useParams();
  const { data: template } = useTemplateById(templateId);
  const [mainForm] = Form.useForm();
  const [headerForm] = Form.useForm(mainForm);

  const { setFieldsValue } = mainForm;
  useEffect(() => {
    if (template) {
      setFieldsValue({
        template,
      });
    }
  }, [setFieldsValue, template]);

  const onFinish = async () => {
    closeDrawer();
  };

  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={ViewTemplatePreviewFormFields({
        templateId,
        isManualEntry,
        hasAdvancedSettings,
        event,
      })}
      grid
      // @ts-ignore
      title={<PreviewTemplateDrawerHeader form={headerForm} event={event} setEvent={setEvent} />}
      rowProps={{ gutter: 32 }}
      visible={visible}
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        ...restDrawerVisibility,
      }}
      submitter={{
        searchConfig: {
          submitText: t('exit_preview'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        render: (_, defaultDoms) => <Space>{defaultDoms[1]}</Space>,
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default ViewTemplatePreviewForm;

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetPaginatedProductsOptionalParams, ProductResponse } from 'services/api/client/src';
import useProducts from 'services/api/useProducts';
import { useDebouncedCallback } from 'use-debounce';
import GSelect from './GSelect';
import { FetchDataFn, ProductSelectProps } from './typings';

const ProductSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  renderLabel,
  renderValue,
  disabledItem,
  isSerial,
  allowLotSerial,
  prependOption,
  isAddProduct,
  filterItem,
  ...props
}: ProductSelectProps<ValueType, OptionType>) => {
  const [params, setParams] = useState<GetPaginatedProductsOptionalParams>();
  const { data, isLoading } = useProducts(params);
  const navigate = useNavigate();
  const filterOption = useCallback(
    (option?: DefaultOptionType) => {
      if (!filterItem) {
        return true;
      }
      return filterItem?.(option?.itemProps as OptionType['itemProps']);
    },
    [filterItem],
  );
  const filterSerial = useCallback(
    (option: DefaultOptionType) => {
      const product = option.itemProps as ProductResponse;
      if (allowLotSerial) {
        return true;
      }

      if (isSerial) {
        return product.productIdentifierType === 'Serial';
      }
      return product.productIdentifierType !== 'Serial';
    },
    [isSerial, allowLotSerial],
  );

  // we create here the list with the shape the select is expecting for
  const productOptions: Array<DefaultOptionType> = useMemo(
    () => [
      ...(prependOption ? [prependOption] : []),
      ...(data?.results
        ?.map((product) => ({
          label: renderLabel ? renderLabel(product) : String(product.name),
          value: renderValue ? renderValue(product) : String(product.id),
          disabled: disabledItem?.(product) || false,
          itemProps: product,
        }))
        ?.filter(filterSerial)
        ?.filter(filterOption) || []),
    ],
    [
      prependOption,
      data?.results,
      filterSerial,
      filterOption,
      renderLabel,
      renderValue,
      disabledItem,
    ],
  );

  const debounced = useDebouncedCallback(
    // function
    (filterText) => {
      setParams((oldParams) => ({ ...oldParams, name: filterText }));
    },
    // delay in ms
    1000,
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );

  const dropdownRender = useCallback(
    (menu: ReactElement) => (
      <>
        {menu}
        <Button type="link" icon={<PlusOutlined />} onClick={() => navigate('../../../add')}>
          New Product
        </Button>
      </>
    ),
    [navigate],
  );

  return (
    <GSelect<ValueType, OptionType>
      placeholder="Select Product"
      {...props}
      loading={isLoading}
      options={productOptions as OptionType[]}
      fetchData={fetchData}
      allowFilterOption={false}
      dropdownRender={isAddProduct ? dropdownRender : undefined}
    />
  );
};

export default ProductSelect;

import { ProColumns } from '@ant-design/pro-table';
import { TablePaginationConfig, Typography } from 'antd';
import { GTable } from 'components';
import { useTableSearchFilter, useWindowSize } from 'hooks';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { TFunction } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEventListById } from 'services/api';
import { EventDetailResponse, ListEventDetailsOptionalParams } from 'services/api/client/src';
import { useLocationFilters } from 'services/api/useLocations';
import { dateLocalFormat, getEventsT, getProductsT, getTransformType } from 'utils';
import { EventsProps } from './typings';

type ColumnsType = {
  eventSearchfilter: ProColumns;
  productSearchfilter: ProColumns;
  primaryIdSearchfilter: ProColumns;
  coreEventTypes: ProColumns['filters'];
  coreLocations: ProColumns['filters'];
  t: TFunction<'pages', 'dashboard.activity_event'>;
};
const Columns = ({
  productSearchfilter,
  primaryIdSearchfilter,
  coreEventTypes,
  coreLocations,
  t,
}: ColumnsType): ProColumns<EventDetailResponse>[] => [
  {
    title: t('activity_event.event_tbl_col_event_title'),
    dataIndex: 'eventType',
    sorter: false,
    width: 100,
    hideInSetting: true,
    ellipsis: true,
    filters: coreEventTypes,
    render: (text, record) => (
      <Typography.Text>
        {getEventsT(record?.eventType) || ''}{' '}
        <Typography.Text type="secondary">
          {getTransformType(record?.transformType)}
        </Typography.Text>
      </Typography.Text>
    ),
  },
  {
    title: t('activity_event.event_tbl_col_product_title'),
    dataIndex: 'productName',
    sorter: false,
    width: 200,
    ellipsis: true,
    ...productSearchfilter,
  },
  {
    title: t('activity_event.event_tbl_col_primary_id_title'),
    dataIndex: 'lotSerial',
    sorter: false,
    width: 150,
    ellipsis: true,
    render: (dom, record) => (
      <Link
        className="text-link"
        to={{
          pathname: `/products/${
            // @ts-ignore
            record?.productId || record?.containerItems?.[0]?.productId || ''
          }/${String(record?.instanceId || record?.containerId || '')}`,
          search: `?eventId=${record?.eventId || ''}${
            record?.isContainer ? '&isContainer=true' : ''
          }`,
        }}
      >
        {record?.isContainer ? getProductsT('sscc') : getProductsT('lot')}:{' '}
        {record?.lotSerial || ''}
      </Link>
    ),
    ...primaryIdSearchfilter,
  },
  {
    title: t('activity_event.event_tbl_col_quantity_title'),
    dataIndex: 'quantity',
    width: 143,
    ellipsis: true,
    render: (dom, record) => `${record?.quantity || ''} ${record?.unitOfMeasure || ''}`,
  },
  {
    title: t('activity_event.event_tbl_col_location_title'),
    dataIndex: 'locationName',
    hideInSetting: true,
    ellipsis: true,
    filters: coreLocations,
    filterSearch: true,
    width: 240,
    render: (text, record) => record?.locationName || '',
  },
  {
    title: t('activity_event.event_tbl_col_date_title'),
    dataIndex: 'createdTime',
    sorter: true,
    defaultSortOrder: 'descend',
    ellipsis: true,
    width: 152,
    render: (text, record) =>
      dateLocalFormat(new Date(record?.eventLoggedDate || new Date())) || '',
  },
];
const Events: FC<EventsProps> = ({ t }) => {
  const [, height] = useWindowSize();

  const scrollHeight = useMemo(() => {
    // amount of pixels equivalent to 40vh
    const vh = Math.floor(height * 0.33);

    return vh;
  }, [height]);

  const [params, setParams] = useState<ListEventDetailsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    sortBy: '-createdTime',
  });
  const { data: events, isLoading } = useEventListById(params);
  const records = events?.results || [];
  const totalItems = events?.totalItems || 0;
  const onSearch = useCallback(
    (text: string, key: string) => {
      setParams({
        ...params,
        pageNumber: 1,
        [key]: text,
      });
    },
    [params],
  );

  const { filters: eventSearchfilter } = useTableSearchFilter({
    title: t('activity_event.filter_event_name'),
    onSearch: (text) => onSearch(text, 'eventName'),
  });
  const { filters: productSearchfilter } = useTableSearchFilter({
    title: t('activity_event.filter_product_name'),
    onSearch: (text) => onSearch(text, 'productName'),
  });
  const { filters: primaryIdSearchfilter } = useTableSearchFilter({
    title: t('activity_event.event_tbl_col_primary_id_title'),
    onSearch: (text) => onSearch(text, 'lotSerial'),
  });

  /* locations */
  const coreLocations = useLocationFilters();
  /* event types */
  const coreEventTypes = useMemo(
    () => [
      { value: 'COMMISSION', text: getEventsT('commission') },
      { value: 'OBSERVE', text: getEventsT('observe') },
      { value: 'SHIP', text: getEventsT('ship') },
      { value: 'TRANSFORM', text: getEventsT('transform') },
      { value: 'AGGREGATION', text: getEventsT('aggregation') },
      { value: 'DECOMMISSION', text: getEventsT('decommission') },
      { value: 'DISAGGREGATION', text: getEventsT('disaggregation') },
      { value: 'RECEIVE', text: getEventsT('receive') },
    ],
    [],
  );

  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, Array<string> | null>,
      sorter: any,
    ) => {
      const apiFilters: ListEventDetailsOptionalParams = {
        locationIds: filters?.locationName?.length ? (filters.locationName as string[]) : undefined,
        eventTypes: filters?.eventType?.length ? (filters.eventType as string[]) : undefined,
      };
      if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
        setParams({
          ...params,
          ...apiFilters,
          sortBy: sorter?.field
            ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
            : undefined,
          pageNumber: 1,
        });
      } else {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: pagination?.current,
          pageSize: pagination?.pageSize,
        });
      }
    },
    [params, setParams],
  );
  return (
    <GTable<EventDetailResponse>
      columns={Columns({
        coreLocations,
        coreEventTypes,
        productSearchfilter,
        eventSearchfilter,
        primaryIdSearchfilter,
        t,
      })}
      value={records}
      options={{ setting: false, reload: false }}
      scroll={{ x: 1300, y: scrollHeight }}
      loading={isLoading}
      pagination={{
        defaultPageSize: params.pageSize,
        total: totalItems,
      }}
      // @ts-ignore
      onTableChange={onTableChange}
      columnsState={{
        persistenceKey: 'dashboard-events',
      }}
    />
  );
};
export default React.memo(Events);

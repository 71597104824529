import { InfoCircleOutlined } from '@ant-design/icons';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { FormInstance } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import ActionButtons from 'components/ActionButtons';
import ProductLotSelect from 'components/GSelect/ProductLotSelect';
import ProductSelect from 'components/GSelect/ProductSelect';
import GTable from 'components/GTable';
import moment, { Moment } from 'moment';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  dateFormat,
  disableExistingLotLocations,
  getDisabledDate,
  getDisabledTime,
  getInventoryPropsAddon,
  getLotUomAddon,
  getQuantityAddon,
  getTimeFormat,
  hiddencol,
  isSameKey,
  onChangeBatchShipFieldDate,
  onChangeFieldsPropInstance,
  onChangeFieldsPropProduct,
} from 'utils';
import { BatchShipProductItem, BatchShipProductsProps } from '../typings';

type ColumnsType = {
  t?: TFunction<'pages', 'shipments.batch_ship.form_fields'>;
  onEditRow: (actionPayload?: BatchShipProductItem) => void;
  onDelRow: (actionPayload?: BatchShipProductItem) => void;
  onDuplicateRow: (actionPayload?: BatchShipProductItem) => void;
  disabledDate?: (date: Moment) => boolean;
  disabledTime?: RangePickerProps['disabledTime'];
  onChangeDate?: (
    form: FormInstance<any>,
    value: string,
    isMultipleDates?: boolean,
    rowKey?: string,
  ) => void;
  multipleDates: boolean;
  readonly?: boolean;
  defaultUom?: string;
  locationId?: string;
  products?: BatchShipProductItem[];
  eventTime?: Moment;
};

const columns = ({
  t,
  onEditRow,
  onDelRow,
  onDuplicateRow,
  multipleDates,
  readonly = false,
  defaultUom,
  products,
  locationId,
  eventTime,
}: ColumnsType): Array<ProColumns<BatchShipProductItem>> => [
  {
    title: t?.('tbl_col_product_title'),
    dataIndex: 'parentProductId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_product_title_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, config, form) => (
      <ProductSelect
        size="small"
        onChange={(value, option) =>
          onChangeFieldsPropProduct(form, config, option, {
            // @ts-ignore
            name: option?.itemProps?.name || '',
          })
        }
      />
    ),
    render: (text, record) => record?.name || '',
    ellipsis: true,
    width: 220,
  },
  {
    title: t?.('tbl_col_lot_id_title'),
    dataIndex: 'primaryId',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_lot_id_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: (form, { rowKey }) => {
      const product = form?.getFieldValue?.(String(rowKey))?.parentProductId;
      return {
        disabled: !product,
        placeholder: t?.('tbl_col_lot_id_placeholder'),
      };
    },
    renderFormItem: (_, { recordKey, record }, form) => (
      <ProductLotSelect
        productId={record?.parentProductId}
        disabledItem={(item) => disableExistingLotLocations(item, products, locationId, eventTime)}
        size="small"
        onChange={(value, option) => onChangeFieldsPropInstance(form, { recordKey }, option)}
      />
    ),
    render: (text, record) => `${record?.lotSerial || ''}`,
    ellipsis: true,
    width: 220,
  },
  {
    title: t?.('tbl_col_scss_title'),
    dataIndex: 'logisticId',
    fieldProps: {
      size: 'small',
      placeholder: t?.('tbl_col_scss_placeholder'),
      disabled: true,
    },
    ellipsis: true,
    width: 200,
  },
  {
    title: t?.('tbl_col_purchase_order_title'),
    dataIndex: 'purchaseOrder',
    fieldProps: {
      size: 'small',
      placeholder: t?.('tbl_col_purchase_order_placeholder'),
      disabled: true,
    },
    ellipsis: true,
    width: 200,
  },
  {
    dataIndex: 'instanceInventory',
    ...hiddencol,
  },
  {
    title: t?.('tbl_col_quantity_title'),
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: (form, config) => getLotUomAddon(form, config, defaultUom),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_quantity_req'),
        },
      ],
      hasFeedback: false,
    },
    render: (text, record) => `${record?.quantity} ${getQuantityAddon(record, defaultUom)}`,
    ellipsis: true,
    width: 330,
  },
  {
    title: t?.('tbl_col_current_inventory_title'),
    dataIndex: 'currentInventory',
    tooltip: {
      title: t?.('tbl_col_current_inventory_tooltip'),
      icon: <InfoCircleOutlined />,
    },
    fieldProps: (form, config) =>
      getInventoryPropsAddon(form, config, defaultUom, {
        disabled: true,
        placeholder: t?.('tbl_col_current_inventory_placeholder'),
      }),
    render: (text, record) => `${record?.currentInventory} ${record?.unitOfMeasure || ''}`,
    ellipsis: true,
    width: 320,
  },
  {
    dataIndex: 'eventDate',
    ...hiddencol,
  },
  {
    title: t?.('tbl_col_date_title'),
    dataIndex: 'date',
    valueType: 'date',
    hideInTable: !multipleDates,
    shouldCellUpdate: (record, prevRecord) => record?.eventDate !== prevRecord?.eventDate,
    fieldProps: (form, { rowKey }) => {
      const eventDate = form?.getFieldValue?.(String(rowKey))?.eventDate;
      return {
        size: 'small',
        className: 'full-width',
        placeholder: t?.('tbl_col_date_placeholder'),
        onChange: (value: string) => onChangeBatchShipFieldDate?.(form, rowKey, value),
        disabledDate: getDisabledDate(eventDate),
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_date_req'),
        },
      ],

      hasFeedback: false,
    },
  },
  {
    title: t?.('tbl_col_time_title'),
    dataIndex: 'time',
    valueType: 'time',
    hideInTable: !multipleDates,
    shouldCellUpdate: (record, prevRecord) => record?.eventDate !== prevRecord?.eventDate,
    fieldProps: (form, { rowKey }) => {
      const eventDate = form?.getFieldValue?.(String(rowKey))?.eventDate;

      return {
        size: 'small',
        placeholder: 'Select Time',
        disabledTime: getDisabledTime(eventDate),
        className: 'full-width',
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('tbl_col_time_req'),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
  },

  {
    dataIndex: 'unitOfMeasure',
    ...hiddencol,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInTable: readonly,
    fixed: 'right',
    width: 150,
    render: (_text, record) => (
      <ActionButtons
        showDuplicate
        record={record}
        onEdit={onEditRow}
        onDelete={onDelRow}
        onDuplicate={onDuplicateRow}
      />
    ),
  },
];

const sumReducer = (partialSum: number, a: number) => Number(partialSum) + Number(a);
const BatchShipProducts: FC<BatchShipProductsProps> = ({
  form,
  multipleDates = false,
  readonly = false,
  defaultUom,
  locationId,
  date,
  time,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.batch_ship.form_fields' });
  const { setFieldsValue, getFieldValue } = form;

  const eventTime = useMemo(
    () =>
      date && time
        ? moment(`${dateFormat(date)} ${getTimeFormat(time)}`, 'YYYY-MM-DD HH:mm:ss')
        : undefined,
    [date, time],
  );

  const products: Array<BatchShipProductItem> = getFieldValue('batchShipProducts');
  const setProducts = useCallback(
    (value: Array<BatchShipProductItem>) => {
      setFieldsValue({
        batchShipProducts: value,
      });
    },
    [setFieldsValue],
  );

  const actionRef = useRef<ActionType>();
  const onEditRow = (actionPayload?: BatchShipProductItem) => {
    actionRef.current?.startEditable(actionPayload?.id || 0);
  };
  const onDelRow = async (actionPayload?: BatchShipProductItem) => {
    if (actionPayload) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    }
  };
  const onDuplicateRow = async (actionPayload?: BatchShipProductItem) => {
    if (actionPayload) {
      const newProduct: BatchShipProductItem = {
        id: Date.now().toString(),
        parentProductId: actionPayload?.parentProductId || '0',
        name: actionPayload?.name || '',
        lotSerial: '',
        primaryId: undefined,
        currentInventory: Number(actionPayload?.currentInventory || 0),
        instanceInventory: actionPayload?.instanceInventory || 0,
        quantity: 0,
        unitOfMeasure: actionPayload?.unitOfMeasure || '',
        logisticId: '',
        purchaseOrder: '',
        date: undefined,
        time: undefined,
        eventDate: actionPayload?.eventDate,
      };

      setProducts([...products, newProduct]);
    }
  };
  const onSaveProduct = async (_rowKey: RecordKey, data: BatchShipProductItem) => {
    actionRef.current?.cancelEditable(data?.id || 0);
    /* reset date and time field if set */
    setFieldsValue({
      date: undefined,
      time: undefined,
    });

    const existingProduct = products.find((product) => product.id === data?.id);
    if (existingProduct) {
      existingProduct.name = data?.name || '';
      existingProduct.quantity = Number(data?.quantity || 0);
      existingProduct.currentInventory = data?.currentInventory;
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.lotSerial = data?.lotSerial || '';
      existingProduct.unitOfMeasure = data?.unitOfMeasure || '';
      existingProduct.logisticId = data?.logisticId || '';
      existingProduct.purchaseOrder = data?.purchaseOrder || '';
      existingProduct.date = data?.date;
      existingProduct.time = data?.time;
      // update product
      setProducts(products.map((p) => (p.id === existingProduct.id ? existingProduct : p)));
    } else {
      const productData = {
        id: data?.id || '0',
        parentProductId: data?.parentProductId || '0',
        name: data?.name || '',
        lotSerial: data?.lotSerial || '',
        primaryId: data?.primaryId || '0',
        currentInventory: Number(data?.currentInventory || 0),
        quantity: Number(data?.quantity || 0),
        unitOfMeasure: data?.unitOfMeasure || '',
        logisticId: data?.logisticId || '',
        purchaseOrder: data?.purchaseOrder || '',
        date: data?.date,
        time: data?.time,
        instanceInventory: data?.instanceInventory || 0,
        eventDate: data?.eventDate,
      };
      // add product
      setProducts([...products, productData]);
    }
  };
  const onAddProduct = async () => {
    actionRef.current?.addEditRecord?.({
      id: Date.now().toString(),
    });
  };
  const getcolSpan = useMemo(() => {
    if (!readonly) {
      return !multipleDates ? 3 : 4;
    }
    return 2;
  }, [readonly, multipleDates]);

  const summary = useCallback(
    (tblData: readonly BatchShipProductItem[]) => {
      const sameUnitOfMeasure = isSameKey('unitOfMeasure', tblData as BatchShipProductItem[]);
      const unitOfMeasure = tblData[0]?.unitOfMeasure || '';
      const quantityArray = tblData?.map((el) => el?.quantity || 0) || [];
      const totalQuantity = quantityArray.reduce(sumReducer, 0);
      return sameUnitOfMeasure ? (
        <ProTable.Summary.Row className="summary-row">
          <ProTable.Summary.Cell index={0} colSpan={4} className="summary-title">
            {t('total_quantity')}
          </ProTable.Summary.Cell>
          <ProTable.Summary.Cell index={1} colSpan={getcolSpan} className="summary-title">
            {totalQuantity} {unitOfMeasure}
          </ProTable.Summary.Cell>
        </ProTable.Summary.Row>
      ) : undefined;
    },
    [getcolSpan, t],
  );

  return (
    <GTable<BatchShipProductItem>
      value={products}
      actionRef={actionRef}
      columns={columns({
        t,
        onEditRow,
        onDelRow,
        onDuplicateRow,
        multipleDates,
        readonly,
        defaultUom,
        products,
        locationId,
        eventTime,
      })}
      editable={{
        onSave: (rowKey, data) => onSaveProduct(rowKey, data),
        onCancel: async (_rowKey, data) => {
          actionRef.current?.cancelEditable(data?.id || 0);
        },
      }}
      enableRecordCreator={!readonly}
      options={{
        setting: false,
        reload: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={onAddProduct}
      summary={summary}
      // className={styles.tbl}
      scroll={{ x: !multipleDates ? 1700 : 2000 }}
    />
  );
};
export default React.memo(BatchShipProducts);

import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Space,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useAuthenticationContext } from 'contexts';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useChangeEmail, useUploadProfilePicture, useUserById } from 'services/api';
import { msalResetPasswordPolicy } from 'services/utils';
import { errorHandler, globalStyles, setPathSession } from 'utils';
import styles from '../index.module.less';
import ProfileTable from './ProfileTable';
import { CheckMatching, EmailDataItem } from './typings';

const Profile = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.profile' });
  const queryClient = useQueryClient();
  const [preview, setPreview] = useState(false);
  const { currentUser, signout } = useAuthenticationContext();
  const { data: user } = useUserById(currentUser?.id || '');
  const updateEmail = useChangeEmail(queryClient);
  const uploadProfile = useUploadProfilePicture(queryClient);
  const profilePicture = useMemo(() => {
    const timestamp = new Date().getTime();
    if (!user?.profilePictureUrl) {
      return undefined;
    }
    return `${user?.profilePictureUrl}?t=${timestamp}`;
  }, [user]);

  const [profile, setProfile] = useState({
    firstName: user?.givenName || '',
    lastName: user?.surname || '',
    email: user?.email || '',
    imgUrl: user?.profilePictureUrl ? `${user?.profilePictureUrl}?t=${new Date().getTime()}` : '',
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  useEffect(() => {
    if (user?.profilePictureUrl) {
      setFileList([
        {
          uid: user?.id || '1',
          name: 'profile.png',
          status: 'done',
          url: `${user?.profilePictureUrl}?t=${new Date().getTime()}`,
        },
      ]);
    }
  }, [user]);

  useEffect(() => {
    const profileData = {
      firstName: user?.givenName || '',
      lastName: user?.surname || '',
      email: user?.email || '',
      imgUrl: user?.profilePictureUrl ? `${user?.profilePictureUrl}?t=${new Date().getTime()}` : '',
    };
    setProfile(profileData);
  }, [user]);

  /* reset email */
  const checkMatching = ({ getFieldValue, fieldName, message: msg }: CheckMatching) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue(fieldName) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(msg));
    },
  });
  const [resetEmailForm] = Form.useForm<EmailDataItem>();
  const resetEmailFormRules = {
    initialValues: {
      currentEmail: profile.email,
      newEmail: '',
      confirmNewEmail: '',
    },
    rules: {
      currentEmail: {
        name: 'currentEmail',
        label: t('current_email'),
        placeholder: t('current_email'),
        required: true,
        message: t('current_email_req'),
        wrapperCol: { md: { span: 11 } },
      },
      newEmail: {
        name: 'newEmail',
        label: t('new_email'),
        placeholder: t('new_email'),
        required: true,
        message: t('new_email_req'),
        wrapperCol: { md: { span: 22 } },
        validEmail: {
          type: 'email',
          message: t('new_email_valid'),
        },
      },
      confirmNewEmail: {
        name: 'confirmNewEmail',
        label: t('confirm_new_email'),
        placeholder: t('confirm_new_email'),
        required: true,
        message: t('confirm_new_email_req'),
        wrapperCol: { md: { span: 22 } },
        matchEmail: ({ getFieldValue }: any) =>
          checkMatching({
            getFieldValue,
            fieldName: 'newEmail',
            message: t('confirm_new_email_valid'),
          }),
      },
    },
  };
  const [resetEmailModal, setResetEmailModal] = useState(false);

  const profileImgSecSpan = {
    lg: { span: 6 },
    md: { span: 6 },
  };
  const profileImgSpan = {};
  const profileTitleSpan = {
    lg: { span: 24 },
    md: { span: 24 },
  };
  const profileSpan = {
    lg: { span: 18 },
    md: { span: 18 },
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={globalStyles.mt8}>{t('upload')}</div>
    </div>
  );
  const onUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file } = info;
    const { status } = file;

    if (status === 'done') {
      message.success(`${info.file.name} ${t('upload_sucess')}`);
      if (file?.originFileObj) {
        const blobUrl = URL.createObjectURL(file.originFileObj);
        file.url = blobUrl;
      }
    } else if (status === 'error') {
      message.error(`${info.file.name} ${t('upload_fail')}`);
    }
  };
  const onUpload: UploadProps['customRequest'] = async ({
    file,
    onProgress,
    onSuccess,
    onError,
  }) => {
    const success = message.loading(t('uploading_image'), 0);
    const blobUrl = URL.createObjectURL(file as RcFile);
    try {
      onProgress?.({ percent: 0 });
      setTimeout(() => onProgress?.({ percent: 50 }), 1000);

      onProgress?.({ percent: 100 });
      const fileRes = await uploadProfile.mutateAsync({ picture: file });

      setProfile({ ...profile, imgUrl: String(blobUrl) });
      setFileList([{ uid: fileRes?.id || '1', name: 'profile.png', status: 'done', url: blobUrl }]);
      onSuccess?.(file);
      success();
    } catch (error) {
      success();

      onError?.({
        name: 'upload-error',
        message: errorHandler(error),
      });
    }
  };
  const onPreview = () => {
    setPreview(true);
  };
  const onRemove = async () => {
    try {
      setFileList([]);
      await uploadProfile.mutateAsync({ picture: null });
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const onResetEmail = () => {
    setResetEmailModal(true);
    resetEmailForm.resetFields();
  };
  const onResetPassword = () => {
    Modal.warning({
      title: t('rst_pwd_title'),
      content: t('rst_pwd_desc'),
      okText: t('rst_pwd_submit'),
      cancelText: t('rst_pwd_cancel'),
      onOk: async () => {
        setPathSession('/settings');
        await msalResetPasswordPolicy.loginRedirect({
          scopes: ['openid', 'profile', 'offline_access'],
          extraQueryParameters: {
            isDesktopApp: 'true',
          },
        });
      },
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  };

  const onSubmitResetEmail = async () => {
    /* reset email api */
    try {
      const values = await resetEmailForm.validateFields();
      await updateEmail.mutateAsync({
        body: {
          email: values.newEmail,
        },
      });
      Modal.info({
        title: t('email_redirect_title'),
        content: t('email_redirect_desc'),
        okText: t('continue'),
        cancelButtonProps: {
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: false,
        onOk: () => signout(),
      });
      setResetEmailModal(false);
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <>
      <Modal
        visible={resetEmailModal}
        title={t('reset_email_title')}
        footer={
          <Space>
            <Button onClick={() => setResetEmailModal(false)} type="primary" ghost shape="round">
              {t('cancel')}
            </Button>
            <Button onClick={onSubmitResetEmail} type="primary" shape="round">
              {t('reset_email')}
            </Button>
          </Space>
        }
        onCancel={() => setResetEmailModal(false)}
        width="58%"
        centered
      >
        <Form
          name="reset_email"
          initialValues={resetEmailFormRules.initialValues}
          layout="vertical"
          form={resetEmailForm}
        >
          <Form.Item
            wrapperCol={resetEmailFormRules.rules.currentEmail.wrapperCol}
            name={resetEmailFormRules.rules.currentEmail.name}
            label={resetEmailFormRules.rules.currentEmail.label}
            rules={[resetEmailFormRules.rules.currentEmail]}
          >
            <Input
              placeholder={resetEmailFormRules.rules.currentEmail.placeholder}
              className="b-r10"
              disabled
            />
          </Form.Item>
          <Row>
            <Col {...{ md: { span: 12 } }}>
              <Form.Item
                wrapperCol={resetEmailFormRules.rules.newEmail.wrapperCol}
                name={resetEmailFormRules.rules.newEmail.name}
                label={resetEmailFormRules.rules.newEmail.label}
                rules={[
                  Object(resetEmailFormRules.rules.newEmail.validEmail),
                  resetEmailFormRules.rules.newEmail,
                ]}
              >
                <Input
                  placeholder={resetEmailFormRules.rules.newEmail.placeholder}
                  className="b-r10"
                />
              </Form.Item>
            </Col>
            <Col {...{ md: { span: 12 } }}>
              <Form.Item
                wrapperCol={resetEmailFormRules.rules.confirmNewEmail.wrapperCol}
                name={resetEmailFormRules.rules.confirmNewEmail.name}
                label={resetEmailFormRules.rules.confirmNewEmail.label}
                rules={[
                  resetEmailFormRules.rules.confirmNewEmail,
                  resetEmailFormRules.rules.confirmNewEmail.matchEmail,
                ]}
              >
                <Input
                  placeholder={resetEmailFormRules.rules.confirmNewEmail.placeholder}
                  className="b-r10"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <PageHeader title={t('title')} className={styles['page-header']} />
      <Image
        className={styles.imagepreview}
        src={profilePicture}
        preview={{
          visible: preview,
          src: profilePicture,
          onVisibleChange: (value) => {
            setPreview(value);
          },
        }}
      />
      <Row>
        <Col {...profileImgSecSpan}>
          <Row justify="center" align="middle">
            <Col {...profileImgSpan}>
              <ImgCrop
                rotationSlider
                modalTitle={t('edit_image')}
                modalOk={t('ok')}
                modalCancel={t('cancel')}
              >
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={onPreview}
                  onRemove={onRemove}
                  customRequest={onUpload}
                  onChange={onUploadChange}
                  maxCount={1}
                  className={styles['profile-upload']}
                >
                  {fileList.length > 0 ? null : uploadButton}
                </Upload>
              </ImgCrop>
            </Col>
            <Col {...profileTitleSpan}>
              <Typography.Title level={5} className="profile-img-title">
                {user?.givenName || ''} {user?.surname || ''}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
        <Col {...profileSpan}>
          <ProfileTable onResetEmail={onResetEmail} onResetPassword={onResetPassword} />
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Profile);

import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ProCard from '@ant-design/pro-card';
import { DatePicker, DatePickerProps, Space, Tooltip, Typography } from 'antd';
import moment from 'moment';
import LineChart from 'pages/Network/components/ShipmentContainer/LineChart';
import React, { FC, useState } from 'react';
import { useShipmentsSummary } from 'services/api';
import { GetShipmentsSummaryOptionalParams } from 'services/api/client/src';
import styles from './index.module.less';
import { ShipmentGraphProps } from './typings';

const ShipmentGraph: FC<ShipmentGraphProps> = ({ t }) => {
  const [params, setParams] = useState<GetShipmentsSummaryOptionalParams>({
    startDate: new Date(`${new Date().getFullYear()}`),
    endDate: new Date(`${new Date().getFullYear() + 1}`),
  });
  const { data, isLoading } = useShipmentsSummary(params);

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setParams({
      startDate: new Date(dateString),
      endDate: new Date(`${Number(dateString) + 1}`),
    });
  };

  return (
    <ProCard
      title={
        <Space>
          {t('shipments.title')}
          <Tooltip title={t('shipments.tooltip')}>
            <Typography.Text type="secondary">
              <InfoCircleOutlined />
            </Typography.Text>
          </Tooltip>
          <DatePicker
            className={styles.datepicker}
            value={moment.utc(params?.startDate)}
            picker="year"
            bordered={false}
            onChange={onChangeDate}
            allowClear={false}
            suffixIcon={<DownOutlined className={styles.calicon} />}
          />
        </Space>
      }
      loading={isLoading}
      className={styles.gcardstyle}
    >
      <LineChart data={data} />
    </ProCard>
  );
};
export default React.memo(ShipmentGraph);

import G6 from '@antv/g6';
import { EventNodeStub, ProductResponse } from 'services/api/client/src';
import { copyIcon, flattenAddress, getEventsT } from 'utils';
import { fittingString, getNodeConfig } from './methods';
import { ExpandNode } from './typings';

const NODE_TYPE = 'modelRect';
const createComplexNodes = (
  nodeBasicMethod: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  product?: ProductResponse,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  expandedNode?: ExpandNode,
) => {
  G6.registerNode(
    NODE_TYPE,
    {
      drawShape: function drawShape(cfg: any, group: any) {
        if (cfg) {
          const config = getNodeConfig(cfg);
          const isRoot = cfg.type === 'root';
          const data = cfg;
          const { nodeError } = data;
          let container;
          if (!data.expand) {
            container = nodeBasicMethod.createNodeBox(group, config, 243, 64, isRoot);
            const eventData = data?.eventData as EventNodeStub;
            if (data.eventType) {
              const isSerial =
                eventData?.products?.[0]?.productIdentifierType === 'Serial' ||
                eventData?.outputProducts?.[0]?.productIdentifierType === 'Serial' ||
                false;
              const containerl = eventData?.container || eventData?.containers?.[0];
              const isContainer =
                !!containerl?.identifier || (eventData?.container?.products?.length || 0) > 0;
              const quantity = data?.quantity || '-';
              const quantityText = `(${quantity} ${data?.unitOfMeasure || ''})`;
              const quantityTextStr = isSerial || isContainer ? '' : quantityText;

              const nameText = `${getEventsT(data.eventType)} ${quantityTextStr}`;
              /* Type above */
              group.addShape('text', {
                attrs: {
                  text: nameText,
                  x: 3,
                  y: -10,
                  fontSize: 14,
                  textAlign: 'left',
                  textBaseline: 'middle',
                  fill: 'rgba(0,0,0,0.65)',
                },
                name: 'type-text-event',
              });
            }

            let ipWidth = 0;
            if (data.eventTime) {
              /* eventTime start */
              /* eventTimeBox */
              const ipRect = group.addShape('rect', {
                attrs: {
                  fill: nodeError ? null : '#FFF',
                  stroke: nodeError ? 'rgba(255,255,255,0.65)' : null,
                  radius: 2,
                  cursor: 'pointer',
                },
                name: 'ip-container-shape',
              });

              /* eventTime */
              const ipText = group.addShape('text', {
                attrs: {
                  text: data.eventTime,
                  x: 0,
                  y: 19,
                  fontSize: 12,
                  textAlign: 'left',
                  textBaseline: 'middle',
                  fill: nodeError ? 'rgba(255,255,255,0.85)' : 'rgba(0,0,0,0.65)',
                  cursor: 'pointer',
                },
                name: 'ip-text-shape',
              });

              const ipBBox = ipText.getBBox();
              /* ip text is always 12px from the right */
              ipText.attr({
                x: 224 - 12 - ipBBox.width,
              });
              /* ipBox */
              ipRect.attr({
                x: 224 - 12 - ipBBox.width - 4,
                y: ipBBox.minY - 5,
                width: ipBBox.width + 8,
                height: ipBBox.height + 10,
              });

              /* Overlay a layer of transparency on the IP element to facilitate listening for hover events */
              group.addShape('rect', {
                attrs: {
                  stroke: '',
                  cursor: 'pointer',
                  x: 224 - 12 - ipBBox.width - 4,
                  y: ipBBox.minY - 5,
                  width: ipBBox.width + 8,
                  height: ipBBox.height + 10,
                  fill: '#fff',
                  opacity: 0,
                },
                name: 'ip-box',
              });

              /* copyIpLine */
              group.addShape('rect', {
                attrs: {
                  x: 194,
                  y: 7,
                  width: 1,
                  height: 24,
                  fill: '#E3E6E8',
                  opacity: 0,
                },
                name: 'ip-cp-line',
              });
              /* copyIpBG */
              group.addShape('rect', {
                attrs: {
                  x: 195,
                  y: 8,
                  width: 22,
                  height: 22,
                  fill: '#FFF',
                  cursor: 'pointer',
                  opacity: 0,
                },
                name: 'ip-cp-bg',
              });
              /* copyIpIcon */
              group.addShape('image', {
                attrs: {
                  x: 200,
                  y: 13,
                  height: 12,
                  width: 10,
                  img: copyIcon,
                  cursor: 'pointer',
                  opacity: 0,
                },
                name: 'ip-cp-icon',
              });
              /* Place a transparent rectangle on the icon area for easy click monitoring */
              group.addShape('rect', {
                attrs: {
                  x: 195,
                  y: 8,
                  width: 22,
                  height: 22,
                  fill: '#FFF',
                  cursor: 'pointer',
                  opacity: 0,
                },
                name: 'ip-cp-box',
                tooltip: '复制IP',
              });

              const ipRectBBox = ipRect.getBBox();
              ipWidth = ipRectBBox.width;
              // /* ip end */
            }

            /* Product Name */
            group.addShape('text', {
              attrs: {
                /* Calculate the remaining length for name based on the length of the IP! */
                text: fittingString(data?.name, 224 - ipWidth - 40, 12), // data.products.name,
                x: 19,
                y: 19,
                fontSize: 14,
                fontWeight: 700,
                textAlign: 'left',
                textBaseline: 'middle',
                fill: config.fontColor,
                cursor: 'pointer',
              },
              name: 'name-text-shape',
              tooltip: data?.name,
            });

            /* Location Name */
            const flattenedadress = flattenAddress(data.location?.address);
            const location = fittingString(flattenedadress || data.location?.name || '', 204, 12);
            group.addShape('text', {
              attrs: {
                text: location,
                x: 19,
                y: 45,
                fontSize: 14,
                textAlign: 'left',
                textBaseline: 'middle',
                fill: config.fontColor,
                cursor: 'pointer',
              },
              name: 'bottom-text-shape',
            });
            /* node error */
            if (nodeError) {
              group.addShape('image', {
                attrs: {
                  x: 191,
                  y: 32,
                  height: 35,
                  width: 35,
                  img: '/static/images/warning-circle.svg',
                },
                name: 'image-shape',
              });
            }
          } else {
            /* expand / collapse box */
            container = group.addShape('rect', {
              attrs: {
                x: 0,
                y: 0,
                width: 130,
                height: 40,
                fill: '#3F51B5',
                stroke: '#3F51B5',
                radius: 20,
                cursor: 'pointer',
              },
              name: 'container-rect-shape',
            });

            group.addShape('text', {
              attrs: {
                text: data?.collapsed ? '+' : '-',
                x: 10,
                y: 20,
                fontSize: 24,
                fontWeight: 700,
                textAlign: 'left',
                textBaseline: 'middle',
                fill: '#E7F0F2',
                cursor: 'pointer',
              },
              name: 'sign-shape',
            });
            group.addShape('text', {
              attrs: {
                text: data?.name,
                x: 25,
                y: 21,
                fontSize: 22,
                fontWeight: 700,
                textAlign: 'left',
                textBaseline: 'middle',
                fill: '#FFFFFF',
                cursor: 'pointer',
              },
              name: 'name_text_shape',
            });
            if (nodeError) {
              group.addShape('image', {
                attrs: {
                  x: 191,
                  y: 32,
                  height: 35,
                  width: 35,
                  img: '/static/images/warning-circle.svg',
                },
                name: 'image-shape',
              });
            }
          }

          // const hasChildren = cfg.children && cfg.children.length > 0;
          // if (hasChildren) {
          //   nodeBasicMethod.createNodeMarker(group, cfg.collapsed, 236, 32);
          // }
          return container;
        }
        return nodeBasicMethod.createNodeBox(
          group,
          {
            basicColor: '#722ED1',
            fontColor: '#722ED1',
            borderColor: '#722ED1',
            bgColor: '#F6EDFC',
          },
          171,
          38,
          false,
        );
      },
    },
    'single-node',
  );
};

export default createComplexNodes;

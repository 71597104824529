import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  CountInboundShipmentsOptionalParams,
  CountOutboundShipmentsOptionalParams,
  DirectReceiveOptionalParams,
  DirectReceiveResponse,
  GetInboundShipmentDetailsOptionalParams,
  GetOtherSystemShipmentByIdOptionalParams,
  GetOtherSystemShipmentByIdResponse,
  GetOutboundShipmentDetailsOptionalParams,
  GetPaginatedInboundShipmentsOptionalParams,
  GetPaginatedInboundShipmentsResponse,
  GetPaginatedOtherSystemShipmentsOptionalParams,
  GetPaginatedOtherSystemShipmentsResponse,
  GetPaginatedOutboundShipmentsOptionalParams,
  GetPaginatedOutboundShipmentsResponse,
  GetShipEventByIdOptionalParams,
  GetShipEventByIdResponse,
  GetShipmentDiagramOperationResponse,
  GetShipmentDiagramOptionalParams,
  GetShipmentMapOptionalParams,
  GetShipmentMapResponse,
  ListShipmentDetailsOptionalParams,
  ListShipmentDetailsResponse,
  ShipmentDetailsResponse,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

export interface GetInboundShipmentsParams extends GetInboundShipmentDetailsOptionalParams {
  enabled?: boolean;
}

export const listInboundShipments = (
  options?: GetPaginatedInboundShipmentsOptionalParams,
): Promise<GetPaginatedInboundShipmentsResponse> =>
  HttpClient.getPaginatedInboundShipments(options);

export const useInboundShipments = (params?: GetPaginatedInboundShipmentsOptionalParams) =>
  useQuery(['ListInboundShipments', params], () => listInboundShipments(params));

export const useInboundListShipMutate = () =>
  useMutation(({ options }: { options: GetPaginatedInboundShipmentsOptionalParams }) =>
    listInboundShipments(options),
  );

export const listInboundOtherSystemShipments = (
  options?: GetPaginatedOtherSystemShipmentsOptionalParams,
): Promise<GetPaginatedOtherSystemShipmentsResponse> =>
  HttpClient.getPaginatedOtherSystemShipments(options);

export const useInboundOtherSystemShipments = (
  params?: GetPaginatedOtherSystemShipmentsOptionalParams,
) => useQuery(['ListInboundOtherShipments', params], () => listInboundOtherSystemShipments(params));

export const useInboundOtherSystemListShipMutate = () =>
  useMutation(({ options }: { options: GetPaginatedOtherSystemShipmentsOptionalParams }) =>
    listInboundOtherSystemShipments(options),
  );

export const listInboundDetailsShipments = (
  shipmentIds: string[],
  options?: GetInboundShipmentDetailsOptionalParams,
): Promise<ShipmentDetailsResponse[]> => HttpClient.getInboundShipmentDetails(shipmentIds, options);

export const useInboundDetailsShipments = (
  shipmentIds: string[],
  params?: GetInboundShipmentsParams,
) =>
  useQuery(
    ['InboundDetailsShipments', ...shipmentIds, params],
    () => listInboundDetailsShipments(shipmentIds, params),
    {
      enabled: params?.enabled,
    },
  );

export const useInboundDetailsListShipMutate = () =>
  useMutation((shipmentIds: string[], options?: GetInboundShipmentDetailsOptionalParams) =>
    listInboundDetailsShipments(shipmentIds, options),
  );

export const listOutboundShipments = (
  options?: GetPaginatedOutboundShipmentsOptionalParams,
): Promise<GetPaginatedOutboundShipmentsResponse> =>
  HttpClient.getPaginatedOutboundShipments(options);

export const useOutboundShipments = (params?: GetPaginatedOutboundShipmentsOptionalParams) =>
  useQuery(['OutboundShipments', params], () => listOutboundShipments(params));

export const useOutboundListShipMutate = () =>
  useMutation(({ options }: { options: GetPaginatedOutboundShipmentsOptionalParams }) =>
    listOutboundShipments(options),
  );

export const listOutboundOtherSystemShipments = (
  options?: GetPaginatedOtherSystemShipmentsOptionalParams,
): Promise<GetPaginatedOtherSystemShipmentsResponse> =>
  HttpClient.getPaginatedOtherSystemShipments(options);

export const useOutboundOtherSystemShipments = (
  params?: GetPaginatedOtherSystemShipmentsOptionalParams,
) =>
  useQuery(['ListOutboundOtherShipments', params], () => listOutboundOtherSystemShipments(params));

export const useOutboundOtherSystemListShipMutate = () =>
  useMutation(({ options }: { options: GetPaginatedOtherSystemShipmentsOptionalParams }) =>
    listOutboundOtherSystemShipments(options),
  );

export const listOutboundDetailsShipments = (
  shipmentIds: string[],
  options?: GetOutboundShipmentDetailsOptionalParams,
): Promise<ShipmentDetailsResponse[]> =>
  HttpClient.getOutboundShipmentDetails(shipmentIds, options);

export const useOutboundDetailsShipments = (
  shipmentIds: string[],
  params?: GetOutboundShipmentDetailsOptionalParams,
) =>
  useQuery(['OutboundDetailsShipments', ...shipmentIds, params], () =>
    listOutboundDetailsShipments(shipmentIds, params),
  );

export const useOutboundDetailsListShipMutate = () =>
  useMutation((shipmentIds: string[], options?: GetOutboundShipmentDetailsOptionalParams) =>
    listOutboundDetailsShipments(shipmentIds, options),
  );

/* getShipEventById */

export const listShipEventByIdShipments = (
  shipmentIds: string,
  options?: GetShipEventByIdOptionalParams,
): Promise<GetShipEventByIdResponse> | undefined =>
  shipmentIds ? HttpClient.getShipEventById(shipmentIds, options) : undefined;

export const useShipEventByIdShipments = (
  shipmentIds: string,
  params?: GetShipEventByIdOptionalParams,
) =>
  useQuery(['shipEventById', shipmentIds, params], () =>
    listShipEventByIdShipments(shipmentIds, params),
  );

export const useShipEventByIdShipmentsMutate = () =>
  useMutation(({ shipmentIds }: { shipmentIds: string }) =>
    HttpClient.getShipEventById(shipmentIds),
  );

/* listShipmentDetails */

export const listShipmentDetailsShipments = (
  eventId: string,
  options?: ListShipmentDetailsOptionalParams,
): Promise<ListShipmentDetailsResponse> => HttpClient.listShipmentDetails(eventId, options);

export const useListShipmentDetailsShipments = (
  eventId: string,
  params?: ListShipmentDetailsOptionalParams,
) =>
  useQuery(['ListShipmentDetails', eventId, params], () =>
    listShipmentDetailsShipments(eventId, params),
  );

/* getShipmentDiagram */

export const listShipmentDiagramShipments = (
  eventId: string,
  options?: GetShipmentDiagramOptionalParams,
): Promise<GetShipmentDiagramOperationResponse> => HttpClient.getShipmentDiagram(eventId, options);

export const useShipmentDiagramShipments = (
  eventId: string,
  params?: GetShipmentDiagramOptionalParams,
) =>
  useQuery(['ShipmentDiagram', eventId, params], () =>
    listShipmentDiagramShipments(eventId, params),
  );

export const useShipmentDiagramListShipMutate = () =>
  useMutation((eventId: string, options?: GetShipmentDiagramOptionalParams) =>
    listShipmentDiagramShipments(eventId, options),
  );

/* getShipmentMap */

export const listShipmentMapShipments = (
  eventId: string,
  options?: GetShipmentMapOptionalParams,
): Promise<GetShipmentMapResponse> => HttpClient.getShipmentMap(eventId, options);

export const useShipmentMapShipments = (eventId: string, params?: GetShipmentMapOptionalParams) =>
  useQuery(['ShipmentMap', eventId, params], () => listShipmentMapShipments(eventId, params));

export const useShipmentMapListShipMutate = () =>
  useMutation((eventId: string, options?: GetShipmentMapOptionalParams) =>
    listShipmentMapShipments(eventId, options),
  );

const getOtherSystemShipmentById = (
  shipmentIds: string[],
  options?: GetOtherSystemShipmentByIdOptionalParams,
): Promise<GetOtherSystemShipmentByIdResponse> =>
  HttpClient?.getOtherSystemShipmentById(shipmentIds, options);

export const useGetExternalShipmentDetailsById = (
  shipmentIds: string[],
  options?: GetOtherSystemShipmentByIdOptionalParams,
  enabled?: boolean,
) =>
  useQuery(
    ['getOtherSystemShipmentById', shipmentIds, options, enabled],
    () => getOtherSystemShipmentById(shipmentIds, options),
    {
      enabled,
    },
  );

export const useGetExternalShipmentDetailsByIdMutate = () =>
  useMutation(
    ({
      shipmentIds,
      options,
    }: {
      shipmentIds: string[];
      options?: GetOtherSystemShipmentByIdOptionalParams;
    }) => HttpClient.getOtherSystemShipmentById(shipmentIds, options),
  );

export const CountInboundShipments = (
  options?: CountInboundShipmentsOptionalParams,
): Promise<any> => HttpClient.countInboundShipments(options);

export const useCountInboundShipmentsShipments = (params?: CountInboundShipmentsOptionalParams) =>
  useQuery(['CountInboundShipments', params], () => CountInboundShipments(params));

export const useCountInboundShipmentsListShipMutate = () =>
  useMutation((options?: CountInboundShipmentsOptionalParams) => CountInboundShipments(options));

export const CountOutboundShipments = (
  options?: CountOutboundShipmentsOptionalParams,
): Promise<any> => HttpClient.countOutboundShipments(options);

export const useCountOutboundShipmentsShipments = (params?: CountOutboundShipmentsOptionalParams) =>
  useQuery(['CountOutboundShipments', params], () => CountOutboundShipments(params));

export const useCountOutboundShipmentsListShipMutate = () =>
  useMutation((options?: CountOutboundShipmentsOptionalParams) => CountOutboundShipments(options));
const directReceive = (options?: DirectReceiveOptionalParams): Promise<DirectReceiveResponse> =>
  HttpClient.directReceive(options);

export const useDirectReceiveMutate = (queryClient: QueryClient) =>
  useMutation(({ options }: { options: DirectReceiveOptionalParams }) => directReceive(options), {
    onSuccess: () => {
      queryClient.invalidateQueries('shipments');
      queryClient.invalidateQueries(['infiniteShipments']);
      queryClient.invalidateQueries('externalShipments');
      queryClient.invalidateQueries('productInstance');
      queryClient.invalidateQueries('containerInstances');
      queryClient.invalidateQueries('productInstances');
      queryClient.invalidateQueries('productInstanceQuantities');
      queryClient.refetchQueries(['notifications']);
      queryClient.invalidateQueries('product');
      queryClient.invalidateQueries('products');
      queryClient.invalidateQueries(['subProducts']);
      queryClient.invalidateQueries(['shipments_summary']);
      queryClient.invalidateQueries('poSearchResults');
    },
  });

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { useTranslation } from 'react-i18next';
import { ActionsProps } from './typings';

const Actions = <ActionPayloadType extends {}>({
  actionPayload,
  buttonProps,
  menuProps,
  className,
  items,
  onClick,
  trigger,
  showIcon = true,
  disabled,
  actionsText,
}: ActionsProps<ActionPayloadType>) => {
  const { t } = useTranslation('pages', { keyPrefix: 'options' });
  if ((items?.length || 0) <= 0) {
    return null;
  }

  const onItemClick: MenuClickEventHandler = ({ key }) => {
    if (onClick) {
      onClick(key, actionPayload);
    }
  };

  return (
    <Dropdown
      className={className}
      overlay={<Menu {...menuProps} onClick={onItemClick} items={items} />}
      placement="bottomRight"
      trigger={trigger}
      disabled={disabled}
    >
      <Button type="primary" shape="round" size="middle" {...buttonProps}>
        {actionsText || t('action_text')}
        {showIcon && <DownOutlined />}
      </Button>
    </Dropdown>
  );
};

export default Actions;

import { EditOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Button, ButtonProps, Tooltip, message } from 'antd';
import GTable from 'components/GTable';
import { useAuthenticationContext } from 'contexts';
import { FC, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useChangePersonalInfo, useUserById } from 'services/api';
import { ChangePersonalInfoRequest } from 'services/api/client/src';
import { errorHandler } from 'utils';
import styles from '../index.module.less';
import { ActionProps, ProfileAttribute, ProfileTableProps } from './typings';

const defaultBtnProps: ButtonProps = {
  size: 'small',
  ghost: false,
  type: 'text',
  className: 'value-actions-btn',
};

type ColumnsType = {
  onEditRow: (actionPayload?: ProfileAttribute) => void;
  onResetEmail: (actionPayload?: ProfileAttribute) => void;
  onResetPassword: (actionPayload?: ProfileAttribute) => void;
  t?: TFunction<'pages', 'settings.profile'>;
};
const Actions: FC<ActionProps> = ({ onEditRow, onResetEmail, onResetPassword, record, t }) => {
  switch (record?.id) {
    case 'firstName':
      return (
        <Tooltip title={t?.('edit')} placement="bottom">
          <Button {...defaultBtnProps} className="actions" onClick={() => onEditRow(record)}>
            <EditOutlined className={styles.btnicon} />
          </Button>
        </Tooltip>
      );
    case 'lastName':
      return (
        <Tooltip title={t?.('edit')} placement="bottom">
          <Button {...defaultBtnProps} className="actions" onClick={() => onEditRow(record)}>
            <EditOutlined className={styles.btnicon} />
          </Button>
        </Tooltip>
      );
    case 'email':
      return (
        <Button
          type="primary"
          shape="round"
          size="small"
          ghost
          className="actions"
          onClick={() => onResetEmail(record)}
        >
          {t?.('reset_email')}
        </Button>
      );
    case 'password':
      return (
        <Button
          type="primary"
          shape="round"
          size="small"
          ghost
          className="actions"
          onClick={() => onResetPassword(record)}
        >
          {t?.('reset_password')}
        </Button>
      );

    default:
      return null;
  }
};
const Columns = ({
  onEditRow,
  onResetEmail,
  onResetPassword,
  t,
}: ColumnsType): Array<ProColumns<ProfileAttribute>> => [
  {
    title: t?.('id'),
    dataIndex: 'id',
    hideInTable: true,
  },
  {
    title: t?.('attribute'),
    dataIndex: 'attribute',
    className: 'attribute-cell',
    editable: false,
    width: '29%',
  },
  {
    title: t?.('value'),
    dataIndex: 'value',
    fieldProps: {
      size: 'small',
      placeholder: t?.('please_enter'),
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('please_enter_req'),
        },
        {
          whitespace: true,
          message: t?.('please_enter_req'),
        },
      ],
    },
    width: '52%',
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    className: 'right-aligned-cell',
    width: '18%',
    render: (_, record) => (
      <Actions
        record={record}
        onEditRow={onEditRow}
        onResetEmail={onResetEmail}
        onResetPassword={onResetPassword}
        t={t}
      />
    ),
  },
];
const ProfileTable: FC<ProfileTableProps> = ({ onResetEmail, onResetPassword }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.profile' });
  const actionRef = useRef<ActionType>();
  const { currentUser } = useAuthenticationContext();
  const queryClient = useQueryClient();
  const updateUser = useChangePersonalInfo(queryClient);
  const { data: user, isLoading } = useUserById(currentUser?.id || '1');
  const data: Array<ProfileAttribute> = [
    {
      id: 'firstName',
      attribute: t('first_name'),
      value: user?.givenName || '',
    },
    {
      id: 'lastName',
      attribute: t('last_name'),
      value: user?.surname || '',
    },
    {
      id: 'email',
      attribute: t('email'),
      value: user?.email || '',
    },
    {
      id: 'password',
      attribute: t('password'),
      value: '••••••••••••••••',
    },
  ];
  const onEditRow = (actionPayload?: ProfileAttribute) => {
    if (actionPayload?.id === 'firstName' || actionPayload?.id === 'lastName') {
      actionRef.current?.startEditable(actionPayload?.id || '');
    }
  };
  const onSaveRow = async (rowKey: RecordKey, actionPayload: ProfileAttribute) => {
    try {
      const reqData: ChangePersonalInfoRequest = {
        givenName: rowKey === 'firstName' ? actionPayload.value : undefined,
        surname: rowKey === 'lastName' ? actionPayload.value : undefined,
      };
      await updateUser.mutateAsync({
        body: {
          ...reqData,
        },
      });
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  return (
    <GTable<ProfileAttribute>
      actionRef={actionRef}
      columns={Columns({ onEditRow, onResetEmail, onResetPassword, t })}
      editable={{
        onSave: (rowKey, actionPayload) => onSaveRow(rowKey, actionPayload),
        onCancel: async (_rowKey, actionPayload) => {
          actionRef.current?.cancelEditable(actionPayload?.id || 0);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: true,
        cancel: true,
      }}
      recordCreatorProps={false}
      loading={isLoading}
      value={data}
      showHeader={false}
    />
  );
};
export default ProfileTable;

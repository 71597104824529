import { Button, Image, Layout, Typography } from 'antd';
import { expiredInviteSvg } from 'assets';
import { Loading } from 'components';
import GenLayout from 'components/GenLayout';
import { signinRequest } from 'config/authConfig';
import { useAuthenticationContext } from 'contexts';
import { useModalVisibility } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useValidateInvite } from 'services/api';
import { msalInstance } from 'services/utils/security';
import { initLanguageSelection, setExistingUserSession, setInviteIdSession } from 'utils';
import NewInviteRequestModal from './NewInviteRequestModal';
import styles from './index.module.less';

const RedeemInvite = () => {
  const { setIsAPIAuthenticated, setIsAuthProcess } = useAuthenticationContext();
  const [queryParams] = useSearchParams();
  const inviteId = queryParams?.get('id') || '';
  const { t } = useTranslation('pages', { keyPrefix: 'redeem_invite' });
  const { data: validateInvite, isLoading } = useValidateInvite(inviteId || '');
  const userExists = queryParams?.get('existingUser') || 'false';
  const [loading, setLoading] = useState<boolean>(true);
  const contactUsModal = useModalVisibility(false);
  const onContinue = useCallback(() => {
    // navigate to b2c signup page
    msalInstance.loginRedirect({
      scopes: ['openid', 'profile', 'offline_access'],
      extraQueryParameters: {
        isWebApp: 'true',
        isDesktopApp: 'true',
        isReedemInvite: 'true',
        id: inviteId,
      },
    });
  }, [inviteId]);

  const onUpgrade = useCallback(() => contactUsModal?.show(), [contactUsModal]);

  useEffect(() => {
    initLanguageSelection();

    setIsAPIAuthenticated(true);
    setIsAuthProcess(false);
    setInviteIdSession(inviteId);
    if (userExists === 'true' && validateInvite?.isValid) {
      setExistingUserSession('true');
      msalInstance.loginRedirect(signinRequest);
    }

    setLoading(false);
  }, [
    inviteId,
    onContinue,
    setIsAPIAuthenticated,
    setIsAuthProcess,
    userExists,
    validateInvite?.isValid,
  ]);

  if (loading || isLoading) return <Loading />;

  return validateInvite?.isValid ? (
    <GenLayout>
      <Layout.Content className={styles.container}>
        <div className={styles.cnt}>
          <Typography.Title level={2}>{t('title')}</Typography.Title>
          <div className={styles.subtitle}>
            <Typography.Text>{t('subtitle')}</Typography.Text>
          </div>
          <div className={styles.description}>
            <Typography.Text>{t('description')}</Typography.Text>
          </div>
          <Button shape="round" type="primary" onClick={onContinue}>
            {t('submit_btn_title')}
          </Button>
        </div>
      </Layout.Content>
    </GenLayout>
  ) : (
    <>
      <NewInviteRequestModal modal={contactUsModal} tkey="redeem_invite." inviteId={inviteId} />
      <GenLayout>
        <Layout.Content className={styles.container}>
          <div className={styles.cnt}>
            <Image src={expiredInviteSvg} className={styles.margin} />
            <Typography.Title level={2}>{t('expired.title')}</Typography.Title>
            <div className={styles.singledescription}>
              <Typography.Text>{t('expired.subtitle')}</Typography.Text>
            </div>
            <Button shape="round" type="primary" onClick={onUpgrade}>
              {t('expired.submit_btn_title')}
            </Button>
          </div>
        </Layout.Content>
      </GenLayout>
    </>
  );
};
export default RedeemInvite;

import { ActionType } from '@ant-design/pro-table';
import { EditableProTableProps } from '@ant-design/pro-table/lib/components/EditableTable';
import { Button, Tag } from 'antd';
import GTable from 'components/GTable';
import { useModalVisibility } from 'hooks';
import { useEventActions } from 'pages/Events/components';
import { useEventsStore } from 'pages/Events/hooks';
import { InvColumnFunction, InvColumnType, ProductInventoryItem } from 'pages/Products/typings';
import useProductInstanceFilters from 'pages/Products/useProductInstanceFilters';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, ReactText, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { GetPaginatedProductInventoryOptionalParams } from 'services/api/client/src';
import { dateLocaleFormat, getEventsT, getProductsT, getUom } from 'utils';
import EventDetails from './EventDetails';
import { EventTableProps } from './typings';

/**
 * Generates an array of columns for the inventory table based on the provided parameters.
 * @param t The translation function.
 * @param onRowActionClick Function to handle row action clicks.
 * @param setInventoryItem Function to set the inventory item.
 * @param primaryIdSearchfilter Search filter for the primary ID column.
 * @param poSearchfilter Search filter for the purchase order column.
 * @param selectedRowKeys Selected row keys.
 * @param defaultUom Default unit of measure.
 * @param isSerial Indicates whether the inventory item is serial.
 * @param coreLocations Array of core locations for filtering.
 * @param coreEventTypes Array of core event types for filtering.
 * @returns An array of columns for the inventory table.
 */
const columns: InvColumnFunction = ({
  t,
  onViewEvent,
  primaryIdSearchfilter,
  defaultUom,
  isSerial,
  coreLocations,
  coreEventTypes,
}) => [
  {
    title: t('tbl_col_primary_id'),
    dataIndex: 'primaryId',
    fixed: 'left',
    hideInSetting: true,
    render: (text, record) => {
      const name = !record?.isContainer
        ? `${getProductsT(record.productIdentifierType)}: ${record?.lotSerial || ''}`
        : `${getProductsT('sscc')}: ${record?.containerIdentifier || ''}`;

      return record?.id ? (
        <Link
          className="text-link"
          to={{
            pathname: '',
          }}
          onClick={() => {
            onViewEvent?.(record);
          }}
        >
          {name}
        </Link>
      ) : (
        name
      );
    },
    ellipsis: true,
    width: 200,
    ...primaryIdSearchfilter,
  },
  {
    title: t('tbl_col_last_event_name'),
    dataIndex: 'eventName',
    width: 170,
    ellipsis: true,
    filters: coreEventTypes,
    render: (text, record) =>
      record?.eventName ? (
        <Tag className="blue-tag">{`${getEventsT(record?.eventName) || ''}`}</Tag>
      ) : (
        '-'
      ),
  },
  {
    title: t('tbl_col_date'),
    dataIndex: 'date',
    sorter: true,
    ellipsis: true,
    width: 150,
  },
  {
    title: t('tbl_col_location'),
    dataIndex: 'locationAddress',
    width: '30vw',
    filters: coreLocations,
    filterSearch: true,
    ellipsis: true,
  },
  {
    title: t('tbl_col_quantity'),
    dataIndex: 'quantity',
    width: 180,
    ellipsis: true,
    hideInTable: isSerial || false,
    render: (text, record) =>
      `${record?.quantity || ''} ${
        getUom({
          product: record,
        }) || defaultUom
      }`,
  },
  {
    title: t('tbl_col_shipped_to'),
    dataIndex: 'shippedTo',
    width: '30vw',
    ellipsis: true,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: 120,
    hideInSetting: true,
    fixed: 'right',
    render: (text, record) =>
      record?.id ? (
        <Button
          className="actions"
          onClick={() => onViewEvent?.(record)}
          type="primary"
          size="small"
        >
          {t('actions.view_details')}
        </Button>
      ) : undefined,
  },
];
const EventTable: FC<EventTableProps> = ({ productId }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.current_inventory' });
  const { setTemplate, setDataEntryMethod, setMultipleDates } = useEventsStore();
  const infoModal = useModalVisibility(false);
  const { inventoryItem, setInventoryItem } = useShipmentStore();
  const { purchaseOrder = '' } = useParams();
  const actionRef = useRef<ActionType>();
  const { defaultUom, identifier, isSerial } = useEventActions({
    productId,
  });
  const {
    params,
    setParams,
    isLoading,
    records,
    coreLocations,
    coreEventTypes,
    filters: [primaryIdSearchfilter, poSearchfilter],
    getSortField,
  } = useProductInstanceFilters(productId, {
    sortBy: '-loggedDate',
    purchaseOrder,
    pageSize: 9999,
  });

  const recordList: ProductInventoryItem[] = useMemo(
    () =>
      records?.map((item) => ({
        ...item,
        date: `${item?.eventDate ? dateLocaleFormat(item?.eventDate) : '-'}`,
        shippedTo: item?.shipped ? `${item?.destinationName} - ${item?.destinationAddress}` : '-',
      })),
    [records],
  );
  const onViewEvent: InvColumnType['onViewEvent'] = (actionPayload) => {
    setInventoryItem(actionPayload as ProductInventoryItem);
    setTemplate(undefined);
    setDataEntryMethod('manual');
    setMultipleDates(false);
    infoModal?.show();
  };

  const onTableChange: EditableProTableProps<ProductInventoryItem, ReactText[]>['onTableChange'] = (
    pagination,
    filters,
    sorter: any,
  ) => {
    const apiFilters: GetPaginatedProductInventoryOptionalParams = {
      locationIds: filters?.locationAddress?.length
        ? (filters.locationAddress as string[])
        : undefined,
      // @ts-ignore
      eventTypes: filters?.eventName?.length ? (filters.eventName as string[]) : undefined,
    };

    setParams({
      ...params,
      ...apiFilters,
      sortBy: sorter?.field
        ? `${sorter.order === 'ascend' ? '+' : '-'}${getSortField(sorter)}`
        : undefined,
    });
  };

  return (
    <>
      <EventDetails
        modalProps={infoModal}
        productId={productId}
        lotId={inventoryItem?.id || inventoryItem?.containerId || ''}
        eventId={inventoryItem?.eventId}
        isContainer={inventoryItem?.isContainer}
      />
      <GTable<ProductInventoryItem>
        className="gtbl-abs-toolbar"
        actionRef={actionRef}
        columns={columns({
          t,
          onViewEvent,
          setInventoryItem,
          primaryIdSearchfilter,
          defaultUom,
          selectedRowKeys: [],
          identifier,
          isSerial,
          coreLocations,
          coreEventTypes,
          poSearchfilter,
        })}
        columnsState={{
          defaultValue: {
            purchaseOrder: {
              show: true,
            },
            eventDate: {
              show: false,
            },
            loggedDate: {
              show: false,
            },
            blockchain: {
              show: false,
            },
          },
          persistenceKey: 'current-inventory-columns',
        }}
        loading={isLoading}
        value={recordList}
        recordCreatorProps={false}
        scroll={{ x: 1200, y: 200 }}
        onTableChange={onTableChange}
        options={{
          setting: false,
          reload: false,
        }}
      />
    </>
  );
};
export default React.memo(EventTable);

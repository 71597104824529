import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Switch, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { arrayMoveImmutable } from 'array-move';
import classNames from 'classnames';
import ActionButtons from 'components/ActionButtons';
import AttributeSelect from 'components/GSelect/AttributeSelect';
import GTable from 'components/GTable';
import { DragHandle, SortableItem, SortContainer } from 'components/useDragSort';
import { DraggableContainerProps } from 'components/useDragSort/typings';
import { FC, MutableRefObject, useCallback, useMemo, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { SortEnd } from 'react-sortable-hoc';
import { CustomAttribute, CustomDataTemplateProps } from '../../typings';
import styles from './index.module.less';
import useDocumentAttributeStore from './useDocumentAttributeStore';

type ColumnsType = {
  onEditRow: (actionPayload?: CustomAttribute) => void;
  onDelRow: (actionPayload?: CustomAttribute) => void;
  onChangeAttribute: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void;
  editableFormRef?: MutableRefObject<EditableFormInstance<CustomAttribute> | undefined>;
  t: TFunction<'pages', 'templates_document.add_template'>;
};

const columns = ({
  onEditRow,
  onDelRow,
  onChangeAttribute,
  t,
}: ColumnsType): Array<ProColumns<CustomAttribute>> => [
  {
    dataIndex: 'id',
    width: 40,
    render: () => <DragHandle />,
    fieldProps: {
      disabled: true,
    },
    renderFormItem: () => null,
    className: 'drag-visible',
  },
  {
    title: t('form_fields.custom_template_col_required'),
    dataIndex: 'required',
    valueType: 'switch',
    fieldProps: {
      size: 'small',
    },
    width: 80,
    render: (text, record) => <Switch checked={record.required} size="small" disabled />,
    className: 'drag-visible',
  },
  {
    title: t('form_fields.custom_template_col_field_attribute'),
    dataIndex: 'name',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('form_fields.custom_template_col_field_attribute_message'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t('form_fields.custom_template_col_field_attribute_placholder'),
      size: 'small',
    },
    renderFormItem: () => (
      <AttributeSelect
        key="attributeselect"
        size="small"
        onChange={(value, option) => {
          onChangeAttribute(value, option);
        }}
      />
    ),
    width: '26.8%',
    className: 'drag-visible',
  },
  {
    title: t('form_fields.custom_template_col_data_attribute'),
    dataIndex: 'dataAttribute',
    formItemProps: {
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t('form_fields.custom_template_col_data_attribute'),
      size: 'small',
      disabled: true,
    },
    tooltip: {
      title: t('form_fields.custom_template_col_data_attribute_tooltip'),
      key: null,
      type: '',
      props: null,
    },
    width: '18.70%',
  },
  {
    title: t('form_fields.custom_template_col_field_type'),
    dataIndex: 'fieldType',
    valueType: 'select',
    valueEnum: {
      text: {
        text: 'Text',
        status: 'default',
      },
      number: {
        text: 'Number',
        status: 'default',
      },
      dropdown: {
        text: 'Dropdown',
        status: 'default',
      },
      radio: {
        text: 'Radio Button',
        status: 'default',
      },
      date: {
        text: 'Date',
        status: 'default',
      },
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('form_fields.custom_template_col_field_type_message'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t('form_fields.custom_template_col_field_type'),
      size: 'small',
    },
    width: '18.70%',
  },
  {
    title: t('form_fields.custom_template_col_values'),
    dataIndex: 'values',
    fieldProps: {
      placeholder: t('form_fields.custom_template_col_values'),
      size: 'small',
    },
    width: '18.70%',
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons record={record} onDelete={onDelRow} onEdit={onEditRow} />
    ),
    width: '8.96%',
  },
];
const CustomDataTemplate: FC<CustomDataTemplateProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates_document.add_template' });
  const { setFieldsValue } = form;
  const tactionRef = useRef<ActionType>();
  const tmpAttribute = useDocumentAttributeStore((state) => state.attribute);
  const setTmpAttribute = useDocumentAttributeStore((state) => state.setAttribute);
  const attributes: Array<CustomAttribute> = form.getFieldValue('customAttributes');
  const setAttributes = useCallback(
    (value: Array<CustomAttribute>) =>
      setFieldsValue({
        customAttributes: value,
      }),
    [setFieldsValue],
  );

  const onEditRow = (actionPayload?: CustomAttribute) => {
    tactionRef.current?.startEditable(actionPayload?.id || 0);
    setTmpAttribute({
      id: actionPayload?.id || '0',
      name: actionPayload?.name || '',
      dataAttribute: actionPayload?.dataAttribute || '',
      fieldType: actionPayload?.fieldType || '',
      valueOptions: actionPayload?.valueOptions || [],
      required: actionPayload?.required || false,
    });
  };

  const onDelRow = async (actionPayload?: CustomAttribute) => {
    if (actionPayload) setAttributes(attributes.filter((item) => item.id !== actionPayload.id));
  };

  const onChangeAttribute: ColumnsType['onChangeAttribute'] = (value, option) => {
    if (value && option) {
      // @ts-ignore
      // itemprops will exist when the attribute is selected from the dropdown
      const item: CustomAttribute = option?.itemProps;
      setTmpAttribute({
        id: value || '0',
        name: item?.name || '',
        dataAttribute: item?.dataAttribute || '',
        fieldType: item?.fieldType || '',
        valueOptions: item?.valueOptions || [],
      });
    }
  };

  const onSaveAttribute = async (rowKey: RecordKey, data: CustomAttribute) => {
    tactionRef.current?.cancelEditable(data?.id || 0);
    const existingAttribute = attributes.find((item) => item.id === data?.id);
    if (existingAttribute) {
      existingAttribute.name = tmpAttribute?.name || data?.name || '';
      existingAttribute.dataAttribute = tmpAttribute?.dataAttribute || '';
      existingAttribute.fieldType = tmpAttribute?.fieldType || '';
      existingAttribute.defaultValue = tmpAttribute?.defaultValue || '';
      existingAttribute.valueOptions = tmpAttribute?.valueOptions || [];

      setAttributes(attributes.map((p) => (p.id === existingAttribute.id ? existingAttribute : p)));
    } else {
      const attributeData = {
        id: data?.id || '0',
        name: tmpAttribute?.name || '',
        dataAttribute: tmpAttribute?.dataAttribute || '',
        fieldType: data?.fieldType || '',
        valueOptions: data?.valueOptions || [],
      };
      setAttributes([...attributes, attributeData]);
    }
  };
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable([...attributes], oldIndex, newIndex);

        setAttributes([...newData]);
      }
    },
    [attributes, setAttributes],
  );
  const DraggableContainer: FC<DraggableContainerProps> = useCallback(
    (props) => (
      <SortContainer
        useDragHandle
        disableAutoscroll
        helperClass={styles['row-dragging']}
        onSortEnd={onSortEnd}
        {...props}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSortEnd],
  );

  const DraggableBodyRow: FC<any> = useCallback(
    (props) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      // @ts-ignore
      // eslint-disable-next-line react/destructuring-assignment
      const index = attributes?.findIndex((x) => x.id === props['data-row-key']) || 0;
      return <SortableItem {...props} index={index} />;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributes],
  );

  const { body } = useMemo(
    () => ({ body: { wrapper: DraggableContainer, row: DraggableBodyRow } }),
    [DraggableContainer, DraggableBodyRow],
  );

  return (
    <>
      <Typography.Paragraph className={classNames('ant-pro-form-group-title', styles.title)}>
        {t('form_fields.custom_template_grp_title')}
      </Typography.Paragraph>
      <GTable<CustomAttribute>
        key="sttribt"
        actionRef={tactionRef}
        columns={columns({ onEditRow, onDelRow, onChangeAttribute, t })}
        editable={{
          onSave: (rowKey, data) => onSaveAttribute(rowKey, data),
          onCancel: async (_rowKey, data) => {
            tactionRef.current?.cancelEditable(data?.id || 0);
          },
        }}
        options={{
          reload: false,
          setting: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        recordCreatorProps={false}
        onAddRecordClick={() => {
          tactionRef.current?.addEditRecord?.({
            id: Date.now().toString(),
          });
        }}
        value={attributes}
        enableRecordCreator
        components={{
          body,
        }}
      />
    </>
  );
};
export default CustomDataTemplate;

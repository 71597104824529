import Profield, { ProFieldValueType } from '@ant-design/pro-field';
import { ProColumns } from '@ant-design/pro-table';
import { ProFieldValueObjectType } from '@ant-design/pro-utils';
import { DatePicker, DatePickerProps, Input, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { NumericInput } from 'components';
import moment from 'moment';
import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionSelectProps } from '../typings';

const fieldTypes: {
  [key: string]: {
    name?: string;
    dataIndex?: string;
    valueType?: ProFieldValueType | ProFieldValueObjectType;
    isDefault?: boolean;
    fieldProps?: ProColumns['fieldProps'];
  };
} = {
  Text: {
    name: 'Text',
    dataIndex: 'Text',
    valueType: 'text',
    isDefault: true,
    fieldProps: {
      size: 'small',
      placeholder: 'Values',
    },
  },
  Number: {
    name: 'Number',
    dataIndex: 'Number',
    valueType: 'digit',
    fieldProps: {
      stringMode: true,
      size: 'small',
      placeholder: 'Values',
      className: 'full-width',
    },
  },
  Dropdown: {
    name: 'Dropdown',
    dataIndex: 'Dropdown',
    valueType: 'select',
    fieldProps: {
      mode: 'tags',
      size: 'small',
      placeholder: 'Add Values',
      notFoundContent: 'Type your values and press Enter to create it.',
    },
  },
  RadioButton: {
    name: 'RadioButton',
    dataIndex: 'RadioButton',
    valueType: 'select',
    fieldProps: {
      mode: 'tags',
      size: 'small',
      placeholder: 'Add Values',
      notFoundContent: 'Type your values and press Enter to create it.',
    },
  },
  Date: {
    name: 'Date',
    dataIndex: 'Date',
    valueType: 'date',
    fieldProps: {
      size: 'small',
      placeholder: 'Values',
      className: 'full-width',
    },
  },
};
const OptionSelect: FC<OptionSelectProps> = ({ form, config }) => {
  const { setFieldsValue, getFieldValue } = form;
  const recordKey = config?.recordKey;
  const record = config?.record;
  const isNewAttribute = getFieldValue(String(recordKey))?.isNewAttribute;
  const valueOptions = useMemo(() => record?.valueOptions || [], [record]);
  const { valueType, fieldProps } = fieldTypes[String(record?.fieldType || 'Text')];
  // disable value type is true not for select
  const isDisabled = useMemo(
    () => valueType !== 'select' || !isNewAttribute,
    [valueType, isNewAttribute],
  );

  const renderFormItem = useCallback(() => {
    switch (valueType) {
      case 'select':
        return (
          <Profield
            mode="edit"
            valueType="select"
            value={valueOptions}
            fieldProps={{ ...fieldProps, disabled: isDisabled, maxTagCount: 'responsive' }}
            onChange={(values) => {
              setFieldsValue({
                [String(recordKey)]: {
                  valueOptions: values,
                },
              });
            }}
          />
        );
      default:
        return (
          <Profield
            mode="edit"
            valueType={valueType}
            fieldProps={{ ...fieldProps, disabled: isDisabled }}
            onChange={(e) => {
              if (valueType === 'text') {
                setFieldsValue({
                  [String(recordKey)]: {
                    defaultValue: e?.target?.value || '',
                  },
                });
              } else {
                setFieldsValue({
                  [String(recordKey)]: {
                    defaultValue: e,
                  },
                });
              }
            }}
          />
        );
    }
  }, [valueType, fieldProps, setFieldsValue, recordKey, valueOptions, isDisabled]);

  return renderFormItem();
};

export const DefaultField: FC<OptionSelectProps> = ({ form, config }) => {
  const recordKey = config?.recordKey;
  const record = config?.record;
  const values: Array<DefaultOptionType> =
    record?.valueOptions?.map((el) => ({
      label: el,
      value: el,
    })) || [];
  const { setFieldsValue } = form;
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_attributes' });

  const setDefaultValue = (val: string) => {
    setFieldsValue({
      [String(recordKey)]: {
        defaultValue: val,
      },
    });
  };

  const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (record) {
      setDefaultValue(dateString);
    }
  };

  const onSelectChange = (val: string) => {
    if (record) {
      setDefaultValue(val);
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (record) {
      setDefaultValue(e.target.value);
    }
  };

  switch (record?.fieldType) {
    case 'Date':
      return (
        <DatePicker
          value={record?.defaultValue ? moment(record?.defaultValue) : null}
          onChange={onDateChange}
          size="small"
        />
      );
    case 'Dropdown':
    case 'RadioButton':
      return (
        <Select
          allowClear
          value={record?.defaultValue !== '' ? record?.defaultValue : undefined}
          options={values}
          placeholder={t('value')}
          onChange={onSelectChange}
          size="small"
        />
      );
    case 'Text':
      return (
        <Input
          placeholder={t('value')}
          value={record?.defaultValue || ''}
          onChange={onInputChange}
          size="small"
        />
      );
    case 'Number':
      return (
        <NumericInput
          placeholder={t('value')}
          value={record?.defaultValue || ''}
          onChange={onSelectChange}
          size="small"
        />
      );
    default:
      <Input placeholder={t('value')} size="small" />;
  }
  return <Input placeholder={t('value')} size="small" />;
};
export default React.memo(OptionSelect);

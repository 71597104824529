// @ts-nocheck
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { EditableFormInstance } from '@ant-design/pro-table';
import { GraphData, NodeConfig } from '@antv/g6/lib/types';
import { Button, FormInstance, Modal, Tabs, Upload, UploadProps, message } from 'antd';
import { useActions } from 'hooks';
import moment from 'moment';
import { useEventActions } from 'pages/Events';
import { ProductInventoryItem } from 'pages/Products';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EnrichedWorkflowCommissionEvent,
  EnrichedWorkflowObserveEvent,
  EnrichedWorkflowReceiveEvent,
  EnrichedWorkflowShipEvent,
  EnrichedWorkflowTransformEvent,
} from 'services/api/client/src';
import {
  SheetDataResponse,
  SheetResponse,
  dateFormat,
  errorHandler,
  getCompanyIdByName,
  getLocationById,
  getLocationIdByName,
  getProductById,
  getProductIdByName,
  getTimezoneOffset,
  onCreateStoreInstance,
  readWorkflowExcelTemplate,
  titletoKeys,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';
import CSVTable from './CSVTable';
import {
  commissionTemplate,
  decomissionTemplate,
  observeTemplate,
  shipTemplate,
  transformTemplate,
} from './CSVTemplates';
import styles from './index.module.less';
import { DataItem, EventData, UploadTemplateProps } from './typings';

const modalWidth = '64%';
const UploadTemplate: FC<UploadTemplateProps> = ({ modal, form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'workflows.create_workflow' });
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();

  const [, setTabKey] = useState<string>('Commission');
  const [sheetData, setSheetData] = useState<SheetResponse | undefined>();
  const { defaultTimezone } = useEventActions();
  const { locations, products, tradePartners } = useActions();

  const onRemoveSheet = () => {
    setSheetData(undefined);
    form?.setFieldsValue({
      csvData: undefined,
    });
  };
  const getCSVDataByTemplate = (rawData: any, key: string) => {
    const newKey = key.split('.')?.[1]?.trim();
    const pageKey = String(newKey).toLowerCase();

    switch (pageKey) {
      case 'commission':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(commissionTemplate, item),
            location: getLocationIdByName(item?.Location, locations),
            productId: getProductIdByName(item?.Product, products),
            bizStep: item?.['Business Step'] || 'urn:epcglobal:cbv:bizstep:commissioning',
            disposition: item?.Disposition || 'urn:epcglobal:cbv:disp:active',
            timeZone: item?.['Time Zone'] || defaultTimezone,
          })) || []
        );
      case 'observe':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(observeTemplate, item),
            location: getLocationIdByName(item?.Location, locations),
            productId: getProductIdByName(item?.Product, products),
            lotID: item?.['Lot Number'] || item?.['Serial Number'],
            bizStep: item?.['Business Step'],
            disposition: item?.Disposition || 'urn:epcglobal:cbv:disp:in_progress',
            timeZone: item?.['Time Zone'] || defaultTimezone,
          })) || []
        );

      case 'ship':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(shipTemplate, item),
            location: getLocationIdByName(item?.Location, locations),
            shippingCompany: getCompanyIdByName(item?.['Ship To Company'], tradePartners),
            shippingAddress: getLocationIdByName(item?.['Ship To Address'], locations),
            productId: getProductIdByName(item?.Product, products),
            lotID: item?.['Lot Number'] || item?.['Serial Number'],
            bizStep: item?.['Business Step'] || 'urn:epcglobal:cbv:disp:shipping',
            disposition: item?.Disposition || 'urn:epcglobal:cbv:disp:in_transit',
            timeZone: item?.['Time Zone'] || defaultTimezone,
          })) || []
        );

      case 'receive':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(shipTemplate, item),
            location: getLocationIdByName(item?.Location, locations),
            shippingCompany: getCompanyIdByName(item?.['Ship To Company'], tradePartners),
            shippingAddress: getLocationIdByName(item?.['Ship To Address'], locations),
            productId: getProductIdByName(item?.Product, products),
            lotID: item?.['Lot Number'] || item?.['Serial Number'],
            bizStep: item?.['Business Step'] || 'urn:epcglobal:cbv:disp:shipping',
            disposition: item?.Disposition || 'urn:epcglobal:cbv:disp:in_transit',
            timeZone: item?.['Time Zone'] || defaultTimezone,
          })) || []
        );

      case 'transform':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(transformTemplate, item),
            // productId: getProductIdByName(item?.Product, products, productId),
            bizStep: item?.['Business Step'] || 'urn:epcglobal:cbv:disp:commissioning',
            disposition: item?.Disposition || 'urn:epcglobal:cbv:disp:active',
            timeZone: item?.['Time Zone'] || defaultTimezone,
          })) || []
        );

      case 'decomission':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(decomissionTemplate, item),
            bizStep: item?.['Business Step'],
            disposition: item?.Disposition || 'urn:epcglobal:cbv:disp:in_progress',
            timeZone: item?.['Time Zone'] || defaultTimezone,
          })) || []
        );

      default:
        return {};
    }
  };

  const onCSVUpload: UploadProps['onChange'] = async (info) => {
    const { file } = info;
    file.status = 'done';
    if (!file.url && !file.preview && file.originFileObj) {
      const data = await readWorkflowExcelTemplate(file.originFileObj);
      const newData = Object.keys(data).reduce((acc, key) => {
        acc[key] = getCSVDataByTemplate(data[key], key);
        return acc;
      }, {} as SheetResponse);
      setSheetData(newData);
      form?.setFieldsValue({
        csvData: newData,
      });
    }
  };
  const createEventData = () => {
    const sheetFormData = form?.getFieldValue('csvData') as SheetDataResponse;
    const graphData = form?.getFieldValue('graphData') as GraphData;
    const nodes = graphData?.nodes || [];

    const updatedNodes: Array<NodeConfig> = [];
    if (!sheetFormData) {
      throw new Error('No data to submit');
    }

    // const formKeys = Object.keys(sheetFormData);

    Object.keys(sheetFormData).forEach((key, idx) => {
      const instances = (form?.getFieldValue('instances') as Array<ProductInventoryItem>) || [];
      const currentNode = nodes[idx];
      const eventId = currentNode?.id;
      const eventType = key.split('.')?.[1]?.trim();

      // const sourceKey = idx > 0 ? formKeys[idx - 1] : undefined;
      const edataArr = sheetFormData[key];
      const sourceNode = idx > 0 ? nodes[idx - 1] : undefined;

      const edata = edataArr?.[0];
      const primaryId = edata?.lotID;
      const productId = String(edata?.productId || '');
      const product = getProductById(productId, products);
      const productName = product?.name;
      const quantity = edata?.quantity;
      const unitOfMeasure = product?.unitOfMeasure;
      const locationId = String(edata?.location || currentNode?.locationId);
      const location = getLocationById(locationId, locations);
      const locationName = location?.address?.line2 || location?.name || '';
      const isSerial = product?.productIdentifierType === 'Serial';
      let eventData: EventData = {};

      switch (eventType) {
        case 'Commission':
          eventData = {
            ...((currentNode?.eventData as EventData) || {}),
            eventId,
            eventIdInTemplate: eventId,
            eventTime: new Date(moment(`${edata.date} ${edata.time}`).toISOString()),
            eventTimeZone: getTimezoneOffset(edata.timeZone),
            bizStep: edata.bizStep,
            disposition: edata.disposition,
            locationId: edata.location,
            purchaseOrder: edata.poNumber,
            products: [
              {
                productId: String(edata?.productId || ''),
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
                lotSerial: edata.lotID,
              },
            ],
            newProducts: [
              {
                productId: String(edata?.productId || ''),
                productName: productName || '',
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
                unitOfMeasure: unitOfMeasure || 'LBS',
                lotSerial: edata.lotID,
              },
            ],
          } as EnrichedWorkflowCommissionEvent;
          onCreateStoreInstance(
            {
              id: eventId,
              eventId,
              name: product?.name || '',
              productId,
              instanceId: eventId,
              primaryId: edata.lotID || '',
              lotSerial: edata.lotID || '',
              quantity: Number(edata?.quantity) || 0,
              unitOfMeasure: unitOfMeasure || 'LBS',
              eventName: eventType,
              location: location?.name || '',
              locationAddress: location?.address?.line2 || '',
              locationId,
              destinationId: undefined,
              isContainer: false,
              containerId: undefined,
              containerIdentifier: '',
              containerItems: [],
              tradePartnerName: location?.tradePartnerName || '',
              address: location?.address,
              eventDate: eventData.eventTime,
              loggedDate: new Date(),
              purchaseOrder: edata.poNumber,
              shipmentType: undefined,
              shipped: false,
              productIdentifierType: product?.productIdentifierType,
              bizStep: edata.bizStep,
              disposition: edata.disposition,
              timezone: getTimezoneOffset(edata.timeZone),
              templateId: String(currentNode?.eventTemplateId || ''),
            },
            instances,
            form,
          );
          break;
        case 'Observe':
          eventData = {
            ...((currentNode?.eventData as EventData) || {}),
            eventId,
            eventIdInTemplate: eventId,
            sourceId: sourceNode ? [sourceNode?.id] : [],
            eventTime: new Date(moment(`${edata.date} ${edata.time}`).toISOString()),
            bizStep: edata.bizStep,
            disposition: edata.disposition,
            eventTimeZone: getTimezoneOffset(edata.timeZone),
            locationId: edata.location,
            purchaseOrder: edata.poNumber,
            products: [
              {
                instanceId: eventId,
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
              },
            ],
            newProducts: [
              {
                productId: String(edata?.productId || ''),
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
                lotSerial: edata.lotID,
              },
            ],
          } as EnrichedWorkflowObserveEvent;
          onCreateStoreInstance(
            {
              id: eventId,
              name: product?.name || '',
              productId,
              instanceId: edata.lotID,
              primaryId: edata.lotID || '',
              lotSerial: edata.lotID || '',
              quantity: Number(edata?.quantity) || 0,
              unitOfMeasure: unitOfMeasure || 'LBS',
              eventName: eventType,
              location: location?.name || '',
              locationAddress: location?.address?.line2 || '',
              locationId,
              destinationId: undefined,
              eventId,
              isContainer: false,
              containerId: undefined,
              containerIdentifier: '',
              containerItems: [],
              tradePartnerName: location?.tradePartnerName || '',
              address: location?.address,
              eventDate: eventData.eventTime,
              loggedDate: new Date(),
              purchaseOrder: edata.poNumber,
              shipmentType: undefined,
              shipped: false,
              productIdentifierType: product?.productIdentifierType,
              bizStep: edata.bizStep,
              disposition: edata.disposition,
              timezone: getTimezoneOffset(edata.timeZone),
              templateId: String(currentNode?.eventTemplateId || ''),
            },
            instances,
            form,
          );
          break;
        case 'Ship':
          eventData = {
            ...((currentNode?.eventData as EventData) || {}),
            eventId,
            eventIdInTemplate: eventId,
            sourceId: sourceNode ? [sourceNode?.id] : [],
            eventTime: new Date(moment(`${edata.date} ${edata.time}`).toISOString()),
            bizStep: edata.bizStep,
            disposition: edata.disposition,
            eventTimeZone: getTimezoneOffset(edata.timeZone),
            originLocationId: edata.location,
            destinationLocationId: edata.shippingAddress,
            purchaseOrder: edata.poNumber,
            products: [
              {
                // @ts-ignore
                productId: edata?.productId,
                instanceId: primaryId,
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
              },
            ],
            newProducts: [
              {
                productId: String(edata?.productId || ''),
                // @ts-ignore
                instanceId: primaryId,
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
                lotSerial: edata.lotID,
                unitOfMeasure: unitOfMeasure || 'LBS',
              },
            ],
          } as EnrichedWorkflowShipEvent;
          onCreateStoreInstance(
            {
              id: eventId,
              name: product?.name || '',
              productId,
              instanceId: edata.lotID,
              primaryId: edata.lotID || '',
              lotSerial: edata.lotID || '',
              quantity: Number(edata?.quantity) || 0,
              unitOfMeasure: unitOfMeasure || 'LBS',
              eventName: eventType,
              location: location?.name || '',
              locationAddress: location?.address?.line2 || '',
              locationId,
              destinationId: edata.shippingAddress,
              eventId,
              isContainer: false,
              containerId: undefined,
              containerIdentifier: '',
              containerItems: [],
              tradePartnerName: location?.tradePartnerName || '',
              address: location?.address,
              eventDate: eventData.eventTime,
              loggedDate: new Date(),
              purchaseOrder: edata.poNumber,
              shipmentType: 'Internal',
              shipped: true,
              productIdentifierType: product?.productIdentifierType,
              bizStep: edata.bizStep,
              disposition: edata.disposition,
              timezone: getTimezoneOffset(edata.timeZone),
              templateId: String(currentNode?.eventTemplateId || ''),
            },
            instances,
            form,
          );
          break;
        case 'Transform':
          eventData = {
            ...((currentNode?.eventData as EventData) || {}),
            eventId,
            eventIdInTemplate: eventId,
            sourceId: sourceNode ? [sourceNode?.id] : [],
            eventTime: new Date(moment(`${edata.date} ${edata.time}`).toISOString()),
            bizStep: edata.bizStep,
            disposition: edata.disposition,
            eventTimeZone: getTimezoneOffset(edata.timeZone),
            locationId: edata.location,
            purchaseOrder: edata.poNumber,
            inputProducts: [
              {
                // @ts-ignore
                productId: String(edata?.productId || ''),
                instanceId: eventId,
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
              },
            ],
            newInputProducts: [
              {
                productId: String(edata?.productId || ''),
                productName,
                lotSerial: primaryId,
                instanceId: eventId,
                quantity: unitOfMeasure !== 'item' ? Number(edata?.quantity || 0) : 1,
                unitOfMeasure,
              },
            ],
            newProducts: [
              {
                productId: String(edata?.productId || ''),
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
                lotSerial: edata.lotID,
              },
            ],
            outputProducts: [
              {
                productId: String(edata?.productId || ''),
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
                lotSerial: edata.lotID,
              },
            ],
            newOutputProducts: [
              {
                productId: String(edata?.productId || ''),
                productName,
                lotSerial: primaryId,
                instanceId: eventId,
                quantity: unitOfMeasure !== 'item' ? Number(edata?.quantity || 0) : 1,
                unitOfMeasure,
              },
            ],
          } as EnrichedWorkflowTransformEvent;
          onCreateStoreInstance(
            {
              id: eventId,
              name: product?.name || '',
              productId,
              instanceId: edata.lotID,
              primaryId: edata.lotID || '',
              lotSerial: edata.lotID || '',
              quantity: Number(edata?.quantity) || 0,
              unitOfMeasure: unitOfMeasure || 'LBS',
              eventName: eventType,
              location: location?.name || '',
              locationAddress: location?.address?.line2 || '',
              locationId,
              destinationId: undefined,
              eventId,
              isContainer: false,
              containerId: undefined,
              containerIdentifier: '',
              containerItems: [],
              tradePartnerName: location?.tradePartnerName || '',
              address: location?.address,
              eventDate: eventData.eventTime,
              loggedDate: new Date(),
              purchaseOrder: edata.poNumber,
              shipmentType: undefined,
              shipped: false,
              productIdentifierType: product?.productIdentifierType,
              bizStep: edata.bizStep,
              disposition: edata.disposition,
              timezone: getTimezoneOffset(edata.timeZone),
              templateId: String(currentNode?.eventTemplateId || ''),
            },
            instances,
            form,
          );
          break;

        case 'Receive':
          eventData = {
            ...((currentNode?.eventData as EventData) || {}),
            eventId,
            eventIdInTemplate: eventId,
            sourceId: sourceNode ? [sourceNode?.id] : [],
            eventTime: new Date(moment(`${edata.date} ${edata.time}`).toISOString()),
            bizStep: edata.bizStep,
            disposition: edata.disposition,
            eventTimeZone: getTimezoneOffset(edata.timeZone),
            destinationLocationId: edata.location,
            originLocationId: (sourceNode?.eventData as EnrichedWorkflowShipEvent)
              ?.originLocationId,
            purchaseOrder: edata.poNumber,
            products: [
              {
                instanceId: eventId,
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
                shipEventId: eventId,
                remainingQuantity: 0,
              },
            ],
            newProducts: [
              {
                productId: String(edata?.productId || ''),
                quantity: !isSerial ? Number(edata?.quantity || 0) : 1,
                lotSerial: edata.lotID,
                // @ts-ignore
                shipEventId: eventId,
                remainingQuantity: 0,
              },
            ],
          } as EnrichedWorkflowReceiveEvent;
          onCreateStoreInstance(
            {
              id: eventId,
              name: product?.name || '',
              productId,
              instanceId: edata.lotID,
              primaryId: edata.lotID || '',
              lotSerial: edata.lotID || '',
              quantity: Number(edata?.quantity) || 0,
              unitOfMeasure: unitOfMeasure || 'LBS',
              eventName: eventType,
              location: location?.name || '',
              locationAddress: location?.address?.line2 || '',
              locationId,
              destinationId: edata.location,
              eventId,
              isContainer: false,
              containerId: undefined,
              containerIdentifier: '',
              containerItems: [],
              tradePartnerName: location?.tradePartnerName || '',
              address: location?.address,
              eventDate: eventData.eventTime,
              loggedDate: new Date(),
              purchaseOrder: edata.poNumber,
              shipmentType: 'Internal',
              shipped: false,
              productIdentifierType: product?.productIdentifierType,
              bizStep: edata.bizStep,
              disposition: edata.disposition,
              timezone: getTimezoneOffset(edata.timeZone),
              templateId: String(currentNode?.eventTemplateId || ''),
            },
            instances,
            form,
          );
          break;
        default:
          break;
      }
      const updatedNode = {
        ...currentNode,
        id: currentNode?.id || uuidv4(),
        eventId: currentNode?.id || uuidv4(),
        eventType,
        eventTime: edata?.date
          ? dateFormat(new Date(moment(`${edata.date} ${edata.time}`).toISOString()))
          : undefined,
        name: productName || eventType,
        primaryId,
        quantity,
        unitOfMeasure,
        locationId,
        eventTemplateName: locationName,
        locationName,
        productId,
        productName: product?.name || '',
        colourIndex: 'colour_4',
        children: [],
        eventData,
        isSuccess: false,
        isError: false,
      };
      updatedNodes.push(updatedNode);
    });
    const updatedGraphData: GraphData = {
      ...graphData,
      nodes: updatedNodes,
    };
    form?.setFieldsValue({
      graphData: updatedGraphData,
    });
  };

  const onSubmit = () => {
    try {
      createEventData();
      modal?.hide();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const uploadProps: UploadProps = {
    name: 'csvfile',
    multiple: false,
    maxCount: 1,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: onCSVUpload,
    beforeUpload: async (file) => {
      const isExcel =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        message.error('You can only upload XLSX file!');
      }
      // check if XLSX file contains data
      const data = await readWorkflowExcelTemplate(file);
      const dataKeys = Object.keys(data);
      if (!dataKeys?.length) {
        message.error('No data found in XLSX');
      }

      return isExcel && dataKeys?.length > 0;
    },
    showUploadList: false,
  };

  return (
    <Modal
      {...modal}
      title={t('upload_modal_title')}
      okText={t('upload_modal_submit')}
      width={modalWidth}
      okButtonProps={{ shape: 'round' }}
      onOk={onSubmit}
      cancelButtonProps={{ shape: 'round', hidden: true }}
      maskClosable={false}
    >
      {sheetData ? (
        <div>
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            shape="round"
            ghost
            onClick={onRemoveSheet}
            className={styles.removebtn}
          >
            {t('upload_modal_remove')}
          </Button>
          <Tabs onChange={setTabKey} defaultActiveKey={Object.keys(sheetData)?.[0]}>
            {Object.keys(sheetData).map((tab) => (
              <Tabs.TabPane tab={tab} key={tab}>
                <CSVTable
                  form={form as FormInstance<any>}
                  editableTableFormRef={editableTableFormRef}
                  tab={tab}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      ) : (
        <Upload.Dragger {...uploadProps} className={styles.upload}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{t('upload_modal_desc')}</p>
        </Upload.Dragger>
      )}
    </Modal>
  );
};

export default UploadTemplate;

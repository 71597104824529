import { DatePicker, Input, Select } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { globalStyles } from 'utils';

type TemplateFieldTypeProps = {
  fieldType: string;
};
const TemplateFieldType: FC<TemplateFieldTypeProps> = ({ fieldType }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.event_table' });

  return (
    <>
      {fieldType === 'Date' && (
        <DatePicker style={globalStyles.w100} showTime size="small" disabled />
      )}
      {fieldType === 'Dropdown' && (
        <Select size="small" placeholder={t('field_type.dropdown')} disabled />
      )}
      {fieldType === 'Text' && <Input size="small" placeholder={t('field_type.text')} disabled />}
      {fieldType === 'Number' && (
        <Input size="small" placeholder={t('field_type.number')} disabled />
      )}
      {fieldType === 'RadioButton' && (
        <Input size="small" placeholder={t('field_type.radio_button')} disabled />
      )}
    </>
  );
};

export default React.memo(TemplateFieldType);

import { ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/es/useEditableArray';
import { message } from 'antd';
import { FormInstance, useWatch } from 'antd/lib/form/Form';
import { ActionButtons, GTable, ProductSelect } from 'components';
import pluralize from 'pluralize';
import { useCallback } from 'react';
import { ProductResponse } from 'services/api/client/src';
import { hiddencol } from 'utils';
import { ReceiveProductsDataType } from './AllReceiveProducts';
import { NewReceiveProductsProps } from './typings';

export type NewReceiveTableColumnsType = {
  onEditRow: (actionPayload?: ReceiveProductsDataType) => void;
  onDeleteRow: (actionPayload?: ReceiveProductsDataType) => void;
  lotChangeModal?: (value: string, rowKey: string, tblForm: FormInstance<any>) => void;
  quantityChangeModal?: (value: string, rowKey: string, tblForm: FormInstance<any>) => void;
  isMultipleProducts?: boolean;
};

// new Receive Products Table Columns
export const columns = ({
  onEditRow,
  onDeleteRow,
  lotChangeModal,
  quantityChangeModal,
  isMultipleProducts,
}: NewReceiveTableColumnsType): Array<ProColumns<ReceiveProductsDataType>> => [
  {
    title: 'Product',
    dataIndex: 'parentProductId',
    valueType: 'select',
    hideInTable: !isMultipleProducts,
    fieldProps: {
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey }, form) => (
      <ProductSelect
        allowLotSerial
        isAddProduct
        size="small"
        onChange={(value, option) => {
          // @ts-ignore
          const item: ProductResponse = option.itemProps;
          form?.setFieldsValue({
            [String(recordKey)]: {
              parentProductId: item?.id,
              productName: item?.name || '',
              isAssignedProduct: true,
              currentInventory: item?.currentInventory,
              currentInventoryTotal: item?.currentInventoryTotal,
              unitOfMeasure: item?.simpleUnitOfMeasurement || '',
              productIdentifierType: item?.productIdentifierType,
              primaryId: undefined,
              quantity: undefined,
            },
          });
        }}
      />
    ),
    render: (_t, record) => record?.productName,
  },
  {
    title: 'Primary ID',
    dataIndex: 'primaryId',
    valueType: 'text',
    fieldProps: (form, { rowKey }) => {
      const productIdentifierType = form?.getFieldValue?.(String(rowKey))?.productIdentifierType;
      const selectedProductId = form?.getFieldValue?.(String(rowKey))?.parentProductId;
      return {
        addonBefore: productIdentifierType ? `${productIdentifierType}: ` : '-',
        size: 'small',
        onBlur: (e: any) => lotChangeModal?.(e.target.value, String(rowKey), form),
        placeholder: productIdentifierType,
        disabled: isMultipleProducts && selectedProductId === undefined,
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    render: (_t, record) => `${record?.productIdentifierType}: ${record?.primaryId}`,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: (form, { rowKey }) => {
      const unitOfMeasure = form?.getFieldValue?.(String(rowKey))?.unitOfMeasure || 'LBS';
      const productIdentifierType =
        form?.getFieldValue?.(String(rowKey))?.productIdentifierType || 'Lot';
      return {
        addonAfter:
          productIdentifierType === 'Serial' ? 'items' : `${unitOfMeasure}`?.toLocaleUpperCase(),
        size: 'small',
        stringMode: true,
        type: 'number',
        style: {
          width: '100%',
        },
        onBlur: (e: any) => quantityChangeModal?.(e.target.value, String(rowKey), form),
        disabled: productIdentifierType === 'Serial',
        placeholder: '0',
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    render: (text, record) =>
      record?.productIdentifierType === 'Serial'
        ? `${record?.quantity} ${pluralize('item', record?.quantity)}`
        : `${record?.quantity} ${record?.unitOfMeasure || ''}`?.toLocaleUpperCase(),
  },

  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    align: 'center',
    width: 160,
    render: (_, record) => (
      <ActionButtons
        record={record}
        onEdit={onEditRow}
        onDelete={onDeleteRow}
        showDelete
        showEdit={!record?.isSSCC}
      />
    ),
  },
  {
    dataIndex: 'eventDate',
    ...hiddencol,
  },
  {
    dataIndex: 'instanceQuantity',
    ...hiddencol,
  },
  {
    dataIndex: 'unitOfMeasure',
    ...hiddencol,
  },
  {
    dataIndex: 'productIdentifierType',
    ...hiddencol,
  },
  {
    dataIndex: 'productName',
    ...hiddencol,
  },
  {
    dataIndex: 'parentProductId',
    ...hiddencol,
  },
  {
    dataIndex: 'currentInventory',
    ...hiddencol,
  },
  {
    dataIndex: 'currentInventoryTotal',
    ...hiddencol,
  },
];

const NewReceiveProducts = ({ form, actionRef, setRowBeingEdited }: NewReceiveProductsProps) => {
  const { setFieldsValue } = form;
  // Receive Products table data
  const newReceiveProducts: ReceiveProductsDataType[] = useWatch('inboundShipments', form) || [];
  const productProps: ProductResponse = useWatch('productProps', form) || [];

  const isMultipleProducts = useWatch('isMultipleProducts', form);

  // function to update receive products table data
  const SetNewReceiveProducts = useCallback(
    (value: ReceiveProductsDataType[]) => {
      setFieldsValue({
        inboundShipments: value,
      });
    },
    [setFieldsValue],
  );

  // Handles on save button click
  const onSaveRow = async (_rowKey: RecordKey, data: ReceiveProductsDataType) => {
    actionRef.current?.cancelEditable(data?.id || 0);
    setRowBeingEdited?.(data?.id || '');
    const existingProduct = newReceiveProducts.find((product) => product.id === data?.id);
    if (existingProduct) {
      existingProduct.id = data?.id || '';
      existingProduct.productName = data?.productName || '';
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.parentProductId = data?.parentProductId?.trim() || undefined;
      existingProduct.quantity = data?.quantity;
      existingProduct.instanceQuantity = data?.quantity;
      existingProduct.currentInventory = data?.currentInventory;
      existingProduct.unitOfMeasure = data?.unitOfMeasure || '';

      // update product
      SetNewReceiveProducts(
        newReceiveProducts.map((p) => (p.id === existingProduct.id ? existingProduct : p)),
      );
    } else {
      // Todo : Add more fields related to product after ProductSelect Integration
      const newProduct = {
        id: data?.id,
        productName: data?.productName || '',
        parentProductId: data?.parentProductId?.trim() || undefined,
        currentInventory: data?.currentInventory,
        currentInventoryTotal: data?.currentInventoryTotal,
        unitOfMeasure: data?.unitOfMeasure || '',
        primaryId: data?.primaryId || '',
        quantity: data?.quantity,
        instanceQuantity: data?.quantity,
        productIdentifierType: data?.productIdentifierType,
      };
      SetNewReceiveProducts([...newReceiveProducts, newProduct]);
    }
  };

  // handles edit click on row
  const onEditRow = (actionPayload?: ReceiveProductsDataType) => {
    setRowBeingEdited?.(actionPayload?.id || '');
    actionRef.current?.startEditable(actionPayload?.id || 0);
  };

  const onCancelProduct = async (rowKey: RecordKey, data: ReceiveProductsDataType) => {
    actionRef.current?.cancelEditable(data?.id || 0);
  };

  // handles deleting a row in gtin table
  const onDeleteRow = async (actionPayload?: ReceiveProductsDataType) => {
    if (actionPayload) {
      SetNewReceiveProducts(newReceiveProducts.filter((g) => g.id !== actionPayload.id));
    }
  };

  return (
    <GTable<ReceiveProductsDataType>
      actionRef={actionRef}
      key="newReceiveProductsTable"
      rowKey="id"
      columns={columns({ onEditRow, onDeleteRow, isMultipleProducts })}
      value={newReceiveProducts}
      editable={{
        onSave: (rowKey, actionPayload) => onSaveRow(rowKey, actionPayload),
        onCancel: async (rowKey, oldData, data) => onCancelProduct(rowKey, data),
      }}
      options={{
        setting: false,
        reload: false,
      }}
      onAddRecordClick={() => {
        const newAddId = Date.now().toString();
        // only allow user to add item if a product is selected
        if (isMultipleProducts) {
          setRowBeingEdited?.(newAddId);
          actionRef.current?.addEditRecord?.({
            id: newAddId,
          });
        } else if (!isMultipleProducts && productProps?.name) {
          setRowBeingEdited?.(newAddId);
          actionRef.current?.addEditRecord?.({
            id: newAddId,
            productName: productProps?.name || '',
            parentProductId: productProps?.id?.trim() || undefined,
            currentInventory: productProps?.currentInventory,
            currentInventoryTotal: productProps?.currentInventoryTotal,
            unitOfMeasure: productProps?.simpleUnitOfMeasurement || '',
            productIdentifierType: productProps?.productIdentifierType,
            instanceQuantity: productProps?.unitQuantity,
          });
          // else show error message
        } else {
          message.error('Please select a product first');
        }
      }}
      enableRecordCreator
      addBtnText="Add Item"
      addBtnPosition="top"
    />
  );
};

export default NewReceiveProducts;

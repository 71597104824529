import type { ActionType, ProColumns } from '@ant-design/pro-table';
import { Typography } from 'antd';
import GTable from 'components/GTable';
import { useModalVisibility } from 'hooks';
import { FilterArrayProp } from 'pages/Network/typings';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import { useTradePartners } from 'services/api';
import { EventResponse } from 'services/api/client/src';
import { getEventNode } from 'services/api/eventDetails';
import { getTransformType } from 'utils';
import DetailsModal from './DetailsModal/DetailsModal';
import { EventListItem, EventListProps, OnInfoModalClickFn } from './typings';
import useEventListActions from './useEventListActions';

const EventList: FC<EventListProps> = ({
  eventId,
  product,
  productInstance,
  event,
  modalWidth,
  isInboundShipment,
  closeAllModal,
}) => {
  const actionRef = useRef<ActionType>();
  const {
    records,
    coreLocations,
    params,
    totalItems,
    onTableChange,
    isLoading,
    coreEventTypes,
    filters: [primaryIdSearchfilter],
    productIdFilter,
    productNameFilter,
  } = useEventListActions({
    eventIds: [eventId || ''],
    performTraceback: true,
    isInboundShipment,
    sortBy: '-createdTime',
  });
  const infoModal = useModalVisibility(false);
  const [activeEvent, setActiveEvent] = useState<EventResponse>();
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details.event_list' });

  const { data: allCompany } = useTradePartners();

  const onInfoModalClick = useCallback<OnInfoModalClickFn>(
    async (eventInfo) => {
      const eventData = await getEventNode(eventInfo?.eventId);
      setActiveEvent(eventData);
      infoModal?.show();
    },
    [infoModal],
  );

  const tradePartnersFilter: Array<FilterArrayProp> = useMemo(
    () =>
      allCompany?.data?.map((item) => ({
        text: item.name || '',
        value: item.id || '',
      })) || [],
    [allCompany],
  );

  const columns: Array<ProColumns<EventListItem>> = [
    {
      title: t('product_name'),
      dataIndex: 'productName',
      ellipsis: true,
      width: '8%',
      filters: productNameFilter,
      filterSearch: true,
    },
    {
      title: t('product_identifier'),
      dataIndex: 'productId',
      ellipsis: true,
      width: '8%',
      filters: productIdFilter,
      filterSearch: true,
    },
    {
      title: t('primary_id'),
      dataIndex: 'primaryId',
      render: (text, record) => (
        <Link
          to={record?.company || ''}
          className="text-link"
          onClick={(e) => {
            e.preventDefault();
            onInfoModalClick(record?.itemProps);
          }}
        >
          {text}
        </Link>
      ),
      width: '8%',
      ...primaryIdSearchfilter,
    },
    {
      title: t('quantity'),
      dataIndex: 'quantity',
      width: '8%',
    },
    {
      title: t('event'),
      dataIndex: 'event',
      filters: coreEventTypes,
      width: '8%',
      render: (text, record) => (
        <Typography.Text>
          {record?.event || ''}{' '}
          <Typography.Text type="secondary">
            {getTransformType(record?.itemProps?.transformType)}
          </Typography.Text>
        </Typography.Text>
      ),
    },
    {
      title: t('company'),
      dataIndex: 'company',
      filters: tradePartnersFilter,
      filterSearch: true,
      width: '8%',
    },
    {
      title: t('location'),
      dataIndex: 'location',
      filters: coreLocations,
      filterSearch: true,
      width: '8%',
    },
    {
      title: t('event_date'),
      sorter: true,
      dataIndex: 'eventDate',
      width: '8%',
    },
  ];

  return (
    <>
      <DetailsModal
        key={activeEvent?.id}
        modalProps={infoModal}
        event={event}
        activeEvent={activeEvent}
        productInstance={productInstance}
        product={product}
        modalWidth={modalWidth}
        closeAllModal={closeAllModal}
      />
      <GTable<EventListItem>
        actionRef={actionRef}
        columns={columns}
        value={records}
        pagination={{ defaultPageSize: params.pageSize, total: totalItems }}
        scroll={{ x: 1500, y: '75vh' }}
        options={{
          reload: false,
          setting: false,
        }}
        onTableChange={onTableChange}
        loading={isLoading}
      />

      {/* Outlet to render the sub-routes */}
      <Outlet />
    </>
  );
};

export default React.memo(EventList);

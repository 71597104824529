import { Progress, Typography } from 'antd';
import classNames from 'classnames';
import { FunctionComponent, useEffect } from 'react';
import styles from './Loading.module.less';
import { LoadingProps } from './typings';

const Loading: FunctionComponent<LoadingProps> = ({
  isLoading,
  fullOpacity,
  isFloating,
  percent,
  setTick,
  label,
}) => {
  const maxLoad = 100; // total percentage to cover
  const fulfillInterval = 5000; // clear interval timeout
  const step = maxLoad / (fulfillInterval / 1000); // % filled every step

  useEffect(() => {
    const id = setInterval(
      () => {
        // eslint-disable-next-line no-shadow
        setTick?.((tick: number) => {
          const count = Math.floor(tick + step);
          if (count === 100) {
            if (isLoading) {
              return 99;
            }
            return 100;
          }
          return count;
        }); // No dependency anymore
      },
      isLoading ? 1000 : 100,
    );

    setTimeout(() => {
      clearInterval(id);
    }, fulfillInterval + 1);

    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div
      className={classNames(styles.container, styles.column, {
        [styles.fullopacity]: fullOpacity,
        [styles.floating]: isFloating,
      })}
    >
      <Typography.Text className={styles.title}>{label}</Typography.Text>
      <Progress
        size="small"
        showInfo={false}
        percent={percent || 0}
        strokeColor="#3f51b5"
        className={styles.line}
      />
    </div>
  );
};

export default Loading;

import { GTableDateType } from 'components/GTable/contexts/typings';
import { Key, useCallback, useMemo, useState } from 'react';

const useTableRowSelection = () => {
  const [selectedRowKeys, setSelectedKeys] = useState<Array<Key>>([]);
  const [selectedRows, setSelectedRows] = useState<Array<GTableDateType>>([]);
  const onRowSelectionChange = useCallback(
    (selectedKeys: Array<Key>, currentselectedRows: Array<GTableDateType>) => {
      setSelectedKeys(selectedKeys);
      setSelectedRows(currentselectedRows);
    },
    [],
  );

  const value = useMemo(
    () => ({
      selectedRowKeys,
      selectedRows,
      onRowSelectionChange,
    }),
    [selectedRowKeys, selectedRows, onRowSelectionChange],
  );
  return value;
};
export default useTableRowSelection;

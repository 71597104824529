// @ts-nocheck

import { CreateWorkflowTemplateRequest, GetWorkflowTemplateByIdResponse } from 'pages/Templates';
import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  ListWorkflowTemplatesOptionalParams,
  ListWorkflowTemplatesResponse,
  ListWorkflowsOptionalParams,
  UpdateWorkflowTemplateRequest,
} from 'services/api/client/src';
import { fetchRequest } from 'services/utils/security/HttpClient';

/* list workflow  */
export const getWorkflows = () => {};

/* get workflow by id */
export const getWorkflowById = () => {};

/* create workflow */
export const createWorkflow = () => {};

/* update workflow by id */
export const updateWorkflowById = () => {};
// HttpClient.updateWorkflow(id, { body });

/* delete workflow by id */
export const deleteWorkflowById = async (id: string) =>
  // await HttpClient.deleteWorkflow(id);
  ({
    status: 200,
    id,
  });
/* list workflow templates */
export const getWorkflowTemplates = (
  params?: ListWorkflowTemplatesOptionalParams,
): Promise<ListWorkflowTemplatesResponse> =>
  fetchRequest({
    path: '/WorkflowTemplate',
    params,
  });

/* get workflow template by id */
export const getWorkflowTemplateById = (id: string): Promise<GetWorkflowTemplateByIdResponse> =>
  fetchRequest({
    path: `/WorkflowTemplate/${id}`,
  });

/* create workflow template */
export const createWorkflowTemplate = (body?: CreateWorkflowTemplateRequest) =>
  fetchRequest({
    path: '/WorkflowTemplate',
    method: 'POST',
    body,
  });

/* update workflow template by id */
export const updateWorkflowTemplateById = (id: string, body?: UpdateWorkflowTemplateRequest) =>
  fetchRequest({
    path: `/WorkflowTemplate/${id}`,
    method: 'PUT',
    body,
  });
/* delete workflow template by id */
export const deleteWorkflowTemplateById = async (id: string) =>
  // await HttpClient.deleteWorkflowTemplate(id);
  ({
    status: 200,
    id,
  });

/* queries */

export const useWorkflowTemplateById = (id: string) =>
  useQuery(['workflowTemplate', id], () => getWorkflowTemplateById(id), {
    // disable refetch on window focus and switch tab
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useWorkflowTemplates = (params?: ListWorkflowTemplatesOptionalParams) =>
  useQuery(['workflowTemplates', params], () => getWorkflowTemplates(params));

export const useCreateWorkflowTemplate = (queryClient: QueryClient) =>
  useMutation((body: CreateWorkflowTemplateRequest) => createWorkflowTemplate(body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['workflowTemplates']);
    },
    retry: 0,
  });

export const useUpdateWorkflowTemplate = (queryClient: QueryClient) =>
  useMutation(
    ({ id, body }: { id: string; body: CreateWorkflowTemplateRequest }) =>
      updateWorkflowTemplateById(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['workflowTemplates']);
      },
    },
  );

export const useDeleteWorkflowTemplate = (queryClient: QueryClient) =>
  useMutation((id: string) => deleteWorkflowTemplateById(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['workflowTemplates']);
    },
  });

export const useDeleteWorkflowTemplates = (queryClient?: QueryClient) =>
  useMutation((ids: Array<string>) => Promise.all(ids.map(deleteWorkflowTemplateById)), {
    onSuccess: () => {
      if (queryClient) {
        queryClient.invalidateQueries('workflowTemplates');
      }
    },
  });

export const useWorkflowById = (id: string) =>
  useQuery(['workflow', id], () => getWorkflowById(), {
    // disable refetch on window focus and switch tab
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useWorkflows = (params?: ListWorkflowsOptionalParams) =>
  useQuery(['workflows', params], () => getWorkflows());

export const useCreateWorkflow = () => {};

export const useUpdateWorkflow = () => {};

export const useDeleteWorkflow = (queryClient: QueryClient) =>
  useMutation((id: string) => deleteWorkflowById(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['workflows']);
    },
  });

export const useDeleteWorkflows = (queryClient?: QueryClient) =>
  useMutation((ids: Array<string>) => Promise.all(ids.map(deleteWorkflowById)), {
    onSuccess: () => {
      if (queryClient) {
        queryClient.invalidateQueries(['workflows']);
      }
    },
  });

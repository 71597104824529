import { ArrowLeftOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Button, Space, message } from 'antd';
import React, { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler } from 'utils';
import styles from './ShareProduct.module.less';
import ShareProductFormFields from './ShareProductFormFields';
import { DataItem, ShareProductProps } from './typings';
import useShareProductStore from './useShareProductStore';

const ShareProduct: FC<ShareProductProps> = ({ modal, product }) => {
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const { t } = useTranslation('pages', { keyPrefix: 'share_product' });
  const partners = useShareProductStore((state) => state.tradePartners);
  const setPartners = useShareProductStore((state) => state.setTradePartners);

  const onFinish = async (formData: DataItem) => {
    try {
      const reqBody = {
        ...formData,
      };
      // eslint-disable-next-line no-console
      console.log(reqBody);
      message.success(t('submit_sucess'));
      modal.hide();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
    setPartners([]);
  }, [setPartners]);

  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
  }, [modal, resetFields]);

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={ShareProductFormFields({ t })}
      grid
      // @ts-ignore
      title={
        <Space>
          {(partners?.length || 0) > 0 && (
            <Button type="text" size="small" onClick={resetFields}>
              <ArrowLeftOutlined />
            </Button>
          )}
          {t('share_product_title', { name: product?.name })}
        </Space>
      }
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '40%',
        ...modal,
        onCancel: closeModal,
        className: styles.modal,
      }}
      submitter={{
        searchConfig: {
          submitText: (partners?.length || 0) > 0 ? t('send') : t('done'),
          resetText: t('cancel'),
        },
        resetButtonProps: {
          shape: 'round',
          onClick: closeModal,
          hidden: (partners?.length || 0) === 0,
        },
        submitButtonProps: {
          shape: 'round',
        },
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default React.memo(ShareProduct);

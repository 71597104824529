import { SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useBizStepOptions } from 'services/api/useBizSteps';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

const BizStepSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const { data, isLoading } = useBizStepOptions();
  const { t } = useTranslation('pages', { keyPrefix: 'select' });

  const fetchData: FetchDataFn<ValueType> = useCallback(() => {}, []);

  return (
    <GSelect<ValueType, OptionType>
      placeholder={t('bizstep_placeholder')}
      {...props}
      loading={isLoading}
      options={data as OptionType[]}
      fetchData={fetchData}
      allowFilterOption
      optionFilterProp="label"
    />
  );
};

export default React.memo(BizStepSelect);

// @ts-nocheck
import { ProColumns } from '@ant-design/pro-table';
import { Modal, Segmented, Tag, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SegmentedValue } from 'antd/lib/segmented';
import { Actions, ComingSoon, TitleBar } from 'components';
import GTable from 'components/GTable';
import { useGTableContext } from 'components/GTable/contexts';
import useTableSearchFilter from 'hooks/useTableSearchFilter';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTemplates } from 'services/api';
import { GetEventTemplatesOptionalParams } from 'services/api/client/src';
import { dateLocalFormat } from 'utils';
import { ActionMenu, TemplateDocumentsItem, TemplateTitleProps } from './typings';

const mainActionItems = ({ selectedRowKeys }: ActionMenu): Array<ItemType> => [
  {
    key: 'addDocumentTemplate',
    label: 'Add Document Template',
  },
  {
    key: 'manage',
    label: 'Manage',
    type: 'group',
    children: [
      {
        key: 'templateAttributes',
        label: 'Template Attributes',
      },
      {
        key: 'request',
        label: 'Request',
        disabled: !selectedRowKeys?.length,
      },
      {
        key: 'delete',
        label: 'Delete',
        disabled: !selectedRowKeys?.length,
      },
    ],
  },
];
const actionItems: ItemType[] = [
  {
    key: 'view_template',
    label: 'View Template',
  },
  {
    key: 'edit_template',
    label: 'Edit Template',
  },
  {
    key: 'view_preview',
    label: 'View Preview',
  },
  {
    key: 'request',
    label: 'Request',
  },
  {
    key: 'duplicate',
    label: 'Duplicate',
  },
  {
    key: 'delete',
    label: 'Delete',
  },
];

type ColumnsType = {
  productSearchfilter: ProColumns;
  corePermissions: Array<{ text: string; value: string }>;
  handleActionsMenuClick: (actionItemKey: string, actionPayload?: TemplateDocumentsItem) => void;
};

const columns = ({
  productSearchfilter,
  corePermissions,
  handleActionsMenuClick,
}: ColumnsType): ProColumns<TemplateDocumentsItem>[] => [
  {
    title: 'Template Title',
    dataIndex: 'title',
    width: '20.52%',
    ...productSearchfilter,
  },
  {
    title: 'Document Type',
    dataIndex: 'documentType',
    sorter: true,
    width: '16%',
  },
  {
    title: 'Permissions',
    dataIndex: 'permission',
    filters: corePermissions,
    render: (text) => <Tag>{text}</Tag>,
    width: '14.52%',
  },
  {
    title: 'Last Edited',
    dataIndex: 'updatedAt',
    sorter: true,
    width: '17.43%',
  },
  {
    title: 'Shared',
    dataIndex: 'shared',
    sorter: true,
    render: (text) => <Tag color="geekblue">{text}</Tag>,
    width: '19.34%',
  },
  {
    dataIndex: 'actions',
    render: (text, record) => (
      <Actions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        items={actionItems}
        onClick={handleActionsMenuClick}
        actionPayload={record}
      />
    ),
    width: '10.17%',
  },
];

const TemplatesTitle: FC<TemplateTitleProps> = ({ records, className }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates' });
  const navigate = useNavigate();

  const templateTypes = useMemo(() => [t('tab_events_title'), t('tab_documents_title')], [t]);
  const [templateType, setTemplateType] = useState<string | number>(templateTypes[1]);

  const { selectedRowKeys } = useGTableContext();
  const singleIdx = records.findIndex((el) => el.id === selectedRowKeys[0]);

  const onDeleteTemplate = () => {
    const greaterthanone = selectedRowKeys.length > 1;
    Modal.warning({
      title: greaterthanone
        ? t('delete_modal.title_many', { count: selectedRowKeys.length })
        : t('delete_modal.title', { name: records[singleIdx].title }),
      content: greaterthanone ? t('delete_modal.content_many') : t('delete_modal.content'),
      okCancel: true,
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
        shape: 'round',
      },
      okText: t('delete_modal.ok_text'),
      cancelText: t('delete_modal.cancel_text'),
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
    });
  };
  const onRequestTemplate = () => {};
  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'addDocumentTemplate':
        navigate('../add');
        break;
      case 'templateAttributes':
        break;
      case 'request':
        onRequestTemplate();
        break;
      case 'delete':
        onDeleteTemplate();
        break;

      default:
        break;
    }
  };

  const onTemplateTitleChange = (value: SegmentedValue) => {
    setTemplateType(value);
    switch (value) {
      case templateTypes[0]:
        navigate(-1);
        break;
      case templateTypes[1]:
        navigate({ pathname: '../documents' });
        break;

      default:
        break;
    }
  };

  return (
    <TitleBar
      title={t('title')}
      extraTitle={
        <Segmented
          options={templateTypes}
          value={templateType || templateTypes[0]}
          onChange={onTemplateTitleChange}
        />
      }
      extra={
        selectedRowKeys.length > 0 && (
          <Typography.Title level={5} className="selectiontext">
            {`${selectedRowKeys.length} Item Selected`}
          </Typography.Title>
        )
      }
      actionItems={mainActionItems({ selectedRowKeys })}
      onActionItemClick={onActionItemClick}
      className={className}
      actionProps={{
        disabled: true,
      }}
    />
  );
};
const TemplateDocuments = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates' });
  const [params, setParams] = useState<GetEventTemplatesOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
  });

  const onDelete = (actionItemKey: string, actionPayload?: TemplateDocumentsItem) => {
    Modal.warning({
      title: t('delete_modal.title', { name: actionPayload?.title || '' }),
      content: t('delete_modal.content'),
      okCancel: true,
      cancelButtonProps: {
        type: 'primary',
        ghost: true,
        shape: 'round',
      },
      okText: t('delete_modal.ok_text'),
      cancelText: t('delete_modal.cancel_text'),
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
    });
  };

  const navigate = useNavigate();
  const handleActionsMenuClick = (actionItemKey: string, actionPayload?: TemplateDocumentsItem) => {
    switch (actionItemKey) {
      case 'view_template':
        navigate(String(actionPayload?.id || 0), {
          state: {
            templateName: actionPayload?.title,
          },
        });
        break;
      case 'edit_template':
        break;
      case 'view_preview':
        break;
      case 'request':
        break;
      case 'duplicate':
        break;
      case 'delete':
        onDelete(actionItemKey, actionPayload);
        break;

      default:
        break;
    }
  };
  const corePermissions = [
    { text: 'Viewer', value: 'viewer' },
    { text: 'Editor', value: 'editor' },
  ];
  const { data, isLoading } = useTemplates(params);
  const sharingOptions = ['With a seafood company', 'with 3 others'];
  const records: Array<TemplateDocumentsItem> =
    data?.results?.map((item) => ({
      id: item.id,
      title: item.templateName,
      documentType: 'Purchase Order',
      permission: corePermissions[Math.floor(Math.random() * corePermissions.length)].text,
      updatedAt: dateLocalFormat(new Date(item?.lastModifiedTime || new Date())),
      createdAt: dateLocalFormat(new Date(item?.createdTime || new Date())),
      shared: [sharingOptions[Math.floor(Math.random() * sharingOptions.length)]],
    })) || [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSearch = (text: string) => {
    setParams({
      ...params,
      templateName: text,
    });
  };
  const { filters: productSearchfilter } = useTableSearchFilter({
    title: 'Template Name',
    onSearch,
  });
  return (
    <>
      <TemplatesTitle records={records} className="page-header" t={t} />
      <ComingSoon />
      <div className="no-display">
        <GTable<TemplateDocumentsItem>
          columns={columns({ productSearchfilter, corePermissions, handleActionsMenuClick })}
          value={records}
          loading={isLoading}
          enableRowSelection
          options={{
            reload: false,
            setting: false,
          }}
        />
      </div>
    </>
  );
};

export default TemplateDocuments;

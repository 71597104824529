import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { TimezoneSelect } from 'components/GSelect';
import BizStepSelect from 'components/GSelect/BizStepSelect';
import DispositionSelect from 'components/GSelect/DispositionSelect';
import moment from 'moment';
import { DataItem as DocumentItem } from 'pages/Documents/Forms/typings';
import { useTranslation } from 'react-i18next';
import { CertificationInfo, Documents, EventDetails } from '../Sections';
import DisaggregateProducts from './TransformTables/DisaggregateProducts';
import { DisaggregateProductItem, DisaggregationFormFieldsProps } from './typings';

const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const colProps = { xs: 24, sm: 12, md: 12, lg: 6 };

export type DataItem = {
  location: string;
  poNumber: string;
  date: string;
  time: string;
  timeZone?: string;
  bizStep?: string;
  disposition?: string;
  eventProducts: Array<DisaggregateProductItem>;
  documents?: Array<DocumentItem>;
  csvData?: Array<DataItem>;
  customProperties?: Array<string>;
  certificationList?: Array<CertificationInfo>;
};

export const DisaggregationFormFields = ({
  hasAdvancedSettings,
  dataEntryMethod,
  defaultTimezone,
  lastEventDate,
  disabledDate,
  onChangeDate,
  disabledTime,
  timeValidation,
  isSerial,
  identifier,
}: DisaggregationFormFieldsProps): ProFormColumnsType<DataItem>[] => {
  const isManualEntry = dataEntryMethod === 'manual';
  const { t } = useTranslation('pages', { keyPrefix: 'events.disaggregate' });

  return [
    {
      renderFormItem: () => <EventDetails />,
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['date'],
      },
      columns: ({ date }: DataItem) => [
        {
          title: t('form_fields.date_title'),
          valueType: 'date',
          dataIndex: 'date',
          initialValue: null,
          hideInForm: !isManualEntry,

          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.date_req'),
              },
            ],
          },
          fieldProps: (form) => ({
            placeholder: t('form_fields.date_placeholder'),
            className: 'full-width',
            disabledDate,
            onChange: (value: string) => onChangeDate?.(form, value),
          }),
          colProps,
        },
        {
          title: t('form_fields.time_title'),
          hideInForm: !isManualEntry,
          valueType: 'time',
          dataIndex: 'time',
          initialValue: '12:00:00',
          className: 'timecol',
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_req'),
              },
              ...(timeValidation?.(date) || []),
            ],
          },
          fieldProps: {
            placeholder: t('form_fields.time_placeholder'),

            className: 'full-width',
            // check is same day as date
            disabledTime:
              moment(date).isValid() &&
              moment(lastEventDate).isValid() &&
              moment(new Date(String(lastEventDate))).isSame(date, 'day')
                ? disabledTime
                : undefined,
          },

          colProps,
        },
      ],
    },

    {
      valueType: 'group',
      rowProps: { gutter: 32 },
      columns: [
        {
          title: t('form_fields.time_zone_title'),
          dataIndex: 'timeZone',
          valueType: 'select',
          initialValue: defaultTimezone,
          fieldProps: {
            placeholder: t('form_fields.time_zone_placeholder'),
          },

          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_zone_req'),
              },
            ],
          },
          renderFormItem: () => <TimezoneSelect />,

          colProps: !hasAdvancedSettings ? colPropsNull : colProps,
        },
        {
          title: t('form_fields.bizstep_title'),
          dataIndex: 'bizStep',
          initialValue: 'urn:epcglobal:cbv:bizstep:unpacking',
          valueType: 'select',
          renderFormItem: () => <BizStepSelect />,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.bizstep_req'),
              },
            ],
          },
          tooltip: {
            icon: <InfoCircleOutlined />,
            title: t('form_fields.bizstep_tooltip'),
            key: null,
            type: '',
            props: null,
          },
          colProps: !hasAdvancedSettings ? colPropsNull : colProps,
        },
        {
          title: t('form_fields.disposition_title'),
          dataIndex: 'disposition',
          initialValue: 'urn:epcglobal:cbv:disp:in_progress',
          valueType: 'select',
          renderFormItem: () => <DispositionSelect />,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.disposition_req'),
              },
            ],
          },
          fieldProps: {
            placeholder: t('form_fields.disposition_placeholder'),
          },
          tooltip: {
            icon: <InfoCircleOutlined />,
            title: t('form_fields.disposition_tooltip'),
            key: null,
            type: '',
            props: null,
          },
          colProps: !hasAdvancedSettings ? colPropsNull : colProps,
        },
      ],
    },
    {
      dataIndex: 'eventProducts',
      initialValue: [],
      renderFormItem: (_schema, _config, form) => (
        <DisaggregateProducts form={form} isSerial={isSerial} identifier={identifier} />
      ),
      colProps: {
        xs: 24,
        md: 24,
      },
    },
    {
      valueType: 'divider',
    },
    {
      dataIndex: 'documents',
      initialValue: [],
      renderFormItem: (_row, _config, form) => <Documents form={form} />,
      colProps: colPropsFull,
    },
  ];
};

export default DisaggregationFormFields;

import { ProColumns } from '@ant-design/pro-table';
import { Segmented, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SegmentedValue } from 'antd/lib/segmented';
import { Actions, TitleBar } from 'components';
import GTable from 'components/GTable';
import { useGTableContext } from 'components/GTable/contexts';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ShipmentTag from './components/ShipmentTag';
import useShipmentStore, { useBatchShipFilters } from './hooks';
import {
  ActionItemProps,
  ShipmentTicketItem,
  ShipmentsTitleProps,
  TicketsColumnsType,
} from './typings';

const ActionItems = ({ record, t }: ActionItemProps): Array<ItemType> => [
  record?.status !== 'DRAFT'
    ? {
        key: 'view_ticket',
        label: t?.('view_ticket'),
      }
    : {
        key: 'view_draft',
        label: t?.('view_draft'),
      },
];

const Columns = ({
  t,
  productSearchfilter,
  primaryIdSearchfilter,
  dispatchDateSearchfilter,
  logisticIdSearchfilter,
  onRowActionClick,
}: TicketsColumnsType): ProColumns<ShipmentTicketItem>[] => [
  {
    title: t?.('col_ticket_id_title'),
    dataIndex: 'ticketId',
    sorter: true,
    fixed: 'left',
    width: 200,
    hideInSetting: true,
    ellipsis: true,
    render: (text, record) => (
      <Link
        className="text-link"
        to={record?.status !== 'DRAFT' ? `${record?.id || ''}` : `${record?.id || ''}/update`}
        title={`View ${text}`}
      >
        {text}
      </Link>
    ),
  },
  {
    title: t?.('col_products_title'),
    dataIndex: 'totalProducts',
    sorter: false,
    width: 200,
    ...productSearchfilter,
  },
  {
    title: t?.('col_lots_title'),
    dataIndex: 'totalLots',
    width: 143,
    ...primaryIdSearchfilter,
  },

  {
    title: t?.('col_dispatch_date_title'),
    dataIndex: 'dispatchDate',
    width: 240,
    ellipsis: true,
    ...dispatchDateSearchfilter,
  },
  {
    title: t?.('col_logistic_id_title'),
    dataIndex: 'logisiticId',
    width: 186,
    ellipsis: true,
    ...logisticIdSearchfilter,
  },
  {
    title: t?.('col_quantity_title'),
    dataIndex: 'quantity',
    sorter: true,
    width: 186,
  },
  {
    title: t?.('col_status_title'),
    dataIndex: 'status',
    filters: [
      {
        text: t?.('draft'),
        value: 'DRAFT',
      },
      {
        text: t?.('pending'),
        value: 'PENDING',
      },
    ],
    width: 152,
    render: (text, record) => <ShipmentTag status={record.status} />,
  },
  {
    title: t?.('col_logged_date_title'),
    dataIndex: 'createdDate',
    sorter: false,
    width: 186,
  },
  {
    dataIndex: 'actions',
    render: (text, record) =>
      record?.id ? (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
          }}
          items={ActionItems({ record, t })}
          onClick={onRowActionClick}
          actionPayload={record}
        />
      ) : undefined,
    fixed: 'right',
    width: 117,
    hideInSetting: true,
  },
];
const ShipmentsTitle: FC<ShipmentsTitleProps> = ({ t }) => {
  const shipmentTypes = useMemo(() => [t('inbound'), t('outbound'), t('tickets')], [t]);

  const [shipmentType, setShipmentType] = useState<string | number>(shipmentTypes[2]);
  const { selectedRowKeys } = useGTableContext();
  const navigate = useNavigate();
  const { setAdvancedSettings, setDataEntryMethod } = useShipmentStore();

  const onBatchShip = () => {
    navigate('batch_ship');
    setDataEntryMethod('manual');
    setAdvancedSettings(false);
  };

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'batch_ship':
        onBatchShip();
        break;

      default:
        break;
    }
  };
  const onChangeShipmentType = (value: SegmentedValue) => {
    setShipmentType(value);
    switch (value) {
      case t('inbound'):
        navigate('../', { replace: true });
        break;
      case t('outbound'):
        navigate('../outbound', { replace: true });
        break;
      case t('tickets'):
        navigate('../tickets', { replace: true });
        break;

      default:
        break;
    }
  };
  const mainActionItems: Array<ItemType> = [
    {
      key: 'batch_ship',
      label: t?.('batch_ship'),
    },
  ];

  return (
    <TitleBar
      title={`${t('shipment_title')} `}
      extraTitle={
        <Segmented
          options={shipmentTypes}
          value={shipmentType || shipmentTypes[0]}
          onChange={onChangeShipmentType}
        />
      }
      extra={
        selectedRowKeys.length > 0 && (
          <Typography.Title className="selectiontext" level={5}>
            {`${selectedRowKeys.length} ${
              selectedRowKeys.length === 1 ? t?.('item') : t?.('items')
            } ${t?.('selected')}`}
          </Typography.Title>
        )
      }
      actionItems={mainActionItems}
      onActionItemClick={onActionItemClick}
    />
  );
};

const ShipmentTickets = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.tickets' });
  const {
    params,
    // records,
    // totalItems,
    // isLoading,
    onTableChange,
    // refetch,
    filters: [
      productSearchfilter,
      primaryIdSearchfilter,
      dispatchDateSearchfilter,
      logisticIdSearchfilter,
    ],
  } = useBatchShipFilters();

  const onRowActionClick = (actionItemKey: string, actionPayload?: ShipmentTicketItem) => {
    switch (actionItemKey) {
      case 'view_ticket':
        navigate(`${actionPayload?.id || ''}`);
        break;
      case 'view_draft':
        navigate(`${actionPayload?.id || ''}/update`);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <GTable<ShipmentTicketItem>
        columns={Columns({
          t,
          onRowActionClick,
          productSearchfilter,
          primaryIdSearchfilter,
          dispatchDateSearchfilter,
          logisticIdSearchfilter,
        })}
        headerTitle={<ShipmentsTitle t={t} />}
        // value={records}
        scroll={{ x: 1300, y: '75vh' }}
        // loading={isLoading}
        pagination={{
          defaultPageSize: params.pageSize,
          // total: totalItems,
        }}
        columnsState={{
          persistenceKey: 'pages-shipment-tickets',
        }}
        // @ts-ignore
        onTableChange={onTableChange}
        options={
          {
            // reload: () => refetch(),
          }
        }
      />
      {/* Outlet used to render the drawers */}
      <Outlet />
    </>
  );
};
export default React.memo(ShipmentTickets);

import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, message, Modal } from 'antd';
import EventTypesModal from 'components/EventTypesModal';
import { useDrawerVisibility } from 'hooks';
import useModalVisibility from 'hooks/useModalVisibility';
import useReceiveEventActions from 'pages/AllShipments/hooks/useReceiveEventActions';
import { DataItem } from 'pages/Documents/Forms/typings';
import { useShipmentStore } from 'pages/Shipments';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { useDeleteDocument } from 'services/api/documents';
import { errorHandler } from 'utils';
import {
  Aggregation,
  Commission,
  Decommission,
  Disaggregate,
  Observe,
  Ship,
  Transform,
  useEventActions,
} from './components';
import AllReceive from './components/AllReceive';
import { Footer, Header } from './components/Sections';
import ReceiveHeader from './components/Sections/ReceiveHeader';
import { useEventsStore } from './hooks';

const Events = () => {
  const queryClient = useQueryClient();
  const deleteDocument = useDeleteDocument(queryClient);
  const { t } = useTranslation('pages', { keyPrefix: 'events' });
  const { locationId, tradePartnerId, locationName, onChangeLocation } = useEventActions({}, true);
  const { onChangeLocation: onChangeReceiveLocation } = useReceiveEventActions();
  const { setSelectedShipments } = useShipmentStore();
  const [queryParams] = useSearchParams();
  const [locationForm] = Form.useForm();

  const { closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);

  const { title, isSubmitting, dataEntryMethod } = useEventsStore();
  const [mainForm] = Form.useForm();
  const [headerForm] = Form.useForm(mainForm);
  const infoModal = useModalVisibility(false);
  const locationDisabled = ['ship', 'disaggregate', 'aggregation', 'transform', 'decomission'].some(
    (el) => String(title).toLowerCase().includes(el),
  );

  /* set default value for templates */

  const onSubmit = async () => {
    try {
      if (title === 'receive') {
        mainForm.validateFields();
        await locationForm.validateFields();
        mainForm.submit();
      } else {
        await mainForm.validateFields();
        mainForm.submit();
      }
    } catch (error) {
      message.error(t('events_field_req'));
    }
  };

  const checkAndDeleteIfNewFileUploaded = () => {
    const documents: Array<DataItem> = mainForm.getFieldValue('documents');

    const newFiles = documents?.filter((document) => document?.file);
    if (newFiles?.length) {
      newFiles.forEach(async (file) => {
        try {
          await deleteDocument.mutateAsync({ id: file?.id || '' });
        } catch (error) {
          if (errorHandler(error)) {
            message.error(errorHandler(error));
          }
        }
      });
    }
    setSelectedShipments([]);
    closeDrawer();
  };

  /* on close modal */
  const onClose = () => {
    const hasFilled = mainForm.isFieldsTouched();
    if (hasFilled) {
      Modal.warning({
        title: t?.('unsaved_changes_title'),
        content: t?.('unsaved_changes_desc'),
        okText: t?.('unsaved_changes_submit'),
        cancelText: t?.('unsaved_changes_cancel'),
        onOk: checkAndDeleteIfNewFileUploaded,
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      });
    } else {
      setSelectedShipments([]);
      closeDrawer();
    }
  };

  return (
    <>
      <EventTypesModal
        activeTabKey={String(title).toLowerCase()}
        onOk={() => infoModal.setVisible(false)}
        {...infoModal}
      />
      <Drawer
        height="100%"
        key="events-drawer"
        placement="top"
        size="large"
        title={
          title === 'receive' ? (
            <ReceiveHeader
              t={t}
              isManual={dataEntryMethod === 'manual'}
              title={title}
              form={mainForm}
              currentForm={locationForm}
              onInfoClick={() => infoModal.show()}
              locationUrn={
                queryParams?.get('locationUrn')?.trim()
                  ? queryParams?.get('locationUrn')?.trim()
                  : undefined
              }
              tradePartnerUrn={
                queryParams?.get('tradePartnerUrn')?.trim()
                  ? queryParams?.get('tradePartnerUrn')?.trim()
                  : undefined
              }
              locationId={
                queryParams?.get('locationId')?.trim()
                  ? queryParams?.get('locationId')?.trim()
                  : undefined
              }
              locationName={locationName}
              locationDisabled={locationDisabled}
              onChangeLocation={(form, loc, isMultiLoc) =>
                onChangeReceiveLocation(form, loc, isMultiLoc)
              }
            />
          ) : (
            <Header
              t={t}
              isManual={dataEntryMethod === 'manual'}
              title={title}
              form={headerForm}
              onInfoClick={() => infoModal.show()}
              locationId={locationId?.trim() ? locationId?.trim() : undefined}
              tradePartnerId={tradePartnerId?.trim() ? tradePartnerId?.trim() : undefined}
              locationName={locationName}
              locationDisabled={locationDisabled}
              onChangeLocation={(form, loc) => onChangeLocation(form, loc)}
            />
          )
        }
        className="events-drawer"
        closeIcon={
          <Button type="text">
            <CloseOutlined />
          </Button>
        }
        footer={<Footer t={t} form={mainForm} isSubmitting={isSubmitting} onSubmit={onSubmit} />}
        {...restDrawerVisibility}
        onClose={onClose}
      >
        <Routes>
          <Route path="commission" element={<Commission form={mainForm} />} />
          <Route path="decommission" element={<Decommission form={mainForm} />} />
          <Route path="receive" element={<AllReceive form={mainForm} />} />
          <Route path="aggregation" element={<Aggregation form={mainForm} />} />
          <Route path="disaggregation" element={<Disaggregate form={mainForm} />} />
          <Route path="observe" element={<Observe form={mainForm} />} />
          <Route path="ship/:lotId" element={<Ship form={mainForm} />} />
          <Route path="transform/:lotId" element={<Transform form={mainForm} />} />
        </Routes>
      </Drawer>
    </>
  );
};

export default Events;

import { Space, Tag, Tooltip, Typography } from 'antd';
import { useModalVisibility } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SingleColumnModal from './SingleColumnModal';
import styles from './index.module.less';
import { SingleColumnProps } from './typings';

const MultiTags: FC<SingleColumnProps> = ({ values, columnTitle, modalTitle, colTableTitle }) => {
  const singleColTableModal = useModalVisibility();
  const { t } = useTranslation('pages', { keyPrefix: 'documents' });

  return (
    <>
      <SingleColumnModal
        modal={singleColTableModal}
        values={values}
        modalTitle={modalTitle || ''}
        columnTitle={colTableTitle || columnTitle || ''}
      />
      {values?.length ? (
        <Space direction="horizontal" className={styles.tagcontainer}>
          <Tooltip placement="bottom" title={<div>{t('click_to_view')}</div>}>
            {values?.length && values?.length > 1 ? (
              (
                <Tag className="document-tag" onClick={() => singleColTableModal?.show()}>
                  {`${values?.length} ${columnTitle}` || ''}
                </Tag>
              ) || ''
            ) : (
              <Tag className="document-tag" onClick={() => singleColTableModal?.show()}>
                <Typography.Text type="secondary" ellipsis className={styles.tagwidth}>
                  {values?.[0] || ''}
                </Typography.Text>
              </Tag>
            )}
          </Tooltip>
        </Space>
      ) : (
        <Typography.Text>-</Typography.Text>
      )}
    </>
  );
};
export default MultiTags;

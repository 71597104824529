// @ts-nocheck
/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { GraphData, NodeConfig } from '@antv/g6/lib/types';
import { Form, message } from 'antd';
import { useActions } from 'hooks';
import moment from 'moment';
import {
  DecommissionDataItem,
  DecommissionFormFields,
  Header,
  useEventActions,
  useEventsStore,
  useTitle,
} from 'pages/Events';

import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EnrichedWorkflowDecommissionEvent,
  WorkflowDecommissionEvent,
} from 'services/api/client/src';
import {
  dateFormat,
  dateLocaleFormat,
  errorHandler,
  getLocationById,
  getLocationNameById,
  getProductNameById,
  getTimezoneOffset,
  getUom,
  onCreateStoreInstance,
  timeLocaleFormat,
} from 'utils';
import styles from '../index.module.less';
import { EventProps, WorkflowEvent } from '../typings';

type DataItem = DecommissionDataItem;
const Commission: FC<EventProps> = ({
  form,
  drawer,
  event,
  isVisible,
  selectedInstances,
  instances,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.decommission' });
  useTitle(t('title'));
  const locationDisabled = true;
  const [mainForm] = Form.useForm<DataItem>();
  const { setFieldsValue } = mainForm;
  const formProductId = Form.useWatch(['product'], mainForm);
  const graphData: GraphData = form?.getFieldValue('graphData') || {
    nodes: [],
    edges: [],
  };

  const {
    lastEventDate,
    product: dataProduct,
    defaultTimezone,
    isSerial,
    identifier,
    locationId,
    onChangeTemplate,
    disabledDate,
    disabledTime,
    onChangeDate,
    onChangeLocation,
  } = useEventActions({
    productId: event?.productId || undefined,
    locationId: event?.locationId || selectedInstances?.[0]?.locationId || undefined,
    eventDate: dateLocaleFormat(selectedInstances?.[0]?.eventDate || new Date()) || '',
  });
  const { hasAdvancedSettings, dataEntryMethod, template, setSubmitting, setAdvancedSettings } =
    useEventsStore();
  const { products, locations, product } = useActions({ productId: formProductId });
  /* effect to sync eventData in event */
  useEffect(() => {
    setAdvancedSettings(false);
    if (event?.eventData) {
      const edata = event?.eventData as EnrichedWorkflowDecommissionEvent;
      setFieldsValue({
        date: edata?.eventTime ? dateFormat(edata?.eventTime) : undefined,
        time: edata?.eventTime ? timeLocaleFormat(edata?.eventTime) : undefined,
        lotID: edata?.newProducts?.[0]?.lotSerial,
        location: edata?.locationId,
        poNumber: edata?.purchaseOrder,
        bizStep: edata?.bizStep || 'urn:epcglobal:cbv:bizstep:decomissioning',
        disposition: edata?.disposition || 'urn:epcglobal:cbv:disp:inactive',
        timeZone: defaultTimezone,
        customData: event?.eventTemplateId || edata?.templateId,
      });
    }
  }, [event, setAdvancedSettings, setFieldsValue, defaultTimezone]);

  const onCloseDrawer = () => {
    mainForm?.resetFields();
    drawer?.closeDrawer();
  };
  const onFormSubmit = async (formData: DataItem) => {
    try {
      const updatedBody: WorkflowDecommissionEvent = {
        id: event?.id,
        eventIdInTemplate: event?.id,
        eventId: event?.id,
        // @ts-ignore
        sourceId: selectedInstances?.map((i) => i?.id) || [],
        bizStep: formData.bizStep,
        disposition: formData.disposition,
        eventTime: new Date(moment(`${formData.date} ${formData.time}`).toISOString()),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        purchaseOrder: formData.poNumber,
        decommissionType: formData.decommissionType,
        products: formData?.eventProducts?.map((p) => ({
          instanceId: p.primaryId,
          quantity: Number(p.quantity || 0),
        })),
        newProducts: formData?.eventProducts?.map((p) => ({
          instanceId: p.primaryId,
          quantity: Number(p.quantity || 0),
        })),
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        templateName: template?.name,
        customProperties:
          template?.templateFields?.map((field, idx) => ({
            name: field?.name || field?.propertyName,
            value: formData?.customProperties?.[idx]
              ? String(formData.customProperties?.[idx])
              : '',
            namespace: field?.namespace?.prefix || 'wc',
          })) || [],
      };
      const location = getLocationById(formData.location, locations);

      onCreateStoreInstance(
        {
          id: event?.id,
          name: product?.name || '',
          productId: formProductId,
          instanceId: formData.lotID,
          primaryId: selectedInstances?.[0]?.primaryId || '',
          lotSerial: selectedInstances?.[0]?.primaryId || '',
          quantity: selectedInstances?.[0]?.quantity || 0,
          unitOfMeasure: selectedInstances?.[0]?.unitOfMeasure || 'LBS',
          eventName: 'Observe',
          location: location?.name || '',
          locationAddress: location?.address?.line2 || '',
          locationId: formData.location || '',
          destinationId: undefined,
          eventId: event?.id,
          isContainer: false,
          containerId: undefined,
          containerIdentifier: '',
          containerItems: [],
          tradePartnerName: location?.tradePartnerName || '',
          address: location?.address,
          eventDate: updatedBody.eventTime,
          loggedDate: new Date(),
          purchaseOrder: formData.poNumber,
          shipmentType: undefined,
          shipped: false,
          productIdentifierType: product?.productIdentifierType,
          bizStep: formData.bizStep,
          disposition: formData.disposition,
          timezone: getTimezoneOffset(formData.timeZone),
          templateId: template?.id,
        },
        instances,
        form,
      );
      const updatedNodes = graphData.nodes?.map((item: WorkflowEvent) => {
        if (item.id === event?.id) {
          const updatedNode: NodeConfig = {
            id: item?.id || '',
            eventType: 'Decommission',
            eventTime: updatedBody?.eventTime ? dateFormat(updatedBody?.eventTime) : undefined,
            name: getProductNameById(formData?.eventProducts?.[0]?.primaryId, products),
            primaryId: selectedInstances?.[0]?.primaryId || '',
            quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
            unitOfMeasure: selectedInstances?.[0]?.unitOfMeasure || 'LBS',
            eventTemplateId: item?.eventTemplateId,
            eventTemplateName: location?.address?.line2 || location?.name || '',
            locationId: formData?.location,
            locationName: getLocationNameById(formData.location, locations),
            productId: formData.product,
            productName: getProductNameById(formData?.eventProducts?.[0]?.primaryId, products),
            colourIndex: 'colour_4',
            children: [],
          };
          return {
            ...item,
            ...updatedNode,
            eventData: updatedBody,
          };
        }
        return item;
      });
      const updatedGraphData = {
        ...graphData,
        nodes: updatedNodes,
      };

      form?.setFieldsValue({
        // @ts-ignore
        graphData: updatedGraphData,
      });
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);

      await onFormSubmit(formData);
      setSubmitting(false);
      onCloseDrawer();
    } catch (error: any) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={DecommissionFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        lastEventDate,
        defaultTimezone,
        isSerial,
        identifier,
        disabledDate,
        disabledTime,
        onChangeDate,
        onChangeTemplate,
        selectedInstances,
      })}
      grid
      rowProps={{ gutter: 32 }}
      onFinish={onFinish}
      // @ts-ignore
      title={
        <Header
          isManual={dataEntryMethod === 'manual'}
          title={t('title')}
          form={mainForm}
          locationId={locationId?.trim() ? locationId?.trim() : undefined}
          locationDisabled={locationDisabled}
          onChangeLocation={onChangeLocation}
        />
      }
      submitter={{
        searchConfig: {
          submitText: t('add_event_title'),
          resetText: t('cancel_btn_title'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
      }}
      initialValues={{
        unitOfMeasure: getUom({ product: dataProduct }),
      }}
      visible={isVisible ? drawer?.visible || false : false}
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        className: styles.eventdrawer,
        ...drawer,
      }}
    />
  );
};

export default Commission;

/* eslint-disable no-console */
import { ProColumns } from '@ant-design/pro-table';
import { TradePartnerItem } from 'components';
import * as js2xmlparser from 'js2xmlparser';
import { DataItem } from 'pages/Documents/Forms/typings';
import { TradePartnerResponse, UpdateDocumentRequest } from 'services/api/client/src';
import { getT } from 'utils';

export const corePermissions = [
  { text: 'Viewer', value: 'viewer' },
  { text: 'Editor', value: 'editor' },
];

export const downloadDocument = (url: string, fileName: string = 'document') => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.pdf`);
  link.click();
};

export const documentJSONtoXML = (data: any, fileName: string = 'document') => {
  const xml = js2xmlparser.parse('document', data);
  const blob = new Blob([xml], { type: 'text/xml;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `${fileName}.xml`);
  link.click();
};

export const isDocumentUpdated = (reqBody: UpdateDocumentRequest, document?: DataItem) => {
  /* has privacy changed */
  if (reqBody.privacy !== document?.privacy) {
    console.log('privacy changed');

    return true;
  }

  /* has location tags changed */
  if ((reqBody?.locationsIds?.length || 0) !== (document?.locations?.length || 0)) {
    console.log('locations changed', reqBody, document);
    return true;
  }
  if (reqBody?.locationsIds?.some((id) => !document?.locations?.map((el) => el?.id).includes(id))) {
    console.log('locationsid changed');
    return true;
  }

  /* has products tags changed */
  if ((reqBody?.productsIds?.length || 0) !== (document?.products?.length || 0)) {
    console.log('products changed');
    return true;
  }
  if (reqBody?.productsIds?.some((id) => !document?.products?.map((el) => el?.id).includes(id))) {
    console.log('productsid changed');
    return true;
  }

  /* has tradePartners tags changed */
  if ((reqBody?.tradePartnersIds?.length || 0) !== (document?.tradePartners?.length || 0)) {
    return true;
  }
  if (
    reqBody?.tradePartnersIds?.some(
      (id) => !document?.tradePartners?.map((el) => el?.id).includes(id),
    )
  ) {
    return true;
  }
  return false;
};

export const disableExistingPartners = (
  item?: TradePartnerResponse,
  partners?: Array<TradePartnerItem>,
) => {
  const exists = partners?.find((el) => el?.id === item?.wholechainId);
  return !!exists;
};

export const getDocTypeFilters = () => {
  const t = (key: string) => getT(`template_attributes.documents.${key}`);

  return [
    {
      text: t('columns.document_type.filters.certification'),
      value: 'Certification',
    },
    {
      text: t('columns.document_type.filters.purchase_order'),
      value: 'PurchaseOrder',
    },
    {
      text: t('columns.document_type.filters.bol'),
      value: 'BOL',
    },
    {
      text: t('columns.document_type.filters.other'),
      value: 'other',
    },
  ] as ProColumns['filters'];
};
export const getDocTypeEnum = () => {
  const t = (key: string) => getT(`template_attributes.documents.${key}`);

  return {
    certification: {
      text: t('columns.document_type.filters.certification'),
      status: 'default',
    },
    purchaseOrder: {
      text: t('columns.document_type.filters.purchase_order'),
      status: 'default',
    },
    bol: {
      text: t('columns.document_type.filters.bol'),
      status: 'default',
    },
    other: {
      text: t('columns.document_type.filters.other'),
      status: 'default',
    },
  } as ProColumns['valueEnum'];
};

import { Button, Empty, Space, Typography } from 'antd';
import { documentsEmptySvg } from 'assets';
import useAttributeStore from 'pages/Templates/Tables/useAttributeStore';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyStateProps } from './typings';

const EmptyStateActivity: FC<EmptyStateProps> = ({ t }) => {
  const navigate = useNavigate();
  const setTemplateMode = useAttributeStore((state) => state.setTemplateMode);

  return (
    <Empty
      className="empty-state"
      image={documentsEmptySvg}
      description={<Typography.Text>{t?.('activity_event.empty_title')}</Typography.Text>}
    >
      <Space>
        <Button type="primary" shape="round" ghost onClick={() => navigate('../products/add')}>
          {t?.('activity_event.empty_add_product')}
        </Button>
        <Button
          type="primary"
          shape="round"
          ghost
          onClick={() => {
            setTemplateMode?.('edit');
            navigate('../templates/add');
          }}
        >
          {t?.('activity_event.empty_add_template')}
        </Button>
      </Space>
    </Empty>
  );
};
export default EmptyStateActivity;

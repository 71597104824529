import { Button, Layout, Typography } from 'antd';
import { Loading, PDFViewer } from 'components';
import GenLayout from 'components/GenLayout';
import { signinRequest } from 'config';
import { useAuthenticationContext } from 'contexts';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDownloadDocument } from 'services/api/documents';
import { msalInstance } from 'services/utils/security';
import { setDocIdSession } from 'utils';
import styles from './index.module.less';

const RedeemInvite = () => {
  const { setIsAPIAuthenticated, setIsAuthProcess, isAuthenticated } = useAuthenticationContext();
  const [queryParams] = useSearchParams();
  const documentId = queryParams?.get('id') || '';
  const { t } = useTranslation('pages', { keyPrefix: 'access_doc' });
  const { data: attachment, isLoading, isError } = useDownloadDocument(documentId);
  const [loading, setLoading] = useState<boolean>(true);
  const onContinue = useCallback(() => {
    // navigate to b2c signup page
    msalInstance.loginRedirect({
      scopes: ['openid', 'profile', 'offline_access'],
      extraQueryParameters: {
        isDesktopApp: 'true',
      },
    });
  }, []);
  useEffect(() => {
    setIsAPIAuthenticated(true);
    setIsAuthProcess(false);
    if (!isAuthenticated && isError) {
      setDocIdSession(documentId);
      msalInstance.loginRedirect(signinRequest);
    }
    setLoading(false);
  }, [documentId, isAuthenticated, isError, onContinue, setIsAPIAuthenticated, setIsAuthProcess]);

  if (loading || isLoading) return <Loading />;

  if (attachment) return <PDFViewer file={attachment} />;

  if (isAuthenticated && isError)
    return (
      <GenLayout className={styles.backdiv}>
        <Layout.Content className={styles.layout}>
          <div className={styles.container}>
            <div className={styles.cnt}>
              <Typography.Title level={2} className={styles.title}>
                {t('title')}
              </Typography.Title>
              <div className={styles.description}>
                <Typography.Text>{t('description')}</Typography.Text>
              </div>
              <Button shape="round" type="primary" onClick={onContinue}>
                {t('submit_btn_title')}
              </Button>
            </div>
          </div>
        </Layout.Content>
      </GenLayout>
    );
  return <div />;
};
export default RedeemInvite;

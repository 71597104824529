export const lineColor = '#52C41A';
export const inboundColor = '#3F51B5';
export const outboundColor = '#52C41A';

export const leftValueStyle = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  color: '#000',
};

export const rightValueStyle = {
  fontWeight: 400,
  fontSize: '24px',
  lineHeight: '32px',
  color: '#000',
};

export const pointStyle = {
  fill: lineColor,
  stroke: lineColor,
  lineWidth: 2,
};

export const stateStyle = {
  shadowBlur: 4,
  stroke: inboundColor,
  fill: inboundColor,
  opacity: 0.3,
};

export const mainStyle = {
  height: '100%',
};

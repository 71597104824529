import { QueryClient, useInfiniteQuery, useMutation } from 'react-query';
import {
  ChangeToReadNotificationRequest,
  ListNotificationsOptionalParams,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';
import { fetchRequest } from 'services/utils/security/HttpClient';

const getNotificationsByPage = async (
  pageNumber: number,
  params?: ListNotificationsOptionalParams,
) => {
  try {
    const res = await HttpClient.listNotifications({ ...params, pageNumber });
    const result = res?.results || [];

    // /* get cached shipments */
    // const shipmentparams: GetPaginatedShipmentsOptionalParams = {
    //   pageNumber: 1,
    //   pageSize: 20,
    //   direction: 'Inbound',
    //   sortBy: '-LoggedDate',
    //   status: ['Pending'],
    // };
    // let shipments: GetPaginatedShipmentsResponse | undefined;
    // const cachedShipments = queryClient.getQueryData<GetPaginatedShipmentsResponse | undefined>([
    //   'shipments',
    //   shipmentparams,
    // ]);
    // if (cachedShipments) {
    //   shipments = cachedShipments;
    // } else {
    //   shipments = await queryClient.fetchQuery<GetPaginatedShipmentsResponse | undefined>(
    //     ['shipments', shipmentparams],
    //     () => getShipments(shipmentparams),
    //   );
    // }

    // /* get cached invites */
    // const invitesParams = {
    //   incoming: true,
    //   status: ['Pending'],
    //   isResentRequest: false,
    // };
    // const cachedInvites = queryClient.getQueryData<ListNetworkInvitesResponse | undefined>([
    //   'networkInvites',
    //   invitesParams,
    // ]);
    // let invites: ListNetworkInvitesResponse | undefined;
    // if (cachedInvites) {
    //   invites = cachedInvites;
    // } else {
    //   invites = await queryClient.fetchQuery<ListNetworkInvitesResponse | undefined>(
    //     ['listNetworkInvites', invitesParams],
    //     () => HttpClient.listNetworkInvites(invitesParams),
    //   );
    // }

    // if an object of result has action property not null then fetch the resource of that action

    const results = result?.map(async (item) => {
      /* if url exist then append query timestamp to that url */
      const sender = {
        ...item?.sender,
        profileImageUrl: item?.sender?.profileImageUrl
          ? `${item?.sender?.profileImageUrl}?t=${Date.now()}`
          : undefined,
      };

      if (item?.action?.text) {
        const resource = await fetchRequest({
          path: item?.action?.resourceUrl || '',
          isUrl: true,
        });

        return {
          ...item,
          sender,
          resource,
        };
      }

      return {
        ...item,
        sender,
      };
    });

    return {
      ...res,
      results: await Promise.all(results),
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.info('Error fetching notifications', error);
    return undefined;
  }
};

/* read notification */
const readNotifications = async (body?: ChangeToReadNotificationRequest) => {
  try {
    await HttpClient.changeToReadNotifications({ body });
    return body;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error reading notifications', error);
    return body;
  }
};

/* queries */
/* use notification query */

/* use read notification query */
export const useReadNotifications = (qClient: QueryClient) =>
  useMutation((body: ChangeToReadNotificationRequest) => readNotifications(body), {
    onSuccess: () => {
      qClient.refetchQueries(['notifications']);
    },
  });

/* use infinite query for activity */
export const useInfiniteNotifications = (params?: ListNotificationsOptionalParams) =>
  useInfiniteQuery({
    queryKey: ['notifications', params?.pageNumber, params?.pageSize, params?.type],
    queryFn: ({ pageParam = 1 }) => getNotificationsByPage(pageParam, params),
    getNextPageParam: (lastPage) => {
      /* if there is no next page, return undefined */
      if ((lastPage?.pageNumber || 0) < (lastPage?.totalPages || 0)) {
        const nextPage = (lastPage?.pageNumber || 1) + 1;
        return nextPage;
      }
      return undefined;
    },
    retry: false,
  });

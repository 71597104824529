import { Spin } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Loading.module.less';
import { LoadingProps } from './typings';

const Loading: FC<LoadingProps> = ({ isLoading = true, fullOpacity, isFloating, hideSpin }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.fullopacity]: fullOpacity,
        [styles.floating]: isFloating,
      })}
    >
      {hideSpin ? null : (
        <span className={styles.spin}>
          <Spin size="large" />
        </span>
      )}
    </div>
  );
};

export default React.memo(Loading);

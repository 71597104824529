import { Col, Row, Statistic, Typography } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPhoneNumber } from 'utils';
import { showConnectionType } from 'utils/network';
import useLocationStore from '../../hook/useLocationStore';
import { leftValueStyle, rightValueStyle } from '../../styles';
import styles from './LocationInfoBar.module.less';
import { StatisticProp, SummaryProp } from './typings';

const LeftStatistic: FC<StatisticProp> = ({ title, value }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.summary' });
  const phone = value !== '-' ? <div>{getPhoneNumber(value)?.number}</div> : <div>-</div>;

  const renderPhoneNumber = () => {
    switch (title) {
      case t('phone_number'):
        return phone;

      case t('connection_type'):
        return t(`${showConnectionType(value)}`);

      default:
        return (
          <Typography.Text
            ellipsis={{
              tooltip: `${value}`,
            }}
          >
            {value}
          </Typography.Text>
        );
    }
  };
  return (
    <Statistic
      title={title}
      value={value}
      valueStyle={leftValueStyle}
      className={styles.title}
      valueRender={renderPhoneNumber}
    />
  );
};

const RightStatistic: FC<StatisticProp> = ({ title, value }) => (
  <Statistic title={title} value={value} valueStyle={rightValueStyle} className={styles.title} />
);

const LocationInfoHeader: FC<SummaryProp> = ({ summaryInfo, isLoading }) => {
  const { isLocationView, accountType, locationAddress, lat, lng, wcId, urn } = useLocationStore();
  const { t } = useTranslation('pages', { keyPrefix: 'network.summary' });
  const { shipmentsReceived, totalEvents, shipmentsSent, connectionType, contact } = summaryInfo;
  const titleComponents = [];

  const getGeoAddressField = useMemo(() => {
    if (locationAddress === '') {
      return ['geoCoordinates', `${lat},${lng}`];
    }
    return ['address', locationAddress];
  }, [lat, lng, locationAddress]);

  const getShipmentInfo = useMemo(() => {
    const sum = (shipmentsReceived || 0) + (shipmentsSent || 0);
    if (accountType !== 'ME' && connectionType === 'BUYER') {
      return ['shipmentSent', String(shipmentsSent || 0)];
    }
    if (accountType !== 'ME' && connectionType === 'SUPPLIER') {
      return ['shipmentReceived', String(shipmentsReceived || 0)];
    }
    if (sum === 0 || (shipmentsReceived !== 0 && shipmentsSent !== 0)) {
      return ['totalShipments', String(sum)];
    }
    return shipmentsReceived
      ? ['shipmentReceived', String(shipmentsReceived || 0)]
      : ['shipmentSent', String(shipmentsSent || 0)];
  }, [accountType, connectionType, shipmentsReceived, shipmentsSent]);

  if (!isLoading) {
    const leftInfo = [
      ...(accountType !== 'ME' ? [['connectionType', connectionType || '-']] : []),
      ...(accountType !== 'NONWC' ? [['wholechainId', wcId]] : []),
      ...(isLocationView
        ? [
            ['locationUrn', urn || '-'],
            ['phoneNumber', contact?.phone ? contact.phone : '-'],
            ['email', contact?.email ? contact.email : '-'],
            getGeoAddressField,
          ]
        : [['companyUrn', urn || '-']]),
    ];
    const rightInfo = [['totalEvents', totalEvents || 0], [...getShipmentInfo]];

    const getLabel = (key: string): string => {
      switch (key) {
        case 'connectionType':
          return t('connection_type');
        case 'wholechainId':
          return t('wholechain_id');
        case 'companyUrn':
          return t('company_urn');
        case 'locationUrn':
          return t('location_urn');
        case 'phoneNumber':
          return t('phone_number');
        case 'email':
          return t('email');
        case 'address':
          return t('address');
        case 'geoCoordinates':
          return t('geo_coordinates');
        case 'totalEvents':
          return t('total_events');
        case 'totalShipments':
          return t('shipments');
        case 'shipmentSent':
          return t('shipments_sent');
        case 'shipmentReceived':
          return t('shipment_received');
        default:
          break;
      }
      return '';
    };

    titleComponents.push(
      <Row justify="space-between" gutter={[20, 20]}>
        <Row gutter={[20, 20]}>
          {leftInfo.map((item) => (
            <Col className={styles.leftpadding}>
              <LeftStatistic title={getLabel(item?.[0] || '')} value={String(item?.[1]) || ''} />
            </Col>
          ))}
        </Row>

        <Row gutter={[20, 20]}>
          {rightInfo.map((item) => (
            <Col className={styles.rightpadding}>
              <RightStatistic title={getLabel(String(item?.[0]))} value={String(item?.[1]) || ''} />
            </Col>
          ))}
        </Row>
      </Row>,
    );
  }

  return <div className={styles.headermargin}>{titleComponents}</div>;
};

export default LocationInfoHeader;

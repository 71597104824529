import { Layout, Menu, MenuProps } from 'antd';
import { useAuthenticationContext } from 'contexts';
import { Footer } from 'layouts/GeneralLayout/components';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useUserById } from 'services/api';
import { getSettingsTabSession, setSettingsTabSession } from 'utils/settings';
import AccountSettings from './AccountSettings';
import Archived from './Archived';
import DefinedAttributes from './DefinedAttributes';
import Integrations from './Integrations';
import Preferences from './Preferences';
import Products from './Products';
import Profile from './Profile';
import SubscriptionBilling from './SubscriptionBilling';
import UserPermissions from './UserPermissions';
import { routes } from './common';
import styles from './index.module.less';

const Settings = () => {
  const [queryParams] = useSearchParams();
  const sessionTab = getSettingsTabSession();
  const activeTab = queryParams?.get('tab') || sessionTab || 'profile';
  const { t } = useTranslation('pages', { keyPrefix: 'settings.drawer_item' });
  const { currentUser } = useAuthenticationContext();
  const { data: user } = useUserById(currentUser?.id || '');
  const [menuItem, setMenuItem] = useState(activeTab);
  const onSwitchComponent = (key: string) => {
    switch (key) {
      case 'profile':
        return <Profile />;
      case 'preferences':
        return <Preferences />;
      case 'account_settings':
        return <AccountSettings />;
      case 'users_permission':
        return <UserPermissions />;
      case 'integrations':
        return <Integrations />;
      case 'products':
        return <Products />;
      case 'archived':
        return <Archived />;
      case 'template_attributes':
        return <DefinedAttributes />;
      case 'subscription':
        return <SubscriptionBilling />;

      default:
        return <Profile />;
    }
  };
  const onClickMenuItem: MenuProps['onClick'] = (item) => {
    setSettingsTabSession(item.key);
    setMenuItem(item.key);
    /* If tab query param exist in url then remove it */
    if (queryParams?.get('tab')) {
      queryParams.delete('tab');
      window.history.replaceState({}, '', `${window.location.pathname}`);
    }
  };

  const visibleRoutes = useMemo(() => {
    const restrictedRoutes = ['account_settings', 'subscription'];
    if (user?.userAccounts?.filter((ac) => ac.isCurrentAccount)[0].role === 'Admin') {
      return routes;
    }
    return routes.filter((route) => !restrictedRoutes.includes(route.key));
  }, [user]);

  return (
    <Layout>
      <Layout.Sider width={200} className={styles.white} breakpoint="lg" collapsedWidth="0">
        <Menu
          mode="inline"
          defaultSelectedKeys={[activeTab]}
          className={styles.menu}
          onClick={onClickMenuItem}
        >
          {visibleRoutes.map((route) => (
            <Menu.Item key={route.key}>{t(`${route.key}`)}</Menu.Item>
          ))}
        </Menu>
      </Layout.Sider>
      <Layout.Content className={styles.container}>
        <div>{onSwitchComponent(menuItem)}</div>
        <Layout.Footer className={styles.footer}>
          <Footer />
        </Layout.Footer>
      </Layout.Content>
    </Layout>
  );
};

export default React.memo(Settings);

import { InfoCircleOutlined } from '@ant-design/icons';
import type { ProFormColumnsType } from '@ant-design/pro-form';
import { Input, RowProps } from 'antd';
import { useCallback } from 'react';
import { TFunction } from 'react-i18next';
import styles from './index.module.less';

const { TextArea } = Input;
const rowProps: RowProps = { gutter: [32, 8] };
export type DataItem = {
  locations: Array<string>;
  companies: Array<string>;
  message: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'network.share_location'>;
  setHideMessage: (prop: boolean) => void;
  isMessageHidden: boolean;
  isAllLocations: boolean;
  tradePartnerList: any;
  locationsList: any;
  setSelectedCompany: (prop: Array<string>) => void;
};

export const ShareLocationColumns = ({
  t,
  setHideMessage,
  isMessageHidden,
  isAllLocations,
  tradePartnerList,
  locationsList,
  setSelectedCompany,
}: ColumnsType): ProFormColumnsType<DataItem>[] => {
  const hideMessageInput = useCallback(
    (locations: string, companies: string) => {
      if (isAllLocations) {
        if (!locations || !companies) {
          setHideMessage(true);
          return true;
        }
        if (locations?.length === 0 || companies.length === 0) {
          setHideMessage(true);
          return true;
        }
      } else {
        if (!companies) {
          setHideMessage(true);
          return true;
        }
        if (companies.length === 0) {
          setHideMessage(true);
          return true;
        }
      }

      setHideMessage(false);
      return false;
    },
    [setHideMessage, isAllLocations],
  );

  return [
    {
      title: isMessageHidden ? t('company_select') : undefined,
      dataIndex: 'companies',
      valueType: 'select',
      rowProps,
      formItemProps: {
        rules: [
          {
            required: true,
            message: `${t('company_required')}`,
          },
        ],
      },
      valueEnum: tradePartnerList,
      tooltip: !isAllLocations && {
        icon: <InfoCircleOutlined />,
        placement: 'bottom',
        title: t('company_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      fieldProps: (form) => ({
        mode: 'multiple',
        className: styles.textselect,
        placeholder: t('company_placeholder'),
        onChange: (val: any) => {
          setSelectedCompany(val);
          form?.setFieldsValue({
            locations: [],
          });
        },
        getPopupContainer: (triggerNode) => triggerNode.parentElement,
      }),
    },
    {
      title: isMessageHidden ? t('location_select') : '',
      dataIndex: 'locations',
      hideInForm: !isAllLocations,
      valueType: 'select',
      valueEnum: locationsList,
      rowProps,
      formItemProps: {
        rules: [
          {
            required: true,
            message: `${t('location_required')}`,
          },
        ],
      },
      tooltip: isAllLocations && {
        icon: <InfoCircleOutlined />,
        placement: 'bottom',
        title: t('company_disable_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      fieldProps: () => ({
        mode: 'multiple',
        className: styles.textselect,
        placeholder: t('location_placeholder'),
        getPopupContainer: (triggerNode) => triggerNode.parentElement,
      }),
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['locations', 'companies'],
      },
      columns: ({ locations, companies }) => [
        {
          dataIndex: 'message',
          valueType: 'text',
          hideInForm: hideMessageInput(locations, companies),
          renderFormItem: () => (
            <TextArea className={styles.textinput} rows={7} placeholder={t('add_message')} />
          ),
          fieldProps: {
            placeholder: t('add_message'),
          },
        },
      ],
    },
  ];
};

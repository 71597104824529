import Profield from '@ant-design/pro-field';
import { PhoneCodeSelect, PlacesSelect, TimezoneSelect } from 'components/GSelect';
import { AsYouType } from 'libphonenumber-js';
import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { checkIfCodeEnteredInPhoneNumber, PhoneCode } from 'utils';
import { FormItemProps } from './typings';

const FormItem: FC<FormItemProps> = ({ record, recordKey, form, onChangePlace, t, ...props }) => {
  const renderFormItem = useCallback(() => {
    const countryCode = form?.getFieldValue?.('phone')?.phoneISO || 'US';

    switch (record?.id) {
      case 'address':
        return (
          <PlacesSelect
            size="small"
            {...props}
            onChange={(value, option) => {
              onChangePlace?.(value, option, String(recordKey || ''), form);
            }}
          />
        );
      case 'timezone':
        return <TimezoneSelect size="small" {...props} />;
      case 'phone':
        return (
          <Profield
            mode="edit"
            fieldProps={{
              size: 'small',
              placeholder: t?.('please_input'),
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                let value = new AsYouType(countryCode).input(e.target.value);
                value = checkIfCodeEnteredInPhoneNumber(value);
                form?.setFieldsValue({
                  phone: {
                    phone: value,
                    value,
                  },
                });
              },
              addonBefore: (
                <PhoneCodeSelect
                  value={countryCode}
                  defaultValue={countryCode}
                  onChange={(val, option) => {
                    // @ts-ignore
                    const item: PhoneCode = option?.itemProps;
                    form?.setFieldsValue({
                      phone: {
                        phoneCode: `+${String(item?.code || '').replace('+', '')}`,
                        phoneISO: item.iso,
                        value: undefined,
                        phone: undefined,
                      },
                    });
                  }}
                />
              ),
            }}
            {...props}
            value={record?.phone}
          />
        );

      default:
        return (
          <Profield
            mode="edit"
            fieldProps={{
              size: 'small',
            }}
            {...props}
          />
        );
    }
  }, [form, record?.id, record?.phone, props, t, onChangePlace, recordKey]);
  const formItem = useMemo(() => renderFormItem(), [renderFormItem]);
  return formItem;
};

export default FormItem;

import { create } from 'zustand';
import { ProductStore } from './typings';

/**
 * Custom store hook for managing product state.
 */
const useProductStore = create<ProductStore>((set) => ({
  product: undefined,
  setProduct: (product) => set({ product }),
  paramsStore: {
    pageNumber: 1,
    pageSize: 20,
  },
  setParamsStore: (paramsStore) => set({ paramsStore }),
}));

export default useProductStore;

import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Switch, Tooltip, Typography } from 'antd';
import { FC, useCallback } from 'react';
import { getProductsT } from 'utils';
import styles from './index.module.less';
import { MultipleDatesFormItemProps } from './typings';

const MultipleDatesFormItem: FC<MultipleDatesFormItemProps> = ({
  title,
  dataIndex = 'isMultipleDates',
  tooltip,
  form,
  actionRef,
  rowBeingEdited,
  onValueChange,
  ...props
}) => {
  const { getFieldValue, setFieldsValue } = form;
  const value = getFieldValue(dataIndex);
  const setValue = useCallback(
    (newValue: boolean) => {
      if (onValueChange) {
        onValueChange(newValue);
      }
      setFieldsValue({
        [dataIndex]: newValue,
      });
      // reset product select and inbound shipment table data on multiple products toggle
      if (dataIndex === 'isMultipleProducts') {
        setFieldsValue({
          parentProductId: undefined,
          productName: undefined,
          isAssignedProduct: false,
          productProps: undefined,
          inboundShipments: undefined,
        });
        actionRef?.current?.cancelEditable(rowBeingEdited);
      }
    },
    [onValueChange, setFieldsValue, dataIndex, actionRef, rowBeingEdited],
  );
  return (
    <Space direction="vertical">
      <Space>
        {title || getProductsT?.('multiple_dates')}
        {tooltip && (
          <Tooltip title={tooltip}>
            <Typography.Text type="secondary">
              <InfoCircleOutlined className={styles.tooltip} />
            </Typography.Text>
          </Tooltip>
        )}
      </Space>
      <Switch size="small" {...props} checked={value} onChange={setValue} />
    </Space>
  );
};
export default MultipleDatesFormItem;

import { PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Input, message, Row, SelectProps, Typography } from 'antd';
import GSelect from 'components/GSelect';
import { useModalVisibility } from 'hooks';
import UpgradeModal from 'pages/Network/forms/AddEdit/UpgradeModal';
import useAddEditActions from 'pages/Network/hook/useAddEditActions';
import useSubscriptionAction from 'pages/Network/hook/useSubscriptionActions';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListTradePartnersOptionalParams, TradePartnerResponse } from 'services/api/client/src';
import useTradePartners from 'services/api/useTradePartners';
import { useDebouncedCallback } from 'use-debounce';
import styles from './index.module.less';
import { FetchDataFn } from './typings';
import useCompanySelectStore from './useCompanySelectStore';

// @ts-ignore
interface CompanySelectProps<ValueType, OptionType> extends SelectProps<ValueType, OptionType> {
  myAccount?: boolean;
  isWCUser?: boolean;
  isAddCompany?: boolean;
}

const TradePartnerSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  isAddCompany,
  isWCUser,
  ...props
}: CompanySelectProps<ValueType, OptionType>) => {
  const [params, setParams] = useState<ListTradePartnersOptionalParams>({
    types: isWCUser ? ['Wholechain'] : ['Self', 'NonWholechain'],
  });
  const { onChange } = props;
  const { data, isLoading } = useTradePartners(params);
  const tradePartners = useMemo(() => data?.data, [data?.data]);
  const [inputValue, setInputValue] = useState<string>('');
  const { setCompanyEmpty, setSelectedCompany } = useCompanySelectStore();
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.form_fields' });
  const { createNewCompanyByName } = useAddEditActions();
  const upgradeModal = useModalVisibility(false);
  const { allowInternalLocation, allowExternalLocation } = useSubscriptionAction();

  const options: Array<DefaultOptionType> =
    tradePartners?.map((option) => ({
      label: (
        <div>
          {option.name}
          <span>
            <Typography.Text type="secondary">
              {option.isOwnerAccount ? ` (${t('me')})` : ''}
            </Typography.Text>
          </span>
        </div>
      ),
      value: isAddCompany ? String(option.id) : String(option.wholechainId),
      itemProps: option,
    })) || [];

  const debounced = useDebouncedCallback(
    // function
    (filterText) => {
      setParams((oldParams) => ({ ...oldParams, name: filterText }));
    },
    // delay in ms
    1000,
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );

  const addItem = useCallback(async () => {
    if (!inputValue.trim()) {
      message.error(t('add_valid_company_name'));
    } else if (allowExternalLocation) {
      const res = (await createNewCompanyByName(inputValue)) as TradePartnerResponse;
      if (res?.id) {
        message.success(t('success.add_company', { company: res.name }));
        setCompanyEmpty();
        setSelectedCompany(undefined);
      }
      setInputValue('');
    } else {
      upgradeModal?.show();
    }
  }, [
    allowExternalLocation,
    createNewCompanyByName,
    inputValue,
    setCompanyEmpty,
    setSelectedCompany,
    t,
    upgradeModal,
  ]);

  const dropdownRender = useCallback(
    (menu: ReactElement) => (
      <>
        {menu}
        <Divider className={styles.dropdowndivider} />
        <div className={styles.dropdowninputgrp}>
          <Row justify="space-between" gutter={[4, 4]}>
            <Col flex="auto">
              <Input
                placeholder={t('other')}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                size="small"
              />
            </Col>
            <Col flex="none">
              <Typography.Link
                onClick={addItem}
                className={styles.dropdownlink}
                disabled={isLoading || !inputValue?.trim()}
              >
                <PlusOutlined /> {t('add')}
              </Typography.Link>
            </Col>
          </Row>
        </div>
      </>
    ),
    [addItem, inputValue, isLoading, t],
  );

  const handleOnChange = (value: ValueType, option: OptionType | OptionType[]) => {
    if (isAddCompany && value) {
      const selectedComp = tradePartners?.find((item) => item.id === String(value));
      const showUpgradeModal = selectedComp?.isOwnerAccount
        ? allowInternalLocation
        : allowExternalLocation;
      if (showUpgradeModal) {
        setSelectedCompany(selectedComp);
        setCompanyEmpty();
      } else {
        upgradeModal?.show();
      }
    }

    if (onChange) {
      onChange(value, option);
    }
  };

  return (
    <>
      <UpgradeModal modal={upgradeModal} tkey="your_loc." />
      <GSelect<ValueType, OptionType>
        {...props}
        allowSearch
        onClear={() => {
          setSelectedCompany();
          setCompanyEmpty();
        }}
        loading={isLoading}
        options={options as OptionType[]}
        fetchData={fetchData}
        onChange={handleOnChange}
        allowFilterOption={false}
        dropdownRender={isAddCompany ? dropdownRender : undefined}
      />
    </>
  );
};

TradePartnerSelect.defaultProps = {
  myAccount: true,
  isWCUser: false,
  isAddCompany: true,
};

export default TradePartnerSelect;

import UpgradPlanModal from 'components/Settings/SubscriptionBilling/UpgradePlanModal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { supportEmailLink } from 'utils';
import { UpgradePlanModalProps } from './typings';

const UpgradeModal: FC<UpgradePlanModalProps> = ({ modal, tkey }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network' });

  return (
    <UpgradPlanModal
      modal={modal}
      title={t('upgrade_modal_title') || ''}
      description={
        <div>
          {`${t(`${tkey || ''}upgrade_modal_description`)}`}&nbsp;
          <a href={supportEmailLink} type="mail">
            {t('support_anchor_email_title')}
          </a>
          &nbsp;
          {t('upgrade_modal_desc2')}
        </div>
      }
    />
  );
};

export default UpgradeModal;

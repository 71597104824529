// @ts-nocheck
import { GraphData } from '@antv/g6/lib/types';
import { message, Modal } from 'antd';
import { useShareTemplateStore } from 'components';
import { GTableDateType } from 'components/GTable/contexts/typings';
import { useModalVisibility } from 'hooks';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getWorkflowById,
  useAccount,
  useCreateWorkflow,
  useDeleteWorkflow,
  useDeleteWorkflows,
  useUpdateWorkflow,
  useWorkflowById,
} from 'services/api';
import {
  CreateWorkflowRequest,
  ListWorkflowItem,
  UpdateWorkflowRequest,
  WorkflowAggregateEvent,
  WorkflowCommissionEvent,
  WorkflowDecommissionEvent,
  WorkflowDisaggregateEvent,
  WorkflowEdge,
  WorkflowObserveEvent,
  WorkflowReceiveEvent,
  WorkflowShipEvent,
  WorkflowTemplate,
  WorkflowTransformEvent,
} from 'services/api/client/src';
import { downloadJsonToCSV, errorHandler } from 'utils';
import { DataItem, EventData } from './typings';

const useWorkflowActions = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'workflows' });
  const navigate = useNavigate();
  const { data: dataAccount } = useAccount();
  const { workflowId: defaultId } = useParams();
  const [workflowId, setWorkflowId] = useState<string | undefined>(defaultId);
  const { data: workflow } = useWorkflowById(defaultId || '');

  const queryClient = useQueryClient();
  const shareModal = useModalVisibility();
  const createRecord = useCreateWorkflow(queryClient);
  const updateRecord = useUpdateWorkflow(queryClient);
  const deleteRecord = useDeleteWorkflow(queryClient);
  const deleteRecords = useDeleteWorkflows(queryClient);
  const { setTemplates, setEtradePartners } = useShareTemplateStore();

  /* delete Record */
  const onDeleteRecord = useCallback(
    (actionItemKey: string, actionPayload?: WorkflowTemplate) => {
      Modal.warning({
        title: t('delete_modal.title', { name: actionPayload?.name || '' }),
        content: t('delete_modal.content'),
        okCancel: true,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okText: t('delete_modal.ok_text'),
        cancelText: t('delete_modal.cancel_text'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onOk: () => {
          deleteRecord.mutate(actionPayload?.id || '0', {
            onSuccess: () => {
              message.success(t('delete_success_message', { id: actionPayload?.name }));
            },
          });
        },
        centered: true,
      });
    },
    [deleteRecord, t],
  );
  /* delete record multiple */
  const onDeleteRecordMultiple = useCallback(
    (selectedRows: Array<ListWorkflowItem>) => {
      const greaterthanone = selectedRows.length > 1;
      Modal.warning({
        title: greaterthanone
          ? t('delete_modal.title_many', { count: selectedRows.length })
          : t('delete_modal.title', { name: selectedRows?.[0].name }),
        content: greaterthanone ? t('delete_modal.content_many') : t('delete_modal.content'),
        okCancel: true,
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okText: t('delete_modal.ok_text'),
        cancelText: t('delete_modal.cancel_text'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        onOk: () => {
          deleteRecords.mutate(selectedRows.map((el) => String(el.id)));
        },
        centered: true,
      });
    },
    [deleteRecords, t],
  );

  /* duplicate template */
  const createDuplicate = useCallback(
    async (id: string) => {
      const done = message.loading('Action in progress..', 0);
      try {
        const record = await getWorkflowById(id);
        const reqBody: CreateWorkflowRequest = {
          ...record,
          name: `${record?.name} (copy)`,
        };
        await createRecord.mutateAsync(reqBody);
        done();
        message.success(t('duplicate_success_message', { id: record?.name }));
      } catch (error) {
        done();
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [createRecord, t],
  );

  /* share template */
  const onShareTemplate = useCallback(
    (actionPayload?: WorkflowTemplate) => {
      setEtradePartners([
        {
          id: dataAccount?.id,
          name: dataAccount?.name || '',
          isOwner: true,
          isUpdated: false,
          isDeleted: false,
        },
        // @ts-ignore
        ...(actionPayload?.sharedWith?.map((el) => ({
          id: el?.wholechainId,
          name: el?.accountName || '',
          permission: (el?.role as 'Viewer' | 'Editor') || ('Viewer' as 'Viewer' | 'Editor'),
          isOwner: false,
          isUpdated: false,
          isDeleted: false,
        })) || []),
      ]);
      shareModal.show();
    },
    [shareModal, setEtradePartners, dataAccount],
  );
  /* share template multiple */
  const onShareTemplateMultiple = useCallback(
    (records?: Array<string>) => {
      shareModal.show();
      setTemplates(records);
    },
    [setTemplates, shareModal],
  );
  const onDownloadCSV = useCallback((payload?: ListWorkflowItem) => {
    downloadJsonToCSV([payload]);
  }, []);
  const onDownloadCSVMultiple = useCallback((payload?: GTableDateType[]) => {
    downloadJsonToCSV(payload);
  }, []);
  const handleActionsMenuClick = useCallback(
    (actionItemKey: string, actionPayload?: ListWorkflowItem) => {
      switch (actionItemKey) {
        case 'view':
          navigate(String(actionPayload?.id || 0));
          break;
        case 'edit':
          navigate(String(actionPayload?.id || 0));
          break;
        case 'view_preview':
          navigate(`${actionPayload?.id || 0}/preview`);
          break;
        case 'share':
          onShareTemplate(actionPayload);
          break;
        case 'download':
          onDownloadCSV(actionPayload);
          break;
        case 'duplicate':
          createDuplicate(actionPayload?.id || '0');
          break;
        case 'delete':
          onDeleteRecord(actionItemKey, actionPayload);
          break;

        default:
          break;
      }
    },
    [navigate, onShareTemplate, createDuplicate, onDeleteRecord, onDownloadCSV],
  );
  const onSaveDraft = async (formData: DataItem, close = true) => {
    try {
      const nodes: GraphData['nodes'] =
        formData.graphData?.nodes?.map((el, idx) => ({
          ...el,
          eventData: {
            id: el?.id,
            templateId: el?.eventTemplateId || undefined,
            templateName: el?.eventTemplateName,
            ...(el?.eventData as EventData),
            order: idx,
          },
        })) || [];

      const reqBody: CreateWorkflowRequest = {
        name: formData.name,
        deadline: formData?.deadline
          ? new Date(moment(formData.deadline).toISOString())
          : undefined,
        workflowTemplateId: formData.templateId,
        commissionEvents: nodes
          ?.filter((node) => node?.eventType === 'Commission')
          ?.map((n) => n.eventData as WorkflowCommissionEvent),
        decommissionEvents: nodes
          ?.filter((node) => node?.eventType === 'Decommission')
          ?.map((n) => n.eventData as WorkflowDecommissionEvent),
        aggregateEvents: nodes
          ?.filter((node) => node?.eventType === 'Aggregate')
          ?.map((n) => n.eventData as WorkflowAggregateEvent),
        disaggregateEvents: nodes
          ?.filter((node) => node?.eventType === 'Disaggregation')
          ?.map((n) => n.eventData as WorkflowDisaggregateEvent),
        observeEvents: nodes
          ?.filter((node) => node?.eventType === 'Observe')
          ?.map((n) => n.eventData as WorkflowObserveEvent),
        receiveEvents: nodes
          ?.filter((node) => node?.eventType === 'Receive')
          ?.map((n) => n.eventData as WorkflowReceiveEvent),
        shipEvents: nodes
          ?.filter((node) => node?.eventType === 'Ship')
          ?.map((n) => n.eventData as WorkflowShipEvent),
        transformEvents: nodes
          ?.filter((node) => node?.eventType === 'Transform')
          ?.map((n) => n.eventData as WorkflowTransformEvent),
        edges: (formData.graphData?.edges as WorkflowEdge[]) || [],
      };
      const res = await createRecord.mutateAsync(reqBody);
      if (close) {
        navigate(-1);
        setTimeout(() => {
          message.success(t('success_message', { id: res?.name }));
        }, 1000);
      } else {
        // @ts-ignore
        setWorkflowId(res?.id);
      }
    } catch (error) {
      if (errorHandler(error)) {
        message.error(await errorHandler(error));
      }
    }
  };
  const onUpdateDraft = async (
    formData: DataItem,
    status: UpdateWorkflowRequest['status'] = 'InProgress',
  ) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const nodes: GraphData['nodes'] =
        formData.graphData?.nodes?.map((el, idx) => ({
          ...el,
          eventData: {
            id: el?.id,
            templateId: el?.eventTemplateId || undefined,
            templateName: el?.eventTemplateName,
            ...(el?.eventData as EventData),
            order: idx,
          },
        })) || [];

      const reqBody: UpdateWorkflowRequest = {
        id: workflowId,
        status,
        name: formData.name,
        deadline: formData?.deadline
          ? new Date(moment(formData.deadline).toISOString())
          : undefined,
        workflowTemplateId: formData.templateId,
        commissionEvents: nodes
          ?.filter((node) => node?.eventType === 'Commission')
          ?.map((n) => n.eventData as WorkflowCommissionEvent),
        decommissionEvents: nodes
          ?.filter((node) => node?.eventType === 'Decommission')
          ?.map((n) => n.eventData as WorkflowDecommissionEvent),
        aggregateEvents: nodes
          ?.filter((node) => node?.eventType === 'Aggregate')
          ?.map((n) => n.eventData as WorkflowAggregateEvent),
        disaggregateEvents: nodes
          ?.filter((node) => node?.eventType === 'Disaggregation')
          ?.map((n) => n.eventData as WorkflowDisaggregateEvent),
        observeEvents: nodes
          ?.filter((node) => node?.eventType === 'Observe')
          ?.map((n) => n.eventData as WorkflowObserveEvent),
        receiveEvents: nodes
          ?.filter((node) => node?.eventType === 'Receive')
          ?.map((n) => n.eventData as WorkflowReceiveEvent),
        shipEvents: nodes
          ?.filter((node) => node?.eventType === 'Ship')
          ?.map((n) => n.eventData as WorkflowShipEvent),
        transformEvents: nodes
          ?.filter((node) => node?.eventType === 'Transform')
          ?.map((n) => n.eventData as WorkflowTransformEvent),
        edges: (formData.graphData?.edges as WorkflowEdge[]) || [],
      };
      const res = await updateRecord.mutateAsync({
        id: workflowId || '',
        body: reqBody,
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  return {
    workflow,
    shareModal,
    onDeleteRecord,
    createDuplicate,
    handleActionsMenuClick,
    onShareTemplate,
    onShareTemplateMultiple,
    onDeleteRecordMultiple,
    onDownloadCSV,
    onDownloadCSVMultiple,
    onSaveDraft,
    onUpdateDraft,
  };
};
export default useWorkflowActions;

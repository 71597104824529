import { Typography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventContext } from './EventContext';
import styles from './index.module.less';
import { LifeCycleHeaderProps } from './typings';

const LifecycleHeader: FC<LifeCycleHeaderProps> = ({ propsString }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const { lifeCyle } = useEventContext();
  // const { lifeCyle, showAllProducts, setShowAllProducts } = useEventContext();
  // const filteredView = !showAllProducts;
  // const setFilteredView = (newValue: boolean) => {
  //   setShowAllProducts(!newValue);
  // };

  return (
    <div className={styles.lifeheader}>
      <Typography.Text className={styles.digramtext}>
        {t('lifecycle_title')}: {propsString || lifeCyle}
      </Typography.Text>
      {/* <Space>
        <Tooltip title={t?.('show_all_products_tooltip')}>
          <Typography.Text type="secondary">
            <InfoCircleOutlined className={styles.tooltip} />
          </Typography.Text>
        </Tooltip>
        {t?.('show_all_products')}
        <Switch size="small" checked={filteredView} onChange={setFilteredView} />
      </Space> */}
    </div>
  );
};

export default React.memo(LifecycleHeader);

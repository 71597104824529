import Profield from '@ant-design/pro-field';
import { BizStepSelect, DispositionSelect } from 'components/GSelect';
import { FC, useCallback, useMemo } from 'react';
import { AdvanceSetting } from '../typings';

type FormItemProps = {
  id?: string;
  record?: AdvanceSetting;
};
const FormItem: FC<FormItemProps> = ({ record, ...props }) => {
  const renderFormItem = useCallback(() => {
    switch (record?.id) {
      case 'bizStep':
        return <BizStepSelect size="small" {...props} />;
      case 'disposition':
        return <DispositionSelect size="small" {...props} />;

      default:
        return (
          <Profield
            mode="edit"
            fieldProps={{
              size: 'small',
            }}
            {...props}
          />
        );
    }
  }, [record, props]);
  const formItem = useMemo(() => renderFormItem(), [renderFormItem]);
  return formItem;
};

export default FormItem;

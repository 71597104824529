import { Button, Empty, Space, Typography } from 'antd';
import { locationPin } from 'assets';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import { EmptyProps } from './typings';

const imageHeight = {
  height: 70,
};

const EmptyLocation: FC<EmptyProps> = ({ onAddLocation, hideButton }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.empty_state' });
  return (
    <Space className={styles.emptycard}>
      <Empty
        image={locationPin}
        imageStyle={imageHeight}
        description={<Typography.Text className={styles.text}>{t('empty_text')}</Typography.Text>}
      >
        {hideButton ? null : (
          <Button shape="round" className={styles.button} onClick={onAddLocation}>
            {t('button_text')}
          </Button>
        )}
      </Empty>
    </Space>
  );
};

export default EmptyLocation;

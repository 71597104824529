import React from 'react';
import styles from '../index.module.less';
import { RowFieldProps } from './typings';

const Component: React.FC<RowFieldProps> = ({
  title,
  fieldValue,
  actions,
  borderBottom = true,
}) => {
  const rowStyle = borderBottom
    ? `${styles['row-tbl']} ${styles['row-field-border-bottom']}`
    : styles['row-tbl'];
  return (
    <div className={rowStyle}>
      <div className={`${styles['row-field']} ${styles['row-field-title']}`}>{title}</div>
      <div className={styles['row-field-value']}>{fieldValue}</div>
      {actions && <div className={styles['row-field-actions']}>{actions}</div>}
    </div>
  );
};
export default Component;

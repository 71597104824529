import { ProColumns } from '@ant-design/pro-table';
import { ColProps } from 'antd';

export const colPropsNull: ColProps = { xs: 0, sm: 0, md: 0, lg: 0 };
export const colProps24Cols: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
export const colProps6Cols: ColProps = { xs: 24, sm: 12, md: 12, lg: 6 };

export const hiddencol: ProColumns = {
  width: 1,
  renderFormItem: () => null,
  render: () => null,
};

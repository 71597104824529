import { Button, Col, Empty, List, Row, Skeleton, Tag, Typography } from 'antd';
import { useWindowSize } from 'hooks';
import VirtualList from 'rc-virtual-list';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { usePOSearch } from 'services';
import styles from './index.module.less';
import { SearchListItem, SearchListProps } from './typings';

const SearchList: FC<SearchListProps> = ({ purchaseOrder }) => {
  const { data, isLoading } = usePOSearch({ purchaseOrder });
  const [, height] = useWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation('pages', { keyPrefix: 'global_search.search_list' });
  const record: SearchListItem[] = useMemo(
    () => [
      {
        title: purchaseOrder,
        description: t('product_count', { count: data?.totalItems }),
        tagString: t('purchase_order'),
      },
    ],
    [data?.totalItems, purchaseOrder, t],
  );

  const scrollHeight = useMemo(() => {
    // amount of pixels equivalent to 40vh
    const vh = Math.floor(height * 0.6);

    return vh;
  }, [height]);

  const onNavigate = useCallback(() => {
    navigate(`po_search`);
  }, [navigate]);

  if (isLoading) {
    return <Skeleton loading active />;
  }

  if (!data?.totalItems) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('no_data')} />;
  }

  return (
    <List>
      <VirtualList data={record || []} height={scrollHeight} itemHeight={75} itemKey="email">
        {(item: SearchListItem) => (
          <List.Item key={item?.title}>
            <List.Item.Meta
              title={
                <Row justify="space-between">
                  <Link
                    className="text-link"
                    to={{
                      pathname: ``,
                    }}
                    onClick={(e) => {
                      e?.preventDefault();
                      onNavigate();
                    }}
                  >
                    {item?.title}
                  </Link>
                  <Button type="link" className="list-actions" onClick={onNavigate}>
                    {t('view')}
                  </Button>
                </Row>
              }
              description={
                <Col className={styles.margintopn8}>
                  <Row>
                    <Typography.Text type="secondary">{item?.description}</Typography.Text>
                  </Row>
                  <Row className={styles.margintop8}>
                    <Tag>{item?.tagString}</Tag>
                  </Row>
                </Col>
              }
            />
          </List.Item>
        )}
      </VirtualList>
    </List>
  );
};

export default SearchList;

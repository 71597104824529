// @ts-nocheck
import { InfoCircleOutlined } from '@ant-design/icons';
import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Form, FormInstance } from 'antd';
import {
  BizStepSelect,
  DispositionSelect,
  GTable,
  ProductLotSelect,
  ProductSelect,
  TimezoneSelect,
} from 'components';
import moment, { Moment } from 'moment';
import { DataItem } from 'pages/Shipments/Forms/typings';
import React, { FC, Key, useCallback, useEffect, useMemo, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useTemplateById } from 'services/api';
import {
  ProductInstanceQuantity,
  ProductResponse,
  TemplateAttributeResponse,
} from 'services/api/client/src';
import {
  DefaultValue,
  dateFormat,
  disableExistingLotLocations,
  fieldTypes,
  getDisabledDate,
  getDisabledTime,
  getRecordValuev2,
  getTimeFormat,
  hiddencol,
  onChangeBatchShipFieldDate,
  onChangeFieldsPropInstance,
} from 'utils';
import { BatchShipCSVTableProps } from '../typings';

type ColumnsType = {
  columnWidth?: string | number;
  defaultValues?: DefaultValue;
  templateFields?: TemplateAttributeResponse[];
  products: Array<DataItem>;
  locationId?: string;
  eventTime?: Moment;
  multipleDates?: boolean;
  editableTableFormRef?: React.MutableRefObject<EditableFormInstance<any> | undefined>;
  t?: TFunction<'pages', 'products'>;
};
const Columns = ({
  columnWidth,
  defaultValues,
  templateFields,
  products,
  locationId,
  multipleDates,
  editableTableFormRef,
  t,
}: ColumnsType): Array<ProColumns<DataItem>> => [
  {
    title: t?.('csv_product_title'),
    dataIndex: 'productId',
    fieldProps: {
      placeholder: t?.('csv_product_title'),
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('product_req'),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
    renderFormItem: () => (
      <ProductSelect
        size="small"
        allowLotSerial
        onChange={(value, option) => {
          // @ts-ignore
          const item = option?.itemProps as ProductResponse;
          editableTableFormRef?.current?.setFieldsValue({
            productName: item?.name,
            productId: value,
          });
        }}
      />
    ),
  },
  {
    dataIndex: 'productName',
    ...hiddencol,
  },
  {
    title: t?.('primary_id'),
    dataIndex: 'primaryId',
    fieldProps: {
      placeholder: t?.('primary_id'),
      size: 'small',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('primary_id_req'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { recordKey, record }) => (
      <ProductLotSelect
        searchValue={record?.lotSerial || ''}
        productId={record?.productId}
        disabledItem={(item) => disableExistingLotLocations(item, products, locationId)}
        size="small"
        onChange={(value, option) =>
          onChangeFieldsPropInstance(
            editableTableFormRef?.current as FormInstance<any>,
            { recordKey },
            option,
            {
              // @ts-ignore
              lotSerial: (option?.itemProps as ProductInstanceQuantity)?.lotSerial,
            },
          )
        }
      />
    ),
    width: columnWidth,
  },
  {
    dataIndex: 'lotSerial',
    ...hiddencol,
  },
  {
    title: t?.('quantity'),
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: {
      placeholder: t?.('quantity'),
      stringMode: true,
      size: 'small',
      type: 'number',
      className: 'full-width',
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('quantity_req'),
        },
      ],
      hasFeedback: false,
    },
    width: columnWidth,
  },
  {
    dataIndex: 'instanceInventory',
    ...hiddencol,
  },
  {
    title: t?.('purchase_order'),
    dataIndex: 'poNumber',
    fieldProps: {
      placeholder: t?.('purchase_order'),
      size: 'small',
    },
    width: columnWidth,
  },
  {
    dataIndex: 'currentInventory',
    ...hiddencol,
  },
  {
    title: t?.('logistic_id'),
    dataIndex: 'scss',
    fieldProps: {
      placeholder: t?.('logistic_id'),
      size: 'small',
    },
    width: columnWidth,
  },
  {
    dataIndex: 'eventDate',
    ...hiddencol,
  },
  {
    title: t?.('date'),
    dataIndex: 'date',
    valueType: 'date',
    hideInTable: !multipleDates,
    fieldProps: (form, { rowKey }) => {
      const eventDate = getRecordValuev2(
        'eventDate',
        products,
        rowKey,
        editableTableFormRef?.current as FormInstance<any>,
      );
      const onChange = (value: string) =>
        onChangeBatchShipFieldDate?.(
          editableTableFormRef?.current as FormInstance<any>,
          rowKey,
          value,
        );
      const disabledDate = getDisabledDate(eventDate);
      return {
        size: 'small',
        className: 'full-width',
        placeholder: 'Date',
        onChange,
        disabledDate,
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('date_req'),
        },
      ],

      hasFeedback: false,
    },
    width: columnWidth,
  },
  {
    dataIndex: 'unitOfMeasure',
    ...hiddencol,
  },
  {
    title: t?.('time'),
    dataIndex: 'time',
    valueType: 'time',
    hideInTable: !multipleDates,
    shouldCellUpdate: (record, prevRecord) => record?.date !== prevRecord?.date,
    fieldProps: (form, { rowKey }) => {
      const eventDate = getRecordValuev2(
        'eventDate',
        products,
        rowKey,
        editableTableFormRef?.current as FormInstance<any>,
      );

      return {
        size: 'small',
        placeholder: t?.('time'),
        disabledTime: getDisabledTime(eventDate),
        className: 'full-width',
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('time_req'),
        },
      ],
      hasFeedback: false,
    },
    ellipsis: true,
    width: columnWidth,
  },
  {
    title: t?.('bizstep_title'),
    dataIndex: 'bizStep',
    valueType: 'select',
    renderFormItem: () => <BizStepSelect size="small" />,
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('bizstep_req'),
        },
        {
          whitespace: true,
          message: t?.('bizstep_req'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('bizstep_placeholder'),
      size: 'small',
    },
    tooltip: {
      icon: <InfoCircleOutlined />,
      title: t?.('bizstep_tooltip'),
      key: null,
      type: '',
      props: null,
    },
    width: columnWidth,
  },
  {
    title: t?.('disposition_title'),
    dataIndex: 'disposition',
    valueType: 'select',
    fieldProps: {
      placeholder: t?.('disposition_title'),
      size: 'small',
    },
    renderFormItem: () => <DispositionSelect size="small" />,
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('disposition_req'),
        },
        {
          whitespace: true,
          message: t?.('disposition_req'),
        },
      ],
    },

    tooltip: {
      icon: <InfoCircleOutlined />,
      title: t?.('disposition_tooltip'),
      key: null,
      type: '',
      props: null,
    },
    width: columnWidth,
  },
  {
    title: t?.('time_zone_title'),
    dataIndex: 'timeZone',
    valueType: 'select',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t?.('time_zone_req'),
        },
        {
          whitespace: true,
          message: t?.('time_zone_req'),
        },
      ],
    },
    fieldProps: {
      placeholder: t?.('time_zone_placeholder'),
      size: 'small',
    },
    renderFormItem: () => (
      <TimezoneSelect placeholder={t?.('time_zone_placeholder')} size="small" />
    ),
    width: columnWidth,
  },
  ...(templateFields?.map((item) => ({
    title: item?.attribute?.name || '',
    dataIndex: item?.attribute?.fieldProperties?.fields?.propertyName || '',
    valueType: fieldTypes[item?.attribute?.fieldProperties?.fields?.fieldType || 'Text'].valueType,
    initialValue: defaultValues?.[item?.attribute?.fieldProperties?.fields?.propertyName || ''],
    fieldProps: {
      ...fieldTypes[item?.attribute?.fieldProperties?.fields?.fieldType || 'Text'].fieldProps,
      placeholder: item?.attribute?.name || '',
      size: 'small',
      options: item?.attribute?.fieldProperties?.values?.valueOptions,
    },
    formItemProps: {
      rules: [
        {
          required: item?.attribute?.fieldProperties?.fields?.required || false,
          validator: (_: any, value: string) => {
            if (item?.attribute?.fieldProperties?.fields?.required) {
              /* check input is filled */
              if (
                item?.attribute?.fieldProperties?.fields?.fieldType !== 'Number' &&
                !value?.trim()
              ) {
                return Promise.reject(
                  new Error(
                    t?.('template_field_req', {
                      field: item?.attribute?.name || '',
                    }),
                  ),
                );
              }
              if (item?.attribute?.fieldProperties?.fields?.fieldType === 'Number' && !value) {
                return Promise.reject(
                  new Error(
                    t?.('template_field_req', {
                      field: item?.attribute?.name || '',
                    }),
                  ),
                );
              }
              /* input is filled, now check if the values are correct */
              if (
                (item?.attribute?.fieldProperties?.fields?.fieldType === 'Dropdown' ||
                  item?.attribute?.fieldProperties?.fields?.fieldType === 'RadioButton') &&
                !item?.attribute?.fieldProperties?.values?.valueOptions?.includes(value) &&
                value?.trim()
              ) {
                return Promise.reject(new Error(t?.('template_field_valid')));
              }
              return Promise.resolve();
            }

            /* input is filled,not required, now check if the values are correct */
            if (
              (item?.attribute?.fieldProperties?.fields?.fieldType === 'Dropdown' ||
                item?.attribute?.fieldProperties?.fields?.fieldType === 'RadioButton') &&
              !item?.attribute?.fieldProperties?.values?.valueOptions?.includes(value) &&
              value?.trim()
            ) {
              return Promise.reject(new Error(t?.('template_field_valid')));
            }

            return Promise.resolve();
          },
        },
      ],
    },
    ellipsis: true,
    width:
      item.attribute?.fieldProperties?.fields?.fieldType !== 'RadioButton'
        ? columnWidth
        : 2 * Number(columnWidth),
  })) || []),
  {
    dataIndex: 'actions',
    valueType: 'option',
    render: () => null,
  },
];

const BatchShipTable: FC<BatchShipCSVTableProps> = ({
  form,
  editableTableFormRef,
  defaultValues,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  const { setFieldsValue } = form;
  const templateId = Form?.useWatch?.('customData', form) || '';
  const locationId = Form?.useWatch?.('shipFromAddress', form) || '';
  const multipleDates = true;
  const date = Form?.useWatch?.('date', form) || undefined;
  const time = Form?.useWatch?.('time', form) || undefined;
  const eventTime = useMemo(
    () =>
      date && time
        ? moment(`${dateFormat(date)} ${getTimeFormat(time)}`, 'YYYY-MM-DD HH:mm:ss')
        : undefined,
    [date, time],
  );
  const { data: template } = useTemplateById(templateId);

  const templateFields = useMemo(
    () =>
      template?.templateAttributes?.filter(
        (field) =>
          field?.attribute?.fieldProperties?.fields?.propertyName !== 'bizStep' &&
          field?.attribute?.fieldProperties?.fields?.propertyName !== 'disposition',
      ) || [],
    [template],
  );

  // const columnWidth = 100;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const products: Array<DataItem> = Form?.useWatch('csvData', form) || [];
  const sactionRef = useRef<ActionType>();
  useEffect(() => {
    if (editableTableFormRef?.current) {
      setTimeout(() => {
        editableTableFormRef?.current?.validateFields();
      }, 500);
    }
  }, [products, editableTableFormRef]);

  const setProducts = useCallback(
    (value: Array<DataItem>) => {
      setFieldsValue({
        csvData: value,
      });
    },
    [setFieldsValue],
  );
  const onDelRow = async (key: RecordKey, actionPayload?: DataItem) => {
    if (actionPayload) {
      setProducts(products.filter((p) => p.id !== actionPayload.id));
    }
  };
  return (
    <GTable<DataItem>
      key="sactionRef"
      editableFormRef={editableTableFormRef}
      actionRef={sactionRef}
      columns={Columns({
        defaultValues,
        templateFields,
        products,
        eventTime,
        locationId,
        multipleDates,
        editableTableFormRef,
        t,
      })}
      editable={{
        type: 'multiple',
        onlyAddOneLineAlertMessage: undefined,
        editableKeys: products.map((p) => p.id as Key),
        onDelete: onDelRow,
        onValuesChange: (record, recordList) => {
          setProducts(recordList);
        },
      }}
      options={{
        reload: false,
        setting: false,
      }}
      actionsRenderOptions={{
        save: false,
        cancel: false,
        delete: true,
      }}
      recordCreatorProps={false}
      onAddRecordClick={() => {
        sactionRef.current?.addEditRecord?.({
          id: Date.now().toString(),
        });
      }}
      value={products}
      scroll={{ y: '50vh' }}
    />
  );
};
export default React.memo(BatchShipTable);

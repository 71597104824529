import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { FC, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import DocAssociationFormFields from './SingleColumnTable.fields';
import { DataItem, ModalProps } from './typings';

const ShareDocument: FC<ModalProps> = ({ modal, values, columnTitle, modalTitle }) => {
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const { t } = useTranslation('pages', { keyPrefix: 'documents.add_document' });

  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
  }, []);

  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
  }, [modal, resetFields]);

  const onFinish = useCallback(async () => {
    closeModal();
  }, [closeModal]);

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={DocAssociationFormFields({ values, columnTitle })}
      grid
      title={modalTitle}
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '40%',
        ...modal,
        onCancel: closeModal,
        className: styles.modal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('existing_submit_btn'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        render: (_, defaultDoms) => defaultDoms[1],
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default ShareDocument;

import { BetaSchemaForm, ProFormColumnsType, ProFormInstance } from '@ant-design/pro-form';
import { Form, Typography, message } from 'antd';
import { SubProductSelect } from 'components';
import { AddSubProductProps } from 'pages/Products/typings';
import React, { FC, useCallback, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useAddSubProduct, useProducts } from 'services/api';
import { ProductResponse, SubProductResponse } from 'services/api/client/src';
import { errorHandler, getProductNameById } from 'utils';
import { DataItem as ProductDataItem } from '../../Actions/AddProduct/Columns';

type DataItem = {
  productIds?: Array<string>;
};

type ColumnType = {
  t: TFunction<'pages', 'products.current_inventory'>;
  product?: ProductResponse;
  subProducts?: ProductDataItem['subProducts'];
};

const Fields = ({ t, product, subProducts }: ColumnType): ProFormColumnsType<DataItem>[] => [
  {
    renderFormItem: () => (
      <Typography.Text>
        {t('add_sp_desc', {
          name: product?.name || '',
        })}
      </Typography.Text>
    ),
  },
  {
    dataIndex: 'productIds',
    title: t('product_title'),
    initialValue: [],
    renderFormItem: () => (
      <SubProductSelect
        product={product}
        isSerial={product?.productIdentifierType === 'Serial'}
        filterItem={(option) => !subProducts?.some((subProduct) => subProduct?.id === option?.id)}
      />
    ),
    fieldProps: {
      mode: 'multiple',
      placeholder: t('product_placeholder'),
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('product_req'),
        },
      ],
    },
  },
];
const AddSubProduct: FC<AddSubProductProps> = ({ modal, parentProduct }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.settings.form_fields' });
  const mainForm = Form.useFormInstance<ProFormInstance<ProductDataItem>>();
  const subProducts = Form.useWatch<ProductDataItem['subProducts']>('subProducts', mainForm);
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const { data: dataProducts } = useProducts();

  const queryClient = useQueryClient();
  const createSubProduct = useAddSubProduct(queryClient);
  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
  }, []);

  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
  }, [modal, resetFields]);

  const onCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onFinish = useCallback(
    async (formData: DataItem) => {
      try {
        /* get matched products from dataProduct.results and then map by the type subproduct */
        const matchedProducts =
          (dataProducts?.results
            ?.filter((product) => formData?.productIds?.includes(product?.id || ''))
            ?.map((el) => ({
              ...el,
            })) as Array<SubProductResponse>) || [];
        const newSubProducts = matchedProducts;
        const existingFormSubProducts = subProducts || [];

        mainForm?.setFieldsValue({
          // @ts-ignore
          subProducts: [...existingFormSubProducts, ...newSubProducts],
        });

        if ((formData.productIds?.length || 0) > 1) {
          message.success(
            t('add_sp_multi_success_msg', { count: formData.productIds?.length || 0 }),
          );
        } else {
          message.success(
            t('add_sp_success_msg', {
              name: getProductNameById(formData?.productIds?.[0], dataProducts?.results),
            }),
          );
        }

        closeModal();
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createSubProduct, parentProduct, dataProducts, closeModal, t],
  );

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={Fields({ t, product: parentProduct, subProducts })}
      grid
      title={t('add_sp_title')}
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '40%',
        ...modal,
        onCancel,
      }}
      submitter={{
        searchConfig: {
          submitText: t?.('add_sp_confirm'),
          resetText: t?.('add_sp_cancel'),
        },
        resetButtonProps: {
          shape: 'round',
          onClick: closeModal,
          type: 'primary',
          ghost: true,
        },
        submitButtonProps: {
          shape: 'round',
        },
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default React.memo(AddSubProduct);

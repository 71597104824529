import ProList, { ProListMetas } from '@ant-design/pro-list';
import { Button, message } from 'antd';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useListAccounts, useSwitchAccount } from 'services/api';
import { UserAccountsResponse } from 'services/api/client/src';
import { msalInstance } from 'services/utils/security';
import { delay, errorHandler, globalStyles } from 'utils';

const ForTestingStuff: FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'for_testing_stuff' });
  const { data } = useListAccounts();
  const [queryParams] = useSearchParams();
  const isRelogin = queryParams?.get('query') === 'relogin' || false;

  const queryClient = useQueryClient();
  const switchAccount = useSwitchAccount(queryClient);
  const records = data || [];

  const relogin = async () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.caches.keys().then((names) => {
      names.forEach((name) => {
        window.caches.delete(name);
      });
    });
    window.indexedDB.deleteDatabase('msal');
    const scopes = ['openid', 'profile', 'email'];
    const randomScopes = scopes.slice(0, Math.floor(Math.random() * scopes.length));

    await msalInstance.loginRedirect({
      scopes: randomScopes,
    });
  };
  useEffect(() => {
    if (isRelogin) {
      relogin();
    }
  }, [isRelogin]);
  const onSignIn = async (record: UserAccountsResponse) => {
    try {
      await switchAccount.mutateAsync({
        body: {
          accountId: record.accountId,
        },
      });
      await msalInstance.acquireTokenSilent({
        scopes: ['openid', 'profile'],
        account: msalInstance.getAllAccounts()?.[0],
      });
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.caches.keys().then((names) => {
        names.forEach((name) => {
          window.caches.delete(name);
        });
      });
      window.indexedDB.deleteDatabase('msal');

      window.localStorage.clear();
      window.sessionStorage.clear();
      window.caches.keys().then((names) => {
        names.forEach((name) => {
          window.caches.delete(name);
        });
      });
      window.indexedDB.deleteDatabase('msal');
      const scopes = ['openid', 'profile', 'email'];

      await delay(2000);

      await msalInstance.loginRedirect({
        // pick random scopes to force login
        scopes,
      });
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const listMetas: ProListMetas<UserAccountsResponse> = {
    title: {
      dataIndex: 'name',
    },

    description: {
      dataIndex: 'urn',
    },
    actions: {
      render: (text, record) => (
        <Button
          type="primary"
          shape="round"
          onClick={() => onSignIn(record)}
          loading={switchAccount.isLoading}
        >
          Sign In
        </Button>
      ),
    },
  };
  return (
    <div style={globalStyles.ts1}>
      <h1>{t('title')}</h1>
      <ProList
        itemLayout="horizontal"
        rowKey="id"
        showActions="hover"
        split
        dataSource={records}
        cardProps={{ bodyStyle: globalStyles.ts2 }}
        metas={listMetas}
      />
    </div>
  );
};

export default ForTestingStuff;

import { ProColumns } from '@ant-design/pro-table';
import { Actions, GTable, SingleColumnTags } from 'components';
import { TFunction } from 'i18next';
import { useDocumentFilters } from 'pages/Documents';
import SharedTag from 'pages/Documents/components';
import { DocumentItem } from 'pages/Documents/typings';
import { FilterArrayProp } from 'pages/Network/typings';
import { actionItems } from 'pages/Products/ProductDocuments';
import pluralize from 'pluralize';
import { FC } from 'react';
import { getDocTypeFilters } from 'utils';
import { DetailsDocumentProps } from '../typings';

type ColumnsType = {
  nameSearchfilter: ProColumns;
  actionsMenuClick: (actionItemKey: string, actionPayload?: DocumentItem) => void;
  coreCompanyFilter: Array<FilterArrayProp>;
  tradePartnersFilter: Array<FilterArrayProp>;
  locationsFilter: Array<FilterArrayProp>;
  t: TFunction<'pages', 'template_attributes.documents'>;
};

const columns = ({
  nameSearchfilter,
  actionsMenuClick,
  coreCompanyFilter,
  tradePartnersFilter,
  locationsFilter,
  t,
}: ColumnsType): ProColumns<DocumentItem>[] => [
  {
    title: t('columns.document_name'),
    dataIndex: 'title',
    fixed: 'left',
    ellipsis: true,
    ...nameSearchfilter,
    width: '8%',
  },
  {
    title: t('columns.document_type.title'),
    dataIndex: 'documentType',
    filters: getDocTypeFilters(),
    ellipsis: true,
    width: '8%',
  },
  {
    title: t('columns.company'),
    dataIndex: 'tradepartners',
    filters: tradePartnersFilter,
    filterSearch: true,
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Company')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.company'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.company') || ''}
      />
    ),
    width: '10%',
  },
  {
    title: t('columns.location'),
    dataIndex: 'locations',
    filters: locationsFilter,
    filterSearch: true,
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Location')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.location'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.location') || ''}
      />
    ),
    width: '10%',
  },
  {
    title: t('columns.product'),
    dataIndex: 'products',
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Product')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.product'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.product') || ''}
      />
    ),
    width: '10%',
  },
  {
    title: t('columns.shared.title'),
    dataIndex: 'shared',
    render: (text, record) => <SharedTag sharedWith={record.sharedWith} />,
    filters: coreCompanyFilter,
    width: '8%',
    ellipsis: true,
  },
  {
    title: t('columns.recent'),
    dataIndex: 'recentActivity',
    ellipsis: true,
    width: '8%',
  },
  {
    title: t('columns.expiration'),
    dataIndex: 'expirationDate',
    ellipsis: true,
    width: '8%',
  },
  {
    title: t('columns.blockchain.title'),
    dataIndex: 'blockchain',
    ellipsis: true,
    filters: [
      {
        text: t('columns.blockchain.filters.algorand'),
        value: 'Algorand',
      },
      {
        text: t('columns.blockchain.filters.none'),
        value: 'None',
      },
    ],
    width: '8%',
  },
  {
    title: t('columns.visibility.title'),
    dataIndex: 'privacy',
    render: (text, record) =>
      t(`columns.visibility.filters.${String(record?.privacy)?.toLowerCase()}`),
    ellipsis: true,
    width: '8%',
    filters: [
      {
        text: t('columns.visibility.filters.private'),
        value: 'Private',
      },
      {
        text: t('columns.visibility.filters.public'),
        value: 'Public',
      },
    ],
  },
  {
    title: t('columns.url'),
    dataIndex: 'url',
    width: '8%',
    ellipsis: true,
    copyable: true,
  },
  {
    dataIndex: 'actions',
    hideInSetting: true,
    render: (text, record) => (
      <Actions
        className="actions"
        buttonProps={{
          ghost: true,
          size: 'small',
        }}
        items={actionItems({ t })}
        onClick={actionsMenuClick}
        actionPayload={record}
      />
    ),
    fixed: 'right',
    width: '7%',
  },
];
const Documents: FC<DetailsDocumentProps> = ({
  activeEvent,
  actionsMenuClick,
  selectedRowKeys,
  onRowSelectionChange,
}) => {
  const {
    params,
    records,
    totalItems,
    isLoading,
    filters: [nameSearchfilter],
    onTableChange,
    coreCompanyFilter,
    tradePartnersFilter,
    locationsFilter,
    t,
  } = useDocumentFilters({
    eventId: activeEvent?.id || '',
    // status: ['Archived', 'Active'],
  });

  return (
    <GTable<DocumentItem>
      columns={columns({
        nameSearchfilter,
        actionsMenuClick,
        coreCompanyFilter,
        tradePartnersFilter,
        locationsFilter,
        t,
      })}
      value={records}
      enableRowSelection
      loading={isLoading}
      options={{
        setting: false,
        reload: false,
      }}
      scroll={{ x: 2500, y: '55vh' }}
      pagination={{ defaultPageSize: params.pageSize, total: totalItems }}
      rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange }}
      // @ts-ignore
      onTableChange={onTableChange}
    />
  );
};
export default Documents;

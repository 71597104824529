import { FC, useMemo } from 'react';
import styles from '../../index.module.less';
import AttributeTable from './AttributeTable';
import DocumentTable from './DocumentTable';
import LocationTable from './LocationTable';
import { TableContainerProps } from './LocationTable/typings';
import SwitchButton from './SwitchButton';
import { useTabStore } from './hooks';

const TableContainer: FC<TableContainerProps> = ({
  onShareDocument,
  onShareLocation,
  companyLocations,
  onEditAttribute,
  isMyLocations,
}) => {
  const tab = useTabStore((state) => state.tab);

  const getTabComponent = useMemo(() => {
    switch (tab) {
      case 'document':
        return <DocumentTable onShareDocument={onShareDocument} />;
      case 'attribute':
        return (
          <AttributeTable companyLocations={companyLocations} onEditAttribute={onEditAttribute} />
        );
      case 'location':
        return (
          <LocationTable
            isMyLocations={isMyLocations}
            onShareLocation={onShareLocation}
            options={{
              setting: true,
              reload: true,
            }}
            isActionColumn
          />
        );
      default:
        return <div />;
    }
  }, [tab, onShareDocument, companyLocations, onEditAttribute, isMyLocations, onShareLocation]);
  return (
    <div className={styles.lefttable}>
      <div className={styles.divcontainer}>
        <div className={styles.switchcontainer}>
          <SwitchButton />
        </div>
        <div className={styles.innercontainer}>{getTabComponent}</div>
      </div>
    </div>
  );
};

export default TableContainer;

import { Select, SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useTranslation } from 'react-i18next';

const TradePartnerSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.add_network.form_fields' });
  const options: Array<DefaultOptionType> = [
    {
      label: t('buyer'),
      value: 'BUYER',
    },
    {
      label: t('supplier'),
      value: 'SUPPLIER',
    },
    {
      label: t('other'),
      value: 'OTHER',
    },
  ];

  return <Select {...props} options={options as OptionType[]} />;
};

export default TradePartnerSelect;

import { QuestionCircleOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Tooltip, Typography } from 'antd';
import { NotificationDropdown } from 'components';
import useSearchStore from 'pages/Search/useSearchStore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'services/api';
import { getDocIdSession, getPathSession, removeDocIdSession, removePathSession } from 'utils';
import AvatarDropdown from './AvatarDropdown';
import SourceDropdown from './SourceDropdown';
import styles from './index.module.less';
import { RightContentProps } from './typings';

declare const window: any;
export type SiderTheme = 'light' | 'dark';

const RightContent: React.FC<RightContentProps> = () => {
  const navigate = useNavigate();
  const { data: account } = useAccount();
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard.toolbar_tooltip' });
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const { setInitPOSearch } = useSearchStore();
  const searchInput = useRef<InputRef>(null);

  const getAccount = useCallback(() => {
    const docID = getDocIdSession();
    if (docID) {
      removeDocIdSession();
      navigate(`../view_document/?id=${docID}`);
    }
    /* if from path exist (particulary for links coming from sourceview) */
    const fromPath = getPathSession();
    if (fromPath) {
      removePathSession();
      navigate(fromPath);
    }
  }, [navigate]);
  useEffect(() => getAccount(), [getAccount]);

  const onSearch = useCallback(
    (value: string) => {
      setShowSearch(false);
      setInitPOSearch?.(value);
      navigate(`../search/${value}`);
    },
    [navigate, setInitPOSearch],
  );

  const onSearchClick = useCallback(() => {
    setShowSearch(true);
    setTimeout(() => {
      searchInput.current!.focus({
        cursor: 'start',
      });
    }, 500);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.accountname}>
        <Typography.Text className={styles.accounttext}>{account?.name}</Typography.Text>
      </div>

      {showSearch && (
        <div className={styles.searchinput}>
          <Input.Search
            ref={searchInput}
            onSearch={onSearch}
            size="small"
            placeholder={t('search_placeholder')}
          />
        </div>
      )}
      <div className={styles.searchbutton}>
        {!showSearch && (
          <Tooltip title={t('search_tooltip')}>
            <Button type="text" icon={<SearchOutlined />} onClick={onSearchClick} />
          </Tooltip>
        )}

        <Tooltip title={t('help')}>
          <Button
            type="text"
            icon={<QuestionCircleOutlined />}
            onClick={() => window.Beacon('toggle')}
          />
        </Tooltip>
        <Tooltip title={t('settings')}>
          <Button type="text" icon={<SettingOutlined />} onClick={() => navigate('../settings')} />
        </Tooltip>
        <NotificationDropdown />
        <SourceDropdown />
        <AvatarDropdown />
      </div>
    </div>
  );
};
export default RightContent;

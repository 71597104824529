import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { message } from 'antd';
import ViewDocumentFormFields from 'pages/Documents/Forms/ViewDocumentForm.fields';
import { DataItem } from 'pages/Documents/Forms/typings';
import useDocumentsStore from 'pages/Documents/hooks/useDocumentsStore';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { UpdateDocumentRequest } from 'services/api/client/src';
import { useDocumentByIdWithDownload, useUpdateDocument } from 'services/api/documents';
import { errorHandler, isDocumentUpdated } from 'utils';
import styles from './index.module.less';
import { ViewDocumentFormProps } from './typings';

const rowProps = { gutter: 32 };

const ViewDocumentForm: FC<ViewDocumentFormProps> = ({ modal, key, form, isWorkflow }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'documents' });
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const queryClient = useQueryClient();
  const updateDocument = useUpdateDocument(queryClient);
  const { getFieldValue, setFieldsValue } = form;
  const documents: Array<DataItem> = getFieldValue('documents') || [];
  const setDocuments = useCallback(
    (data: Array<DataItem>) => {
      setFieldsValue({ documents: data });
    },
    [setFieldsValue],
  );
  const { document, setDocument } = useDocumentsStore();
  const { data } = useDocumentByIdWithDownload(document?.id || '');

  const attachment = useMemo(() => data?.attachment, [data]);

  useEffect(() => {
    if (attachment) {
      editableFormRef.current?.setFieldsValue({
        documentAttachment: attachment,
      });
    }
  }, [attachment]);

  const closeModal = useCallback(() => {
    modal.hide();
  }, [modal]);

  const onFinish = async (formData: DataItem) => {
    try {
      const reqBody: UpdateDocumentRequest = {
        id: document?.id || '',
        privacy: formData.privacy,
      };
      if (isDocumentUpdated(reqBody, document)) {
        const res = await updateDocument.mutateAsync({ id: document?.id || '', document: reqBody });
        message.success(t('view_document.update_success', { id: res?.title || '' }));
      }
      setDocument({ ...formData, id: Date.now().toString() });
      const updatedDocuments = documents.map((doc) => {
        if (doc.id === document?.id) {
          return { ...doc, ...formData };
        }
        return doc;
      });
      setDocuments(updatedDocuments);
      closeModal();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      key={key}
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={ViewDocumentFormFields({ t, document })}
      grid
      title={document?.title || ''}
      visible={modal.visible}
      rowProps={rowProps}
      initialValues={document}
      autoFocusFirstInput
      modalProps={{
        width: '80%',
        ...modal,
        onCancel: closeModal,
        wrapClassName: isWorkflow ? styles.workflowmodal : undefined,
        maskStyle: isWorkflow ? { zIndex: 1001 } : undefined,
        className: isWorkflow ? styles.workflowmodal : undefined,
      }}
      submitter={{
        searchConfig: {
          submitText: t('view_document.update_text'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          onClick: closeModal,
          type: 'primary',
          ghost: true,
        },
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};

export default ViewDocumentForm;

import { ArrowLeftOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { Button, Modal, Space, Typography, message } from 'antd';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { ShareDocumentRequest } from 'services/api/client/src';
import { useCreateShareDocument, useUnShareDocument } from 'services/api/documents';
import { errorHandler } from 'utils';
import styles from './ShareDocument.module.less';
import ShareDocumentFormFields from './ShareDocumentFormFields';
import { DataItem, ShareDocumentProps, UnShareRequest } from './typings';
import useShareDocumentStore from './useShareDocumentStore';

const ShareDocument: FC<ShareDocumentProps> = ({ modal, cleanUpAfterShare }) => {
  const editableFormRef = useRef<ProFormInstance<DataItem>>();
  const { t } = useTranslation('pages', { keyPrefix: 'share_document' });
  const queryClient = useQueryClient();
  const shareDocument = useCreateShareDocument(queryClient);
  const unshareDocument = useUnShareDocument(queryClient);
  const {
    tradePartners: partners,
    setTradePartners: setPartners,
    document,
    documents,
    etradePartners,
    setDocuments,
    setEtradePartners,
  } = useShareDocumentStore();
  const pendingChanges = useMemo(
    () => etradePartners.some((partner) => partner.isUpdated || partner.isDeleted),
    [etradePartners],
  );
  const resetFields = useCallback(() => {
    editableFormRef.current?.resetFields();
    setPartners([]);
    setEtradePartners([]);
    setDocuments([]);
  }, [setPartners, setEtradePartners, setDocuments]);

  const closeModal = useCallback(() => {
    modal.hide();
    resetFields();
    cleanUpAfterShare?.();
  }, [cleanUpAfterShare, modal, resetFields]);
  const onCancel = useCallback(() => {
    if (pendingChanges) {
      Modal.confirm({
        title: t('cancel_changes_title'),
        okText: t('cancel_changes_btn'),
        cancelText: t('cancel_changes_btn_cancel'),
        onOk: closeModal,
        centered: true,
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
      });
    } else {
      closeModal();
    }
  }, [closeModal, pendingChanges, t]);

  const onFinish = useCallback(
    async (formData: DataItem) => {
      const unshareTradePartners =
        etradePartners?.filter((item) => item?.isDeleted)?.map((item) => item?.id || '') || [];
      if (!partners?.length && !unshareTradePartners.length) {
        closeModal();
      }

      if (partners?.length) {
        try {
          const reqBody: ShareDocumentRequest = {
            documentIds: (documents?.length || 0) > 0 ? documents : [document?.id || ''],
            message: formData.message,
            accountIds: partners,
          };
          await shareDocument.mutateAsync(reqBody);
          message.success(t('submit_sucess'));
          closeModal();
        } catch (error) {
          if (errorHandler(error)) {
            message.error(errorHandler(error));
          }
        }
      }
      if (unshareTradePartners?.length) {
        const arrayOfReq: UnShareRequest[] =
          (documents?.length || 0) > 0
            ? documents?.map((item) => ({
                documentId: item || '',
                options: {
                  body: {
                    documentId: item || '',
                    accountIds: unshareTradePartners,
                  },
                },
              })) || []
            : [
                {
                  documentId: document?.id || '',
                  options: {
                    body: {
                      documentId: document?.id || '',
                      accountIds: unshareTradePartners,
                    },
                  },
                },
              ];
        try {
          await Promise.all(arrayOfReq?.map((req) => unshareDocument.mutateAsync(req)));

          message.success(t('unshare_submit_sucess'));

          closeModal();
        } catch (error) {
          if (errorHandler(error)) {
            message.error(errorHandler(error));
          }
        }
      }
    },
    [
      etradePartners,
      partners,
      documents,
      document?.id,
      shareDocument,
      t,
      closeModal,
      unshareDocument,
    ],
  );

  return (
    <BetaSchemaForm<DataItem>
      formRef={editableFormRef}
      layoutType="ModalForm"
      columns={ShareDocumentFormFields({ t, partners, setPartners, documents, etradePartners })}
      grid
      // @ts-ignore
      title={
        <Space>
          {(partners?.length || 0) > 0 && (
            <Button type="text" size="small" onClick={resetFields}>
              <ArrowLeftOutlined />
            </Button>
          )}
          {t('share_document_title', { name: document?.title })}
        </Space>
      }
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: false,
        width: '40%',
        ...modal,
        onCancel,
        className: styles.modal,
      }}
      submitter={{
        searchConfig: {
          // eslint-disable-next-line no-nested-ternary
          submitText: pendingChanges
            ? t('save')
            : (partners?.length || 0) > 0
            ? t('send')
            : t('done'),
          resetText: t('cancel'),
        },
        resetButtonProps: {
          shape: 'round',
          onClick: closeModal,
          hidden: (partners?.length || 0) === 0,
          type: 'primary',
          ghost: true,
        },
        submitButtonProps: {
          shape: 'round',
        },
        render: (_, defaultDoms) => (
          <Space>
            <Typography.Text type="secondary">
              {pendingChanges ? t('pending_changes') : ''}
            </Typography.Text>
            {defaultDoms[0]}
            {defaultDoms[1]}
          </Space>
        ),
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default ShareDocument;

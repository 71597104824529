export const setInviteIdSession = (inviteId: string) => {
  sessionStorage.setItem('inviteId', inviteId);
};

export const getInviteIdSession = () => sessionStorage.getItem('inviteId');

export const removeInviteIdSession = () => {
  sessionStorage.removeItem('inviteId');
};

export const setExistingUserSession = (inviteUser: string) => {
  sessionStorage.setItem('invitedExistingUser', inviteUser);
};

export const getExistingUserSession = () => sessionStorage.getItem('invitedExistingUser');

export const removeExistingUserSession = () => {
  sessionStorage.removeItem('invitedExistingUser');
};

export const setPathSession = (path: string) => {
  sessionStorage.setItem('fromPath', path);
};

export const getPathSession = () => sessionStorage.getItem('fromPath');

export const removePathSession = () => {
  sessionStorage.removeItem('fromPath');
};

import type { ProFormColumnsType } from '@ant-design/pro-form';
import { Input, RowProps } from 'antd';
import { EmailTagInput } from 'components';
import TradePartnerLocationsSelect from 'components/GSelect/TradePartnerLocationsSelect';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { showConnectionType } from 'utils/network';
import styles from './index.module.less';
import { WCIdsProp } from './typings';

const { TextArea } = Input;

const rowProps: RowProps = { gutter: [5, 5] };
const colProps = { xs: 24, sm: 24, md: 24, lg: 24 };

export type DataItem = {
  locations: Array<string>;
  name: string | ReactNode;
  state: string;
  connectionType: string;
  message: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'network.invite'>;
  inviteWCIDsCount: number;
  setInviteWCIDs: (prop: Array<WCIdsProp>) => void;
  inviteWCIDs: Array<WCIdsProp>;
};

export const InviteColumns = ({
  t,
  inviteWCIDsCount,
  inviteWCIDs,
  setInviteWCIDs,
}: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    title: <div className={styles.inivitetitle}>{t('invite_description')}</div>,
    hideInForm: inviteWCIDsCount !== 0,
    columns: [],
  },
  {
    title: `${t('connection_type')}`,
    dataIndex: 'connectionType',
    colProps,
    rowProps,
    valueType: 'radio',
    hideInForm: inviteWCIDsCount !== 0,
    valueEnum: {
      BUYER: t('buyer'),
      SUPPLIER: t('supplier'),
      OTHER: t('other'),
    },
  },
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['connectionType'],
    },
    rowProps,
    columns: ({ connectionType }) => [
      {
        title: `${t('input_title', { type: t(`${showConnectionType(connectionType)}`) })}`,
        dataIndex: 'ids',
        valueType: 'select',
        formItemProps: {
          rules: [
            {
              required: true,
              message: `${t('placeholder', { type: t(`${showConnectionType(connectionType)}`) })}`,
            },
          ],
        },
        renderFormItem: (row, record, form) => (
          <EmailTagInput
            form={form}
            placeholder={`${t('placeholder', {
              type: t(`${showConnectionType(connectionType)}`),
            })}`}
            inviteWCIDs={inviteWCIDs}
            setInviteWCIDs={setInviteWCIDs}
          />
        ),
      },
    ],
  },
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['ids'],
    },
    rowProps,
    columns: () => [
      {
        title: t('select_location_title'),
        dataIndex: 'locations',
        hideInForm: inviteWCIDsCount === 0,
        valueType: 'select',
        rowProps,
        formItemProps: {
          rules: [
            {
              required: true,
              message: `${t('location_required')}`,
            },
          ],
        },
        fieldProps: {
          mode: 'multiple',
          placeholder: t('location_placeholder'),
        },
        renderFormItem: () => (
          <TradePartnerLocationsSelect
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
          />
        ),
      },
      {
        dataIndex: 'message',
        valueType: 'text',
        formItemProps: {
          rules: [
            {
              whitespace: true,
              message: t('invalid_message'),
            },
          ],
        },
        renderFormItem: () =>
          inviteWCIDsCount === 0 ? null : <TextArea rows={7} placeholder={t('value')} />,
        fieldProps: {
          placeholder: t('value'),
        },
      },
    ],
  },
];

import { queryClient } from 'index';
import { GetAccountResponse, LocationResponse } from 'services/api/client/src';
import { coreBizStep, coreDispositions } from './products';
import { DefaultValue, GetDefaultValues } from './typings';

export const getLocationByUrn = (locations: Array<LocationResponse>, urn: string) =>
  locations.find((location) => location?.urn?.includes(urn));

export const getLocationById = (locations: Array<LocationResponse>, id: string) =>
  locations.find((location) => location.id === id);

export const getDefaultValues = ({
  template,
  attributes,
  locationId,
}: GetDefaultValues): DefaultValue => {
  const defaultValues: DefaultValue = {};
  if (template && (attributes?.results?.length || 0) > 0) {
    const account = queryClient.getQueryData<GetAccountResponse | undefined>([
      'account',
      undefined,
    ]);

    // set timeZone default value
    defaultValues.timeZone = account?.timezone;

    template?.templateAttributes?.forEach((f) => {
      const attribute = f?.attribute;
      const fProperties = attribute?.fieldProperties;
      const field = fProperties?.fields;
      const values = fProperties?.values;
      const propertyName = field?.propertyName;
      const fieldProperties = attributes?.results?.map((el) => el?.fieldProperties);
      const fieldProperty = fieldProperties?.find(
        (el) => el?.fields?.propertyName === propertyName,
      );

      if (fieldProperty?.values?.defaultValues || f?.defaultValue) {
        /* override location default value by template default value if exist (doc:https://docs.google.com/document/d/1cr-wlrfDWbjjYw2UVwsMQU4tvAskHDVPjC0Bp4YwhlU/edit) */
        defaultValues[String(propertyName)] =
          f?.defaultValue ||
          fieldProperty?.values?.defaultValues?.find((el) => el?.locationId === locationId)?.value;
      }

      if (propertyName === 'bizStep' && values?.defaultValue) {
        defaultValues[String(propertyName)] =
          coreBizStep()?.find?.((el) => el.urn === values?.defaultValue)?.name || undefined;
      }
      if (propertyName === 'disposition' && values?.defaultValue) {
        defaultValues[String(propertyName)] =
          coreDispositions()?.find?.((el) => el.urn === values?.defaultValue)?.name || undefined;
      }
    });
  }
  return defaultValues;
};

export const isObject = (value: any) =>
  value && typeof value === 'object' && value.constructor === Object;

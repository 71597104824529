import { InfoCircleOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProFormColumnsType, ProFormInstance } from '@ant-design/pro-form';
import { Form, message, RowProps, Typography } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { LocationSelect, TradePartnerSelect } from 'components/GSelect';
import EditableMultiReceiveTable from 'pages/AllShipments/EditableMultiReceiveTable';
import useReceiveEventActions from 'pages/AllShipments/hooks/useReceiveEventActions';
import useWatchValues from 'pages/AllShipments/hooks/useWatchValues';
import { DataItem as DocumentItem } from 'pages/Documents/Forms/typings';
import { useEventsStore } from 'pages/Events/hooks';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationResponse, TradePartnerResponse } from 'services/api/client/src';
import {
  checkSameDate,
  dateLocaleFormat,
  errorHandler,
  getDisabledDate,
  getDisabledTime,
  getTemplateFields,
} from 'utils';
import { ReceiveProductItem } from '../Forms';
import { MultiReceiveProductItem } from '../Forms/AllReceiveForm.fields';
import { ReceiveProductsDataType } from '../Forms/TransformTables/AllReceiveProducts';
import CertDocuments from './CertDocuments';
import { CustomDataTemplate } from './DataTemplate';
import Documents from './Documents';
import styles from './index.module.less';
import { CertificationInfo, MultiReceiveFormFieldsProps, MultiReceiveFormProps } from './typings';

const colProps = { xs: 24, sm: 12, md: 12, lg: 8 };
const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };
const rowProps: RowProps = { gutter: [32, 24] };

export type DataItem = {
  company?: string;
  location?: string;
  companyName?: string;
  locationName?: string;
  date?: string;
  time?: string;
  poNumber?: string;
  inboundShipments?: Array<ReceiveProductItem>;
  documents?: Array<DocumentItem>;
  customProperties?: { [key: string]: any };
  shipEventDate?: string;
  certificationList?: Array<CertificationInfo>;
  initialMultiReceiveObjects?: MultiReceiveProductItem[];
  [key: string]: any;
};

const MultiReceiveFormFields = ({
  template,
  t,
  mainForm,
  editableShipment,
  isMultipleLocations,
  isMultipleDates,
  onChangeDate,
  timeValidation,
}: MultiReceiveFormFieldsProps): ProFormColumnsType<DataItem>[] => [
  {
    dataIndex: 'inboundShipments',
    renderFormItem: (row, config, form) => (
      <EditableMultiReceiveTable
        form={mainForm}
        editableShipment={editableShipment}
        currentForm={form}
      />
    ),
  },
  {
    valueType: 'group',
    title: () => <Typography.Text strong>Event Details</Typography.Text>,
    hideInForm: !isMultipleDates && !isMultipleLocations,
    columns: [
      {
        valueType: 'group',
        columns: [
          {
            valueType: 'group',
            colProps: colPropsFull,
            rowProps,
            columns: [
              {
                title: 'Receive Company',
                dataIndex: 'company',
                fieldProps: {
                  placeholder: 'Receive Company',
                },
                renderFormItem: (row, config, form) => (
                  <TradePartnerSelect
                    initParams={{
                      types: ['Self', 'NonWholechain'],
                    }}
                    onChange={(value: DataItem['tradePartners'], option) => {
                      // @ts-ignore
                      const item: TradePartnerResponse = option?.itemProps;
                      form?.setFieldsValue({ company: item?.id, companyName: item?.name });
                    }}
                  />
                ),
                formItemProps: {
                  rules: [
                    {
                      required: true,
                      message: t('form_fields.custom_data_req'),
                    },
                  ],
                },
                colProps,
                hideInForm: !isMultipleLocations,
              },
              {
                valueType: 'dependency',
                fieldProps: {
                  name: ['company'],
                },
                columns: ({ company }) => [
                  {
                    title: 'Receive Location',
                    dataIndex: 'location',
                    fieldProps: {
                      placeholder: 'Receive Location',
                    },
                    renderFormItem: (row, config, form) => (
                      <LocationSelect
                        onChange={(value, option) => {
                          // @ts-ignore
                          const item: LocationResponse = option?.itemProps;
                          form?.setFieldsValue({
                            location: item?.id,
                            locationName: item?.name,
                          });
                        }}
                        initParams={{
                          tradePartnerStatus: ['Self', 'NonWholechain'],
                        }}
                        filterItem={(val) => (company ? val?.tradePartnerId === company : true)}
                      />
                    ),
                    formItemProps: {
                      rules: [
                        {
                          required: true,
                          message: t('form_fields.custom_data_req'),
                        },
                      ],
                    },
                    colProps,
                    hideInForm: !isMultipleLocations,
                  },
                ],
              },
            ],
          },
          {
            valueType: 'dependency',
            fieldProps: {
              name: ['date', 'shipEventDate'],
            },
            columns: ({ date, shipEventDate }: DataItem) => [
              {
                valueType: 'group',
                colProps: colPropsFull,
                rowProps,
                columns: [
                  {
                    title: 'Received Date',
                    valueType: 'date',
                    dataIndex: 'date',
                    initialValue: null,
                    fieldProps: (form) => ({
                      placeholder: 'Received Date',
                      className: 'full-width',
                      onChange: (value: string) => onChangeDate?.(form, value),
                      disabledDate: getDisabledDate(shipEventDate),
                    }),
                    formItemProps: {
                      rules: [
                        {
                          required: true,
                          message: t('form_fields.date_req'),
                        },
                      ],
                    },
                    colProps,
                    hideInForm: !isMultipleDates,
                  },
                  {
                    title: 'Received Time',
                    valueType: 'time',
                    dataIndex: 'time',
                    className: 'timecol',
                    initialValue: '12:00:00',
                    fieldProps: {
                      placeholder: 'Received Time',

                      className: 'full-width',
                      // check is same day as date
                      disabledTime: checkSameDate(date, shipEventDate)
                        ? getDisabledTime(shipEventDate)
                        : undefined,
                    },

                    formItemProps: {
                      rules: [
                        {
                          required: true,
                          message: t('form_fields.time_req'),
                        },
                        ...(timeValidation?.(date, shipEventDate) || []),
                      ],
                    },
                    colProps,
                    hideInForm: !isMultipleDates,
                  },
                  {
                    title: 'Receiver Purchase Order',
                    dataIndex: 'poNumber',
                    colProps,
                    fieldProps: {
                      placeholder: 'Receiver Purchase Order',
                    },
                    tooltip: {
                      icon: <InfoCircleOutlined />,
                      placement: 'top',
                      title: t('wc_note'),
                      key: null,
                      type: '',
                      props: null,
                    },
                    hideInForm: !isMultipleDates,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    valueType: 'divider',
  },
  {
    dataIndex: 'csvData',
    initialValue: [],
    renderFormItem: (row, record, form) => <CustomDataTemplate form={form} />,
    colProps: colPropsFull,
  },
  {
    valueType: 'formSet',
    dataIndex: 'customProperties',
    initialValue: [],
    columns: getTemplateFields(template),
    rowProps,
    hideInForm: !template,
  },
  {
    dataIndex: 'certificationList',
    rowProps,
    renderFormItem: (row, record, form) => <CertDocuments form={form} />,
    hideInForm:
      !template || !template?.templateAttributes || !template?.allowCertificationDocuments,
  },
  {
    valueType: 'divider',
  },
  {
    dataIndex: 'documents',
    initialValue: [],
    renderFormItem: (_row, _config, form) => <Documents form={form} />,
    colProps: colPropsFull,
  },
  {
    dataIndex: 'locationName',
    fieldProps: {
      className: 'no-display',
    },
    formItemProps: {
      className: 'no-display',
    },
    colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
  },
  {
    dataIndex: 'companyName',
    fieldProps: {
      className: 'no-display',
    },
    formItemProps: {
      className: 'no-display',
    },
    colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
  },
  {
    dataIndex: 'initialMultiReceiveObjects',
    fieldProps: {
      className: 'no-display',
    },
    formItemProps: {
      className: 'no-display',
    },
    colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
  },
  {
    dataIndex: 'shipEventDate',
    fieldProps: {
      className: 'no-display',
    },
    formItemProps: {
      className: 'no-display',
    },
    colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
  },
];

const MultiReceiveForm: FC<MultiReceiveFormProps> = ({ form, modal, editableShipment }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.recieve' });
  const { template } = useEventsStore();
  const modalFormRef = useRef<ProFormInstance>();
  const [currentForm] = Form.useForm();
  const [fetched, setFetched] = useState(false);
  const { dataEntryMethod } = useEventsStore();
  const { onChangeDate, timeValidation, lastEventDate } = useReceiveEventActions({
    eventDate: dateLocaleFormat(editableShipment?.eventDate) || '',
  });
  const isCSVUpload = dataEntryMethod === 'csvUpload';
  const isMultipleLocations: boolean =
    useWatch('isMultipleLocations', form) || isCSVUpload || false;
  const isMultipleDates: boolean = useWatch('isMultipleDates', form) || isCSVUpload || false;

  const { multiReceiveObjects } = useWatchValues(form);
  const receiveProducts: MultiReceiveProductItem = useMemo(
    () =>
      multiReceiveObjects?.find((item) => item?.shipmentId === editableShipment?.shipmentId, []) ||
      [],
    [editableShipment?.shipmentId, multiReceiveObjects],
  );
  const setCurrentForm = useCallback(
    (value: DataItem) => {
      currentForm?.setFieldsValue({
        ...value,
      });
    },
    [currentForm],
  );

  const setMultiReceiveObjects = useCallback(
    (value: ReceiveProductsDataType[]) => {
      form?.setFieldsValue({
        multiReceiveObjects: value,
      });
    },
    [form],
  );

  useEffect(() => {
    if (receiveProducts?.shipmentId && !fetched) {
      setCurrentForm({
        inboundShipments: receiveProducts?.inboundShipments,
        location: receiveProducts?.location,
        locationName: receiveProducts?.locationName,
        company: receiveProducts?.company,
        companyName: receiveProducts?.companyName,
        date: receiveProducts?.date,
        time: receiveProducts?.time,
        poNumber: receiveProducts?.poNumber,
        documents: receiveProducts?.documents,
        customProperties: receiveProducts?.customProperties,
        certificationList: receiveProducts?.certificationList,
        initialMultiReceiveObjects: multiReceiveObjects,
        shipEventDate: receiveProducts?.shipEventDate,
      });
      setFetched(true);
    }
  }, [fetched, multiReceiveObjects, receiveProducts, setCurrentForm]);

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onCancel = useCallback(() => {
    const initialMultiReceiveObjects = currentForm?.getFieldValue('initialMultiReceiveObjects');
    setMultiReceiveObjects(initialMultiReceiveObjects);
    closeModal();
  }, [closeModal, currentForm, setMultiReceiveObjects]);

  const onFinish = async (formData: DataItem) => {
    try {
      setMultiReceiveObjects(
        multiReceiveObjects?.map((item) => {
          if (item?.shipmentId === editableShipment?.shipmentId) {
            return {
              ...item,
              ...(isMultipleDates && {
                date: formData?.date,
                time: formData?.time,
                poNumber: formData?.poNumber,
              }),
              ...(isMultipleLocations && {
                company: formData?.company,
                companyName: formData?.companyName,
                location: formData?.location,
                locationName: formData?.locationName,
              }),
              documents: formData?.documents,
              customProperties: formData?.customProperties,
              certificationList: formData?.certificationList,
            };
          }
          return item;
        }, []),
      );
      closeModal();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      layoutType="ModalForm"
      formRef={modalFormRef}
      form={currentForm}
      columns={MultiReceiveFormFields({
        t,
        template,
        mainForm: form,
        editableShipment,
        isMultipleLocations,
        isMultipleDates,
        onChangeDate,
        timeValidation,
        lastEventDate,
      })}
      grid
      title="Receive Details"
      rowProps={rowProps}
      autoFocusFirstInput
      visible={modal.visible}
      modalProps={{
        destroyOnClose: false,
        width: '80%',
        className: styles.receivemodal,
        ...modal,
        onCancel,
      }}
      submitter={{
        searchConfig: {
          submitText: 'Save',
          resetText: 'Cancel',
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          onClick: onCancel,
          type: 'primary',
          ghost: true,
        },
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default MultiReceiveForm;

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { AttributesStore } from './typings';

const useAttributeStore = create<AttributesStore>()(
  persist(
    (set) => ({
      hasAdvancedSettings: false,
      toggleAdvancedSettings: () =>
        set((state) => ({
          hasAdvancedSettings: !state.hasAdvancedSettings,
        })),
      selectedRow: '',
      setSelectedRow: (selectedRow) => set(() => ({ selectedRow })),
      valueLength: 0,
      setValueLength: (valueLength) => set(() => ({ valueLength })),
      newValue: [],
      setNewValue: (newValue) => set(() => ({ newValue })),
    }),
    {
      name: 'attribute-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ),
);

export default useAttributeStore;

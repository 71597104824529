import { Tag, Tooltip } from 'antd';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'services/api';
import { SharedTagProps } from '../typings';

const SharedTag: FC<SharedTagProps> = ({ record, onClick }) => {
  const { data: account } = useAccount();
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents.shared_tag' });
  const isOwner = useMemo(() => record?.ownerAccount === account?.id, [account, record]);
  const renderTag = useMemo(() => {
    if (isOwner) {
      if (record?.sharedWith?.length) {
        return (
          <Tooltip
            title={
              <div>
                {/* @ts-ignore accountName tbd backend */}
                {record?.sharedWith?.map(({ accountName }) => (
                  <div>{accountName}</div>
                ))}
              </div>
            }
          >
            <Tag color="processing" onClick={onClick}>
              {t?.('with')}{' '}
              {record?.sharedWith?.length > 1
                ? `${record?.sharedWith?.length} ${
                    record?.sharedWith?.length === 1 ? t?.('other') : t?.('others')
                  }`
                : // @ts-ignore accountName tbd backend
                  `${record?.sharedWith?.[0]?.accountName}`}
            </Tag>
          </Tooltip>
        );
      }
      return null;
    }
    return record?.ownerAccountName ? (
      <Tag className="document-tag" onClick={onClick}>
        {t('shared_by')} {record?.ownerAccountName || ''}
      </Tag>
    ) : null;
  }, [isOwner, record?.ownerAccountName, record?.sharedWith, onClick, t]);
  return renderTag;
};
export default SharedTag;

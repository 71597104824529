import { create } from 'zustand';
import { DocumentsStore } from './typings';

const useDocumentsStore = create<DocumentsStore>((set) => ({
  title: '',
  hasAdvancedSettings: false,
  document: undefined,
  eventTagAlert: false,
  setDocument: (document) => set(() => ({ document })),
  toggleAdvancedSettings: () =>
    set((state) => ({
      hasAdvancedSettings: !state.hasAdvancedSettings,
    })),
  setTitle: (title: string) =>
    set(() => ({
      title,
    })),
  setEventTagAlert: (eventTagAlert) =>
    set(() => ({
      eventTagAlert,
    })),
}));

export default useDocumentsStore;

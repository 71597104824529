import { InfoCircleOutlined } from '@ant-design/icons';
import { BetaSchemaForm } from '@ant-design/pro-form';
import { FormInstance, message, Modal, RowProps, Space, Tooltip, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import GenLayout from 'components/GenLayout';
import { useAuthenticationContext } from 'contexts';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useCreateAccount } from 'services/api';
import { CreateAccountRequest } from 'services/api/client/src';
import { getAddress, getGeocode } from 'services/api/usePlaces';
import { errorHandler } from 'utils';
import CreateAccountFormFields from './CreateAccountForm.fields';
import styles from './index.module.less';
import { DataItem } from './typings';

const rowProps: RowProps = {
  gutter: [24, 24],
};
const CreateAccount = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('pages', { keyPrefix: 'create_account' });
  const formRef = useRef<FormInstance<DataItem>>();
  const { signout, refreshToken } = useAuthenticationContext();
  const createAccountAction = useCreateAccount(queryClient);
  const onCancel = useCallback(() => {
    signout();
  }, [signout]);
  const onChangePlace = useCallback(
    async (value: string, option: DefaultOptionType | DefaultOptionType[]) => {
      if (!value) {
        formRef.current?.setFieldsValue({
          address: '',
          addressLine1: '',
          addressLine2: '',
          addressCity: '',
          addressState: '',
          addressCountry: '',
          addressPostalCode: '',
          coordinates: '',
        });
        return;
      }
      // @ts-ignore
      const item: window.google.maps.places.AutocompletePrediction = option?.itemProps || '';
      const geoCode = await getGeocode(item?.place_id);
      const location = geoCode?.[0]?.geometry?.location;
      const address = getAddress(geoCode?.[0]?.address_components || []);
      formRef.current?.setFieldsValue({
        address: value || '',
        addressLine1: address?.addressLine1 || '',
        addressLine2: address?.addressLine2 || '',
        addressCity: address?.city || '',
        addressState: address?.state || '',
        addressCountry: address?.country || '',
        addressPostalCode: address?.postalCode || '',
        coordinates: `${location?.lat?.() || 0},${location?.lng?.() || 0}`,
      });
    },
    [],
  );
  const successModal = () => {
    Modal.success({
      title: t('submit_success_title'),
      content: t('submit_success_description'),
      cancelText: t('submit_success_btn'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      okButtonProps: {
        hidden: true,
      },
      onCancel: () => refreshToken(),
      okCancel: true,
      closable: false,
      centered: true,
    });
  };
  const onFinish = async (formData: DataItem) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const reqBody: CreateAccountRequest = {
        name: formData.name || '',
        address: {
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          city: formData.addressCity,
          state: formData.addressState,
          country: formData.addressCountry,
          postalCode: formData.addressPostalCode,
        },
        isGs1Member: formData.isGS1Member || false,
        phone: `${formData?.phoneCode || '+1'} ${formData?.phone}`,
        timezone: formData.timezone || '',
      };
      await createAccountAction.mutateAsync({ body: reqBody });
      successModal();
    } catch (err) {
      if (errorHandler(err)) {
        message.error(errorHandler(err));
      }
    }
  };
  return (
    <GenLayout className={styles.genlayout}>
      <div className={styles.space}>
        <div>
          <Typography.Title level={3}>{t('title')}</Typography.Title>
          <Space>
            <Typography.Text className={styles.text}>{t('description')}</Typography.Text>
            <Tooltip title={t('description_tooltip')}>
              <Typography.Text className={styles.text} type="secondary">
                <InfoCircleOutlined />
              </Typography.Text>
            </Tooltip>
          </Space>
          <BetaSchemaForm<DataItem>
            layoutType="Form"
            columns={CreateAccountFormFields({ t, onChangePlace })}
            formRef={formRef}
            grid
            title={t('title')}
            autoFocusFirstInput
            submitter={{
              searchConfig: {
                submitText: t('create_btn'),
                resetText: 'Cancel',
              },
              submitButtonProps: {
                shape: 'round',
              },
              resetButtonProps: {
                shape: 'round',
                type: 'primary',
                ghost: true,
                onClick: onCancel,
              },
              render: (_, defaultDoms) => (
                <div className={styles.footer}>
                  <Space>
                    {defaultDoms[0]}
                    {defaultDoms[1]}
                  </Space>
                </div>
              ),
            }}
            rowProps={rowProps}
            onFinish={onFinish}
          />
        </div>
      </div>
    </GenLayout>
  );
};
export default CreateAccount;

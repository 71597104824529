import { LoadScript } from '@react-google-maps/api';
import { Loading } from 'components';
import { useAuthenticationContext } from 'contexts';
import { FC } from 'react';
import { getVariableFromEnv } from 'utils/config';
import { LoadMapProps } from './typings';

const gmapsAPIKey = getVariableFromEnv('GMAPS_API_KEY');

const LoadMap: FC<LoadMapProps> = ({ children }) => {
  const { isAuthenticated, langCode } = useAuthenticationContext();

  if (!isAuthenticated) {
    return <div>{children}</div>;
  }

  if (langCode === '') {
    return <Loading />;
  }

  return (
    <LoadScript
      googleMapsApiKey={gmapsAPIKey}
      libraries={['places']}
      language={langCode || 'en-US'}
      loadingElement={<Loading />}
    >
      {children}
    </LoadScript>
  );
};

export default LoadMap;

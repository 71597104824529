import { Select, SelectProps } from 'antd';
import { Loading } from 'components';
import { BaseSelectRef } from 'rc-select';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentResponse, GetPaginatedDocumentsOptionalParams } from 'services/api/client/src';
import { useDocuments } from 'services/api/documents';
import styles from './index.module.less';

interface SelectedProps {
  value?: string;
  label?: string;
}

type ColumnsType = {
  documents: Array<DocumentResponse>;
  setDocuments: (props: Array<DocumentResponse>) => void;
  newDoc: SelectedProps;
};

const DocumentSelect = ({ documents, setDocuments, newDoc }: ColumnsType) => {
  const actionRef = useRef<BaseSelectRef>(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('pages', { keyPrefix: 'network.share_document' });
  const [selected, setSelected] = useState<Array<SelectedProps>>([]);
  const params: GetPaginatedDocumentsOptionalParams = {
    pageNumber: 1,
    pageSize: 20,
  };

  const { data, isLoading } = useDocuments(params);

  useEffect(() => {
    if (newDoc?.label) {
      setSelected([newDoc]);
    } else {
      setSelected([]);
    }
  }, [newDoc]);

  const getEnumValues: Array<SelectedProps> = useMemo(
    () =>
      data?.results?.map((item) => ({
        value: item.id,
        label: item.title,
      })) || [],
    [data],
  );

  const handleSelect = <
    ValueType = any,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
  >(
    props: SelectProps<ValueType, OptionType>,
  ) => {
    setOpen(false);
    if (actionRef.current !== null) {
      actionRef.current.blur();
    }
    const array = data?.results?.filter((item) => item.id === String(props)) || [];
    setDocuments([...documents, ...array]);
  };

  const handleDeselect = <
    ValueType = any,
    OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
  >(
    props: SelectProps<ValueType, OptionType>,
  ) => {
    setOpen(false);
    if (actionRef.current !== null) {
      actionRef.current.blur();
    }
    const array = documents?.filter((item) => item.id !== String(props));
    setDocuments(array);
  };

  const openMessageBox = () => {
    setOpen(true);
  };

  return isLoading ? (
    <Loading />
  ) : (
    <Select
      ref={actionRef}
      mode="tags"
      className={styles.dropdownwidth}
      value={selected}
      onChange={setSelected}
      placeholder={t('select_placeholder')}
      open={open}
      options={getEnumValues}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onFocus={openMessageBox}
    />
  );
};

export default DocumentSelect;

import { useCallback, useMemo } from 'react';
import { useLocations } from 'services/api';
import { ListLocationsResponse, LocationResponse } from 'services/api/client/src';
import { useCompanyLocations } from 'services/api/useLocations';
import { GetLocationsOptionalParams } from './typings';

export const getTradePartnerType = (
  isOwnAccount?: boolean,
  isWCUser?: boolean,
  isRemovedWC?: boolean,
) => {
  if (isOwnAccount) {
    return 'Self';
  }
  if (isRemovedWC) {
    return 'NonWholechain';
  }
  if (isWCUser) {
    return 'Wholechain';
  }
  return 'NonWholechain';
};

const filterResults = (
  data?: ListLocationsResponse,
  param?: GetLocationsOptionalParams,
): LocationResponse[] =>
  data?.data?.filter((item) => {
    const locationName = param?.locationName
      ? item?.name?.toLowerCase().includes((param?.locationName || '').toLowerCase()) ||
        item?.address?.displayAddress
          ?.toLowerCase()
          .includes((param?.locationName || '').toLowerCase())
      : true;

    const tradePartnerId = param?.partnerIds
      ? param?.partnerIds?.includes(item?.tradePartnerId || '')
      : true;

    const tradePartnerStatus = param?.tradePartnerStatus
      ? param?.tradePartnerStatus?.includes(item?.networkStatus || 'Self')
      : true;

    const isVesselFilter =
      'isVessel' in (param || {}) ? param?.isVessel === (item?.vessel !== null) : true;

    const filter = item?.sharedWith?.map((i) => i?.wholechainId, []);

    const sharedWith = param?.sharedWith
      ? param?.sharedWith?.some((val) => filter?.includes(val))
      : true;

    return locationName && tradePartnerId && tradePartnerStatus && sharedWith && isVesselFilter;
  }, []) || [];

const useListLocation = (param?: GetLocationsOptionalParams) => {
  const { data, isLoading, isFetching, refetch } = useLocations({
    showArchived: param?.archived || false,
  });

  const results = useMemo(() => filterResults(data, param), [data, param]);

  return {
    data: results,
    totalItems: results?.length,
    isLoading,
    isFetching,
    results,
    refetch,
  };
};

export const useGetLocationMutate = () => {
  const getCompanyLocations = useCompanyLocations();

  const getLocationAPICall = useCallback(
    async (param?: GetLocationsOptionalParams) => {
      const data = await getCompanyLocations.mutateAsync({ params: {} });
      const filteredLocation = filterResults(data, param);
      return filteredLocation;
    },
    [getCompanyLocations],
  );

  return {
    getLocationAPICall,
  };
};

export default useListLocation;

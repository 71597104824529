import { MarkerItemProps } from 'components/GMap/typings';
import { GTableDateType } from 'components/GTable/contexts/typings';
import { UseModalVisibilityReturnType } from 'hooks/useModalVisibility/typings';
import { GTableRowSelectionReturnType } from 'hooks/useTableRowSelection';
import { DocumentItem } from 'pages/Documents/typings';
import { ProductInventoryItem } from 'pages/Products/typings';
import { Key, ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { ContainerProductInstance } from 'services/api';
import {
  EventDetailResponse,
  EventNodeStub,
  EventResponse,
  MapModel,
  MapNode,
  ProductResponse,
} from 'services/api/client/src';

export interface EventTitleProps {
  tab?: 'map' | 'diagram' | 'list';
  product?: ProductInventoryItem;
  event?: EventResponse;
  instances?: MapModel;
  t: TFunction<'pages', 'products.event_details'>;
}
export interface GeneralActionItemsProps {
  t: TFunction<'pages', 'products.event_details'>;
  tab?: 'map' | 'diagram';
}

export type EventTabs = 'map' | 'diagram' | 'list';
export interface EventMapProps {
  t: TFunction<'pages', 'products.event_details'>;
  event?: EventResponse;
  productInstance?: ContainerProductInstance;
  isContainer?: string | boolean;
  isInboundShipment?: boolean;
  primaryId?: string;
  eventId?: string;
  product?: ProductResponse;
  modalWidth?: string;
  closeAllModal?: () => void;
}
export interface EventDiagramProps {
  t?: TFunction<'pages', 'products.event_details'>;
  event?: EventResponse;
  productInstance?: ContainerProductInstance;
  isContainer?: string | boolean;
  isInboundShipment?: boolean;
  primaryId?: string;
  eventId?: string;
  product?: ProductResponse;
  showLifeCycle?: boolean;
  showMinmap?: boolean;
  actionsComponent?: ReactNode;
  modalWidth?: string;
  closeAllModal?: () => void;
}
export const EVENT_DETAILS = 'Event Details';
export const EVENT_DATA = 'Event Data';
export const EPCIS = 'EPCIS';
export const DOCUMENT = 'Document';
export type DetailsSection = 'Event Details' | 'Event Data' | 'EPCIS' | 'Document';

export interface DetailsModalProps {
  t?: TFunction<'pages', 'products.event_details'>;
  modalProps?: UseModalVisibilityReturnType;
  event?: EventResponse;
  activeEvent?: EventResponse;
  productInstance?: ContainerProductInstance;
  product?: ProductResponse;
  key?: Key;
  loading?: boolean;
  modalWidth?: string;
  closeAllModal?: () => void;
  hideDocument?: boolean;
}
export interface ActionItemProps {
  t?: TFunction<'pages', 'products.event_details'>;
  activeSection?: DetailsSection;
  selectedRowKeys?: Key[];
}
export interface DetailsContentProps {
  t?: TFunction<'pages', 'products.event_details'>;
  event?: EventResponse;
  node?: ContainerProductInstance;
  productInstance?: ContainerProductInstance;
  activeEvent?: EventResponse;
  product?: ProductResponse;
  isSerial?: boolean;
  identifier?: string;
}
export interface DetailsDataProps {
  t?: TFunction<'pages', 'products.event_details'>;
  event?: EventResponse;
  productInstance?: ContainerProductInstance;
  activeEvent?: EventResponse;
  product?: ProductResponse;
  isPretty?: boolean;
  isSerial?: boolean;
  identifier?: string;
}
export interface DetailsDocumentProps {
  t?: TFunction<'pages', 'products.event_details'>;
  event?: EventResponse;
  productInstance?: ContainerProductInstance;
  product?: ProductResponse;
  activeEvent?: EventResponse;
  actionsMenuClick: (actionItemKey: string, actionPayload?: DocumentItem) => void;
  selectedRowKeys: GTableRowSelectionReturnType['selectedRowKeys'];
  onRowSelectionChange: GTableRowSelectionReturnType['onRowSelectionChange'];
  isSerial?: boolean;
  identifier?: string;
}
export interface DetailsEpcisProps {
  t?: TFunction<'pages', 'products.event_details'>;
  event?: EventResponse;
  productInstance?: ContainerProductInstance;
  product?: ProductResponse;
  activeEvent?: EventResponse;
  isPretty?: boolean;
  isSerial?: boolean;
  identifier?: string;
}

export interface EventItem {
  id?: string;
  attribute?: string;
  value?: string;
}

export interface DataTitleProps {
  t?: TFunction<'pages', 'products.event_details'>;
  activeEvent?: EventResponse;
  productInstance?: ContainerProductInstance;
  product?: ProductResponse;
  isSerial?: boolean;
  identifier?: string;
}
export interface MainDiagramProps {
  fullscreenView: any;
  mainGraph: any;
  setMainGraph: any;
  event: any;
  onNodeClick?: (node: any) => void;
  productInstance?: ContainerProductInstance;
  product?: ProductResponse;
  isSerial?: boolean;
  identifier?: string;
  showMinmap?: boolean;
  actionComponent?: ReactNode;
  primaryId?: string;
}

export interface DocumentTitleProp {
  total?: number;
  product?: ProductInventoryItem;
  selectedRowKeys: Array<Key>;
  eventId: string;
  onActionItemClick: (actionItemKey: string) => void;
}

export interface GeneralActionItemProps {
  t: TFunction<'pages', 'products'>;
  selectedRowKeys: Array<Key>;
  selectedRows?: GTableDateType[];
  tab: string;
}

export interface LocationState {
  state: {
    from?: string;
  };
}

export interface EventListItem {
  productName?: string;
  productId?: string;
  primaryId?: string;
  quantity?: string;
  event?: string;
  company?: string;
  location?: string;
  eventDate?: string;
  itemProps?: EventDetailResponse;
}
export type OnInfoModalClickFn = (eventInfo?: EventDetailResponse) => void;

export interface EventListProps {
  eventId?: string;
  isInboundShipment?: boolean;
  product?: ProductResponse;
  productInstance?: ContainerProductInstance;
  event?: EventResponse;
  modalWidth?: string;
  closeAllModal?: () => void;
}

export type RenderEventNode = (node: EventNodeStub) => ReactNode;

export interface EventContextProps {
  showAllProducts: boolean;
  lifeCyle: string;
  setShowAllProducts: (showAllProducts: boolean) => void;
  inventoryItem?: ProductInventoryItem;
}

export interface FlatObject {
  [key: string]: any;
}

export type MapNodeFn = (item?: MapNode) => MarkerItemProps<MapNode>;

export interface LifeCycleHeaderProps {
  propsString?: string;
}

// @ts-nocheck
import { InfoCircleOutlined } from '@ant-design/icons';
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { Button, Form, message, RowProps, Space, Typography } from 'antd';
import { useActions, useDrawerVisibility } from 'hooks';
import moment from 'moment';
import { useEventActions } from 'pages/Events/components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useCreateBatchShipment, useUpdateBatchShipmentById } from 'services/api';
import { CreateBatchShipRequest, UpdateBatchShipRequest } from 'services/api/client/src';
import useTradePartners from 'services/api/useTradePartners';
import {
  apiDateFormat,
  checkLotExists,
  errorHandler,
  getProductById,
  getTimeFormat,
  getUom,
  hasUnsavedChanges,
} from 'utils';
import useShipmentStore from '../hooks/useShipmentStore';
import { BatchShipProductItem } from '../typings';
import BacthShipFormFields from './BatchShipForm.fields';
import DrawerFormHeader from './DrawerFormHeader';
import styles from './index.module.less';
import { DataItem } from './typings';

const rowProps: RowProps = { gutter: [24, 24] };
const { Text } = Typography;

const BatchShipForm = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.batch_ship' });
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const { data: dataPartners } = useTradePartners();

  const tradePartners = useMemo(
    () => dataPartners?.data?.filter((p) => p.type === 'Self'),
    [dataPartners],
  );

  const { products } = useActions();
  // const createBatchShip = useCreateBatchShipment(queryClient);
  // const updateBatchShip = useUpdateBatchShipmentById(queryClient);
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const [mainForm] = Form.useForm();
  const [headerForm] = Form.useForm(mainForm);
  const { setFieldsValue } = mainForm;
  const { hasAdvancedSettings, dataEntryMethod, submitting, setSubmitting } = useShipmentStore();
  const { onCreateBatchShipEvent, defaultTimezone } = useEventActions();
  const [savingDraft, setSavingDraft] = useState<boolean>();
  const isManualEntry = dataEntryMethod === 'manual';

  const onFinish = async (formData: DataItem) => {
    try {
      hasUnsavedChanges();
      if (isManualEntry) {
        checkLotExists(formData?.batchShipProducts);
      } else {
        checkLotExists((formData?.csvData || []) as BatchShipProductItem[]);
      }
      const isMultipleDates = formData?.isMultipleDates || !isManualEntry;
      const sEventDate = formData?.date ? moment(formData.date).format('YYYY-MM-DD') : undefined;
      const sEventTime = formData?.time ? getTimeFormat(formData.time) : undefined;
      const dispatchDate = isMultipleDates
        ? undefined
        : new Date(moment(`${sEventDate} ${sEventTime}`).toISOString());
      const singleEventTime = apiDateFormat(formData?.date, formData?.time);

      const ticketItems: CreateBatchShipRequest['ticketItems'] = isManualEntry
        ? formData?.batchShipProducts?.map((item) => {
            const mEventTime =
              item?.date && item?.time
                ? apiDateFormat(item?.date, getTimeFormat(item?.time))
                : undefined;
            const eventTime = isMultipleDates ? mEventTime : singleEventTime;

            return {
              productId: item.parentProductId,
              productName: item.name,
              instanceId: item.primaryId,
              lotSerial: item.lotSerial,
              instanceInventory: item.instanceInventory,
              purchaseOrder: item.purchaseOrder,
              quantity: Number(item?.quantity || 0),
              unitDescriptor: item.unitOfMeasure,
              currentInventory: item?.currentInventory || 0,
              isLot: !!item?.lotSerial,
              eventTime,
              documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            };
          }) || []
        : formData?.csvData?.map((item) => {
            const product = getProductById(item.productId, products);
            return {
              productId: item.productId,
              productName: item?.productName || product?.name || '',
              instanceId: item.primaryId,
              lotSerial: item.lotSerial,
              instanceInventory: item.instanceInventory,
              purchaseOrder: item.purchaseOrder,
              quantity: Number(item?.quantity || 0),
              unitDescriptor: item.unitOfMeasure || getUom({ product }),
              currentInventory: item?.currentInventory || product?.currentInventory || 0,
              isLot: !!item?.lotSerial,
              eventTime:
                item?.date && item?.time
                  ? apiDateFormat(item?.date, getTimeFormat(item?.time))
                  : undefined,
              documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            };
          }) || [];

      /* create draft */
      const draftReqData: CreateBatchShipRequest = {
        ticketId: formData.ticketId,
        bizStep: formData.bizStep,
        eventDate: !isMultipleDates ? sEventDate : undefined,
        eventTime: !isMultipleDates ? sEventTime : undefined,

        eventTimezone: formData.timeZone,
        disposition: formData.disposition,
        origin: {
          tradePartnerName: tradePartners?.[0]?.name,
          tradePartnerId: tradePartners?.[0]?.id,
          locationId: formData?.shipFromAddress,
          locationName: formData?.shipFromAddressName,
        },
        destination: {
          tradePartnerId: formData.shipToCompany,
          tradePartnerName: formData.shipToCompanyName,
          locationId: formData?.shipToAddress,
          locationName: formData?.shipToAddressName,
        },
        ticketItems,
      };
      setSubmitting(true);
      // const batchShipDraft = await createBatchShip.mutateAsync(draftReqData);

      /* update ticket request to sent */
      const reqData: UpdateBatchShipRequest = {
        dispatchDate,
        status: 'PENDING',
        ...draftReqData,
        documentIds: formData?.documents?.map((doc) => doc?.id || ''),
      };
      // await updateBatchShip.mutateAsync({
      //   id: batchShipDraft?.id || '',
      //   body: reqData,
      // });
      await onCreateBatchShipEvent(draftReqData, formData);
      setSubmitting(false);

      closeDrawer();

      setTimeout(() => {
        message.success(
          t('ship_success', {
            count: reqData.ticketItems?.length || 0,
          }),
        );
      }, 1000);
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
      setSubmitting(false);
    }
  };
  const onSaveDraft = async () => {
    try {
      hasUnsavedChanges();
      await mainForm.validateFields(['ticketId']);

      const formData: DataItem = mainForm.getFieldsValue();
      const isMultipleDates = formData?.isMultipleDates || !isManualEntry;
      const sEventDate = formData?.date ? moment(formData.date).format('YYYY-MM-DD') : undefined;
      const sEventTime = formData?.time ? getTimeFormat(formData.time) : undefined;
      const ticketItems = isManualEntry
        ? formData?.batchShipProducts?.map((item) => ({
            productId: item.parentProductId,
            productName: item.name,
            instanceId: item.primaryId,
            lotSerial: item.lotSerial,
            instanceInventory: item.instanceInventory,
            purchaseOrder: item.purchaseOrder,
            quantity: Number(item?.quantity || 0),
            unitDescriptor: item.unitOfMeasure,
            currentInventory: item?.currentInventory || 0,
            isLot: !!item?.lotSerial,
            eventTime:
              item?.date && item?.time
                ? apiDateFormat(item?.date, getTimeFormat(item?.time))
                : undefined,
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
          })) || []
        : formData?.csvData?.map((item) => {
            const product = getProductById(item.productId, products);

            return {
              productId: item.productId,
              productName: item.productName || product?.name,
              instanceId: item.primaryId,
              lotSerial: item.lotSerial,
              instanceInventory: item.instanceInventory,
              purchaseOrder: item.purchaseOrder,
              quantity: Number(item?.quantity || 0),
              unitDescriptor: item.unitOfMeasure || getUom({ product }),
              currentInventory: item?.currentInventory || product?.currentInventory || 0,
              isLot: !!item?.lotSerial,
              eventTime:
                item?.date && item?.time
                  ? apiDateFormat(item?.date, getTimeFormat(item?.time))
                  : undefined,
              documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            };
          }) || [];
      const reqData: CreateBatchShipRequest = {
        ticketId: formData.ticketId,
        bizStep: formData.bizStep,
        eventDate: !isMultipleDates ? sEventDate : undefined,
        eventTime: !isMultipleDates ? sEventTime : undefined,
        eventTimezone: formData.timeZone,
        disposition: formData.disposition,
        origin: {
          tradePartnerName: tradePartners?.[0]?.name,
          tradePartnerId: tradePartners?.[0]?.id,
          locationId: formData?.shipFromAddress,
          locationName: formData?.shipFromAddressName,
        },
        destination: {
          tradePartnerId: formData.shipToCompany,
          tradePartnerName: formData.shipToCompanyName,
          locationId: formData?.shipToAddress,
          locationName: formData?.shipToAddressName,
        },
        ticketItems,
      };
      setSavingDraft(true);
      // const res = await createBatchShip.mutateAsync(reqData);
      setSavingDraft(false);
      closeDrawer();
      setTimeout(() => {
        message.success(
          <Space>
            {t('save_draft_success', {
              count: reqData?.ticketItems?.length || 0,
            })}
            {/* <Typography.Link onClick={() => onViewDraft(res)}>{t('view_draft')}</Typography.Link> */}
          </Space>,
        );
      }, 1000);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      if (!error?.errorFields && errorHandler(error)) {
        message.error(errorHandler(error));
      }
      setSavingDraft(false);
    }
  };

  useEffect(() => {
    setFieldsValue({
      shipFromCompany: tradePartners?.[0]?.id,
      shipFromCompanyName: tradePartners?.[0]?.name,
    });
  }, [setFieldsValue, tradePartners]);

  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={BacthShipFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        editableTableFormRef,
        defaultTimezone,
        t,
      })}
      grid
      // @ts-ignore
      title={<DrawerFormHeader title={t('title')} form={headerForm} t={t} />}
      visible={visible}
      rowProps={rowProps}
      autoFocusFirstInput
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        ...restDrawerVisibility,
      }}
      submitter={{
        searchConfig: {
          submitText: t('submit_btn'),
        },
        submitButtonProps: {
          shape: 'round',
          loading: submitting,
          disabled: savingDraft,
        },
        render: (_, defaultDoms) => (
          <Space>
            <Text type="secondary" className={styles.footertext}>
              <span>
                <InfoCircleOutlined /> &nbsp; {t('footer_title')}
              </span>
            </Text>
            <Button
              type="primary"
              shape="round"
              ghost
              onClick={onSaveDraft}
              loading={savingDraft}
              disabled={submitting}
            >
              {t('save_draft_btn')}
            </Button>
            {defaultDoms[1]}
          </Space>
        ),
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default React.memo(BatchShipForm);

import { ProFormColumnsType } from '@ant-design/pro-form';
import EventTable from './EventTable';

export interface EventTableItem {
  id?: string;
  fieldAttribute?: string;
  dataAttribute?: string;
  fieldType?: string;
  value?: Array<string>;
  stdOption?: string;
  namespace?: string;
  location?: string;
  allowDelete?: boolean;
}

export type DataItem = {
  eventTable: Array<EventTableItem>;
};

export const EventTableFields = (): ProFormColumnsType<DataItem>[] => [
  {
    dataIndex: 'eventTable',
    initialValue: [],
    renderFormItem: (_schema, _config, form) => <EventTable form={form} />,
    colProps: {
      xs: 24,
      md: 24,
    },
  },
];

export default EventTableFields;

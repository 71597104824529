import { SelectProps } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePlaces from 'services/api/usePlaces';
import { useDebouncedCallback } from 'use-debounce';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

const PlacesSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: SelectProps<ValueType, OptionType>,
) => {
  const [input, setInput] = useState<string>('');
  const { data, isLoading } = usePlaces(input);
  const { t } = useTranslation('pages', { keyPrefix: 'select' });

  // we create here the list with the shape the select is expecting for
  const options: Array<DefaultOptionType> = useMemo(
    () =>
      data?.map((item) => ({
        label: item.description,
        value: item.description,
        itemProps: item,
      })) || [],
    [data],
  );

  const debounced = useDebouncedCallback(
    // function
    (filterText) => {
      setInput(filterText || '');
    },
    // delay in ms
    1000,
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText }) => {
      debounced(filterText);
    },
    [debounced],
  );

  return (
    <GSelect<ValueType, OptionType>
      placeholder={t('places_placeholder')}
      {...props}
      loading={isLoading}
      options={options as OptionType[]}
      fetchData={fetchData}
    />
  );
};

export default PlacesSelect;

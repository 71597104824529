import { ProFormInstance } from '@ant-design/pro-form';
import { ProColumns } from '@ant-design/pro-table';
import { Form, Modal, message } from 'antd';
import { ActionButtons, GTable } from 'components';
import { useModalVisibility } from 'hooks';
import React, { FC, useCallback } from 'react';
import { TFunction } from 'react-i18next';
import { useParams } from 'react-router';
import { useProductById } from 'services/api';
import { SubProductResponse } from 'services/api/client/src';
import { errorHandler } from 'utils';
import { DataItem as ProductDataItem } from '../../Actions/AddProduct/Columns';
import { SubProductsProps } from '../typings';
import AddSubProduct from './AddSubProduct';

type ColumnType = {
  t?: TFunction<'pages', 'products.settings'>;
  onDelRow?: (record?: SubProductResponse) => void;
};
const Columns = ({ t, onDelRow }: ColumnType): ProColumns<SubProductResponse>[] => [
  {
    title: t?.('col_epc_urn_title'),
    dataIndex: 'urn',
    copyable: true,
    ellipsis: true,
  },
  {
    title: t?.('col_product_name_title'),
    dataIndex: 'name',
  },
  {
    title: t?.('col_company_name'),
    dataIndex: 'accountName',
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    fixed: 'right',
    width: 150,
    render: (_text, record) => (
      <ActionButtons
        showDelete
        showEdit={false}
        record={record}
        deleteTooltipText={t?.('remove_sp_confirm')}
        onDelete={() => onDelRow?.(record)}
      />
    ),
  },
];
const SubProducts: FC<SubProductsProps> = ({ t, products }) => {
  const { productId = '1' } = useParams();
  const { data: product } = useProductById(productId);

  const spModal = useModalVisibility(false);
  const mainForm = Form.useFormInstance<ProFormInstance<ProductDataItem>>();

  const onConfirmDel = useCallback(
    async (record?: SubProductResponse) => {
      try {
        const subProducts = products?.filter((el) => el?.id !== record?.id);
        mainForm.setFieldsValue({
          // @ts-ignore
          subProducts,
        });

        message.success(
          t?.('remove_sp_success', {
            name: record?.name || '',
          }),
        );
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    },
    [mainForm, products, t],
  );
  const onDelRow = useCallback(
    (record?: SubProductResponse) => {
      Modal.warning({
        title: t?.('remove_sp_title', {
          name: record?.name || '',
        }),
        content: t?.('remove_sp_desc', {
          name: record?.name || '',
        }),
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        okText: t?.('remove_sp_confirm'),
        cancelText: t?.('remove_sp_cancel'),
        okCancel: true,
        onOk: () => onConfirmDel(record),
        centered: true,
      });
    },
    [t, onConfirmDel],
  );

  const onAddRecord = useCallback(() => {
    spModal.show();
  }, [spModal]);

  return (
    <>
      <GTable<SubProductResponse>
        columns={Columns({ t, onDelRow })}
        options={{
          setting: false,
          reload: false,
        }}
        value={products}
        enableRecordCreator
        onAddRecordClick={onAddRecord}
      />
      <AddSubProduct modal={spModal} parentProduct={product} />
    </>
  );
};
export default React.memo(SubProducts);

/* eslint-disable no-undef */
import { Polyline } from '@react-google-maps/api';
import { FC, useMemo } from 'react';
import { EventNodeInstance, MapDiagramEdge, MapNode } from 'services/api/client/src';
import { MarkerItemProps, PolylinesProps } from './typings';

const getPathFromEdges = (
  markers?: Array<MarkerItemProps<MapNode>>,
  edges?: Array<MapDiagramEdge>,
) => {
  if (markers && edges) {
    const paths =
      edges?.length > 0
        ? edges.map((edge: MapDiagramEdge) => {
            const sourceNode = markers.find((node) => node?.id === edge?.source);
            const targetNode = markers.find((node) => node?.id === edge?.target);
            const linePath = [
              {
                lat: sourceNode?.position?.lat,
                lng: sourceNode?.position?.lng,
              },
              {
                lat: targetNode?.position?.lat,
                lng: targetNode?.position?.lng,
              },
            ] as google.maps.LatLng[];

            // it has receive event if target node is receive placeholder or target node has receive event
            const containsReceiveEvent = targetNode?.extra?.events?.some(
              (event: EventNodeInstance) => event?.eventType === 'Receive',
            );
            const hasReceiveEvent = targetNode?.extra?.acceptedByReceiver || containsReceiveEvent;
            const iconsetngs: google.maps.Symbol = {
              path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              ...(!hasReceiveEvent && {
                scale: 2,
                strokeOpacity: 1,
                strokeColor: '#737373',
                strokeWeight: 2,
              }),
            };
            const dashdotIcon: google.maps.Symbol = {
              path: 'M 0,-1 0,1',
              scale: 4,
              strokeOpacity: 1,
              strokeColor: '#737373',
              strokeWeight: 2,
            };
            const options: google.maps.PolylineOptions = {
              geodesic: false,
              strokeOpacity: 0.8,
              strokeColor: '#2b378f',
              strokeWeight: hasReceiveEvent ? 2 : 0,
              clickable: false,
              draggable: false,
              editable: false,
              visible: true,
              icons: hasReceiveEvent
                ? [
                    {
                      icon: iconsetngs,
                      offset: '0',
                      repeat: '100px',
                    },
                  ]
                : [
                    {
                      icon: dashdotIcon,
                      offset: '0',
                      repeat: '20px',
                    },
                    {
                      icon: iconsetngs,
                      offset: '0',
                      repeat: '100px',
                    },
                  ],
              zIndex: 1,
            };

            return {
              linePath,
              options,
            };
          }, [])
        : [];

    return paths;
  }
  return [];
};

const Polylines: FC<PolylinesProps> = ({ markers, edges }) => {
  const paths = useMemo(() => getPathFromEdges(markers, edges), [markers, edges]);
  return (
    <div>
      {paths?.map((path) => (
        <Polyline
          path={path?.linePath}
          options={path?.options}
          key={String(path?.linePath?.[0].lat)}
        />
      ))}
      ;
    </div>
  );
};
export default Polylines;

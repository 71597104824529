import ProLayout, { MenuDataItem } from '@ant-design/pro-layout';
import { BackTop } from 'antd';
import { logoSvg, smallLogoColorSvg, smallLogoSvg } from 'assets';
import { Loading, Protected } from 'components';
import menuData from 'config/menuData';
import { useWindowSize } from 'hooks';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getActiveAccount, useAccount, useListAccounts } from 'services/api';
import { getExistingUserSession } from 'utils';
import { Footer } from './components';
import RightContent from './components/RightContent';

const settings = {
  primaryColor: '#f47b20;',
  contentWidth: 'Fluid',
  fixSiderbar: true,
  fixedHeader: true,
  iconfontUrl: '',
  layout: 'side',
  menu: { locale: true },
  navTheme: 'dark',
  title: 'Wholechain',
};

const menuItemRender = (menuItem: MenuDataItem, defaultDom: ReactNode) => {
  if (menuItem.isUrl || menuItem.children || !menuItem.path) {
    return defaultDom;
  }

  return <Link to={menuItem.path}>{defaultDom}</Link>;
};

const rightContentRender = () => <RightContent settings={{ navTheme: 'dark', layout: 'side' }} />;

const GeneralLayout = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation('pages', { keyPrefix: 'drawer_menu' });
  const [width] = useWindowSize();
  const showOrphan = true;
  const logo = useMemo(() => {
    if (!collapsed) {
      return logoSvg;
    }
    if (width < 769) {
      return smallLogoColorSvg;
    }
    return smallLogoSvg;
  }, [collapsed, width]);

  const { data: accountData } = useAccount();
  const layoutMenuData = menuData({ t });

  if (!showOrphan || !accountData?.resolveOrphansEnabled) {
    layoutMenuData.pop();
  }
  const navigate = useNavigate();
  const { data: accounts, isLoading } = useListAccounts();

  const getAccount = useCallback(() => {
    if ((accounts?.length || 0) > 1) {
      const isExistingUser = getExistingUserSession();
      if (isExistingUser === 'redeem') {
        navigate('../change_account');
      }
      const activeAccount = getActiveAccount();
      const isAccountMatch = accounts?.find((item) => activeAccount?.accountId === item.accountId);
      if (!isAccountMatch) {
        navigate('../change_account');
      }
    }
  }, [accounts, navigate]);
  useEffect(() => getAccount(), [getAccount]);

  const children = useMemo(
    () =>
      isLoading ? (
        <Loading />
      ) : (
        <Protected>
          <ProLayout
            logo={logo}
            location={location}
            menuDataRender={() => layoutMenuData}
            menuItemRender={menuItemRender}
            itemRender={() => null}
            footerRender={Footer}
            rightContentRender={rightContentRender}
            onCollapse={setCollapsed}
            {...settings}
          >
            <Outlet />
            <BackTop />
          </ProLayout>
        </Protected>
      ),
    [isLoading, logo, location, layoutMenuData],
  );

  return children;
};

export default GeneralLayout;

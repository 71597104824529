import { BetaSchemaForm } from '@ant-design/pro-form';
import { Form, message } from 'antd';
import { useDrawerVisibility } from 'hooks';
import { useTranslation } from 'react-i18next';
import { DataItem } from '../../typings';
import AddTemplateFormFields from './AddTemplateForm.fields';
import DrawerFormHeader from './DrawerFormHeader';

const rowProps = { gutter: 32 };

const AddDocumentTemplateForm = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates_document.add_template' });
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const [mainForm] = Form.useForm();
  const [headerForm] = Form.useForm(mainForm);
  const onFinish = async () => {
    message.success(t('success_message', { id: Date.now() }));
    closeDrawer();
  };
  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={AddTemplateFormFields()}
      grid
      // @ts-ignore
      title={<DrawerFormHeader title={t('title')} form={headerForm} />}
      visible={visible}
      rowProps={rowProps}
      autoFocusFirstInput
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        ...restDrawerVisibility,
      }}
      submitter={{
        searchConfig: {
          submitText: t('submit_btn'),
        },
        submitButtonProps: {
          shape: 'round',
        },
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default AddDocumentTemplateForm;

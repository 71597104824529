import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import getTranslationByLangCode from 'utils/language';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: false,
    load: 'currentOnly',
    lowerCaseLng: true,
    ns: 'pages',
    defaultNS: 'default',
    fallbackNS: 'pages',
    debug: false,
    // react: {
    //   useSuspense: false,
    // },
    lng: 'en',
    backend: {
      loadPath: 'locales/{{lng}}/{{ns}}.json',
      addPath: 'locales/{{lng}}/{{ns}}',
      request: (options: any, url: any, payload: any, callback: any) => {
        const lng = url?.split('/');
        try {
          getTranslationByLangCode(lng?.[1] || 'en').then((translation: any) => {
            callback('', {
              status: 200,
              data: JSON.stringify(translation),
            });
          });
        } catch (e) {
          callback(e, { data: '', status: 400 });
        }
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, message, Tooltip, Typography, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { pdfDocumentPng } from 'assets';
import classNames from 'classnames';
import useModalVisibility from 'hooks/useModalVisibility';
import { DataItem } from 'pages/Documents/Forms/typings';
import useDocumentsStore from 'pages/Documents/hooks/useDocumentsStore';
import { useEventsStore } from 'pages/Events/hooks';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import AddDocumentForm from './AddDocumentForm';
import AddExistingDocuments from './AddExistingDocuments';
import styles from './index.module.less';
import { DocumentProps } from './typings';
import ViewDocumentForm from './ViewDocumentForm';

const Documents: FC<DocumentProps> = ({ form }) => {
  const modal = useModalVisibility(false);
  const viewModal = useModalVisibility(false);
  const existingDocModal = useModalVisibility(false);
  const location = useLocation();
  const isWorkflow = useMemo(() => location.pathname.includes('workflow'), [location]);
  const { t } = useTranslation('pages', { keyPrefix: 'events.document_details' });
  const tDocuments = useTranslation('pages', { keyPrefix: 'documents' }).t;
  const { document, setDocument, setEventTagAlert } = useDocumentsStore();
  const { template } = useEventsStore();
  const { setFieldsValue } = form;
  const documents: Array<DataItem> = Form.useWatch('documents', form) || [];
  const setDocuments = useCallback(
    (data: Array<DataItem>) => {
      setFieldsValue({ documents: data });
    },
    [setFieldsValue],
  );
  const fileList: Array<UploadFile> =
    documents?.map?.((doc: DataItem) => ({
      uid: doc?.id || '',
      name: doc?.title || '',
      status: 'done',
      url: doc?.documentURL || '',
      thumbUrl: pdfDocumentPng,
    })) || [];

  const onRemove: UploadProps['onRemove'] = (file) => {
    const id = file.uid as string;
    const newDocuments = documents.filter((doc) => doc.id !== id);
    setDocuments(newDocuments);
  };

  const onUpload: UploadProps['customRequest'] = async ({
    file,
    onProgress,
    onSuccess,
    onError,
  }) => {
    try {
      onProgress?.({ percent: 0 });
      setTimeout(() => onProgress?.({ percent: 50 }), 1000);
      onProgress?.({ percent: 100 });

      onSuccess?.(file);
    } catch (error: any) {
      onError?.({
        name: 'upload-error',
        message: error.message,
      });
    }
  };
  const onPreview = (file: UploadFile) => {
    const currdocument = documents.find((doc) => doc.id === file.uid);
    if (currdocument) {
      setDocument(currdocument);
      viewModal.show();
    }
  };
  const onAddExisting = useCallback(() => {
    existingDocModal.show();
  }, [existingDocModal]);
  const onAddDocument = useCallback(() => {
    modal.show();
    setEventTagAlert(true);
  }, [modal, setEventTagAlert]);
  const itemRender: UploadProps['itemRender'] = (node, file) => (
    <div className={styles.uploaditem}>
      {node}
      <div className={styles.uploaditemtext}>
        <Tooltip placement="bottom" title={file.name}>
          <Typography.Text type="secondary" ellipsis>
            {file.name}
          </Typography.Text>
        </Tooltip>
      </div>
    </div>
  );

  const props: UploadProps = {
    listType: 'picture-card',
    name: 'file',
    multiple: true,
    accept: '.pdf, application/pdf',
    itemRender,
    openFileDialogOnClick: false,
    fileList,
    onRemove,
    onPreview,

    customRequest: onUpload,
    onChange: (info: UploadChangeParam<UploadFile<any>>) => {
      const { file } = info;
      const { status } = file;

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (file?.originFileObj) {
          const blobUrl = URL.createObjectURL(file.originFileObj);
          file.url = blobUrl;
        }
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const isReqDocuments = useMemo(
    () =>
      template &&
      (template?.templateDocuments?.filter((el) => el?.required || false)?.length || 0) > 0,
    [template],
  );
  const isOptionalDocuments = useMemo(
    () => template && (template?.templateDocuments?.filter((el) => !el?.required)?.length || 0) > 0,
    [template],
  );
  const requestDocuments = useMemo(
    () =>
      template?.templateDocuments
        ?.filter((el) => el?.required || false)
        .map((el) => tDocuments(`${el?.documentType?.toLocaleLowerCase()}`) || '')
        .join(', '),
    [tDocuments, template?.templateDocuments],
  );
  const optionalDocuments = useMemo(
    () =>
      template?.templateDocuments
        ?.filter((el) => !el?.required)
        .map((el) => el?.documentName || '')
        .join(', '),
    [template],
  );

  return (
    <>
      <AddDocumentForm
        form={form}
        modal={modal}
        onAddExisting={onAddExisting}
        isWorkflow={isWorkflow}
        isEvent
      />
      <ViewDocumentForm form={form} modal={viewModal} key={document?.id} isWorkflow={isWorkflow} />
      <AddExistingDocuments
        form={form}
        modal={existingDocModal}
        onAddDocument={onAddDocument}
        isWorkflow={isWorkflow}
      />
      <Typography.Text className={classNames('ant-pro-form-group-title', styles.documentsection)}>
        {t('documents')}
      </Typography.Text>
      {isReqDocuments && (
        <div className={classNames('ant-col ant-form-item-label', styles.reqdocumentsection)}>
          <label htmlFor="documents" className="ant-form-item-required" title="Requested Documents">
            {t('required_doc')} {requestDocuments}
          </label>
        </div>
      )}
      <br />
      {isOptionalDocuments && (
        <div>
          {t('optional_doc')} {optionalDocuments}
        </div>
      )}
      <div className={styles.content}>
        <Upload {...props}>
          <Button
            id="documents"
            type="text"
            size="middle"
            onClick={onAddDocument}
            className={styles.uploadbutton}
          >
            <PlusOutlined className={styles.uploadicon} />
            <div>{t('add')}</div>
          </Button>
        </Upload>
      </div>
    </>
  );
};

export default Documents;

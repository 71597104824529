import { FilterOutlined } from '@ant-design/icons';
import { Button, Tabs, Typography } from 'antd';
import { TitleBar } from 'components';
import { GTableDateType } from 'components/GTable/contexts/typings';
import useTableRowSelection from 'hooks/useTableRowSelection';
import { TabTitle } from 'pages/Dashboard';
import { useEventsStore } from 'pages/Events/hooks';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, createSearchParams, useNavigate } from 'react-router-dom';
import { useListShipMutate } from 'services/api';
import { InboundShipmentResponse } from 'services/api/client/src';
import {
  useCountInboundShipmentsShipments,
  useInboundListShipMutate,
} from 'services/api/newShipments';
import {
  dateLocalFormat,
  dateLocaleFormat,
  downloadJsonToCSV,
  getMaxEventDate,
  getShipmentType,
  hasContainer,
  mapShipInvItem,
} from 'utils';
import { MainActionItemsFn, Shipment } from '../../Shipments/typings';
import { ShipmentProps } from '../hooks/typings';
import useNewShipmentActions from '../hooks/useNewShipmentActions';
import { ShipmentTitleProps } from '../typings';
import InboundExternalSystemShipments from './InboundExternalSystemShipments';
import InboundInternalShipments from './InboundInternalShipments';
import {
  getInboundSettingsShipTabSession,
  getInboundSettingsTabSession,
  setInboundSettingsShipTabSession,
  setInboundSettingsTabSession,
} from './InboundTabSettings';
import styles from './index.module.less';

const mainActionItems: MainActionItemsFn = ({ /* selectedRowKeys, */ t }) => [
  {
    key: 'receive',
    label: t('actions.receive'),
  },
];

export const ShipmentsTitle: FC<ShipmentTitleProps> = ({
  t,
  setProducts,
  selectedRowKeys,
  selectedRows,
  setSelectedShipments,
  showFilter,
  setShowFilter,
  shipmentType,
  isOtherSystem,
}) => {
  const { onShowMixSSCCDialog, onShowErrorExternalShipmentDialog } = useNewShipmentActions();
  const { setAdvancedSettings, setDataEntryMethod, setTemplate } = useEventsStore();
  const navigate = useNavigate();
  const onReceive = useCallback(() => {
    const hasPendingShipments: any[] =
      (selectedRows && selectedRows?.filter((row) => row?.shipmentStatus === 'Error')) || [];
    if (hasPendingShipments.length) {
      onShowErrorExternalShipmentDialog();
    } else {
      const isSameLocation =
        selectedRows?.length &&
        !selectedRows?.find(
          (item) => item?.destinationLocationId !== selectedRows?.[0]?.destinationLocationId,
          [],
        );

      const productsSearchParams = createSearchParams({
        shipmentIds: selectedRowKeys?.join(',') || '1',
        locationUrn: isSameLocation ? selectedRows?.[0]?.destinationLocationUrn || '' : '',
        locationId: isSameLocation ? selectedRows?.[0]?.destinationLocationId || '' : '',
        tradePartnerUrn: isSameLocation ? selectedRows?.[0]?.destinationCompanyUrn || '' : '',
        eventDate: getMaxEventDate(selectedRows),
        shipmentType: shipmentType || '',
        isOtherSystem: String(isOtherSystem || false),
        isSameLocation: String(isSameLocation || false),
      });
      const productId = selectedRowKeys?.length >= 1 ? selectedRowKeys?.join(',') : ['new_receive'];
      navigate({
        pathname: `/products/${productId}/events/receive`,
        search: selectedRowKeys.length ? `?${productsSearchParams}` : undefined,
      });
      setProducts?.(selectedRows as Array<Shipment>);
      setSelectedShipments?.(selectedRows as InboundShipmentResponse[]);
    }
  }, [
    isOtherSystem,
    navigate,
    onShowErrorExternalShipmentDialog,
    selectedRowKeys,
    selectedRows,
    setProducts,
    setSelectedShipments,
    shipmentType,
  ]);

  const onReceiveBtnPress = useCallback(
    (fields?: Array<GTableDateType>) => {
      let selectedFields = fields;
      if (isOtherSystem) {
        selectedFields = selectedFields?.map((field) => ({
          ...field,
          hasContainer: !!field?.containers?.length,
        }));
      }
      if ((fields?.length || 0) > 1) {
        const isContainer = hasContainer(selectedFields);

        if (!isContainer.value) {
          onReceive();
        } else if (isContainer.value && isContainer.type === 'sscc') {
          onShowMixSSCCDialog();
        } else if (isContainer.value && isContainer.type === 'lot') {
          /* onShowMixLotDialog(); */ onShowMixSSCCDialog();
        }
      } else {
        onReceive();
      }
    },
    [isOtherSystem, onReceive, onShowMixSSCCDialog],
  );
  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'receive':
        onReceiveBtnPress(selectedRows);
        break;

      default:
        break;
    }
    setAdvancedSettings(false);
    setDataEntryMethod('manual');
    setTemplate(undefined);
  };
  return (
    <TitleBar
      title={`${t('inbound_shipment')}`}
      extra={
        <>
          <Button type="link" onClick={() => setShowFilter && setShowFilter(!showFilter)}>
            <FilterOutlined />
            {showFilter === true ? t('hide_filter') : t('show_filter')}
          </Button>
          {selectedRowKeys.length > 0 && (
            <Typography.Title className="selectiontext" level={5}>
              {`${selectedRowKeys.length} ${
                selectedRowKeys.length === 1 ? t('item') : t('items')
              } ${t('selected')}`}
            </Typography.Title>
          )}
        </>
      }
      actionItems={mainActionItems({ selectedRowKeys, selectedRows, t })}
      onActionItemClick={onActionItemClick}
    />
  );
};

/**
 * Component to manage the inventory of a product.
 */
const ShipmentInbound: FC<ShipmentProps> = ({
  isNetworkShip,
  inboundShipParam,
  inboundOtherShipParam,
  hideOtherSystemTab,
  considerAsOutbound,
}) => {
  const sessionTab: string = isNetworkShip
    ? getInboundSettingsShipTabSession()
    : getInboundSettingsTabSession();
  const [currentTab, setCurrentTab] = useState(sessionTab);
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  const { onRowSelectionChange, selectedRows, selectedRowKeys } = useTableRowSelection();
  const { setProducts, setSelectedShipments } = useShipmentStore();
  const listShipMutate = useListShipMutate();
  const listExternalShipMutate = useInboundListShipMutate();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { data: dataProductCounts } = useCountInboundShipmentsShipments({
    ...(!isNetworkShip && { status: ['Pending'] }),
    ...inboundShipParam,
  });
  const totalItemsInternal = dataProductCounts?.internal || 0;
  const totalItemsWholechain = dataProductCounts?.wholechain || 0;
  const totalItemsOtherSystems = dataProductCounts?.otherSystems || 0;
  useEffect(() => {
    setCurrentTab(sessionTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChange = (activeKey: string) => {
    if (isNetworkShip) {
      setInboundSettingsShipTabSession(activeKey);
    } else {
      setInboundSettingsTabSession(activeKey);
    }

    setCurrentTab(activeKey);
    onRowSelectionChange([], []);
  };

  const onDownloadCSVMulti = useCallback(async () => {
    if (currentTab === 'wholechain') {
      const data = await listShipMutate.mutateAsync({
        options: {
          direction: 'Inbound',
          sortBy: '-eventDate',
          status: ['Pending', 'Rejected'],
          onlyMyShipments: true,
        },
      });
      const records = data?.results?.map(mapShipInvItem);
      downloadJsonToCSV(records);
    } else {
      const data = await listExternalShipMutate.mutateAsync({
        options: {
          sortBy: '-eventDate',
          status: ['Pending', 'Rejected'],
        },
      });
      const records = data?.results?.map(
        (item) => ({
          ...item,
          loggedDate: dateLocalFormat(new Date(item?.loggedDate || new Date())),
          eventDate: dateLocaleFormat(new Date(item?.eventDate || new Date())),
        }),
        [],
      );
      downloadJsonToCSV(records);
    }
  }, [currentTab, listExternalShipMutate, listShipMutate]);

  const hideOtherSystem = useMemo(
    () => isNetworkShip && hideOtherSystemTab,
    [hideOtherSystemTab, isNetworkShip],
  );

  return (
    <>
      {!isNetworkShip && (
        <div className={styles.titlebarwarp}>
          <ShipmentsTitle
            onRowSelectionChange={onRowSelectionChange}
            onDownloadCSVMultiple={onDownloadCSVMulti}
            selectedRowKeys={selectedRowKeys}
            selectedRows={selectedRows}
            t={t}
            setProducts={setProducts}
            setSelectedShipments={setSelectedShipments}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            shipmentType={getShipmentType(currentTab)}
            isOtherSystem={currentTab === 'other_systems'}
          />
        </div>
      )}

      <Tabs defaultActiveKey="internal" activeKey={currentTab} onChange={onTabChange}>
        <Tabs.TabPane
          tab={
            <TabTitle
              activeTab={currentTab === 'internal'}
              showZero
              title={t('tab.internal')}
              count={totalItemsInternal}
            />
          }
          key="internal"
        >
          <InboundInternalShipments
            onRowSelectionChange={onRowSelectionChange}
            selectedRowKeys={selectedRowKeys}
            showFilter={showFilter}
            inboundShipmentType="Internal"
            isNetworkShip={isNetworkShip}
            inboundShipParam={inboundShipParam}
            considerAsOutbound={considerAsOutbound}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <TabTitle
              activeTab={currentTab === 'wholechain'}
              showZero
              title={t('tab.wholechain')}
              count={totalItemsWholechain}
            />
          }
          key="wholechain"
        >
          <InboundInternalShipments
            onRowSelectionChange={onRowSelectionChange}
            selectedRowKeys={selectedRowKeys}
            showFilter={showFilter}
            inboundShipmentType="Wholechain"
            isNetworkShip={isNetworkShip}
            inboundShipParam={inboundShipParam}
            considerAsOutbound={considerAsOutbound}
          />
        </Tabs.TabPane>
        {!hideOtherSystem && (
          <Tabs.TabPane
            tab={
              <TabTitle
                activeTab={currentTab === 'other_systems'}
                showZero
                title={t('tab.other_systems')}
                count={totalItemsOtherSystems}
              />
            }
            key="other_systems"
          >
            <InboundExternalSystemShipments
              onRowSelectionChange={onRowSelectionChange}
              selectedRowKeys={selectedRowKeys}
              showFilter={showFilter}
              isNetworkShip={isNetworkShip}
              inboundOtherShipParam={inboundOtherShipParam}
            />
          </Tabs.TabPane>
        )}
      </Tabs>

      {/* Outlet used to render the drawers */}
      <Outlet />
    </>
  );
};

export default React.memo(ShipmentInbound);

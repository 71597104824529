export const checkDateFormat = (date: string) => {
  /**
   * rules:
   * 1. date should be a valid date
   * 2. date can be in the format of YYYY-MM-DD or YYYY/MM/DD or MM/DD/YYYY or MM-DD-YYYY or mm/dd/yyyy or mm-dd-yyyy
   * 3. valid dates are 2020-02-29, 2020/02/29,2020/2/29, 02/29/2020, 2/29/2020, 02-29-2020, 02/29/20, 02-29-20
   *
   */
  const isValidDate = new Date(date).toString() !== 'Invalid Date';

  if (isValidDate) {
    return date;
  }
  return undefined;
};

export const checkTimeFormat = (time: string) => {
  /**
   * rules:
   * 1. time should be a valid time
   * 2. time can be in the format of HH:mm or HH:mm:ss or hh:mm or hh:mm:ss
   */
  const timeRegex = /^(?:[0-1]?\d|2[0-3]):[0-5]\d:[0-5]\d$/;
  if (timeRegex.test(time) || timeRegex.test(time?.split(' ')[0])) {
    const updatedTime = time?.split(' ')[0]?.split(':');
    if (/^\d$/.test(updatedTime[0])) {
      updatedTime[0] = `0${updatedTime[0]}`;
    }
    return updatedTime.join(':');
  }
  return undefined;
};

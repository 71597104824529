import { create } from 'zustand';
import { ShareTemplateStore } from './typings';

const useShareTemplateStore = create<ShareTemplateStore>((set) => ({
  template: undefined,
  templates: undefined,
  tradePartners: [],
  etradePartners: [],

  setTradePartners: (tradePartners) => set({ tradePartners }),
  setEtradePartners: (etradePartners) => set({ etradePartners }),
  setTemplate: (template) => set({ template }),
  setTemplates: (templates) => set({ templates }),
}));

export default useShareTemplateStore;

import { create } from 'zustand';
import { SelectTabStore } from './typings';

const useTabStore = create<SelectTabStore>((set) => ({
  tab: 'document',
  setTab: (tab: string) =>
    set(() => ({
      tab,
    })),
}));

export default useTabStore;

import type { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps, Input } from 'antd';
import { TradePartnerSelect } from 'components/GSelect';
import DocumentSelect from 'pages/Network/forms/ShareDocument/DocumentSelect';
import { TFunction } from 'react-i18next';
import { DocumentResponse } from 'services/api/client/src';
import { SelectedProps } from './typings';

const { TextArea } = Input;
const colProps18: ColProps = { xs: 24, sm: 16, md: 16, lg: 18 };
const colProps6: ColProps = { xs: 24, sm: 6, md: 6, lg: 6 };

export type DataItem = {
  message: string;
  tradePartners?: Array<string>;
  permission?: string;
};

type ColumnsType = {
  t: TFunction<'pages', 'network.share_document'>;
  documentCount: number;
  documents: Array<DocumentResponse>;
  setDocuments: (props: Array<DocumentResponse>) => void;
  newDoc: SelectedProps;
  setPartners?: (tradePartners: Array<string>) => void;
  partners?: Array<string>;
};

export const ShareDocumentColumns = ({
  t,
  documentCount,
  documents,
  setDocuments,
  newDoc,
  partners,
  setPartners,
}: ColumnsType): ProFormColumnsType<DataItem>[] => [
  {
    title: !partners?.length || !documentCount ? t('trade_partner_select') : '',
    dataIndex: 'tradePartners',
    initialValue: [],
    renderFormItem: (row, config, form) => (
      <TradePartnerSelect
        initParams={{ types: ['Wholechain'] }}
        onChange={(value, option) => {
          const wcIds = option?.map((item: any) => item?.itemProps?.wholechainId);
          setPartners?.(wcIds || []);
          form?.setFieldsValue({ tradePartners: value });
        }}
      />
    ),
    fieldProps: {
      mode: 'multiple',
      placeholder: t('company_placeholder'),
    },
    colProps: !partners?.length || !documentCount ? undefined : colProps18,
  },
  {
    title: !partners?.length || !documentCount ? '        ' : '',
    dataIndex: 'permission',
    valueType: 'select',
    initialValue: 'Viewer',
    fieldProps: {
      allowClear: false,
    },
    valueEnum: {
      Viewer: {
        text: 'Viewer',
        status: 'Default',
      },
    },
    hideInForm: !partners?.length || !documentCount,
    colProps: colProps6,
  },
  {
    title: !partners?.length || !documentCount ? t('field') : '',
    dataIndex: 'attribute',
    valueType: 'select',
    renderFormItem: () => (
      <DocumentSelect documents={documents} setDocuments={setDocuments} newDoc={newDoc} />
    ),
  },
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['attribute'],
    },
    columns: () => [
      {
        dataIndex: 'message',
        valueType: 'text',
        renderFormItem: () =>
          !partners?.length || !documentCount ? null : (
            <TextArea rows={7} placeholder={t('value')} />
          ),
        fieldProps: {
          placeholder: t('value'),
        },
      },
    ],
  },
];

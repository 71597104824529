import { QueryClient, useMutation, useQueries, useQuery } from 'react-query';
import {
  CreateDocument$multipartOptionalParams,
  GetPaginatedDocumentsOptionalParams,
  ShareDocumentOptionalParams,
  ShareDocumentRequest,
  UnshareDocumentOptionalParams,
  UnshareDocumentResponse,
  UpdateDocumentRequest,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';
import { TagItem } from './typings';

export const createDocument = async (data: CreateDocument$multipartOptionalParams) =>
  HttpClient.createDocument('multipart/form-data', data);
export const updateDocument = async (id: string, body: UpdateDocumentRequest) =>
  HttpClient.updateDocument(id, { body });
export const DeleteDocument = async (id: string) => HttpClient.deleteDocumentById(id);

export const createShareDocument = (params?: ShareDocumentOptionalParams) =>
  HttpClient.shareDocument(params);

export const useCreateDocument = (queryClient: QueryClient) =>
  useMutation((document: CreateDocument$multipartOptionalParams) => createDocument(document), {
    onSuccess: () => {
      queryClient.invalidateQueries('documents');
    },
  });
export const useDeleteDocument = (queryClient: QueryClient) =>
  useMutation(({ id }: { id: string }) => DeleteDocument(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('documents');
    },
  });
export const useUpdateDocument = (queryClient: QueryClient) =>
  useMutation(
    ({ id, document }: { id: string; document: UpdateDocumentRequest }) =>
      updateDocument(id, document),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('documents');
        queryClient.invalidateQueries('document');
        queryClient.invalidateQueries('documentId');
      },
    },
  );

const getDocuments = async (params?: GetPaginatedDocumentsOptionalParams) =>
  HttpClient.getPaginatedDocuments(params);

const getDocumentTagsCombine = async () => {
  const [products, locations, tradePartners] = await Promise.all([
    // TODO : Review these calls, maybe switch to a list tags?
    HttpClient.getPaginatedProducts(),
    HttpClient.listLocations(),
    HttpClient.listTradePartners(),
  ]);
  const data: Array<TagItem> = [];
  const productData = products.results;
  productData?.forEach((product) => {
    const productObj = {
      label: product.name,
      value: product.id,
      type: 'Product',
      item: product,
    };
    data.push(productObj);
  });
  locations?.data?.forEach((location) => {
    const locationObj = {
      label: `${location?.name || ''} ${location?.tradePartnerId ? '-' : ''} ${
        location?.tradePartnerId || ''
      }`,
      value: location.id,
      type: 'Location',
      item: location,
    };
    data.push(locationObj);
  });
  tradePartners?.data?.forEach((tradePartner) => {
    const tradePartenerObj = {
      label: tradePartner.name || '',
      value: tradePartner.id,
      type: 'Company',
      item: tradePartner,
    };
    data.push(tradePartenerObj);
  });

  return data;
};

export const useDocuments = (params?: GetPaginatedDocumentsOptionalParams) =>
  useQuery(['documents', params], () => getDocuments(params));

/* create share document */
export const useCreateShareDocument = (queryClient: QueryClient) =>
  useMutation((body?: ShareDocumentRequest) => createShareDocument({ body }), {
    onSuccess: () => {
      queryClient.invalidateQueries('documents');
    },
  });

export const useDocumentTagsCombine = () =>
  useQuery(['documentTagsCombine'], () => getDocumentTagsCombine());

const unshareDocument = (
  documentId: string,
  options?: UnshareDocumentOptionalParams,
): Promise<UnshareDocumentResponse> =>
  HttpClient.unshareDocument({ body: { documentId, ...options?.body }, ...options });

/* create share document */
export const useUnShareDocument = (queryClient: QueryClient) =>
  useMutation(
    ({ documentId, options }: { documentId: string; options: UnshareDocumentOptionalParams }) =>
      unshareDocument(documentId, options),
    {
      onSuccess: () => {
        queryClient.refetchQueries('documents');
      },
    },
  );

const getdocumentById = (id: string) => HttpClient.getDocumentById(id);
export const downloadDocumentById = async (id: string) => {
  const res = HttpClient.downloadDocument(id);
  const data = await (await res).blobBody;
  if (data) {
    const uri = URL.createObjectURL(data);
    return uri;
  }
  return undefined;
};

const getDocumentByIdWithDownload = async (id: string) => {
  const [document, attachment] = await Promise.all([getdocumentById(id), downloadDocumentById(id)]);
  return { document, attachment };
};

export const useDownloadDocument = (id: string) =>
  useQuery(['documentAttachment', id], () => downloadDocumentById(id), {
    // disable refetch on window focus and switch tab
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

export const useDocumentById = (id: string) =>
  useQuery(['documentId', id], () => getdocumentById(id));

export const useDocumentByIdWithDownload = (id: string) =>
  useQuery(['document', id], () => getDocumentByIdWithDownload(id));

export const useDocumentsByIdDownload = (id: string) =>
  useQueries([
    {
      queryKey: ['document', id],
      queryFn: () => getdocumentById(id),
    },
    {
      queryKey: ['documentAttachment', id],
      queryFn: () => downloadDocumentById(id),
    },
  ]);

export const getDocumentsByFilter = async (params?: GetPaginatedDocumentsOptionalParams) => {
  const response = HttpClient.getPaginatedDocuments(params);
  const data = (await response).results;
  if (data && data.length > 0) {
    return data;
  }
  return [];
};

export default useDocuments;

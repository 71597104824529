import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { Button, Menu, Typography } from 'antd';
import { SupportModal } from 'components';
import { useModalVisibility } from 'hooks';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountSubscription } from 'services/api';
import { featureFlag, legacySystemURL, supportEmailLink } from 'utils';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.module.less';

const SourceSvg = () => (
  <svg width="20" height="20" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="7" r="2.125" fill="black" stroke="black" strokeWidth="0.25" />
    <circle cx="29" cy="7" r="2.125" fill="black" stroke="black" strokeWidth="0.25" />
    <circle cx="29" cy="18" r="2.125" fill="black" stroke="black" strokeWidth="0.25" />
    <circle cx="29" cy="29" r="2.125" fill="black" stroke="black" strokeWidth="0.25" />
    <circle cx="18" cy="18" r="2.125" fill="black" stroke="black" strokeWidth="0.25" />
    <circle cx="18" cy="29" r="2.125" fill="black" stroke="black" strokeWidth="0.25" />
    <circle cx="7" cy="18" r="2.125" fill="black" stroke="black" strokeWidth="0.25" />
    <circle cx="7" cy="29" r="2.125" fill="black" stroke="black" strokeWidth="0.25" />
    <circle cx="7" cy="7" r="2.125" fill="black" stroke="black" strokeWidth="0.25" />
  </svg>
);
const SourceIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SourceSvg} {...props} />
);

const SourceDropdown: FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard' });
  const { data: account } = useAccountSubscription();
  const subscription = useMemo(() => account?.subscriptionDetails || {}, [account]);
  // const subscription = mockSubscription;
  const supportModal = useModalVisibility(false);
  const onMenuClick: MenuClickEventHandler = useCallback(
    ({ key }) => {
      switch (key) {
        case 'sourceview':
          if (featureFlag?.planCheck ? subscription?.sourceviewAddon : true) {
            window.open(legacySystemURL);
          } else {
            supportModal.show();
          }
          break;
        default:
          break;
      }
    },
    [subscription, supportModal],
  );

  const menuHeaderDropdown = (
    <Menu className={styles.menu} onClick={onMenuClick}>
      <Menu.Item key="wholechain" className={styles.profile}>
        <div>
          <Typography.Text>{t('menu.wholechain')}</Typography.Text>
        </div>
        <div>
          <Typography.Text type="secondary">{t('menu.tracebility')}</Typography.Text>
        </div>
      </Menu.Item>
      <Menu.Item key="sourceview">
        <div>
          <Typography.Text>{t('menu.sourceview')}</Typography.Text>
        </div>
        <div>
          <Typography.Text type="secondary">{t('menu.consumer_touchpoint')}</Typography.Text>
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <HeaderDropdown overlay={menuHeaderDropdown}>
        <span className={`${styles.action} ${styles.account}`}>
          <Button type="text" icon={<SourceIcon />} />
        </span>
      </HeaderDropdown>
      <SupportModal
        title={t('upgrade_modal_title')}
        modal={supportModal}
        description={
          <div>
            {t('upgrade_modal_description')}&nbsp;
            <a href={supportEmailLink} type="mail">
              {t('support_anchor_email_title')}
            </a>
            &nbsp;
            {t('upgrade_modal_desc2')}
          </div>
        }
      />
    </>
  );
};

export default SourceDropdown;

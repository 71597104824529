import { UserItem } from 'components';
import { QueryClient, useMutation, useQuery } from 'react-query';
import {
  ChangeEmailRequest,
  ChangePersonalInfoRequest,
  ChangeRoleRequest,
  ChangeSettingsRequest,
  DeleteUserInviteFromAccountOptionalParams,
  InviteUsersToAccountRequest,
  ListParticipantsOptionalParams,
  UploadProfilePicture$multipartOptionalParams,
} from 'services/api/client/src';
import { HttpClient } from 'services/utils/security';

/* get list of users */
const fetchUsers = async (params?: ListParticipantsOptionalParams) =>
  HttpClient.listParticipants(params);

/* get user by id  */
export const getUserById = async (id?: string) => (id ? HttpClient.getUserAccount() : undefined);

/* delete user by id IN PROGRESS */
const deleteUserById = () => {};
/* update user role by id */
export const updateUserRoleById = async (id: string, body: ChangeRoleRequest) =>
  HttpClient.changeRole(id, { body });

export const changeSettings = async (body: ChangeSettingsRequest) =>
  HttpClient.changeSettings({ body });

export const changePersonalInfo = async (body: ChangePersonalInfoRequest) =>
  HttpClient.changePersonalInfo({ body });

export const changeEmail = async (body: ChangeEmailRequest) => HttpClient.changeEmail({ body });

const uploadProfilePicture = async (body: UploadProfilePicture$multipartOptionalParams) =>
  HttpClient.uploadProfilePicture('multipart/form-data', body);

/* create invite */
const createInvite = async (body: InviteUsersToAccountRequest) =>
  HttpClient.inviteUsersToAccount({ body });

/* redeem invite IN PROGRESS */
const redeemInvite = async (id: string) => HttpClient.redeemInvite(id);

/* delete invite */
const deleteInvite = async (id: string, options?: DeleteUserInviteFromAccountOptionalParams) =>
  HttpClient.deleteUserInviteFromAccount(id, options);

/*  invite by id IN PROGRESS */
const validateInvite = async (id: string) => HttpClient.isValidInvite(id);

const bulkUpdateDelete = (users?: Array<UserItem>) => {
  const removedUsers = users?.filter((user) => user.isDeleted);
  const updatedUsers = users?.filter((user) => user.isUpdated && !user.isDeleted);
  const removePromises = removedUsers?.map((user) => {
    if (user?.id) {
      return deleteUserById();
    }
    return deleteInvite(user?.inviteId || '');
  });
  const updatePromises = updatedUsers?.map((user) =>
    updateUserRoleById(user?.id || '', {
      role: user.role,
    }),
  );
  return Promise.all([...(removePromises || []), ...(updatePromises || [])]);
};

export const useUserById = (id: string) => useQuery(['user', id], () => getUserById(id));

/* update user role by id */
export const useUpdateUserRoleById = (queryClient: QueryClient) =>
  useMutation(
    ({ id, body }: { id: string; body: ChangeRoleRequest }) => updateUserRoleById(id, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );

export const useChangeSettings = (queryClient: QueryClient) =>
  useMutation(({ body }: { body: ChangeSettingsRequest }) => changeSettings(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
    },
  });

export const useChangePersonalInfo = (queryClient: QueryClient) =>
  useMutation(({ body }: { body: ChangePersonalInfoRequest }) => changePersonalInfo(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries('user');
    },
  });
export const useChangeEmail = (queryClient: QueryClient) =>
  useMutation(({ body }: { body: ChangeEmailRequest }) => changeEmail(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

/* update create invite */
export const useCreateInvite = (queryClient: QueryClient) =>
  useMutation((body: InviteUsersToAccountRequest) => createInvite(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

// /* update create invite */
export const useValidateInvite = (id: string) =>
  useQuery(['validateInvite', id], () => validateInvite(id), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

/* use redeem invite */
export const useRedeemInvite = (queryClient: QueryClient) =>
  useMutation((id: string) => redeemInvite(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

/* use delete invite */
export const useDeleteInvite = (queryClient: QueryClient) =>
  useMutation((id: string) => deleteInvite(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });
/* use bulk  update delete */
export const useBulkUpdateDelete = (queryClient: QueryClient) =>
  useMutation((users?: Array<UserItem>) => bulkUpdateDelete(users), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
    },
  });

/* use UploadProfilePicture */
export const useUploadProfilePicture = (queryClient: QueryClient) =>
  useMutation((body: UploadProfilePicture$multipartOptionalParams) => uploadProfilePicture(body), {
    onSuccess: (data) => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries(['notifications']);
      queryClient.invalidateQueries(['user', data?.id]);
    },
  });

/* use user */
const useUsers = (params?: ListParticipantsOptionalParams) =>
  useQuery(['users', params], () => fetchUsers(params));

export default useUsers;

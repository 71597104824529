import { BetaSchemaForm } from '@ant-design/pro-form';
import { Form, Modal, message } from 'antd';
import { useDrawerVisibility } from 'hooks';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { CreateAttributeRequest, CreateEventTemplateRequest } from 'services/api/client/src';
import { useCreateTemplate } from 'services/api/useTemplates';
import {
  documentTypes,
  errorHandler,
  getEventsT,
  hasUnsavedChanges,
  mapAdvanceSettings,
  mapCertInfoRequest,
  mapCustomAtrribute,
} from 'utils';
import { DataItem } from '../typings';
import AddTemplateFormFields from './AddTemplateForm.fields';
import DrawerFormHeader from './DrawerFormHeader';

const rowProps = { gutter: 32 };

const AddTemplateForm = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates.add_template' });
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const [mainForm] = Form.useForm();
  const [headerForm] = Form.useForm(mainForm);
  const queryClient = useQueryClient();
  const createTemplate = useCreateTemplate(queryClient);

  /* on close modal */
  const onClose = useCallback(() => {
    const hasFilled = mainForm.isFieldsTouched();

    if (hasFilled) {
      Modal.warning({
        title: t?.('unsaved_changes_title'),
        content: t?.('unsaved_changes_desc'),
        okText: t?.('unsaved_changes_submit'),
        cancelText: t?.('unsaved_changes_cancel'),
        onOk: closeDrawer,
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      });
    } else {
      closeDrawer();
    }
  }, [closeDrawer, mainForm, t]);

  const onFinish = async (formData: DataItem) => {
    try {
      if (!formData?.customAttributes?.length) {
        throw new Error(t('form_fields.custom_template_required'));
      }
      hasUnsavedChanges();

      const customAttributes = formData?.customAttributes?.map(mapCustomAtrribute) || [];
      const advancedSettings = formData?.advanceSettings?.map(mapAdvanceSettings) || [];
      const vesselAttributes = formData?.vesselCatchFields?.map(mapCustomAtrribute) || [];

      const templateFields = [
        ...customAttributes,
        ...advancedSettings,
        ...vesselAttributes,
      ] as CreateAttributeRequest[];

      const reqBody: CreateEventTemplateRequest = {
        name: formData.name,
        templateFields,
        templateDocuments:
          formData?.documents?.map((doc) => ({
            documentName: doc?.name || documentTypes(doc?.type || 'other').name,
            required: doc.required,
            documentType: documentTypes(doc?.type || 'other').dataIndex,
          })) || [],
        allowVesselCatch: formData?.additionalOptions?.includes('vesselCatch') || false,
        allowCertificationDocuments:
          formData?.additionalOptions?.includes('certificationDocuments') || false,
        templateCertifications: formData?.certificationFields?.map(mapCertInfoRequest) || [],
      };
      const res = await createTemplate.mutateAsync(reqBody);
      message.success(t('success_message', { id: formData.name || res?.id }));
      closeDrawer();
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };
  const onSubmit = async () => {
    try {
      await mainForm?.validateFields();
      mainForm?.submit();
    } catch (error) {
      message.error(getEventsT('events_field_req'));
    }
  };
  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={AddTemplateFormFields({ t, isEdit: true })}
      grid
      // @ts-ignore
      title={<DrawerFormHeader title={t('title')} form={headerForm} />}
      visible={visible}
      rowProps={rowProps}
      autoFocusFirstInput
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        ...restDrawerVisibility,
        onClose,
      }}
      submitter={{
        searchConfig: {
          submitText: t('submit_btn'),
          resetText: t('cancel_btn'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
          onClick: onClose,
        },
        onSubmit,
      }}
      submitTimeout={2000}
      onClose={onClose}
      onFinish={onFinish}
    />
  );
};

export default React.memo(AddTemplateForm);

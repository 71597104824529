import type { ActionType } from '@ant-design/pro-table';
import {
  createContext,
  Key,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GTableContextProviderProps, GTableContextValue, GTableDateType } from './typings';

const GTableProviderContextDefault: GTableContextValue<GTableDateType> = {
  actionRef: { current: null },
  selectedRowKeys: [],
  onRowSelectionChange: () => {},
};

const GTableProviderContext = createContext<GTableContextValue<GTableDateType>>(
  GTableProviderContextDefault,
);

export const GTableProvider = ({ children, actionRef }: GTableContextProviderProps) => {
  const internalActionRef = useRef<ActionType>();
  const [selectedRowKeys, setSelectedKeys] = useState<Array<Key>>([]);

  useImperativeHandle(actionRef, () => internalActionRef.current);

  const onRowSelectionChange = useCallback((selectedKeys: Array<Key>) => {
    setSelectedKeys(selectedKeys);
  }, []);

  const contextValue: GTableContextValue<GTableDateType> = useMemo(
    () => ({
      actionRef: internalActionRef,
      selectedRowKeys,
      onRowSelectionChange,
    }),
    [internalActionRef, selectedRowKeys, onRowSelectionChange],
  );

  return (
    <GTableProviderContext.Provider value={contextValue}>{children}</GTableProviderContext.Provider>
  );
};

export const useGTableContext = (): GTableContextValue<GTableDateType> => {
  const context = useContext(GTableProviderContext);

  if (context === undefined) {
    throw new Error('useGTableContext must be used within a GTableProvider');
  }

  return context;
};

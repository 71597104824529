import React from 'react';
import { BetaSchemaForm } from '@ant-design/pro-form';
import { Form, message, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDrawerVisibility } from 'hooks';
import { Actions } from 'components';
import { useTranslation } from 'react-i18next';
import { DataItem } from '../../typings';
import ViewDocumentTemplateFormField from './ViewDocumentTemplateForm.fields';
import ViewDocumentTemplateDrawerHeader from './ViewDocumentTemplateDrawerHeader';

const rowProps = { gutter: 32 };

interface LocationState {
  state: {
    templateName: string;
  };
}

const actionItems: Array<ItemType> = [
  {
    label: 'Edit',
    key: 'edit',
  },
  {
    label: 'View Preview',
    key: 'viewPreview',
  },
  {
    label: 'Duplicate',
    key: 'duplicate',
  },
  {
    label: 'Delete',
    key: 'delete',
  },
];

const ViewDocumentTemplateForm: React.FC = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates.view_template' });
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const [mainForm] = Form.useForm();
  const [headerForm] = Form.useForm(mainForm);
  const navigate = useNavigate();

  const location = useLocation() as LocationState;
  const { templateName } = location.state;
  const onFinish = async () => {
    message.success(t('success_message', { id: Date.now() }));
    closeDrawer();
  };

  const onRowActionClick = (actionKey: string) => {
    switch (actionKey) {
      case 'viewPreview':
        navigate('preview');
        break;

      default:
        break;
    }
  };
  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={ViewDocumentTemplateFormField()}
      grid
      // @ts-ignore
      title={
        <ViewDocumentTemplateDrawerHeader
          title={t('document_template.title')}
          form={headerForm}
          readonly
          templateName={templateName}
        />
      }
      visible={visible}
      rowProps={rowProps}
      autoFocusFirstInput
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        ...restDrawerVisibility,
      }}
      submitter={{
        searchConfig: {
          submitText: 'Save',
        },
        submitButtonProps: {
          shape: 'round',
          size: 'large',
        },
        render: ({ form }, defaultDoms) => (
          <Space>
            <Actions
              className="actions"
              buttonProps={{
                ghost: true,
                size: 'large',
              }}
              items={actionItems}
              onClick={onRowActionClick}
              actionPayload={form}
            />
            {defaultDoms[1]}
          </Space>
        ),
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default ViewDocumentTemplateForm;

// @ts-nocheck
import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { RowProps } from 'antd';
import {
  BizStepSelect,
  DispositionSelect,
  LocationSelect,
  TemplateSelect,
  TimezoneSelect,
  TradePartnerSelect,
} from 'components/GSelect';
import MultipleDatesFormItem from 'components/MultipleDatesFormItem';
import { EventTemplate, Location, TradePartner } from 'services/api/client/src';
import {
  getBizStepNameByIdentifier,
  getDisabledDate,
  getDisabledTimeDependency,
  getDispositionNameByIdentifier,
  getLocalMaxEventDate,
  getMaxEventTime,
  onChangeFieldDate,
} from 'utils';
import BatchShipProducts from '../Tables/BatchShipProducts';
import DataEntryMethod from './DataEntryMethod';
import DataTemplate from './DataTemplate';
import Documents from './Documents';
import ShipmentDetailsHeader from './ShipmentDetailsHeader';
import { BacthShipFormFieldsProps, DataItem } from './typings';

const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0 };
const colProps = { xs: 24, sm: 12, md: 12, lg: 6 };
const colProps2Cols = { xs: 24, sm: 12, md: 12, lg: 12 };
const colProps2ColsMd = { xs: 24, sm: 24, md: 24, lg: 12 };
const colProps1row2Cols = { xs: 24, sm: 24, md: 12, lg: 12 };

const rowProps: RowProps = { gutter: [24, 24] };

const BacthShipFormFields = ({
  hasAdvancedSettings,
  dataEntryMethod,
  isViewTicket = false,
  editableTableFormRef,
  defaultTimezone,
  t,
}: BacthShipFormFieldsProps): ProFormColumnsType<DataItem>[] => {
  const isManualEntry = dataEntryMethod === 'manual';

  return [
    {
      valueType: 'group',
      title: t?.('form_fields.ship_from_grp_title'),
      rowProps,
      colProps: colProps1row2Cols,
      columns: [
        {
          title: t?.('form_fields.ship_from_company_title'),
          dataIndex: 'shipFromCompany',
          colProps: colProps2ColsMd,
          initialValue: 'Beaver Street Fisheries',
          formItemProps: {
            rules: [
              {
                required: !isViewTicket,
                message: t?.('form_fields.ship_from_company_req'),
              },
            ],
            className: isViewTicket ? 'readformlabel' : undefined,
          },
          fieldProps: {
            placeholder: t?.('form_fields.ship_from_company_title'),
            disabled: true,
          },
          readonly: isViewTicket,
          renderFormItem: (row, config, form) => (
            <TradePartnerSelect
              onChange={(value, option) => {
                // @ts-ignore
                const item: TradePartner = option?.itemProps;
                form?.setFieldsValue({
                  shipFromCompany: value,
                  shipFromCompanyName: item?.name || '',
                });
              }}
            />
          ),
        },
        {
          valueType: 'dependency',
          fieldProps: {
            name: ['shipFromCompany'],
          },
          columns: ({ shipFromCompany }: DataItem) => [
            {
              title: t?.('form_fields.ship_from_address_title'),
              dataIndex: 'shipFromAddress',
              colProps: colProps2ColsMd,
              formItemProps: {
                rules: [
                  {
                    required: !isViewTicket,
                    message: t?.('form_fields.ship_from_address_req'),
                  },
                ],
                className: isViewTicket ? 'readformlabel' : undefined,
              },
              fieldProps: {
                placeholder: t?.('form_fields.ship_from_address_title'),
              },
              readonly: isViewTicket,
              renderFormItem: (row, config, form) => (
                <LocationSelect
                  partnerId={shipFromCompany}
                  onChange={(value, option) => {
                    // @ts-ignore
                    const item: Location = option?.itemProps;
                    form?.setFieldsValue({
                      shipFromAddress: value,
                      shipFromAddressName: item?.name,
                      shipToAddress: undefined,
                    });
                  }}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      valueType: 'group',
      title: t?.('form_fields.ship_to_grp_title'),
      rowProps,
      colProps: colProps1row2Cols,
      columns: [
        {
          title: t?.('form_fields.ship_to_company_title'),
          dataIndex: 'shipToCompany',
          colProps: colProps2ColsMd,
          formItemProps: {
            rules: [
              {
                required: !isViewTicket,
                message: t?.('form_fields.ship_to_company_req'),
              },
            ],
            className: isViewTicket ? 'readformlabel' : undefined,
          },
          fieldProps: {
            placeholder: t?.('form_fields.ship_to_company_title'),
          },
          readonly: isViewTicket,
          renderFormItem: (row, config, form) => (
            <TradePartnerSelect
              onChange={(value, option) => {
                // @ts-ignore
                const item: TradePartner = option?.itemProps;
                form?.setFieldsValue({
                  shipToCompany: value,
                  shipToAddress: undefined,
                  shipToCompanyName: item?.name || '',
                });
              }}
            />
          ),
        },
        {
          valueType: 'dependency',
          fieldProps: {
            name: ['shipToCompany', 'shipFromAddress'],
          },
          columns: ({ shipToCompany, shipFromAddress }: DataItem) => [
            {
              title: t?.('form_fields.ship_to_address_title'),
              dataIndex: 'shipToAddress',
              colProps: colProps2ColsMd,
              formItemProps: {
                rules: [
                  {
                    required: !isViewTicket,
                    message: t?.('form_fields.ship_to_address_req'),
                  },
                ],
                className: isViewTicket ? 'readformlabel' : undefined,
              },
              fieldProps: {
                placeholder: t?.('form_fields.ship_to_address_title'),
              },
              readonly: isViewTicket,
              renderFormItem: (row, config, form) => (
                <LocationSelect
                  partnerId={shipToCompany}
                  filterItem={(location) => location?.id !== shipFromAddress}
                  onChange={(value, option) => {
                    // @ts-ignore
                    const item: Location = option?.itemProps;
                    form?.setFieldsValue({
                      shipToAddress: value,
                      shipToAddressName: item?.name,
                    });
                  }}
                />
              ),
            },
          ],
        },
      ],
    },
    {
      valueType: 'divider',
      formItemProps: {
        className: !isViewTicket ? 'no-margin-divider' : 'no-margint-divider',
      },
      fieldProps: {
        className: !isViewTicket ? 'no-margin-divider' : 'no-margint-divider',
      },
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['date', 'status', 'customData'],
      },
      columns: ({ date, status, customData }: DataItem) => [
        {
          renderFormItem: () => (
            <ShipmentDetailsHeader
              t={t}
              isViewTicket={isViewTicket}
              date={date}
              status={status}
              customData={customData}
            />
          ),
          formItemProps: {
            className: !isViewTicket ? 'no-margin-divider' : undefined,
          },
          fieldProps: {
            className: !isViewTicket ? 'no-margin-divider' : undefined,
          },
        },
      ],
    },
    {
      dataIndex: 'isMultipleDates',
      valueType: 'switch',
      initialValue: false,
      hideInForm: isViewTicket || !isManualEntry,
      renderFormItem: (_schema, _config, form) => <MultipleDatesFormItem form={form} />,
      colProps: {
        xs: 24,
        md: 24,
      },
      formItemProps: {
        className: 'no-margin-divider',
      },
      fieldProps: {
        className: 'no-margin-divider',
      },
    },
    {
      title: t?.('form_fields.custom_data_title'),
      dataIndex: 'customData',
      hideInForm: isViewTicket,
      formItemProps: {
        rules: [
          {
            required: false,
            message: t?.('form_fields.custom_data_req'),
          },
        ],
        className: isViewTicket ? 'readformlabel' : undefined,
      },
      fieldProps: {
        placeholder: t?.('form_fields.custom_data_title'),
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t?.('form_fields.custom_data_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      renderFormItem: (row, config, form) => (
        <TemplateSelect
          onChange={(value, option) => {
            // @ts-ignore
            const item: EventTemplate = option?.itemProps;
            form?.setFieldsValue({
              template: item,
              customData: value,
            });
          }}
        />
      ),
      colProps,
    },

    {
      valueType: 'dependency',
      fieldProps: {
        name: ['isMultipleDates', 'customData', 'batchShipProducts'],
      },
      columns: ({ isMultipleDates, customData, batchShipProducts }: DataItem) => [
        {
          title: t?.('form_fields.data_entry_title'),
          dataIndex: 'dataEntryMethod',
          hideInForm: isViewTicket,
          renderFormItem: () => <DataEntryMethod disabled={!!customData} />,
          colProps,
        },
        {
          valueType: 'group',
          dataIndex: 'dateTime',
          hideInForm: !isManualEntry || !!customData,
          rowProps,
          columns: [
            {
              title: t?.('form_fields.date_title'),
              valueType: 'date',
              dataIndex: 'date',
              hideInForm: isMultipleDates || isViewTicket,
              readonly: isViewTicket,
              fieldProps: (form) => ({
                disabledDate: getDisabledDate(getLocalMaxEventDate(batchShipProducts)),
                onChange: (val: string) =>
                  onChangeFieldDate(form, val, getLocalMaxEventDate(batchShipProducts)),
                placeholder: t?.('form_fields.date_title'),
                className: 'full-width',
              }),
              formItemProps: {
                rules: [
                  {
                    required: !isViewTicket,
                    message: t?.('form_fields.date_req'),
                  },
                ],
                className: isViewTicket ? 'readformlabel' : undefined,
              },
              colProps: colProps2Cols,
            },
            {
              title: t?.('form_fields.time_title'),
              valueType: 'time',
              dataIndex: 'time',
              initialValue: '12:00:00',
              className: 'timecol',
              readonly: isViewTicket,
              hideInForm: isMultipleDates || isViewTicket,
              fieldProps: {
                placeholder: t?.('form_fields.time_title'),
                className: 'full-width',
                format: 'HH:mm:ss',
                disabledTime: getDisabledTimeDependency(getMaxEventTime(batchShipProducts)),
                showNow: false,
              },
              formItemProps: {
                rules: [
                  {
                    required: !isViewTicket,
                    message: t?.('form_fields.time_req'),
                  },
                ],
                className: isViewTicket ? 'readformlabel' : undefined,
              },
              colProps: colProps2Cols,
            },
          ],
          colProps: colProps2ColsMd,
        },
      ],
    },

    {
      valueType: 'group',
      rowProps,
      columns: [
        {
          valueType: 'dependency',
          fieldProps: {
            name: ['customData'],
          },
          columns: ({ customData }: DataItem) => [
            {
              title: t?.('form_fields.timezone_title'),
              dataIndex: 'timeZone',
              valueType: 'select',
              initialValue: defaultTimezone,
              readonly: isViewTicket,
              renderFormItem: () => <TimezoneSelect />,
              formItemProps: {
                rules: [
                  {
                    required: !isViewTicket,
                    message: t?.('form_fields.timezone_req'),
                  },
                ],
                className: isViewTicket ? 'readformlabel' : undefined,
              },
              colProps:
                !isManualEntry || !hasAdvancedSettings || !!customData ? colPropsNull : colProps,
            },
            {
              title: t?.('form_fields.bizstep_title'),
              dataIndex: 'bizStep',
              initialValue: 'urn:epcglobal:cbv:bizstep:shipping',
              valueType: 'select',
              readonly: isViewTicket,
              formItemProps: {
                rules: [
                  {
                    required: !isViewTicket,
                    message: t?.('form_fields.bizstep_req'),
                  },
                ],
                className: isViewTicket ? 'readformlabel' : undefined,
              },
              tooltip: {
                icon: <InfoCircleOutlined />,
                title: t?.('form_fields.bizstep_tooltip'),
                key: null,
                type: '',
                props: null,
              },
              renderFormItem: () => <BizStepSelect />,
              render: (text, record) => (
                <span>{getBizStepNameByIdentifier(String(record?.value || ''))}</span>
              ),
              colProps:
                !isManualEntry || !hasAdvancedSettings || !!customData ? colPropsNull : colProps,
            },
            {
              title: t?.('form_fields.disposition_title'),
              dataIndex: 'disposition',
              initialValue: 'urn:epcglobal:cbv:disp:active',
              valueType: 'select',
              readonly: isViewTicket,

              formItemProps: {
                rules: [
                  {
                    required: !isViewTicket,
                    message: t?.('form_fields.disposition_req'),
                  },
                ],
                className: isViewTicket ? 'readformlabel' : undefined,
              },
              tooltip: {
                icon: <InfoCircleOutlined />,
                title: t?.('form_fields.disposition_tooltip'),
                key: null,
                type: '',
                props: null,
              },
              renderFormItem: () => <DispositionSelect />,
              render: (text, record) => (
                <span>{getDispositionNameByIdentifier(String(record?.value || ''))}</span>
              ),
              colProps:
                !isManualEntry || !hasAdvancedSettings || !!customData ? colPropsNull : colProps,
            },
            {
              title: t?.('form_fields.blockchain_title'),
              dataIndex: 'blockchain',
              hideInForm: !isViewTicket,
              initialValue: 'Algorand',
              valueType: 'select',
              valueEnum: {
                Algorand: { text: 'Algorand', status: 'Default' },
              },
              readonly: isViewTicket,
              formItemProps: {
                rules: [
                  {
                    required: !isViewTicket,
                    message: t?.('form_fields.blockchain_req'),
                  },
                ],
                className: isViewTicket ? 'readformlabel' : undefined,
              },

              colProps,
            },
          ],
        },
      ],
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['isMultipleDates', 'customData', 'shipFromAddress', 'date', 'time'],
      },
      columns: ({ isMultipleDates, customData, shipFromAddress, date, time }: DataItem) => [
        {
          dataIndex: 'batchShipProducts',
          initialValue: [],
          hideInForm: !!customData || !isManualEntry,
          renderFormItem: (_schema, _config, form) => (
            <BatchShipProducts
              locationId={shipFromAddress}
              form={form}
              multipleDates={isMultipleDates}
              readonly={isViewTicket}
              date={date}
              time={time}
            />
          ),
          colProps: {
            xs: 24,
            md: 24,
          },
          formItemProps: {
            className: 'no-margin-divider',
          },
          fieldProps: {
            className: 'no-margin-divider',
          },
        },
        {
          dataIndex: 'csvData',
          initialValue: [],
          hideInForm: isViewTicket || (!customData && isManualEntry),
          renderFormItem: (row, record, form) => (
            <DataTemplate form={form} editableTableFormRef={editableTableFormRef} />
          ),
          colProps: colPropsFull,
        },
      ],
    },

    {
      valueType: 'divider',
      formItemProps: {
        className: !isViewTicket ? 'no-margin-divider' : undefined,
      },
      fieldProps: {
        className: !isViewTicket ? 'no-margin-divider' : undefined,
      },
    },
    {
      dataIndex: 'documents',
      initialValue: [],
      renderFormItem: (row, config, form) => <Documents isViewTicket={isViewTicket} form={form} />,
      colProps: colPropsFull,
    },
    // hidden fields
    {
      dataIndex: 'shipFromAddressName',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: colPropsNull,
    },
    {
      dataIndex: 'shipFromCompanyName',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: colPropsNull,
    },
    {
      dataIndex: 'shipToAddressName',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: colPropsNull,
    },
    {
      dataIndex: 'shipToCompanyName',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: colPropsNull,
    },
    {
      dataIndex: 'template',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: colPropsNull,
    },
  ];
};
export default BacthShipFormFields;

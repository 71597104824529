import { create } from 'zustand';
import { EventProductsStore } from './typings';

const useEventProductsStore = create<EventProductsStore<any>>((set) => ({
  products: [],
  template: undefined,
  tmpProduct: null,
  setTemplate: (template) => set({ template }),
  setTmpProduct: (product) => set(() => ({ tmpProduct: product })),
  setProducts: (products) =>
    set(() => ({
      products,
    })),
  addProduct: (product) =>
    set((state) => ({
      products: [...state.products, product],
    })),
  updateProduct: (product) =>
    set((state) => ({
      products: state.products.map((p) => (p.id === product.id ? product : p)),
    })),
  deleteProduct: (product) =>
    set((state) => ({
      products: state.products.filter((p) => p.id !== product.id),
    })),
}));
export default useEventProductsStore;

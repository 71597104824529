import { create } from 'zustand';
import { DataEntryMethodType, EventsStore } from './typings';

const useDataEntryMethodStore = create<EventsStore>((set) => ({
  dataEntryMethod: 'manual',
  setDataEntryMethod: (dataEntryMethod: DataEntryMethodType) =>
    set(() => ({
      dataEntryMethod,
    })),
}));

export default useDataEntryMethodStore;

import useListLocation from 'hooks/useListLocation';
import { useMemo } from 'react';
import { useAccountSubscription } from 'services/api';
import { featureFlag } from 'utils';

const useSubscriptionAction = () => {
  const { data: accountData } = useAccountSubscription();
  const { totalItems: totalItemsSelf } = useListLocation({
    tradePartnerStatus: ['Self'],
  });
  const { totalItems } = useListLocation({
    tradePartnerStatus: ['Wholechain', 'NonWholechain'],
  });
  const subscription = useMemo(() => accountData?.subscriptionDetails || {}, [accountData]);

  const internalLocationCount = useMemo(() => totalItemsSelf || 0, [totalItemsSelf]);

  const externalLocationCount = useMemo(() => totalItems || 0, [totalItems]);

  const allowInternalLocation = useMemo(
    () =>
      Object.prototype.hasOwnProperty.call(subscription, 'totalCompanyLocation')
        ? (internalLocationCount || 0) < (subscription?.totalCompanyLocation || 0)
        : true,
    [internalLocationCount, subscription],
  );

  const allowExternalLocation = useMemo(
    () =>
      Object.prototype.hasOwnProperty.call(subscription, 'totalOutsideLocation')
        ? (externalLocationCount || 0) < (subscription?.totalOutsideLocation || 0)
        : true,
    [externalLocationCount, subscription],
  );

  const allowTraceibility = useMemo(
    () => subscription?.integrations || false,
    [subscription?.integrations],
  );

  return {
    allowInternalLocation: featureFlag?.planCheck ? allowInternalLocation : true,
    allowExternalLocation: featureFlag?.planCheck ? allowExternalLocation : true,
    allowTraceibility: featureFlag?.planCheck ? allowTraceibility : true,
  };
};

export default useSubscriptionAction;

import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from 'utils';
import styles from './index.module.less';
import { CopyTextProps } from './typings';

const CopyText: FC<CopyTextProps> = ({ text, className }) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation('pages', { keyPrefix: 'copy_text' });

  const tooltips = useMemo(() => [t('copy'), t('copied')], [t]);

  const onCopy = useCallback(async () => {
    setCopied(true);
    if (text) {
      await copyToClipboard(text);
    }
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [text]);

  return (
    <Tooltip placement="bottom" title={!copied ? tooltips[0] : tooltips[1]}>
      {!copied ? (
        <CopyOutlined className={classNames(styles.copytext, className)} onClick={onCopy} />
      ) : (
        <CheckOutlined className={classNames(styles.checkcolor, className)} />
      )}
    </Tooltip>
  );
};
export default CopyText;

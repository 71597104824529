import { create } from 'zustand';
import { DataEntryMethodType, EventsStore } from './typings';

const useEventsStore = create<EventsStore>((set) => ({
  title: '',
  hasAdvancedSettings: false,
  dataEntryMethod: 'manual',
  multipleDates: false,
  isSubmitting: false,
  template: undefined,
  showNewTraceModal: true,
  setTemplate: (template) => set({ template }),
  setDataEntryMethod: (dataEntryMethod: DataEntryMethodType) =>
    set(() => ({
      dataEntryMethod,
    })),
  toggleAdvancedSettings: () =>
    set((state) => ({
      hasAdvancedSettings: !state.hasAdvancedSettings,
    })),
  setAdvancedSettings: (value) =>
    set(() => ({
      hasAdvancedSettings: value,
    })),
  setTitle: (title: string) =>
    set(() => ({
      title,
    })),
  setMultipleDates: (value) =>
    set(() => ({
      multipleDates: value,
    })),
  setSubmitting: (value) =>
    set(() => ({
      isSubmitting: value,
    })),
  setShowNewTraceModal: (value) =>
    set(() => ({
      showNewTraceModal: value,
    })),
}));

export default useEventsStore;

import { useAuthenticationContext } from 'contexts';

const Login = () => {
  const { isAuthenticated, signin, signout } = useAuthenticationContext();

  return (
    <div>
      <p>This page is using the Blank layout</p>
      <p>
        Is logged in &nbsp;
        {isAuthenticated ? 'true' : 'false'}
      </p>
      <button type="button" onClick={signin}>
        SignIn
      </button>
      <button type="button" onClick={signout}>
        SignOut
      </button>
    </div>
  );
};

export default Login;

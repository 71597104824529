import { useEffect } from 'react';
import useEventsStore from './useEventsStore';

const useTitle = (title: string) => {
  const setTitle = useEventsStore((state) => state.setTitle);

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);
};

export default useTitle;

import { SelectProps, Space, Typography } from 'antd';
import useListLocation from 'hooks/useListLocation';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useMemo, useState } from 'react';

import { GetLocationsOptionalParams } from 'hooks/useListLocation/typings';
import { useDebouncedCallback } from 'use-debounce';
import GSelect from './GSelect';
import { FetchDataFn } from './typings';

interface TradePartnerSelectProps<ValueType, OptionType extends BaseOptionType | DefaultOptionType>
  extends SelectProps<ValueType, OptionType> {
  tradePartnerId?: string;
}

const TradePartnerLocationSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  tradePartnerId,
  ...props
}: TradePartnerSelectProps<ValueType, OptionType>) => {
  const [params, setParams] = useState<GetLocationsOptionalParams>(
    tradePartnerId
      ? {
          partnerIds: [String(tradePartnerId)],
        }
      : {
          tradePartnerStatus: ['Self'],
        },
  );
  const { data, isLoading } = useListLocation(params);
  const locations = useMemo(() => data || [], [data]);

  // we create here the list with the shape the select is expecting for
  const options = useMemo<Array<DefaultOptionType>>(
    () =>
      locations?.map((option) => ({
        label: (
          <Space>
            <Typography.Text strong>{option.name}</Typography.Text>
            <Typography.Text>
              {option?.address?.displayAddress ||
                `${option?.address?.geoCoordinates?.latitude},${option?.address?.geoCoordinates?.longitude}`}
            </Typography.Text>
          </Space>
        ),
        value: String(option.id),
        itemProps: option,
      })) || [],
    [locations],
  );

  const debounced = useDebouncedCallback(
    // function
    (filterText) => {
      setParams((oldParams) => ({ ...oldParams, locationName: filterText }));
    },
    // delay in ms
    500,
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );

  return (
    <GSelect<ValueType, OptionType>
      placeholder="Select Location"
      {...props}
      loading={isLoading}
      options={options as OptionType[]}
      fetchData={fetchData}
      allowFilterOption={false}
      onBlur={() => {
        setParams((oldParams) => ({ ...oldParams, locationName: '' }));
      }}
    />
  );
};

TradePartnerLocationSelect.defaultProps = {
  tradePartnerId: '',
};

export default TradePartnerLocationSelect;

import { DefaultFooter } from '@ant-design/pro-layout';
import { Divider, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { privacyURL, termsURL } from 'utils';

const Footer = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'footer' });
  return (
    <DefaultFooter
      // @ts-ignore
      copyright={
        <Space>
          {`${t('label')} ${t('version')} ${process.env.REACT_APP_VERSION}`}
          <span>
            <Typography.Link type="secondary" href={privacyURL}>
              {t('privacy')}
            </Typography.Link>
            <Divider type="vertical" />
            <Typography.Link type="secondary" href={termsURL}>
              {t('terms')}
            </Typography.Link>
          </span>
        </Space>
      }
    />
  );
};

export default Footer;

import { Modal, ModalFuncProps, Typography, message } from 'antd';
import { GTableDateType } from 'components/GTable/contexts/typings';
import useListLocation from 'hooks/useListLocation';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { errorHandler, getProductsT, getShipmentsT } from 'utils';
import { Shipment } from '../typings';

const useShipmentActions = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  const { data: dataLocations } = useListLocation();
  const locations = useMemo(() => dataLocations || [], [dataLocations]);
  const onRejectShipment = async (actionPayload?: Shipment) => {
    const success = message.loading(t('reject_progress_title'), 0);

    if (actionPayload) {
      try {
        success();
        message.success(
          t('reject_success', {
            id: actionPayload?.primaryId || '',
            type: actionPayload?.isContainer ? getProductsT('sscc') : 'Lot',
          }),
        );
      } catch (error) {
        success();
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    }
  };
  const onRejectShipmentMultiple = useCallback(
    async (rows?: GTableDateType[]) => {
      const success = message.loading(t('reject_progress_title'), 0);

      try {
        if (rows?.length) {
          success();
          message.success(
            t('reject_success_multiple', {
              count: rows?.length,
            }),
          );
        }
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
        success();
      }
    },
    [t],
  );

  const onShowMixLotDialog = useCallback(() => {
    Modal.warning({
      title: getShipmentsT?.('mix_lot_serial_title'),
      content: getShipmentsT?.('mix_lot_serial_desc'),
      okText: getShipmentsT?.('mix_lot_serial_confirm'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);

  const onShowMixSSCCDialog = useCallback(() => {
    Modal.warning({
      title: getProductsT?.('sscc_selection_title'),
      content: getProductsT?.('sscc_selection_desc'),
      okText: getProductsT?.('sscc_selection_confirm'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);

  const onShowErrorExternalShipmentDialog = useCallback(() => {
    Modal.error({
      title: 'Unable To Receive Shipments',
      content:
        'Wholechain was unable to retrieve this data for this shipment. Please contact the company that sent the shipment or Wholechain support.',
      okText: 'Done',
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        hidden: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
    });
  }, []);

  const onShowDiffLocDialog = useCallback((onOk: ModalFuncProps['onOk']) => {
    Modal.warning({
      title: getShipmentsT?.('diff_location_title'),
      content: getShipmentsT?.('diff_location_desc'),
      okText: getShipmentsT?.('diff_location_confirm'),
      cancelText: getShipmentsT?.('diff_location_cancel'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
      onOk,
    });
  }, []);
  const onShowExtTraceDialog = useCallback(
    (traceShipments?: Array<GTableDateType>) => {
      const senderNames = traceShipments?.map((shipment) => shipment?.senderName);
      Modal.warning({
        title: t?.('new_trace_selection_title'),
        content: (
          <Typography.Paragraph>
            {t?.('new_trace_selection_desc')}
            <ul>
              {senderNames?.map((senderName) => (
                <li key={senderName}>{senderName}</li>
              ))}
            </ul>
          </Typography.Paragraph>
        ),
        okText: t?.('new_trace_seletion_confirm'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
      });
    },
    [t],
  );

  return {
    locations,
    onRejectShipment,
    onRejectShipmentMultiple,
    onShowMixLotDialog,
    onShowMixSSCCDialog,
    onShowDiffLocDialog,
    onShowExtTraceDialog,
    onShowErrorExternalShipmentDialog,
  };
};
export default useShipmentActions;

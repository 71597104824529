import G6 from '@antv/g6';
import { FC, useCallback, useEffect, useRef } from 'react';
import { getProductsT, globalStyles } from 'utils';
import './diagram.css';
import {
  createComplexNodes,
  createEdges,
  createReduceNodes,
  formatData,
  getNodeMethods,
} from './nodes';
import { MainDiagramProps } from './typings';

const NODE_TYPE = 'modelRect';
const EDGE = 'cubic-horizontal';

const MainDiagram: FC<MainDiagramProps> = ({
  fullscreenView,
  mainGraph,
  setMainGraph,
  event,
  onNodeClick,
  product,
  primaryId,
  showMinmap = true,
}) => {
  const containerRef = useRef<any>();
  const clearContainer = () => {
    const containerElement = document.getElementById('container');
    if (containerElement) {
      containerElement.innerHTML = '';
    }
  };
  const initializeGraph = useCallback(() => {
    const SCROLL_WIDTH = containerRef.current?.scrollWidth;
    const SCROLL_HEIGHT = containerRef.current?.scrollHeight;
    const minimap = new G6.Minimap({
      size: [SCROLL_WIDTH, 150],
    });

    const graph = new G6.Graph({
      container: document.getElementById('container') || '',
      width: SCROLL_WIDTH - 50,
      height: (SCROLL_HEIGHT && SCROLL_HEIGHT - 160) || 550,
      // zoom: 0.2,
      fitCenter: true,
      fitView: true,
      plugins: showMinmap ? [minimap] : [],
      modes: {
        default: [
          {
            type: 'collapse-expand',
            onChange: (item, collapsed) => {
              const data = item?.get?.('model');
              data.collapsed = collapsed;

              return true;
            },
            shouldBegin: (e) => {
              // only expand if there exist a expand property
              if (e?.item && e?.item?.getModel?.()?.expand) {
                return true;
              }
              return false;
            },
          },
          'drag-canvas',
          'zoom-canvas',
          {
            type: 'tooltip', // Tooltip
            formatText(model) {
              let text = '';
              if (model.expand) {
                text = getProductsT('expand_event_tooltip');
              } else {
                // The content of tooltip
                text = getProductsT('view_event_tooltip');
              }
              return text;
            },
          },
        ],
      },
      defaultNode: {
        type: NODE_TYPE,
        anchorPoints: [
          [0, 0.5],
          [0.9, 0.5],
        ],
      },
      defaultEdge: {
        type: EDGE,
        style: {
          stroke: '#A3B1BF',
        },
      },
      layout: {
        type: 'dagre',
        rankdir: 'LR',
        nodesepFunc: () => 20,
        ranksepFunc: () => 180,
      },
    });
    graph.on('itemcollapsed', (e: any) => {
      // eslint-disable-next-line no-underscore-dangle
      const item = e.item._cfg.model;
      item.name = e.collapsed ? 'Expand' : 'Collapse';

      graph.render();
    });
    graph.on('node:dblclick', (evt: any) => {
      // eslint-disable-next-line no-underscore-dangle
      // setSelectedNode(evt.item._cfg.model);
      // eslint-disable-next-line no-console, no-underscore-dangle
      onNodeClick?.(evt?.item?._cfg.model?.eventData);
      // setModalVisible(true);
    });
    setMainGraph(graph);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMainGraph, showMinmap]);

  useEffect(() => () => clearContainer(), []);

  useEffect(() => {
    clearContainer();
    initializeGraph();
  }, [initializeGraph, fullscreenView]);

  // Graph Data
  useEffect(() => {
    if (mainGraph) {
      const data = formatData(event, primaryId);
      mainGraph.data(data);
      mainGraph.render();
    }
  }, [mainGraph, event, primaryId]);

  const nodeBasicMethod = getNodeMethods(mainGraph);

  createReduceNodes(nodeBasicMethod);

  /* Complex node */
  createComplexNodes(nodeBasicMethod, product);

  createEdges(mainGraph);

  return <div style={globalStyles.gridcenter} ref={containerRef} id="container" />;
};

export default MainDiagram;

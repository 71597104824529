import { Button, Space, Typography } from 'antd';
import { useEventsStore } from 'pages/Events/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EventDetailsProps } from './typings';

const EventDetails: FC<EventDetailsProps> = ({ children }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.event_details' });
  const toggleAdvanced = useEventsStore((state) => state.toggleAdvancedSettings);
  const hasAdvancedSettings = useEventsStore((state) => state.hasAdvancedSettings);
  const dataEntryMethod = useEventsStore((state) => state.dataEntryMethod);

  return (
    <div>
      <Space size="middle">
        <Typography.Text className="ant-pro-form-group-title">{t('title')}</Typography.Text>
        {dataEntryMethod === 'manual' && (
          <Button type="link" size="middle" onClick={toggleAdvanced}>
            {hasAdvancedSettings ? t('hide_advanced_details') : t('show_advanced_details')}
          </Button>
        )}
      </Space>
      {children}
    </div>
  );
};

export default EventDetails;

import { InfoCircleOutlined } from '@ant-design/icons';
import { ProPageHeader } from '@ant-design/pro-layout';
import classNames from 'classnames';
import Actions from 'components/Actions';
import { FC } from 'react';
import styles from './TitleBar.module.less';
import { PageTitleInfoProps, PageTitleProps } from './typings';

const TitleBarInfo: FC<PageTitleInfoProps> = ({ onClick }) => (
  <InfoCircleOutlined className={styles.info} onClick={onClick} size={12} />
);

const TitleBar: FC<PageTitleProps> = ({
  actionItems,
  className,
  extra,
  extraTitle,
  onActionItemClick,
  onInfoClick,
  title,
  children,
  actionProps,
  ...props
}) => {
  const titleComponents = [title];

  const handleActionClick = (actionItemKey: string) => {
    if (onActionItemClick) {
      onActionItemClick(actionItemKey);
    }
  };

  if (onInfoClick) {
    titleComponents.push(<TitleBarInfo key="info" onClick={onInfoClick} />);
  }

  if (extraTitle) {
    titleComponents.push(<span key="extra">&nbsp;</span>);
    titleComponents.push(extraTitle);
  }

  const extraComponents = [extra];
  if (actionItems && actionItems.length > 0) {
    extraComponents.push(
      // @ts-ignore
      <Actions {...actionProps} key="actions" items={actionItems} onClick={handleActionClick} />,
    );
  }

  return (
    <ProPageHeader
      className={classNames(styles.container, className)}
      title={titleComponents}
      prefixedClassName="titlebar"
      extra={extraComponents}
      {...props}
    >
      {children}
    </ProPageHeader>
  );
};

export default TitleBar;

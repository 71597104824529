import { PlusOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button, Tooltip, Typography, Upload, message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { pdfDocumentPng } from 'assets';
import { ActionButtons, GTable } from 'components';
import useModalVisibility from 'hooks/useModalVisibility';
import { DataItem, DocumentItem, useDocumentsStore } from 'pages/Documents';
import AddDocumentForm from 'pages/Events/components/Sections/AddDocumentForm';
import AddExistingDocuments from 'pages/Events/components/Sections/AddExistingDocuments';
import ViewDocumentForm from 'pages/Events/components/Sections/ViewDocumentForm';
import { FC, useCallback, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { getT } from 'utils';
import styles from './index.module.less';
import { BacthShipDocuments } from './typings';

const { Text } = Typography;
type ColumnsType = {
  t: TFunction<'pages', 'templates.add_template'>;
  isEdit?: boolean;
};
const columns = ({ t, isEdit = true }: ColumnsType): Array<ProColumns<DocumentItem>> => [
  {
    dataIndex: 'id',
    hideInTable: true,
  },
  {
    title: t('form_fields.doc_tbl.name'),
    dataIndex: 'name',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('form_fields.doc_tbl.name_req'),
        },
      ],
      hasFeedback: false,
    },
    fieldProps: {
      placeholder: t('form_fields.doc_tbl.name'),
      size: 'small',
    },
    width: '28%',
  },

  {
    dataIndex: 'actions',
    hideInTable: !isEdit,
    valueType: 'option',
    render: (text, record) => <ActionButtons record={record} />,
    width: '9%',
  },
];

const Documents: FC<BacthShipDocuments> = ({ isViewTicket, form }) => {
  const actionRef = useRef<ActionType>();
  const modal = useModalVisibility(false);
  const viewModal = useModalVisibility(false);
  const existingDocModal = useModalVisibility(false);
  const document = useDocumentsStore((state) => state.document);
  const setDocument = useDocumentsStore((state) => state.setDocument);

  const { t } = useTranslation('pages', { keyPrefix: 'shipments.batch_ship' });
  const { getFieldValue, setFieldsValue } = form;
  const isEdit = true;

  const documents: Array<DocumentItem> = getFieldValue('documents') || [];
  const setDocuments = useCallback(
    (value: Array<DocumentItem>) =>
      setFieldsValue({
        documents: value,
      }),
    [setFieldsValue],
  );
  const fileList: Array<UploadFile> =
    documents?.map?.((doc: DataItem) => ({
      uid: doc?.id || '',
      name: doc?.title || '',
      status: 'done',
      url: doc?.documentURL || '',
      thumbUrl: pdfDocumentPng,
    })) || [];

  const onRemove: UploadProps['onRemove'] = (file) => {
    const id = file.uid as string;
    const newDocuments = documents.filter((doc) => doc.id !== id);
    setDocuments(newDocuments);
  };
  const onAddExisting = useCallback(() => {
    existingDocModal.show();
  }, [existingDocModal]);
  const onPreview = (file: UploadFile) => {
    const currdocument = documents.find((doc) => doc.id === file.uid);
    if (currdocument) {
      setDocument(currdocument);
      viewModal.show();
    }
  };
  const onAddDocument = useCallback(() => {
    modal.show();
  }, [modal]);
  const itemRender: UploadProps['itemRender'] = (node, file) => (
    <div className={styles.uploaditem}>
      {node}
      <div className={styles.uploaditemtext}>
        <Tooltip placement="bottom" title={file.name}>
          <Typography.Text type="secondary" ellipsis>
            {file.name}
          </Typography.Text>
        </Tooltip>
      </div>
    </div>
  );
  const uploadProps: UploadProps = {
    listType: 'picture-card',
    name: 'file',
    multiple: true,
    accept: '.pdf, application/pdf',
    itemRender,
    openFileDialogOnClick: false,
    fileList,
    onRemove,
    onPreview,

    onChange: (info: UploadChangeParam<UploadFile<any>>) => {
      const { file } = info;
      const { status } = file;

      switch (status) {
        case 'done':
          message.success(`${info.file.name} ${getT('templates.preview.file_upload_success_msg')}`);
          if (file?.originFileObj) {
            const blobUrl = URL.createObjectURL(file.originFileObj);
            file.url = blobUrl;
          }
          break;
        case 'error':
          message.error(`${info.file.name} ${getT('templates.preview.file_upload_error_msg')}`);
          break;
        default:
          // Handle unexpected status here
          break;
      }
    },
  };

  return (
    <>
      <AddDocumentForm form={form} modal={modal} onAddExisting={onAddExisting} />
      <ViewDocumentForm form={form} modal={viewModal} key={document?.id} />
      <AddExistingDocuments form={form} modal={existingDocModal} onAddDocument={onAddDocument} />

      <Text className="ant-pro-form-group-title">{t('documents_grp_title')}</Text>
      <div className={styles.content}>
        {!isViewTicket && (
          <Upload {...uploadProps}>
            <Button
              type="text"
              size="middle"
              onClick={onAddDocument}
              className={styles.uploadbutton}
            >
              <PlusOutlined className={styles.uploadicon} />
              <div>{t('documents_upload_btn_title')}</div>
            </Button>
          </Upload>
        )}
        {isViewTicket && (
          <GTable<DocumentItem>
            actionRef={actionRef}
            columns={columns({ t, isEdit })}
            editable={{
              onCancel: async (_rowKey, data) => {
                actionRef.current?.cancelEditable(data?.id || 0);
              },
            }}
            options={{
              reload: false,
              setting: false,
            }}
            actionsRenderOptions={{
              save: true,
              cancel: true,
            }}
            recordCreatorProps={false}
            onAddRecordClick={() => {
              actionRef.current?.addEditRecord?.({
                id: Date.now().toString(),
              });
            }}
            value={documents}
            onChange={setDocuments}
            enableRecordCreator={false}
            addBtnText={t('form_fields.add_document_title')}
          />
        )}
      </div>
    </>
  );
};

export default Documents;

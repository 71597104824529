import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { RowProps } from 'antd';
import { MultipleDatesFormItem } from 'components';
import { TimezoneSelect } from 'components/GSelect';
import BizStepSelect from 'components/GSelect/BizStepSelect';
import DispositionSelect from 'components/GSelect/DispositionSelect';
import TemplateSelect from 'components/GSelect/TemplateSelect';
import { DataItem as DocumentItem } from 'pages/Documents/Forms/typings';
import { useEventsStore } from 'pages/Events/hooks';
import {
  checkSameDate,
  concatDateTime,
  getDisabledDate,
  getDisabledTimeDependency,
  getTemplateFields,
  onChangeShipFieldDate,
} from 'utils';
import {
  CertDocuments,
  CertificationInfo,
  DataTemplate,
  Documents,
  EventDetails,
} from '../Sections';
import DataEntryMethod from './DataEntryMethod';
import ReceiveProducts from './TransformTables/ReceiveProducts';
import { ReceiveFormFieldsProps, ReceiveProductItem } from './typings';

const colProps = { xs: 24, sm: 12, md: 12, lg: 6 };
const colPropsDataEntry = { xs: 24, sm: 12, md: 18, lg: 18 };
const colPropsFull = { xs: 24, sm: 24, md: 24, lg: 24 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };
const colPropsFullCsv = { xs: 24, sm: 24, md: 24, lg: 24 };

const rowProps: RowProps = { gutter: [32, 24] };

export type DataItem = {
  id?: string;
  poNumber?: string;
  location?: string;
  customData?: string;
  assignedProduct?: string;
  primaryId?: string;
  scss?: string;
  quantity?: number;
  unitOfMeasure?: string;
  date?: string;
  time?: string;
  aggregateDate?: string;
  aggregateTime?: string;
  timeZone?: string;
  bizStep?: string;
  disposition?: string;
  inboundShipments?: Array<ReceiveProductItem>;
  advancedField?: string;
  advancedField2?: string;
  advancedField3?: string;
  documents?: Array<DocumentItem>;
  csvData?: Array<DataItem>;
  customProperties?: Array<string>;
  autologDisaggregateEvent?: boolean;
  disableAutologDisaggregateEvent?: boolean;
  certificationList?: Array<CertificationInfo>;
  isMultipleDates?: boolean;
  [key: string]: any;
};

export const ReceiveFormFields = ({
  hasAdvancedSettings,
  dataEntryMethod,
  lastEventDate,
  isInternal,
  isSSCC,
  // uom,
  defaultTimezone,
  products,
  isSerial,
  identifier,
  t,
  disabledDate,
  onChangeDate,
  disabledTime,
  onChangeTemplate,
  timeValidation,
  editableTableFormRef,
}: ReceiveFormFieldsProps): ProFormColumnsType<DataItem>[] => {
  const isManualEntry = dataEntryMethod === 'manual';
  const { template } = useEventsStore();

  return [
    {
      renderFormItem: () => <EventDetails />,
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['location'],
      },
      columns: ({ location }: DataItem) => [
        {
          dataIndex: 'isMultipleDates',
          valueType: 'switch',
          renderFormItem: (row, config, form) => (
            <MultipleDatesFormItem
              form={form}
              title={t('form_fields.multiple_dates_title')}
              dataIndex="isMultipleDates"
            />
          ),
          colProps: {
            xs: 24,
            md: 24,
          },
          hideInForm: !isManualEntry,
        },
        {
          title: t('form_fields.custom_data_title'),
          dataIndex: 'customData',
          fieldProps: {
            placeholder: t('form_fields.custom_data_placeholder'),
          },
          formItemProps: {
            rules: [
              {
                required: false,
                message: t('form_fields.custom_data_req'),
              },
            ],
          },
          renderFormItem: (row, config, form) => (
            <TemplateSelect
              onChange={(value, option) => onChangeTemplate?.(form, option, value, location)}
            />
          ),
          colProps,
        },
        {
          title: t('form_fields.data_entry_title'),
          dataIndex: 'dataEntryMethod',
          renderFormItem: () => <DataEntryMethod />,
          colProps: colPropsDataEntry,
        },
      ],
    },

    {
      valueType: 'dependency',
      fieldProps: {
        name: [
          'assignedProduct',
          'date',
          'time',
          'scss',
          'autologDisaggregateEvent',
          'disableAutologDisaggregateEvent',
          'unitOfMeasure',
          'isMultipleDates',
          'aggregateDate',
        ],
      },
      columns: ({
        date,
        time,
        autologDisaggregateEvent,
        disableAutologDisaggregateEvent,
        isMultipleDates,
        aggregateDate,
      }: DataItem) => [
        {
          title: t('form_fields.date_title'),
          valueType: 'date',
          dataIndex: 'date',
          initialValue: null,
          fieldProps: (form) => ({
            placeholder: t('form_fields.date_placeholder'),
            className: 'full-width',
            disabledDate,
            onChange: (value: string) => onChangeDate?.(form, value),
          }),
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.date_req'),
              },
            ],
          },
          colProps,
          hideInForm: !isManualEntry || isMultipleDates,
        },
        {
          title: t('form_fields.time_title'),
          valueType: 'time',
          dataIndex: 'time',
          className: 'timecol',
          initialValue: '12:00:00',
          fieldProps: {
            placeholder: t('form_fields.time_placeholder'),

            className: 'full-width',
            // check is same day as date
            disabledTime: checkSameDate(date, lastEventDate) ? disabledTime : undefined,
          },

          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_req'),
              },
              ...(timeValidation?.(date) || []),
            ],
          },
          colProps,
          hideInForm: !isManualEntry || isMultipleDates,
        },
        {
          title: t('form_fields.time_zone_title'),
          dataIndex: 'timeZone',
          valueType: 'select',
          initialValue: defaultTimezone,

          fieldProps: {
            placeholder: t('form_fields.time_zone_placeholder'),
          },
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.time_zone_req'),
              },
            ],
          },
          renderFormItem: () => <TimezoneSelect />,

          colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
        },
        {
          title: t('form_fields.bizstep_title'),
          dataIndex: 'bizStep',
          initialValue: 'urn:epcglobal:cbv:bizstep:receiving',
          valueType: 'select',
          renderFormItem: () => <BizStepSelect />,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.bizstep_req'),
              },
            ],
          },
          tooltip: {
            icon: <InfoCircleOutlined />,
            title: t('form_fields.bizstep_tooltip'),
            key: null,
            type: '',
            props: null,
          },
          colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
        },
        {
          title: t('form_fields.disposition_title'),
          dataIndex: 'disposition',
          initialValue: 'urn:epcglobal:cbv:disp:in_progress',
          valueType: 'select',
          renderFormItem: () => <DispositionSelect />,
          formItemProps: {
            rules: [
              {
                required: true,
                message: t('form_fields.disposition_req'),
              },
            ],
          },
          tooltip: {
            icon: <InfoCircleOutlined />,
            title: t('form_fields.disposition_tooltip'),
            key: null,
            type: '',
            props: null,
          },
          colProps: !isManualEntry || !hasAdvancedSettings ? colPropsNull : colProps,
        },
        {
          dataIndex: 'inboundShipments',
          renderFormItem: (_row, _schema, form) => (
            <ReceiveProducts
              form={form}
              allowEdit={false}
              isSSCC={isSSCC}
              isInternal={isInternal}
              dataProducts={products}
              isSerial={isSerial}
              identifier={identifier}
            />
          ),
          hideInForm: !isManualEntry,
        },
        /* autolog disaggregate event */
        {
          valueType: 'divider',
          hideInForm: !isSSCC || !isManualEntry,
        },
        {
          valueType: 'group',
          title: t('form_fields.disggregate_grp_title'),
          hideInForm: !isSSCC,
          rowProps,
          columns: [
            {
              dataIndex: 'autologDisaggregateEvent',
              initialValue: false,
              fieldProps: {
                disabled: disableAutologDisaggregateEvent,
              },
              renderFormItem: (_schema, _config, form) => (
                <MultipleDatesFormItem
                  form={form}
                  title={t('form_fields.autolog_disagregate_title')}
                  dataIndex="autologDisaggregateEvent"
                />
              ),
              hideInForm: !isManualEntry,
              colProps: colPropsFull,
            },
            {
              title: t('form_fields.date_title'),
              valueType: 'date',
              dataIndex: 'aggregateDate',
              hideInForm: !autologDisaggregateEvent || !isManualEntry,
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.date_req'),
                  },
                ],
              },
              fieldProps: (form) => ({
                className: 'full-width',
                placeholder: t('form_fields.date_placeholder'),
                onChange: (val: string) => onChangeShipFieldDate(form, val, date),
                disabledDate: getDisabledDate(date),
              }),
              colProps,
            },
            {
              title: t('form_fields.time_title'),
              valueType: 'time',
              dataIndex: 'aggregateTime',
              initialValue: '12:00:00',
              className: 'timecol',
              hideInForm: !autologDisaggregateEvent || !isManualEntry,
              formItemProps: {
                rules: [
                  {
                    required: true,
                    message: t('form_fields.time_req'),
                  },
                  ...(timeValidation?.(aggregateDate, concatDateTime(date, time)) || []),
                ],
              },
              fieldProps: {
                className: 'full-width',
                placeholder: t('form_fields.time_placeholder'),
                disabledTime: checkSameDate(date, aggregateDate)
                  ? getDisabledTimeDependency(time)
                  : undefined,
              },
              colProps,
            },
          ],
        },
      ],
    },

    {
      valueType: 'divider',
    },
    {
      dataIndex: 'csvData',
      initialValue: [],
      renderFormItem: (row, record, form) => (
        <DataTemplate form={form} editableTableFormRef={editableTableFormRef} />
      ),
      colProps: colPropsFullCsv,
    },

    {
      valueType: 'formSet',
      dataIndex: 'customProperties',
      initialValue: [],
      columns: getTemplateFields(template),
      rowProps,
      hideInForm: !template,
    },
    {
      dataIndex: 'certificationList',
      rowProps,
      renderFormItem: (row, record, form) => <CertDocuments form={form} />,
      hideInForm:
        !template || !template?.templateAttributes || !template?.allowCertificationDocuments,
    },
    {
      valueType: 'divider',
    },
    {
      dataIndex: 'documents',
      initialValue: [],
      renderFormItem: (_row, _config, form) => <Documents form={form} />,
      colProps: colPropsFull,
    },
    {
      dataIndex: 'disableAutologDisaggregateEvent',
      valueType: 'switch',
      initialValue: false,
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
    {
      dataIndex: 'unitOfMeasure',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: { xs: 0, sm: 0, md: 0, lg: 0 },
    },
  ];
};

export default ReceiveFormFields;

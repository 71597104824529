import { CloseCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { ColProps, FormInstance, Tag, Tooltip } from 'antd';
import { TagProp } from 'pages/Network/components/WCTagInput/typings';
import { isInvalidEmail } from 'utils';
import AccessList from './AccessList';
import styles from './Invite.module.less';
import { DataItem, InviteFormProps } from './typings';
import useInviteStore from './useInviteStore';

const colProps18: ColProps = { xs: 24, sm: 24, md: 16, lg: 18 };
const colProps24: ColProps = { xs: 24, sm: 24, md: 24, lg: 24 };
const colProps6: ColProps = { xs: 24, sm: 24, md: 8, lg: 6 };
const EmailTag = ({ prop, t }: TagProp) => {
  const { label, closable, onClose } = prop;
  return (
    <Tag
      {...prop}
      key={String(`key${label}`)}
      color={isInvalidEmail(String(label)) ? 'error' : 'default'}
      icon={
        isInvalidEmail(String(label)) && (
          <Tooltip placement="bottom" title={t('email_invalid')}>
            <CloseCircleOutlined />
          </Tooltip>
        )
      }
      closable={closable}
      onClose={onClose}
      className={styles.emailtag}
    >
      {label}
    </Tag>
  );
};
const InviteFormFields = ({ t }: InviteFormProps): ProFormColumnsType<DataItem>[] => {
  const { emails, setEmails } = useInviteStore();
  return [
    {
      dataIndex: 'emails',
      initialValue: [],
      valueType: 'select',
      fieldProps: (form: FormInstance<DataItem>) => ({
        mode: 'tags',
        placeholder: t('form_fields.emails_placeholder'),
        onChange: (value: DataItem['emails']) => {
          setEmails(value);
          form?.setFieldsValue({ emails: value });
        },
        tokenSeparators: [',', ' ', ';'],
        tagRender: (tagProps) => <EmailTag prop={tagProps} t={t} />,
        notFoundContent: null,
      }),
      formItemProps: {
        className: styles.mrb0,
      },
      colProps: (emails?.length || 0) > 0 ? colProps18 : colProps24,
    },
    {
      dataIndex: 'role',
      valueType: 'select',
      initialValue: 'Employee',
      fieldProps: {
        allowClear: false,
      },
      valueEnum: {
        Employee: {
          text: t('employee'),
          status: 'Default',
        },
        Admin: {
          text: t('admin'),
          status: 'Default',
        },
      },
      hideInForm: emails?.length === 0,
      colProps: colProps6,
    },
    {
      dataIndex: 'access',
      hideInForm: emails?.length !== 0,
      renderFormItem: () => <AccessList t={t} />,
    },
    {
      dataIndex: 'message',
      valueType: 'textarea',
      hideInForm: emails?.length === 0,
      fieldProps: {
        placeholder: t('form_fields.message_placeholder'),
        rows: 6,
      },
    },
  ];
};
export default InviteFormFields;

import { PlusOutlined } from '@ant-design/icons';
import { Col, ColProps, Divider, Input, message, Row, Typography } from 'antd';
import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useAttributes, useCreateAttribute } from 'services/api';
import { CreateAttributeRequest, ListAttributesOptionalParams } from 'services/api/client/src';
import { useDebouncedCallback } from 'use-debounce';
import { camelize, errorHandler } from 'utils';
import GSelect from './GSelect';
import styles from './index.module.less';
import { AttributeSelectProps, FetchDataFn } from './typings';

const colPropsInput: ColProps = { xs: 24, sm: 18, md: 18, lg: 20 };
const colPropsAdd: ColProps = { xs: 24, sm: 6, md: 4, lg: 4 };

const fieldTypes = {
  Text: {
    name: 'Text',
    dataIndex: 'Text',
    isDefault: true,
  },
  Number: {
    name: 'Number',
    dataIndex: 'Number',
  },
  Dropdown: {
    name: 'Dropdown',
    dataIndex: 'Dropdown',
  },
  RadioButton: {
    name: 'RadioButton',
    dataIndex: 'RadioButton',
  },
  Date: {
    name: 'Date',
    dataIndex: 'Date',
  },
};

const AttributeSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  filterItem,
  ...props
}: AttributeSelectProps<ValueType, OptionType>) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('pages', { keyPrefix: 'select.gselect.attribute_select' });

  const [params, setParams] = useState<ListAttributesOptionalParams>({});
  const [inputValue, setInputValue] = useState<string>('');
  const createAttribute = useCreateAttribute(queryClient);
  const { data, isLoading } = useAttributes(params);
  const attributes = useMemo(() => data?.results || [], [data]);
  const filterOption = useCallback(
    (option?: DefaultOptionType) => {
      if (!filterItem) {
        return true;
      }
      return filterItem?.(option?.itemProps as OptionType['itemProps']);
    },
    [filterItem],
  );
  // we create here the list with the shape the select is expecting for
  const AttributeOptions: Array<DefaultOptionType> = useMemo(
    () =>
      attributes
        ?.map((item) => ({
          label: String(item.name),
          value: String(item.id),
          itemProps: item,
        }))
        ?.filter(filterOption) || [],
    [attributes, filterOption],
  );

  const debounced = useDebouncedCallback(
    // function
    (filterText) => {
      setParams((oldParams) => ({ ...oldParams, fieldsFieldName: filterText }));
    },
    // delay in ms
    1000,
  );

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );

  const addItem = useCallback(async () => {
    try {
      const atrribute: CreateAttributeRequest = {
        name: inputValue,
        fieldProperties: {
          fields: {
            propertyName: camelize(inputValue),
            fieldType: fieldTypes.Text.dataIndex,
            required: false,
          },
          values: {
            defaultValue: '',
            defaultValues: [],
          },
          namespace: {
            name: 'newAttribute',
            prefix: 'wc',
            uri: 'wholechain.com',
          },
        },
      };
      await createAttribute.mutateAsync(atrribute);
      setInputValue('');
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  }, [createAttribute, inputValue]);

  const dropdownRender = useCallback(
    (menu: React.ReactElement) => (
      <>
        {menu}
        <Divider className={styles.dropdowndivider} />
        <div className={styles.dropdowninputgrp}>
          <Row justify="space-between" gutter={[8, 8]}>
            <Col {...colPropsInput}>
              <Input
                placeholder={t('placeholder')}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                size="small"
                className={styles.dropdowninput}
              />
            </Col>
            <Col {...colPropsAdd}>
              <Typography.Link
                onClick={addItem}
                className={styles.dropdownlink}
                disabled={isLoading || !inputValue}
              >
                <PlusOutlined /> {t('add')}
              </Typography.Link>
            </Col>
          </Row>
        </div>
      </>
    ),
    [addItem, inputValue, isLoading, t],
  );

  return (
    <GSelect<ValueType, OptionType>
      placeholder="Select Attribute"
      {...props}
      loading={isLoading}
      options={AttributeOptions as OptionType[]}
      fetchData={fetchData}
      allowFilterOption={false}
      dropdownRender={dropdownRender}
    />
  );
};

export default AttributeSelect;

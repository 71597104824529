import { Loading } from 'components';
import React, { FC, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { msalInstance } from 'services/utils/security';
import { setPathSession } from 'utils';

const Query: FC = () => {
  const [queryParams] = useSearchParams();
  const fromPath = queryParams?.get('from_path') || '';

  const onMount = useCallback(() => {
    if (fromPath) {
      setPathSession(fromPath);
      msalInstance.loginRedirect({
        scopes: ['openid', 'profile', 'offline_access'],
        extraQueryParameters: {
          isWebApp: 'true',
          isDesktopApp: 'true',
        },
        state: fromPath,
      });
    }
  }, [fromPath]);
  useEffect(() => {
    onMount();
  }, [onMount]);

  return <Loading />;
};
export default React.memo(Query);

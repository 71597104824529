import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { Typography } from 'antd';
import { LocationSelect, ProductSelect, SingleColumnTags, TradePartnerSelect } from 'components';
import pluralize from 'pluralize';
import { getDocTypeEnum } from 'utils';
import styles from '../index.module.less';
import DocumentAttachment from './DocumentAttachment';
import DocumentAttachmentHeader from './DocumentAttachmentHeader';
import { DataItem, ViewDocumentsProps } from './typings';

// const colProps = { xs: 24, sm: 12, md: 8, lg: 6 };
const colProps2Cols = { xs: 24, sm: 24, md: 24, lg: 12 };
const colProps3Cols = { xs: 24, sm: 12, md: 8, lg: 8 };
const colProps24Cols = { xs: 24, sm: 24, md: 24, lg: 24 };

const rowProps = { gutter: 32 };

const ViewDocumentFormFields = ({
  t,
  document,
  isEdit,
  isOwner,
}: ViewDocumentsProps): ProFormColumnsType<DataItem>[] => [
  {
    valueType: 'group',
    title: t('add_document.form_fields.doc_inf_title'),
    rowProps,
    colProps: colProps2Cols,
    columns: [
      {
        title: t('add_document.form_fields.doc_title'),
        dataIndex: 'title',
        colProps: colProps3Cols,
        readonly: true,
        fieldProps: {
          placeholder: t('add_document.form_fields.doc_title'),
        },
        formItemProps: {
          className: 'readformlabel',
        },
      },
      {
        title: t('add_document.form_fields.doc_type_title'),
        dataIndex: 'documentType',
        valueType: 'select',
        readonly: true,
        valueEnum: getDocTypeEnum(),
        colProps: colProps3Cols,
        fieldProps: {
          allowSearch: true,
          showSearch: true,
          placeholder: t('add_document.form_fields.doc_type_placeholder'),
        },
        formItemProps: {
          className: 'readformlabel',
        },
      },

      {
        valueType: 'dependency',
        fieldProps: {
          name: ['documentType'],
        },
        columns: ({ documentType }) => [
          {
            title: t('add_document.form_fields.doc_type_name_title'),
            dataIndex: 'documentTypeName',
            hideInForm: documentType !== 'other',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.doc_type_name_placeholder'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
          },
          {
            title: t('add_document.form_fields.doc_exp_date_title'),
            dataIndex: 'expirationDate',
            valueType: 'date',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              placeholder: t('add_document.form_fields.doc_exp_date_placeholder'),
              className: 'full-width',
            },
            formItemProps: {
              className: 'readformlabel',
            },
          },
          {
            title: t('add_document.form_fields.doc_exp_date_alert_title'),
            dataIndex: 'expirationDateAlert',
            valueType: 'date',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              placeholder: t('add_document.form_fields.doc_exp_alert_date_placeholder'),
              className: 'full-width',
            },
            formItemProps: {
              className: 'readformlabel',
            },
          },
          {
            title: t('add_document.form_fields.ship_from'),
            dataIndex: 'shipFrom',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.ship_from'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
            hideInForm: documentType !== 'bol',
          },
          {
            title: t('add_document.form_fields.doc_blockchain_title'),
            dataIndex: 'blockchain',
            initialValue: 'Algorand',
            valueType: 'select',
            readonly: true,
            valueEnum: {
              Algorand: {
                text: 'Algorand',
                status: 'default',
              },
              None: {
                text: 'None',
                status: 'default',
              },
            },
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.doc_blockchain_placeholder'),
              disabled: true,
            },
            formItemProps: {
              className: 'readformlabel',
            },
          },
          {
            title: t('add_document.form_fields.sid'),
            dataIndex: 'sID',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.sid'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
            hideInForm: documentType !== 'bol',
          },
          {
            title: t('add_document.form_fields.ship_to'),
            dataIndex: 'shipTo',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.ship_to'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
            hideInForm: documentType !== 'bol',
          },
          {
            title: t('add_document.form_fields.fob'),
            dataIndex: 'fOB',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.fob'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
            hideInForm: documentType !== 'bol',
          },
          {
            title: t('add_document.form_fields.cid'),
            dataIndex: 'cID',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.cid'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
            hideInForm: documentType !== 'bol',
          },
          {
            title: t('add_document.form_fields.ship_date'),
            dataIndex: 'shipDate',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.ship_date'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
            hideInForm: documentType !== 'bol',
          },
          {
            title: t('add_document.form_fields.doc_cert_std_title'),
            dataIndex: 'certificationStandard',
            hideInForm: documentType !== 'certification',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.doc_cert_std_title'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
          },
          {
            title: t('add_document.form_fields.doc_agency_title'),
            dataIndex: 'agency',
            hideInForm: documentType !== 'certification',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.doc_agency_title'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
          },
          {
            title: t('add_document.form_fields.doc_indentification_title'),
            dataIndex: 'identification',
            hideInForm: documentType !== 'certification',
            colProps: colProps3Cols,
            readonly: true,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.doc_indentification_title'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
          },
          {
            title:
              documentType === 'bol'
                ? t('add_document.form_fields.doc_bol_po_title')
                : t('add_document.form_fields.doc_purchase_order_title'),
            dataIndex: 'purchaseOrder',
            hideInForm: documentType === 'certification' || documentType === 'other',
            colProps: colProps3Cols,
            readonly: true,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.doc_purchase_order_placeholder'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
          },
          {
            title: t('add_document.form_fields.items'),
            dataIndex: 'items',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.items'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
            hideInForm: documentType !== 'bol',
          },
          {
            title: t('add_document.form_fields.carrier'),
            dataIndex: 'carrier',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.carrier'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
            hideInForm: documentType !== 'bol',
          },
          {
            title: t('add_document.form_fields.quantity_of_shipped_items'),
            dataIndex: 'quantitiesOfShippedItems',
            readonly: true,
            colProps: colProps3Cols,
            fieldProps: {
              allowSearch: true,
              showSearch: true,
              placeholder: t('add_document.form_fields.quantity_of_shipped_items'),
            },
            formItemProps: {
              className: 'readformlabel',
            },
            hideInForm: documentType !== 'bol',
          },
        ],
      },
      {
        valueType: 'divider',
      },
      {
        title: t?.('add_document.form_fields.doc_details_grp_title'),
        valueType: 'group',
        rowProps,
        columns: [
          {
            title: (
              <Typography.Text className={styles.tagheader}>
                {t('add_document.form_fields.company')}
              </Typography.Text>
            ),
            dataIndex: 'tradepartners',
            hideInForm: isEdit,
            colProps: colProps3Cols,
            renderFormItem: () => (
              <SingleColumnTags
                values={
                  document?.tags
                    ?.filter((item) => item?.type === 'Company')
                    ?.map((item) => item?.label || '') || []
                }
                columnTitle={pluralize(t('add_document.form_fields.company'), 2)}
                modalTitle={document?.title || ''}
                colTableTitle={t('add_document.form_fields.company') || ''}
              />
            ),
          },
          {
            title: (
              <Typography.Text className={styles.tagheader}>
                {t('add_document.form_fields.location')}
              </Typography.Text>
            ),
            dataIndex: 'locations',
            colProps: colProps3Cols,
            hideInForm: isEdit,
            renderFormItem: () => (
              <SingleColumnTags
                values={
                  document?.tags
                    ?.filter((item) => item?.type === 'Location')
                    ?.map((item) => item?.label || '') || []
                }
                columnTitle={pluralize(t('add_document.form_fields.location'), 2)}
                modalTitle={document?.title || ''}
                colTableTitle={t('add_document.form_fields.location') || ''}
              />
            ),
          },
          {
            title: (
              <Typography.Text className={styles.tagheader}>
                {t('add_document.form_fields.product')}
              </Typography.Text>
            ),
            dataIndex: 'products',
            colProps: colProps3Cols,
            hideInForm: isEdit,
            renderFormItem: () => (
              <SingleColumnTags
                values={
                  document?.tags
                    ?.filter((item) => item?.type === 'Product')
                    ?.map((item) => item?.label || '') || []
                }
                columnTitle={pluralize(t('add_document.form_fields.product'), 2)}
                modalTitle={document?.title || ''}
                colTableTitle={t('add_document.form_fields.product') || ''}
              />
            ),
          },
          {
            initialValue: [],
            title: t('add_document.form_fields.company'),
            dataIndex: 'tradePartnerArray',
            hideInForm: !isEdit,
            fieldProps: {
              placeholder: t('add_document.form_fields.company'),
            },
            renderFormItem: () => <TradePartnerSelect mode="multiple" />,
            colProps: colProps24Cols,
          },
          {
            initialValue: [],
            dataIndex: 'locationArray',
            title: t('add_document.form_fields.location'),
            hideInForm: !isEdit,
            fieldProps: {
              placeholder: t('add_document.form_fields.location'),
            },
            renderFormItem: () => <LocationSelect mode="multiple" placeholder="Location" />,
            colProps: colProps24Cols,
          },
          {
            initialValue: [],
            dataIndex: 'productArray',
            title: t('add_document.form_fields.product'),
            hideInForm: !isEdit,
            fieldProps: {
              placeholder: t('add_document.form_fields.product'),
            },
            renderFormItem: () => <ProductSelect mode="multiple" allowLotSerial />,
            colProps: colProps24Cols,
          },
          {
            title: t('add_document.form_fields.doc_details_privacy_title'),
            dataIndex: 'privacy',
            readonly: true,
            colProps: colProps24Cols,
            hideInForm: isEdit && isOwner,
            tooltip: {
              icon: <InfoCircleOutlined />,
              title: t('add_document.form_fields.doc_details_privacy_tooltip'),
              key: null,
              type: '',
              props: null,
            },
            render: (text) => t(`add_document.visibility.${String(text)?.toLowerCase()}`),
            formItemProps: {
              className: 'readformlabel',
            },
          },
          {
            title: t('add_document.form_fields.doc_details_privacy_title'),
            dataIndex: 'privacy',
            colProps: colProps2Cols,
            valueType: 'radio',
            hideInForm: !isEdit || (isEdit && !isOwner),
            initialValue: 'public',
            valueEnum: {
              Public: {
                text: t('add_document.visibility.public'),
                status: 'default',
              },
              Private: {
                text: t('add_document.visibility.private'),
                status: 'default',
              },
            },
            tooltip: {
              icon: <InfoCircleOutlined />,
              title: t('add_document.form_fields.doc_details_privacy_tooltip'),
              key: null,
              type: '',
              props: null,
            },
            formItemProps: {
              className: 'readformlabel',
            },
          },
        ],
      },
    ],
  },
  {
    valueType: 'dependency',
    fieldProps: {
      name: ['documentAttachment'],
    },
    columns: ({ documentAttachment = '' }: DataItem) => [
      {
        valueType: 'group',
        rowProps,
        colProps: colProps2Cols,
        columns: [
          {
            renderFormItem: (_schema, _config, form) => (
              <DocumentAttachmentHeader form={form} isView />
            ),
            formItemProps: {
              className: styles.documentheader,
            },
          },
          {
            dataIndex: 'documentAttachment',
            renderFormItem: (_schema, _config, form) => (
              <DocumentAttachment form={form} isView documentAttachment={documentAttachment} />
            ),
          },
        ],
      },
    ],
  },
];
export default ViewDocumentFormFields;

import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button, Tag, Typography } from 'antd';
import GTable from 'components/GTable';
import UpgradeModal from 'pages/Network/forms/AddEdit/UpgradeModal';
import { useNetworkActions } from 'pages/Network/hook';
import useDeleteActions from 'pages/Network/hook/useDeleteActions';
import { NetworkItem } from 'pages/Network/typings';
import { FC, Key, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { getStatusColor } from 'utils/network';
import { ArchivedProps } from '../typings';

const statusFilter = [
  { text: 'Wholechain', value: 'Wholechain' },
  { text: 'Non-Wholechain', value: 'NonWholechain' },
  { text: 'Pending', value: 'Pending' },
  { text: 'Rejected', value: 'Rejected' },
];

type ColumnsType = {
  onRowActionClick?: (actionPayload?: NetworkItem) => void;
  addressSearchfilter: ProColumns;
  nameSearchfilter: ProColumns;
  coreTradePartners: ProColumns['filters'];
  t: TFunction<'pages', 'networks'>;
};
const columns = ({
  onRowActionClick,
  coreTradePartners,
  addressSearchfilter,
  nameSearchfilter,
  t,
}: ColumnsType): Array<ProColumns<NetworkItem>> => [
  {
    title: t('network_table_cols.company'),
    dataIndex: 'company',
    filters: coreTradePartners,
    filterSearch: true,
    render: (text, record) => (
      <div>
        {`${text}`}
        <span>
          <Typography.Text type="secondary">
            {record.isOwnAccount ? ` ${t('me')}` : ''}
          </Typography.Text>
        </span>
      </div>
    ),
  },
  {
    title: t('network_table_cols.location_address'),
    dataIndex: 'locationAddress',
    ellipsis: true,
    ...addressSearchfilter,
  },
  {
    title: t('network_table_cols.location_name'),
    dataIndex: 'name',
    ellipsis: true,
    ...nameSearchfilter,
  },
  {
    title: t('network_table_cols.status'),
    dataIndex: 'status',
    filters: statusFilter,
    render: (text, record) => (
      <Tag className={getStatusColor(record?.status || '')}>{`${text}`}</Tag>
    ),
  },
  {
    dataIndex: 'actions',
    hideInSetting: true,
    valueType: 'option',
    render: (text, record) => (
      <Button
        className="actions"
        disabled={!onRowActionClick}
        onClick={() => onRowActionClick?.(record)}
        type="primary"
        ghost
        size="small"
      >
        Move to Networks
      </Button>
    ),
    width: 150,
  },
];
const Locations: FC<ArchivedProps> = ({ onRowSelectionChange, selectedRowKeys }) => {
  const actionRef = useRef<ActionType>();
  const { t } = useTranslation('pages', { keyPrefix: 'network' });
  const {
    params,
    records,
    totalItems,
    isLoading,
    coreTradePartners,
    filters: [nameSearchfilter, addressSearchfilter],
    onTableChange,
    isFetching,
  } = useNetworkActions({
    archived: true,
  });
  const { onUnarchiveMultipleLocations, upgradeModal, unArchiveLoading } = useDeleteActions();

  const onRowActionClick = async (actionPayload?: NetworkItem) => {
    await onUnarchiveMultipleLocations([actionPayload?.id as Key]);
  };

  return (
    <>
      {upgradeModal.visible && <UpgradeModal modal={upgradeModal} tkey="archive_loc." />}
      <GTable<NetworkItem>
        actionRef={actionRef}
        columns={columns({
          onRowActionClick: unArchiveLoading || isFetching ? undefined : onRowActionClick,
          nameSearchfilter,
          addressSearchfilter,
          coreTradePartners,
          t,
        })}
        loading={isLoading}
        enableRowSelection
        value={records}
        pagination={{
          defaultPageSize: params?.pageSize,
          total: totalItems,
          showTotal: (total, range) =>
            total <= 1
              ? t('network_pagi', { range0: range[0], range1: range[1], total })
              : t('product_pagi_archived', { range0: range[0], range1: range[1], total }),
        }}
        rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange }}
        scroll={{ y: '75vh', x: 980 }}
        columnsState={{
          persistenceKey: 'archived-locations',
        }}
        onTableChange={onTableChange}
        options={{
          setting: false,
          reload: false,
        }}
      />
    </>
  );
};
export default Locations;

import { DeleteOutlined, InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, ColProps, Form, Space, Tooltip, Typography, Upload, message } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import useListLocation from 'hooks/useListLocation';
import moment from 'moment';
import MultiReceiveTable from 'pages/AllShipments/MultiReceiveTable';
import { useEventsStore } from 'pages/Events/hooks';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAttributes, useTemplateById, useTradePartners } from 'services/api';
import { TemplateCertificationResponse } from 'services/api/client/src';
import {
  CSVTemplate,
  certificationMappings,
  checkDateFormat,
  checkTimeFormat,
  coreBizStep,
  coreDispositions,
  dateLocaleFormat,
  filterHiddenAttributes,
  filterUnique,
  getBizStepByIdentifier,
  getCompanyIdByName,
  getDispositionByIdentifier,
  getLocationIdByName,
  getProductsT,
  getTimezoneByOffsetorText,
  keysToSampleObject,
  msofficeHelpLink,
  readExcelTemplate,
  titletoKeys,
} from 'utils';
import { getDefaultValues } from 'utils/locations';
import XLSX from 'xlsx';
import useWatchValues from '../../../AllShipments/hooks/useWatchValues';
import { getTranslatedCSVTemplate } from '../CSVTemplates';
import { MultiReceiveProductItem } from '../Forms/AllReceiveForm.fields';
import { DataItem } from '../Forms/CommissionForm.fields';
import { ReceiveProductsDataType } from '../Forms/TransformTables/AllReceiveProducts';
import styles from './index.module.less';
import { CustomDataTemplateProps } from './typings';

const certificationKeyMappings: {
  [key: string]: string;
} = {
  type: 'certificationType',
  standard: 'certificationStandard',
  agency: 'certificationAgency',
  value: 'certificationValue',
  identification: 'certificationIdentification',
  id: 'id',
};

const uploadColProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 12 };

const { Text, Link } = Typography;
const { Dragger } = Upload;

export const CustomDataTemplate: FC<CustomDataTemplateProps> = () => {
  const template = useEventsStore((state) => state.template);
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  return (
    <div>
      <Text className="ant-pro-form-group-title">{t('custom_data_template')}</Text>
      {(!template || !template?.templateAttributes) && (
        <div className={styles.content}>
          <Text type="secondary">{t('no_template')}</Text>
        </div>
      )}
    </div>
  );
};

export const CSVUpload: FC<CustomDataTemplateProps> = ({
  form,
  eventProducts,
  validationMessage,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.data_template' });
  const { title } = useEventsStore();
  const { setFieldsValue } = form;
  const templateId = Form.useWatch('customData', form);
  const { data: template } = useTemplateById(templateId);
  const { data: locations } = useListLocation();
  const { data: attributes } = useAttributes();
  const { data: tradePartners } = useTradePartners({ types: ['Self', 'NonWholechain'] });
  const csvData: Array<DataItem> = Form.useWatch?.('csvData', form) || [];
  const locationId = Form.useWatch?.('location', form) || undefined;
  const { multiReceiveObjects } = useWatchValues(form);

  const defaultValues = useMemo(
    () => getDefaultValues({ template, attributes, locationId }),
    [locationId, template, attributes],
  );

  const templateAttributes = useMemo(
    () =>
      template?.templateAttributes?.filter(filterHiddenAttributes)?.reduce((acc, templateObj) => {
        if (
          templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep' ||
          templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'disposition'
        ) {
          if (templateObj?.attribute?.fieldProperties?.values?.defaultValue) {
            const initialValue =
              templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep'
                ? coreBizStep()?.find?.(
                    (el) =>
                      el.urn === templateObj?.attribute?.fieldProperties?.values?.defaultValue,
                  )?.name || undefined
                : coreDispositions()?.find?.(
                    (el) =>
                      el.urn === templateObj?.attribute?.fieldProperties?.values?.defaultValue,
                  )?.name || undefined;
            acc[String(templateObj?.attribute?.fieldProperties?.fields?.propertyName || '')] = {
              dataIndex: templateObj?.attribute?.fieldProperties?.fields?.propertyName || '',
              title: templateObj?.attribute.name || '',
              initialValue,
            };
          }
        } else {
          acc[String(templateObj?.attribute?.fieldProperties?.fields?.propertyName || '')] = {
            dataIndex: templateObj?.attribute?.fieldProperties?.fields?.propertyName || '',
            title: templateObj?.attribute?.name || '',
            ...(defaultValues?.[
              templateObj?.attribute?.fieldProperties?.fields?.propertyName || ''
            ] && {
              initialValue:
                defaultValues?.[
                  templateObj?.attribute?.fieldProperties?.fields?.propertyName || ''
                ],
            }),
          };
        }

        return acc;
      }, {} as CSVTemplate),
    [template, defaultValues],
  );
  const certificationAttributes = useMemo(() => {
    const certificationFields = template?.templateCertifications || [];
    if ((certificationFields?.length || 0) > 0) {
      return certificationFields.reduce((acc, field, idx) => {
        Object.keys(field)
          ?.filter((key) => key !== 'id' && key !== 'eventTemplateId', [])
          ?.forEach((key) => {
            const dataIndex = `${key} ${idx + 1}`;
            acc[dataIndex] = {
              dataIndex: `${key} ${idx + 1}`,
              title: `${getProductsT(certificationMappings[key])} ${idx + 1}`,
              initialValue: field?.[key as keyof TemplateCertificationResponse] || '',
            };
          });

        return acc;
      }, {} as CSVTemplate);
    }
    return {};
  }, [template]);

  const overallTemplateReceive: CSVTemplate = useMemo(
    () => ({
      ...certificationAttributes,
      ...templateAttributes,
    }),
    [certificationAttributes, templateAttributes],
  );

  const setCsvData = useCallback(
    (value: MultiReceiveProductItem[]) => {
      setFieldsValue({ csvData: value });
    },
    [setFieldsValue],
  );

  const setMultiReceiveObjects = useCallback(
    (value: MultiReceiveProductItem[]) => {
      if (value?.length) {
        setFieldsValue({
          multiReceiveObjects: value,
        });
      }
    },
    [setFieldsValue],
  );

  const { selectedShipments } = useShipmentStore();

  // function to download upload csv template with already filled in data
  const downloadReceiveXLSXTemplate = useCallback(
    (data: any) => {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${title}.xlsx`);
    },
    [title],
  );

  const onDownloadCSVTemplate = useCallback(() => {
    const sampleDataObj = keysToSampleObject(getTranslatedCSVTemplate(overallTemplateReceive));
    const data = selectedShipments?.map((item) => {
      const inboundShipment: ReceiveProductsDataType[] =
        multiReceiveObjects?.find((i) => i?.shipmentId === item?.shipmentId, [])
          ?.inboundShipments || [];
      const productNames =
        inboundShipment?.map((i) =>
          i?.isSSCC
            ? filterUnique(
                i?.children?.map((j: ReceiveProductsDataType) => j?.productName, []),
              )?.join(',')
            : i?.productName || '',
        ) || [];

      return {
        'Shipped From PO Number': item?.purchaseOrderNumber,
        'Shipped From Company': item?.originCompanyName,
        'Shipped From Location': item?.originLocationName,
        Products: filterUnique(productNames)?.join(',') || '',
        'Receive To Company': '',
        'Receive To Location': '',
        'PO Number': '',
        Date: '',
        Time: '',
        Timezone: '(UTC-11:00) Coordinated Universal Time-11',
        'Business Step': 'Commissioning (CBV)',
        Disposition: 'Active (CBV)',
        ...sampleDataObj,
      };
    }, []);
    return downloadReceiveXLSXTemplate(data);
  }, [downloadReceiveXLSXTemplate, multiReceiveObjects, overallTemplateReceive, selectedShipments]);

  const isValidDate = (cDate?: string, eDate?: string) => {
    if (cDate && checkDateFormat(cDate)) {
      const currentDate = moment(cDate).format('YYYY-MM-DD');
      const eventDate = moment(eDate).format('YYYY-MM-DD');
      if (
        moment(currentDate)?.isSame(moment(eventDate)) ||
        moment(currentDate)?.isAfter(moment(eventDate))
      ) {
        return moment(currentDate).format('YYYY-MM-DD');
      }
      return undefined;
    }
    return undefined;
  };

  const isValidTime = (cDate?: string, eDate?: string, time?: string) => {
    if (time && checkTimeFormat(time)) {
      if (cDate && checkDateFormat(cDate)) {
        const currentDate = moment(cDate).format('YYYY-MM-DD');
        const eventDate = moment(eDate).format('YYYY-MM-DD');
        if (moment(currentDate)?.isAfter(moment(eventDate))) {
          return time;
        }
        if (eDate && moment(currentDate)?.isSame(moment(eventDate))) {
          const currentDateTime = moment(
            `${moment(new Date(currentDate)).format('YYYY-MM-DD')} ${time}`,
            'YYYY-MM-DD HH:mm:ss',
          );
          const eventDateTime = moment(`${moment(new Date(eDate)).format('YYYY-MM-DD HH:mm:ss')}`);
          const isSameofBeforeTime = moment(currentDateTime).isSameOrBefore(
            eventDateTime,
            'second',
          );
          if (!isSameofBeforeTime) {
            return time;
          }
        }
        return undefined;
      }
      return time;
    }
    return undefined;
  };

  const getCSVDataByTemplate = (rawData: any) => {
    const records = selectedShipments?.map((item, i) => {
      const obj = multiReceiveObjects?.find((j) => j?.shipmentId === item?.shipmentId, []);
      const certificationTable =
        template?.templateCertifications?.map((field, k) => {
          const keys = Object.keys(field).filter(
            (key) => key !== 'id' && key !== 'eventTemplateId',
            [],
          );
          const row: any = {};
          keys?.forEach((key) => {
            const keyField = certificationKeyMappings[key];
            const value = rawData?.[i]?.[`${getProductsT(certificationMappings[key])} ${k + 1}`];
            row[keyField] = value;
          });

          return row;
        }, []) || [];

      const shipEventDate =
        dateLocaleFormat(
          typeof item?.eventDate === 'string' ? new Date(item?.eventDate) : item?.eventDate,
        ) || '';

      return {
        ...obj,
        originLocationId: item?.originLocationId,
        shipEventDate,
        customProperties: titletoKeys(overallTemplateReceive, rawData?.[i]),
        certificationList: certificationTable,
        date: isValidDate(rawData?.[i]?.Date, shipEventDate),
        time: isValidTime(rawData?.[i]?.Date, shipEventDate, rawData?.[i]?.Time),
        location: getLocationIdByName(rawData?.[i]?.['Receive To Location'], locations) || '',
        companyName: rawData?.[i]?.['Receive To Company'],
        company:
          getCompanyIdByName(rawData?.[i]?.['Receive To Company'], tradePartners?.data) || '',
        locationName: rawData?.[i]?.['Receive To Location'],
        poNumber: rawData?.[i]?.['PO Number'],
        bizStep: getBizStepByIdentifier(rawData?.[i]?.['Business Step']),
        disposition: getDispositionByIdentifier(rawData?.[i]?.Disposition),
        timeZone: getTimezoneByOffsetorText(rawData?.[i]?.Timezone),
      };
    }, []);
    return records || [];
  };
  const onCSVUpload: UploadProps['onChange'] = async (info) => {
    const { file } = info;
    file.status = 'done';
    if (!file.url && !file.preview && file.originFileObj) {
      const data = await readExcelTemplate(file.originFileObj);
      const newData: Array<MultiReceiveProductItem> = getCSVDataByTemplate(data);
      setCsvData(newData);
      setMultiReceiveObjects(newData);
    }
  };
  const uploadProps: UploadProps = {
    name: 'csvfile',
    multiple: false,
    maxCount: 1,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: onCSVUpload,
    beforeUpload: async (file) => {
      const isCSV =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isCSV) {
        message.error(t('only_xlsx_upload'));
      }
      // check if csv file contains data
      const data = await readExcelTemplate(file);
      const newData: Array<MultiReceiveProductItem> = getCSVDataByTemplate(data);
      if (!newData?.length) {
        message.error(t('no_csv'));
      }

      return isCSV && newData?.length > 0;
    },
    showUploadList: false,
  };

  const onRemoveFile = useCallback(() => {
    const records = selectedShipments?.map((item) => {
      const obj = multiReceiveObjects?.find((j) => j?.shipmentId === item?.shipmentId, []);
      return {
        shipmentId: obj?.shipmentId,
        inboundShipments: obj?.inboundShipments,
        originLocationId: item?.originLocationId,
        shipEventDate:
          dateLocaleFormat(
            typeof item?.eventDate === 'string' ? new Date(item?.eventDate) : item?.eventDate,
          ) || '',
      };
    }, []);
    setMultiReceiveObjects(records || []);
    setCsvData([]);
  }, [multiReceiveObjects, selectedShipments, setCsvData, setMultiReceiveObjects]);

  return (
    <>
      <Space size="large" direction="vertical" className={styles.csvbtn}>
        <Space>
          <Space direction="vertical">
            <Typography.Text strong>Shipments</Typography.Text>
            <Typography.Text>
              Each shipment will have it&apos;s own set of custom data attributes and documents.
              Click into each shipment to add documents and upload the receive details
            </Typography.Text>
            {!csvData?.length && (
              <Typography.Text>
                <Typography.Text strong>Step 1:</Typography.Text> Download template and input
                receive details.{' '}
                <Tooltip
                  title={
                    <div className={styles.tooltiplink}>
                      {t('upload_tooltip')}
                      &nbsp;
                      <Link target="_blank" href={msofficeHelpLink} rel="noreferrer">
                        {t('upload_tooltip_href')}
                      </Link>
                    </div>
                  }
                >
                  <Typography.Text>
                    <InfoCircleOutlined className={styles.tooltip} />
                  </Typography.Text>
                </Tooltip>
              </Typography.Text>
            )}
          </Space>
        </Space>
        {!csvData?.length && (
          <Button shape="round" type="primary" onClick={onDownloadCSVTemplate}>
            {t('download_csv_template')}
          </Button>
        )}
        {!csvData?.length && (
          <Typography.Text>
            <Typography.Text strong>Step 2:</Typography.Text> Upload template to add receive details
            to each shipment.
          </Typography.Text>
        )}
      </Space>

      {csvData?.length ? (
        <div>
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            shape="round"
            ghost
            onClick={onRemoveFile}
            className={styles.removebtn}
          >
            {t('remove_file')}
          </Button>
          <MultiReceiveTable
            form={form}
            eventProducts={eventProducts}
            validationMessage={validationMessage}
          />
        </div>
      ) : (
        <Col {...uploadColProps}>
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('upload_desc')}</p>
          </Dragger>
        </Col>
      )}
    </>
  );
};

const DataTemplate: FC<CustomDataTemplateProps> = ({
  form,
  editableTableFormRef,
  eventProducts,
  validationMessage,
}) => (
  <CSVUpload
    form={form}
    editableTableFormRef={editableTableFormRef}
    eventProducts={eventProducts}
    validationMessage={validationMessage}
  />
);

export default React.memo(DataTemplate);

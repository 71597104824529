import { create } from 'zustand';
import { AddressProps, AddressStoreProps } from './typings';

const useLocationStore = create<AddressStoreProps>((set) => ({
  city: '',
  state: '',
  country: '',
  line1: '',
  line2: '',
  postalCode: '',
  setAddressData: (address: AddressProps) =>
    set(() => ({
      ...address,
    })),
}));

export default useLocationStore;

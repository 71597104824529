import ProList, { ProListMetas } from '@ant-design/pro-list';
import { Button, Typography } from 'antd';
import classNames from 'classnames';
import { useNotificationFilters } from 'pages/Dashboard';
import { useShipmentActions, useShipmentStore } from 'pages/Shipments';
import { Shipment } from 'pages/Shipments/typings';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { NetworkInviteResponse } from 'services/api/client/src';
import { getTParamOrReturnBackup } from 'utils';
import { getLocationByUrn } from 'utils/locations';
import getObjectFromParam from 'utils/notifications';
import { useNotifyContext } from './NotificationContext';
import styles from './index.module.less';
import { NotificationBoxProps, NotificationItem } from './typings';

const Notifications: FC<NotificationBoxProps> = ({ setHasNotification }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard.notification' });
  const { setVisible } = useNotifyContext();

  const navigate = useNavigate();
  const {
    setProducts,
    setAdvancedSettings,
    setInvitePopup,
    setInviteRequest,
    processingReceiveIds,
  } = useShipmentStore();
  const { locations } = useShipmentActions();

  const {
    records,
    isFetchingNextPage,
    isLoading,
    disableSeeMore,
    disableClear,
    onClear,
    onViewMore,
    onItem,
  } = useNotificationFilters({
    pageNumber: 1,
    pageSize: 5,
    type: 'Notification',
  });

  const [clearNotificationsControl, setClearNotificationsControl] = useState(disableClear);
  const [seeMoreControl, setSeeMoreControl] = useState(false);

  useEffect(() => {
    if (disableClear !== clearNotificationsControl && seeMoreControl === false) {
      setClearNotificationsControl(disableClear);
      if (setHasNotification) setHasNotification(true);
    }
    setSeeMoreControl(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableClear, records]);

  const shouldDisableReceiveNavigation = useCallback(
    (record?: Shipment) => processingReceiveIds?.includes(record?.id!) ?? false,
    [processingReceiveIds],
  );

  const onReceive = useCallback(
    (record: Shipment) => {
      if (setVisible !== undefined) setVisible(false);
      setAdvancedSettings(false);
      setProducts([record]);
      const recordId = record?.id || '';
      const productsSearchParams = createSearchParams({
        products: String(recordId),
        locationId: getLocationByUrn(locations, record?.destinationUrn || '')?.id || '',
        shippedFrom: record?.senderName || '',
        eventDate: record?.eventDate ? record?.eventDate : '',
        ...(record?.isContainer && { containers: record?.containerId || '' }),
      });
      navigate({
        pathname: `../products/${
          record?.productId ||
          record?.containerItems?.[0]?.productId ||
          record?.externalShipmentId ||
          ''
        }/events/receive`,
        search: record ? `?${productsSearchParams}` : undefined,
      });
    },
    [locations, navigate, setAdvancedSettings, setProducts, setVisible],
  );

  const onViewInvitation = useCallback(
    (record: NotificationItem) => {
      if (setVisible !== undefined) setVisible(false);
      setInvitePopup(true);
      setInviteRequest(record?.action?.resource as NetworkInviteResponse);
      navigate('/view_invite');
    },
    [navigate, setInvitePopup, setInviteRequest, setVisible],
  );

  const onItemAction = useCallback(
    (record: NotificationItem) => {
      switch (record?.kind) {
        case 'NewNetworkInvite':
          onViewInvitation(record);
          break;

        case 'InboundShipment':
          onReceive(record?.action?.resource as Shipment);
          break;

        default:
          break;
      }
    },
    [onViewInvitation, onReceive],
  );
  const getAction = useCallback(
    (record?: NotificationItem) => {
      if (!record?.action?.text) {
        return null;
      }
      switch (record?.kind) {
        case 'NewNetworkInvite':
          return (
            record?.action?.resourceStatus === 'Pending' && (
              <Button
                type="link"
                size="small"
                onClick={() => onItemAction(record)}
                disabled={record?.read}
              >
                {record?.action?.text || ''}
              </Button>
            )
          );
        case 'InboundShipment':
          return (
            record?.action?.resourceStatus === 'Pending' && (
              <Button
                type="link"
                size="small"
                onClick={() => onItemAction(record)}
                disabled={
                  record?.read ||
                  shouldDisableReceiveNavigation(record?.action?.resource as Shipment)
                }
              >
                {record?.action?.text || ''}
              </Button>
            )
          );

        default:
          return null;
      }
    },
    [onItemAction, shouldDisableReceiveNavigation],
  );

  const listMetas: ProListMetas<NotificationItem> = {
    title: {
      dataIndex: 'message',
      render: (text, record) => (
        <Typography.Paragraph
          className={styles.ntitle}
          ellipsis={{
            rows: 2,
          }}
        >
          {`${getTParamOrReturnBackup(`${record?.message}`, `${record?.messageBackup}`, {
            ...getObjectFromParam(record?.messageParams?.split(';')),
          })} `}
        </Typography.Paragraph>
      ),
    },
    description: {
      dataIndex: 'description',
      render: (text, record) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 3,
          }}
          className={styles.listdesc}
          type="secondary"
        >
          {`${getTParamOrReturnBackup(`${record?.description}`, `${record?.descriptionBackup}`, {
            ...getObjectFromParam(record?.descriptionParams?.split(';')),
          })} `}
        </Typography.Paragraph>
      ),
    },
    actions: {
      render: (text, record) => getAction(record),
    },
  };

  const updateClearNotifications = () => {
    setClearNotificationsControl(true);
    if (setHasNotification) setHasNotification(false);
    onClear();
  };

  const updateSeeMore = () => {
    setSeeMoreControl(true);
    onViewMore();
  };

  return (
    <div>
      <ProList
        itemLayout="horizontal"
        rowKey="id"
        split
        dataSource={records}
        metas={listMetas}
        ghost
        loading={!records?.length ? isLoading : undefined}
        className={styles.list}
        rowClassName={(row: NotificationItem) =>
          classNames(styles.item, {
            [styles.read]: row.read,
          })
        }
        onItem={onItem}
      />
      <div className={styles.bottombar}>
        <Button
          onClick={updateClearNotifications}
          type="text"
          className={styles.footerbtn}
          disabled={clearNotificationsControl}
        >
          {t('clear_notification')}
        </Button>
        <Button
          onClick={updateSeeMore}
          type="text"
          disabled={disableSeeMore}
          className={styles.footerbtn}
          loading={isFetchingNextPage}
        >
          {t('see_more')}
        </Button>
      </div>
    </div>
  );
};
export default React.memo(Notifications);

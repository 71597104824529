import { Empty, Typography } from 'antd';
import { FC } from 'react';
import { EmptyStateProps } from './typings';

const NoShipmentsFound: FC<EmptyStateProps> = ({ t, params, shipmentType, isInbound }) => {
  const getNoDataFoundText = () => {
    const returnText = (
      <Typography.Text type="secondary">{`${t('no_result_found')}`}</Typography.Text>
    );
    const hasFilter =
      (params?.destinationLocationUrns && params?.destinationLocationUrns?.length) ||
      (params?.destinationTradePartnerUrns && params?.destinationTradePartnerUrns?.length) ||
      (params?.shippedToTradePartnerUrn && params?.shippedToTradePartnerUrn?.length) ||
      (params?.shippedFromTradePartnerUrn && params?.shippedFromTradePartnerUrn?.length) ||
      (params?.originLocationUrns && params?.originLocationUrns?.length) ||
      (params?.originTradePartnerUrns && params?.originTradePartnerUrns?.length) ||
      params?.purchaseOrderNumber ||
      params?.purchaseOrder;
    if (hasFilter) {
      return returnText;
    }
    let returnValue = 'no_result_found';
    let firstLine = '';
    firstLine = isInbound ? 'inbound_no_result_found' : 'outbound_shipment_no_result_found';
    const returnValuesData: any = {
      Internal: 'internal_no_result_found',
      Wholechain: 'wholechain_no_result_found',
      OtherSystem: 'other_system_no_result_found',
    };

    if (returnValuesData?.[shipmentType]) {
      returnValue = returnValuesData?.[shipmentType];
    } else {
      returnValue = 'no_result_found';
    }
    return (
      <Typography.Paragraph>
        <Typography.Text type="secondary">{`${t(firstLine)}`}</Typography.Text>
        <br />
        <Typography.Text type="secondary">{`${t(returnValue)}`}</Typography.Text>
      </Typography.Paragraph>
    );
  };

  return (
    <Empty
      className="empty-state"
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={getNoDataFoundText()}
    />
  );
};
export default NoShipmentsFound;

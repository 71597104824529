import { Form, Input, Space, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DrawerFormHeaderProps } from '../typings';
import styles from './index.module.less';

const ViewTemplateDrawerHeader: FC<DrawerFormHeaderProps> = ({
  title,
  form,
  readonly = false,
  templateName,
}) => {
  const { templateId } = useParams();

  const { t } = useTranslation('pages', { keyPrefix: 'templates.add_template' });
  return (
    <Space size={20}>
      <Typography.Title level={4}>
        {title} {readonly && templateName}
      </Typography.Title>
      {!readonly ? (
        <Form form={form} layout="inline">
          <Form.Item
            initialValue={templateName}
            name="name"
            rules={[
              {
                required: true,
                message: t('form_fields.name_required'),
              },
              {
                whitespace: true,
                message: t?.('form_fields.name_required'),
              },
            ]}
          >
            <Input
              placeholder={t('form_fields.name_placeholder')}
              className={styles.templatename}
            />
          </Form.Item>
        </Form>
      ) : (
        <Typography.Text type="secondary">
          {t('template_id')}: {templateId}
        </Typography.Text>
      )}
    </Space>
  );
};
export default ViewTemplateDrawerHeader;

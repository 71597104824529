import ProList, { ProListMetas } from '@ant-design/pro-list';
import { Button, Empty, Typography } from 'antd';
import useModalVisibility from 'hooks/useModalVisibility';
import { InviteShareLocation } from 'pages/Network/forms';
import UpgradeModal from 'pages/Network/forms/AddEdit/UpgradeModal';
import useSubscriptionAction from 'pages/Network/hook/useSubscriptionActions';
import { FC, useMemo, useState } from 'react';
import { useListNetworkInvites } from 'services/api';
import { NetworkInviteResponse } from 'services/api/client/src';
import styles from './index.module.less';
import { InviteProps } from './typings';

const Invites: FC<InviteProps> = ({ t }) => {
  const { data } = useListNetworkInvites({
    incoming: true,
    status: ['Pending'],
  });
  const inviteShareLocModal = useModalVisibility(false);
  const invitations = useMemo(() => data?.results || [], [data]);
  const [inviteRequest, setInviteRequest] = useState<NetworkInviteResponse>({});
  const upgradeModal = useModalVisibility(false);
  const { allowExternalLocation } = useSubscriptionAction();
  const onViewInvite = (record: NetworkInviteResponse) => {
    if (allowExternalLocation) {
      setInviteRequest(record);
      inviteShareLocModal.show();
    } else {
      upgradeModal?.show();
    }
  };

  const listMetas: ProListMetas<NetworkInviteResponse> = {
    title: {
      dataIndex: 'name',
      render: (text, record) => (
        <>
          <Typography.Text className={styles.title} ellipsis>{`${
            record?.companyName || ''
          } `}</Typography.Text>
          {record?.message ? (
            <div>
              <Typography.Paragraph
                className={styles.paragraph}
                type="secondary"
                ellipsis={{ rows: 2, tooltip: true }}
              >
                {`${record?.message || ''} `}
              </Typography.Paragraph>
            </div>
          ) : null}
        </>
      ),
    },
    actions: {
      render: (text, record) => (
        <Button type="link" size="small" onClick={() => onViewInvite(record)}>
          {t('shipment_invite.view_invite')}
        </Button>
      ),
    },
  };
  return (
    <>
      {upgradeModal.visible && <UpgradeModal modal={upgradeModal} tkey="accept_invite." />}
      {inviteShareLocModal.visible && (
        <InviteShareLocation modal={inviteShareLocModal} inviteRequest={inviteRequest} />
      )}
      <ProList
        locale={{
          emptyText: (
            <Empty
              className="empty-state-simple"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('invite.no_data')}
            />
          ),
        }}
        itemLayout="horizontal"
        rowKey="id"
        split
        dataSource={invitations}
        metas={listMetas}
        ghost
      />
    </>
  );
};
export default Invites;

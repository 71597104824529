import ProList, { ProListMetas } from '@ant-design/pro-list';
import { Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Actions from 'components/Actions';
import { TFunction } from 'i18next';
import { FC, useMemo } from 'react';
import styles from './ShareTemplate.module.less';
import { AccessListProps, TradePartnerItem } from './typings';
import useShareTemplateStore from './useShareTemplateStore';

const ActionItems = (t?: TFunction<'components', 'share_template'>): Array<ItemType> => [
  {
    type: 'group',
    key: 'access',
    label: t?.('access'),
    children: [
      {
        key: 'Viewer',
        label: t?.('viewer'),
      },
      {
        key: 'Editor',
        label: t?.('editor'),
      },
    ],
  },
  {
    type: 'group',
    key: 'manage',
    label: t?.('manage'),
    children: [
      {
        key: 'remove',
        label: t?.('delete'),
      },
    ],
  },
];
const AccessList: FC<AccessListProps> = ({ t }) => {
  const { etradePartners, setEtradePartners } = useShareTemplateStore();

  const dataTradePartners = useMemo(
    () => etradePartners.filter((item) => !item.isDeleted),
    [etradePartners],
  );
  const onRowActionClick = (key: string, payload?: TradePartnerItem) => {
    const tradePartner = etradePartners?.find((tp) => tp?.id === payload?.id);

    switch (key) {
      case 'remove':
        if (tradePartner) {
          tradePartner.isUpdated = true;
          tradePartner.isDeleted = true;
          setEtradePartners(
            etradePartners.map((p) => (p.id === tradePartner.id ? tradePartner : p)),
          );
        }
        break;
      case 'Viewer':
        if (tradePartner) {
          tradePartner.isUpdated = true;
          tradePartner.permission = 'Viewer';
          const updatedTradePartners = etradePartners.map((p) =>
            p.id === tradePartner.id ? tradePartner : p,
          );
          setEtradePartners([...updatedTradePartners]);
        }

        break;
      case 'Editor':
        if (tradePartner) {
          tradePartner.isUpdated = true;
          tradePartner.permission = 'Editor';
          setEtradePartners(
            etradePartners.map((p) => (p.id === tradePartner.id ? tradePartner : p)),
          );
        }
        break;

      default:
        break;
    }
  };
  const listMetas: ProListMetas<TradePartnerItem> = {
    title: {
      dataIndex: 'name',
      render: (text) => (
        <Typography.Text ellipsis className={styles.user}>
          {text}
        </Typography.Text>
      ),
    },
    actions: {
      render: (text, record) =>
        !record?.isOwner ? (
          <Actions
            buttonProps={{
              ghost: true,
              size: 'small',
            }}
            items={ActionItems(t)}
            onClick={onRowActionClick}
            actionsText={record?.permission}
            actionPayload={record}
            menuProps={{
              selectable: true,
              defaultSelectedKeys: [record?.permission || 'Viewer'],
              className: styles.actionmenu,
            }}
          />
        ) : (
          <span>{t?.('owner')}</span>
        ),
    },
  };
  return (
    <ProList
      itemLayout="horizontal"
      rowKey="id"
      split
      dataSource={dataTradePartners}
      metas={listMetas}
      ghost
    />
  );
};
export default AccessList;

import { MsalProvider } from '@azure/msal-react';
import { Loading } from 'components';
import { AuthenticationProvider, TranslationProvider } from 'contexts';
import Pages from 'pages';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { msalInstance } from 'services/utils/security';
import './i18n';
import './index.less';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line import/prefer-default-export
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1800000,
    },
    mutations: {
      retry: false,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <TranslationProvider>
          <AuthenticationProvider>
            <Suspense fallback={<Loading />}>
              <Pages />
            </Suspense>
          </AuthenticationProvider>
        </TranslationProvider>
      </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

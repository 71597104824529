import { create } from 'zustand';
import { ShareDocumentStore } from './typings';

const useShareDocumentStore = create<ShareDocumentStore>((set) => ({
  document: undefined,
  documents: undefined,
  tradePartners: [],
  etradePartners: [],
  setTradePartners: (tradePartners) => set({ tradePartners }),
  setEtradePartners: (etradePartners) => set({ etradePartners }),
  setDocument: (document) => set({ document }),
  setDocuments: (documents) => set({ documents }),
}));

export default useShareDocumentStore;

import { ActionType, EditableFormInstance, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import { Typography } from 'antd';
import classNames from 'classnames';
import { ActionButtons, GTable } from 'components';
import React, { FC, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { coreBizStep, coreDispositions } from 'utils';
import useAttributeStore from '../Tables/useAttributeStore';
import { AdvanceSetting, AdvanceSettingsProps, EditableFormProps } from '../typings';
import FormItem from './FormItem';
import styles from './index.module.less';

type ColumnsType = {
  t: TFunction<'pages', 'templates.add_template'>;
  onEditRow: (actionPayload?: AdvanceSetting) => void;
  editableFormRef?: MutableRefObject<EditableFormInstance<EditableFormProps> | undefined>;
  isEdit?: boolean;
};
const columns = ({
  t,
  onEditRow,
  isEdit = true,
}: ColumnsType): Array<ProColumns<AdvanceSetting>> => [
  {
    dataIndex: 'id',
    title: t('form_fields.tbl_col_field_title'),
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    hideInTable: true,
  },
  {
    dataIndex: 'label',
    title: t('form_fields.tbl_col_field_title'),
    fieldProps: {
      size: 'small',
      disabled: true,
    },
  },
  {
    title: t('form_fields.tbl_col_value_title'),
    dataIndex: 'value',
    fieldProps: {
      size: 'small',
    },
    renderFormItem: (item, { record }) => <FormItem record={record} />,
    render: (text, record) => {
      switch (record?.id) {
        case 'bizStep':
          return coreBizStep()?.find?.((el) => el.urn === record.value)?.name || t('default');

        case 'disposition':
          return coreDispositions()?.find?.((el) => el.urn === record.value)?.name || t('default');

        default:
          return t('default');
      }
    },
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    className: 'right-aligned-cell',
    width: '18%',
    render: (text, record) => (
      <ActionButtons showEdit={isEdit} showDelete={false} record={record} onEdit={onEditRow} />
    ),
  },
];
const AdvanceSettings: FC<AdvanceSettingsProps> = ({ form, templateQuery }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates.add_template' });
  const { setFieldsValue, getFieldValue } = form;
  const templateData = templateQuery?.data;
  const templateLoading = templateQuery?.isLoading || false;
  const tactionRef = useRef<ActionType>();
  const { templateMode } = useAttributeStore();
  const isEdit = templateMode === 'edit';
  const attributes: Array<AdvanceSetting> = getFieldValue?.('advanceSettings');
  const setAttributes = useCallback(
    (value: Array<AdvanceSetting>) =>
      setFieldsValue({
        advanceSettings: value,
      }),
    [setFieldsValue],
  );
  const editableFormRef = useRef<EditableFormInstance<any>>();

  useEffect(() => {
    if (templateData) {
      const bizStep = templateData?.templateAttributes?.find(
        (el) => el?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep',
      );
      // const disposition = templateData?.templateAttributes?.find(
      //   (el) => el?.attribute?.fieldProperties?.fields?.propertyName === 'disposition',
      // );
      const bizstepDefaultValue = bizStep?.defaultValue;
      // const dispositionDefaultValue = disposition?.defaultValue;
      setAttributes([
        {
          id: 'bizStep',
          label: t?.('form_fields.advance_settings_feild_bizstep'),
          value: bizstepDefaultValue,
        },
        // {
        //   id: 'disposition',
        //   label: t?.('form_fields.advance_settings_feild_disposition'),
        //   value: dispositionDefaultValue,
        // },
      ]);
    }
  }, [templateData, setAttributes, t]);

  const onEditRow = (actionPayload?: AdvanceSetting) => {
    tactionRef.current?.startEditable(actionPayload?.id || 0);
  };

  const onSaveAttribute = async (rowKey: RecordKey, data: AdvanceSetting) => {
    tactionRef.current?.cancelEditable(data?.id || 0);
    const newAttributes = attributes.map((item) => {
      if (item.id === data.id) {
        return {
          ...item,
          ...data,
        };
      }
      return item;
    });

    setAttributes(newAttributes);
  };

  return (
    <>
      <Typography.Paragraph className={classNames('ant-pro-form-group-title', styles.title)}>
        {t('form_fields.advance_settings_grp_title')}
      </Typography.Paragraph>
      <GTable<AdvanceSetting>
        key="sttribt"
        editableFormRef={editableFormRef}
        actionRef={tactionRef}
        columns={columns({
          t,
          onEditRow,
          isEdit,
        })}
        editable={{
          onSave: (rowKey, data) => onSaveAttribute(rowKey, data),
          onCancel: async (_rowKey, data) => {
            tactionRef.current?.cancelEditable(data?.id || 0);
          },
        }}
        options={{
          reload: false,
          setting: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        recordCreatorProps={false}
        value={attributes}
        loading={templateLoading}
      />
    </>
  );
};
export default React.memo(AdvanceSettings);

import { BetaSchemaForm } from '@ant-design/pro-form';
import { Button, Form } from 'antd';
import { useDrawerVisibility } from 'hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useBizStepOptions } from 'services/api';
import useShipmentStore from '../hooks/useShipmentStore';
import BacthShipFormFields from './BatchShipForm.fields';
import DrawerFormHeader from './DrawerFormHeader';
import { DataItem } from './typings';

const rowProps = { gutter: 32 };

const ViewTicketForm = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.batch_ship' });
  const { visible, closeDrawer, ...restDrawerVisibility } = useDrawerVisibility(true);
  const [mainForm] = Form.useForm();
  const [headerForm] = Form.useForm(mainForm);
  const { hasAdvancedSettings, dataEntryMethod } = useShipmentStore();
  // const { data } = useBatchShipmentById(ticketId);
  const { data: bizStepOptions } = useBizStepOptions();
  // useEffect(() => {
  //   if (data) {
  //     const isMultipleDates = !data?.eventDate;
  //     const values: DataItem = {
  //       ticketId: data?.ticketId || '',
  //       shipFromCompany: data?.origin?.tradePartnerName || undefined,
  //       shipFromAddress: data?.origin?.locationName || undefined,
  //       shipToCompany: data?.destination?.tradePartnerName || undefined,
  //       shipToAddress: data?.destination?.locationName || undefined,
  //       date: data?.eventDate || undefined,
  //       time: data?.eventTime || undefined,
  //       status: data?.status || undefined,
  //       timeZone: data?.eventTimeZone || undefined,
  //       bizStep: data?.bizStep || undefined,
  //       disposition: data?.disposition || undefined,
  //       isMultipleDates,
  //       batchShipProducts: data?.ticketItems?.map((el) => {
  //         const eventTime = el?.eventTime ? dateLocaleFormat(new Date(el?.eventTime)) : undefined;
  //         const date = eventTime ? moment(eventTime).format('YYYY-MM-DD') : undefined;
  //         const time = eventTime ? moment(eventTime).format('HH:mm:ss') : undefined;

  //         return {
  //           id: el?.productId || '',
  //           parentProductId: el?.productId || '',
  //           name: el?.productName || '',
  //           primaryId: el?.instanceId || '',
  //           lotSerial: el?.lotSerial || '',
  //           instanceInventory: el?.instanceInventory || 0,
  //           currentInventory: el?.currentInventory || 0,
  //           quantity: el?.quantity || 0,
  //           logisticId: '',
  //           purchaseOrder: el?.purchaseOrder || '',
  //           dispatchDate: data?.dispatchDate
  //             ? dateLocalFormat(data?.dispatchDate || new Date()) || ''
  //             : undefined,
  //           unitOfMeasure: el?.unitDescriptor,
  //           date,
  //           time,
  //         };
  //       }),
  //     };
  //     setFieldsValue(values);
  //     setDataEntryMethod('manual');
  //   }
  // }, [data, ticketId, setFieldsValue, setDataEntryMethod]);
  const onFinish = async () => {
    closeDrawer();
  };
  const onDone = () => {
    closeDrawer();
  };

  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={BacthShipFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        isViewTicket: true,
        t,
        bizStepOptions,
      })}
      grid
      // @ts-ignore
      title={
        <DrawerFormHeader
          t={t}
          title={t('ticket_id_title')}
          form={headerForm}
          // ticketId={data?.ticketId || ''}
          readonly
        />
      }
      visible={visible}
      rowProps={rowProps}
      autoFocusFirstInput
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        ...restDrawerVisibility,
      }}
      submitter={{
        searchConfig: {
          submitText: 'Ship',
        },
        submitButtonProps: {
          shape: 'round',
        },
        render: () => (
          <Button type="primary" shape="round" ghost onClick={onDone}>
            {t('done')}
          </Button>
        ),
      }}
      submitTimeout={2000}
      onFinish={onFinish}
    />
  );
};
export default React.memo(ViewTicketForm);

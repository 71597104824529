// @ts-nocheck
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProducts, useTradePartners } from 'services/api';
import { GetBatchShipEventsOptionalParams } from 'services/api/client/src';
// import { useBatchShipments } from 'services/api/shipments';

const useBatchShipFilters = (initParams?: GetBatchShipEventsOptionalParams) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.tickets' });
  const [params, setParams] = useState<GetBatchShipEventsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    ...initParams,
  });
  /* prefetch products */
  useProducts();
  /* prefetch product instance quantities */
  // useInstanceQuantities();
  /* prefetch tradepartners */
  useTradePartners();

  // const { data, isLoading, refetch } = useBatchShipments(params);

  // const totalItems = useMemo(() => data?.totalItems || 0, [data]);
  const onSearch = useCallback(
    (text: string, key: string) => {
      setParams({
        ...params,
        pageNumber: 1,
        [key]: text,
      });
    },
    [setParams, params],
  );
  // const records: Array<ShipmentTicketItem> = useMemo(
  //   () =>
  //     data?.results?.map((item) => ({
  //       ...item,
  //       createdDate: item?.createdDate
  //         ? dateLocalFormat(new Date(item?.createdDate || new Date()))
  //         : '',
  //       dispatchDate: item?.dispatchDate ? dateLocalFormat(new Date(item?.dispatchDate)) : '',
  //       recentActivity: dateLocalFormat(new Date()),
  //       expirationDate: dateLocalFormat(new Date()),
  //       children: [
  //         ...(item?.ticketItems?.map((i) => ({
  //           ticketId: ' ',
  //           status: ' ',
  //           totalProducts: i?.productName,
  //           totalLots: i?.lotSerial,
  //           quantity: `${i?.quantity || 0} ${i?.unitDescriptor || 'LBS'}`,
  //         })) || []),
  //       ],
  //     })) || [],
  //   [data],
  // );
  const { filters: productSearchfilter } = useTableSearchFilter({
    title: t('name_filter_title'),
    onSearch: (text) => onSearch(text, 'productName'),
  });
  const { filters: primaryIdSearchfilter } = useTableSearchFilter({
    title: t('primary_id_filter_title'),
    onSearch: (text) => onSearch(text, 'lotId'),
  });
  const { filters: dispatchDateSearchfilter } = useTableSearchFilter({
    title: t('dispatch_date_filter_title'),
    onSearch: (text) => onSearch(text, 'dispatchDate'),
  });
  const { filters: logisticIdSearchfilter } = useTableSearchFilter({
    title: t('logistic_id_filter_title'),
  });

  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, Array<string> | null>,
      sorter: any,
    ) => {
      const apiFilters: GetBatchShipEventsOptionalParams = {
        // @ts-ignore
        status: filters?.status?.length ? filters?.status : undefined,
      };
      if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
        setParams({
          ...params,
          ...apiFilters,
          sortBy: sorter?.field
            ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
            : undefined,
          pageNumber: 1,
        });
      } else {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: pagination?.current,
          pageSize: pagination?.pageSize,
        });
      }
    },
    [params, setParams],
  );

  return {
    // records,
    // totalItems,
    // isLoading,
    params,
    setParams,
    onTableChange,
    // refetch,
    filters: [
      productSearchfilter,
      primaryIdSearchfilter,
      dispatchDateSearchfilter,
      logisticIdSearchfilter,
    ],
  };
};
export default useBatchShipFilters;

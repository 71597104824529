import { useModalVisibility } from 'hooks';
import { InviteShareLocation } from 'pages/Network/forms';
import { useShipmentStore } from 'pages/Shipments';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const InviteModal: FC = () => {
  const { invitePopup, inviteRequest } = useShipmentStore();
  const inviteShareLocModal = useModalVisibility(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (invitePopup) {
      inviteShareLocModal.show();
    } else {
      navigate(-1);
    }
  }, [invitePopup, inviteShareLocModal, navigate]);

  return <InviteShareLocation modal={inviteShareLocModal} inviteRequest={inviteRequest} />;
};
export default InviteModal;

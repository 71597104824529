import { Form, FormInstance } from 'antd';
import { ReceiveProductsDataType } from 'pages/Events/components/Forms/TransformTables/AllReceiveProducts';
import { MultiReceiveProductItem } from '../../Events/components/Forms/AllReceiveForm.fields';

const useWatchValues = (form: FormInstance<any>) => {
  const multiReceiveObjects: MultiReceiveProductItem[] =
    Form?.useWatch('multiReceiveObjects', form) || [];

  const inboundShipments: ReceiveProductsDataType[] =
    Form?.useWatch('inboundShipments', form) || [];

  return {
    multiReceiveObjects,
    inboundShipments,
  };
};

export default useWatchValues;

import { Modal } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEventHistoryCountById } from 'services/api';
import { GetEventDetailsMapOptionalParams } from 'services/api/client/src';

const useEventCountModal = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  const queryClient = useQueryClient();
  const [queryParams] = useSearchParams();
  const modalShown = useRef<boolean>(false);

  const { lotId = '1' } = useParams();
  const eventId = queryParams?.get('eventId') || undefined;
  const isContainer = queryParams?.get('isContainer') || false;
  const options: GetEventDetailsMapOptionalParams = {
    instanceId: !isContainer ? lotId : undefined,
    containerId: isContainer ? lotId : undefined,
  };
  const { data } = useEventHistoryCountById(eventId);
  const maxCount = 100;

  /* effect to show modal */
  useEffect(() => {
    if (data && !modalShown.current && (data?.count || 0) >= maxCount) {
      const mapCache = queryClient.getQueryData(['eventMap', eventId, options]);
      const digramCache = queryClient.getQueryData(['eventDiagram', eventId, options]);

      /* show modal only when map and diagram are not cached */
      if (!!mapCache && !!digramCache) return;

      modalShown.current = true;
      Modal.warning({
        title: t?.('large_supply_chain_title'),
        content: t?.('large_supply_chain_desc'),
        okText: t?.('large_supply_chain_confirm'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
          hidden: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        okCancel: true,
        onOk: () => Modal.destroyAll(),
        // have the highest z-index
        zIndex: 9999,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, t, eventId, options]);

  return {
    data,
  };
};
export default useEventCountModal;

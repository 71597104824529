import { CSVTemplate } from 'utils';

export const commissionTemplate: CSVTemplate = {
  productId: {
    title: 'Product',
    dataIndex: 'productId',
  },
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
  },
  quantity: {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  location: {
    title: 'Location',
    dataIndex: 'location',
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
  },
  scss: {
    title: 'Logistic ID/SSCC',
    dataIndex: 'scss',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
  },
};
export const observeTemplate: CSVTemplate = {
  productId: {
    title: 'Product',
    dataIndex: 'productId',
  },
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
  },
  scss: {
    title: 'Logistic ID/SSCC',
    dataIndex: 'scss',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
  },
  location: {
    title: 'Location',
    dataIndex: 'location',
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
  },
};
export const receiveTemplate: CSVTemplate = {
  productId: {
    title: 'Product',
    dataIndex: 'productId',
  },
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
  },
  location: {
    title: 'Location',
    dataIndex: 'location',
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
  },
  scss: {
    title: 'Logistic ID/SSCC',
    dataIndex: 'scss',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
  },
};
export const transformTemplate: CSVTemplate = {
  productId: {
    title: 'Product',
    dataIndex: 'productId',
  },
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
  },
  scss: {
    title: 'Logistic ID/SSCC',
    dataIndex: 'scss',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
  },
  location: {
    title: 'Location',
    dataIndex: 'location',
  },
};
export const decomissionTemplate: CSVTemplate = {
  productId: {
    title: 'Product',
    dataIndex: 'productId',
  },
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
  },
  location: {
    title: 'Location',
    dataIndex: 'location',
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
  },
  scss: {
    title: 'Logistic ID/SSCC',
    dataIndex: 'scss',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
  },
};
export const shipTemplate: CSVTemplate = {
  productId: {
    title: 'Product',
    dataIndex: 'productId',
  },
  lotID: {
    title: 'Lot Number',
    dataIndex: 'lotID',
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
  },
  scss: {
    title: 'Logistic ID/SSCC',
    dataIndex: 'scss',
  },
  date: {
    title: 'Date',
    dataIndex: 'date',
  },
  time: {
    title: 'Time',
    dataIndex: 'time',
  },
  location: {
    title: 'Location',
    dataIndex: 'location',
  },
  shippingCompany: {
    title: 'Ship To Company',
    dataIndex: 'shippingCompany',
  },
  shippingAddress: {
    title: 'Ship To Address',
    dataIndex: 'shippingAddress',
  },
  bizStep: {
    title: 'Business Step',
    dataIndex: 'bizStep',
  },
  disposition: {
    title: 'Disposition',
    dataIndex: 'disposition',
  },
  timeZone: {
    title: 'Time Zone',
    dataIndex: 'timeZone',
  },
};
export const batchShipTemplate: CSVTemplate = {
  name: {
    title: 'Product Name',
    dataIndex: 'name',
  },
  primaryId: {
    title: 'Lot Number',
    dataIndex: 'primaryId',
  },
  poNumber: {
    title: 'Purchase Order',
    dataIndex: 'poNumber',
  },
  scss: {
    title: 'Logistic ID/SSCC',
    dataIndex: 'scss',
  },
  quantity: {
    title: 'Quantity (units)',
    dataIndex: 'quantity',
  },
};

import type { ActionType, ListToolBarProps, ProColumns } from '@ant-design/pro-table';
import { Button, TablePaginationConfig } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import { Actions } from 'components';
import GTable from 'components/GTable';
import { UseModalVisibilityReturnType } from 'hooks/useModalVisibility';
import { useEventsStore } from 'pages/Events';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  GetPaginatedInboundShipmentsOptionalParams,
  InboundShipmentResponse,
} from 'services/api/client/src';
import { useInboundShipments } from 'services/api/newShipments';
import { dateLocaleFormat } from 'utils';
import useShipmentStore from '../../Shipments/hooks';
import useNewShipmentActions from '../hooks/useNewShipmentActions';
import NoShipmentsFound from '../NoShipmentsFound';
import ShipmentToolbar from '../ShipmentToolbar';
import { InboundInternalShipmentProps } from '../typings';
import InboundShipmentTableRowData from './InboundShipmentTableRowData';
import styles from './index.module.less';
import ShipmentDetailsTable from './ShipmentDetailsTable';

type RowActionsType = {
  t?: TFunction<'pages', 'shipments'>;
  inboundShipmentType?: string;
};
const rowActionItems = ({ t, inboundShipmentType }: RowActionsType): Array<ItemType> => [
  {
    key: 'preview_shipment',
    label: t?.('preview_shipment'),
  },
  {
    key: 'receive',
    label: t?.('receive'),
  },
  ...(inboundShipmentType === 'Internal'
    ? [{ key: 'remove_event', label: t?.('remove_event') }]
    : []),
  /*  {
    key: 'reject',
    label: t?.('reject'),
  }, */
];

type ColumnsType = {
  t?: TFunction<'pages', 'products'>;
  supportModal?: UseModalVisibilityReturnType;
  productNameSearchfilter?: ProColumns;
  onRowActionClick: (actionItemKey: string, record?: InboundShipmentResponse) => void;
  isNetworkShip?: boolean;
  inboundShipmentType?: string;
  considerAsOutbound?: boolean;
};

const columns = ({
  t,
  productNameSearchfilter,
  onRowActionClick,
  isNetworkShip,
  inboundShipmentType,
  considerAsOutbound,
}: ColumnsType): Array<ProColumns<InboundShipmentResponse>> => [
  {
    dataIndex: 'shipmentId',
    hideInTable: true,
  },
  {
    title: t?.('col_product'),
    dataIndex: 'originCompanyName',
    hideInSetting: true,
    render: (text, record) => (
      <InboundShipmentTableRowData
        record={record}
        showStatus={inboundShipmentType === 'OtherSystem' ? false : considerAsOutbound}
      />
    ),
    ellipsis: true,
    ...productNameSearchfilter,
  },
  {
    dataIndex: 'networkActions',
    width: 180,
    hideInTable: !isNetworkShip,
    render: (text, record) =>
      record?.shipmentId ? (
        <Button
          className="actions"
          type="link"
          onClick={() => onRowActionClick('preview_shipment', record)}
        >
          {considerAsOutbound ? `${t?.('view_shipment')}` : `${t?.('preview_shipment')}`}
        </Button>
      ) : undefined,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    width: 120,
    hideInSetting: true,
    hideInTable: isNetworkShip,
    fixed: 'right',
    render: (text, record) =>
      record?.shipmentId ? (
        <Actions
          className="actions"
          buttonProps={{
            ghost: true,
            size: 'small',
            type: 'link',
          }}
          items={rowActionItems({ t, inboundShipmentType })}
          onClick={onRowActionClick}
          actionPayload={record}
        />
      ) : undefined,
  },
];

const InboundInternalShipments: FC<InboundInternalShipmentProps> = ({
  selectedRowKeys,
  onRowSelectionChange,
  inboundShipmentType,
  showFilter,
  isNetworkShip,
  inboundShipParam,
  considerAsOutbound,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'global_search.shipments_search_table' });
  const { setSelectedShipments } = useShipmentStore();
  const { setAdvancedSettings, setDataEntryMethod, setTemplate } = useEventsStore();
  const navigate = useNavigate();
  const actionRef = useRef<ActionType>();
  const [params, setParams] = useState<GetPaginatedInboundShipmentsOptionalParams>({
    pageNumber: 1,
    pageSize: 10,
    sortBy: '-eventDate',
    ...(!isNetworkShip && { status: ['Pending'] }),
    inboundShipmentType,
    ...inboundShipParam,
  });
  const { data, isLoading } = useInboundShipments(params);
  const { onShowErrorExternalShipmentDialog, onRemoveEvent } = useNewShipmentActions();

  const expandedRowRender = (row: InboundShipmentResponse) => (
    <ShipmentDetailsTable shipmentId={row?.shipmentId} />
  );

  const onTableChange = (pagination: TablePaginationConfig, sorter: any) => {
    if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
      setParams({
        ...params,
        sortBy: sorter?.field
          ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
          : undefined,
        pageNumber: 1,
      });
    } else {
      setParams({
        ...params,
        pageNumber: pagination?.current,
        pageSize: pagination?.pageSize,
      });
    }
  };

  const onViewEvent = useCallback(
    (record?: InboundShipmentResponse) => {
      if (record?.shipmentStatus === 'Error') {
        onShowErrorExternalShipmentDialog();
      } else {
        const productsSearchParams = createSearchParams({
          shipmentId: record?.shipmentId || '',
          shipmentType: inboundShipmentType || '',
          tradePartnerUrn: record?.destinationCompanyUrn || '',
          locationUrn: record?.destinationLocationUrn || '',
          locationId: record?.destinationLocationId || '',
          eventDate: dateLocaleFormat(record?.eventDate) || '',
        });
        // sets signle shippment details in store
        setSelectedShipments([record as InboundShipmentResponse]);
        navigate({
          pathname: `/products/${record?.shipmentId}/events/receive`,
          search: record ? `?${productsSearchParams}` : undefined,
        });
      }
    },
    [inboundShipmentType, navigate, onShowErrorExternalShipmentDialog, setSelectedShipments],
  );

  const onPreviewShip = useCallback(
    (record?: InboundShipmentResponse) => {
      const previewSearchParams = createSearchParams({
        name: record?.originCompanyName || '',
        hideDocument: String(inboundShipmentType === 'Wholechain' || 'false'),
      });
      // sets signle shippment details in store
      setSelectedShipments([record as InboundShipmentResponse]);
      navigate({
        pathname: isNetworkShip
          ? `../../../network/shipments/inbound/${record?.shipmentId}/eventDetails`
          : `./${record?.shipmentId}/eventDetails`,
        search: record ? `?${previewSearchParams}` : undefined,
      });
    },
    [inboundShipmentType, isNetworkShip, navigate, setSelectedShipments],
  );

  const onRowActionClick = useCallback(
    (actionItemKey: string, actionPayload?: InboundShipmentResponse) => {
      switch (actionItemKey) {
        case 'receive':
          onViewEvent(actionPayload);
          break;

        /*  case 'reject':
          break; */

        case 'preview_shipment':
          onPreviewShip(actionPayload);
          break;

        case 'remove_event':
          onRemoveEvent(actionPayload);
          break;

        default:
          break;
      }
      setAdvancedSettings(false);
      setDataEntryMethod('manual');
      setTemplate(undefined);
    },
    [
      onPreviewShip,
      onRemoveEvent,
      onViewEvent,
      setAdvancedSettings,
      setDataEntryMethod,
      setTemplate,
    ],
  );

  useEffect(() => {
    if (!showFilter) {
      setParams({
        pageNumber: 1,
        pageSize: 10,
        sortBy: '-eventDate',
        ...(!isNetworkShip && { status: ['Pending'] }),
        inboundShipmentType,
        ...inboundShipParam,
      });
    }
  }, [inboundShipParam, inboundShipmentType, isNetworkShip, showFilter]);

  const toolBarRender: ListToolBarProps = useMemo(
    () =>
      showFilter
        ? {
            multipleLine: true,
            filter: (
              <ShipmentToolbar
                t={t}
                originLocationUrn={params.originLocationUrns}
                purchaseOrderNumber={params.purchaseOrderNumber}
                originTradePartnerUrn={params.originTradePartnerUrns}
                onLocationChange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    originLocationUrns: value,
                  })
                }
                onTradePartnerChange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    originTradePartnerUrns: value,
                  })
                }
                onPOchange={(value) =>
                  setParams({
                    ...params,
                    pageNumber: 1,
                    pageSize: params?.pageSize,
                    purchaseOrderNumber: value,
                  })
                }
                tradePartnerParam={{
                  types:
                    inboundShipmentType === 'Wholechain'
                      ? ['Wholechain']
                      : ['Self', 'NonWholechain'],
                }}
                locationParam={{
                  tradePartnerStatus:
                    inboundShipmentType === 'Wholechain'
                      ? ['Wholechain']
                      : ['Self', 'NonWholechain'],
                }}
              />
            ),
          }
        : {},
    [showFilter, t, params, inboundShipmentType],
  );
  return (
    <GTable<InboundShipmentResponse>
      actionRef={actionRef}
      showHeader={false}
      {...(isNetworkShip && { className: classNames(styles.table) })}
      scroll={isNetworkShip ? { x: 1025, y: 160 } : { x: 1025 }}
      rowKey="shipmentId"
      columns={columns({
        t,
        onRowActionClick,
        isNetworkShip,
        inboundShipmentType,
        considerAsOutbound,
      })}
      columnsState={{
        persistenceKey: 'pages-products1',
      }}
      loading={isLoading}
      value={data?.results}
      {...(!isNetworkShip && { enableRowSelection: true })}
      recordCreatorProps={false}
      pagination={{
        defaultPageSize: params.pageSize,
        current: params?.pageNumber,
        total: data?.totalItems,
        showTotal: (total) =>
          total <= 1
            ? t('search_table_pagi_shipment', { total })
            : t('search_table_pagi', { total }),
      }}
      {...(!isNetworkShip && {
        rowSelection: {
          selectedRowKeys,
          onChange: onRowSelectionChange,
          columnWidth: 30,
          preserveSelectedRowKeys: true,
        },
      })}
      onTableChange={onTableChange}
      {...(!isNetworkShip && { expandable: { expandedRowRender, defaultExpandedRowKeys: ['0'] } })}
      options={{
        setting: false,
        reload: false,
      }}
      toolbar={showFilter ? toolBarRender : undefined}
      emptyContainer={
        <NoShipmentsFound
          t={t}
          params={params}
          shipmentType={inboundShipmentType || ''}
          isInbound
        />
      }
    />
  );
};

export default React.memo(InboundInternalShipments);

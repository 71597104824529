import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/es/useEditableArray';
import { Alert, Modal, Space, Typography } from 'antd';
import { FormInstance, useWatch } from 'antd/lib/form/Form';
import { ActionButtons, GTable, ProductSelect } from 'components';
import { useShipmentStore } from 'pages/Shipments';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  ContainerProductInstanceResponse,
  ProductInstanceResponse,
  ProductResponse,
} from 'services/api/client/src';
import { getProductsT, getUom, hiddencol } from 'utils';
import { AllReceiveProductsProps } from './typings';

const { Text } = Typography;

export type ReceiveProductsDataType = {
  [x: string]: any;
  id?: string;
  instanceId?: string;
  parentProductId?: string;
  productName?: string;
  primaryId?: string;
  lotSerial?: string;
  quantity?: number;
  instanceQuantity?: number;
  unitOfMeasure?: string;
  currentInventory?: number;
  currentInventoryTotal?: number;
  productUrn?: string;
  isSerial?: boolean;
  isSSCC?: boolean;
  productIdentifierType?: string;
  parentContainerId?: string;
  shipmentType?: string;
  originalInstance?: ProductInstanceResponse;
};

export type AllReceiveTableColumnsType = {
  onEditRow: (actionPayload?: ReceiveProductsDataType) => void;
  lotChangeModal?: (value: string, rowKey: string, tblForm: FormInstance<any>) => void;
  quantityChangeModal?: (value: string, rowKey: string, tblForm: FormInstance<any>) => void;
};

// Receive Products Table Columns
export const columns = ({
  onEditRow,
  lotChangeModal,
  quantityChangeModal,
}: AllReceiveTableColumnsType): Array<ProColumns<ReceiveProductsDataType>> => [
  {
    title: 'Product',
    dataIndex: 'parentProductId',
    valueType: 'select',
    hideInForm: true,
    fieldProps: {
      size: 'small',
      disabled: true,
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (_, { record, recordKey }, form) => {
      const isInternal = record?.shipmentType === 'Internal';
      const { setFieldsValue } = form;
      return (
        <ProductSelect
          allowLotSerial={record?.productIdentifierType === undefined}
          isSerial={record?.productIdentifierType === 'Serial'}
          size="small"
          disabled={isInternal}
          filterItem={(item) => item?.id !== record?.originalInstance?.product?.id}
          isAddProduct
          prependOption={{
            label: `${record?.originalInstance?.product?.name || ''}`,
            value: record?.originalInstance?.product?.id,
            itemProps: {
              ...record?.originalInstance?.product,
            },
          }}
          onChange={(value, option) => {
            // @ts-ignore
            const item: ProductResponse = option.itemProps;
            setFieldsValue({
              [String(recordKey)]: {
                parentProductId: item?.id,
                productName: item?.name || '',
                isAssignedProduct: true,
                // resets values of row items being edited
                primaryId: undefined,
                quantity: undefined,
                currentInventory: item?.currentInventory,
                currentInventoryTotal: item?.currentInventoryTotal,
                instanceQuantity: item?.currentInventoryTotal,
                unitOfMeasure: item?.simpleUnitOfMeasurement || '',
                productIdentifierType: item?.productIdentifierType,
              },
            });
          }}
        />
      );
    },
    render: (_t, record) => record?.productName,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: (form, { rowKey }) => {
      const unitOfMeasure = form?.getFieldValue?.(String(rowKey))?.unitOfMeasure || 'LBS';
      const productIdentifierType =
        form?.getFieldValue?.(String(rowKey))?.productIdentifierType || 'Lot';
      return {
        addonAfter:
          productIdentifierType === 'Serial' ? 'items' : `${unitOfMeasure?.toLocaleUpperCase()}`,
        size: 'small',
        stringMode: true,
        type: 'number',
        style: {
          width: '100%',
        },
        onBlur: (e: any) => quantityChangeModal?.(e.target.value, String(rowKey), form),
        disabled: productIdentifierType === 'Serial',
        placeholder: '0',
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    render: (text, record) =>
      record?.productIdentifierType === 'Serial'
        ? `${record?.quantity} ${pluralize('item', record?.quantity)}`
        : `${record?.quantity} ${record?.unitOfMeasure?.toLocaleUpperCase() || ''}`,
  },
  {
    title: 'Primary ID',
    dataIndex: 'primaryId',
    valueType: 'text',
    fieldProps: (form, { rowKey }) => {
      const productIdentifierType = form?.getFieldValue?.(String(rowKey))?.productIdentifierType;
      return {
        addonBefore: `${productIdentifierType}:` || 'Lot: ',
        size: 'small',
        onBlur: (e: any) => lotChangeModal?.(e.target.value, String(rowKey), form),
        placeholder: productIdentifierType,
      };
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: 'Required',
        },
      ],
      hasFeedback: false,
    },
    render: (_t, record) => `${record?.productIdentifierType}: ${record?.primaryId}`,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    align: 'center',
    width: 160,
    render: (_, record) => (
      <ActionButtons
        record={record}
        onEdit={onEditRow}
        showDelete={false}
        showEdit={!record?.isSSCC}
      />
    ),
  },
  {
    dataIndex: 'eventDate',
    ...hiddencol,
  },
  {
    dataIndex: 'instanceQuantity',
    ...hiddencol,
  },
  {
    dataIndex: 'unitOfMeasure',
    ...hiddencol,
  },
  {
    dataIndex: 'productIdentifierType',
    ...hiddencol,
  },
];

const AllReceiveProducts: React.FC<AllReceiveProductsProps> = ({
  form,
  eventProducts,
  isLoading,
}) => {
  const actionRef = useRef<ActionType>();
  const [queryParams] = useSearchParams();
  const { t } = useTranslation('pages', { keyPrefix: 'events.recieve.form_fields' });
  const { setFieldsValue } = form;
  const [fetched, setFetched] = useState(false);

  const { selectedShipments } = useShipmentStore();
  const shipmentType = queryParams?.get('shipmentType');

  // Receive Products table data
  const receiveProducts: ReceiveProductsDataType[] = useWatch('inboundShipments', form) || [];
  // function to update receive products table data
  const SetReceiveProducts = useCallback(
    (value: ReceiveProductsDataType[]) => {
      setFieldsValue({
        inboundShipments: value,
      });
    },
    [setFieldsValue],
  );

  // find total quantity in all lots of sscc
  const findTotalSSCCQuantity = useCallback((p: ContainerProductInstanceResponse[]) => {
    let total = 0;
    for (let i = 0; i <= Number(p?.length) - 1 || 0; i += 1) {
      total = total + Number(p?.[i]?.quantity) || 0;
    }
    return total;
  }, []);

  // modified list as per our requirement
  const modifiedProducts: ReceiveProductsDataType[] | undefined = useMemo(
    () =>
      (eventProducts?.productInstances?.length || 0) > 0
        ? eventProducts?.productInstances?.map((i) => ({
            id: i?.id,
            instanceId: i?.id,
            quantity: i?.quantity,
            instanceQuantity: i?.quantity,
            // initial product id -> either and id in case of internal otherwise '' -> to keep track of original product id
            productId: i?.product?.id,
            parentProductId: i?.product?.id,
            primaryId: i?.lotSerial,
            lotSerial: i?.lotSerial,
            productName: i?.product?.name,
            unitOfMeasure: getUom({ product: i?.product }) || 'LBS',
            isSerial: i?.product?.productIdentifierType === 'Serial',
            currentInventory: i?.product?.currentInventory,
            currentInventoryTotal: i?.product?.currentInventoryTotal,
            isSSCC: false,
            productUrn: i?.product?.urn,
            productIdentifierType: i?.product?.productIdentifierType,
            shipmentType: queryParams?.get('shipmentType') || '',
            instanceUrn: i?.urn,
            originalInstance: i,
          }))
        : eventProducts?.containers?.map((c) => ({
            id: c?.id,
            parentProductId: 'SSCC',
            primaryId: c?.containerIdentifier,
            quantity: findTotalSSCCQuantity(c?.productInstances || []),
            instanceQuantity: findTotalSSCCQuantity(c?.productInstances || []),
            isSSCC: true,
            productName: c?.productInstances?.find(
              (j) => j?.productId !== c?.productInstances?.[0]?.productId,
              [],
            )
              ? 'SSCC: Multiple Products'
              : `SSCC: ${c?.productInstances?.[0]?.productName}`,
            productIdentifierType: 'SSCC',
            unitOfMeasure: getUom({ product: c }) || 'LBS',
            shipmentType: queryParams?.get('shipmentType') || '',
            instanceUrn: c?.urn,
            ...(c.productInstances?.length && {
              children: [
                ...(c.productInstances?.map((i) => ({
                  id: i?.id,
                  parentContainerId: c?.id,
                  // initial value
                  productId: i?.productId,
                  parentProductId: i?.productId,
                  productName: i?.productName,
                  instanceId: i?.id || '',
                  primaryId: i?.lotSerial || '',
                  lotSerial: i?.lotSerial || '',
                  quantity: Number(i?.quantity || 0),
                  instanceQuantity: Number(i?.quantity || 0),
                  unitOfMeasure: getUom({ product: i }) || 'LBS',
                  unitQuantity: i?.unitQuantity,
                  unitDescriptor: i?.unitDescriptor,
                  productIdentifierType: i?.productIdentifierType || 'Lot',
                  shipmentType: queryParams?.get('shipmentType') || '',
                  originalInstance: {
                    ...i,
                    product: {
                      id: i?.productId,
                      name: i?.productName,
                      unitQuantity: i?.unitQuantity,
                      unitDescriptor: i?.unitDescriptor,
                      simpleUnitOfMeasurement: getUom({ product: i }) || 'LBS' || '',
                      productIdentifierType: i?.productIdentifierType,
                    },
                  },
                })) || []),
              ],
            }),
          })),
    [
      eventProducts?.containers,
      eventProducts?.productInstances,
      findTotalSSCCQuantity,
      queryParams,
    ],
  );

  useEffect(() => {
    if (modifiedProducts?.length && !fetched && eventProducts) {
      SetReceiveProducts(modifiedProducts);
      setFetched(true);
    }
  }, [modifiedProducts, fetched, SetReceiveProducts, eventProducts]);

  // Handles on save button click
  const onSaveRow = async (_rowKey: RecordKey, data: ReceiveProductsDataType) => {
    actionRef.current?.cancelEditable(data?.id || 0);
    const existingProduct = receiveProducts.find((product) => product.id === data?.id);
    const existingSsccParent = receiveProducts.find(
      (product) => product.id === data?.parentContainerId,
    );

    if (existingProduct) {
      existingProduct.productName = data?.productName || '';
      existingProduct.productId = data?.productId || '';
      existingProduct.primaryId = data?.primaryId || '';
      existingProduct.parentProductId = data?.parentProductId?.trim() || undefined;
      existingProduct.quantity = data?.quantity;
      existingProduct.currentInventory = data?.currentInventory;
      existingProduct.instanceQuantity = data?.currentInventoryTotal;
      existingProduct.unitOfMeasure = data?.unitOfMeasure || '';

      // update product
      SetReceiveProducts(
        receiveProducts.map((p) => {
          if (p.id === existingProduct.id) {
            return existingProduct;
          }
          if (p?.productId === existingProduct?.productId) {
            return {
              ...p,
              parentProductId: data?.parentProductId,
              productName: data?.productName,
            };
          }
          return p;
        }, []),
      );
    } else if (existingSsccParent) {
      const changedChildren = existingSsccParent?.children?.find((c: any) => c?.id === data?.id);
      const updatedChildren = {
        ...changedChildren,
        id: changedChildren?.id,
        parentContainerId: existingSsccParent?.id,
        parentProductId: data?.parentProductId,
        productName: data?.productName,
        primaryId: data?.primaryId || '',
        quantity: Number(data?.quantity || 0),
        instanceQuantity: Number(data?.currentInventoryTotal || 0),
      };

      SetReceiveProducts(
        receiveProducts.map((p) => {
          if (p.id === existingSsccParent.id) {
            const children = existingSsccParent?.children?.map((child: any) => {
              if (child?.id !== changedChildren?.id) {
                if (child?.productId === changedChildren?.productId) {
                  return {
                    ...child,
                    parentProductId: data?.parentProductId,
                    productName: data?.productName,
                  };
                }
                return child;
              }
              return updatedChildren;
            }, []);

            return {
              ...existingSsccParent,
              quantity: findTotalSSCCQuantity(children || []),
              productName: children?.find(
                (i: any) => i?.parentProductId !== children?.[0]?.parentProductId,
                [],
              )
                ? 'SSCC: Multiple Products'
                : `SSCC: ${children?.[0]?.productName}`,
              children,
            };
          }
          const children = p?.children?.map((child: any) => {
            if (child?.productId === changedChildren?.productId) {
              return {
                ...child,
                parentProductId: data?.parentProductId,
                productName: data?.productName,
              };
            }
            return child;
          }, []);
          return {
            ...p,
            children,
            productName: children?.find(
              (i: any) => i?.parentProductId !== children?.[0]?.parentProductId,
              [],
            )
              ? 'SSCC: Multiple Products'
              : `SSCC: ${children?.[0]?.productName}`,
          };
        }, []),
      );
    }
  };

  // handles edit click on row
  const onEditRow = (actionPayload?: ReceiveProductsDataType) => {
    actionRef.current?.startEditable(actionPayload?.id || 0);
  };

  const onCancelProduct = async (rowKey: RecordKey, data: ReceiveProductsDataType) => {
    actionRef.current?.cancelEditable(data?.id || 0);
  };

  // lot change modal
  const lotChangeModal = (value: string, rowKey: string, tblForm: FormInstance<any>) => {
    const product = modifiedProducts?.find((item) => item?.instanceId === rowKey);
    let ssccChildren: ReceiveProductsDataType = {};
    for (let i = 0; i <= (modifiedProducts?.length || 1) - 1; i += 1) {
      ssccChildren = modifiedProducts?.[i]?.children?.find((item: any) => item?.id === rowKey);
    }
    const isSerial = product?.productIdentifierType === 'Serial';
    const lotSerial = product?.primaryId || '';
    const ssccChildLotSerial = ssccChildren?.primaryId || '';
    if ((product ? value !== lotSerial : value !== ssccChildLotSerial) && value) {
      Modal.warning({
        title: !isSerial
          ? t?.('lot_change_modal_title', {
              identifier: getProductsT(product?.productIdentifierType),
            })
          : `Changing the Serial ID will Transform this item in the selected product`,
        content: `${isSerial ? 'Serial ID' : 'Lot ID'} “${
          lotSerial || ssccChildLotSerial
        }” will be changed to “${value}”. This is the ${
          isSerial ? 'Serial ID' : 'Lot ID'
        } that will appear in your inventory.`,
        cancelText: t('lot_change_cancel_btn'),
        okText: t('lot_change_continue_btn'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        onCancel: () => {
          tblForm?.setFieldsValue({
            [String(rowKey)]: {
              primaryId: lotSerial || ssccChildLotSerial || '',
            },
          });
        },
        okCancel: true,
      });
    }
  };

  // quantity change modal
  const quantityChangeModal = (value: string, rowKey: string, tblForm: FormInstance<any>) => {
    const product = modifiedProducts?.find((item) => item?.id === rowKey);
    let ssccChildren: ReceiveProductsDataType = {};
    for (let i = 0; i <= (modifiedProducts?.length || 1) - 1; i += 1) {
      ssccChildren = modifiedProducts?.[i]?.children?.find((item: any) => item?.id === rowKey);
    }
    const quantity = product?.quantity || 0;
    const ssccChildQuantity = ssccChildren?.quantity || '';
    const isQuantityChangeModalTriggered = tblForm?.getFieldValue(
      String(rowKey),
    )?.isQuantityChangeModalTriggered;

    if (
      (product ? Number(value) !== quantity : value !== ssccChildQuantity) &&
      value &&
      !isQuantityChangeModalTriggered
    ) {
      Modal.info({
        title:
          'Changing the Quantity will create an observe event for this item in the selected product',
        content: `Quantity “${
          quantity || ssccChildQuantity
        }” will be changed to "${value}". This will log an observe event after you log the receive.`,
        cancelText: t('quantity_change_cancel_btn'),
        okText: t('quantity_change_continue_btn'),
        cancelButtonProps: {
          type: 'primary',
          shape: 'round',
          ghost: true,
        },
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        centered: true,
        onCancel: () => {
          tblForm?.setFieldsValue({
            [String(rowKey)]: {
              quantity: quantity || ssccChildQuantity || 0,
              isQuantityChangeModalTriggered: true,
            },
          });
        },
        onOk: () => {
          tblForm?.setFieldsValue({
            [String(rowKey)]: {
              isQuantityChangeModalTriggered: true,
            },
          });
        },
        okCancel: true,
      });
    }
  };

  return (
    <Space direction="vertical">
      <>
        <Space direction="vertical">
          <Text strong>{selectedShipments?.[0]?.originCompanyName}</Text>
          <Text>Location: {selectedShipments?.[0]?.originLocationName}</Text>
          {eventProducts?.hasNewProducts && shipmentType === 'Wholechain' && (
            <Alert
              message="This shipment contains products that you do not currently have in your account. Receiving as is will add this product into your account or you can assign an alias."
              type="info"
              showIcon
              closable
            />
          )}
        </Space>
        <GTable<ReceiveProductsDataType>
          actionRef={actionRef}
          key="receiveProductsTable"
          rowKey="id"
          columns={columns({ onEditRow, lotChangeModal, quantityChangeModal })}
          value={receiveProducts}
          loading={isLoading}
          editable={{
            onSave: (rowKey, actionPayload) => onSaveRow(rowKey, actionPayload),
            onCancel: async (rowKey, oldData, data) => onCancelProduct(rowKey, data),
          }}
          options={{
            setting: false,
            reload: false,
          }}
        />
      </>
    </Space>
  );
};

export default AllReceiveProducts;

import { Tag } from 'antd';
import React, { FC } from 'react';
import { getEventsT, getProductsT } from 'utils';
import styles from '../index.module.less';
import { DataTitleProps } from '../typings';

const DataTitle: FC<DataTitleProps> = ({ activeEvent, identifier }) => (
  <div className={styles.mrb10}>
    <Tag className="permission-tag">{getEventsT(activeEvent?.eventType || 'Commission')}</Tag>
    {((activeEvent?.containers?.length || 0) > 0 ||
      (activeEvent?.productInstances?.length || 0) === 1) && (
      <Tag className="permission-tag">
        {!!activeEvent?.containers?.[0]?.containerIdentifier ||
        (activeEvent?.containers?.length || 0) > 0
          ? `${getProductsT('sscc')}: `
          : `${identifier}: `}
        {!!activeEvent?.containers?.[0]?.containerIdentifier ||
        (activeEvent?.containers?.length || 0) > 0
          ? activeEvent?.containers?.[0]?.containerIdentifier ||
            activeEvent?.containers?.[0]?.containerIdentifier ||
            ''
          : activeEvent?.productInstances?.[0]?.lotSerial ||
            activeEvent?.productInstances?.[0]?.lotSerial ||
            ''}
      </Tag>
    )}
  </div>
);
export default React.memo(DataTitle);

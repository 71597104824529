import ProList, { ProListMetas } from '@ant-design/pro-list';
import { Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Actions from 'components/Actions';
import { UserItem } from 'components/Settings';
import { useAuthenticationContext } from 'contexts';
import React, { FC, useEffect, useMemo } from 'react';
import { useUsers } from 'services/api';
import { v4 as uuidv4 } from 'uuid';
import styles from './Invite.module.less';
import { AccessListProps, ActionItemProps } from './typings';
import useInviteStore from './useInviteStore';

const ActionItems = ({ record, currentUser, t }: ActionItemProps): Array<ItemType> => [
  {
    type: 'group',
    key: 'role',
    label: t?.('role'),
    children: [
      {
        key: 'Admin',
        label: t?.('admin'),
        disabled: !record.userId,
      },
      {
        key: 'Employee',
        label: t?.('employee'),
        disabled: !record.userId,
      },
    ],
  },
  {
    type: 'group',
    key: 'manage',
    label: t?.('manage'),
    children: [
      {
        key: 'remove',
        label: t?.('remove'),
        disabled: !record?.inviteId
          ? !record.userId || (record?.userId || '') === (currentUser?.id || '')
          : false,
      },
    ],
  },
];
const AccessList: FC<AccessListProps> = ({ t }) => {
  const { data, isLoading } = useUsers();
  const { currentUser } = useAuthenticationContext();
  const { users, setUsers } = useInviteStore();

  useEffect(() => {
    if (data) {
      setUsers(
        data?.results?.map((item) => ({
          ...item,
          id: item?.userId,
          uniqueId: uuidv4(),
          isUpdated: false,
          isDeleted: false,
        })) || [],
      );
    }
  }, [data, setUsers]);

  const dataUsers = useMemo(() => users?.filter((item) => !item.isDeleted) || [], [users]);

  const onRowActionClick = async (key: string, payload?: UserItem) => {
    if (payload) {
      const user = users?.find((item) => item.uniqueId === payload.uniqueId);
      switch (key) {
        case 'remove':
          if (user) {
            user.isUpdated = true;
            user.isDeleted = true;
            setUsers(users?.map((p) => (p.uniqueId === user.uniqueId ? user : p) || []));
          }

          break;
        case 'Admin':
          if (user) {
            user.isUpdated = true;
            user.isDeleted = false;
            user.role = 'Admin';
            setUsers(users?.map((p) => (p.uniqueId === user.uniqueId ? user : p) || []));
          }
          break;

        case 'Employee':
          if (user) {
            user.isUpdated = true;
            user.isDeleted = false;
            user.role = 'Employee';
            setUsers(users?.map((p) => (p.uniqueId === user.uniqueId ? user : p) || []));
          }

          break;

        default:
          break;
      }
    }
  };
  const listMetas: ProListMetas<UserItem> = {
    title: {
      dataIndex: 'name',
      render: (text, record) => (
        <Typography.Text className={styles.title}>
          {record?.name || record?.email || '-'}
          <Typography.Text type="secondary">
            {(record?.userId || '') === (currentUser?.id || '') ? ` ${t?.('me')}` : ''}
          </Typography.Text>
        </Typography.Text>
      ),
    },
    actions: {
      render: (text, record) =>
        !record?.isOwner ? (
          <Actions
            buttonProps={{
              ghost: true,
              size: 'small',
            }}
            menuProps={{
              selectable: true,
              defaultSelectedKeys: [record?.role || 'Employee'],
              className: styles.actionmenu,
            }}
            actionPayload={record}
            items={ActionItems({ record, currentUser, t })}
            onClick={onRowActionClick}
            actionsText={record?.role}
          />
        ) : (
          <span>{t?.('me')}</span>
        ),
    },
  };
  return (
    <ProList
      itemLayout="horizontal"
      rowKey="uniqueId"
      split
      dataSource={dataUsers}
      loading={isLoading}
      metas={listMetas}
      ghost
    />
  );
};
export default React.memo(AccessList);

import { Locale } from 'antd/lib/locale-provider';
import deDE from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import ptBR from 'antd/lib/locale/pt_BR';
import { unflatten } from 'flat';
import i18n from 'i18next';
import { SetStateAction } from 'react';
import { getUserById } from 'services/api';
import { ListLanguageAttributesOptionalParams } from 'services/api/client/src';
import { getAllLanguages, getTranslationByLangCodeAPI } from 'services/api/useLanguage';

export type AntLocale = {
  [key: string]: Locale;
};
export const antLocale: AntLocale = {
  en: enUS,
  'pt-br': ptBR,
  de: deDE,
};

const getTranslationByLangCode = async (
  langCode: string,
  params?: ListLanguageAttributesOptionalParams,
) => {
  try {
    const response = await getTranslationByLangCodeAPI(langCode || '', {
      type: 'DESKTOP',
      ...params,
    });
    const unFlatten = unflatten(response.results || []);
    return unFlatten;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ file: language.ts:13 ~ getTranslationByLangCode ~ error', error);
  }
  return {};
};

export const changeLanguage = (languageCode: string) => i18n.changeLanguage(languageCode);

export const initLanguageSelection = async (
  id?: string,
  setLocale?: (value: SetStateAction<Locale>) => void,
  setLangCode?: (value: SetStateAction<string>) => void,
) => {
  try {
    if (id) {
      const userData = await getUserById(id);
      if (userData?.settings?.language) {
        setLocale?.(antLocale?.[userData?.settings?.language as keyof AntLocale] || antLocale.en);
        setLangCode?.(userData?.settings?.language);
        changeLanguage(userData?.settings?.language);
      } else {
        const allLanguages = await getAllLanguages(userData?.settings?.language || '');
        const language =
          allLanguages?.find(
            (item) => item?.code === window.navigator.language?.toLocaleLowerCase(),
          )?.code || 'en';
        setLocale?.(antLocale?.[language as keyof AntLocale] || antLocale.en);
        setLangCode?.(language);
        changeLanguage(language);

        /* update role commented */
        // updateUserRoleById(id || '', {
        //   id: id || '',
        //   preferences: {
        //     ...userData?.settings,
        //     languageCode: language,
        //   },
        // });
      }
    } else {
      const lang = window.navigator.language.includes('en') ? 'en' : window.navigator.language;
      setLocale?.(antLocale?.[lang || ('en' as keyof AntLocale)] || antLocale.en);
      setLangCode?.(lang || 'en');
      changeLanguage(lang || 'en');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('🚀 ~ file: language.ts:27 ~ initLanguageSelection ~ error', error);
  }
};
export const getProductsT = (str?: string) => i18n?.t?.(`products.${String(str)?.toLowerCase()}`);
export const getShipmentsT = (str?: string) => i18n?.t?.(`shipments.${String(str)?.toLowerCase()}`);
export const getEventsT = (str?: string) =>
  str ? i18n?.t?.(`products.events.${String(str)?.toLowerCase()}`) : '';

export const getTransformType = (transformType?: string) => {
  if (!transformType) return '';
  const transformText = i18n?.t?.(`products.${String(transformType)?.toLowerCase()}`);
  return `(${transformText})`;
};

export const getT = (str: string) => i18n?.t?.(str);
export const getTParam = (str: string, param?: object) => i18n?.t?.(str, param || {});
export const getCurrentTranslation = () => i18n?.language;

export const getTParamOrReturnBackup = (str: string, strBackup: string, param?: object) => {
  const translation = getTParam(str, param);
  return translation === str ? strBackup : translation;
};

export default getTranslationByLangCode;

// @ts-nocheck
import { TablePaginationConfig } from 'antd';
import { useTableSearchFilter } from 'hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWorkflows } from 'services/api';
import {
  GetEventTemplatesOptionalParams,
  ListWorkflowsOptionalParams,
  WorkflowTemplate,
} from 'services/api/client/src';

const useWorkflowFilters = (initParams?: ListWorkflowsOptionalParams) => {
  const { t } = useTranslation('pages', { keyPrefix: 'workflows' });

  const [params, setParams] = useState<ListWorkflowsOptionalParams>({
    pageNumber: 1,
    pageSize: 20,
    ...initParams,
  });
  const onSearch = (text: string) => {
    setParams({
      ...params,
      pageNumber: 1,
      name: text,
    });
  };
  const { data, isLoading } = useWorkflows(params);
  const { data: dataTemplates } = useWorkflows();

  const totalItems = useMemo(() => data?.totalItems || 0, [data]);
  const netItemCount = useMemo(() => dataTemplates?.totalItems || 0, [dataTemplates]);

  const { filters: templateSearchfilter } = useTableSearchFilter({
    title: t?.('col_template_title'),
    onSearch,
  });

  const records: Array<WorkflowTemplate> = useMemo(() => data?.results || [], [data]);

  const onTableChange = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, Array<string> | null>,
      sorter: any,
    ) => {
      const apiFilters: GetEventTemplatesOptionalParams = {};
      if (pagination?.current === params?.pageNumber && pagination?.pageSize === params?.pageSize) {
        setParams({
          ...params,
          ...apiFilters,
          sortBy: sorter?.field
            ? `${sorter.order === 'ascend' ? '+' : '-'}${sorter.field}`
            : undefined,
          pageNumber: 1,
        });
      } else {
        setParams({
          ...params,
          ...apiFilters,
          pageNumber: pagination?.current,
          pageSize: pagination?.pageSize,
        });
      }
    },
    [params, setParams],
  );

  return {
    params,
    setParams,
    totalItems,
    netItemCount,
    records,
    isLoading,
    onTableChange,
    filters: [templateSearchfilter],
  };
};
export default useWorkflowFilters;

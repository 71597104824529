import { BetaSchemaForm, ProFormInstance } from '@ant-design/pro-form';
import { FC, useCallback, useRef } from 'react';
import { DataItem, FieldColumns } from './GenerateAPIKey.fields';
import { ModalProps } from './typings';

const GenerateAPIKeyForm: FC<ModalProps> = ({ t, modal, newAPIKey, modalTitle, onSave }) => {
  const modalFormRef = useRef<ProFormInstance>();

  const closeModal = useCallback(() => {
    modalFormRef.current?.resetFields();
    modal.hide();
  }, [modal]);

  const onFinish = async (data: DataItem) => {
    if (data?.myAPIKey) {
      onSave?.(data?.myAPIKey);
    } else {
      closeModal();
    }
  };

  return (
    <BetaSchemaForm
      layoutType="ModalForm"
      title={modalTitle || t('generate_key.title')}
      formRef={modalFormRef}
      columns={FieldColumns({
        t,
        newAPIKey,
      })}
      grid
      visible={modal.visible}
      autoFocusFirstInput
      modalProps={{
        destroyOnClose: true,
        maskClosable: false,
        width: '50%',
        ...modal,
        onCancel: closeModal,
      }}
      submitter={{
        searchConfig: {
          submitText: t('generate_key.done'),
        },
        submitButtonProps: {
          shape: 'round',
          type: 'primary',
        },
        render: (_, defaultDoms) => defaultDoms[1],
      }}
      onFinish={onFinish}
    />
  );
};

export default GenerateAPIKeyForm;

import { ProColumns } from '@ant-design/pro-table';
import { Col, Row, Typography } from 'antd';
import { GTable } from 'components';
import moment from 'moment';
import { FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { InboundShipmentResponse } from 'services/api/client/src';
import EditableEventTable from './EditableEventTable';
import { EditableMultiReceiveTableProps } from './typings';

type ColumnsType = {
  t?: TFunction<'pages', 'shipments.multi_receive'>;
};
// multipple receive column
const multipleReceiveColumns = ({ t }: ColumnsType): Array<ProColumns<InboundShipmentResponse>> => [
  {
    dataIndex: 'originCompanyName',
    hideInSetting: true,
    render: (text, record) => (
      <div>
        <Typography.Text strong>{`${record?.originCompanyName} `}</Typography.Text>
        <Row gutter={[32, 8]}>
          <Col>
            <Typography.Text type="secondary">{`${t?.('number_of_products')} ${
              record?.numberOfProducts || '-'
            }`}</Typography.Text>
          </Col>
          <Col>
            <Typography.Text type="secondary">{`Ship Date: ${
              moment(record?.eventDate).format('YYYY/MM/DD') || '-'
            }`}</Typography.Text>
          </Col>
          <Col>
            <Typography.Text type="secondary">{`Receiver Purchase Order: ${
              record?.purchaseOrderNumber || '-'
            }`}</Typography.Text>
          </Col>
        </Row>
      </div>
    ),
    ellipsis: true,
    width: '90%',
  },
  {
    dataIndex: 'shipmentId',
    hideInTable: true,
  },
];

const EditableMultiReceiveTable: FC<EditableMultiReceiveTableProps> = ({
  form,
  editableShipment,
  currentForm,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'shipments.inbound' });
  const expandedRowRender = (row: InboundShipmentResponse) => (
    <EditableEventTable
      shipmentId={row?.shipmentId}
      form={form}
      isEdit
      editableForm={currentForm}
    />
  );

  return (
    <GTable<InboundShipmentResponse>
      showHeader={false}
      rowKey="shipmentId"
      columns={multipleReceiveColumns({ t })}
      columnsState={{
        persistenceKey: 'pages-products1',
      }}
      value={[editableShipment || {}]}
      expandable={{
        expandedRowRender,
        defaultExpandedRowKeys: [editableShipment?.shipmentId || 0],
      }}
      options={{
        setting: false,
        reload: false,
      }}
    />
  );
};

export default EditableMultiReceiveTable;

import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { getProductsT } from 'utils';
import styles from './index.module.less';
import { AdvanceSettingsFormItemProps } from './typings';

const AdvanceSettingsFormItem: FC<AdvanceSettingsFormItemProps> = ({
  dataIndex = 'hasAdvanceSettings',
  form,
  tooltip,
}) => {
  const { getFieldValue, setFieldsValue } = form;
  const value = getFieldValue?.(dataIndex) || false;
  const title = useMemo(
    () => (!value ? getProductsT('show_advance_title') : getProductsT('hide_advance_title')),
    [value],
  );
  const setValue = useCallback(
    (newValue: boolean) => {
      setFieldsValue?.({
        [dataIndex]: newValue,
      });
    },
    [setFieldsValue, dataIndex],
  );
  return (
    <Space>
      {tooltip && (
        <Tooltip title={tooltip}>
          <Typography.Text type="secondary">
            <InfoCircleOutlined className={styles.tooltip} />
          </Typography.Text>
        </Tooltip>
      )}
      <Typography.Link
        onClick={(e) => {
          e.preventDefault();
          setValue(!value);
        }}
      >
        {title}
      </Typography.Link>
    </Space>
  );
};
export default AdvanceSettingsFormItem;

import { BellOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, Tooltip } from 'antd';
import classNames from 'classnames';
import { useModalVisibility } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteNotifications } from 'services/api';
import NotificationBox from './NotificationBox';
import NotificationContext from './NotificationContext';
import styles from './index.module.less';
import { NotificationContextProps } from './typings';

const NotificationDropdown = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'dashboard.toolbar_tooltip' });
  const { visible, setVisible } = useModalVisibility(false);
  const { data: notifications } = useInfiniteNotifications({
    pageNumber: 1,
    pageSize: 5,
    type: 'Notification',
  });

  const { data: recentActivity } = useInfiniteNotifications({
    pageNumber: 1,
    pageSize: 5,
    type: 'RecentActivity',
  });
  const hasNotification = useMemo(
    () => (notifications?.pages?.[0]?.totalUnreadAmount || 0) > 0,
    [notifications],
  );
  const hasRecentActivity = useMemo(
    () => (recentActivity?.pages?.[0]?.totalUnreadAmount || 0) > 0,
    [recentActivity],
  );
  const isNotification = useMemo(
    () => hasNotification || hasRecentActivity,

    [hasNotification, hasRecentActivity],
  );

  const [hasBellDot, setHasBellDot] = useState(isNotification);

  useEffect(() => {
    setHasBellDot(isNotification);
  }, [isNotification]);
  const contextValue = useMemo<NotificationContextProps>(
    () => ({
      hasBellDot,
      hasNotification,
      hasRecentActivity,
      isNotification,
      setHasBellDot,
      setVisible,
    }),
    [hasBellDot, hasNotification, hasRecentActivity, isNotification, setHasBellDot, setVisible],
  );
  return (
    <NotificationContext.Provider value={contextValue}>
      <Dropdown
        placement="bottomRight"
        overlay={<NotificationBox />}
        overlayClassName={classNames(styles.container, styles.popover)}
        trigger={['click']}
        visible={visible}
        onVisibleChange={setVisible}
      >
        <Tooltip title={t('notifications')}>
          <Button
            type="text"
            icon={
              <Badge dot={hasBellDot}>
                <BellOutlined />
              </Badge>
            }
          />
        </Tooltip>
      </Dropdown>
    </NotificationContext.Provider>
  );
};
export default React.memo(NotificationDropdown);

import { Select } from 'antd';
import useAttributeStore from 'components/Settings/DefinedAttributes/hooks';
import type { DefaultOptionType } from 'rc-select/lib/Select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTemplateAttributes } from 'services/api/eventTemplateAttributes';

type ValueProps = {
  disabled: boolean;
  key: string;
};
const RadioValueSelect = ({ disabled, key }: ValueProps) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.select' });
  const selectedRow = useAttributeStore((state) => state.selectedRow);
  const setNewValue = useAttributeStore((state) => state.setNewValue);

  const { data: dataTemplateAttributes } = useTemplateAttributes();
  const templateAttributes = useMemo(
    () => dataTemplateAttributes?.results || [],
    [dataTemplateAttributes],
  );

  const valueOptions: Array<string> | undefined =
    templateAttributes?.find((attribute) => attribute.id === selectedRow)?.fieldProperties?.fields
      ?.fieldType === 'RadioButton'
      ? templateAttributes?.find((attribute) => attribute.id === selectedRow)?.fieldProperties
          ?.values?.valueOptions
      : [];

  const valueRecords: Array<DefaultOptionType> | undefined =
    valueOptions === undefined || valueOptions?.length > 0
      ? valueOptions?.map((item: string) => ({
          value: String(item),
          label: String(item),
        }))
      : undefined;

  const onOptionChange = (e: any) => {
    setNewValue(e);
  };

  return (
    <Select
      key={key}
      mode="tags"
      size="small"
      defaultValue={valueRecords}
      placeholder={t('value_select')}
      onChange={onOptionChange}
      disabled={disabled}
      notFoundContent={t('not_found_content')}
    />
  );
};

export default RadioValueSelect;

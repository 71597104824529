import { Avatar, List, Typography } from 'antd';
import { NotificationItem } from 'components';
import { useWindowSize } from 'hooks';
import VirtualList, { ListProps } from 'rc-virtual-list';
import React, { FC, useCallback, useMemo } from 'react';
import { getTParamOrReturnBackup } from 'utils';
import getObjectFromParam from 'utils/notifications';
import { EmptyStateActivity } from './components';
import styles from './index.module.less';
import { EventsProps } from './typings';
import useNotificationFilters from './useNotificationFilters';

const avatarStyle = {
  color: '#3f51b5',
  backgroundColor: '#e6ebf5',
};
const Activity: FC<EventsProps> = ({ t }) => {
  const [, height] = useWindowSize();
  const { records, onViewMore } = useNotificationFilters({
    pageNumber: 1,
    pageSize: 10,
    type: 'RecentActivity',
  });

  const scrollHeight = useMemo(() => {
    // amount of pixels equivalent to 40vh
    const vh = Math.floor(height * 0.425);

    return vh;
  }, [height]);

  const onScroll = useCallback<NonNullable<ListProps<NotificationItem>['onScroll']>>(
    (e) => {
      if (Math.floor(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === scrollHeight) {
        onViewMore();
      }
    },
    [onViewMore, scrollHeight],
  );

  const renderItem = useCallback(
    () =>
      records?.length > 0 ? (
        <VirtualList<NotificationItem>
          data={records}
          height={scrollHeight}
          itemHeight={73}
          itemKey="id"
          onScroll={onScroll}
        >
          {(item: NotificationItem) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={!item?.sender?.profileImageUrl ? avatarStyle : undefined}
                    src={item?.sender?.profileImageUrl ? item?.sender?.profileImageUrl : undefined}
                  >
                    {item?.sender?.givenName?.[0] || 'U'}
                  </Avatar>
                }
                title={
                  <Typography.Text ellipsis>{`${getTParamOrReturnBackup(
                    `${item?.message}`,
                    `${item?.messageBackup}`,
                    {
                      ...getObjectFromParam(item?.messageParams?.split(';')),
                    },
                  )} `}</Typography.Text>
                }
                description={
                  <Typography.Text ellipsis className={styles.listdesc} type="secondary">
                    {`${getTParamOrReturnBackup(
                      `${item?.description}`,
                      `${item?.descriptionBackup}`,
                      {
                        ...getObjectFromParam(item?.descriptionParams?.split(';')),
                      },
                    )} `}
                  </Typography.Text>
                }
              />
            </List.Item>
          )}
        </VirtualList>
      ) : (
        <EmptyStateActivity t={t} />
      ),
    [records, scrollHeight, onScroll, t],
  );

  return <List className={styles.list}>{renderItem()}</List>;
};
export default React.memo(Activity);

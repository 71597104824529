/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { message, Space, Typography } from 'antd';
import React, { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getEventResponse } from 'services/api';
import {
  DecommissionRequest,
  EventResponse,
  ExistingProductInstanceContract,
} from 'services/api/client/src';
import { useCreateDecommissionEvent, useMultiDecommissionEvent } from 'services/api/events';
import {
  apiDateFormat,
  checkRequestedDocuments,
  errorHandler,
  getBizStepIdByURN,
  getCustomProperties,
  getDispositionIdByURN,
  getProductsT,
  getTimezoneOffset,
  hasUnsavedChanges,
  mapCertProperties,
} from 'utils';
import { useEventsStore, useTitle } from '../hooks';
import { useEventActions } from './Forms';
import DecommissionFormFields, { DataItem } from './Forms/DecommissionForm.fields';
import { EventPageProps } from './typings';

const Decommission: FC<EventPageProps> = ({ form }) => {
  useTitle('decommission');
  const queryClient = useQueryClient();
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const [validationMessage, setValidationMessage] = useState<boolean>();

  const navigate = useNavigate();

  const { hasAdvancedSettings, dataEntryMethod, setSubmitting, template, multipleDates } =
    useEventsStore();
  const {
    productId,
    lastEventDate,
    defaultTimezone,
    disabledDate,
    disabledTime,
    onChangeDate,
    clearSameDayMessage,
    onChangeTemplate,
    isSerial,
    identifier,
    timeValidation,
  } = useEventActions();
  const { t } = useTranslation('pages', { keyPrefix: 'events.decommission' });
  const createDecommissionEvent = useCreateDecommissionEvent(queryClient);
  const createDecommissionEvents = useMultiDecommissionEvent(queryClient);

  const onViewEvent = useCallback(
    (formData: DataItem, res?: EventResponse) => {
      const instanceId = res?.productInstances?.[0]?.id || '';
      const eventId = res?.id || '1';
      navigate({
        pathname: `/products/${productId}/${instanceId}`,
        search: `?eventId=${eventId}`,
      });
    },
    [navigate, productId],
  );
  const generateSuccessMessage = useCallback(
    (formData: DataItem, res?: EventResponse) => {
      if (formData?.eventProducts?.length === 1) {
        message.success(
          <Space>
            {t('submit_success', {
              id: formData?.eventProducts?.[0]?.lotSerial,
              identifier: getProductsT(identifier),
            })}
            <Typography.Link
              onClick={(e) => {
                e.preventDefault();
                onViewEvent(formData, res);
              }}
            >
              {t('view_event')}
            </Typography.Link>
          </Space>,
        );
      } else {
        message.success(
          <Space>
            {t('submit_multi_success', { items: formData?.eventProducts?.length })}
            <Typography.Link
              onClick={(e) => {
                e.preventDefault();
                onViewEvent(formData, res);
              }}
            >
              {t('view_event')}
            </Typography.Link>
          </Space>,
        );
      }
    },
    [t, onViewEvent, identifier],
  );

  const onFormSubmit = async (formData: DataItem) => {
    try {
      if (!multipleDates) {
        setValidationMessage(false);
        const productInstancesList: ExistingProductInstanceContract[] = [];
        formData?.eventProducts?.forEach((product) => {
          if (product?.isContainer) {
            product?.containerItems?.forEach((containerItem) => {
              productInstancesList.push({
                id: containerItem?.instanceId,
                quantity: Number(containerItem.quantity || 0),
              });
            });
          } else {
            productInstancesList.push({
              id: product.primaryId,
              quantity: Number(product.quantity || 0),
            });
          }
        });
        const reqData: DecommissionRequest = {
          // decommissionType: formData.decommissionType,
          purchaseOrder: formData.poNumber,
          bizStepId: getBizStepIdByURN(formData.bizStep),
          dispositionId: getDispositionIdByURN(formData.disposition),
          eventTimeZone: getTimezoneOffset(formData.timeZone),
          eventTime: apiDateFormat(formData.date, formData.time),
          locationId: formData.location,
          productInstances: productInstancesList,
          documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
          templateId: template?.id,
          masterData: getCustomProperties(formData?.customProperties, template),
          certifications: mapCertProperties(formData?.certificationList || []),
        };
        const res = await createDecommissionEvent.mutateAsync(reqData);
        /* get event response */
        const event = await getEventResponse(res);
        generateSuccessMessage(formData, event);
      } else {
        const requests: Array<DecommissionRequest> = [];
        formData?.eventProducts?.forEach((formValue) => {
          const isContainer = formValue?.isContainer;
          const reqData: DecommissionRequest = {
            // decommissionType: formData.decommissionType,
            purchaseOrder: formData.poNumber,
            bizStepId: getBizStepIdByURN(formData.bizStep),
            dispositionId: getDispositionIdByURN(formData.disposition),
            eventTimeZone: getTimezoneOffset(formData.timeZone),
            eventTime: apiDateFormat(formValue.date, formValue.time),
            locationId: formData.location,
            productInstances: !isContainer
              ? [
                  {
                    id: formValue.primaryId,
                    quantity:
                      formValue.unitOfMeasure !== 'item' ? Number(formValue?.quantity || 0) : 1,
                  },
                ]
              : formValue?.containerItems?.map((product) => ({
                  id: product.instanceId,
                  quantity: Number(product.quantity || 0),
                })),
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            masterData: getCustomProperties(formData?.customProperties, template),
            certifications: mapCertProperties(formData?.certificationList || []),
          };
          requests.push(reqData);
        });
        await createDecommissionEvents.mutateAsync(requests);
        generateSuccessMessage(formData);
      }
    } catch (error) {
      throw error;
    }
  };
  const onCSVSubmit = async (formData: DataItem) => {
    try {
      const values = formData?.csvData;
      if (values?.length) {
        await editableTableFormRef.current?.validateFields();
        const requests: Array<DecommissionRequest> = [];
        const isContainer = false;

        values?.forEach((formValue) => {
          const reqData: DecommissionRequest = {
            // decommissionType: formValue.decommissionType,
            purchaseOrder: formValue.poNumber,
            bizStepId: getBizStepIdByURN(formValue.bizStep),
            dispositionId: getDispositionIdByURN(formValue.disposition),
            eventTimeZone: getTimezoneOffset(formValue.timeZone),
            eventTime: apiDateFormat(formValue.date, formValue.time),
            locationId: formData.location,
            productInstances: !isContainer
              ? [
                  {
                    id: formValue.lotID,
                    quantity: !isSerial ? Number(formValue?.quantity || 0) : 1,
                  },
                ]
              : [],
            documentsIds: formValue?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            masterData: getCustomProperties(formData?.customProperties, template),
            certifications: mapCertProperties(formData?.certificationList || []),
          };
          requests.push(reqData);
        });
        await createDecommissionEvents.mutateAsync(requests);
        const msg = t('submit_multi_success', { items: requests?.length });

        setTimeout(() => {
          message.success(
            <Space>
              {msg}
              {formData?.eventProducts?.length === 1 ? (
                <Typography.Link
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {t('view_details')}
                </Typography.Link>
              ) : null}
            </Space>,
          );
        }, 800);
      } else {
        throw new Error(t('no_data'));
      }
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      hasUnsavedChanges();
      checkRequestedDocuments({ template, documents: formData?.documents });

      if (dataEntryMethod === 'manual' && multipleDates) {
        const showValidate = formData?.eventProducts?.find(
          (item) => !item?.date || !item?.time,
          [],
        );
        if (showValidate) {
          setValidationMessage(true);
          return;
        }
      }

      setSubmitting(true);

      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
      } else {
        await onCSVSubmit(formData);
      }

      setSubmitting(false);

      navigate(-1);
      clearSameDayMessage();
    } catch (error) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      columns={DecommissionFormFields({
        editableTableFormRef,
        hasAdvancedSettings,
        dataEntryMethod,
        disabledDate,
        disabledTime,
        onChangeDate,
        onChangeTemplate,
        timeValidation,
        lastEventDate,
        defaultTimezone,
        isSerial,
        identifier,
        validationMessage,
      })}
      form={form}
      grid
      rowProps={{ gutter: 32 }}
      layoutType="Form"
      onFinish={onFinish}
      submitter={{ render: () => null }}
    />
  );
};

export default React.memo(Decommission);

import { Tabs } from 'antd';
import { useEventCountModal } from 'pages/Events';
import EventDiagram from 'pages/Products/Inventory/EventDetails/EventDiagram';
import EventList from 'pages/Products/Inventory/EventDetails/EventList';
import EventMap from 'pages/Products/Inventory/EventDetails/EventMap';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventById, useProductById } from 'services/api';
import { OrphanMatchEventDetailsProps } from './typings';

const OrphanMatchEventDetails: FC<OrphanMatchEventDetailsProps> = ({ ...detailsProps }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });

  useEventCountModal();
  const { data: currentProduct } = useProductById(detailsProps.matchEvent.parentProductId!, {
    authorizingEventId: detailsProps.matchEvent.isInboundShipment
      ? detailsProps.matchEvent.lastEventId
      : undefined,
  });
  const { data: event } = useEventById(detailsProps.matchEvent.lastEventId!);
  // const { data: productInstance } = useProductInstance(detailsProps.matchEvent.productInstanceId!);

  return (
    <Tabs defaultActiveKey="map">
      <Tabs.TabPane key="map" tab={t('map')}>
        <EventMap
          t={t}
          // productInstance={productInstance}
          product={currentProduct}
          event={event}
          isInboundShipment={detailsProps.matchEvent.isInboundShipment}
          // primaryId={productInstance?.id}
          isContainer={detailsProps.matchEvent.productType !== 'Lot'}
          eventId={detailsProps.matchEvent.lastEventId}
        />
      </Tabs.TabPane>
      <Tabs.TabPane key="diagram" tab={t('diagram')}>
        <EventDiagram
          t={t}
          // productInstance={productInstance}
          product={currentProduct}
          event={event}
          isInboundShipment={detailsProps.matchEvent.isInboundShipment}
          // primaryId={productInstance?.id}
          eventId={detailsProps.matchEvent.lastEventId}
          isContainer={detailsProps.matchEvent.productType !== 'Lot'}
        />
      </Tabs.TabPane>
      <Tabs.TabPane key="list" tab={t('list')}>
        <EventList
          eventId={detailsProps.matchEvent.lastEventId}
          // productInstance={productInstance}
          isInboundShipment={detailsProps.matchEvent.isInboundShipment}
          product={currentProduct}
          event={event}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};
export default OrphanMatchEventDetails;

// @ts-nocheck
/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { GraphData, NodeConfig } from '@antv/g6/lib/types';
import { Form, message } from 'antd';
import { useActions } from 'hooks';
import moment from 'moment';
import {
  Header,
  ShipDataItem,
  ShipFormFields,
  useEventActions,
  useEventsStore,
  useTitle,
} from 'pages/Events';
import { ProductInventoryItem } from 'pages/Products';

import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateShipRequest, EnrichedWorkflowShipEvent } from 'services/api/client/src';
import {
  dateFormat,
  dateLocaleFormat,
  errorHandler,
  getLocationById,
  getLocationNameById,
  getProductById,
  getTimezoneOffset,
  getUom,
  timeLocaleFormat,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';
import styles from '../index.module.less';
import { EventProps, WorkflowEvent } from '../typings';

type DataItem = ShipDataItem;
const Ship: FC<EventProps> = ({ form, drawer, event, isVisible, selectedInstances, instances }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.ship' });
  useTitle(t('title'));
  const locationDisabled = true;
  const [mainForm] = Form.useForm<DataItem>();
  const { setFieldsValue } = mainForm;
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const graphData: GraphData = form?.getFieldValue('graphData') || {
    nodes: [],
    edges: [],
  };

  const {
    productId,
    product: dataProduct,
    containerIdArray,
    lastEventDate,
    onChangeTemplate,
    defaultTimezone,
    isSerial,
    identifier,
    locationId,
    disabledDate,
    disabledTime,
    onChangeDate,
    onChangeLocation,
  } = useEventActions({
    productId: event?.productId || undefined,
    locationId: selectedInstances?.[0]?.locationId || event?.locationId || undefined,
    eventDate: dateLocaleFormat(selectedInstances?.[0]?.eventDate || new Date()) || '',
  });
  const { hasAdvancedSettings, dataEntryMethod, template, setSubmitting, setTemplate } =
    useEventsStore();
  const {
    products,
    locations,
    product,
    template: initTemplate,
  } = useActions({
    productId: event?.productId || undefined,
    templateId: event?.eventData?.templateId || event?.eventTemplateId,
  });

  const newSelectedInstances = useMemo(() => {
    if (selectedInstances?.length) {
      return selectedInstances;
    }
    const currentInstance = instances?.find((instance) => instance?.id === event?.id);
    if (currentInstance) {
      return [currentInstance];
    }
    return [];
  }, [selectedInstances, instances, event]);

  const onMount = useCallback(() => {
    if (event) {
      const edata = event?.eventData as EnrichedWorkflowShipEvent;
      setFieldsValue({
        date: edata?.eventTime ? dateFormat(edata?.eventTime) : undefined,
        time: edata?.eventTime ? timeLocaleFormat(edata?.eventTime) : undefined,
        lotID: edata?.newProducts?.[0]?.lotSerial,
        quantity: edata?.newProducts?.[0]?.quantity,
        ...(edata?.originLocationId && { location: edata?.originLocationId }),
        poNumber: edata?.purchaseOrder,
        bizStep: edata?.bizStep || 'urn:epcglobal:cbv:bizstep:shipping',
        disposition: edata?.disposition || 'urn:epcglobal:cbv:disp:in_transit',
        shippingCompany: getLocationById(edata?.destinationLocationId || '', locations)
          ?.tradePartnerId,
        shippingAddress: edata?.destinationLocationId,
        timeZone: defaultTimezone,
        customData: event?.eventTemplateId || edata?.templateId || undefined,
      });
    }
  }, [event, setFieldsValue, locations, defaultTimezone]);

  const onGetInitTemplate = useCallback(() => {
    if (event && initTemplate) {
      const edata = event?.eventData as EnrichedWorkflowShipEvent;
      const templateId = edata?.templateId || event?.eventTemplateId || '';
      onChangeTemplate?.(
        mainForm,
        {
          itemProps: initTemplate,
        },
        templateId,
        locationId,
      );
      if (edata) {
        setFieldsValue({
          customProperties: edata?.customProperties?.map((el) => el?.value || '') || [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTemplate, event]);

  /* effect to sync eventData in event */
  useEffect(() => onMount(), [onMount]);
  useEffect(() => onGetInitTemplate(), [onGetInitTemplate]);

  const onCloseDrawer = () => {
    mainForm?.resetFields();
    drawer?.closeDrawer();
  };
  const onCreateStoreInstance = (p: ProductInventoryItem) => {
    const instanceExists = instances?.find((instance) => instance?.id === p?.id);
    if (!instanceExists) {
      const newInstances = [...(instances || []), p];
      form?.setFieldsValue({
        instances: newInstances,
      });
    } else {
      form?.setFieldsValue({
        instances: instances?.map((instance) => {
          if (instance?.id === p?.id) {
            return p;
          }
          return instance;
        }),
      });
    }
  };
  const onMultipleCreateStoreInstance = (p: Array<ProductInventoryItem>) => {
    const newInstances = [...(instances || []), ...p];
    form?.setFieldsValue({
      instances: newInstances,
    });
  };
  const onCSVSubmit = async (formData: DataItem) => {
    const values = formData?.csvData;
    try {
      if (values?.length) {
        await editableTableFormRef.current?.validateFields();

        const requests: Array<CreateShipRequest> = [];
        values?.forEach((value) => {
          const reqData: CreateShipRequest = {
            eventTime: new Date(moment(`${value.date} ${value.time}`).toISOString()),
            eventTimeZone: getTimezoneOffset(value.timeZone),
            bizStep: value.bizStep,
            disposition: value.disposition,
            purchaseOrder: value.poNumber,
            originLocationId: formData.location,
            destinationLocationId: formData.shippingAddress,
            products: [
              {
                instanceId: value.lotID,
                quantity: !isSerial ? Number(value?.quantity || 0) : 1,
              },
            ],
            containerIdentifiers: containerIdArray,
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            templateName: template?.name,
            customProperties:
              template?.templateFields?.map((field, idx) => ({
                name: field?.name || field?.propertyName,
                value: value?.customProperties?.[idx] ? String(value.customProperties?.[idx]) : '',
                namespace: field?.namespace?.prefix || 'wc',
              })) || [],
          };
          requests.push(reqData);
        });
        setSubmitting(false);
      } else {
        throw new Error('No data to submit');
      }
    } catch (error) {
      throw error;
    }
  };

  const onFormSubmit = async (formData: DataItem) => {
    try {
      const location = getLocationById(formData.location, locations);

      if (selectedInstances?.[0]) {
        const updatedBody: EnrichedWorkflowShipEvent = {
          id: event?.id,
          eventIdInTemplate: event?.id,
          eventId: event?.id,
          sourceId: selectedInstances?.map((i) => i?.id || '') || [],
          bizStep: formData.bizStep,
          disposition: formData.disposition,
          eventTime: new Date(moment(`${formData.date} ${formData.time}`).toISOString()),
          eventTimeZone: getTimezoneOffset(formData.timeZone),
          originLocationId: formData.location,
          destinationLocationId: formData.shippingAddress,
          purchaseOrder: formData.poNumber,
          products: formData?.eventProducts
            ?.filter((p) => !p?.isContainer || false)
            ?.map((p) => ({
              productId: p.parentProductId,
              instanceId: p.primaryId,
              quantity: isSerial ? 1 : Number(p.quantity || 0),
            })),
          newProducts: formData?.eventProducts
            ?.filter((p) => !p?.isContainer || false)
            ?.map((p) => ({
              productId: p.parentProductId,
              instanceId: p.primaryId,
              quantity: isSerial ? 1 : Number(p.quantity || 0),
              lotSerial: p.lotSerial,
              unitOfMeasure: p.unitOfMeasure,
            })),
          containerIdentifiers: containerIdArray,
          documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
          templateId: template?.id,
          templateName: template?.name,
          customProperties:
            template?.templateFields?.map((field, idx) => ({
              name: field?.name || field?.propertyName,
              value: formData?.customProperties?.[idx]
                ? String(formData.customProperties?.[idx])
                : '',
              namespace: field?.namespace?.prefix || 'wc',
            })) || [],
        };

        /* create instance in quantities */
        onCreateStoreInstance({
          id: event?.id,
          name: selectedInstances?.[0]?.name || '',
          productId: selectedInstances?.[0]?.productId || '',
          instanceId: selectedInstances?.[0]?.instanceId || '',
          primaryId: selectedInstances?.[0].lotSerial,
          lotSerial: selectedInstances?.[0].lotSerial,
          quantity:
            selectedInstances?.[0]?.unitOfMeasure !== 'item'
              ? Number(selectedInstances?.[0].quantity || 0)
              : 1,
          unitOfMeasure: selectedInstances?.[0]?.unitOfMeasure || 'LBS',
          eventName: 'Ship',
          location: location?.name || '',
          locationAddress: location?.address?.line2 || '',
          locationId: formData.location || '',
          destinationId: formData.shippingAddress,
          eventId: event?.id,
          isContainer: false,
          containerId: undefined,
          containerIdentifier: '',
          containerItems: [],
          tradePartnerName: location?.tradePartnerName || '',
          address: location?.address,
          eventDate: updatedBody.eventTime,
          loggedDate: new Date(),
          purchaseOrder: formData.poNumber,
          shipmentType: 'Internal',
          shipped: true,
          productIdentifierType: getProductById(selectedInstances?.[0]?.productId, products)
            ?.productIdentifierType,
          bizStep: formData.bizStep,
          disposition: formData.disposition,
          timezone: getTimezoneOffset(formData.timeZone),
          templateId: template?.id,
        });

        const updatedNodes = graphData.nodes?.map((item: WorkflowEvent) => {
          if (item.id === event?.id) {
            const updatedNode: NodeConfig = {
              id: item?.id || '',
              eventType: 'Ship',
              eventTime: updatedBody?.eventTime ? dateFormat(updatedBody?.eventTime) : undefined,
              name: selectedInstances?.[0]?.name || '',
              primaryId: selectedInstances?.[0]?.primaryId || '',
              quantity: !isSerial ? Number(selectedInstances?.[0]?.quantity || 0) : 1,
              unitOfMeasure: selectedInstances?.[0]?.unitOfMeasure || 'LBS',
              eventTemplateId: item?.eventTemplateId,
              eventTemplateName: location?.address?.line2 || location?.name || '',
              locationId: formData?.location,
              locationName: getLocationNameById(formData.shippingAddress, locations),
              productId: formData.eventProducts?.[0]?.primaryId,
              productName: selectedInstances?.[0]?.name,
              colourIndex: 'colour_4',
              children: [],
            };
            return {
              ...item,
              ...updatedNode,
              eventData: updatedBody,
            };
          }
          return item;
        });
        const updatedGraphData = {
          ...graphData,
          nodes: updatedNodes,
        };

        form?.setFieldsValue({
          // @ts-ignore
          graphData: updatedGraphData,
        });
      } else {
        /*  considering there exist only one ship node hence multiple output is possible */
        const updatedNodes: Array<NodeConfig> = [];
        const newInstances: Array<ProductInventoryItem> = [];

        formData?.eventProducts?.forEach((ep) => {
          const newId = uuidv4();
          const reqData: EnrichedWorkflowShipEvent = {
            id: newId,
            eventIdInTemplate: event?.id,
            eventId: event?.id,
            sourceId: [],
            bizStep: formData.bizStep,
            disposition: formData.disposition,
            eventTime: new Date(moment(`${formData.date} ${formData.time}`).toISOString()),
            eventTimeZone: getTimezoneOffset(formData.timeZone),
            originLocationId: formData.location,
            destinationLocationId: formData.shippingAddress,
            purchaseOrder: formData.poNumber,
            products: [
              {
                // @ts-ignore
                productId: product?.id,
                instanceId: ep.primaryId,
                quantity: isSerial ? 1 : Number(ep?.quantity || 0),
              },
            ],
            newProducts: [
              {
                productId: product?.id,
                // @ts-ignore
                instanceId: ep.primaryId,
                quantity: isSerial ? 1 : Number(ep?.quantity || 0),
                lotSerial: ep.lotSerial,
                unitOfMeasure: ep.unitOfMeasure,
              },
            ],
            containerIdentifiers: containerIdArray,
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            templateName: template?.name,
            customProperties:
              template?.templateFields?.map((field, idx) => ({
                name: field?.name || field?.propertyName,
                value: formData?.customProperties?.[idx]
                  ? String(formData.customProperties?.[idx])
                  : '',
                namespace: field?.namespace?.prefix || 'wc',
              })) || [],
          };
          /* create instance in quantities */
          newInstances.push({
            id: newId,
            name: product?.name || '',
            productId: ep?.parentProductId || '',
            instanceId: ep?.primaryId || '',
            primaryId: ep.lotSerial,
            lotSerial: ep.lotSerial,
            quantity: ep?.unitOfMeasure !== 'item' ? Number(ep.quantity || 0) : 1,
            unitOfMeasure: ep?.unitOfMeasure || 'LBS',
            eventName: 'Ship',
            location: location?.name || '',
            locationAddress: location?.address?.line2 || '',
            locationId: formData.location || '',
            destinationId: formData.shippingAddress,
            eventId: event?.id,
            isContainer: false,
            containerId: undefined,
            containerIdentifier: '',
            containerItems: [],
            tradePartnerName: location?.tradePartnerName || '',
            address: location?.address,
            eventDate: reqData.eventTime,
            loggedDate: new Date(),
            purchaseOrder: formData.poNumber,
            shipmentType: 'Internal',
            shipped: true,
            productIdentifierType: getProductById(ep?.parentProductId, products)
              ?.productIdentifierType,
            bizStep: formData.bizStep,
            disposition: formData.disposition,
            timezone: getTimezoneOffset(formData.timeZone),
            templateId: template?.id,
          });
          const updatedNode: NodeConfig = {
            id: newId,
            eventType: 'Ship',
            eventTime: reqData?.eventTime ? dateFormat(reqData?.eventTime) : undefined,
            name: product?.name || '',
            primaryId: ep?.lotSerial || '',
            quantity: !isSerial ? Number(ep?.quantity || 0) : 1,
            unitOfMeasure: product?.unitOfMeasure || 'LBS',
            eventTemplateId: event?.eventTemplateId,
            eventTemplateName: location?.address?.line2 || location?.name || '',
            locationId: formData?.location,
            locationName: getLocationNameById(formData.shippingAddress, locations),
            productId: formData.eventProducts?.[0]?.primaryId,
            productName: product?.name,
            colourIndex: 'colour_4',
            children: [],
            eventData: reqData,
          };
          updatedNodes.push(updatedNode);
        });
        onMultipleCreateStoreInstance(newInstances);

        const updatedGraphData: GraphData = {
          ...graphData,
          nodes: updatedNodes,
        };

        form?.setFieldsValue({
          graphData: updatedGraphData,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);

      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
        setSubmitting(false);
      } else {
        await onCSVSubmit(formData);
      }
      onCloseDrawer();
    } catch (error: any) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      key={event?.id}
      form={mainForm}
      layoutType="DrawerForm"
      columns={ShipFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        defaultTimezone,
        lastEventDate,
        template,
        locationId,
        product,
        editableTableFormRef,
        productId,
        isSerial,
        identifier,
        selectedInstances: newSelectedInstances,
        disabledDate,
        disabledTime,
        onChangeDate,
        setTemplate,
        onChangeTemplate,
        t,
        containerIdArray,
      })}
      grid
      rowProps={{ gutter: 32 }}
      onFinish={onFinish}
      // @ts-ignore
      title={
        <Header
          isManual={dataEntryMethod === 'manual'}
          title={t('title')}
          form={mainForm}
          locationId={locationId}
          locationDisabled={locationDisabled}
          onChangeLocation={onChangeLocation}
        />
      }
      submitter={{
        searchConfig: {
          submitText: t('add_event_title'),
          resetText: t('cancel_btn_title'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
      }}
      initialValues={{
        unitOfMeasure: getUom({ product: dataProduct }),
      }}
      visible={isVisible ? drawer?.visible || false : false}
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        className: styles.eventdrawer,
        ...drawer,
      }}
    />
  );
};

export default Ship;

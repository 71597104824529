import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { TitleBar } from 'components';
import useTableRowSelection from 'hooks/useTableRowSelection';
import useDeleteActions from 'pages/Network/hook/useDeleteActions';
import type { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useLocationStore from '../../hook/useLocationStore';
import styles from '../../index.module.less';
import { ActionType, HeaderProps, MenuItem } from './typings';

const MainActionItems = ({ accountType }: ActionType): ItemType[] => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.view_location' });
  return [
    ...(accountType === 'ME'
      ? [
          {
            key: 'shareLocation',
            label: t('gen_actions.share_location'),
            disabled: false,
          },
        ]
      : []),
    {
      key: 'editCompanyLocation',
      label: t('gen_actions.edit_company_location'),
      disabled: false,
    },
    ...(accountType === 'WC'
      ? [
          {
            key: 'shareDocument',
            label: t('gen_actions.share_document'),
            disabled: false,
          },
        ]
      : []),
  ];
};

const LocationTitle: FC<HeaderProps> = ({
  shareDocModal,
  companyLocations,
  shareLocModal,
  deleteLocModal,
  addAttributeModal,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'network.view_location' });
  const locationData = useLocationStore();
  const { id, companyId, isLocationView, company, accountType } = locationData;
  const navigate = useNavigate();
  const { selectedRowKeys = [] } = useTableRowSelection();
  const { archiveSingleLocation } = useDeleteActions();
  const loc = companyLocations?.find((item) => item?.id === id);
  const title = isLocationView
    ? `${loc?.tradePartnerName} : ${loc?.name}`
    : `${company} : ${t('all_locations')}`;
  const locations =
    companyLocations?.map((item) => ({
      key: item?.id || '',
      label: item?.name || '',
    })) || [];
  const records: Array<MenuItem> = [
    {
      key: companyId || '',
      label: t('all_locations'),
    },
    ...locations,
  ];

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'shareLocation':
        shareLocModal?.show();
        break;
      case 'editCompanyLocation':
        if (isLocationView) {
          navigate('editLocationId');
        } else {
          navigate('editLocation');
        }
        break;
      case 'addDefaultAttribute':
        addAttributeModal?.show();
        break;
      case 'shareDocument':
        shareDocModal.show();
        break;
      case 'deleteLocation':
        if (deleteLocModal) {
          deleteLocModal?.show();
        } else {
          archiveSingleLocation(id || '');
        }
        break;

      default:
        break;
    }
  };

  const onItemClick: MenuClickEventHandler = ({ key }) => {
    if (String(key) === String(companyId)) {
      navigate('../');
    } else {
      navigate(`../${key}`);
    }
  };

  return (
    <TitleBar
      className="page-header"
      title={
        companyLocations.length > 1 ? (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu
                defaultSelectedKeys={isLocationView ? [`${id}`] : [`${companyId}`]}
                selectable
                onClick={onItemClick}
                items={records}
                className={styles.dropdownselect}
              />
            }
          >
            <Space>
              {title}
              <DownOutlined className={styles.dropdownicon} />
            </Space>
          </Dropdown>
        ) : (
          <Space>{title}</Space>
        )
      }
      onBack={() => navigate('../../')}
      actionItems={MainActionItems({ selectedRowKeys, accountType })}
      onActionItemClick={onActionItemClick}
    />
  );
};

export default LocationTitle;

import { ProColumns } from '@ant-design/pro-table';
import { Button } from 'antd';
import { SingleColumnTags } from 'components';
import GTable from 'components/GTable';
import { TFunction } from 'i18next';
import { DocumentItem, useDocumentActions, useDocumentFilters } from 'pages/Documents';
import SharedTag from 'pages/Documents/components';
import { FilterArrayProp } from 'pages/Network/typings';
import pluralize from 'pluralize';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDocTypeFilters } from 'utils';
import { ArchivedProps } from '../typings';

type ColumnsType = {
  nameSearchFilter: ProColumns;
  handleActionsMenuClick?: (actionPayload?: DocumentItem) => void;
  coreCompanyFilter: Array<FilterArrayProp>;
  tradePartnersFilter: Array<FilterArrayProp>;
  locationsFilter: Array<FilterArrayProp>;
  productsFilter: Array<FilterArrayProp>;
  t: TFunction<'pages', 'template_attributes.documents'>;
};

const columns = ({
  nameSearchFilter,
  handleActionsMenuClick,
  coreCompanyFilter,
  tradePartnersFilter,
  locationsFilter,
  productsFilter,
  t,
}: ColumnsType): ProColumns<DocumentItem>[] => [
  {
    title: t('columns.document_name'),
    dataIndex: 'title',
    fixed: 'left',
    ellipsis: true,
    width: 230,
    ...nameSearchFilter,
  },
  {
    title: t('columns.document_type.title'),
    dataIndex: 'documentType',
    filters: getDocTypeFilters(),
    ellipsis: true,
    width: 150,
  },
  {
    title: t('columns.company'),
    dataIndex: 'tradepartners',
    filters: tradePartnersFilter,
    filterSearch: true,
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Company')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.company'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.company') || ''}
      />
    ),
    width: 200,
  },
  {
    title: t('columns.location'),
    dataIndex: 'locations',
    filters: locationsFilter,
    filterSearch: true,
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Location')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.location'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.location') || ''}
      />
    ),
    width: 300,
  },
  {
    title: t('columns.product'),
    dataIndex: 'products',
    filters: productsFilter,
    ellipsis: true,
    render: (text, record) => (
      <SingleColumnTags
        values={
          record?.associations
            ?.filter((item) => item?.type === 'Product')
            ?.map((item) => item?.label || '') || []
        }
        columnTitle={pluralize(t('columns.product'), 2)}
        modalTitle={record?.title || ''}
        colTableTitle={t('columns.product') || ''}
      />
    ),
    width: 200,
  },
  {
    title: t('columns.shared.title'),
    dataIndex: 'shared',
    render: (text, record) => <SharedTag sharedWith={record.sharedWith} />,
    filters: coreCompanyFilter,
    filterSearch: true,
    width: 230,
    ellipsis: true,
  },
  {
    title: t('columns.recent'),
    dataIndex: 'recent',
    ellipsis: true,
    width: 150,
  },
  {
    title: t('columns.expiration'),
    dataIndex: 'expirationDate',
    ellipsis: true,
    width: 150,
  },
  {
    title: t('columns.blockchain.title'),
    dataIndex: 'blockchain',
    width: 150,
  },
  {
    title: t('columns.visibility.title'),
    dataIndex: 'visibility',
    filters: [
      {
        text: t('columns.visibility.filters.private'),
        value: 'Private',
      },
      {
        text: t('columns.visibility.filters.public'),
        value: 'Public',
      },
    ],
    width: 150,
  },
  {
    title: t('columns.url'),
    dataIndex: 'url',
    ellipsis: true,
    copyable: true,
    width: 150,
  },
  {
    dataIndex: 'actions',
    hideInSetting: true,
    render: (text, record) => (
      <Button
        className="actions"
        disabled={!handleActionsMenuClick}
        onClick={() => handleActionsMenuClick?.(record)}
        type="primary"
        ghost
        size="small"
      >
        {t('move_to_doc')}
      </Button>
    ),
    fixed: 'right',
    width: 200,
  },
];

const Documents: FC<ArchivedProps> = ({
  onRowSelectionChange,
  selectedRowKeys,
  docParams,
  setDocParams,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.documents' });
  const { onUnarchiveDocument, isUnarchiving } = useDocumentActions();
  const {
    params,
    records,
    totalItems,
    isLoading,
    filters: [nameSearchFilter],
    onTableChange,
    coreCompanyFilter,
    tradePartnersFilter,
    locationsFilter,
    productsFilter,
    isFetching,
    setParams,
  } = useDocumentFilters({
    // status: ['Archived'],
    showArchived: true,
  });

  useEffect(() => {
    if (docParams?.pageNumber) {
      setParams({
        ...params,
        pageNumber: 1,
      });
      setDocParams?.({});
    }
  }, [docParams, params, setDocParams, setParams]);

  const handleActionsMenuClick = async (actionPayload?: DocumentItem) => {
    onUnarchiveDocument(actionPayload);
  };

  return (
    <GTable<DocumentItem>
      columns={columns({
        nameSearchFilter,
        handleActionsMenuClick: isUnarchiving || isFetching ? undefined : handleActionsMenuClick,
        coreCompanyFilter,
        tradePartnersFilter,
        locationsFilter,
        productsFilter,
        t,
      })}
      value={records}
      enableRowSelection
      loading={isLoading}
      options={{
        setting: false,
        reload: false,
      }}
      scroll={{ x: 1950, y: '75vh' }}
      columnsState={{
        persistenceKey: 'archived-documents',
      }}
      pagination={{
        defaultPageSize: params.pageSize,
        total: totalItems,
        current: params?.pageNumber,
        showTotal: (total, range) =>
          total <= 1
            ? t('product_pagi', { range0: range[0], range1: range[1], total })
            : t('product_pagi_archived', { range0: range[0], range1: range[1], total }),
      }}
      rowSelection={{ selectedRowKeys, onChange: onRowSelectionChange }}
      // @ts-ignore
      onTableChange={onTableChange}
    />
  );
};

export default Documents;

import { Card, Progress, Typography } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { DefaultOptionType } from 'antd/lib/select';
import { Actions } from 'components';
import { ActionsProps } from 'components/Actions/typings';
import { useDrawerVisibility, useModalVisibility } from 'hooks';
import { useEventsStore } from 'pages/Events';
import { EventDiagram, ProductInventoryItem } from 'pages/Products';
import { FC, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { downloadJsonToCSV, downloadWorkflowExcelTemplate } from 'utils';
import Diagram from './Diagram';
import { Aggregate, Commission, Decommission, Observe, Receive, Ship, Transform } from './Events';
import styles from './index.module.less';
import { EventGridProps, WorkflowEvent } from './typings';
import UploadTemplate from './UploadTemplate';

type ActionProps = {
  t: TFunction<'pages', 'workflows.create_workflow'>;
  isCompleted?: boolean;
};
const ActionItems = ({ t, isCompleted }: ActionProps): Array<ItemType> =>
  !isCompleted
    ? [
        {
          label: t?.('actions.download_template'),
          key: 'download_template',
        },
        {
          label: t?.('actions.upload_template'),
          key: 'upload_template',
        },
      ]
    : [
        {
          label: t?.('actions.download_csv'),
          key: 'download_csv',
        },
      ];

const EventGrid: FC<EventGridProps> = ({
  graphData,
  form,
  instances,
  mainGraph,
  setMainGraph,
  templateId,
  isCompleted,
  product,
  productInstance,
  event,
  isSubmitting,
  percent,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'workflows.create_workflow' });
  const drawer = useDrawerVisibility(false);
  const [activeEvent, setActiveEvent] = useState<WorkflowEvent>();
  const [selectedInstances, setSelectedInstances] = useState<Array<ProductInventoryItem>>([]);
  const { setTitle, setDataEntryMethod, setAdvancedSettings, setTemplate } = useEventsStore();
  const uploadModal = useModalVisibility(false);
  const onNodeClick = (node: any) => {
    const model = node?.getModel();
    const currInstances = form?.getFieldValue('instances') || [];
    const sourceNeighbors = node?.getNeighbors('source')?.map((n: any) => n.getModel());

    const newInstances = sourceNeighbors?.map((n: any) => {
      const instance = currInstances?.find((i: any) => i?.id === n?.id);
      return instance;
    });

    setSelectedInstances(newInstances);

    setActiveEvent(model);
    setTitle(model?.eventType || 'Commission');
    setDataEntryMethod('manual');
    setTemplate(undefined);
    if (model?.eventType === 'Observe') {
      setAdvancedSettings(true);
    } else {
      setAdvancedSettings(false);
    }
    /* set selected instances */
    drawer.showDrawer();
  };
  const onRowAction: ActionsProps<DefaultOptionType>['onClick'] = (value) => {
    switch (value) {
      case 'download_template':
        downloadWorkflowExcelTemplate(graphData?.nodes || []);
        break;
      case 'upload_template':
        uploadModal.show();
        break;
      case 'download_csv':
        downloadJsonToCSV(instances, 'workflow');
        break;

      default:
        break;
    }
  };

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {templateId ? (
        !isCompleted ? (
          <Card className={styles.diagramcard}>
            {isSubmitting && <Progress percent={percent} strokeColor="#3f51b5" />}
            {!isSubmitting && (
              <Actions<DefaultOptionType>
                className={styles.floatbtn}
                items={ActionItems({ t, isCompleted })}
                onClick={onRowAction}
                buttonProps={{
                  type: 'primary',
                  ghost: true,
                }}
              />
            )}
            <Diagram
              mainGraph={mainGraph}
              setMainGraph={setMainGraph}
              graphData={graphData}
              onNodeClick={onNodeClick}
            />
          </Card>
        ) : (
          <EventDiagram
            product={product}
            productInstance={productInstance}
            event={event}
            showLifeCycle={false}
            actionsComponent={
              <Actions<DefaultOptionType>
                className={styles.floatbtn}
                items={ActionItems({ t, isCompleted })}
                onClick={onRowAction}
                buttonProps={{
                  type: 'primary',
                  ghost: true,
                }}
              />
            }
          />
        )
      ) : (
        <Card className={styles.emptycard}>
          <Typography.Paragraph type="secondary" className={styles.emptytext}>
            {t?.('select_template_title')}
          </Typography.Paragraph>
        </Card>
      )}
      {activeEvent?.eventType === 'Commission' && (
        <Commission
          event={activeEvent}
          drawer={drawer}
          form={form}
          isVisible={activeEvent?.eventType === 'Commission'}
          instances={instances}
        />
      )}
      {activeEvent?.eventType === 'Decommission' && (
        <Decommission
          event={activeEvent}
          drawer={drawer}
          form={form}
          isVisible={activeEvent?.eventType === 'Decommission'}
          instances={instances}
        />
      )}
      {activeEvent?.eventType === 'Transform' && (
        <Transform
          event={activeEvent}
          drawer={drawer}
          form={form}
          isVisible={activeEvent?.eventType === 'Transform'}
          selectedInstances={selectedInstances}
          instances={instances}
        />
      )}
      {activeEvent?.eventType === 'Ship' && (
        <Ship
          event={activeEvent}
          drawer={drawer}
          form={form}
          isVisible={activeEvent?.eventType === 'Ship'}
          selectedInstances={selectedInstances}
          instances={instances}
        />
      )}
      {activeEvent?.eventType === 'Receive' && (
        <Receive
          event={activeEvent}
          drawer={drawer}
          form={form}
          isVisible={activeEvent?.eventType === 'Receive'}
          selectedInstances={selectedInstances}
          instances={instances}
        />
      )}
      {activeEvent?.eventType === 'Observe' && (
        <Observe
          event={activeEvent}
          drawer={drawer}
          form={form}
          isVisible={activeEvent?.eventType === 'Observe'}
          selectedInstances={selectedInstances}
          instances={instances}
        />
      )}
      {activeEvent?.eventType === 'Aggregate' && (
        <Aggregate
          event={activeEvent}
          drawer={drawer}
          form={form}
          isVisible={activeEvent?.eventType === 'Aggregate'}
          selectedInstances={selectedInstances}
          instances={instances}
        />
      )}
      <UploadTemplate modal={uploadModal} form={form} />
    </>
  );
};
export default EventGrid;

import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Button, Tag } from 'antd';
import GTable from 'components/GTable';
import TitleBar from 'components/TitleBar';
import { ProductItem, ProductsTitleProps, useProductFilters } from 'pages/Products';
import { FC, useMemo, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

type FilterType = {
  text: string;
  value: string;
};

type ColumnsType = {
  onRowActionClick: (actionPayload?: ProductItem) => void;
  productSearchfilter: ProColumns;
  t?: TFunction<'pages', 'settings.products'>;
  coreDataSharing: Array<FilterType>;
  coreBlockchain: Array<FilterType>;
};

const Columns = ({
  t,
  onRowActionClick,
  productSearchfilter,
  coreDataSharing,
}: ColumnsType): Array<ProColumns<ProductItem>> => [
  {
    title: t?.('col_product_title'),
    dataIndex: 'name',
    hideInSetting: true,
    render: (text, record) => (
      <Link className="text-link" to={`../products/${record.id}`} title={`${t?.('view')} ${text}`}>
        {text}
      </Link>
    ),
    ellipsis: true,
    ...productSearchfilter,
  },
  {
    title: t?.('col_identifier_title'),
    dataIndex: 'id',
    ellipsis: true,
  },
  {
    title: t?.('col_blockchain_title'),
    dataIndex: 'blockchain',
    ellipsis: true,
  },
  {
    title: t?.('col_datasharing_title'),
    dataIndex: 'sharingPolicy',
    filters: coreDataSharing,
    render: (text) => <Tag>{text}</Tag>,
  },
  {
    dataIndex: 'actions',
    valueType: 'option',
    hideInSetting: true,
    render: (text, record) => (
      <Button
        size="small"
        type="primary"
        ghost
        className="actions"
        onClick={() => onRowActionClick(record)}
      >
        {t?.('edit_setting')}
      </Button>
    ),
  },
];
const ProductsTitle: FC<ProductsTitleProps> = ({ total, t }) => (
  <TitleBar title={`${t?.('title')} (${total})`} />
);

const Products = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.products' });
  const actionRef = useRef<ActionType>();
  const navigate = useNavigate();
  const coreDataSharing = [
    {
      text: t?.('open'),
      value: 'Open',
    },
    {
      text: t?.('restricted'),
      value: 'Restricted',
    },
  ];
  const coreBlockchain = [
    {
      text: t?.('algorand'),
      value: 'Algorand',
    },
  ];
  const {
    params,
    records,
    totalItems,
    isLoading,
    filters: [productSearchfilter],
    onTableChange,
  } = useProductFilters();
  const onRowActionClick = (actionPayload?: ProductItem) => {
    navigate(`../products/${actionPayload?.id}/settings`);
  };

  const filterRecords = useMemo(
    () =>
      records?.map(
        (item) => ({
          ...item,
          sharingPolicy: item?.sharingPolicy ? t(item?.sharingPolicy?.toLowerCase()) : 'Open',
        }),
        [],
      ),
    [records, t],
  );

  return (
    <GTable<ProductItem>
      actionRef={actionRef}
      columns={Columns({
        onRowActionClick,
        productSearchfilter,
        t,
        coreDataSharing,
        coreBlockchain,
      })}
      loading={isLoading}
      value={filterRecords}
      headerTitle={<ProductsTitle total={totalItems} t={t} />}
      recordCreatorProps={false}
      pagination={{
        defaultPageSize: params.pageSize,
        total: totalItems,
        showTotal: (total, range) =>
          total <= 1
            ? t('product_pagi', { range0: range[0], range1: range[1], total })
            : t('product_pagi_archived', { range0: range[0], range1: range[1], total }),
      }}
      onTableChange={onTableChange}
      scroll={{ y: '75vh', x: 1000 }}
      columnsState={{
        persistenceKey: 'settings-products',
      }}
    />
  );
};
export default Products;

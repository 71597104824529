import { BetaSchemaForm } from '@ant-design/pro-form';
import { message, Space, Typography } from 'antd';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getEventResponse } from 'services/api';
import { DisaggregateRequest, EventResponse } from 'services/api/client/src';
import { useCreateDisaggregateEvent } from 'services/api/events';
import {
  apiDateFormat,
  errorHandler,
  getBizStepIdByURN,
  getCustomProperties,
  getDispositionIdByURN,
  getTimezoneOffset,
  mapCertProperties,
} from 'utils';
import { useEventsStore, useTitle } from '../hooks';
import { useEventActions } from './Forms';
import DisaggregationFormFields, { DataItem } from './Forms/DisaggregateForm.fields';
import { EventPageProps } from './typings';

const Disaggregate: FC<EventPageProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.disaggregate' });
  useTitle('disaggregation');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    productId,
    containerIdArray,
    disabledDate,
    disabledTime,
    onChangeDate,
    lastEventDate,
    clearSameDayMessage,
    defaultTimezone,
    isSerial,
    identifier,
    timeValidation,
  } = useEventActions();

  const { hasAdvancedSettings, dataEntryMethod, setSubmitting, template } = useEventsStore();
  const createDisaggregateEvent = useCreateDisaggregateEvent(queryClient);
  const onViewEvent = useCallback(
    (formData: DataItem, res?: EventResponse) => {
      const instanceId = formData?.eventProducts?.[0]?.primaryId || '';
      const eventId = res?.id || '';

      navigate({
        pathname: `/products/${productId}/${instanceId}`,
        search: `?eventId=${eventId}`,
      });
    },
    [navigate, productId],
  );
  const onGenerateMsg = useCallback(
    (formData: DataItem, res?: EventResponse) => {
      const msg =
        (formData?.eventProducts?.length || 0) > 1
          ? t('submit_success_many', { count: formData?.eventProducts?.length })
          : t('submit_success', {
              type: identifier,
              id: formData?.eventProducts?.[0]?.lotSerial || '',
            });
      message.success(
        <Space>
          {msg}
          {formData?.eventProducts?.length === 1 ? (
            <Typography.Link
              onClick={(e) => {
                e.preventDefault();
                onViewEvent(formData, res);
              }}
            >
              {t('view_details')}
            </Typography.Link>
          ) : null}
        </Space>,
      );
    },
    [onViewEvent, t, identifier],
  );
  const onFinish = async (formData: DataItem) => {
    try {
      const reqData: DisaggregateRequest = {
        bizStepId: getBizStepIdByURN(formData.bizStep),
        dispositionId: getDispositionIdByURN(formData.disposition),
        eventTime: apiDateFormat(formData.date, formData.time),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        purchaseOrder: formData.poNumber,
        containerId: containerIdArray?.[0],
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        masterData: getCustomProperties(formData?.customProperties, template),
        certifications: mapCertProperties(formData?.certificationList || []),
      };
      setSubmitting(true);
      const res = await createDisaggregateEvent.mutateAsync(reqData);
      /* get event response */
      const event = await getEventResponse(res);

      onGenerateMsg(formData, event);
      setSubmitting(false);
      clearSameDayMessage();
      navigate(-1);
    } catch (error) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      columns={DisaggregationFormFields({
        disabledDate,
        disabledTime,
        onChangeDate,
        timeValidation,
        hasAdvancedSettings,
        dataEntryMethod,
        lastEventDate,
        defaultTimezone,
        isSerial,
        identifier,
      })}
      form={form}
      grid
      rowProps={{ gutter: 32 }}
      layoutType="Form"
      onFinish={onFinish}
      submitter={{ render: () => null }}
    />
  );
};

export default React.memo(Disaggregate);

import { InfoCircleOutlined } from '@ant-design/icons';
import { ProFormColumnsType } from '@ant-design/pro-form';
import { Segmented, Typography } from 'antd';
import BizStepSelect from 'components/GSelect/BizStepSelect';
import DispositionSelect from 'components/GSelect/DispositionSelect';
import TemplateSelect from 'components/GSelect/TemplateSelect';
import { useTranslation } from 'react-i18next';
import { EventTemplatesResponse } from 'services/api/client/src';
import { camelize, fieldTypes, getProductsT } from 'utils';
import { PreviewFormFieldProps } from '../typings';
import { Documents, EventDetails } from './Sections';
import ProductsTable from './Sections/ProductsTable';

const colProps = { xs: 24, sm: 12, md: 12, lg: 6 };
const colPropsNull = { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 };

export type DataItem = {
  advancedField?: string;
  advancedField2?: string;
  advancedField3?: string;
  customData?: string;
  date?: string;
  location?: string;
  lotID?: string;
  poNumber?: string;
  product?: string;
  quantity?: string;
  unitOfMeasure?: string;
  time?: string;
  timeZone?: string;
  bizStep?: string;
  disposition?: string;
  template?: EventTemplatesResponse;
};

export const ViewTemplatePreviewFormFields = ({
  isManualEntry,
  hasAdvancedSettings,
  templateId,
  event,
}: PreviewFormFieldProps): ProFormColumnsType<DataItem>[] => {
  const { t } = useTranslation('pages', { keyPrefix: 'templates.preview' });

  return [
    {
      valueType: 'group',
      title: t('form_fields.ip_product_grp_title'),
      columns: [
        {
          renderFormItem: () => <ProductsTable event={event} />,
        },
      ],
      hideInForm: event !== 'Transform',
    },
    {
      valueType: 'divider',
      hideInForm: event !== 'Transform',
      formItemProps: {
        className: 'no-margint-divider',
      },
      fieldProps: {
        className: 'no-margint-divider',
      },
    },
    {
      valueType: 'group',
      title: t('form_fields.op_product_grp_title'),
      columns: [
        {
          renderFormItem: () => <ProductsTable event={event} />,
        },
      ],
      hideInForm: event !== 'Transform',
    },
    {
      valueType: 'divider',
      hideInForm: event !== 'Transform',
      formItemProps: {
        className: 'no-margint-divider',
      },
      fieldProps: {
        className: 'no-margint-divider',
      },
    },
    {
      renderFormItem: () => <EventDetails />,
    },
    {
      title: t('form_fields.custom_data_title'),
      dataIndex: 'customData',
      initialValue: templateId,
      hideInForm: event === 'Aggregation' || event === 'Disaggregate',

      renderFormItem: (row, config, form) => (
        <TemplateSelect
          onChange={(value, option) => {
            // @ts-ignore
            const item: EventTemplatesResponse = option?.itemProps;
            form?.setFieldsValue({
              template: item,
              customData: value,
            });
          }}
        />
      ),
      colProps,
    },
    {
      dataIndex: 'template',
      fieldProps: {
        className: 'no-display',
      },
      formItemProps: {
        className: 'no-display',
      },
      colProps: colPropsNull,
    },
    {
      title: t('form_fields.data_entry_title'),
      dataIndex: 'dataEntryMethod',
      renderFormItem: () => (
        <Segmented options={[getProductsT('manual'), getProductsT('csv_upload')]} disabled />
      ),
      colProps: {
        xs: 24,
        md: 18,
      },
      hideInForm: event === 'Aggregation' || event === 'Disaggregate',
    },
    {
      title: t('form_fields.logistical_unit_id'),
      dataIndex: 'logistical_unit_id',
      fieldProps: {
        placeholder: t('form_fields.logistical_unit_id_placeholder'),
      },
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.logistical_unit_id_required'),
          },
        ],
      },
      // @ts-ignore
      hideInForm: event !== 'Aggregation' || event === 'Disaggregate',
      colProps,
    },
    {
      title: t('form_fields.product_title'),
      dataIndex: 'product',
      valueType: 'select',
      fieldProps: {
        placeholder: t('form_fields.product_placeholder'),
        disabled: true,
      },
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.logistical_unit_id_required'),
          },
        ],
      },
      hideInForm:
        !isManualEntry ||
        event === 'Decommission' ||
        event === 'Transform' ||
        event === 'Aggregation' ||
        event === 'Disaggregate' ||
        event === 'Ship',

      colProps,
    },
    {
      title: t('form_fields.product_lot_title'),
      dataIndex: 'lotID',
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.logistical_unit_id_required'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.product_lot_title'),
        disabled: true,
      },
      hideInForm:
        !isManualEntry ||
        event === 'Decommission' ||
        event === 'Transform' ||
        event === 'Aggregation' ||
        event === 'Disaggregate' ||
        event === 'Ship',
      colProps,
    },
    {
      title: t('form_fields.product_quantity_title'),
      dataIndex: 'quantity',
      hideInForm:
        !isManualEntry ||
        event === 'Decommission' ||
        event === 'Aggregation' ||
        event === 'Transform' ||
        event === 'Disaggregate' ||
        event === 'Ship',
      valueType: 'digit',
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.logistical_unit_id_required'),
          },
        ],
      },
      fieldProps: {
        addonAfter: 'LBS',
        stringMode: true,
        className: 'full-width',
        disabled: true,
        placeholder: t('form_fields.product_quantity_title'),
      },
      colProps,
    },
    {
      title: t('form_fields.date_title'),
      valueType: 'date',
      dataIndex: 'date',
      hideInForm: !isManualEntry,
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.logistical_unit_id_required'),
          },
        ],
      },
      fieldProps: {
        disabled: true,
        placeholder: t('form_fields.date_title'),
        className: 'full-width',
      },
      colProps,
    },
    {
      title: t('form_fields.time_title'),
      valueType: 'time',
      dataIndex: 'time',
      hideInForm: !isManualEntry,
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.logistical_unit_id_required'),
          },
        ],
      },
      fieldProps: {
        disabled: true,
        placeholder: t('form_fields.time_title'),
        className: 'full-width',
      },
      colProps,
    },
    {
      title: t('form_fields.ship_to_company_title'),
      dataIndex: 'shippingCompany',
      hideInForm: !isManualEntry || event !== 'Ship',
      valueType: 'select',
      valueEnum: {
        all: 'Beaver Street Fisheries',
      },
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.ship_to_company_req'),
          },
        ],
      },
      fieldProps: {
        disabled: true,
        placeholder: t('form_fields.ship_to_company_title'),
      },
      colProps,
    },
    {
      title: t('form_fields.ship_to_address_title'),
      dataIndex: 'shippingAddress',
      hideInForm: !isManualEntry || event !== 'Ship',
      valueType: 'select',
      valueEnum: {
        all: 'Bristol Bay, Alaska',
      },
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.ship_to_address_req'),
          },
        ],
      },
      fieldProps: {
        disabled: true,
        placeholder: t('form_fields.ship_to_address_title'),
      },
      colProps,
    },
    {
      title: t('form_fields.decomission_type_title'),
      dataIndex: 'decommissionType',
      hideInForm: !isManualEntry || event !== 'Decommission',
      valueType: 'select',
      valueEnum: {
        Sold: getProductsT('decommission.sold'),
        Damaged: getProductsT('decommission.damaged'),
        Destroyed: getProductsT('decommission.destroyed'),
        Disposed: getProductsT('decommission.disposed'),
        Expired: getProductsT('decommission.expired'),
      },
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.logistical_unit_id_required'),
          },
        ],
      },
      fieldProps: {
        disabled: true,
        placeholder: t('form_fields.decomission_type_title'),
      },
      colProps,
    },
    {
      title: t('form_fields.description_title'),
      dataIndex: 'description',
      hideInForm: !isManualEntry || event !== 'Decommission',
      valueType: 'textarea',
      valueEnum: {
        Sold: 'Sold',
        Damaged: 'Damaged',
      },
      fieldProps: {
        disabled: true,
        rows: 1,
        placeholder: t('form_fields.description_title'),
      },
      colProps,
    },
    {
      title: t('form_fields.time_zone_title'),
      dataIndex: 'timeZone',
      valueType: 'select',
      hideInForm: !isManualEntry || !hasAdvancedSettings,
      initialValue: '+06:00',
      valueEnum: {
        '+06:00': { text: 'Central (Chicago)', status: 'Default' },
      },
      fieldProps: {
        placeholder: t('form_fields.time_zone_title'),
        disabled: true,
      },
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.logistical_unit_id_required'),
          },
        ],
      },
      colProps,
    },
    {
      title: t('form_fields.bizstep_title'),
      dataIndex: 'bizStep',
      initialValue: 'urn:epcglobal:cbv:bizstep:shipping',
      valueType: 'select',
      hideInForm: !isManualEntry || !hasAdvancedSettings,
      renderFormItem: () => <BizStepSelect />,
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.bizstep_req'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.bizstep_placeholder'),
        disabled: true,
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.bizstep_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps,
    },
    {
      title: t('form_fields.disposition_title'),
      dataIndex: 'disposition',
      initialValue: 'urn:epcglobal:cbv:disp:active',
      valueType: 'select',
      renderFormItem: () => <DispositionSelect />,
      hideInForm: !isManualEntry || !hasAdvancedSettings,
      formItemProps: {
        rules: [
          {
            required: false,
            message: t('form_fields.disposition_req'),
          },
        ],
      },
      fieldProps: {
        placeholder: t('form_fields.disposition_title'),
        disabled: true,
      },
      tooltip: {
        icon: <InfoCircleOutlined />,
        title: t('form_fields.disposition_tooltip'),
        key: null,
        type: '',
        props: null,
      },
      colProps,
    },
    /* table for decomission, aggregation */
    {
      renderFormItem: () => <ProductsTable event={event} />,
      hideInForm:
        !isManualEntry ||
        !(
          event === 'Decommission' ||
          event === 'Aggregation' ||
          event === 'Disaggregate' ||
          event === 'Ship'
        ),
    },
    {
      valueType: 'divider',
      formItemProps: {
        className: 'no-margint-divider',
      },
      fieldProps: {
        className: 'no-margint-divider',
      },
      hideInForm: event === 'Aggregation' || event === 'Disaggregate',
    },
    {
      renderFormItem: () => (
        <Typography.Text className="ant-pro-form-group-title">
          {t('form_fields.custom_data_title')}
        </Typography.Text>
      ),
      hideInForm: event === 'Aggregation' || event === 'Disaggregate',
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['template'],
      },
      columns: ({ template }: { template: EventTemplatesResponse }) =>
        template?.templateAttributes?.map((f) => {
          const title = f?.attribute?.name;
          const fieldproperties = f?.attribute?.fieldProperties;
          const field = fieldproperties?.fields;
          const propertyName = fieldproperties?.fields?.propertyName;
          const fieldType = field?.fieldType;
          const values = fieldproperties?.values;
          return {
            title,
            dataIndex: propertyName || camelize(title || ''),
            valueType: fieldTypes[field?.fieldType || 'Text'].valueType,
            initialValue: values?.defaultValue || undefined,
            fieldProps: {
              ...fieldTypes[fieldType || 'Text'].fieldProps,
              size: 'middle',
              placeholder: title,
              options: values?.valueOptions,
            },
            formItemProps: {
              rules: [
                {
                  required: false,
                  message: t('form_fields.field_req', {
                    field: title,
                  }),
                },
              ],
            },
            hideInForm: event === 'Aggregation' || event === 'Disaggregate',

            colProps,
          };
        }) || [
          {
            renderFormItem: () => (
              <Typography.Text type="secondary">{t('no_template_selected')}</Typography.Text>
            ),
            hideInForm: event === 'Aggregation' || event === 'Disaggregate',
          },
        ],
    },
    {
      valueType: 'divider',
      formItemProps: {
        className: 'no-margint-divider',
      },
      fieldProps: {
        className: 'no-margint-divider',
      },
    },
    {
      valueType: 'dependency',
      fieldProps: {
        name: ['template'],
      },
      columns: ({ template }: { template: EventTemplatesResponse }) => [
        {
          dataIndex: 'documents',
          initialValue: [],
          renderFormItem: () => <Documents template={template} event={event} />,
          colProps: {
            xs: 24,
            md: 12,
          },
        },
      ],
    },
  ];
};

export default ViewTemplatePreviewFormFields;

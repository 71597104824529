import { Skeleton } from 'antd';
import styles from './index.module.less';

const HeaderLoader = () => (
  <div>
    <div className={styles.headerdiv}>
      <Skeleton active title={false} paragraph={{ rows: 1, width: '40%' }} />
      <Skeleton.Button active size="default" shape="round" />
    </div>
    <Skeleton
      active
      className={styles.button}
      title={false}
      paragraph={{ rows: 2, width: '100%' }}
    />
  </div>
);

export default HeaderLoader;

import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Button, ButtonProps, Popconfirm, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './index.module.less';
import { ActionButtonProps } from './typings';

const defaultBtnProps: ButtonProps = {
  size: 'small',
  ghost: false,
  type: 'text',
  className: 'value-actions-btn',
};

const ActionButtons = <RecordType extends {}>({
  showEdit = true,
  showDelete = true,
  showDuplicate = false,
  showHide = false,
  isHidden,
  onEdit,
  onDelete,
  onDuplicate,
  onToggleHide,
  record,
  editText,
  deleteText,
  duplicateText,
  editTooltipText,
  deleteTooltipText,
  duplicateTooltipText,
  editButtonProps,
  deleteButtonProps,
  duplicateButtonProps,
  showDeleteConfirm = false,
  deleteConfirmProps,
  hideButtonProps,
}: ActionButtonProps<RecordType>) => {
  const { t } = useTranslation('pages', { keyPrefix: 'gtable.actions' });

  return (
    <Space className="actions">
      {showEdit && (
        <Tooltip placement="bottom" title={editTooltipText || t('edit')}>
          <Button
            {...defaultBtnProps}
            {...editButtonProps}
            onClick={() => onEdit && onEdit(record)}
          >
            {!editText ? <EditOutlined className={styles.btnicon} /> : editText}
          </Button>
        </Tooltip>
      )}
      {showDuplicate && (
        <Tooltip placement="bottom" title={duplicateTooltipText || t('duplicate')}>
          <Button
            {...defaultBtnProps}
            {...duplicateButtonProps}
            onClick={() => onDuplicate && onDuplicate(record)}
          >
            {!duplicateText ? <CopyOutlined className={styles.btnicon} /> : duplicateText}
          </Button>
        </Tooltip>
      )}
      {showHide && (
        <Tooltip placement="bottom" title={isHidden ? t('show') : t('hide')}>
          <Button
            {...defaultBtnProps}
            {...hideButtonProps}
            onClick={() => onToggleHide && onToggleHide(record)}
          >
            {isHidden ? (
              <EyeInvisibleOutlined className={styles.btnicon} />
            ) : (
              <EyeOutlined className={styles.btnicon} />
            )}
          </Button>
        </Tooltip>
      )}
      {showDelete && (
        <Tooltip placement="bottom" title={deleteTooltipText || t('delete')}>
          {showDeleteConfirm ? (
            <Popconfirm
              placement="topRight"
              title={t?.('delete_confirm_title')}
              okText={t?.('delete_confirm_yes')}
              cancelText={t?.('delete_confirm_no')}
              {...deleteConfirmProps}
              onConfirm={() => onDelete && onDelete(record)}
            >
              <Button {...defaultBtnProps} {...deleteButtonProps}>
                {!deleteText ? <DeleteOutlined className={styles.btnicon} /> : deleteText}
              </Button>
            </Popconfirm>
          ) : (
            <Button
              {...defaultBtnProps}
              {...deleteButtonProps}
              onClick={() => onDelete && onDelete(record)}
            >
              {!deleteText ? <DeleteOutlined className={styles.btnicon} /> : deleteText}
            </Button>
          )}
        </Tooltip>
      )}
    </Space>
  );
};
export default ActionButtons;

import { Segmented, Space, Tabs } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { SegmentedValue } from 'antd/lib/segmented';
import { TitleBar } from 'components';
import { useTranslationContext } from 'contexts/TranslationProvider';
import { useEventCountModal } from 'pages/Events';
import { ProductItem } from 'pages/Products';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEventById, useEventMapById, useProductById } from 'services/api';
import { downloadJSONToCSVLotDetail, getNodeLifeCycleTime, getProductsT } from 'utils';
import { EventDiagram, EventList } from '.';
import EventContext from './EventContext';
import EventMap from './EventMap';
import {
  EventContextProps,
  EventTabs,
  EventTitleProps,
  GeneralActionItemsProps,
  LocationState,
} from './typings';
import useEventListActions from './useEventListActions';

const GeneralActionItems = ({ t }: GeneralActionItemsProps): Array<ItemType> => [
  {
    key: 'manage',
    label: t('manage_grp'),
    type: 'group',
    children: [
      {
        key: 'download_csv',
        label: t('actions.download_csv'),
      },
    ],
  },
];

const GeneralEventListActionItems = ({ t }: GeneralActionItemsProps): Array<ItemType> => [
  {
    key: 'download_xlsx',
    label: t('actions.download_xlsx'),
  },
];
const EventsTitle: FC<EventTitleProps> = ({ product, instances, tab, t }) => {
  const sections = useMemo(() => [t('details'), t('documents')], [t]);
  const [section, setSection] = useState<string | number>(sections[0]);
  const [queryParams] = useSearchParams();
  const eventId = queryParams?.get('eventId') || '1';
  const { onDownloadExcel } = useEventListActions({ eventIds: [eventId] });
  const navigate = useNavigate();
  const location = useLocation() as LocationState;
  const isContainerParam = queryParams?.get('isContainer') || false;

  const onDownloadCsv = () => {
    if (product && instances) {
      downloadJSONToCSVLotDetail(product, instances);
    }
  };

  const onActionItemClick = (actionItemKey: string) => {
    switch (actionItemKey) {
      case 'download_csv':
        onDownloadCsv();
        break;

      case 'download_xlsx':
        onDownloadExcel();
        break;

      default:
        break;
    }
  };

  const onSegmentedButtonClick = (value: SegmentedValue) => {
    setSection(value);
    switch (value) {
      case t('details'):
        break;
      case t('documents'):
        navigate(
          {
            pathname: 'documents',
            search: isContainerParam
              ? `?eventId=${eventId || '1'}&isContainer=${isContainerParam}`
              : `?eventId=${eventId || '1'}`,
          },
          { state: location.state },
        );
        break;

      default:
        break;
    }
  };
  const onBack = () => {
    const locationFrom = location.state?.from;
    if (locationFrom) {
      navigate(`${locationFrom}`);
    } else {
      navigate('../../');
    }
  };

  return (
    <TitleBar
      className="page-header"
      title={
        <Space>
          {product?.name || ''}
          <Segmented
            value={section || sections[0]}
            options={sections}
            onChange={onSegmentedButtonClick}
          />
        </Space>
      }
      onBack={onBack}
      actionItems={tab === 'list' ? GeneralEventListActionItems({ t }) : GeneralActionItems({ t })}
      onActionItemClick={onActionItemClick}
    />
  );
};
const EventDetails = () => {
  const { hideGoogleMap } = useTranslationContext();
  const [currentTab, setCurrentTab] = useState<EventTabs>(hideGoogleMap ? 'diagram' : 'map');
  const { t } = useTranslation('pages', { keyPrefix: 'products.event_details' });
  const { productId = '1', lotId = '' } = useParams();
  const [queryParams] = useSearchParams();
  const eventId = queryParams?.get('eventId') || undefined;
  const isContainer = queryParams?.get('isContainer') || false;

  useEventCountModal();
  const { data: currentProduct } = useProductById(productId);
  const { data: event } = useEventById(eventId);
  // const { data: productInstanceArray } = useMultipleProductInstances(
  //   !isContainer ? [lotId] : [],
  //   isContainer ? [lotId] : [],
  // );
  const { inventoryItem } = useShipmentStore();

  // const productInstance = productInstanceArray?.[0];
  const [showAllProducts, setShowAllProducts] = useState<boolean>(true);
  const { data: instances } = useEventMapById(eventId, {
    instanceId: lotId || undefined,
    ...(isContainer
      ? {
          containerId: lotId,
          instanceId: inventoryItem?.containerItems?.[0]?.instanceId || lotId || undefined,
        }
      : {}),
    showAllProducts,
  });
  const lifeCyle = useMemo(() => getNodeLifeCycleTime(instances?.nodes), [instances]);

  const product: ProductItem = {
    id: productId,
    epcUrn: currentProduct?.urn || '',
    name: event?.containers?.[0]?.containerIdentifier
      ? `${getProductsT('sscc')}: ${event?.containers?.[0]?.containerIdentifier || ''}`
      : currentProduct?.name || '',
    currentInventory: currentProduct?.currentInventory || 0,
  };
  const contextValue = useMemo<EventContextProps>(
    () => ({
      lifeCyle,
      showAllProducts,
      setShowAllProducts,
      inventoryItem,
    }),
    [lifeCyle, showAllProducts, inventoryItem],
  );

  const onTabChange = (activeKey: EventTabs) => {
    setCurrentTab(activeKey);
  };
  return (
    <>
      <EventsTitle product={product} tab={currentTab} event={event} instances={instances} t={t} />
      <EventContext.Provider value={contextValue}>
        <Tabs
          defaultActiveKey={hideGoogleMap ? 'diagram' : 'map'}
          onChange={(key) => onTabChange(key as EventTabs)}
        >
          {!hideGoogleMap && (
            <Tabs.TabPane key="map" tab={t('map')}>
              {currentTab === 'map' && (
                <EventMap
                  t={t}
                  product={currentProduct}
                  event={event}
                  primaryId={lotId}
                  isContainer={isContainer}
                  eventId={eventId}
                />
              )}
            </Tabs.TabPane>
          )}
          <Tabs.TabPane key="diagram" tab={t('diagram')}>
            {currentTab === 'diagram' && (
              <EventDiagram
                t={t}
                product={currentProduct}
                event={event}
                primaryId={lotId}
                eventId={eventId}
                isContainer={isContainer}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane key="list" tab={t('list')}>
            {currentTab === 'list' && (
              <EventList eventId={eventId} product={currentProduct} event={event} />
            )}
          </Tabs.TabPane>
        </Tabs>
      </EventContext.Provider>
      <Outlet />
    </>
  );
};
export default React.memo(EventDetails);

import type { BaseOptionType, DefaultOptionType } from 'rc-select/lib/Select';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetPaginatedProductInventoryOptionalParams } from 'services/api/client/src';
import { useProductInstances } from 'services/api/useProducts';
import { useDebouncedCallback } from 'use-debounce';
import { filterInvItem } from 'utils';
import GSelect from './GSelect';
import { FetchDataFn, ProductLotSelectProps } from './typings';

const ProductLotSelect = <
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  productId = '',
  disabledItem,
  initParams,
  ...props
}: ProductLotSelectProps<ValueType, OptionType>) => {
  const [params, setParams] = useState<GetPaginatedProductInventoryOptionalParams>({
    isHistory: false,
    pageNumber: 1,
    pageSize: 20,
    sortBy: '-loggedDate',
    ...initParams,
  });

  const { data, isLoading } = useProductInstances(productId, params);
  const { t } = useTranslation('pages', { keyPrefix: 'select' });

  /* we create here the list with the shape the select is expecting for */
  const productOptions: Array<DefaultOptionType> = useMemo(
    () =>
      data?.results?.filter(filterInvItem)?.map((lot) => ({
        label: lot?.productInstance?.lotSerial || '',
        value: lot?.productInstance?.id || lot?.container?.containerId || '',
        itemProps: lot,
        /* temporary solution to disable items */
        disabled: disabledItem?.(lot) || false,
      })) || [],
    [data?.results, disabledItem],
  );

  const debounced = useDebouncedCallback((filterText) => {
    setParams((oldParams) => ({ ...oldParams, lotSerial: filterText }));
  }, 1000);

  const fetchData: FetchDataFn<ValueType> = useCallback(
    ({ filterText, initialValue }) => {
      if (initialValue) {
        setParams((oldParams) => ({ ...oldParams, url: String(initialValue) }));
      } else {
        debounced(filterText);
      }
    },
    [debounced],
  );

  return (
    <GSelect<ValueType, OptionType>
      {...props}
      loading={isLoading}
      options={productOptions as OptionType[]}
      placeholder={t('product_lot_placeholder')}
      fetchData={fetchData}
      allowFilterOption={false}
    />
  );
};

export default ProductLotSelect;

import { QuestionCircleOutlined } from '@ant-design/icons';
import { ActionType, ProColumns } from '@ant-design/pro-table';
import { RecordKey } from '@ant-design/pro-utils/lib/useEditableArray';
import {
  DatePicker,
  FormInstance,
  Input,
  Modal,
  Segmented,
  Select,
  Space,
  Typography,
  message,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import {
  ActionButtons,
  ComingSoon,
  FieldTypeSelect,
  GTable,
  SingleColumnTags,
  SupportModal,
  TitleBar,
  useGTableContext,
} from 'components';
import { useModalVisibility } from 'hooks';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useAccountSubscription, useTemplates } from 'services/api';
import {
  CreateAttributeRequest,
  TemplateFieldContract,
  UpdateTemplateAttributeRequest,
} from 'services/api/client/src';
import {
  useCreateTemplateAttribute,
  useDeleteTemplateAttribute,
  useTemplateAttributes,
  useUpdateTemplateAttributeById,
} from 'services/api/eventTemplateAttributes';
import {
  camelize,
  errorHandler,
  featureFlag,
  getRecordValuev2,
  globalStyles,
  hiddencol,
  supportEmailLink,
} from 'utils';
import useAttributeStore from '../../hooks';
import styles from '../../index.module.less';
import RadioValueSelect from '../Select/RadioValueSelect';
import ValueSelect from '../Select/ValueSelect';
import AdvanceSettingToggle from './AdvanceSettingToggle';
import { DataItem, EventTableItem } from './EventTable.fields';
import { EventTableProps, TemplateAttributeTitleProps } from './typings';

type ColumnsType = {
  onEditRow: (actionPayload?: EventTableItem) => void;
  onDelRow: (actionPayload?: EventTableItem) => void;
  onChangeValue?: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void;
  onChangeFieldType: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void;
  onChangeStandard: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void;
  onChangeNamespace: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void;
  form: FormInstance<DataItem>;
  hasAdvancedSettings: boolean;
  selectedField: string | undefined;
  newValue: Array<string>;
  records: EventTableItem[];
};

const columns = (
  {
    onEditRow,
    onDelRow,
    onChangeFieldType,
    hasAdvancedSettings,
    selectedField,
    newValue,
    records,
  }: ColumnsType,
  t: any,
): Array<ProColumns<EventTableItem>> => [
  {
    title: t('field_attribute.title'),
    dataIndex: 'fieldAttribute',
    fixed: 'left',

    fieldProps: (form, { rowKey }) => ({
      type: 'text',
      size: 'small',
      placeholder: t('field_attribute.please_enter'),
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        form?.setFieldsValue({
          [String(rowKey)]: {
            fieldAttribute: e.target.value,
            dataAttribute: camelize(e.target.value),
          },
        });
      },
      disabled: !String(rowKey).includes('newField')
        ? !getRecordValuev2('allowDelete', records, rowKey, form)
        : false,
      // focus on new row
      autoFocus: String(rowKey).includes('newField'),
    }),
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('field_attribute.message'),
        },
        {
          whitespace: true,
          message: t('field_attribute.message'),
        },
      ],
      hasFeedback: false,
    },
  },
  {
    title: t('data_attribute.title'),
    tooltip: {
      title: t('data_attribute.info'),
      icon: <QuestionCircleOutlined />,
    },
    dataIndex: 'dataAttribute',
    fieldProps: {
      size: 'small',
      disabled: !hasAdvancedSettings,
      placeholder: t('field_attribute.please_enter'),
    },
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('data_attribute.message'),
        },
        {
          whitespace: true,
          message: t('data_attribute.message'),
        },
      ],
      hasFeedback: false,
    },
  },
  {
    title: t('field_type.title'),
    dataIndex: 'fieldType',
    formItemProps: {
      rules: [
        {
          required: true,
          message: t('field_type.message'),
        },
      ],
      hasFeedback: false,
    },
    renderFormItem: (form, { record }) => (
      <FieldTypeSelect
        disabled={!record?.allowDelete}
        key="ftselect"
        size="small"
        onChange={(value: string, option: any) => onChangeFieldType(value, option)}
      />
    ),
    render: (text) => (
      <>
        {text === 'Date' && <DatePicker style={globalStyles.w100} showTime size="small" disabled />}
        {text === 'Dropdown' && (
          <Select size="small" placeholder={t('field_type.dropdown')} disabled />
        )}
        {text === 'Text' && <Input size="small" placeholder={t('field_type.text')} disabled />}
        {text === 'Number' && <Input size="small" placeholder={t('field_type.number')} disabled />}
        {text === 'RadioButton' && (
          <Input size="small" placeholder={t('field_type.radio_button')} disabled />
        )}
      </>
    ),
  },
  {
    title: t('value'),
    dataIndex: 'value',
    fieldProps: {
      size: 'small',
    },
    renderFormItem: () =>
      // eslint-disable-next-line no-nested-ternary
      selectedField === 'Dropdown' ? (
        <ValueSelect key="valueselect" disabled={false} />
      ) : selectedField === 'RadioButton' ? (
        <RadioValueSelect key="radiovalueselect" disabled={false} />
      ) : null,
    render: (text, options) =>
      options.fieldType === 'Dropdown' || options.fieldType === 'RadioButton' ? (
        <SingleColumnTags
          values={options?.value || []}
          columnTitle={t('value')}
          modalTitle={options?.fieldAttribute || ''}
        />
      ) : null,
    formItemProps: {
      rules: [
        {
          message: t('value_req'),
          validator: () => {
            if (selectedField === 'Dropdown' || selectedField === 'RadioButton') {
              if (newValue?.length === 0) {
                return Promise.reject(t('value_req'));
              }
            }
            return Promise.resolve();
          },
        },
      ],
    },
  },
  {
    title: t('std'),
    dataIndex: 'stdOption',
    hideInTable: !hasAdvancedSettings,
    fieldProps: {
      size: 'small',
    },
    valueEnum: {
      ILMD: t?.('imld'),
      Extension: t?.('extension'),
    },
    initialValue: undefined,
  },
  {
    title: t('namespace'),
    dataIndex: 'namespace',
    hideInTable: !hasAdvancedSettings,
    fieldProps: {
      size: 'small',
    },
    valueEnum: {
      GDST: t?.('gdst'),
      CBV: t?.('cbv'),
    },
    initialValue: undefined,
  },
  {
    ...hiddencol,
    dataIndex: 'allowDelete',
  },
  {
    title: '',
    dataIndex: 'actions',
    fixed: 'right',
    width: 100,
    valueType: 'option',
    render: (text, record) => (
      <ActionButtons
        record={record}
        onDelete={onDelRow}
        onEdit={onEditRow}
        showEdit={record?.allowDelete ? true : hasAdvancedSettings}
        showDelete={record?.allowDelete}
      />
    ),
  },
];

const sections = ['Events', 'Documents'];

const DefinedAttributesTitle: FC<TemplateAttributeTitleProps> = ({
  section,
  setSection,
  actionsDisabled,
}) => {
  const { selectedRowKeys } = useGTableContext();
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes' });
  const segmentedOptions = useMemo(
    () => [
      { value: sections[0], label: t?.('defined_attributes.events') },
      { value: sections[1], label: t?.('defined_attributes.documents') },
    ],
    [t],
  );

  return (
    <TitleBar
      title={t('defined_attributes.title')}
      extraTitle={
        <Space>
          <Segmented value={section} options={segmentedOptions} onChange={setSection} />
          {!actionsDisabled && <AdvanceSettingToggle />}
        </Space>
      }
      extra={
        selectedRowKeys.length > 0 && (
          <Typography.Title level={5} className="selectiontext">
            {`${selectedRowKeys.length} ${
              selectedRowKeys.length === 1 ? t?.('select.item') : t?.('select.items')
            } ${t?.('selected')}`}
          </Typography.Title>
        )
      }
      className={styles.header}
      actionProps={{
        disabled: actionsDisabled,
      }}
    />
  );
};
const EventTable: FC<EventTableProps> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'template_attributes.event_table' });
  const { setFieldsValue } = form;
  const eventTableItemRef = useRef<ActionType>();
  const { hasAdvancedSettings, setSelectedRow, newValue, setNewValue } = useAttributeStore();
  const [selectedField, setSelectedField] = useState<string>();
  const [selectedProperty, setSelectedProperty] = useState<string>();
  const [selectedStd, setSelectedStd] = useState();
  const [selectedNamespace, setSelectedNamespace] = useState();
  const [section, setSection] = useState<string | number>(sections[0]);
  const templateAttr: Array<EventTableItem> = form.getFieldValue('eventTable');
  const setTemplateAttr = useCallback(
    (value: Array<EventTableItem>) => {
      setFieldsValue({
        eventTable: value,
      });
    },
    [setFieldsValue],
  );

  const queryClient = useQueryClient();
  const { data: dataTemplateAttributes, isLoading, isSuccess } = useTemplateAttributes();
  const { data: dataTemplates } = useTemplates();
  const templateAttributes = useMemo(
    () => dataTemplateAttributes?.results || [],
    [dataTemplateAttributes],
  );
  const totalItems = useMemo(
    () => dataTemplateAttributes?.totalItems || 0,
    [dataTemplateAttributes],
  );
  const { data: account } = useAccountSubscription();
  const subscription = useMemo(() => account?.subscriptionDetails || {}, [account]);
  // const subscription = mockSubscription;
  const supportModal = useModalVisibility(false);

  const createTemplateAttribute = useCreateTemplateAttribute(queryClient);
  const updateTemplateAttribute = useUpdateTemplateAttributeById(queryClient);
  const deleteTemplateAttribute = useDeleteTemplateAttribute(queryClient);

  useEffect(() => {
    if (isSuccess) {
      // filter advanced setting fields
      const modifiedAttributes =
        templateAttributes
          ?.filter((item) => !(item?.name === 'Disposition' || item?.name === 'Business Step'))
          ?.map((attribute) => ({
            id: attribute.id,
            fieldAttribute: attribute?.name,
            dataAttribute: attribute?.fieldProperties?.fields?.propertyName || '',
            fieldType: attribute?.fieldProperties?.fields?.fieldType || '',
            value: attribute?.fieldProperties?.values?.valueOptions,
            stdOption: attribute?.fieldProperties?.location || '',
            namespace: attribute?.fieldProperties?.namespace?.name || '',
            allowDelete: attribute?.allowDelete,
          })) || [];

      setTemplateAttr(modifiedAttributes);
    }
  }, [setTemplateAttr, templateAttributes, isSuccess]);

  const onChangeFieldType = (value: string, option: any) => {
    setSelectedField(option?.value);
  };

  const onChangeStandard = (value: string, option: any) => {
    if (option) {
      setSelectedStd(option?.label);
    }
  };

  const onChangeNamespace = (value: string, option: any) => {
    if (option) {
      setSelectedNamespace(option?.label);
    }
  };

  const onEditRow = (actionPayload?: EventTableItem) => {
    Modal.info({
      title: t('edit_attribute.consent_title'),
      content: t('edit_attribute.consent_content'),
      okText: t('edit_attribute.continue'),
      cancelText: t('edit_attribute.cancel'),
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
      },
      okButtonProps: {
        type: 'primary',
        shape: 'round',
      },
      centered: true,
      okCancel: true,
      onCancel: () => {
        eventTableItemRef.current?.cancelEditable(actionPayload?.id || 0);
      },
      onOk: () => {
        eventTableItemRef.current?.startEditable(actionPayload?.id || 0);
        setSelectedField(actionPayload?.fieldType);
        setNewValue(actionPayload?.value);
        setSelectedRow(actionPayload?.id);
        setSelectedProperty(actionPayload?.dataAttribute);
      },
    });
  };
  const onDeleteAttributeWarning = async (payload: EventTableItem) => {
    const name = payload?.fieldAttribute;
    const matchingTemplates =
      dataTemplates?.results?.filter(
        (template) =>
          template?.templateAttributes?.find((field) => field?.attributeId === payload?.id) !==
          undefined,
      ) || [];

    const content = (
      <p>
        {t('del_atr_desc', { name })}
        <br />
        <br />
        {matchingTemplates?.length > 0 ? (
          matchingTemplates?.map((template) => (
            <li key={template?.id}>• {template?.templateName}</li>
          ))
        ) : (
          <Typography.Text type="secondary">{t?.('no_match')}</Typography.Text>
        )}
      </p>
    );

    return new Promise((resolve, reject) => {
      Modal.warning({
        title: t('del_atr_title'),
        content,
        centered: true,
        okCancel: true,
        okText: t('del_atr_confirm'),
        cancelText: t('del_atr_cancel'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
        },
        cancelButtonProps: {
          type: 'primary',
          ghost: true,
          shape: 'round',
        },
        onOk: async () => {
          try {
            await deleteTemplateAttribute.mutateAsync({
              templateAttributeId: payload?.id || '',
              templates: matchingTemplates,
            });
            message.success(t('delete_success_attribute', { id: payload.fieldAttribute }));
          } catch (error) {
            const errMsg = errorHandler(error);
            if (errMsg !== 'USER_CANCEL') {
              message.error(errMsg);
            }
          }
          resolve(true);
        },
        onCancel: () => reject(new Error('USER_CANCEL')),
      });
    });
  };
  // Delete Attribute
  const onDelRow = async (actionPayload?: EventTableItem) => {
    if (actionPayload) {
      try {
        await onDeleteAttributeWarning(actionPayload);

        const afterDeleteAttribute = templateAttributes?.filter((a) => a.id !== actionPayload.id);
        const modifiedAttributes =
          afterDeleteAttribute?.map((attribute) => ({
            id: attribute.id,
            fieldAttribute: attribute?.name,
            dataAttribute: attribute?.fieldProperties?.fields?.propertyName || '',
            fieldType: attribute?.fieldProperties?.fields?.fieldType || '',
            value: attribute?.fieldProperties?.values?.valueOptions,
            stdOption: attribute?.fieldProperties?.location || '',
            namespace: attribute?.fieldProperties?.namespace?.name || '',
          })) || [];
        setTemplateAttr(modifiedAttributes);
      } catch (error) {
        const errMsg = errorHandler(error);
        if (errMsg !== 'USER_CANCEL') {
          message.error(errMsg);
        }
      }
    }
  };

  const onSaveTemplate = async (_rowKey: RecordKey, data: EventTableItem) => {
    eventTableItemRef.current?.cancelEditable(data?.id || 0);

    const existingTemplateAttribute = templateAttributes?.find(
      (attribute) => attribute.id === data?.id,
    );

    // update attribute
    if (existingTemplateAttribute) {
      setNewValue([]);
      try {
        const fieldProperties: TemplateFieldContract = {
          fields: {
            propertyName: data?.dataAttribute || selectedProperty || '',
            fieldType: selectedField || data?.fieldType || '',
            required: false,
          },
          values: {
            valueOptions:
              selectedField === 'RadioButton' ||
              selectedField === 'Dropdown' ||
              data?.fieldType === 'RadioButton' ||
              data?.fieldType === 'Dropdown'
                ? newValue
                : undefined,
            defaultValue: 'string',
          },

          namespace: {
            uri: 'wholechain.com',
            name: selectedNamespace || data?.namespace,
            prefix: 'wc',
          },
          location: selectedStd || data?.stdOption || undefined,
        };
        const attributeData: UpdateTemplateAttributeRequest = {
          id: data?.id || '',
          name: data?.fieldAttribute || '',
          fieldProperties,
        };
        const res = await updateTemplateAttribute.mutateAsync({
          id: data?.id || '',
          body: attributeData,
        });
        message.success(t('update_success_attribute', { id: res?.name }));
        setNewValue([]);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    }

    // add attribute
    else {
      try {
        const fieldProperties: TemplateFieldContract = {
          fields: {
            propertyName: data?.dataAttribute || '',
            fieldType: selectedField || '',
            required: false,
          },
          values: {
            defaultValue: 'string',
            valueOptions: newValue,
          },
          namespace: {
            uri: 'wholechain.com',
            name: selectedNamespace || data?.namespace || undefined,
            prefix: 'wc',
          },
          location: selectedStd || data?.stdOption || undefined,
        };
        const attributeData: CreateAttributeRequest = {
          name: data?.fieldAttribute || '',
          fieldProperties,
        };
        const res = await createTemplateAttribute.mutateAsync(attributeData);
        message.success(t('add_success_attribute', { id: res?.name }));
        setNewValue([]);
      } catch (error) {
        if (errorHandler(error)) {
          message.error(errorHandler(error));
        }
      }
    }

    const modifiedAttributes =
      templateAttributes?.map((attribute) => ({
        id: attribute.id,
        fieldAttribute: attribute?.name,
        dataAttribute: attribute?.fieldProperties?.fields?.propertyName || '',
        fieldType: attribute?.fieldProperties?.fields?.fieldType || '',
        value: attribute?.fieldProperties?.values?.valueOptions,
        stdOption: attribute?.fieldProperties?.location || '',
        namespace: attribute?.fieldProperties?.namespace?.name || '',
      })) || [];
    setTemplateAttr(modifiedAttributes);
    setSelectedStd(undefined);
    setSelectedNamespace(undefined);
  };

  const onAddAttribute = () => {
    if (
      featureFlag?.planCheck
        ? // @ts-ignore
          totalItems < subscription?.totalAttributes ||
          !Object.prototype.hasOwnProperty.call(subscription, 'totalAttributes')
        : true
    ) {
      /* return to page 1 if not on page 1, as the new record will be visible on page 1  */
      eventTableItemRef.current?.setPageInfo?.({
        current: 1,
      });

      eventTableItemRef.current?.addEditRecord?.(
        {
          id: `newField${Date.now().toString()}`,
          allowDelete: true,
        },
        {
          position: 'top',
        },
      );
      setSelectedRow('0');
    } else {
      supportModal.show();
    }
  };

  return section === 'Events' ? (
    <>
      <GTable<EventTableItem>
        key="eventTable"
        actionRef={eventTableItemRef}
        columns={columns(
          {
            onEditRow,
            onDelRow,
            onChangeFieldType,
            onChangeStandard,
            onChangeNamespace,
            form,
            hasAdvancedSettings,
            selectedField,
            newValue,
            records: templateAttr,
          },
          t,
        )}
        editable={{
          onSave: (rowKey, data) => onSaveTemplate(rowKey, data),
          onCancel: async (_rowKey, data) => {
            eventTableItemRef.current?.cancelEditable(data?.id || 0);
            setSelectedField(undefined);
          },
        }}
        options={{
          reload: false,
          setting: false,
        }}
        actionsRenderOptions={{
          save: true,
          cancel: true,
        }}
        recordCreatorProps={false}
        onAddRecordClick={onAddAttribute}
        headerTitle={<DefinedAttributesTitle section={section} setSection={setSection} />}
        loading={isLoading}
        value={templateAttr}
        enableRecordCreator
        addBtnText={t('add_attribute')}
        scroll={{ x: 1000, y: '75vh' }}
        columnsState={{
          persistenceKey: 'defined-attributes-event-tbl',
        }}
        pagination={{
          pageSize: 20,
          showSizeChanger: true,
          total: totalItems,
        }}
      />
      <SupportModal
        modal={supportModal}
        description={
          <div>
            {t('upgrade_modal_description')}&nbsp;
            <a href={supportEmailLink} type="mail">
              {t('support_anchor_email_title')}
            </a>
            &nbsp;
            {t('upgrade_modal_desc2')}
          </div>
        }
      />
    </>
  ) : (
    <>
      <DefinedAttributesTitle section={section} setSection={setSection} actionsDisabled />
      <ComingSoon />
    </>
  );
};
export default React.memo(EventTable);

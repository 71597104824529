import { Spin } from 'antd';
import { FC, useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import styles from './index.module.less';
import { PDFViewerProps } from './typings';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};
const PDFViewer: FC<PDFViewerProps> = ({ file, containerStyle, ...props }) => {
  const [numPages, setNumPages] = useState<number>();

  const onLoadSuccess = (pdf: any) => {
    const { numPages: nextNumPages } = pdf;
    setNumPages(nextNumPages);
  };
  return (
    <div id="pdf-viewer" className={styles.container} style={containerStyle}>
      <div className={styles.doccontainer}>
        <Document
          {...props}
          file={file}
          options={options}
          onLoadSuccess={onLoadSuccess}
          loading={<Spin />}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div id={`page_${index + 1}`}>
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className={styles.pagebreak} // forcepagebreak CSS selector for kendo react
              />
            </div>
          ))}
        </Document>
      </div>
    </div>
  );
};
export default PDFViewer;

import { Tabs } from 'antd';
import { TabTitle } from 'pages/Dashboard';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Notifications from './Notifications';
import RecentActivity from './RecentActivity';
import styles from './index.module.less';
import { NotificationBoxProps } from './typings';

const NotificationBox: FC<NotificationBoxProps> = ({ setVisible, setHasBellDot }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'notification' });
  const [hasRecentActivity, setHasRecentActivity] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);

  useEffect(() => {
    if (setHasBellDot !== undefined) {
      setHasBellDot(hasRecentActivity === true || hasNotification === true);
    }
  }, [hasRecentActivity, hasNotification, setHasBellDot]);
  return (
    <Tabs className={styles.tabs} defaultActiveKey="notifications">
      <Tabs.TabPane tab={<TabTitle title={t('sec1_title')} />} key="notifications">
        <Notifications setVisible={setVisible} setHasNotification={setHasNotification} />
      </Tabs.TabPane>
      <Tabs.TabPane tab={<TabTitle title={t('sec2_title')} />} key="recent_activity">
        <RecentActivity setHasRecentActivity={setHasRecentActivity} />
      </Tabs.TabPane>
    </Tabs>
  );
};
export default NotificationBox;

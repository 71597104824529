import { ProColumns } from '@ant-design/pro-table';
import { Typography } from 'antd';
import classNames from 'classnames';
import { GTable } from 'components';
import React, { FC } from 'react';
import { ContainerProductInstanceQuantity, getProductsT, getUom } from 'utils';
import { ContainerTableColumnProps, ContainerTableProps } from './typings';

/**
 * Columns
 * Defines the columns configuration for displaying container product instance quantity data.
 * @param columnProps Additional properties for configuring each column.
 * @param identifier Identifier for distinguishing between different types of columns.
 * @returns An array of ProColumns<ContainerProductInstanceQuantity> objects representing the columns configuration.
 */
const Columns = ({
  columnProps,
  identifier,
}: ContainerTableColumnProps): ProColumns<ContainerProductInstanceQuantity>[] => [
  {
    dataIndex: 'id',
    hideInTable: true,
    ...columnProps?.id,
  },
  {
    title: 'Primary ID',
    dataIndex: 'instanceId',
    hideInTable: true,
    ...columnProps?.instanceId,
  },
  {
    title: 'Lot Name',
    dataIndex: 'lotSerial',
    render: (text, record) => (
      <span>
        {getProductsT(identifier)}: {record?.lotSerial || ''} &nbsp;
        <Typography.Text type="secondary">
          {
            // @ts-ignore
            record?.productName || ''
          }
        </Typography.Text>
      </span>
    ),

    width: '16.5%',
    ...columnProps?.lotSerial,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    /* might need in future based on the requirement */
    // hideInTable: identifier === 'Serial',
    width: '12.5%',
    sorter: true,
    render: (text, record) => `${record?.quantity || 0} ${getUom({ product: record }) || 'LBS'}`,
    ...columnProps?.quantity,
  },
  {
    title: 'Current Inventory',
    dataIndex: 'currentInventory',
    width: '10.5%',
    sorter: true,
    hideInTable: true,
    render: (text, record) =>
      `${record?.totalInventoryAtLocation || 0} ${getUom({ product: record }) || 'LBS'}`,
    ...columnProps?.currentInventory,
  },
  {
    title: 'Last Event',
    dataIndex: 'lastEvent',
    width: '12.5%',
    ellipsis: true,
    ...columnProps?.lastEvent,
  },
  {
    title: 'Location',
    dataIndex: 'locationName',
    width: '36%',
    ellipsis: true,
    ...columnProps?.locationName,
  },
];

/**
 * ContainerTable
 * Represents a table component for displaying container product instance quantity data.
 * @param products The array of container product instance quantity data to be displayed.
 * @param showHeader Boolean indicating whether to display the table header.
 * @param className Additional CSS classes for styling the table.
 * @param columnProps Additional properties for configuring each column.
 * @param identifier Identifier for distinguishing between different types of columns.
 */
const ContainerTable: FC<ContainerTableProps> = ({
  products,
  showHeader,
  className,
  columnProps,
  identifier,
}) => (
  <GTable<ContainerProductInstanceQuantity>
    className={classNames('expanded-row-tbl', className)}
    columns={Columns({ columnProps, identifier })}
    options={{
      setting: false,
      reload: false,
    }}
    value={products}
    showHeader={showHeader}
  />
);
export default React.memo(ContainerTable);

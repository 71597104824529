// @ts-nocheck
import { FormInstance } from 'antd';
import { ProductInventoryItem } from 'pages/Products';
import { DataItem } from 'pages/Workflows/typings';
import {
  AggregateResponse,
  CommissionResponse,
  DecommissionResponse,
  DisaggregateResponse,
  EnrichedWorkflowCommissionEvent,
  EnrichedWorkflowDisaggregateEvent,
  EnrichedWorkflowObserveEvent,
  EnrichedWorkflowReceiveEvent,
  EnrichedWorkflowShipEvent,
  EnrichedWorkflowTransformEvent,
  ReceiveResponse,
  ShipResponse,
  TransformResponse,
} from 'services/api/client/src';

export const onCreateStoreInstance = (
  p: ProductInventoryItem,
  instances?: Array<ProductInventoryItem>,
  form?: FormInstance<DataItem>,
) => {
  const instanceExists = instances?.find((instance) => instance?.id === p?.id);
  if (!instanceExists) {
    const newInstances = [...(instances || []), p];
    form?.setFieldsValue({
      instances: newInstances,
    });
  } else {
    form?.setFieldsValue({
      instances: instances?.map((instance) => {
        if (instance?.id === p?.id) {
          return p;
        }
        return instance;
      }),
    });
  }
};

export const onDeleteStoreInstance = (
  p: ProductInventoryItem,
  instances: Array<ProductInventoryItem>,
  form: FormInstance<DataItem>,
) => {
  const newInstances = instances?.filter((instance) => instance?.id !== p?.id);
  form?.setFieldsValue({
    instances: newInstances,
  });
};
export type WorkflowEventType = Partial<
  EnrichedWorkflowCommissionEvent &
    EnrichedWorkflowDisaggregateEvent &
    EnrichedWorkflowObserveEvent &
    EnrichedWorkflowReceiveEvent &
    EnrichedWorkflowShipEvent &
    EnrichedWorkflowTransformEvent & {
      eventType?: string;
    }
>;
export type EventResponse = Partial<
  CommissionResponse &
    DecommissionResponse &
    AggregateResponse &
    DisaggregateResponse &
    ShipResponse &
    ReceiveResponse &
    TransformResponse & {
      eventId?: string;
    }
>;

export const coreStatus = [
  {
    text: 'InProgress',
    value: 'InProgress',
  },
  {
    text: 'Completed',
    value: 'Completed',
  },
];

/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { message, Space, Typography } from 'antd';
import useProductStore from 'pages/Products/useProductStore';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getEventResponse } from 'services/api';
import { EventResponse, InventoryResponse, ObserveRequest } from 'services/api/client/src';
import { useCreateObserveEvent, useCreateObserveEvents } from 'services/api/events';
import { useMultipleProductInstances } from 'services/api/useProducts';
import {
  apiDateFormat,
  checkRequestedDocuments,
  errorHandler,
  getBizStepIdByURN,
  getCustomProperties,
  getDispositionIdByURN,
  getProductsT,
  getTimezoneOffset,
  getUom,
  mapCertProperties,
} from 'utils';
import { useEventsStore, useTitle } from '../hooks';
import { useEventActions } from './Forms';
import CommissionFormFields, { DataItem } from './Forms/CommissionForm.fields';
import { EventPageProps, PaginatedContainerResponse } from './typings';

const Observe: FC<EventPageProps<DataItem>> = ({ form }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.observe' });
  useTitle('observe');
  const navigate = useNavigate();

  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const { hasAdvancedSettings, dataEntryMethod, setSubmitting, template, setTemplate } =
    useEventsStore();
  const {
    productId,
    product: dataProduct,
    productIdArray,
    containerIdArray,
    lastEventDate,
    lotId,
    defaultTimezone,
    isSerial,
    identifier,
    timeValidation,
    disabledDate,
    disabledTime,
    onChangeDate,
    clearSameDayMessage,
    onChangeTemplate,
  } = useEventActions();

  const { paramsStore } = useProductStore();

  const { data: containerRes } = useMultipleProductInstances([], containerIdArray);
  const container = containerRes?.[0] as PaginatedContainerResponse;

  const { data: dataProducts = [] } = useMultipleProductInstances(productIdArray, [], paramsStore);
  useEffect(() => {
    if (dataProducts?.length) {
      const productInstance = (dataProducts[0] as InventoryResponse)?.productInstance;
      form?.setFieldsValue({
        quantity: productInstance?.quantity || 0,
      });
    }
  }, [dataProducts, form]);

  const onViewEvent = useCallback(
    (formData: DataItem, res?: EventResponse) => {
      const instanceId = res?.productInstances?.[0]?.id || res?.containers?.[0]?.id || '1';
      const eventId = res?.id || '1';
      const containerstr = res?.containers?.[0]?.id ? '&isContainer=true' : '';

      navigate({
        pathname: `/products/${productId}/${instanceId}`,
        search: `?eventId=${eventId}${containerstr}`,
      });
    },
    [navigate, productId],
  );

  const queryClient = useQueryClient();
  const createObserveEvent = useCreateObserveEvent(queryClient);
  const createObserveEvents = useCreateObserveEvents(queryClient);
  const onCSVSubmit = async (formData: DataItem) => {
    try {
      form?.setFieldsValue({
        bizStep: 'urn:epcglobal:cbv:bizstep:accepting',
      });
      const values = formData?.csvData;
      if (values?.length) {
        await editableTableFormRef.current?.validateFields();
        const requests: Array<ObserveRequest> = [];
        values?.forEach((value) => {
          const reqData: ObserveRequest = {
            eventTime: apiDateFormat(value.date, value.time),
            eventTimeZone: getTimezoneOffset(value.timeZone),
            bizStepId: getBizStepIdByURN(value.bizStep),
            dispositionId: getDispositionIdByURN(value.disposition),
            locationId: value.location,
            purchaseOrder: formData.poNumber,
            containerIds: containerIdArray,
            productInstances: !containerIdArray?.length
              ? [
                  {
                    id: value.lotID,
                    quantity: !isSerial ? Number(value?.quantity || 0) : 1,
                  },
                ]
              : [],
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            masterData: getCustomProperties(formData?.customProperties, template),
            certifications: mapCertProperties(formData?.certificationList || []),
          };
          requests.push(reqData);
        });
        await createObserveEvents.mutateAsync(requests);
        message.success(
          t('csv_submit_success', {
            count: requests?.length || 0,
            identifier: getProductsT(identifier)?.toLowerCase(),
          }),
        );
      } else {
        throw new Error('No data to submit');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ CSV upload observe onFormSubmit ~ error', error);

      throw error;
    }
  };
  const onFormSubmit = async (formData: DataItem) => {
    try {
      const reqData: ObserveRequest = {
        bizStepId: getBizStepIdByURN(formData.bizStep),
        dispositionId: getDispositionIdByURN(formData.disposition),
        eventTime: apiDateFormat(formData.date, formData.time),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        purchaseOrder: formData.poNumber,
        containerIds: containerIdArray,
        productInstances: !containerIdArray?.length
          ? [
              {
                id: formData.lotID,
                quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
              },
            ]
          : [],
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        masterData: getCustomProperties(formData?.customProperties, template),
        certifications: mapCertProperties(formData?.certificationList || []),
      };
      const res = await createObserveEvent.mutateAsync({
        observations: [reqData],
      });
      /* get event response */
      const event = await getEventResponse(res);

      const msg = t('submit_success', {
        type: event?.containers?.[0]?.id ? getProductsT('sscc') : `${getProductsT(identifier)}`,
        id:
          event?.productInstances?.[0]?.lotSerial ||
          event?.containers?.[0]?.containerIdentifier ||
          '',
      });
      message.success(
        <Space>
          {msg}
          <Typography.Link
            onClick={(e) => {
              e.preventDefault();
              onViewEvent(formData, event);
            }}
          >
            {t('view_event')}
          </Typography.Link>
        </Space>,
      );
    } catch (error: any) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);
      checkRequestedDocuments({ template, documents: formData?.documents });

      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
      } else {
        await onCSVSubmit(formData);
      }
      setSubmitting(false);
      clearSameDayMessage();
      navigate(-1);
    } catch (error) {
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
      setSubmitting(false);
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      columns={CommissionFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        isObserve: true,
        editableTableFormRef,
        container,
        defaultTimezone,
        lastEventDate,
        template,
        lotId,
        productId,
        isSerial,
        identifier,
        timeValidation,
        disabledDate,
        disabledTime,
        onChangeDate,
        onChangeTemplate,
        setTemplate,
        t,
      })}
      form={form}
      grid
      rowProps={{ gutter: 32 }}
      layoutType="Form"
      onFinish={onFinish}
      // we don't need a submitter here since we are submitting from the drawer footer
      submitter={{ render: () => null }}
      initialValues={{
        unitOfMeasure: getUom({ product: dataProduct }),
      }}
    />
  );
};

export default Observe;

// @ts-nocheck
/* eslint-disable no-useless-catch */
import { BetaSchemaForm } from '@ant-design/pro-form';
import { EditableFormInstance } from '@ant-design/pro-table';
import { EdgeConfig, GraphData, NodeConfig } from '@antv/g6/lib/types';
import { Form, message } from 'antd';
import { useActions } from 'hooks';
import {
  CommissionDataItem,
  CommissionFormFields,
  Header,
  useEventActions,
  useEventsStore,
  useTitle,
} from 'pages/Events';
import { ProductInventoryItem } from 'pages/Products';

import { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { EnrichedWorkflowCommissionEvent } from 'services/api/client/src';
import {
  apiDateFormat,
  dateFormat,
  errorHandler,
  getLocationById,
  getLocationNameById,
  getProductNameById,
  getTimezoneOffset,
  getUom,
  timeLocaleFormat,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';
import styles from '../index.module.less';
import { EventProps, WorkflowEvent } from '../typings';

type DataItem = CommissionDataItem;
const Commission: FC<EventProps> = ({ form, drawer, event, isVisible, instances }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'events.commission' });
  useTitle(t('title'));
  const locationDisabled = false;
  const [mainForm] = Form.useForm<DataItem>();
  const formProductId = Form.useWatch(['product'], mainForm);
  const { setFieldsValue } = mainForm;
  const editableTableFormRef = useRef<EditableFormInstance<DataItem>>();
  const graphData: GraphData = form?.getFieldValue('graphData') || {
    nodes: [],
    edges: [],
  };

  const {
    productId,
    product: dataProduct,
    lotId,
    onChangeTemplate,
    defaultTimezone,
    isSerial,
    identifier,
    locationId,
    locationName,
    onChangeLocation,
    onChangeDate,
    defaultUom,
  } = useEventActions({
    productId: event?.productId || undefined,
    locationId: event?.locationId || undefined,
  });
  const {
    hasAdvancedSettings,
    dataEntryMethod,
    template,
    setSubmitting,
    setTemplate,
    setAdvancedSettings,
  } = useEventsStore();

  const {
    products,
    locations,
    product,
    template: initTemplate,
  } = useActions({
    productId: formProductId,
    templateId: event?.eventData?.templateId || event?.eventTemplateId,
  });

  const onMount = useCallback(() => {
    setAdvancedSettings(false);

    if (event) {
      const edata = event?.eventData as EnrichedWorkflowCommissionEvent;
      const templateId = edata?.templateId || event?.eventTemplateId || undefined;
      setFieldsValue({
        product: edata?.newProducts?.[0]?.productId || event?.productId,
        date: edata?.eventTime ? dateFormat(edata?.eventTime) : undefined,
        time: edata?.eventTime ? timeLocaleFormat(edata?.eventTime) : undefined,
        unitOfMeasure: edata?.newProducts?.[0]?.unitOfMeasure || defaultUom,
        lotID: edata?.newProducts?.[0]?.lotSerial,
        quantity: edata?.newProducts?.[0]?.quantity,
        location: event?.locationId || event?.locationId,
        poNumber: edata?.purchaseOrder,
        customData: templateId,
      });
    }
  }, [event, setAdvancedSettings, setFieldsValue, defaultUom]);

  const onGetInitTemplate = useCallback(() => {
    if (event && initTemplate) {
      const edata = event?.eventData as EnrichedWorkflowCommissionEvent;
      const templateId = edata?.templateId || event?.eventTemplateId || '';
      onChangeTemplate?.(
        mainForm,
        {
          itemProps: initTemplate,
        },
        templateId,
        locationId,
      );
      if (edata) {
        setFieldsValue({
          customProperties: edata?.customProperties?.map((el) => el?.value || '') || [],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTemplate, event]);
  /* effect to sync eventData in event */
  useEffect(() => onMount(), [onMount]);
  useEffect(() => onGetInitTemplate(), [onGetInitTemplate]);

  const onCloseDrawer = () => {
    mainForm?.resetFields();
    drawer?.closeDrawer();
  };

  const onCreateStoreInstance = (p: ProductInventoryItem) => {
    const instanceExists = instances?.find((instance) => instance?.id === p?.id);
    if (!instanceExists) {
      const newInstances = [...(instances || []), p];
      form?.setFieldsValue({
        instances: newInstances,
      });
    } else {
      form?.setFieldsValue({
        instances: instances?.map((instance) => {
          if (instance?.id === p?.id) {
            return p;
          }
          return instance;
        }),
      });
    }
  };
  const onMultipleCreateStoreInstance = (p: Array<ProductInventoryItem>) => {
    const newInstances = [...(instances || []), ...p];
    form?.setFieldsValue({
      instances: newInstances,
    });
  };
  const onCSVSubmit = async (formData: DataItem) => {
    const values = formData?.csvData;
    try {
      /* assuming that the first node is the current node */
      const existingNodes = graphData?.nodes?.filter((node) => node?.id !== event?.id) || [];
      const nextNode = graphData?.nodes?.[1];
      const newEdges = graphData?.edges?.filter((edge) => edge?.source !== event?.id) || [];
      if (values?.length) {
        await editableTableFormRef.current?.validateFields();
        const updatedNodes: Array<NodeConfig> = [];
        const updatedEdges: Array<EdgeConfig> = [];
        const newInstances: Array<ProductInventoryItem> = [];

        values?.forEach((value) => {
          const newId = uuidv4();

          const reqData: EnrichedWorkflowCommissionEvent = {
            id: newId,
            eventIdInTemplate: event?.id,
            eventId: event?.id,
            bizStep: value.bizStep,
            disposition: value.disposition,
            eventTimeZone: getTimezoneOffset(value.timeZone),
            eventTime: apiDateFormat(value.date, value.time),
            locationId: formData.location,
            purchaseOrder: value.poNumber,
            products: [
              {
                productId: formData.product,
                quantity: !isSerial ? Number(value?.quantity || 0) : 1,
                lotSerial: value.lotID,
              },
            ],
            newProducts: [
              {
                productId: formData.product,
                quantity: !isSerial ? Number(value?.quantity || 0) : 1,
                lotSerial: value.lotID,
              },
            ],
            documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
            templateId: template?.id,
            templateName: template?.name,
            customProperties:
              template?.templateFields?.map((field) => ({
                name: field?.name || field?.propertyName,
                value: String(value?.[String(field?.propertyName || field?.name || '')] || ''),
                namespace: field?.namespace?.prefix || 'wc',
              })) || [],
          };
          /* create instance in quantities */
          newInstances.push({
            id: newId,
            eventId: event?.id,
            name: getProductNameById(formData.product, products),
            productId: product?.id,
            instanceId: event?.id,
            primaryId: value.lotID,
            lotSerial: value.lotID,
            quantity: !isSerial ? Number(value?.quantity || 0) : 1,
            unitOfMeasure: getUom({ product }) || 'LBS',
            eventName: 'Commission',
            location: getLocationById(formData.location, locations)?.name || '',
            locationAddress: getLocationById(formData.location, locations)?.address?.line2 || '',
            locationId: formData.location || '',
            destinationId: undefined,
            isContainer: false,
            containerId: undefined,
            containerIdentifier: '',
            containerItems: [],
            tradePartnerName: getLocationById(formData.location, locations)?.tradePartnerName || '',
            address: getLocationById(formData.location, locations)?.address,
            eventDate: reqData.eventTime,
            loggedDate: new Date(),
            purchaseOrder: value.poNumber,
            shipmentType: undefined,
            shipped: false,
            productIdentifierType: product?.productIdentifierType,
            templateId: template?.id,
            bizStep: value.bizStep,
            disposition: value.disposition,
            timezone: value.timeZone,
          });

          const updatedNode: NodeConfig = {
            id: newId,
            eventType: 'Commission',
            eventTime: reqData?.eventTime ? dateFormat(reqData?.eventTime) : undefined,
            name: getProductNameById(formData.product, products),
            primaryId: value.lotID,
            quantity: !isSerial ? Number(value?.quantity || 0) : 1,
            unitOfMeasure: getUom({ product }),
            eventTemplateId: event?.eventTemplateId,
            eventTemplateName: getLocationById(formData.location, locations)?.address?.line2 || '',
            locationId: formData?.location,
            locationName: getLocationNameById(formData.location, locations),
            productId: formData.product,
            productName: getProductNameById(formData.product, products),
            colourIndex: 'colour_4',
            children: [],
            eventData: reqData,
          };
          updatedNodes.push(updatedNode);
          if (nextNode) {
            const updatedEdge: EdgeConfig = {
              source: updatedNode?.id,
              target: nextNode?.id,
            };
            updatedEdges.push(updatedEdge);
          }
        });

        onMultipleCreateStoreInstance(newInstances);

        const updatedGraphData: GraphData = {
          ...graphData,
          nodes: [...updatedNodes, ...existingNodes],
          edges: [...updatedEdges, ...newEdges],
        };

        form?.setFieldsValue({
          graphData: updatedGraphData,
        });

        setSubmitting(false);
      } else {
        throw new Error('No data to submit');
      }
    } catch (error) {
      throw error;
    }
  };

  const onFormSubmit = async (formData: DataItem) => {
    try {
      const updatedBody: EnrichedWorkflowCommissionEvent = {
        id: event?.id,
        eventIdInTemplate: event?.id,
        eventId: event?.id,
        bizStep: formData.bizStep,
        disposition: formData.disposition,
        eventTime: apiDateFormat(formData.date, formData.time),
        eventTimeZone: getTimezoneOffset(formData.timeZone),
        locationId: formData.location,
        purchaseOrder: formData.poNumber,
        products: [
          {
            productId: formData.product,
            quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
            lotSerial: formData.lotID?.trim(),
          },
        ],
        newProducts: [
          {
            productId: formData.product,
            quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
            lotSerial: formData.lotID?.trim(),
          },
        ],
        documentsIds: formData?.documents?.map((doc) => doc?.id || ''),
        templateId: template?.id,
        templateName: template?.name,
        customProperties:
          template?.templateFields?.map((field, idx) => ({
            name: field?.name || field?.propertyName,
            value: formData?.customProperties?.[idx]
              ? String(formData.customProperties?.[idx])
              : '',
            namespace: field?.namespace?.prefix || 'wc',
          })) || [],
      };
      const location = getLocationById(formData.location, locations);

      /* create instance in quantities */
      onCreateStoreInstance({
        id: event?.id,
        name: getProductNameById(formData.product, products),
        productId: product?.id,
        instanceId: event?.id,
        primaryId: formData.lotID,
        lotSerial: formData.lotID,
        quantity: Number(formData?.quantity || 0),
        unitOfMeasure: getUom({ product }) || 'LBS',
        eventName: 'Commission',
        location: location?.name || '',
        locationAddress: location?.address?.line2 || '',
        locationId: formData.location || '',
        destinationId: undefined,
        eventId: event?.id,
        isContainer: false,
        containerId: undefined,
        containerIdentifier: '',
        containerItems: [],
        tradePartnerName: location?.tradePartnerName || '',
        address: location?.address,
        eventDate: updatedBody.eventTime,
        loggedDate: new Date(),
        purchaseOrder: formData.poNumber,
        shipmentType: undefined,
        shipped: false,
        productIdentifierType: product?.productIdentifierType,
        templateId: template?.id,
      });

      const updatedNodes = graphData.nodes?.map((item: WorkflowEvent) => {
        if (item.id === event?.id) {
          const updatedNode: NodeConfig = {
            id: item?.id || '',
            eventType: 'Commission',
            eventTime: updatedBody?.eventTime ? dateFormat(updatedBody?.eventTime) : undefined,
            name: getProductNameById(formData.product, products),
            primaryId: formData.lotID,
            quantity: !isSerial ? Number(formData?.quantity || 0) : 1,
            unitOfMeasure: getUom({ product }),
            eventTemplateId: item?.eventTemplateId,
            eventTemplateName: location?.address?.line2 || location?.name || '',
            locationId: formData?.location,
            locationName: getLocationNameById(formData.location, locations),
            productId: formData.product,
            productName: getProductNameById(formData.product, products),
            colourIndex: 'colour_4',
            children: [],
          };
          return {
            ...item,
            ...updatedNode,
            eventData: updatedBody,
          };
        }
        return item;
      });
      const updatedGraphData = {
        ...graphData,
        nodes: updatedNodes,
      };

      form?.setFieldsValue({
        // @ts-ignore
        graphData: updatedGraphData,
      });
    } catch (error) {
      throw error;
    }
  };

  const onFinish = async (formData: DataItem) => {
    try {
      setSubmitting(true);

      if (dataEntryMethod === 'manual') {
        await onFormSubmit(formData);
        setSubmitting(false);
      } else {
        await onCSVSubmit(formData);
      }
      onCloseDrawer();
    } catch (error: any) {
      setSubmitting(false);
      if (errorHandler(error)) {
        message.error(errorHandler(error));
      }
    }
  };

  return (
    <BetaSchemaForm<DataItem>
      form={mainForm}
      layoutType="DrawerForm"
      columns={CommissionFormFields({
        hasAdvancedSettings,
        dataEntryMethod,
        editableTableFormRef,
        template,
        productId,
        lotId,
        defaultTimezone,
        isSerial,
        identifier,
        setTemplate,
        onChangeDate,
        onChangeTemplate,
        t,
      })}
      grid
      rowProps={{ gutter: 32 }}
      onFinish={onFinish}
      // @ts-ignore
      title={
        <Header
          isManual={dataEntryMethod === 'manual'}
          title={t('title')}
          form={mainForm}
          locationId={locationId?.trim() ? locationId?.trim() : undefined}
          locationName={locationName}
          locationDisabled={locationDisabled}
          onChangeLocation={onChangeLocation}
        />
      }
      submitter={{
        searchConfig: {
          submitText: t('add_event_title'),
          resetText: t('cancel_btn_title'),
        },
        submitButtonProps: {
          shape: 'round',
        },
        resetButtonProps: {
          shape: 'round',
          type: 'primary',
          ghost: true,
        },
      }}
      initialValues={{
        unitOfMeasure: getUom({ product: dataProduct }),
      }}
      visible={isVisible ? drawer?.visible || false : false}
      drawerProps={{
        destroyOnClose: true,
        height: '100%',
        placement: 'top',
        size: 'large',
        className: styles.eventdrawer,
        ...drawer,
      }}
      key={event?.id}
    />
  );
};

export default Commission;

import { DeleteOutlined, InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, ColProps, Form, Space, Tooltip, Typography, Upload, message } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import useListLocation from 'hooks/useListLocation';
import { useEventActions } from 'pages/Events/components';
import { useEventsStore } from 'pages/Events/hooks';
import { useShipmentStore } from 'pages/Shipments';
import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAttributes,
  useExternalShipments,
  useProductInstances,
  useProducts,
  useShipments,
  useTemplateById,
  useTradePartners,
} from 'services/api';
import { TemplateCertificationResponse } from 'services/api/client/src';
import {
  CSVTemplate,
  certificationMappings,
  checkDateFormat,
  checkTimeFormat,
  coreBizStep,
  coreDispositions,
  dateFormat,
  downloadXLSXTemplate,
  filterHiddenAttributes,
  getAssignedProductId,
  getBizStepByIdentifier,
  getDispositionByIdentifier,
  getExternalShipmentByName,
  getInstanceIdByLot,
  getInventoryInstanceIdByLot,
  getLocationIdByName,
  getProductIdByName,
  getProductsT,
  getShipmentByName,
  getShipmentContainerByName,
  getTimezoneByOffsetorText,
  getTradePartnerOByName,
  getUom,
  msofficeHelpLink,
  readExcelTemplate,
  timeLocaleFormat,
  titletoKeys,
} from 'utils';
import { getDefaultValues } from 'utils/locations';
import { v4 as uuidv4 } from 'uuid';
import XLSX from 'xlsx';
import {
  commissionTemplate,
  decomissionTemplate,
  getTranslatedCSVTemplate,
  observeTemplate,
  receiveTemplate,
  shipTemplate,
  transformTemplate,
} from '../CSVTemplates';
import CommissionTable from '../Forms/CSVTables/CommissionTable';
import { DataItem } from '../Forms/CommissionForm.fields';
import styles from './index.module.less';
import { CustomDataTemplateProps } from './typings';

const uploadColProps: ColProps = { xs: 24, sm: 24, md: 12, lg: 12 };

const { Text, Link } = Typography;
const { Dragger } = Upload;

export const CustomDataTemplate: FC<CustomDataTemplateProps> = () => {
  const template = useEventsStore((state) => state.template);
  const { t } = useTranslation('pages', { keyPrefix: 'products' });
  return (
    <div>
      <Text className="ant-pro-form-group-title">{t('custom_data_template')}</Text>
      {(!template || !template?.templateAttributes) && (
        <div className={styles.content}>
          <Text type="secondary">{t('no_template')}</Text>
        </div>
      )}
    </div>
  );
};

export const CSVUpload: FC<CustomDataTemplateProps> = ({ form, editableTableFormRef }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'products.data_template' });
  const { title } = useEventsStore();
  const { setFieldsValue } = form;
  const templateId = Form.useWatch('customData', form);
  const formProductId = Form.useWatch('product', form);
  const { productId, isSerial, defaultUom: duom } = useEventActions();
  const { data: template } = useTemplateById(templateId);
  const { data: locations } = useListLocation();
  const { data: tradePartners } = useTradePartners();
  // used to fectch total item info from products array
  const { data: preProducts } = useProducts();
  // products array with all of the items
  const { data: products } = useProducts({ pageSize: preProducts?.totalItems });
  const { data: attributes } = useAttributes();
  const { data: instances } = useProductInstances(productId, {
    pageSize: 9999,
  });
  const { data: dataToGetTotalItems, isLoading: isgetTotalItemsLoadingShipments } = useShipments({
    direction: 'Inbound',
    status: ['Pending'],
    sortBy: '-LoggedDate',
    pageSize: 20,
  });

  const { data: dataShipments, isLoading: isLoadingShipments } = useShipments({
    direction: 'Inbound',
    status: ['Pending'],
    sortBy: '-LoggedDate',
    pageSize: dataToGetTotalItems?.totalItems,
  });

  const { data: externalShipments } = useExternalShipments({
    sortBy: '-LoggedDate',
    status: ['Pending', 'Rejected'],
    pageSize: 9999,
  });

  const defaultUom = useMemo(() => {
    const formProduct = products?.results?.find((el) => el?.id === formProductId);
    const uom = formProduct ? getUom({ product: formProduct }) : undefined;
    return uom || duom;
  }, [duom, formProductId, products?.results]);

  const uomStr = useMemo(() => {
    const pageKey = String(title).toLowerCase();
    const eventsUomNotRequired = ['transform'];
    if (eventsUomNotRequired.includes(pageKey)) {
      return '';
    }
    return ` (${defaultUom.trim()})`;
  }, [defaultUom, title]);

  const csvData: Array<DataItem> = Form.useWatch?.('csvData', form) || [];
  const locationId = Form.useWatch?.('location', form) || undefined;
  const autologReceiveEvent = Form.useWatch?.('autologReceiveEvent', form) || false;

  const defaultValues = useMemo(
    () => getDefaultValues({ template, attributes, locationId }),
    [locationId, template, attributes],
  );

  const templateAttributes = useMemo(
    () =>
      template?.templateAttributes?.filter(filterHiddenAttributes)?.reduce((acc, templateObj) => {
        if (
          templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep' ||
          templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'disposition'
        ) {
          if (templateObj?.attribute?.fieldProperties?.values?.defaultValue) {
            const initialValue =
              templateObj?.attribute?.fieldProperties?.fields?.propertyName === 'bizStep'
                ? coreBizStep()?.find?.(
                    (el) =>
                      el.urn === templateObj?.attribute?.fieldProperties?.values?.defaultValue,
                  )?.name || undefined
                : coreDispositions()?.find?.(
                    (el) =>
                      el.urn === templateObj?.attribute?.fieldProperties?.values?.defaultValue,
                  )?.name || undefined;
            acc[String(templateObj?.attribute?.fieldProperties?.fields?.propertyName || '')] = {
              dataIndex: templateObj?.attribute?.fieldProperties?.fields?.propertyName || '',
              title: templateObj?.attribute.name || '',
              initialValue,
            };
          }
        } else {
          acc[String(templateObj?.attribute?.fieldProperties?.fields?.propertyName || '')] = {
            dataIndex: templateObj?.attribute?.fieldProperties?.fields?.propertyName || '',
            title: templateObj?.attribute?.name || '',
            ...(defaultValues?.[
              templateObj?.attribute?.fieldProperties?.fields?.propertyName || ''
            ] && {
              initialValue:
                defaultValues?.[
                  templateObj?.attribute?.fieldProperties?.fields?.propertyName || ''
                ],
            }),
          };
        }

        return acc;
      }, {} as CSVTemplate),
    [template, defaultValues],
  );
  const certificationAttributes = useMemo(() => {
    const certificationFields = template?.templateCertifications || [];
    if ((certificationFields?.length || 0) > 0) {
      return certificationFields.reduce((acc, field, idx) => {
        Object.keys(field)
          ?.filter((key) => key !== 'id' && key !== 'eventTemplateId', [])
          ?.forEach((key) => {
            const dataIndex = `${key} ${idx + 1}`;
            acc[dataIndex] = {
              dataIndex: `${key} ${idx + 1}`,
              title: `${getProductsT(certificationMappings[key])} ${idx + 1}`,
              initialValue: field?.[key as keyof TemplateCertificationResponse] || '',
            };
          });

        return acc;
      }, {} as CSVTemplate);
    }
    return {};
  }, [template]);

  const overallTemplate: CSVTemplate = useMemo(
    () => ({
      ...commissionTemplate,
      ...templateAttributes,
      ...certificationAttributes,
      ...(isSerial
        ? {
            lotID: {
              ...commissionTemplate.lotID,
              title: 'Serial Number',
            },
            quantity: undefined,
          }
        : {
            quantity: {
              title: `${getProductsT('quantity')}${uomStr}`,
              dataIndex: 'quantity',
            },
          }),
    }),
    [templateAttributes, isSerial, uomStr, certificationAttributes],
  );
  const overallTemplateObs: CSVTemplate = useMemo(
    () => ({
      ...observeTemplate,
      ...(isSerial
        ? {
            lotID: {
              ...commissionTemplate.lotID,
              title: 'Serial Number',
            },
            quantity: undefined,
          }
        : {
            quantity: {
              title: `${getProductsT('quantity')}${uomStr}`,
              dataIndex: 'quantity',
            },
          }),
    }),
    [isSerial, uomStr],
  );
  const overallTemplateTransform: CSVTemplate = useMemo(
    () => ({
      ...transformTemplate,
      ...(isSerial
        ? {
            lotID: {
              ...commissionTemplate.lotID,
              title: 'Serial Number',
            },
            quantity: undefined,
          }
        : {
            quantity: {
              title: `${getProductsT('quantity')}${uomStr}`,
              dataIndex: 'quantity',
            },
          }),
    }),
    [isSerial, uomStr],
  );
  const overallTemplateDecommission: CSVTemplate = useMemo(
    () => ({
      ...decomissionTemplate,
      ...(isSerial
        ? {
            lotID: {
              ...commissionTemplate.lotID,
              title: 'Serial Number',
            },
            quantity: undefined,
          }
        : {
            quantity: {
              title: `${getProductsT('quantity')}${uomStr}`,
              dataIndex: 'quantity',
            },
          }),
    }),
    [isSerial, uomStr],
  );
  const overallTemplateShip: CSVTemplate = useMemo(
    () => ({
      ...shipTemplate,
      ...(autologReceiveEvent && {
        receiveDate: {
          title: 'Receive Date',
          dataIndex: 'receiveDate',
        },
        receiveTime: {
          title: 'Receive Time',
          dataIndex: 'receiveTime',
        },
      }),
      ...(isSerial
        ? {
            lotID: {
              ...commissionTemplate.lotID,
              title: 'Serial Number',
            },
            quantity: undefined,
          }
        : {
            quantity: {
              title: `${getProductsT('quantity')}${uomStr}`,
              dataIndex: 'quantity',
            },
          }),
    }),
    [isSerial, uomStr, autologReceiveEvent],
  );
  const overallTemplateReceive: CSVTemplate = useMemo(
    () => ({
      ...receiveTemplate,
    }),
    [],
  );
  const setCsvData = useCallback(
    (value: Array<DataItem>) => {
      setFieldsValue({ csvData: value });
    },
    [setFieldsValue],
  );

  const { products: dataProducts } = useShipmentStore();

  // function to download upload csv template with already filled in data
  const downloadReceiveXLSXTemplate = (data: any) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${title}.xlsx`);
  };

  const onDownloadCSVTemplate = () => {
    const pageKey = String(title).toLowerCase();

    // maps data of all lots which user's trying to receive
    const data =
      dataProducts?.length === 1 && dataProducts?.[0]?.isContainer
        ? dataProducts?.[0]?.containerItems?.map((item) => ({
            Product: item?.productName,
            ...(dataProducts?.[0]?.productIdentifierType === 'Serial'
              ? { 'Serial Number': item?.primaryId }
              : { 'Lot Number': item?.primaryId }),
            'Quantity (units)': item?.quantity,
            Date: dataProducts?.[0]?.eventDate ? dateFormat(dataProducts?.[0]?.eventDate) : '',
            Time: dataProducts?.[0]?.eventDate
              ? timeLocaleFormat(new Date(dataProducts?.[0]?.eventDate))
              : '',
            'Business Step': 'Commissioning (CBV)',
            Disposition: 'Active (CBV)',
            Timezone: '(UTC-11:00) Coordinated Universal Time-11',
          }))
        : dataProducts?.map((item) => ({
            Product: item?.productName,
            ...(dataProducts?.[0]?.productIdentifierType === 'Serial'
              ? { 'Serial Number': item?.lotSerial }
              : { 'Lot Number': item?.lotSerial }),
            'Quantity (units)': item?.quantity,
            Date: item?.eventDate ? dateFormat(item?.eventDate) : '',
            Time: item?.eventDate ? timeLocaleFormat(new Date(item?.eventDate)) : '',
            'Business Step': 'Commissioning (CBV)',
            Disposition: 'Active (CBV)',
            Timezone: '(UTC-11:00) Coordinated Universal Time-11',
          }));

    switch (pageKey) {
      case 'commission':
        downloadXLSXTemplate(getTranslatedCSVTemplate(overallTemplate), title);
        break;
      case 'observe':
        downloadXLSXTemplate(getTranslatedCSVTemplate(overallTemplateObs), title);
        break;
      case 'transform':
        downloadXLSXTemplate(getTranslatedCSVTemplate(overallTemplateTransform), title);
        break;
      case 'decommission':
        downloadXLSXTemplate(getTranslatedCSVTemplate(overallTemplateDecommission), title);
        break;
      case 'ship':
        downloadXLSXTemplate(getTranslatedCSVTemplate(overallTemplateShip), title);
        break;
      case 'receive':
        // downloadXLSXTemplate(getTranslatedCSVTemplate(overallTemplateReceive), title);
        downloadReceiveXLSXTemplate(data);
        break;

      default:
        break;
    }
  };

  const getCSVDataByTemplate = (rawData: any) => {
    const pageKey = String(title).toLowerCase();
    /* default bizstep is returned as per event mentioned by ui tester */
    /* default disposition is returned as per event mentioned by ui tester */
    switch (pageKey) {
      case 'commission':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(overallTemplate, item),
            date: checkDateFormat(item?.[getProductsT('date')]),
            time: checkTimeFormat(item?.[getProductsT('time')]),
            bizStep: getBizStepByIdentifier(item?.[getProductsT('bizstep')]),
            disposition: getDispositionByIdentifier(item?.[getProductsT('disposition')]),
            timeZone: getTimezoneByOffsetorText(item?.[getProductsT('timezone')]),
          })) || []
        );
      case 'observe':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(overallTemplateObs, item),
            date: checkDateFormat(item?.[getProductsT('date')]),
            time: checkTimeFormat(item?.[getProductsT('time')]),
            location: getLocationIdByName(item?.[getProductsT('location')], locations),
            lotID: getInstanceIdByLot(
              item?.[getProductsT('lot_number')] || item?.[getProductsT('serial_number')],
              instances?.results,
            ),
            bizStep: getBizStepByIdentifier(item?.[getProductsT('bizstep')]),
            disposition: getDispositionByIdentifier(item?.[getProductsT('disposition')]),
            timeZone: getTimezoneByOffsetorText(item?.[getProductsT('timezone')]),
          })) || []
        );

      case 'ship':
        return (
          rawData?.map((item: any) => {
            const companyLocations = locations || [];
            const shippingLocations = companyLocations?.filter((l) => l?.id !== locationId);
            const shippingCompany = getTradePartnerOByName(
              item?.[getProductsT('ship_to_company')],
              tradePartners?.data,
            );
            const isOwnerAccount = shippingCompany?.isOwnerAccount || false;
            const isWholechain =
              shippingCompany &&
              Object.prototype.hasOwnProperty.call(shippingCompany, 'hasWholechainAccount')
                ? shippingCompany.hasWholechainAccount
                : true;
            const hasWholechainAccount = !shippingCompany?.isOwnerAccount ? isWholechain : true;
            const isInternalAcc = isOwnerAccount || !hasWholechainAccount;
            const inventoryInstance = getInventoryInstanceIdByLot(
              item?.[getProductsT('lot_number')] || item?.[getProductsT('serial_number')],
              instances?.results,
            );

            const receiveDate = isInternalAcc
              ? checkDateFormat(item?.[getProductsT('receive_date')])
              : undefined;
            const receiveTime = isInternalAcc
              ? checkTimeFormat(item?.[getProductsT('receive_time')])
              : undefined;
            return {
              id: item?.id || uuidv4(),
              ...titletoKeys(overallTemplateObs, item),
              shippingCompany: shippingCompany?.id,
              date: checkDateFormat(item?.[getProductsT('date')]),
              time: checkTimeFormat(item?.[getProductsT('time')]),
              lastEventDate: inventoryInstance?.event?.eventDate,
              shippingAddress: getLocationIdByName(
                item?.[getProductsT('ship_to_address')],
                shippingLocations,
              ),
              lotID:
                inventoryInstance?.productInstance?.id || inventoryInstance?.container?.containerId,
              bizStep: getBizStepByIdentifier(item?.[getProductsT('bizstep')]),
              disposition: getDispositionByIdentifier(item?.[getProductsT('disposition')]),
              timeZone: getTimezoneByOffsetorText(item?.[getProductsT('timezone')]),
              /* only for autolog receive */
              receiveDate,
              receiveTime,
              isOwnerAccount,
              hasWholechainAccount,
            };
          }) || []
        );

      case 'transform':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(overallTemplateTransform, item),
            date: checkDateFormat(item?.[getProductsT('date')]),
            time: checkTimeFormat(item?.[getProductsT('time')]),
            productId: getProductIdByName(
              item?.[getProductsT('csv_product_title')],
              products?.results,
              productId,
            ),
            bizStep: getBizStepByIdentifier(item?.[getProductsT('bizstep')]),
            disposition: getDispositionByIdentifier(item?.[getProductsT('disposition')]),
            timeZone: getTimezoneByOffsetorText(item?.[getProductsT('timezone')]),
          })) || []
        );

      case 'receive':
        return (
          rawData?.map((item: any) => {
            const shipment =
              getShipmentByName(
                item?.[getProductsT('lot_number')] || item?.[getProductsT('serial_number')],
                dataShipments?.results,
              ) ||
              getShipmentContainerByName(
                item?.[getProductsT('lot_number')] || item?.[getProductsT('serial_number')],
                dataShipments?.results,
              );
            const externalShip = !shipment
              ? getExternalShipmentByName(
                  item?.[getProductsT('lot_number')] || item?.[getProductsT('serial_number')],
                  externalShipments?.results,
                )
              : undefined;
            const i = shipment?.productInstance;
            const container = shipment?.container;
            // const p = i?.product;
            /* parent product id */
            // const parentProductId = p?.id || container?.productInstances?.[0]?.productId;
            const originLocationId = shipment?.event?.locationId || externalShip?.originId;
            /* last event id */
            const lastEventId = shipment?.event?.id || externalShip?.id;
            /* instance id */
            const instanceId =
              i?.id ||
              container?.productInstances?.find(
                (c) => c?.lotSerial === (item?.['Lot Number'] || item?.['Serial Number']),
              )?.id ||
              externalShip?.instanceId;
            /* destination location */
            const companyLocations = locations || [];
            const location = getLocationIdByName(
              item?.[getProductsT('location')],
              companyLocations,
            );

            // get product data for row which we are trying to edit
            const rowProductInfo = dataProducts?.find(
              (pd) =>
                pd?.productId ===
                (i?.product?.id ||
                  container?.productInstances?.[0]?.productId ||
                  externalShip?.productId),
            );

            return {
              id: item?.id || uuidv4(),
              ...titletoKeys(overallTemplateReceive, item),
              date: checkDateFormat(item?.[getProductsT('date')]),
              time: checkTimeFormat(item?.[getProductsT('time')]),
              parentProductId: getAssignedProductId(rowProductInfo, products?.results) || undefined,
              productId:
                getAssignedProductId(rowProductInfo, products?.results) || 'external_shipment',
              lastEventId,
              originLocationId,
              location,
              instanceId,
              lotID: rowProductInfo?.isContainer
                ? rowProductInfo?.containerItems?.find(
                    (c) => c?.primaryId === (item?.['Lot Number'] || item?.['Serial Number']),
                  )?.primaryId
                : rowProductInfo?.primaryId || '',
              lotSerial: rowProductInfo?.isContainer
                ? rowProductInfo?.containerItems?.find(
                    (c) => c?.primaryId === (item?.['Lot Number'] || item?.['Serial Number']),
                  )?.primaryId
                : rowProductInfo?.primaryId || '',
              isContainer: rowProductInfo?.isContainer || false,
              bizStep: getBizStepByIdentifier(item?.[getProductsT('bizstep')]),
              disposition: getDispositionByIdentifier(item?.[getProductsT('disposition')]),
              timeZone: getTimezoneByOffsetorText(item?.[getProductsT('timezone')]),
              productInfo: {
                productIdentifierType:
                  i?.product?.productIdentifierType ||
                  container?.productInstances?.[0]?.productIdentifierType ||
                  externalShip?.productIdentifierType,
                name:
                  i?.product?.name ||
                  container?.productInstances?.[0]?.productName ||
                  externalShip?.productName,
                shippedFrom: shipment?.event?.senderName || externalShip?.shippedFromCompanyName,
              },
              instanceUrn: rowProductInfo?.instanceUrn || '',
            };
          }) || []
        );

      case 'decommission':
        return (
          rawData?.map((item: any) => ({
            id: item?.id || uuidv4(),
            ...titletoKeys(overallTemplateDecommission, item),
            productId: getProductIdByName(
              item?.[getProductsT('csv_product_title')],
              products?.results,
              productId,
            ),
            lotID: getInstanceIdByLot(
              item?.[getProductsT('lot_number')] || item?.[getProductsT('serial_number')],
              instances?.results,
            ),
            date: checkDateFormat(item?.[getProductsT('date')]),
            time: checkTimeFormat(item?.[getProductsT('time')]),
            bizStep: getBizStepByIdentifier(item?.[getProductsT('bizstep')]),
            disposition: getDispositionByIdentifier(item?.[getProductsT('disposition')]),
            timeZone: getTimezoneByOffsetorText(item?.[getProductsT('timezone')]),
          })) || []
        );

      default:
        return {};
    }
  };
  const onCSVUpload: UploadProps['onChange'] = async (info) => {
    const { file } = info;
    file.status = 'done';
    if (!file.url && !file.preview && file.originFileObj) {
      const data = await readExcelTemplate(file.originFileObj);
      const newData: Array<DataItem> = getCSVDataByTemplate(data);

      setCsvData(newData);
    }
  };
  const uploadProps: UploadProps = {
    name: 'csvfile',
    multiple: false,
    maxCount: 1,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onChange: onCSVUpload,
    beforeUpload: async (file) => {
      const isCSV =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isCSV) {
        message.error(t('only_xlsx_upload'));
      }
      // check if csv file contains data
      const data = await readExcelTemplate(file);
      const newData: Array<DataItem> = getCSVDataByTemplate(data);
      if (!newData?.length) {
        message.error(t('no_csv'));
      }

      return isCSV && newData?.length > 0;
    },
    showUploadList: false,
  };
  const renderTable = () => {
    const pageKey = String(title).toLowerCase();
    switch (pageKey) {
      case 'commission':
        return (
          <CommissionTable
            form={form}
            editableTableFormRef={editableTableFormRef}
            defaultValues={defaultValues}
          />
        );
      case 'observe':
        return (
          <CommissionTable
            form={form}
            editableTableFormRef={editableTableFormRef}
            defaultValues={defaultValues}
          />
        );
      case 'transform':
        return (
          <CommissionTable
            form={form}
            editableTableFormRef={editableTableFormRef}
            defaultValues={defaultValues}
          />
        );
      case 'ship':
        return (
          <CommissionTable
            form={form}
            editableTableFormRef={editableTableFormRef}
            defaultValues={defaultValues}
          />
        );

      default:
        return (
          <CommissionTable
            form={form}
            editableTableFormRef={editableTableFormRef}
            defaultValues={defaultValues}
          />
        );
    }
  };
  const downloadTemplateLoading = useMemo(() => {
    const pageKey = String(title).toLowerCase();

    switch (pageKey) {
      case 'receive':
        return isLoadingShipments || isgetTotalItemsLoadingShipments;

      default:
        return false;
    }
  }, [isLoadingShipments, isgetTotalItemsLoadingShipments, title]);

  return (
    <>
      <Space size="large" direction="vertical" className={styles.csvbtn}>
        <Space>
          <Text className="ant-pro-form-group-title">{t('csv_upload')}</Text>
          <Tooltip
            title={
              <div className={styles.tooltiplink}>
                {t('upload_tooltip')}
                &nbsp;
                <Link target="_blank" href={msofficeHelpLink} rel="noreferrer">
                  {t('upload_tooltip_href')}
                </Link>
              </div>
            }
          >
            <Typography.Text type="secondary">
              <InfoCircleOutlined className={styles.tooltip} />
            </Typography.Text>
          </Tooltip>
        </Space>
        {!csvData?.length && (
          <Button
            shape="round"
            type="primary"
            onClick={onDownloadCSVTemplate}
            loading={downloadTemplateLoading}
          >
            {t('download_csv_template')}
          </Button>
        )}
      </Space>

      {csvData?.length ? (
        <div>
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            shape="round"
            ghost
            onClick={() => setCsvData([])}
            className={styles.removebtn}
          >
            {t('remove_file')}
          </Button>
          {renderTable()}
        </div>
      ) : (
        <Col {...uploadColProps}>
          <Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('upload_desc')}</p>
          </Dragger>
        </Col>
      )}
    </>
  );
};

const DataTemplate: FC<CustomDataTemplateProps> = ({ form, editableTableFormRef }) => {
  const dataEntryMethod = useEventsStore((state) => state.dataEntryMethod);
  const { title } = useEventsStore();
  const pageKey = String(title).toLowerCase();

  if (dataEntryMethod === 'manual') {
    return <CustomDataTemplate form={form} />;
  }

  if (pageKey === 'commission') {
    return <CSVUpload form={form} editableTableFormRef={editableTableFormRef} />;
  }

  return (
    <>
      <CSVUpload form={form} editableTableFormRef={editableTableFormRef} />
      <div className={styles.margint20} />
      <CustomDataTemplate form={form} />
    </>
  );
};
export default React.memo(DataTemplate);

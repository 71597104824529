import { BasicLayoutProps, ProLayout } from '@ant-design/pro-layout';
import { wholechainFulllogoSvg } from 'assets';
import classNames from 'classnames';
import { FC } from 'react';
import styles from './GenLayout.module.less';
import { GenLayoutProps } from './typings';

const settings: BasicLayoutProps = {
  primaryColor: 'red;',
  contentWidth: 'Fluid',
  fixSiderbar: true,
  fixedHeader: true,
  iconfontUrl: '',
  layout: 'top',
  menu: { locale: true },
  navTheme: 'dark',
  title: '',
};

const GenLayout: FC<GenLayoutProps> = ({ children, className, layoutProps }) => (
  <ProLayout
    logo={wholechainFulllogoSvg}
    itemRender={() => null}
    collapsed
    className={classNames(styles.genlayout, className)}
    {...settings}
    {...layoutProps}
  >
    {children}
  </ProLayout>
);
export default GenLayout;
